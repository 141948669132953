import TableComponent from '../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { getOffer, getOffers } from '../../../store/apis/Customers';
import { setOffer } from '../../../store/slices/offerSlice';
import OfferDetail from '../../Commerce/Offers/OfferDetail/OfferDetail';
import { duplicateSelectedOffer, getPublicHolidays, deleteOffer as removeOffer } from '../../../store/apis/OffersApi';

const SaleOffer = () => {
   const dispatch = useDispatch();

   const { selectedCustomerId } = useSelector((state) => state.customer);

   const saleOffers = useSelector((state) => state.offers.offers);

   const formatPrice = (price) => `€${parseFloat(price).toFixed(2)}`;
   const dateDiffInDays = (rowData) => calculateWorkDays(rowData);

   const [deleteSaleOffer, setDeleteSaleOffer] = useState({
      dialogVisible: false,
      selectedOfferId: null,
   });

   const [duplicateOffer, setDuplicateOffer] = useState({
      dialogVisible: false,
      selectedOfferId: null,
   });

   const [saleOfferVisible, setSaleOfferVisible] = useState(false);
   const [publicHolidays, setPublicHolidays] = useState([]);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      dispatch(getOffers(selectedCustomerId, '2'));

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const calculateTotalPrice = (rowData) => {
      const days = dateDiffInDays(rowData);
      const basePrice = rowData?.price * days - (rowData?.price * days * rowData.discountRate) / 100;
      const ecoPrice = rowData.isEco ? +rowData.ecoPrice : 0;
      const insurancePrice = rowData.isInsurance ? (basePrice * rowData.insuranceRate) / 100 : 0;
      const deliveryFee = +rowData.deliveryFee;
      const pickupFee = +rowData.pickupFee;
      return basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee;
   };

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };

   const tableColumns = [
      {
         name: 'offerNumber',
         header: t('global.table.offerNumber'),
         body: (rowData) => {
            return <span>{rowData.orderNumber}</span>;
         },
      },
      {
         name: 'status',
         header: t('global.table.status'),
         body: (rowData) => {
            return <span>{rowData.isRefused ? 'Refused' : rowData.isOffered ? 'Offered' : '-'}</span>;
         },
      },
      {
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'user',
         header: t('global.table.user'),
         body: (rowData) => {
            return <span>{rowData.customer?.companyName}</span>;
         },
      },
      {
         name: 'totalBeforeTax',
         header: t('global.table.totalBeforeTax'),
         body: (rowData) => {
            const totals = rowData.orderItems.map((item) => item?.price * item.quantity);
            const totalPrice = totals.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            return rowData.orderItems.category === 'INV' || rowData.orderItems.category === 'SUP'
               ? formatPrice(calculateTotalPrice(rowData.vehicleReservation))
               : '€' + totalPrice;
         },
      },
      {
         name: 'actions',
         body: (rowData) => {
            return (
               <div className="flex justify-center">
                  <div className="flex items-center">
                     <Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={() => showSaleOfferDetail(rowData.id)} />
                     <Button
                        className="mx-1"
                        icon="pi pi-trash"
                        severity="danger"
                        onClick={() =>
                           setDeleteSaleOffer({
                              dialogVisible: true,
                              selectedOfferId: rowData.id,
                           })
                        }
                     />
                     <Button
                        className="mx-1"
                        icon="pi pi-copy"
                        severity="info"
                        onClick={() =>
                           setDuplicateOffer({
                              dialogVisible: true,
                              selectedOfferId: rowData.id,
                           })
                        }
                     />
                  </div>
               </div>
            );
         },
      },
   ];

   const showSaleOfferDetail = (id) => {
      dispatch(getOffer(+id)).then((res) => {
         if (!res) return;
         setSaleOfferVisible(true);
         dispatch(setOffer(res.data.order));
      });
   };

   const handleDuplicateOffer = async () => {
      const res = await dispatch(duplicateSelectedOffer(+duplicateOffer.selectedOfferId));
      if (res) {
         showSaleOfferDetail(res);
         toast.success(t('pages.offers.duplicateOfferSuccessMessage'));
         setDuplicateOffer({
            dialogVisible: false,
            selectedOfferId: null,
         });
      }
   };

   return (
      <div>
         <div>
            <div className="flex flex-wrap mt-2 justify-content-end"></div>
            <div className="mt-10 col-7">
               <TableComponent columns={tableColumns} size="small" data={saleOffers} />
            </div>
            <Dialog
               header={t('pages.offers.deleteOffer')}
               visible={deleteSaleOffer.dialogVisible}
               style={{ width: '400px' }}
               onHide={() =>
                  setDeleteSaleOffer({
                     dialogVisible: false,
                     selectedOfferId: null,
                  })
               }
            >
               <div className="confirmation-content">{<span>{t('pages.offers.deleteOfferMessage')}</span>}</div>
               <div className="flex justify-end mt-3">
                  <Button
                     label={t('pages.offers.cancel')}
                     severity="warning"
                     className="mr-2 "
                     onClick={() =>
                        setDeleteSaleOffer({
                           dialogVisible: false,
                           selectedOfferId: null,
                        })
                     }
                  />
                  <Button
                     label={t('pages.offers.delete')}
                     severity="danger"
                     onClick={() => {
                        dispatch(removeOffer([+deleteSaleOffer.selectedOfferId])).then(
                           setDeleteSaleOffer({
                              dialogVisible: false,
                              selectedOfferId: null,
                           }),
                           toast.success(t('pages.offers.deletedOfferMessage')),
                        );
                     }}
                  />
               </div>
            </Dialog>

            {/* Duplicate Offer Dialog */}
            <Dialog
               header={t('pages.offers.duplicateOffer')}
               visible={duplicateOffer.dialogVisible}
               style={{ width: '400px' }}
               onHide={() =>
                  setDuplicateOffer({
                     dialogVisible: false,
                     selectedOfferId: null,
                  })
               }
            >
               <div className="confirmation-content">{<span>{t('pages.offers.duplicateOfferMessage')}</span>}</div>
               <div className="flex justify-end mt-3">
                  <Button
                     label={t('pages.offers.cancel')}
                     severity="danger"
                     className="mr-2 "
                     onClick={() =>
                        setDuplicateOffer({
                           dialogVisible: false,
                           selectedOfferId: null,
                        })
                     }
                  />
                  <Button label={t('global.save')} severity="success" onClick={handleDuplicateOffer} />
               </div>
            </Dialog>
            <OfferDetail setOfferDetailVisible={setSaleOfferVisible} offerDetailVisible={saleOfferVisible} />
         </div>
      </div>
   );
};

export default SaleOffer;
