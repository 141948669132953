import AppLayout from './pages/Layouts/AppLayout';
import AuthLayout from './pages/Layouts/AuthLayout';

import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';

import Users from './pages/Settings/Users/Users';
import Customers from './pages/Customers/Customers';
import Machines from './pages/Machines/Machines';
import TCard from './pages/Machines/TCard';
import MalfunctionRecords from './pages/Workshop/MalfunctionRecords';
import { Outlet } from 'react-router-dom';
import Planning from './pages/Planning/Planning';
import Offers from './pages/Commerce/Offers/Offers';
import Contracts from './pages/Commerce/Contracts/Contracts';
import DeparturesAndReturns from './pages/Workshop/DeparturesAndReturns/Index';
import Logistics from './pages/Logistics/Logistics';
import Invoices from './pages/Management/Invoices/Index';
import Tours from './pages/Logistics/Tours/Tours';
import UninvoicedContracts from './pages/Management/UninvoicedContracts/Index';
import PDFS from './components/PDFS/PDFS';
import MachineGroupPrices from './pages/Settings/MachineGroupPrices/MachineGroupPrices';

const routes = [
   {
      title: 'App',
      name: 'app',
      path: '/',
      auth: true,
      showOnSidebar: false,
      element: <AppLayout />,
      children: [
         {
            title: 'Dashboard',
            name: 'dashboard',
            path: '/dashboard',
            auth: true,
            showOnSidebar: true,
            element: <Dashboard />,
            icon: 'home',
         },
         {
            title: 'Customers',
            name: 'customer',
            path: '/customers',
            auth: true,
            showOnSidebar: true,
            element: <Customers />,
            icon: 'users',
         },
         {
            title: 'Commerce',
            name: 'commerce',
            path: '/commerce',
            auth: true,
            showOnSidebar: true,
            element: <Outlet />,
            icon: 'chart-line',
            children: [
               {
                  title: 'Offers',
                  name: 'offers',
                  path: '/commerce/offers',
                  auth: true,
                  showOnSidebar: true,
                  element: <Offers />,
                  icon: 'folder',
               },
               {
                  title: 'Contracts',
                  name: 'contracts',
                  path: '/commerce/contracts',
                  auth: true,
                  showOnSidebar: true,
                  element: <Contracts />,
                  icon: 'envelope',
               },
            ],
         },
         {
            title: 'Planning',
            name: 'planning',
            path: '/planning',
            auth: true,
            showOnSidebar: true,
            element: <Planning />,
            icon: 'map',
         },
         {
            title: 'PDF',
            name: 'pdf',
            path: '/pdf',
            auth: true,
            showOnSidebar: false,
            element: <PDFS />,
            icon: 'map',
         },
         {
            title: 'Workshop',
            name: 'workshop',
            path: '/workshop',
            auth: true,
            showOnSidebar: true,
            element: <Outlet />,
            icon: 'wrench',
            children: [
               /* {
                  title: 'All Machines',
                  name: 'allMachines',
                  path: '/workshop/all-machines',
                  auth: true,
                  showOnSidebar: true,
                  element: <Machines />,
                  icon: 'truck',
               }, */
               {
                  title: 'Departures & Returns',
                  name: 'departuresAndReturns',
                  path: '/workshop/departures-and-returns',
                  auth: true,
                  showOnSidebar: true,
                  element: <DeparturesAndReturns />,
                  icon: 'arrow-right-arrow-left',
               },
               {
                  title: 'T Card',
                  name: 'tCard',
                  path: '/workshop/t-card',
                  auth: true,
                  showOnSidebar: true,
                  element: <TCard />,
                  icon: 'table',
               },
               {
                  title: 'Malfunction Records',
                  name: 'malfunctionRecords',
                  path: '/workshop/malfunction-records',
                  auth: true,
                  showOnSidebar: true,
                  element: <MalfunctionRecords />,
                  icon: 'exclamation-triangle',
               },
            ],
         },

         {
            title: 'Logistics',
            name: 'logistics',
            path: '/logistics',
            auth: true,
            showOnSidebar: true,
            element: <Outlet />,
            icon: 'truck',
            children: [
               {
                  title: 'Logistics',
                  name: 'transports',
                  path: '/logistics/transports',
                  auth: true,
                  showOnSidebar: true,
                  element: <Logistics />,
                  icon: 'truck',
               },
               {
                  title: 'tours',
                  name: 'tours',
                  path: '/logistics/tours',
                  auth: true,
                  showOnSidebar: true,
                  element: <Tours />,
                  icon: 'truck',
               },
            ],
         },

         {
            title: 'Management',
            name: 'management',
            path: '/management',
            auth: true,
            showOnSidebar: true,
            element: <Outlet />,
            icon: 'sitemap',
            children: [
               {
                  title: 'Invoices',
                  name: 'invoices',
                  path: '/management/invoices',
                  auth: true,
                  showOnSidebar: true,
                  element: <Invoices />,
                  icon: 'folder',
               },
               {
                  title: 'Uninvoiced Contract',
                  name: 'uninvoicedContract',
                  path: '/management/uninvoiced-contract',
                  auth: true,
                  showOnSidebar: true,
                  element: <UninvoicedContracts />,
                  icon: 'folder',
               },
            ],
         },
         /*          {
            title: 'Parameters',
            name: 'parameters',
            path: '/parameters',
            auth: true,
            showOnSidebar: true,
            element: <></>,
            icon: 'globe',
         }, */
         {
            title: 'Settings',
            name: 'settings',
            path: '/settings',
            auth: true,
            showOnSidebar: true,
            icon: 'cog',
            children: [
               {
                  title: 'Users',
                  name: 'users',
                  path: '/settings/users',
                  auth: true,
                  showOnSidebar: true,
                  element: <Users />,
                  icon: 'user',
               },
               {
                  title: 'Machine Group Prices',
                  name: 'machineGroupPrices',
                  path: '/settings/machine-group-prices',
                  auth: true,
                  showOnSidebar: true,
                  element: <MachineGroupPrices />,
                  icon: 'cog',
               },
            ],
         },
      ],
   },
   {
      title: 'Authorization',
      name: 'auth',
      path: '/authorization',
      auth: false,
      element: <AuthLayout />,
      children: [
         {
            title: 'Login',
            name: 'login',
            path: '/authorization/login',
            auth: false,
            showOnSidebar: false,
            element: <Login />,
         },
      ],
   },
   {
      name: 'notFound',
      path: '*',
      element: (
         <>
            <h1>Page Not Found.</h1>
         </>
      ),
   },
];

export default routes;
