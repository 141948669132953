import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../components/TableComponent';
import { Button } from 'primereact/button';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { useDispatch } from 'react-redux';
import { getFilteredFinanceStatistics } from '../../store/apis/SharedApi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ProfitabilityAnalysis = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [tableData, setTableData] = useState([]);
   const [dates, setDates] = useState([]);
   const [filterVisible, setFilterVisible] = useState(false);
   const [startDate, setStartDate] = useState(moment().subtract(12, 'months').format('YYYY-MM-DD'));
   const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
   const [selectedSupplier, setSelectedSupplier] = useState('');

   const [tableColumns, setTableColumns] = useState([
      {
         name: 'supplierAndTransporter',
         header: t('pages.dashboard.supplierAndTransporter'),
         body: (rowData) => {
            return <div className="w-[200px]">{rowData.supplierAndTransporter}</div>;
         },
      },
   ]);

   const handleFilter = async () => {
      const res = await dispatch(
         getFilteredFinanceStatistics({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
         }),
      );
      if (res.PROFITABILITY_ANALYSIS && Object.keys(res.PROFITABILITY_ANALYSIS).length !== 0) {
         setTableColumns([]);
         setTableData([]);
         Object.keys(res.PROFITABILITY_ANALYSIS).forEach((key) => {
            setTableData((prev) => [
               ...prev,
               {
                  supplierAndTransporter: key,
                  ...res.PROFITABILITY_ANALYSIS[key],
               },
            ]);
         });

         const _dates = [];
         Object.keys(
            res.PROFITABILITY_ANALYSIS &&
               res.PROFITABILITY_ANALYSIS[Object.keys(res.PROFITABILITY_ANALYSIS)[0]] &&
               res.PROFITABILITY_ANALYSIS[Object.keys(res.PROFITABILITY_ANALYSIS)[0]],
         ).forEach((key) => {
            _dates.push(key);
            setDates(_dates);
         });
      } else {
         setTableData([]);
      }
      setFilterVisible(false);
   };

   useEffect(() => {
      if (props.data && Object.keys(props.data).length === 0) {
         return;
      }
      Object.keys(props.data).forEach((key) => {
         setTableData((prev) => [
            ...prev,
            {
               supplierAndTransporter: key,
               ...props.data[key],
            },
         ]);
      });

      Object.keys(props.data && props.data[Object.keys(props.data)[0]] && props.data[Object.keys(props.data)[0]]).forEach((key) => {
         setDates((prev) => [...prev, key]);
      });
   }, []);

   useEffect(() => {
      setTableColumns([
         {
            name: 'supplierAndTransporter',
            frozen: true,
            header: t('pages.dashboard.supplierAndTransporter'),
            body: (rowData) => {
               return <div className="w-[200px]">{rowData.supplierAndTransporter}</div>;
            },
         },
      ]);
      for (let i = 0; i < dates.length; i++) {
         const element = dates[i];

         setTableColumns((prev) => [
            ...prev,
            {
               name: `date${i + 1}`,
               header: () => {
                  return (
                     <div className="w-full">
                        <div className="mb-2 w-full text-center text-[15px]">{moment(element, 'YYYY-MM').format('MMM YYYY')}</div>
                        <div className="flex">
                           <div className="w-[50px] text-end">Cost</div>
                           <div className="w-[90px] text-end">Turnover</div>
                           <div className="w-[90px] text-end">Margin</div>
                           <div className="w-[40px] text-end">%</div>
                        </div>
                     </div>
                  );
               },
               body: (rowData) => {
                  return (
                     <div className="flex">
                        <div className="w-[50px] text-end text-[10px]">
                           {rowData[element].COST ? rowData[element].COST.toFixed(2).replace('.', ',') : '0,00'} €
                        </div>
                        <div className="w-[80px] text-end text-[10px]">
                           {rowData[element].TURNOVER ? rowData[element].TURNOVER.toFixed(2).replace('.', ',') : '0,00'} €
                        </div>
                        <div className="w-[80px] text-end text-[10px]">
                           {(rowData[element].TURNOVER - rowData[element].COST).toFixed(2).replace('.', ',')} €
                        </div>
                        <div
                           className={`w-[80px] text-end text-[10px] ${
                              rowData[element].COST && (((rowData[element].TURNOVER - rowData[element].COST) / rowData[element].COST) * 100).toFixed(0) > 0
                                 ? 'text-green-400'
                                 : 'text-red-400'
                           }`}
                        >
                           {rowData[element].COST ? (((rowData[element].TURNOVER - rowData[element].COST) / rowData[element].COST) * 100).toFixed(0) : '0'} %
                        </div>
                     </div>
                  );
               },
            },
         ]);
      }
   }, [dates]);

   // Excel export
   const exportExcel = () => {
      const data = [];
      tableData &&
         tableData.length > 0 &&
         tableData.map((item) => {
            const _item = {
               supplierAndTransporter: item.supplierAndTransporter,
            };
            dates.forEach((date) => {
               _item[`cost | ${date}`] = item[date] ? item[date].COST.toFixed(2) + '€' : 0;
               _item[`turnover | ${date}`] = item[date] ? item[date].TURNOVER.toFixed(2) + '€' : 0;
               _item[`margin | ${date}`] = item[date] ? (item[date].TURNOVER - item[date].COST).toFixed(2) + '€' : 0;
               _item[`percent | ${date}`] =
                  item[date] && (((item[date].TURNOVER - item[date].COST) / item[date].COST) * 100).toFixed() !== 'NaN'
                     ? (((item[date].TURNOVER - item[date].COST) / item[date].COST) * 100).toFixed() + '%'
                     : 0;
            });
            data.push(_item);
         });

      const _data =
         data &&
         data.length > 0 &&
         data.map((item) => {
            return {
               ...item,
            };
         });

      import('xlsx').then((xlsx) => {
         const worksheet = xlsx.utils.json_to_sheet(_data);
         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
         import('file-saver').then((module) => {
            if (module && module.default) {
               const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
               const EXCEL_EXTENSION = '.xlsx';
               const data = new Blob([excelBuffer], {
                  type: EXCEL_TYPE,
               });
               module.default.saveAs(data, 'profitability-analysis' + '_' + new Date().getTime() + EXCEL_EXTENSION);
            }
         });
      });
   };

   return (
      <>
         <div className="mb-3 mt-2 flex justify-between">
            <div>
               <Button onClick={() => setFilterVisible(true)} label={t('global.filter')} severity="success" className="w-[10vw] min-w-[100px] h-[38px] me-2" />
               <InputText
                  value={selectedSupplier}
                  onChange={(e) => setSelectedSupplier(e.target.value)}
                  placeholder={t('pages.machines.search')}
                  className="input-detail"
               />
            </div>
            <Button onClick={exportExcel} icon="pi pi-file-excel" severity="success" aria-label="exel" />
         </div>
         <DataTable
            value={
               selectedSupplier ? tableData.filter((item) => item.supplierAndTransporter.toLowerCase().includes(selectedSupplier.toLowerCase())) : tableData
            }
            scrollable
            className="mt-4"
            size="small"
            stripedRows
            showGridlines
         >
            {tableColumns.map((column) => {
               if (column.body) {
                  return (
                     <Column
                        frozen={column.frozen}
                        key={column.name || column.field}
                        header={column.header}
                        footer={column.footer}
                        body={column.body}
                        field={column.field}
                        bodyClassName={column.bodyClassName}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        selectionMode={column.selectionMode}
                     />
                  );
               } else {
                  return (
                     <Column
                        key={column.name || column.field}
                        field={column.field}
                        header={column.header}
                        footer={column.footer}
                        sortable={props.sortable}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        bodyClassName={column.bodyClassName}
                        selectionMode={column.selectionMode}
                     />
                  );
               }
            })}
         </DataTable>

         <Dialog visible={filterVisible} onHide={() => setFilterVisible(false)} style={{ width: '500px' }}>
            <div className="grid">
               <div className="col-6">
                  <span>{t('pages.contracts.startDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(startDate)}
                     onChange={(e) => setStartDate(e.target.value)}
                  />
               </div>

               <div className="col-6">
                  <span>{t('pages.contracts.endDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(endDate)}
                     onChange={(e) => setEndDate(e.target.value)}
                  />
               </div>

               <div className="col-12">
                  <Button severity="success" onClick={handleFilter} label={t('global.filter')} className="w-full  mt-3" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default ProfitabilityAnalysis;
