import client from '../../graphql/apollo.config';
import {
   CREATE_ADDRESS,
   CREATE_MACHINE,
   CREATE_MACHINE_GROUP,
   CREATE_OFFER,
   CREATE_OFFER_DOCUMENT,
   CREATE_OFFER_ITEM,
   CREATE_VEHICLE_RESERVATION,
   DELETE_OFFERS,
   DELETE_OFFER_DOCUMENT,
   DELETE_OFFER_ITEM,
   DELETE_VEHICLE_RESERVATION,
   DUPLICATE_OFFER,
   GET_ADDRESS,
   GET_ADDRESSES,
   GET_CITIES,
   GET_CUSTOMERS,
   GET_CUSTOMER_SUPPLIERS,
   GET_ITEM_CATEGORIES,
   GET_MACHINES,
   GET_OFFER,
   GET_OFFERS,
   GET_OFFER_DOCUMENTS,
   GET_OFFER_KINDS,
   GET_OFFER_STATISTICS,
   GET_PUBLIC_HOLIDAYS,
   GET_USERS,
   GET_VEHICLE_FUEL_KINDS,
   GET_VEHICLE_GROUPS,
   GET_VEHICLE_GROUP_PRICE,
   UPDATE_ADDRESS,
   UPDATE_DETAIL_OFFER,
   UPDATE_OFFER,
   UPDATE_OFFER_ITEM,
   UPDATE_VEHICLE_RESERVATION,
} from '../../graphql/offers';
import {
   setOffers,
   removeOffer,
   addOffer,
   handleUpdateOffer,
   addOfferItem,
   setOffer,
   handleUpdateDetailOffer,
   removeOfferItem,
   handleUpdateOfferItem,
   handleUpdateVehicleReservation,
   setOfferDocuments,
   addOfferDocument,
   removeOfferDocument,
   duplicateOffer,
} from '../slices/offerSlice';
import { removeRequest, setRequest } from '../slices/sharedSlice';

export const getOffers = (first, skip, search, filters) => async (dispatch) => {
   dispatch(setRequest('GET_OFFERS'));
   return client
      .query({
         query: GET_OFFERS,
         variables: {
            first,
            skip,
            search,
            filters: { ...filters, isDeleted: false, isContracted: false },
         },
      })
      .then((res) => {
         dispatch(setOffers(res.data.orders));
         return res.data;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_OFFERS'));
      });
};

export const getOfferKinds = () => async () => {
   return client
      .query({
         query: GET_OFFER_KINDS,
      })
      .then((res) => {
         return res.data.orderKinds;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getUsers = () => async () => {
   return client
      .query({
         query: GET_USERS,
      })
      .then((res) => {
         return res.data.users;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getCustomers = () => async () => {
   return client
      .query({
         query: GET_CUSTOMERS,
      })
      .then((res) => {
         return res.data.customers;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const deleteOffer = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_OFFERS'));
   return client
      .mutate({
         mutation: DELETE_OFFERS,
         variables: {
            ids,
         },
      })
      .then((res) => {
         dispatch(removeOffer(ids));
         return res.data.deleteOrders;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_OFFERS'));
      });
};

export const getAddresses = (filters) => async () => {
   return client
      .query({
         query: GET_ADDRESSES,
         variables: {
            filters: { ...filters, isMainOrBillingAddress: false },
         },
      })
      .then((res) => {
         return res.data.addresses;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createOffer = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_OFFER'));
   return client
      .mutate({
         mutation: CREATE_OFFER,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addOffer(res.data.createOrder.order));
         return res.data.createOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_OFFER'));
      });
};

export const updateOffer = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_OFFER'));
   return client
      .mutate({
         mutation: UPDATE_OFFER,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleUpdateOffer(res.data.updateOrder.order));
         return res.data.updateOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_OFFER'));
      });
};

export const createOfferItem = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_OFFER_ITEM'));
   return client
      .mutate({
         mutation: CREATE_OFFER_ITEM,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addOfferItem(res.data.createOrderItem.orderItem));
         return res.data.createOrderItem.orderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_OFFER_ITEM'));
      });
};

export const updateOfferItem = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_OFFER_ITEM'));
   return client
      .mutate({
         mutation: UPDATE_OFFER_ITEM,
         variables: {
            data: data && data.updateData ? data.updateData : data,
         },
      })
      .then((res) => {
         if (data.updateData) {
            const updateData = {
               orderId: data.orderId,
               orderItemId: data.updateData.id,
               orderItem: res.data.updateOrderItem.orderItem,
            };
            dispatch(handleUpdateOfferItem(updateData));
         }
         return res.data.updateOrderItem.orderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_OFFER_ITEM'));
      });
};

export const deleteOfferItem = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_OFFER_ITEM'));
   return client
      .mutate({
         mutation: DELETE_OFFER_ITEM,
         variables: {
            ids,
         },
      })
      .then((res) => {
         return res.data.deleteOrderItems;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_OFFER_ITEM'));
      });
};

export const getItemCategories = () => async () => {
   return client
      .query({
         query: GET_ITEM_CATEGORIES,
      })
      .then((res) => {
         return res.data.__type.enumValues;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createOfferDocument = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_OFFER_DOCUMENT'));
   return client
      .mutate({
         mutation: CREATE_OFFER_DOCUMENT,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addOfferDocument(res.data.createOrderDocument.order.orderDocuments));
         return res.data.createOrderDocument.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_OFFER_DOCUMENT'));
      });
};

export const getCities = () => async () => {
   return client
      .query({
         query: GET_CITIES,
      })
      .then((res) => {
         return res.data.cities;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createAddress = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_ADDRESS'));
   return client
      .mutate({
         mutation: CREATE_ADDRESS,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createAddress.address;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_ADDRESS'));
      });
};

export const createVehicleReservation = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_RESERVATION,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_VEHICLE_RESERVATION'));
      });
};

export const getVehicleGroups = (filters) => async () => {
   return client
      .query({
         query: GET_VEHICLE_GROUPS,
         variables: {
            filters,
            haveVehicles: true,
         },
      })
      .then((res) => {
         return res.data.vehicleGroups;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const deleteVehicleReservations = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: DELETE_VEHICLE_RESERVATION,
         variables: {
            ids,
         },
      })
      .then((res) => {
         return res.data.deleteVehicleReservations;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_VEHICLE_RESERVATION'));
      });
};

export const updateVehicleReservation = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION,
         variables: {
            data: data && data.updateData ? data.updateData : data,
         },
      })
      .then((res) => {
         const updateData = {
            orderId: data.orderId,
            orderItemId: data.orderItemId,
            vehicleReservation: res.data.updateVehicleReservation.vehicleReservation,
         };
         dispatch(handleUpdateVehicleReservation(updateData));
         return res.data.updateVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_RESERVATION'));
      });
};

export const getCustomerSuppliers = () => async () => {
   return client
      .query({
         query: GET_CUSTOMER_SUPPLIERS,
      })
      .then((res) => {
         return res.data.customers;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getSupplierMachines = (startDate, endDate, filters) => async () => {
   return client
      .query({
         query: GET_MACHINES,
         variables: {
            startDate,
            endDate,
            filters: { ...filters, isDeleted: false },
         },
      })
      .then((res) => {
         return res.data.availableSupplierVehicles;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createMachineGroup = (data) => async () => {
   return client
      .mutate({
         mutation: CREATE_MACHINE_GROUP,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicleGroup.vehicleGroup;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createMachine = (data) => async () => {
   return client
      .mutate({
         mutation: CREATE_MACHINE,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicle.vehicle;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getVehicleFuelKinds = () => async () => {
   return client
      .query({
         query: GET_VEHICLE_FUEL_KINDS,
      })
      .then((res) => {
         return res.data.vehicleFuelKinds;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getVehicleGroupPrices = (vehicleGroupId, customerId) => async () => {
   return client
      .query({
         query: GET_VEHICLE_GROUP_PRICE,
         variables: {
            vehicleGroupId,
            customerId,
         },
      })
      .then((res) => {
         return res.data.vehicleGroupPrices;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getOffer = (id) => async (dispatch) => {
   dispatch(setRequest('GET_OFFER'));
   return client
      .query({
         query: GET_OFFER,
         variables: {
            id,
         },
      })
      .then((res) => {
         dispatch(setOffer(res.data.order));
         return res.data.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_OFFER'));
      });
};
export const updateDetailOffer = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_DETAIL_OFFER'));
   return client
      .mutate({
         mutation: UPDATE_DETAIL_OFFER,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleUpdateDetailOffer(res.data.updateOrder.order));
         return res.data.updateOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_DETAIL_OFFER'));
      });
};

export const deleteDetailOfferItem = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_OFFER_ITEM'));
   return client
      .mutate({
         mutation: DELETE_OFFER_ITEM,
         variables: {
            ids: [+ids.orderItemId],
         },
      })
      .then(() => {
         dispatch(removeOfferItem(ids));
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_OFFER_ITEM'));
      });
};

export const getOfferDocuments = (orderId) => async (dispatch) => {
   dispatch(setRequest('GET_OFFER_DOCUMENTS'));
   return client
      .query({
         query: GET_OFFER_DOCUMENTS,
         variables: {
            orderId,
         },
      })
      .then((res) => {
         dispatch(setOfferDocuments(res.data.orderDocuments));
         return res.data.orderDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_OFFER_DOCUMENTS'));
      });
};

export const deleteOfferDocuments = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_OFFER_DOCUMENT'));
   return client
      .mutate({
         mutation: DELETE_OFFER_DOCUMENT,
         variables: {
            ids,
         },
      })
      .then((res) => {
         dispatch(removeOfferDocument(ids[0]));
         return res.data.deleteOfferDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_OFFER_DOCUMENT'));
      });
};

export const getAddress = (id) => async (dispatch) => {
   dispatch(setRequest('GET_ADDRESS'));
   return client
      .query({
         query: GET_ADDRESS,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.address;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_ADDRESS'));
      });
};

export const updateAddress = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_ADDRESS'));
   return client
      .mutate({
         mutation: UPDATE_ADDRESS,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.updateAddress.address;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_ADDRESS'));
      });
};

export const duplicateSelectedOffer = (id) => async (dispatch) => {
   dispatch(setRequest('DUPLICATE_OFFER'));
   return client
      .mutate({
         mutation: DUPLICATE_OFFER,
         variables: {
            id,
         },
      })
      .then((res) => {
         dispatch(duplicateOffer(res.data.duplicateOrder.order));
         return res.data.duplicateOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DUPLICATE_OFFER'));
      });
};

export const getOfferStatistics = () => async () => {
   return client
      .query({
         query: GET_OFFER_STATISTICS,
      })
      .then((res) => {
         return res.data.orderStatistics;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getPublicHolidays = () => async () => {
   return client
      .query({
         query: GET_PUBLIC_HOLIDAYS,
      })
      .then((res) => {
         return res.data.publicHolidays;
      })
      .catch((err) => {
         console.log(err);
      });
};
