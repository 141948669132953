import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getVehicleMalfunction, updateVehicleMalfunction } from '../../../store/apis/MalfunctionApi';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import toast from 'react-hot-toast';

const Malfunctions = (props) => {
   const dispatch = useDispatch();
   const [updateData, setUpdateData] = useState({
      id: props.selectedId,
      isMalfunctionOperatorDefault: false,
      isMalfunctionBatteryFailure: false,
      isMalfunctionEngineFailure: false,
      isMalfunctionBodyDamage: false,
      isMalfunctionImproperHandling: false,
      isMalfunctionChargerFailure: false,
      isMalfunctionElectricalFailure: false,
      isMalfunctionSafetyAccessoriesFailure: false,
      isMalfunctionRemoteControlFailure: false,
      isMalfunctionBatteryCharger: false,
      isMalfunctionTornCable: false,
      isMalfunctionHydraulicFailure: false,
      isMalfunctionOther: false,
      malfunctionOther: '',
   });

   useEffect(() => {
      dispatch(getVehicleMalfunction({ id: props.selectedId, malfunctions: true, solutions: false, informations: false })).then((res) =>
         setUpdateData({
            id: res.id,
            isMalfunctionOperatorDefault: res.isMalfunctionOperatorDefault,
            isMalfunctionBatteryFailure: res.isMalfunctionBatteryFailure,
            isMalfunctionEngineFailure: res.isMalfunctionEngineFailure,
            isMalfunctionBodyDamage: res.isMalfunctionBodyDamage,
            isMalfunctionImproperHandling: res.isMalfunctionImproperHandling,
            isMalfunctionChargerFailure: res.isMalfunctionChargerFailure,
            isMalfunctionElectricalFailure: res.isMalfunctionElectricalFailure,
            isMalfunctionSafetyAccessoriesFailure: res.isMalfunctionSafetyAccessoriesFailure,
            isMalfunctionRemoteControlFailure: res.isMalfunctionRemoteControlFailure,
            isMalfunctionBatteryCharger: res.isMalfunctionBatteryCharger,
            isMalfunctionTornCable: res.isMalfunctionTornCable,
            isMalfunctionHydraulicFailure: res.isMalfunctionHydraulicFailure,
            isMalfunctionOther: res.isMalfunctionOther,
            malfunctionOther: res.malfunctionOther,
         }),
      );
   }, []);

   const handleUpdateMalfunctions = async () => {
      const result = await dispatch(updateVehicleMalfunction(updateData));
      if (result) {
         toast.success(t('pages.malfunctions.malfunctionUpdated'));
      }
   };

   return (
      <>
         <div className="grid">
            {Object.keys(updateData)
               .map((key) => ({
                  key: key,
                  value: updateData[key],
               }))
               .map((item) => (
                  <div className={`col-12 md:col-6 lg:col-4 xl:col-3 ${(item.key == 'malfunctionOther' || item.key == 'id') && 'hidden'}`} key={item.key}>
                     <div className="flex align-items-center border-2 rounded p-2">
                        <Checkbox
                           inputId={item.key}
                           name={item.key}
                           value={item.key}
                           onChange={(e) => setUpdateData({ ...updateData, [item.key]: e.checked })}
                           checked={item.value}
                        />
                        <label htmlFor={item.key} className="ml-2 cursor-pointer">
                           {t('pages.malfunctions.' + item.key)}
                        </label>
                     </div>
                  </div>
               ))}
            {updateData.isMalfunctionOther && (
               <div className="col-12 md:col-6 lg:col-4">
                  <InputText
                     value={updateData.malfunctionOther}
                     placeholder={t('pages.malfunctions.malfunctionOther')}
                     onChange={(e) => setUpdateData({ ...updateData, malfunctionOther: e.target.value })}
                     maxLength={50}
                     className={`w-full input-detail`}
                  />
               </div>
            )}
         </div>

         <div className="col-12 flex justify-end">
            <Button label={t('pages.malfunctions.saveAndContinue')} className="w-[400px] mt-[20px]" onClick={() => handleUpdateMalfunctions()} />
         </div>
      </>
   );
};

export default Malfunctions;
