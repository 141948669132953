import React from 'react';
import { Button } from 'primereact/button';
import { useState } from 'react';
import ViewMachineDetails from '../ViewMachineDetails';
import { useDispatch } from 'react-redux';
import { setShowAddNewMachineDialog, setShowViewMachineDetailsDialog, setStepCounter } from '../../../store/slices/machineSlice';

const Finish = () => {
	const dispatch = useDispatch();
	const [machineDetails, setMachineDetails] = useState(false);

	return (
		<>
			<div className="flex justify-center">
				<i className="pi pi-check-circle" style={{ fontSize: '2.5rem', color: 'green' }}></i>
			</div>
			<div className="flex justify-center mt-3">
				<h1>Success</h1>
			</div>
			<div className="flex justify-center mt-3">
				<span>The Machine has been created successfully</span>
			</div>
			<div className="flex justify-center mt-3">
				<Button
					onClick={() => {
						setMachineDetails(true);
						dispatch(setShowAddNewMachineDialog(false));
						dispatch(setShowViewMachineDetailsDialog(true));
						dispatch(setStepCounter(0));
					}}
					label="View Machine Detail"
				></Button>
			</div>

			{machineDetails ? <ViewMachineDetails /> : null}
		</>
	);
};

export default Finish;
