// eslint-disable-next-line import/no-anonymous-default-export
export default {
   translation: {
      global: {
         sidebar: {
            dashboard: 'Tableau de Bord(Dashboard)',
            customer: 'Clients',
            workshop: 'Atelier',
            allMachines: 'Machines',
            tCard: 'T-Card',
            orders: 'Commandes',
            offers: 'Devis',
            commerce: 'Commerce',
            contracts: 'Contrats',
            planning: 'Planning',
            departuresAndReturns: 'Départs & Retours',
            malfunctionRecords: 'Rapports Interventions',
            logistics: 'Logistiques',
            transports: 'Transports',
            tours: 'Tournées',
            management: 'Gestion',
            invoices: 'Factures',
            uninvoicedContract: 'Contrats non facturés',
            parameters: 'Paramètres',
            reminderDate: 'Date de rappel',
            settings: 'Configurations',
            logout: 'Se déconnecter',
            users: 'Utilisateurs',
            order: 'Commande',
            machineGroupPrices: 'Grille',
            dropFileOrClickToUpload: 'Déposer le fichier ici ou cliquer pour télécharger',
         },
         table: {
            noData: 'Aucune donnée disponible...',
            fullName: 'Nom-Prénom',
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'E-Mail',
            identityNumber: 'Carte d’identité',
            postCode: 'Code postale',
            dateOfBirth: 'Date de naissance',
            reminderDate: 'Date de rappel',
            explanation: 'Commentaire',
            description: 'Description',
            gender: 'Sexe',
            role: 'Rôle',
            city: 'Ville',
            number: 'Numéro',
            referenceNumber: 'Reference Number',
            factor: 'Factor',
            refundOrder: 'Contrat de l’avoir',
            refundPayed: 'Avoir payé',
            firstMachineCode: 'Matériel initiale',
            newMachineCode: 'Nouveau matériel',
            deliveryAddress: 'Adresse de livraison',
            rentType: 'Type location',
            supplierReceivedInvoiced: 'Facture fournisseur',
            discussion: 'Discussion',
            contract: 'Contrat',
            requestor: 'Demandeur',
            arrivalAddress: 'Lieu de livraison',
            departureAddress: 'Lieu de départ',
            machineCode: 'Matériel',
            phoneNumber: 'Téléphone',
            birthdate: 'Date de naissance',
            invoice: 'Facture',
            deleted: 'Supprimé',
            status: 'Status',
            createdDate: 'Date création',
            placeName: 'Chantier',
            machines: 'Machines',
            groupCode: 'Famille machine',
            groupCodes: 'Famille machine',
            contractNumber: 'N° Contrat',
            type: 'Type',
            user: 'Utilisateur',
            date: 'Date',
            notes: 'Notes',
            companyName: 'Raison sociale',
            followedUser: 'Suivi par',
            comment: 'Commentaire',
            honoraryTitle: 'Titre',
            machineGroup: 'Famille machine',
            price1d: 'Prix 1j',
            price2dTo5d: 'Prix 2j à 5j',
            price6dTo15d: 'Prix 6j à 15j',
            price15d: 'Prix > 15j',
            offerNumber: 'N° Devis',
            monthlyPrice: 'Prix mensuel',
            discussionDate: 'Date de discussion',
            year: 'Année',
            serialNumber: 'N° de série',
            fuelKind: 'Energie',
            supplier: 'Fournisseur',
            brand: 'Marque',
            model: 'Modèle',
            active: 'Actif',
            faxNumber: 'Fax',
            action: 'Action',
            fileName: 'Nom du fichier',
            size: 'Taille',
            extension: 'Extension',
            creationDate: 'Date création',
            customerName: 'Nom du client',
            addNewReminder: 'Nouveau rappel',
            addNewDiscussion: 'Nouvelle discussion',
            addNewContact: 'Nouveau contact',
            addNewMachineGroupPrice: 'Nouveau prix machine',
            informations: 'Informations',
            contacts: 'Contacts',
            reminders: 'Rappels',
            discussions: 'Discussions',
            machineGroupPrices: 'Grille',
            offers: 'Devis',
            contracts: 'Contrats',
            saleOffer: 'Devis vente',
            saleContract: 'Contrats vente',
            invoices: 'Factures',
            refunds: 'Avoirs',
            exchanges: 'Echanges',
            transfers: 'Transferts',
            purchaseOrder: 'Bon de commande',
            dailyPrice: 'Prix 1j',
            twoToFivePrice: 'Prix 2j à 5k',
            sixToFifteenPrice: 'Prix 6j à 15j',
            gtFifteenPrice: 'Prix > 15j',
            totalBeforeTax: 'Total HT',
            totalAfterTax: 'Total TTC',
            dueDate: 'Date d’échéance',
            paymentDate: 'Date de paiement',
            invoiceNumber: 'Facture No',
            customerCode: 'Code Client',
            createRefund: 'Créer un avoir',
         },
         genders: {
            1: 'Homme',
            2: 'Femme',
         },
         roles: {
            1: 'Admin',
         },
         search: 'Recherche',
         filter: 'Filtre',
         filters: 'Filtres',
         warning: 'Avertissement',
         france: 'FRANCE',
         save: 'Enregistrer',
         previous: 'Précédent',
         saveAndContinue: 'Enreg.& Continuer',
         delete: 'Supprimer',
         undelete: 'Annuler suppression',
         fillOutRequiredFields: 'Veuillez remplir tous les champs obligatoires pour continuer.',
         unexpectedError: 'Erreur inattendue !',
         tokenExpired: 'Token expiré !',
         unauthorized: "Vous n'êtes pas autorisé à effectuer cette action.",
         networkError: 'Erreur réseau ! Veuillez vérifier votre connexion internet.',
         duplicateEntry: 'Entrée déjà existante !',
         apply: 'Appliquer',
         create: 'Créer',
         update: 'MISE A JOUR',
         updateSuccess: 'Mise à jour effectuée avec succès !',
         add: 'Ajouter',
         requiredField: 'Ce champ est obligatoire.',
         select: 'Sélectionner',
         next: 'Suivant',
         prev: 'Précédent',
         saveAndFinish: 'Enreg. & Terminer',
         continue: 'Continuer',
         REJECTED: 'Refusés',
         APPROVED: 'Acceptés',
         PENDING: 'En attente',
         finish: 'Terminer',
         saveOrFinish: 'TERMINER',
         yes: 'Oui',
         no: 'Non',
         reminders: 'Rappels',
         unreadNotification: 'Vous avez {{count}} rappel(s) pour aujourd’hui',
      },
      authorization: {
         login: {
            doesNotExist: 'Aucun utilisateur trouvé avec les informations fournies !',
         },
      },
      pages: {
         customers: {
            addNewCustomer: 'Nouvelle fiche Client',
            bannedReason: 'Raison du Blocage',
            generalInformation: 'Informations générales',
            addressInformation: 'Adresses',
            customerAddress: 'Adresse du client',
            creditInformation: 'Informations financières',
            type: 'Type',
            typePlaceholder: 'Type',
            companyName: 'Raison sociale',
            companyNamePlaceholder: 'Entréer la raison sociale',
            addNewContact: 'Nouveau contact',
            contactDetails: 'Détails du contact',
            addNewReminder: 'Nouveau rappel',
            addNewDiscussion: 'Nouvelle discussion',
            discussionDetails: 'Détails de discussion',
            activityName: 'Activité',
            eMail: 'E-Mail',
            phoneNumber: 'Téléphone',
            faxNumber: 'Fax',
            identityNumber: 'SIRET',
            webSite: 'Site Web',
            taxNumber: 'Numéro TVA',
            taxOffice: 'RCS',
            notes: 'Notes',
            followedUser: 'Suivi par',
            mainOfficeAddress: 'Adresse du siège',
            country: 'Pays',
            city: 'Ville',
            district: 'Département',
            postCode: 'Code postale',
            address: 'Adresse',
            addressContinuation: 'Address 2',
            billingAddress: 'Adresse de facturation',
            officeAndBillingAddressSame: 'Même adresse de siège et de facturation',
            averagePaymentsInDays: 'Délai moy. paiement',
            overduePayables: 'Solde impayé',
            outstandingPayables: 'Encours location',
            paymentMethod: 'Conditions de paiement',
            maximumCreditLimit: 'Encours maximum',
            factorLimit: 'Encours factor',
            factor: 'Factor',
            check: 'Chèque',
            receptionDate: 'Date réception',
            hasItsOwnInsurance: 'A sa propre assurance',
            buyersBankAccountNumber: `RIB de l'acheteur'`,
            rib: 'RIB',
            iban: 'IBAN',
            bic: 'BIC',
            dropzoneSheet: 'Dropzone fiche',
            listOfAllFilesLinkedToThisCustomer: 'Liste des fichiers liés à ce client',
            noFilesFound: 'Aucun fichier trouvé...',
            accountStatement: 'EXTRAIT DE COMPTE',
            delete: 'SUPPRIMER',
            unDelete: 'ANNULER SUPPRESSION',
            block: 'BLOQUER',
            unBlock: 'DEBLOQUER',
            open: 'OUVRIR',
            malfunctionRecord: 'RAPPORT INTERVENTION',
            customerTypes: {
               PROSPECT: 'Prospect',
               CUSTOMER: 'Client',
               SUPPLIER: 'Fournisseur',
               TRANSPORTER: 'Transporteur',
            },
            discussionTypes: {
               ACCOUNTANT: 'Recouvrement',
               SALES_AGENT: 'Commercial',
            },
            discussionType: 'Sujet de discussion',
            reminderDetails: 'Détails du rappel',
            blockConfirmation: 'Etes-vous sûr(e) de vouloir bloquer ce client ?',
            blockConfirmationTitle: 'Veuillez confirmer la raison de blocage du client',
            unblockConfirmation: 'Etes-vous sûr(e) de vouloir débloquer ce client ?',
            blockSuccess: 'Client bloqué avec succès !',
            unblockSuccess: 'Client débloqué avec succès.',
            deleteConfirmation: 'Etes-vous sûr(e) de vouloir supprimer ce client ?',
            deleteConfirmationTitle: 'Veuillez confirmer la raison de suppression du client',
            undeleteConfirmation: 'Etes-vous sûr(e) de vouloir récupérer ce client ?',
            deleteSuccess: 'Client supprimé avec succès !',
            undeleteSuccess: 'Client récupéré avec succès !',
            updateSuccess: 'Les informations du client ont été mises à jour avec succès !',
            purchaseOrderNumber: 'N° BDC',
            creationDate: 'Date création',
            user: 'Utilisateur',
            deliveryAddress: 'Adresse de livraison',
            amount: 'Montant',
            referenceOffer: 'Contrat d’origine',
            status: 'Status',
            thisCustomerAlreadyExist: 'Ce client existe déjà',
            createRefundTitle: 'Créer un avoir',
            amountAfterTax: 'Montant TTC',
            amountBeforeTax: 'Montant HT',
            refundAll: 'Avoir total',
            createRefundSuccess: 'L’avoir a été créé avec succès !',
            cash: 'Comptant',
            days_30: '30 jours',
            days_45: '45 jours FDM',
            deleteGroupPricesSuccess: 'La grille des prix a été supprimée avec succès !',
            updateGroupPricesSuccess: 'La grille des prix a été mise à jour avec succès !',
            customerCreatedSuccessfully: 'La fiche client a été créée avec succès !',
            contactCreatedSuccessfully: 'Le contact a été créé avec succès ! ',
            transporterOrderCreatedSuccessfully: 'Le bon de commande transporteur a été créé avec succès !',
            supplierTransporterOrderCreatedSuccessfully: 'Le bon de commande fournisseur a été créé avec succès !',
         },
         machines: {
            addMachines: 'Nouveaux matériels',
            addNewMachine: 'Ajouter un nouveau matériel ',
            addNewMachineGroup: 'Nouvelle famille machine',
            addNewSupplier: 'Nouveau fournisseur',
            machineDetails: 'Détails du matériel',
            addNewMachineDocument: 'Ajouter des documents',
            createNewMachineDocument: 'Ajouter des documents',
            createNewSupplier: 'Créer un nouveau fournisseur',
            createNewMachineGroup: 'Créer une nouvelle famille de machine',
            machineTypes: {
               MACHINE: 'Machine',
               MACHINE_TOOL: 'Petit matériel',
            },
            fuelTypes: {
               GASOLINE: 'Essence',
               DIESEL: 'Diesel',
               ELECTRIQUE: 'Electrique',
               HYBRIDE: 'Hybride',
               'BI-ENERGIE': 'Bio-énergie',
               MANUEL: 'Manuel',
            },
            machineCreateSuccess: 'Machine crée !',
            machineUpdateSuccess: 'Machine mise à jour!',
            machineDeleteSuccess: 'Machine supprimée ',
            machineDeleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cette machine ?',
            createNewVehicleGroupSuccess: 'Création réussie !',
            returnPlanned: 'Retour plannifié',
            malfunction: 'En panne',
            rent: 'En location',
            reserved: 'Réservé',
            available: 'Disponible',
            inspection: 'Inspection',
            leavingAddNewMachineGroup: 'Vous quittez la page d‘Ajouter un nouveau groupe de machines et de petits matériel...',
            limit: 'Limite',
            search: 'Recherche',
            machineGroup: 'Famille machine',
            fuelType: 'Type de carburant',
            deleted: 'Supprimé',
            nextVgp: 'Prochain VGP',
            ifSupplier: 'Si fournisseur',
            supplier: 'Fournisseur',
            machineType: 'Type de machine',
            machineOrToolGroup: 'Famille Machine/Petit matériel',
            machineCode: 'Code machine',
            description: 'Description',
            serialNumber: 'N° de série',
            brand: 'Marque',
            model: 'Modèle',
            year: 'Année',
            lengthSize: 'Longueur',
            widthSize: 'Largeur',
            heightSize: 'Hauteur',
            weight: 'Poids’',
            deport: 'Déport',
            maxWarehouse: 'Charge maximale',
            guaranteeInformations: 'Echéances',
            deliveryDate: 'Date de livraison',
            nextRevision: 'Prochaine révision',
            guaranteePeriod: 'Garantie (mois)',
            remainingPeriod: 'Garantie restante (mois)',
            name: 'Nom',
            machineDuplicate: 'Ce code machine est déjà enregistré dans le système. Veuillez entrer un code différent.',
            totalMachines: 'Total Machines',
            totalActiveMachines: 'Total Machine actives',
            totalDeletedMachines: 'Total Machines supprimées',
            totalMalfunctionedMachine: 'Total Machines en panne',
            totalRentedMachine: 'Total Machines en location',
            totalReadyToRentMachine: 'Total Machines disponibles',
         },
         settings: {
            users: {
               selectGender: 'Selectionner le sexe',
               addNewUser: 'Ajouter un nouvel utilisateur',
               selectBirthdate: 'Selectionner date de naissance',
               password: 'Mot de passe',
               selectRole: 'Sélectionner le Rôle',
               createUser: 'Créer l’utilisateur',
               duplicateError: 'Il y a déjà un utilisateur enregistré dans le système avec les informations fournies !',
               userDetails: 'Détails de l’utilisateur',
               deleteUser: 'Supprimer l’utilisateur',
               deleteConfirmation: 'Êtes-vous sûr(e) de vouloir supprimer cet utilisateur ?',
               userDeleteSuccess: 'L’utilisateur supprimé avec succès !',
            },
         },
         offers: {
            addNewOffer: 'Devis location',
            createSale: 'Devis vente',
            offerDetails: 'Détails du devis',
            quantity: 'Quantité',
            offerKinds: {
               1: 'Location',
               2: 'Vente',
               3: 'Entretien',
               4: 'Réparation',
            },
            offerCreateSuccess: 'Devis créé avec succès !',
            offerUpdateSuccess: 'Devis mis à jour avec succès !',
            type: 'Type',
            customer: 'Client',
            offered: 'Devis',
            refused: 'Refusés',
            searchedFields: 'Champs de recherche',
            deleteOfferMessage: 'Êtes-vous sûr(e) de vouloir supprimer ce devis ?',
            deletedOfferMessage: 'Devis supprimé avec succès !',
            cancel: 'Annuler',
            delete: 'Supprimer',
            deleteOffer: 'Supprimer le devis',
            createANewSale: 'Nouveau devis vente',
            poNumber: 'N° BDC',
            privacyExplanation: 'Commentaire caché',
            factor: 'Factor',
            informations: 'Informations',
            items: 'Articles',
            documents: 'Documents',
            tax: 'TVA',
            price: 'Prix',
            taxFee: 'TVA',
            totalPrice: 'Prix total',
            category: 'Catégorie',
            currency: 'Devise',
            total: 'Total',
            updateSale: 'Mettre à jour le devis vente',
            malfunctionCaseNumber: 'N° BI',
            createSaleMessage: 'Le devis vente a été mis à jour avec succès.',
            addNewOfferDocument: 'Ajouter un document',
            createANewAddress: 'Nouvelle adresse',
            receiverName: 'Contact chantier',
            districtName: 'Département',
            address: 'Adresse',
            addressContinuation: 'Adresse 2',
            country: 'Pays',
            totalOrder: 'Total Devis',
            invoicedOrders: 'Devis facturés',
            pendingOrders: 'Devis en attente',
            refusedOrders: 'Devis refusés',
            reference: 'Référence',
            startDate: 'Date début',
            endDate: 'Date fin',
            ecoFee: 'ECO',
            insurance: 'Assurance',
            transporterDeliveryFee: 'Transport aller',
            transporterPickupFee: 'Transport retour',
            totalBeforeTax: 'Total HT',
            totalAfterTax: 'Total TTC',
            addMachine: 'Ajouter une machine',
            inventoryMachine: 'Machines de parc',
            supplierMachine: 'Machines en sous-location',
            saleItems: 'Articles en Vente',
            updateMachine: 'Mettre à jour la machine',
            pickupFee: 'Transport retour',
            deliveryFee: 'Transport aller',
            isIncludingSaturdays: 'Facturation samedi',
            isIncludingSundays: 'Facturation dimanche',
            isIncludingPublicHolidays: 'Facturation jours fériés',
            isEco: 'ECO',
            isInsurance: 'Assurance',
            pleaseAddAtLeastOneItem: 'Veuillez ajouter au moins un article pour continuer !',
            isPickuppByMetaloc: 'Transport par Metaloc',
            isReserved: 'Reservé',
            addMachineGroup: 'Ajouter Famille machine',
            machineFuelKind: 'Machine Types carburant',
            createMachine: 'Créer un machine',
            addNewOfferErrorMessage: 'Veuillez ajouter au moins une machine pour continuer !',
            price1d: 'Prix 1j',
            price2to5d: 'Prix 2 à 5j',
            price6to15d: 'Prix 6 à 15j',
            priceGt15d: 'Prix > 15j',
            monthlyPrice: 'Prix mensuel',
            offerDetails: 'Détails du devis',
            offerItemUpdated: 'L’article du devis a été mis à jour avec succès !',
            addInventoryMachine: 'Ajouter une machine de parc ',
            updateInventoryMachine: 'Mettre à jour la machine de parc ',
            addSupplierMachine: 'Ajouter une machine sous-loc',
            updateSupplierMachine: 'Mettre à jour la machine en sous-location',
            itemRemoved: 'L’article supprimé avec succès !',
            contractedRoleError: 'Prospect = pas de contrat !.',
            contractedSupplierWarning:
               'Un bon de commande pour chaque loueur a été généré avec succès ! Veuillez vous rendre sur la fiche du ou des loueurs concernés pour le compléter.',
            updateAddress: 'Mettre à jour l’adresse',
            duplicateOfferSuccessMessage: 'Ce devis a été dupliqué avec succès.',
            duplicateOffer: 'Dupliquer le devis',
            duplicateOfferMessage: 'Êtes-vous sûr(e) de vouloir dupliquer ce devis ?',
            acceptedOrders: 'Devis acceptés',
            conversionRate: 'Taux de conversion',
            discountRate: 'Remise',
         },
         malfunctions: {
            caseNumber: 'N° BI',
            faultDate: 'Date de panne',
            technican: 'Technicien',
            machineCode: 'Code machine',
            customer: 'Client',
            customerPostCode: 'Code postale client',
            customerCity: 'Ville client',
            view: 'Voir',
            detail: 'Détail',
            totalMachineMalfunctionRecord: 'Total Pannes',
            totalResolvedMachineMalfunctionRecord: 'Total Pannes resoluées',
            totalPendingMachineMalfunctionRecord: 'Total Pannes en attente',
            addNewMalfunctionRecord: 'Nouveau bon d’intervention',
            informations: 'Informations',
            malfunctions: 'Nature de la panne',
            malfunctionDocuments: 'Documents',
            finish: 'Terminer',
            type: 'Type',
            faultDate: 'Date de panne',
            contactName: 'Nom du contact',
            contactEmail: 'Email',
            contactPhone: 'Numéro du contact',
            explanation: 'Commentaire',
            notes: 'Notes',
            isMalfunctionOperatorDefault: 'Défaut Opérateur',
            isMalfunctionBatteryFailure: 'Panne Batterie',
            isMalfunctionEngineFailure: 'Panne Moteur',
            isMalfunctionBodyDamage: 'Casse Carosserie',
            isMalfunctionImproperHandling: 'Mauvaise Manipulation',
            isMalfunctionChargerFailure: 'Panne Chargeur',
            isMalfunctionElectricalFailure: 'Panne Electronique',
            isMalfunctionSafetyAccessoriesFailure: 'Panne Accessoires de Securité',
            isMalfunctionRemoteControlFailure: 'Panne télécommande',
            isMalfunctionBatteryCharger: 'Chargeur Batterie',
            isMalfunctionTornCable: 'Câble Arraché',
            isMalfunctionHydraulicFailure: 'Panne Hydraulique',
            isMalfunctionOther: 'Autre',
            saveAndContinue: 'Enreg. & Continuer',
            machine: 'Machine',
            MalfunctionRecordDetail: 'Compte-rendu d’intervention',
            generalInformations: 'Informations générales',
            solutions: 'Prise en charge',
            malfunctionDocuments: 'Documents',
            primaryContactName: 'Nom du contact principal',
            emailAddress: 'E-Mail',
            previous: 'Précédent',
            malfunctionOther: 'Autre',
            downloadReport: 'Télécharger le BI',
            technician: 'Technicien',
            malfunctionUpdated: 'Le rapport d’intervention a été mis à jour avec succès !',
            arrivalTime: 'Heure d’arrivée',
            isSolutionWarranty: 'Garantie',
            isSolutionVehicleReplacement: 'Remplacement Machine',
            isSolutionMaintenance: 'Maintenance',
            isSolutionDepotRepair: 'Réparation au Dépôt',
            isSolutionDefectDepotPreparation: 'Défaut Préparation Dépôt',
            isSolutionOnSiteRepair: 'Réparation sur place',
            isSolutionAfterSalesServicePickup: 'Enlèvement SAV',
            isSolutionOther: 'Autre',
            isJobDone: 'Travaux effectués',
            isPartsAndSmallSupplies: 'Pièces et petites fournitures',
            nextStep: 'Suite à donner',
            isInvoiced: 'A facturer',
            isMakeAnOffer: 'Devis à faire',
            isMaintenanceContract: 'Contrat de maintenance',
            departureTime: 'Heure de départ',
            note: 'Commentaire',
            date: 'Date',
            placeName: 'Adresse du chantier',
            customerSignature: 'Signature client',
            clearSignature: 'Effacer la signature',
            createDocumentMessage: 'Document créé avec succès !',
            addNewMalfunctionDocument: 'Ajouter un document',
            isCompleted: 'Résolu',
            resolved: 'Résolu',
            areYouSureResolve: 'Souhaitez-vous résoudre cette panne ?',
         },
         planning: {
            id: 'ID',
            vehicleReservations: 'Réservations Machine',
            machineCode: 'Code machine',
            supplier: 'Fournisseur',
            supplierName: 'Nom fournisseur',
            address: 'Adresse',
            beginDate: 'Date début',
            endDate: 'Date fin',
            customer: 'Client',
            inventory: 'Parc',
            machineGroup: 'Famille machine',
            contractNo: 'N° contrat',
            placeName: 'Chantier',
            returnPlanned: 'Retour planifié',
            available: 'Disponible',
            rent: 'En location',
            reserved: 'Réservé',
            malfunction: 'En panne',
         },
         contracts: {
            deleteContractMessage: 'Êtes-vous sûr(e) de vouloir supprimer ce contrat ?',
            deletedContractMessage: 'Contrat supprimé avec succès !',
            cancel: 'Annuler',
            delete: 'Supprimer',
            deleteContract: 'Supprimer le contrat',
            totalOrder: 'Total Devis',
            invoicedOrders: 'Devis facturés',
            pendingOrders: 'Devis en attente',
            refusedOrders: 'Devis refusés',
            informations: 'Informations',
            items: 'Articles',
            documents: 'Documents',
            searchedFields: 'Champs de recherche',
            searchedFieldsList: 'Contrat, BDC, Commentaires, Description…',
            type: 'Type',
            customer: 'Client',
            quantity: 'Quantité',
            number: 'N° contrat',
            poNumber: 'N° BDC',
            privacyExplanation: 'Commentaire caché',
            explanation: 'Commentaire client',
            factor: 'Factor',
            offered: 'Devis',
            contracted: 'Contrat',
            contract: 'Contrat',
            seeProforma: 'Proforma',
            deliveryNote: 'Bon de livraison',
            pickuppNote: 'Bon de retour',
            contractDetail: 'Détail du contrat',
            information: 'Information',
            transporter: 'Transporteur',
            poTransporter: 'BDC Transporteur',
            departure: 'Départ',
            return: 'Retour',
            createNewTransporterOrder: 'Nouveau BDC transporteur',
            createNewAddress: 'Créer une nouvelle adresse',
            referenceOffer: 'Contrat d’origine',
            contactPerson: 'Contact',
            category: 'Catégorie',
            description: 'Description',
            beginDate: 'Date début',
            endDate: 'Date fin',
            price: 'Prix',
            READY_TO_DISPATCH: 'Prêt à dispatcher',
            DISPATCHED: 'Dispatché',
            RESERVE: 'Reserver',
            READY_TO_DEPART: 'Prêt au départ',
            RENT: 'En location',
            RETURN_PLANNED: 'Retour planifié',
            RENT_FINISHED: 'Location terminée',
            status: 'Status',
            po: 'BDC',
            price: 'Prix',
            totalPrice: 'Prix total',
            taxFee: 'TVA',
            addNewContractDocument: 'Ajouter un document',
            reference: 'Référence',
            startDate: 'Date début',
            ecoFee: 'ECO',
            insurance: 'Assurance',
            transporterDeliveryFee: 'Transport aller',
            transporterPickupFee: 'Transport retour',
            totalBeforeTax: 'Total HT',
            totalAfterTax: 'Total TTC',
            total: 'Total',
            updateSaleItem: 'Mettre à jour article de vente',
            price1d: 'Prix 1j',
            price2to5d: 'Prix 2 à 5j',
            price6to15d: 'Prix 6 à 15j',
            priceGt15d: 'Prix > 15j',
            monthlyPrice: 'Prix mensuel',
            contractDetails: 'Détails du contrat',
            contractItemUpdated: 'L’article du contrat a été mis à jour avec succès ! ',
            discountRate: 'Remise',
            isIncludingSundays: 'Facturation samedi',
            isIncludingSaturdays: 'Facturation dimanche',
            isIncludingPublicHolidays: 'Facturation jours fériés',
            isEco: 'ECO',
            isInsurance: 'Assurance',
            isPickuppByMetaloc: 'Transport par Metaloc',
            isReserved: 'Reservé',
            deliveryFee: 'Transport aller',
            pickupFee: 'Transport retour',
            acceptedOrders: 'Devis acceptés',
            exchangeMachine: 'Echange de machine',
            exchange: 'Echange',
            transferOfMachine: 'Transfert de machine',
            transfer: 'Transfert',
            extendContract: 'Remettre en location',
            extend: 'Remettre en location',
            originalMachine: 'Matériel initiale',
            newMachine: 'Nouveau matériel',
            currentEndDate: 'Date fin actuelle',
            supplier: 'Fournisseur',
            createNewSupplierPurchaseOrder: 'Créer un nouveau bon de commande fournisseur',
            supplierOrderNumber: 'BDC fournisseur',
            supplierPurchaseOrder: 'BDC fournisseur',
            conversionRate: 'Taux de conversion',
            deleteOrderItem: 'Supprimer un article contrat',
            deleteOrderItemMessage: 'Êtes-vous sûr(e) de vouloir supprimer cet article du contrat ?',
            itemRemoved: 'Aricle supprimé avec succès !',
            duplicateContract: 'Dupliquer le contrat',
            duplicateContractMessage: 'Êtes-vous sûr(e) de vouloir dupliquer ce contrat ?',
            duplicateContractSuccessMessage: 'Le contrat a été dupliqué avec succès.',
            updateInventoryMachine: 'Mettre à jour la machine de parc',
            updateSupplierMachine: 'Mettre à jour la machine en sous-location',
            updateAddress: 'Mettre à jour l’adresse',
            RETURN_PLANED: 'Retour planifié',
            INSPECTION: 'Inspection',
            returnDate: 'Date de retour',
            SavedTransporterOrder: 'L’enregistrement a été effectué avec succès !',
            transporterCostHT: 'Coût transporteur HT',
            transporterMarginHT: 'Marge transport HT',
            supplierCostHT: 'Coût fournisseur HT',
            rentMarginHT: 'Marge location HT',
            transporterPO: 'Transporteur BDC ({{transporterOrderNumber}})',
         },
         departuresAndReturns: {
            departures: 'Départs',
            returns: 'Retours',
            beginDate: 'Date début',
            readyToDispatchLabel: 'machines à dispatcher pour le',
            reserveMachineLabel: 'machines réservées pour le',
            readyToDepartLabel: 'machines prêtes au départ pour le',
            contractNo: 'N° contrac',
            dispatch: 'Dispatcher',
            selectMachineGroup: 'Selectionner la famille machine',
            updateSuccess: 'Mise à jour réussie !',
            confirmMachine: 'Confirmer cette machine',
            vehicleReservationControlForm: 'Bon de départ',
            time: 'Heure',
            key: 'Clé',
            vgp: 'VGP',
            good: 'Bon',
            medium: 'Moyen',
            bad: 'Mauvais',
            chassisStatus: 'Chassis',
            basketStatus: 'Panier',
            batteryStatus: 'Batteries',
            cylindersStatus: 'Verins',
            doorsStatus: 'Capots',
            tiresStatus: 'Pneus',
            lowerBodyStatus: 'Poste bas',
            upperBodyStatus: 'Poste haut',
            paintStatus: 'Painture',
            mileage: 'Kilométrage / Horamètre (Km ou Heure)',
            controlFormUpdateSuccess: 'Bon de départ a été mis à jour avec succès !',
            receivingMachinesToday: 'machines à réceptionner aujourd’hui',
            machinePreparation: 'machines en préparation',
            inspection: 'Inspection',
            nextVGP: 'Prochain VGP',
            nextRevision: 'Prochaine révision',
            makeAvailable: 'Rendre disponible',
            confirm: 'Êtes-vous sûr(e) ? Cette action est irréversible !',
         },
         logistics: {
            customer: 'Client',
            contractNo: 'N° contract',
            machineCode: 'Code machine',
            deliveryAddress: 'Adresse de livraison',
            city: 'Ville',
            beginDate: 'Date début',
            confirmMachine: 'Confirmer cette machine',
            confirmDelivery: 'Confirm Delivery',
            transporterOrderNumber: 'BDC Transporteur',
            departureTableHeader: "machines à livrer entre aujourd'hui et le",
            departureTableHeader2: 'livraisons validées pour le',
            confirmMachine: 'Confirmer cette machine',
            confirmMachineSuccess: 'Machine confirmée avec succès !',
            confirmMachineMessage: 'Êtes-vous sûr(e) de vouloir confirmer cette machine ?',
            machineDelivered: 'Matériel Livré ?',
            postpone: 'Reporter',
            enterDates: 'Veuillez entrer les nouvelles dates',
            deliveredMachineMessage: 'Êtes-vous sûr(e) de vouloir confirmer la livraison de cette machine ?',
            offerCancellation: 'Annulation du contrat',
            offerCancellationMessage: 'Le contrat de cette machine sera annulé. Il passera au statut Devis.',
            offerCancellationSuccess: 'Contrat annulé avec succès !',
            updateReservationDatesSuccess: 'Dates de location ont été modifiées avec succès !',
            toPickup: 'A récuperer',
            invoice: 'Facturer ?',
            toPickupSuccess: 'Confirmation de reprise de la machine !!',
            toPickupMessage: 'Êtes-vous sûr(e) de vouloir récupérer cette machine ?',
            endDate: 'Date fin',
            machineRecovery: 'Matériel récupéré ?',
            reRent: 'Remettre en location',
            machineRecoveryMessage: 'Confirmez-vous la reprise de ce matériel ?',
            createInvoiceSuccess: 'Facture créée avec succès ! ',
            returnTableHeader: 'machines à récupérer',
            returnTableHeader2: 'machines en attente de reprise',
            returnExcelButton: 'Liste de relance pour les reprises',
            reRentMessage: 'Est-ce que vous souhaitez mettre ce matériel en location ?',
            reRentSuccess: 'Le materiel a été mis en location avec succès !',
            createInvoiceMessage: 'Souhaitez-vous créer une pré-facture ?',
         },
         invoices: {
            preInvoices: 'Factures en attente',
            invoiced: 'Factures',
            refunds: 'Avoirs',
            createPrevMonth: 'Générer la préfacturation du mois dernier',
            createThisMonth: 'Générer la préfacturation du mois en cours',
            createMonthEnd: 'Forcer la facturation mensuelle du mois en cours',
            downloadPrevMonth: 'Exporter le journal des ventes du mois dernier',
            preInvoiceCard1: 'Total Factures en attente de paiement',
            preInvoiceCard2: 'Total Factures en attente de paiement et non échues',
            preInvoiceCard3: 'Total Facture échues et impayées',
            unpaidListButton: 'Liste de relance des factures échues impayées',
            dueDate: 'Echéance',
            supplierInvoice: 'Facture fournisseur',
            invoiceLock: 'Cadenas facture',
            edit: 'Modifier',
            pdf: 'PDF',
            updateInvoice: 'Mettre à jour la Facture',
            newPrice: 'Location',
            discount: 'Remise',
            fuel: 'Carburant',
            sale: 'Vente',
            discountValue: '% Minoration/Majoration',
            discountIncrease: 'Valeur Minoration/Majoration',
            fuelWithLiter: 'Carburant(L)',
            updateInvoiceSuccess: 'La facture en attente a été mise à jour avec succès !',
            createInvoiceItemsSuccess: 'L’article de la facture a été créée avec succès !',
            updateInvoiceStatusSuccess: 'Le statut de la facture a été mis à jour avec succès !',
            createPrevMonthSuccess: 'La préfacturation du mois dernier a été générée avec succès !',
            createThisMonthSuccess: 'La préfacturation du mois en cours a été générée avec succès !',
            updateSupplierInvoiceSuccess: 'La facture fournisseur a été mise à jour avec succès !',
            deleteInvoiceSuccess: 'La facture a été supprimée avec succès !',
            paymentMethod: 'Mode paiement',
            invoiceNumber: 'Facture No',
            paid: 'Payé',
            unpaid: 'Impayé',
            amountPaid: 'Montant encaissé',
            paymentDate: 'Date paiement',
            bankWire: 'Virement',
            check: 'Chèque',
            creditCard: 'Carte de crédit',
            cameca: 'Cameca',
            cpt: 'CPT',
            directDebit: 'Prélévement',
            lcr: 'LCR',
            judicialLiquidation: 'Liquidation judiciaire',
            payInvoicesHeader: 'Affecter un règlement pour le client',
            invoice: 'Facture',
            refund: 'Avoir',
            unpaidStatusMessage: 'La facture passera au statut impayé !',
            unpaidInvoice: 'Status impayé',
            unpaidStatusSuccess: 'La facture vient de passer au statut impayé !',
            comment: 'Commentaire',
            refundNumber: 'Avoir No',
            createMonthEndPreSuccess: 'La préfacturation de fin de mois a été créée avec succès !',
            uninvoicedContractsHeader: 'Contrats non facturés le mois dernier',
            createInvoiceError: 'Merci d’affecter une machine au contrat avant de créer une facture !',
            alreadyPaid: 'Déjà payé',
            unpaidInvoicesHeader: 'Liste des factures en attente de paiement ',
            previousMonthsReports: 'Journal des ventes du mois dernier',
            journal: 'Journal',
            accountingReference: 'Compte comptable',
            amountInvoicedBeforeTax: 'Montant HT',
            amountReceivedBeforeTax: 'Montant TTC',
            libelle: 'Libellé',
            updateSupplierInvoiceMessage: 'Avez-vous reçu la facture du fournisseur ?',
            updateSupplierInvoiceMessage2: 'Vous n’avez pas reçu la facture du fournisseur ?',
            updateInvoiceLockMessage: 'Souhaitez-vous finaliser la facture ?',
            deleteInvoiceMessage: 'Etes-vous sûr(e) de vouloir supprimer la facture en attente ?',
            deleteInvoice: 'Supprimer la facture',
            invoicedUpdateInvoiceLockMessage: 'Souhaitez-vous passer le statut de la facture en Facture en attente ?',
            payInvoiceSuccess: 'Les factures ont été réglées avec succès !',
            createPrevMonthEndPreMessage: 'Souhaitez-vous générer la préfacturation du mois dernier ?',
            createMonthEndPreMessage: 'Souhaitez-vous générer les factures mensuelles du mois en cours ?',
            createThisMonthPreMessage: 'Souhaitez-vous générer la préfacturation du mois en cours ?',
            invoiceCreatedSuccessfully: 'La facture a été créée avec succès !',
            updateSaleItemSuccess: 'L’article de vente a été mis à jour avec succès !',
            saleItems: 'Article Vente',
            invoiceCreationDate: 'Date création',
            updateRefund: 'Mettre à jour l’avoir',
            updateRefundSuccess: 'L’avoir a été mis à jour avec succès !',
            payRefundSuccess: 'L’avoir a été payé avec succès !',
            for: 'pour',
         },
         tours: {
            editionByDriver: 'Edition par chauffeur',
            printingTourSheets: 'Feuilles de tournée à imprimer',
            contact: 'Contact',
            driver: 'Chauffeur',
         },
         dashboard: {
            finance: 'Finance',
            exploitation: 'Exploitation',
            availableMachine: 'Machines disponibles',
            totalSublease: 'Total Sous-location',
            parcRent: 'Location parc',
            totalOffersOfTheMonth: 'Total Devis du mois',
            mySalesOfTheMonth: 'Mes ventes du mois',
            pendingSubleaseOffers: 'Devis sous-location en attente',
            pendingOffers: 'Devis en attente',
            occupancyRate: 'Taux d’occupation',
            includingReturnPlaned: 'dont le retour planifié',
            dailyAveragePrice: 'Prix moyen journalier',
            rentalVsParc: 'Location/Parc',
            rentalVsParcReturnPlaned: 'Retour planifié',
            dailyRentalRevenue: 'CA Location journalier',
            montlyRentalRevenue: 'CA Location mensuel',
            onlyRentalInvoices: 'seulement les factures location',
            monthlyRevenuePerCustomer: 'CA mensuel par Client',
            totalRevenueFromCustomer: 'CA total par Client',
            dailyRevenue: 'CA journalier',
            onlyRentRevenueByMachineGroupOfParkMachine: 'CA Location par famille machine du parc',
            revenuePerMachineCode: 'CA par code machine',
            revenuePerMachineGroupForSupplier: 'CA par machine sous-location',
            profitabilityAnalysis: 'Analyse de rentabilité',
            transport: 'Transport',
            fuelSales: 'Vente carburant',
            otherSales: 'Autre Vente',
            rentalTurnover: 'CA Location',
            numberOfRentals: 'Nombre de location',
            transportTurnover: 'CA Transport',
            ecoTurnover: 'CA Eco',
            insuranceTurnover: 'CA Assurance',
            fuelTurnover: 'CA Carburant',
            otherSalesTurnover: 'CA Ventes diverses',
            suplierTransporter: 'Fournisseur et Transporteur',
            yourMonthlyTurnover: 'CA Mensuel',
            detailedMonthlyTurnover: 'CA Mensuel détaillé',
            RENTAL_TURNOVER: 'CA Location',
            TRANSPORT_TURNOVER: 'CA Transport',
            INSURANCE_TURNOVER: 'CA Assurance',
            ECO_TURNOVER: 'CA Eco',
            FUEL_TURNOVER: 'CA Carburant',
            OTHER_SALES_TURNOVER: 'CA Ventes diverses',
            INCLUDING_STAGGERED_FUEL_SALES: 'dont les ventes carburant décalées',
            revenueBySalesRepresentatives: 'CA par commercial',
            supplierAndTransporter: 'Fournisseur et Transporteur',
         },
         pdf: {
            malfunctionRecordNo: 'No BI',
            hour: 'Heure',
            report: 'Rapport',
            bdNo: 'No BD',
            contactInfo: 'INFORMATIONS CONTRAT',
            generalCond: 'ETAT GENERAL',
            userManuel: 'Manuel d’utilisateur',
            mileage: 'Kilométrage/Horamètre',
            keys: 'Clé',
            diesel: 'Gasoil',
            userName: 'Utilisateur',
            customerId: 'Numéro client',
            company: 'Société',
            offerNo: 'No Devis',
            rentOffer: 'Devis Location',
            rentOfferPdf1: 'Sous réserve de disponibilité. En cas d’arrêt anticipé les tarifs seront revus selon notre grille tarifaire.',
            rentOfferPdf2:
               'Le travail en hauteur présente des risques, respecter les consignes de sécurité décrite dans le manuel de l’opérateur est fortement recommandé. Il est important de posséder le CACES, d’être habilité et autorisé à utiliser cette machine. Pour une meilleure sécurité, il est à noter la vente d’un harnais antichute, de casques et de chaussures de sécurité.',
            rentOfferPdf3:
               "L'acceptation de la présente proposition par le client implique l'adhésion pleine et entière à nos conditions générales de location figurant ci-joint. Toute condition contraire posée par le client nous sera, à défaut d'acceptation expresse, inopposable, quel que soit le moment où elle aura pu être portée à notre connaissance.",
            deliveryFee: 'Transport aller',
            invoicePdf1:
               '« Règlement à l’ordre de La Banque Postale Leasing & Factoring, à lui adresser directement à Traitement des encaissements TSA 30236 - 93736 BOBIGNY CEDEX 9 – ou par virement au compte La Banque Postale Leasing & Factoring n° RIB BIC CRNPFRP1XXX IBAN FR76 1647 8000 01 00000100447 24. Elle le reçoit par subrogation dans le cadre du contrat de factoring. Elle devra être avisée de toute demande de renseignements ou réclamations. »',
            invoicePdf2:
               'Tout retard de paiement entraînera des pénalités égales à 3 fois le taux d’intérêt légal en vigueur et une indemnité forfaitaire pour frais de recouvrement de 40 euros sera appliquée.',
            purchasePdf1: 'Conditions de paiement : chèque 45 jours fin du mois',
            purchasePdf2: 'Référence commande à rappeler impérativement sur vos BL et factures, autrement aucune facture ne sera traitée.',
            recoveryListHeader: "Liste des matériels en fin de location à aujourd'hui",
            invoicedBeforeTax: 'Débit',
            receivedBeforeTax: 'Crédit',
            customerInvoicesHeader: 'Extrait de compte client',
            start: 'Début',
            end: 'Fin',
            offersBoxText: 'Cachet de l’entreprise : Bon pour accord',
            unitPrice: 'Prix unitaire',
            proformaNo: 'Proforma No',
            proforma: 'Proforma',
            technician: 'Technicien',
            ourBankDetails: 'Nos coordonnées bancaires',
            offer: 'Devis',
            rentOfferPdf4: 'Vous devez effectuer le plein de carburant autrement le gasoil vous sera facturé à 3 euros le litre.',
            workshopObservations: 'Observations atelier',
         },
      },
   },
};
