import { gql } from '@apollo/client';

export const GET_OFFERS = gql`
   query offers($first: Int, $skip: Int, $search: String, $filters: OrderFilter) {
      orderCount(search: $search, filters: $filters)
      orders(first: $first, skip: $skip, search: $search, filters: $filters) {
         id
         orderNumber
         isOffered
         isRefused
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         orderItems {
            id
            category
            vehicleReservation {
               vehicleGroup {
                  id
                  name
               }
               vehicle {
                  id
                  machineCode
                  machineFullCode
               }
            }
         }
      }
   }
`;

export const GET_OFFER_KINDS = gql`
   query orderKinds {
      orderKinds {
         id
         name
      }
   }
`;

export const GET_USERS = gql`
   query users {
      users {
         id
         name
      }
   }
`;

export const GET_CUSTOMERS = gql`
   query customers {
      customers(filters: { isBanned: false, isDeleted: false }) {
         id
         companyName
         ownInsurance
         customerKind {
            name
         }
      }
   }
`;

export const DELETE_OFFERS = gql`
   mutation deleteOffer($ids: [Int!]!) {
      deleteOrders(ids: $ids) {
         success
      }
   }
`;

export const GET_ADDRESSES = gql`
   query adresses($filters: AddressFilter) {
      addresses(filters: $filters) {
         id
         placeName
         receiverName
      }
   }
`;

export const CREATE_OFFER = gql`
   mutation createOrder($data: CreateOrderInput!) {
      createOrder(data: $data) {
         order {
            id
            orderNumber
            isOffered
            isRefused
            isContracted
            orderKind {
               id
               name
            }
            createdAt
            customer {
               id
               companyName
               ownInsurance
            }
            address {
               id
               placeName
            }
            orderItems {
               id
               category
               vehicleReservation {
                  vehicleGroup {
                     id
                     name
                  }
                  vehicle {
                     id
                     machineCode
                     machineFullCode
                  }
               }
            }
         }
      }
   }
`;

export const UPDATE_OFFER = gql`
   mutation updateOrder($data: UpdateOrderInput!) {
      updateOrder(data: $data) {
         order {
            id
            orderNumber
            isOffered
            isRefused
            isContracted
            orderKind {
               id
               name
            }
            createdAt
            customer {
               id
               companyName
               ownInsurance
            }
            address {
               id
               placeName
            }
            orderItems {
               id
               category
               vehicleReservation {
                  vehicleGroup {
                     id
                     name
                  }
                  vehicle {
                     id
                     machineCode
                     machineFullCode
                  }
               }
            }
         }
      }
   }
`;

export const CREATE_OFFER_ITEM = gql`
   mutation createOrderItem($data: CreateOrderItemInput!) {
      createOrderItem(data: $data) {
         orderItem {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               vehicleGroup {
                  id
                  name
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
                  supplierCustomer {
                     id
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               insurancePrice
               quantity
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
               subTotal
               priceKind
            }
         }
      }
   }
`;

export const UPDATE_OFFER_ITEM = gql`
   mutation updateOrderItem($data: UpdateOrderItemInput!) {
      updateOrderItem(data: $data) {
         orderItem {
            id
            category
            description
            quantity
            tax
            price
         }
      }
   }
`;

export const DELETE_OFFER_ITEM = gql`
   mutation deleteOfferItem($ids: [Int!]!) {
      deleteOrderItems(ids: $ids) {
         success
      }
   }
`;

export const GET_ITEM_CATEGORIES = gql`
   query getItemCategories {
      __type(name: "ItemCategory") {
         enumValues {
            name
         }
      }
   }
`;

export const CREATE_OFFER_DOCUMENT = gql`
   mutation createOrderDocument($data: CreateOrderDocumentInput!) {
      createOrderDocument(data: $data) {
         order {
            id
            orderDocuments {
               id
               document {
                  url
                  size
                  name
                  path
               }
            }
         }
      }
   }
`;

export const GET_CITIES = gql`
   query cities {
      cities(filters: { country: 1 }) {
         id
         name
      }
   }
`;

export const CREATE_ADDRESS = gql`
   mutation createAddress($data: CreateAddressInput!) {
      createAddress(data: $data) {
         address {
            id
            placeName
            receiverName
         }
      }
   }
`;

export const CREATE_VEHICLE_RESERVATION = gql`
   mutation createVehicleReservation($data: CreateVehicleReservationInput!) {
      createVehicleReservation(data: $data) {
         vehicleReservation {
            id
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               note
               machineCode
               machineFullCode
               supplierCustomer {
                  id
               }
            }
            beginDate
            endDate
            price
            isEco
            ecoPrice
            isInsurance
            insuranceRate
            insurancePrice
            quantity
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isPickuppByMetaloc
            deliveryFee
            pickupFee
            taxFee
            tax
            discountRate
            subTotal
            priceKind
         }
      }
   }
`;

export const GET_VEHICLE_GROUPS = gql`
   query vehicleGroups($filters: VehicleFilter, $haveVehicles: Boolean) {
      vehicleGroups(filters: $filters, haveVehicles: $haveVehicles) {
         id
         name
      }
   }
`;

export const DELETE_VEHICLE_RESERVATION = gql`
   mutation deleteVehicleReservations($ids: [Int!]!) {
      deleteVehicleReservations(ids: $ids) {
         success
      }
   }
`;

export const UPDATE_VEHICLE_RESERVATION = gql`
   mutation updateVehicleReservation($data: UpdateVehicleReservationInput!) {
      updateVehicleReservation(data: $data) {
         vehicleReservation {
            id
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               note
               machineCode
               machineFullCode
               supplierCustomer {
                  id
               }
            }
            beginDate
            endDate
            price
            isEco
            ecoPrice
            isInsurance
            insuranceRate
            insurancePrice
            quantity
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isPickuppByMetaloc
            deliveryFee
            pickupFee
            taxFee
            tax
            discountRate
            subTotal
            priceKind
         }
      }
   }
`;

export const GET_CUSTOMER_SUPPLIERS = gql`
   query customers {
      customers(filters: { customerKindId: 3, isBanned: false, isDeleted: false }) {
         id
         companyName
      }
   }
`;

export const GET_MACHINES = gql`
   query availableSupplierVehicles($startDate: Date!, $endDate: Date!, $filters: VehicleFilter) {
      availableSupplierVehicles(startDate: $startDate, endDate: $endDate, filters: $filters) {
         id
         machineFullCode
         status
      }
   }
`;

export const CREATE_MACHINE_GROUP = gql`
   mutation createVehicleGroup($data: CreateVehicleGroupInput!) {
      createVehicleGroup(data: $data) {
         vehicleGroup {
            id
            name
         }
      }
   }
`;

export const CREATE_MACHINE = gql`
   mutation createVehicle($data: CreateVehicleInput!) {
      createVehicle(data: $data) {
         vehicle {
            id
            machineFullCode
         }
      }
   }
`;

export const GET_VEHICLE_FUEL_KINDS = gql`
   query vehicleFuelKinds {
      vehicleFuelKinds {
         id
         name
      }
   }
`;

export const GET_VEHICLE_GROUP_PRICE = gql`
   query vehicleGroupPrices($vehicleGroupId: ID, $customerId: ID) {
      vehicleGroupPrices(vehicleGroupId: $vehicleGroupId, customerId: $customerId) {
         id
         currencyId
         dailyPrice
         twoToFivePrice
         sixToFifteenPrice
         gtFifteenPrice
         monthlyPrice
      }
   }
`;

export const GET_OFFER = gql`
   query order($id: ID!) {
      order(id: $id) {
         id
         orderNumber
         isOffered
         isRefused
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
            ownInsurance
         }
         address {
            id
            placeName
         }
         referenceNumber
         description
         privacyDescription
         isFactor
         orderItems {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
               }
               vehicle {
                  id
                  note
                  machineFullCode
                  supplierCustomer {
                     id
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               insurancePrice
               quantity
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
               subTotal
               priceKind
            }
         }
      }
   }
`;

export const UPDATE_DETAIL_OFFER = gql`
   mutation updateOrder($data: UpdateOrderInput!) {
      updateOrder(data: $data) {
         order {
            id
            orderNumber
            isOffered
            isRefused
            isContracted
            orderKind {
               id
               name
            }
            createdAt
            customer {
               id
               companyName
               ownInsurance
            }
            address {
               id
               placeName
            }
            referenceNumber
            description
            privacyDescription
            isFactor
            orderItems {
               id
               orderId
               category
               description
               quantity
               tax
               price
               vehicleReservation {
                  id
                  vehicleGroup {
                     id
                     name
                  }
                  vehicle {
                     id
                     note
                     machineCode
                     machineFullCode
                  }
                  beginDate
                  endDate
                  price
                  isEco
                  ecoPrice
                  isInsurance
                  insuranceRate
                  insurancePrice
                  quantity
                  isIncludingSaturdays
                  isIncludingSundays
                  isIncludingPublicHolidays
                  isPickuppByMetaloc
                  deliveryFee
                  pickupFee
                  taxFee
                  tax
                  discountRate
                  subTotal
                  priceKind
               }
            }
         }
      }
   }
`;

export const UPDATE_DETAIL_OFFER_ITEM = gql`
   mutation updateOrderItem($data: UpdateOrderItemInput!) {
      updateOrderItem(data: $data) {
         orderItem {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               vehicleGroup {
                  id
                  name
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               insurancePrice
               quantity
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
               subTotal
               priceKind
            }
         }
      }
   }
`;

export const GET_OFFER_DOCUMENTS = gql`
   query orderDocuments($orderId: ID!) {
      orderDocuments(orderId: $orderId) {
         id
         document {
            url
            size
            name
            path
         }
      }
   }
`;

export const DELETE_OFFER_DOCUMENT = gql`
   mutation deleteOrderDocuments($ids: [Int!]!) {
      deleteOrderDocuments(ids: $ids) {
         success
      }
   }
`;

export const GET_ADDRESS = gql`
   query getAddress($id: ID!) {
      address(id: $id) {
         id
         addressKindId
         city
         country
         customerId
         placeName
         receiverName
         districtName
         postCode
         address
         addressContinuation
      }
   }
`;

export const UPDATE_ADDRESS = gql`
   mutation updateAddress($data: UpdateAddressInput!) {
      updateAddress(data: $data) {
         address {
            id
            addressKindId
            city
            country
            customerId
            placeName
            receiverName
            districtName
            postCode
            address
            addressContinuation
         }
      }
   }
`;

export const DUPLICATE_OFFER = gql`
   mutation duplicateOrder($id: ID!) {
      duplicateOrder(id: $id) {
         order {
            id
            orderNumber
            isOffered
            isRefused
            isContracted
            orderKind {
               id
               name
            }
            createdAt
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            orderItems {
               id
               category
               vehicleReservation {
                  subTotal
                  priceKind
                  vehicleGroup {
                     id
                     name
                  }
                  vehicle {
                     id
                     machineCode
                     machineFullCode
                  }
               }
            }
         }
      }
   }
`;

export const GET_OFFER_STATISTICS = gql`
   query orderStatistics {
      orderStatistics
   }
`;

export const GET_PUBLIC_HOLIDAYS = gql`
   query publicHolidays {
      publicHolidays {
         id
         day
         month
      }
   }
`;
