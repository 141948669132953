import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from 'primereact/sidebar';

import { getRemindersByUserId } from '../store/apis/Customers';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { setUnreadNotifications, setShowReminders } from '../store/slices/remindersSlice';
import { t } from 'i18next';
import moment from 'moment';

const Reminders = () => {
   const dispatch = useDispatch();
   const { token } = useSelector((state) => state.user);
   const { user } = useSelector((state) => state.user);
   const { reminderButtonClicked, reminders, showReminders, date } = useSelector((state) => state.reminder);

   useEffect(() => {
      if (!token) return;
      dispatch(getRemindersByUserId(user.id, reminderButtonClicked, date)).then((res) => {});
   }, [token]);

   const customizedContent = (item) => {
      return (
         <div className="p-0">
            <small className="text-color-secondary">{moment(item.date).format('DD/MM/YYYY')}</small>
            <Card className="p-0 mt-2 mb-2 ">
               <div className="p-0 mb-2" style={{ fontWeight: 'bold' }}>
                  {item.description.length > 100 ? item.description.slice(0, 40) + '...' : item.description}
               </div>
               <small>{`${item.customer.customerKind.name}:${item.customer.companyName}`}</small>
            </Card>
         </div>
      );
   };

   return (
      <div className="flex items-center justify-between w-full h-10">
         <div className="flex items-center">
            <Sidebar
               className="w-[350px]"
               visible={showReminders}
               position="right"
               onHide={() => {
                  dispatch(setShowReminders(false));
                  dispatch(setUnreadNotifications(0));
               }}
            >
               <div className="mb-5">{t('global.reminders')}</div>
               <div className="card ">
                  <Timeline className="p-0 m-0" value={reminders} align="left" content={customizedContent} />
               </div>
            </Sidebar>
         </div>
      </div>
   );
};

export default Reminders;
