import React from "react";

const Loading = () => {
	return (
		<div className="fixed top-0 left-0 h-full w-full bg-slate-100 opacity-50 z-[9999]">
			<div className="h-full w-full flex justify-center items-center z-50">
				<span>Loading...</span>
			</div>
		</div>
	);
};

export default Loading;
