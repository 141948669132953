import TableComponent from '../../../components/TableComponent';
import { Button } from 'primereact/button';

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   createVehicleGroupPrice,
   deleteVehicleGroupPrice,
   getVehicleGroupPrices,
   getVehicleGroups,
   updateVehicleGroupPrice,
} from '../../../store/apis/Customers';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import toast from 'react-hot-toast';
import MachineGroupPricesPDF from '../../../components/PDFS/MachineGroupPricesPDF';

const MachineGroupPrices = ({ customerData }) => {
   const dispatch = useDispatch();
   const { vehicleGroupPrices } = useSelector((state) => state.customer);
   const [vehicleGroupPricesDialog, setVehicleGroupPricesDialog] = useState(false);
   const [itemsInputs, setItemsInputs] = useState({});
   const [vehicleGroups, setVehicleGroups] = useState([]);
   const [errors, setErrors] = useState({});

   useEffect(() => {
      if (customerData.id) {
         dispatch(getVehicleGroupPrices(customerData.id));
         dispatch(getVehicleGroups()).then((response) => {
            setVehicleGroups(response);
         });
      }
   }, [customerData]);

   const resetItemsInputs = () => {
      setItemsInputs({
         vehicleGroupId: '',
         dailyPrice: '',
         twoToFivePrice: '',
         sixToFifteenPrice: '',
         gtFifteenPrice: '',
         monthlyPrice: '',
      });
   };

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      if (name === 'dailyPrice' || name === 'twoToFivePrice' || name === 'sixToFifteenPrice' || name === 'gtFifteenPrice' || name === 'monthlyPrice') {
         const onlyNums = value.replace(/[^0-9]/g, '');
         setItemsInputs({ ...itemsInputs, [name]: onlyNums });
      } else {
         setItemsInputs({ ...itemsInputs, [name]: value });
         updateErrors(name, value);
      }
   };

   const handleFocus = (name) => {
      if (itemsInputs[name] === 0) {
         setItemsInputs({ ...itemsInputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const inputs = ['vehicleGroupId'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!itemsInputs[input]) {
            updateErrors(input, itemsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };
   const saveItems = async () => {
      if (!validateForm()) return;
      const data = {
         vehicleGroupId: itemsInputs.vehicleGroupId,
         customerId: customerData.id,
         dailyPrice: parseFloat(itemsInputs.dailyPrice),
         twoToFivePrice: parseFloat(itemsInputs.twoToFivePrice),
         sixToFifteenPrice: parseFloat(itemsInputs.sixToFifteenPrice),
         gtFifteenPrice: parseFloat(itemsInputs.gtFifteenPrice),
         monthlyPrice: parseFloat(itemsInputs.monthlyPrice),
         currencyId: 2,
      };
      dispatch(createVehicleGroupPrice(data)).then(() => {
         setVehicleGroupPricesDialog(false);
         resetItemsInputs();
      });
   };

   const removeItem = (item) => {
      dispatch(deleteVehicleGroupPrice([+item.id])).then(() => {
         toast.success(t('pages.customers.deleteGroupPricesSuccess'));
      });
   };

   const textEditor = (options) => {
      return (
         <InputText
            type="text"
            value={options.value}
            onChange={(e) => {
               const onlyNums = e.target.value.replace(/[^0-9]/g, '');
               options.editorCallback(onlyNums);
            }}
            onKeyDown={(e) => e.stopPropagation()}
         />
      );
   };

   const dropdownEditor = (options) => {
      return (
         <Dropdown
            filter
            options={vehicleGroups}
            value={options.value}
            onChange={(e) => {
               options.editorCallback(e.value);
            }}
            optionLabel="name"
            optionValue="id"
            className="w-full input-detail"
            showClear
         />
      );
   };

   const onRowEditComplete = (e) => {
      const data = {
         id: e.newData.id,
         dailyPrice: parseFloat(e.newData.dailyPrice),
         twoToFivePrice: parseFloat(e.newData.twoToFivePrice),
         sixToFifteenPrice: parseFloat(e.newData.sixToFifteenPrice),
         gtFifteenPrice: parseFloat(e.newData.gtFifteenPrice),
         monthlyPrice: parseFloat(e.newData.monthlyPrice),
         /* vehicleGroupId: e.newData.machineGroup, */
      };
      dispatch(updateVehicleGroupPrice(data)).then(() => {
         toast.success(t('pages.customers.updateGroupPricesSuccess'));
      });
   };

   const tableColumns = [
      {
         name: 'machineGroup',
         header: t('global.table.machineGroup'),
         field: 'machineGroup',
         body: (rowData) => {
            return <span>{rowData.vehicleGroup.name}</span>;
         },
         /* editor: (options) => dropdownEditor(options), */
      },
      {
         name: 'description',
         header: t('global.table.description'),
         field: 'description',
         body: (rowData) => {
            return <span>{rowData.vehicleGroup.description}</span>;
         },
      },
      {
         name: 'price1d',
         header: t('global.table.price1d'),
         field: 'dailyPrice',
         editor: (options) => textEditor(options),
         body: (rowData) => {
            return <span>€{parseFloat(rowData.dailyPrice).toFixed(2)}</span>;
         },
      },
      {
         name: 'price2dTo5d',
         header: t('global.table.price2dTo5d'),
         field: 'twoToFivePrice',
         editor: (options) => textEditor(options),
         body: (rowData) => {
            return <span>€{parseFloat(rowData.twoToFivePrice).toFixed(2)}</span>;
         },
      },
      {
         name: 'price6dTo15d',
         header: t('global.table.price6dTo15d'),
         field: 'sixToFifteenPrice',
         editor: (options) => textEditor(options),
         body: (rowData) => {
            return <span>€{parseFloat(rowData.sixToFifteenPrice).toFixed(2)}</span>;
         },
      },
      {
         name: 'price15d',
         header: t('global.table.price15d'),
         field: 'gtFifteenPrice',
         editor: (options) => textEditor(options),
         body: (rowData) => {
            return <span>€{parseFloat(rowData.gtFifteenPrice).toFixed(2)}</span>;
         },
      },
      {
         name: 'monthlyPrice',
         header: t('global.table.monthlyPrice'),
         field: 'monthlyPrice',
         editor: (options) => textEditor(options),
         body: (rowData) => {
            return <span>€{parseFloat(rowData.monthlyPrice).toFixed(2)}</span>;
         },
      },
      {
         name: 'rowEditor',
         rowEditor: true,
      },
      {
         name: 'actions',
         body: (rowData) => {
            return (
               <div className="flex items-center justify-center">
                  <Button className="ml-2" icon="pi pi-trash" severity="danger" onClick={() => removeItem(rowData)} />
               </div>
            );
         },
      },
   ];

   return (
      <>
         <div className="flex justify-between mt-3">
            <InputText placeholder={t('global.search')} />
            <div>
               <Button icon="pi pi-plus" severity="success" onClick={() => setVehicleGroupPricesDialog(true)} />
               <MachineGroupPricesPDF data={vehicleGroupPrices} />
            </div>
         </div>
         <div className="mt-4">
            <TableComponent columns={tableColumns} data={vehicleGroupPrices} editMode="row" onRowEditComplete={onRowEditComplete} />
         </div>
         {/* Create Vehicle Group Prices */}
         <Dialog
            header={t('global.table.addNewMachineGroupPrice')}
            visible={vehicleGroupPricesDialog}
            style={{ width: '50vw' }}
            onHide={() => {
               setVehicleGroupPricesDialog(false);
               resetItemsInputs();
            }}
         >
            <div className="grid">
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.offers.category')}</label>
                  <Dropdown
                     filter
                     options={vehicleGroups}
                     value={itemsInputs.vehicleGroupId}
                     onChange={(e) => handleInputChange(e, 'vehicleGroupId')}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
                  {errors.vehicleGroupId && <small className="p-error">{errors.vehicleGroupId}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.dailyPrice')}</label>
                  <InputText
                     value={itemsInputs.dailyPrice}
                     onChange={(e) => handleInputChange(e, 'dailyPrice')}
                     onFocus={() => handleFocus('dailyPrice')}
                     className="w-full input-detail"
                  />
                  {errors.dailyPrice && <small className="p-error">{errors.dailyPrice}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.twoToFivePrice')}</label>
                  <InputText
                     value={itemsInputs.twoToFivePrice}
                     onChange={(e) => handleInputChange(e, 'twoToFivePrice')}
                     onFocus={() => handleFocus('twoToFivePrice')}
                     className="w-full input-detail"
                  />
                  {errors.twoToFivePrice && <small className="p-error">{errors.twoToFivePrice}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.sixToFifteenPrice')}</label>
                  <InputText
                     value={itemsInputs.sixToFifteenPrice}
                     onChange={(e) => handleInputChange(e, 'sixToFifteenPrice')}
                     onFocus={() => handleFocus('sixToFifteenPrice')}
                     className="w-full input-detail"
                  />
                  {errors.sixToFifteenPrice && <small className="p-error">{errors.sixToFifteenPrice}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.gtFifteenPrice')}</label>
                  <InputText
                     value={itemsInputs.gtFifteenPrice}
                     onChange={(e) => handleInputChange(e, 'gtFifteenPrice')}
                     onFocus={() => handleFocus('gtFifteenPrice')}
                     className="w-full input-detail"
                  />
                  {errors.gtFifteenPrice && <small className="p-error">{errors.gtFifteenPrice}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.monthlyPrice')}</label>
                  <InputText
                     value={itemsInputs.monthlyPrice}
                     onChange={(e) => handleInputChange(e, 'monthlyPrice')}
                     onFocus={() => handleFocus('monthlyPrice')}
                     className="w-full input-detail"
                  />
                  {errors.monthlyPrice && <small className="p-error">{errors.monthlyPrice}</small>}
               </div>
               <div className="mt-3 col-12">
                  <Button label={t('global.save')} className="w-full" onClick={() => saveItems()} severity="success" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default MachineGroupPrices;
