import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../components/TableComponent';
import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch } from 'react-redux';
import { createOffer, createOfferItem, deleteOfferItem, getItemCategories, updateOfferItem } from '../../../../store/apis/OffersApi';
import toast from 'react-hot-toast';

const SaleItems = (props) => {
   const {
      informationsInputs,
      setInformationsInputs,
      items,
      setItems,
      createdOrderId,
      setCreatedOrderId,
      setAddNewOfferVisible,
      setSupplierMachines,
      setInventoryMachines,
      setActiveIndex,
   } = props;
   const dispatch = useDispatch();
   const [createNewSaleDialog, setCreateNewSaleDialog] = useState(false);
   const [itemsInputs, setItemsInputs] = useState({
      orderId: null,
      description: '',
      quantity: 1,
      price: 0,
      currencyId: 2,
   });
   const [categories, setCategories] = useState([]);
   const [totalPrice, setTotalPrice] = useState(0);
   const [totalTaxFee, setTotalTaxFee] = useState(0);
   const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);
   const [actionType, setActionType] = useState('create');
   const [selectedRowData, setSelectedRowData] = useState(null);
   const [errors, setErrors] = useState({});

   useEffect(() => {
      dispatch(getItemCategories()).then((res) => setCategories(res.filter((c) => c.name !== 'INV' && c.name !== 'SUP')));
   }, []);

   useEffect(() => {
      setTotalPrice(items.reduce((acc, item) => acc + +item.price * +item.quantity, 0));
      setTotalTaxFee(items.reduce((acc, item) => acc + (+item.price * +item.quantity * +item.tax) / 100, 0));
      setTotalPriceWithTax(items.reduce((acc, item) => acc + (+item.price * +item.quantity + (+item.price * +item.quantity * +item.tax) / 100), 0));
   }, [items]);

   useEffect(() => {
      if (selectedRowData && createNewSaleDialog) {
         setItemsInputs({
            ...itemsInputs,
            description: selectedRowData.description,
            quantity: selectedRowData.quantity,
            price: selectedRowData.price,
            category: selectedRowData.category,
         });
      }
   }, [selectedRowData, createNewSaleDialog]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      if (name === 'price') {
         const onlyNums = value.replace(/[^0-9]/g, '');
         setItemsInputs({ ...itemsInputs, [name]: onlyNums });
         updateErrors(name, onlyNums);
      } else {
         setItemsInputs({ ...itemsInputs, [name]: value });
         updateErrors(name, value);
      }
   };

   const handleFocus = (name) => {
      if (itemsInputs[name] === 0) {
         setItemsInputs({ ...itemsInputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const inputs = ['description', 'price', 'category'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!itemsInputs[input]) {
            updateErrors(input, itemsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const tableColumns = [
      {
         name: 'category',
         header: t('global.table.type'),
         body: (rowData) => {
            return <span>{rowData.category}</span>;
         },
         footer: t('pages.offers.total').toUpperCase(),
      },
      {
         name: 'explanation',
         header: t('global.table.explanation'),
         body: (rowData) => {
            return <span>{rowData.description}</span>;
         },
      },
      {
         name: 'quantity',
         header: t('pages.offers.quantity'),
         body: (rowData) => {
            return <span>{rowData.quantity}</span>;
         },
      },
      {
         name: 'tax',
         header: t('pages.offers.tax'),
         body: (rowData) => {
            return <span>%{parseInt(rowData.tax)}</span>;
         },
      },
      {
         name: 'price',
         header: t('pages.offers.price'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData.price * rowData.quantity).toFixed(2)}</span>;
         },
         footer: '€' + totalPrice.toFixed(2),
      },
      {
         name: 'taxFee',
         header: t('pages.offers.taxFee'),
         body: (rowData) => {
            return <span>€{((rowData.price * rowData.quantity * rowData.tax) / 100).toFixed(2)}</span>;
         },
         footer: '€' + totalTaxFee.toFixed(2),
      },
      {
         name: 'totalPrice',
         header: t('pages.offers.totalPrice'),
         body: (rowData) => {
            return <span>€{(+(rowData.price * rowData.quantity) + (+(rowData.price * rowData.quantity) * +rowData.tax) / 100).toFixed(2)}</span>;
         },
         footer: '€' + totalPriceWithTax.toFixed(2),
      },
      {
         name: 'actions',
         body: (rowData) => {
            return (
               <div className="flex items-center justify-center">
                  <Button
                     className="mx-1"
                     icon="pi pi-pencil"
                     severity="warning"
                     onClick={() => {
                        setActionType('update');
                        setSelectedRowData(rowData);
                        setCreateNewSaleDialog(true);
                     }}
                  />
                  <Button className="mx-1" icon="pi pi-trash" severity="danger" onClick={() => removeItem(rowData)} />
               </div>
            );
         },
      },
   ];
   const resetItemInputs = () => ({
      orderId: null,
      description: '',
      quantity: 1,
      price: 0,
      currencyId: 2,
   });

   const handleCreateOfferItem = async (orderId) => {
      const response = await dispatch(createOfferItem({ ...itemsInputs, orderId }));
      const newItems = [...items, response];
      setItems(newItems);
      setItemsInputs(resetItemInputs());
      setCreateNewSaleDialog(false);
   };

   const saveItems = async () => {
      if (!validateForm()) return;
      if (actionType === 'create') {
         if (!items.length && !createdOrderId) {
            const res = await dispatch(createOffer(informationsInputs));
            setCreatedOrderId(res.id);
            await handleCreateOfferItem(res.id);
         } else {
            await handleCreateOfferItem(createdOrderId);
         }
      } else {
         const updateData = {
            id: selectedRowData.id,
            description: itemsInputs.description,
            quantity: itemsInputs.quantity,
            price: itemsInputs.price,
            category: itemsInputs.category,
         };
         const response = await dispatch(updateOfferItem(updateData));
         if (response) {
            const newItems = items.map((item) => (item.id === response.id ? response : item));
            setItems(newItems);
            setItemsInputs(resetItemInputs());
            setSelectedRowData(null);
            setCreateNewSaleDialog(false);
         }
      }
   };

   const removeItem = (item) => {
      dispatch(deleteOfferItem([+item.id])).then(() => {
         const newItems = items.filter((i) => i.id !== item.id);
         setItems(newItems);
      });
   };

   const resetInputs = () => {
      toast.success(t('pages.offers.createOfferMessage'));
      setInformationsInputs({
         orderKindId: 1,
         customerId: null,
         addressId: null,
         referenceNumber: '',
         description: '',
         privacyDescription: '',
         isFactor: false,
      });
      setInventoryMachines([]);
      setSupplierMachines([]);
      setItems([]);
      setActiveIndex(0);
      setCreatedOrderId(null);
      setAddNewOfferVisible(false);
   };

   const handleSubmit = () => {
      resetInputs();
   };

   return (
      <>
         <div className="flex justify-end">
            <Button
               label={t('pages.offers.createANewSale')}
               className="mb-4 me-4"
               onClick={() => {
                  setActionType('create');
                  setSelectedRowData(null);
                  setCreateNewSaleDialog(true);
               }}
            />
         </div>
         <div>
            <TableComponent data={items || []} columns={tableColumns} size="small" />
         </div>
         <Dialog
            header={actionType === 'create' ? t('pages.offers.createANewSale') : t('pages.offers.updateSale')}
            visible={createNewSaleDialog}
            onHide={() => {
               setItemsInputs(resetItemInputs());
               setSelectedRowData(null);
               setCreateNewSaleDialog(false);
            }}
            className="w-[40vw]"
         >
            <div className="grid">
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.category')}</label>
                  <Dropdown
                     filter
                     options={categories}
                     value={itemsInputs.category}
                     onChange={(e) => handleInputChange(e, 'category')}
                     optionLabel="name"
                     optionValue="name"
                     className="w-full input-detail"
                     showClear
                  />
                  {errors.category && <small className="p-error">{errors.category}</small>}
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('global.table.explanation')}</label>
                  <InputText value={itemsInputs.description} onChange={(e) => handleInputChange(e, 'description')} className="w-full input-detail" />
                  {errors.description && <small className="p-error">{errors.description}</small>}
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.quantity')}</label>
                  <InputNumber
                     value={itemsInputs.quantity}
                     min={1}
                     onValueChange={(e) => setItemsInputs({ ...itemsInputs, quantity: e.value })}
                     showButtons
                     buttonLayout="horizontal"
                     className="w-full input-detail"
                     decrementButtonClassName="p-button-secondary"
                     incrementButtonClassName="p-button-secondary"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                  />
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.price')}</label>
                  <InputText
                     value={itemsInputs.price}
                     onChange={(e) => handleInputChange(e, 'price')}
                     onFocus={() => handleFocus('price')}
                     className="w-full input-detail"
                  />
                  {errors.price && <small className="p-error">{errors.price}</small>}
               </div>
               <div className="mt-3 col-12">
                  <Button
                     label={actionType === 'create' ? t('global.save') : t('global.update')}
                     className="w-full"
                     onClick={() => saveItems()}
                     severity="success"
                  />
               </div>
            </div>
         </Dialog>
         <div className="flex justify-between mt-4">
            <Button label={t('global.prev')} icon="pi pi-chevron-left" onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
            <Button label={t('global.saveAndFinish')} icon="pi pi-chevron-right" iconPos="right" onClick={handleSubmit} disabled={props.activeIndex === 2} />
         </div>
      </>
   );
};

export default SaleItems;
