import client from '../../graphql/apollo.config';
import {
   CREATE_CONTRACT_DOCUMENT,
   CREATE_CONTRACT_ITEM,
   CREATE_MACHINE,
   CREATE_MACHINE_GROUP,
   CREATE_NEW_TRANSPORTER_ORDER,
   CREATE_ORDER_ITEM,
   CREATE_SUPPLIER_TRANSPORTER_ORDER,
   CREATE_TRANSPORTER_ORDER_ITEM,
   DELETE_CONTRACT,
   DELETE_CONTRACTS,
   DELETE_CONTRACT_DOCUMENT,
   DELETE_CONTRACT_ITEM,
   DELETE_SUPPLIER_TRANSPORTER_ORDER,
   DUPLICATE_CONTRACT,
   EXCHANGE_MACHINE,
   GET_CONTRACT,
   GET_CONTRACTS,
   GET_CONTRACT_DOCUMENTS,
   GET_CONTRACT_KINDS,
   GET_CUSTOMERS,
   GET_CUSTOMER_SUPPLIERS,
   GET_ITEM_CATEGORIES,
   GET_MACHINES,
   GET_USERS,
   GET_VEHICLE_FUEL_KINDS,
   GET_VEHICLE_GROUPS,
   GET_VEHICLE_GROUP_PRICE,
   UPDATE_CONTRACT,
   UPDATE_CONTRACT_ITEM,
   UPDATE_ORDER_ITEM,
   UPDATE_SUPPLIER_TRANSPORTER_ORDER,
   UPDATE_TRANSPORTER_ORDER,
   UPDATE_TRANSPORTER_ORDER_ITEM,
   UPDATE_VEHICLE_RESERVATION,
} from '../../graphql/contracts';
import { GET_ADDRESSES } from '../../graphql/contracts';
import {
   addContractDocument,
   addContractItem,
   duplicateContract,
   handleUpdateContractItem,
   handleUpdateVehicleReservation,
   removeContractDocument,
   removeContractItem,
   removeDeletedContract,
   removeDeletedContracts,
   removeDeletedSupplierTransporterOrder,
   setContract,
   setContractCount,
   setContractDocuments,
   setContracts,
   setUpdatedContract,
} from '../slices/contractsSlice';

import { removeRequest, setRequest } from '../slices/sharedSlice';

export const getContracts =
   ({ first, skip, search, filters }) =>
   async (dispatch) => {
      dispatch(setRequest('getContracts'));
      return client
         .query({
            query: GET_CONTRACTS,
            variables: {
               ...(first !== undefined && skip !== undefined && { first }),
               ...(first !== undefined && skip !== undefined && { skip }),
               ...(search !== '' && { search }),
               ...(filters && { filters }),
            },
         })
         .then((res) => {
            dispatch(setContracts(res.data.orders));
            dispatch(setContractCount(res.data.orderCount));
            return true;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('getContracts'));
         });
   };

export const getContractKinds = () => async (dispatch) => {
   return client
      .query({
         query: GET_CONTRACT_KINDS,
      })
      .then((res) => {
         return res.data.orderKinds;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getUsers = () => async (dispatch) => {
   return client
      .query({
         query: GET_USERS,
      })
      .then((res) => {
         return res.data.users;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const removeContract = (id) => async (dispatch) => {
   dispatch(setRequest('deleteOrders'));
   return client
      .mutate({
         mutation: DELETE_CONTRACT,
         variables: {
            data: {
               id,
               isContracted: false,
            },
         },
      })
      .then(async (res) => {
         await dispatch(removeDeletedContract(id));
         return true;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('deleteOrders'));
      });
};

export const updateContract = (data) => async (dispatch) => {
   dispatch(setRequest('updateContract'));
   return client
      .mutate({
         mutation: UPDATE_CONTRACT,
         variables: {
            data,
         },
      })
      .then(async (res) => {
         await dispatch(setUpdatedContract(res.data.updateOrder.order));
         return res.data.updateOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateContract'));
      });
};

export const getContract = (id) => async (dispatch) => {
   dispatch(setRequest('getContract'));
   return client
      .query({
         query: GET_CONTRACT,
         variables: {
            id,
         },
      })
      .then((res) => {
         dispatch(setContract(res.data.order));
         console.log(res.data.order);
         return true;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getContract'));
      });
};

export const createNewTransporterOrder = (data) => async (dispatch) => {
   dispatch(setRequest('createNewTransporterOrder'));
   return client
      .mutate({
         mutation: CREATE_NEW_TRANSPORTER_ORDER,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createTransporterOrder.transporterOrder;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createNewTransporterOrder'));
      });
};

export const updateTransporterOrder = (data) => async (dispatch) => {
   dispatch(setRequest('updateTransporterOrder'));
   return client
      .mutate({
         mutation: UPDATE_TRANSPORTER_ORDER,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.updateTransporterOrder.transporterOrder;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateTransporterOrder'));
      });
};

export const createTransporterOrderItem = (data) => async (dispatch) => {
   dispatch(setRequest('createOrderItem'));
   return client
      .mutate({
         mutation: CREATE_TRANSPORTER_ORDER_ITEM,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createTransporterOrderItem.transporterOrderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createOrderItem'));
      });
};

export const updateTransporterOrderItem = (data) => async (dispatch) => {
   dispatch(setRequest('updateOrderItem'));
   return client
      .mutate({
         mutation: UPDATE_TRANSPORTER_ORDER_ITEM,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.updateTransporterOrderItem.transporterOrderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateOrderItem'));
      });
};

export const getAddresses = (filters) => async (dispatch) => {
   dispatch(setRequest('getAddresses'));
   return client
      .query({
         query: GET_ADDRESSES,
         variables: {
            filters: { ...filters, isMainOrBillingAddress: false },
         },
      })
      .then((res) => {
         return res.data.addresses;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getAddresses'));
      });
};

export const getCustomers = () => async (dispatch) => {
   return client
      .query({
         query: GET_CUSTOMERS,
      })
      .then((res) => {
         return res.data.customers;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getContractDocuments = (orderId) => async (dispatch) => {
   dispatch(setRequest('getContractDocuments'));
   return client
      .query({
         query: GET_CONTRACT_DOCUMENTS,
         variables: {
            orderId,
         },
      })
      .then((res) => {
         dispatch(setContractDocuments(res.data.orderDocuments));
         return res.data.orderDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getContractDocuments'));
      });
};

export const createContractDocument = (data) => async (dispatch) => {
   dispatch(setRequest('createContractDocument'));
   return client
      .mutate({
         mutation: CREATE_CONTRACT_DOCUMENT,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(setContractDocuments(res.data.createOrderDocument.order.orderDocuments));
         return res.data.createOrderDocument.orderDocument;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createContractDocument'));
      });
};

export const deleteContractDocuments = (ids) => async (dispatch) => {
   dispatch(setRequest('deleteContractDocuments'));
   return client
      .mutate({
         mutation: DELETE_CONTRACT_DOCUMENT,
         variables: {
            ids,
         },
      })
      .then((res) => {
         dispatch(removeContractDocument(ids[0]));
         return res.data.deleteOrderDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('deleteContractDocuments'));
      });
};

export const deleteDetailContractItem = (data) => async (dispatch) => {
   dispatch(setRequest('DELETE_CONTRACT_ITEM'));
   return client
      .mutate({
         mutation: DELETE_CONTRACT_ITEM,
         variables: {
            ids: [+data.orderItemId],
         },
      })
      .then(() => {
         dispatch(removeContractItem(data));
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_CONTRACT_ITEM'));
      });
};

export const updateContractItem = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_CONTRACT_ITEM'));
   return client
      .mutate({
         mutation: UPDATE_CONTRACT_ITEM,
         variables: {
            data: data && data.updateData ? data.updateData : data,
         },
      })
      .then((res) => {
         if (data.updateData) {
            const updateData = {
               orderId: data.orderId,
               orderItemId: data.updateData.id,
               orderItem: res.data.updateOrderItem.orderItem,
            };
            dispatch(handleUpdateContractItem(updateData));
         }
         return res.data.updateOrderItem.orderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_CONTRACT_ITEM'));
      });
};

export const getItemCategories = () => async () => {
   return client
      .query({
         query: GET_ITEM_CATEGORIES,
      })
      .then((res) => {
         return res.data.__type.enumValues;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const updateVehicleReservation = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION,
         variables: {
            data: data && data.updateData ? data.updateData : data,
         },
      })
      .then((res) => {
         const updateData = {
            orderId: data.orderId,
            orderItemId: data.orderItemId,
            vehicleReservation: res.data.updateVehicleReservation.vehicleReservation,
         };
         dispatch(handleUpdateVehicleReservation(updateData));
         return res.data.updateVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_RESERVATION'));
      });
};

export const getVehicleGroupPrices = (vehicleGroupId, customerId) => async () => {
   return client
      .query({
         query: GET_VEHICLE_GROUP_PRICE,
         variables: {
            vehicleGroupId,
            customerId,
         },
      })
      .then((res) => {
         return res.data.vehicleGroupPrices;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createMachineGroup = (data) => async () => {
   return client
      .mutate({
         mutation: CREATE_MACHINE_GROUP,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicleGroup.vehicleGroup;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getVehicleGroups = (filters) => async () => {
   return client
      .query({
         query: GET_VEHICLE_GROUPS,
         variables: {
            filters,
            haveVehicles: true,
         },
      })
      .then((res) => {
         return res.data.vehicleGroups;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createContractItem = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_CONTRACT_ITEM'));
   return client
      .mutate({
         mutation: CREATE_CONTRACT_ITEM,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addContractItem(res.data.createOrderItem.orderItem));
         return res.data.createOrderItem.orderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_CONTRACT_ITEM'));
      });
};

export const getVehicleFuelKinds = () => async () => {
   return client
      .query({
         query: GET_VEHICLE_FUEL_KINDS,
      })
      .then((res) => {
         return res.data.vehicleFuelKinds;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getMachines = (filters) => async () => {
   return client
      .query({
         query: GET_MACHINES,
         variables: {
            filters,
         },
      })
      .then((res) => {
         return res.data.vehicles;
      })
      .catch((err) => {
         console.log(err);
      });
};
export const getCustomerSuppliers = () => async () => {
   return client
      .query({
         query: GET_CUSTOMER_SUPPLIERS,
      })
      .then((res) => {
         return res.data.customers;
      })
      .catch((err) => {
         console.log(err);
      });
};
export const createMachine = (data) => async () => {
   return client
      .mutate({
         mutation: CREATE_MACHINE,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicle.vehicle;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const exchangeMachine =
   ({ orderId, orderItemId, data }) =>
   async (dispatch) => {
      dispatch(setRequest('exchangeMachine'));
      return client
         .mutate({
            mutation: EXCHANGE_MACHINE,
            variables: {
               data,
            },
         })
         .then((res) => {
            dispatch(handleUpdateVehicleReservation({ orderId, orderItemId, vehicleReservation: res.data.exchangeVehicle.vehicleReservation }));
            return res.data.exchangeVehicle.vehicleReservation;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('exchangeMachine'));
         });
   };

export const createSupplierTransporterOrder = (data) => async (dispatch) => {
   dispatch(setRequest('createSupplierTransporterOrder'));
   return client
      .mutate({
         mutation: CREATE_SUPPLIER_TRANSPORTER_ORDER,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createSupplierTransporterOrder.supplierTransporterOrder;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createSupplierTransporterOrder'));
      });
};

export const updateSupplierTransporterOrder = (data) => async (dispatch) => {
   dispatch(setRequest('updateSupplierTransporterOrder'));
   return client
      .mutate({
         mutation: UPDATE_SUPPLIER_TRANSPORTER_ORDER,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.updateSupplierTransporterOrder.supplierTransporterOrder;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateSupplierTransporterOrder'));
      });
};

export const deleteSupplierTransporterOrder =
   ({ orderId, supplierTransporterOrderId }) =>
   async (dispatch) => {
      dispatch(setRequest('deleteSupplierTransporterOrder'));
      return client
         .mutate({
            mutation: DELETE_SUPPLIER_TRANSPORTER_ORDER,
            variables: {
               ids: [+supplierTransporterOrderId],
            },
         })
         .then((res) => {
            dispatch(removeDeletedSupplierTransporterOrder({ orderId, supplierTransporterOrderId }));
            return true;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('deleteSupplierTransporterOrder'));
         });
   };

export const duplicateSelectedContract = (id) => async (dispatch) => {
   dispatch(setRequest('duplicateContract'));
   return client
      .mutate({
         mutation: DUPLICATE_CONTRACT,
         variables: {
            id,
         },
      })
      .then((res) => {
         dispatch(duplicateContract(res.data.duplicateOrder.order));
         return res.data.duplicateOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('duplicateContract'));
      });
};
