import TableComponent from '../../../components/TableComponent';

import { t } from 'i18next';

const Transfers = (props) => {
	const tableColumns = [
		{
			name: 'date',
			header: t('global.table.date'),
			field: 'date',
		},
		{
			name: 'user',
			header: t('global.table.user'),
			field: 'user',
		},
		{
			name: 'machineCode',
			header: t('global.table.machineCode'),
			field: 'machineCode',
		},
		{
			name: 'departureAddress',
			header: t('global.table.departureAddress'),
			field: 'departureAddress',
		},
		{
			name: 'arrivalAddress',
			header: t('global.table.arrivalAddress'),
			field: 'arrivalAddress',
		},
		{
			name: 'requestor',
			header: t('global.table.requestor'),
			field: 'requestor',
		},
		{
			name: 'contract',
			header: t('global.table.contract'),
			field: 'contract',
		},
		{
			name: 'rentType',
			header: t('global.table.rentType'),
			field: 'rentType',
		},
	];

	return (
		<div>
			<div>
				<div className="flex flex-wrap justify-content-end mt-2"></div>
				<div className="col-9 mt-10">
					<TableComponent columns={tableColumns} size="large" />
				</div>
			</div>
		</div>
	);
};

export default Transfers;
