import client from "../../graphql/apollo.config";
import {
  GET_VEHICLE_GROUPS,
  GET_VEHICLE_FUEL_KINDS,
  GET_SUPPLIERS,
  GET_VEHICLE_KINDS,
  GET_MACHINE_GROUPS,
  CREATE_VEHICLE,
  GET_ALL_MACHINE_GROUPS,
  UPDATE_VEHICLE,
  CREATE_VEHICLE_GROUP,
  DELETE_VEHICLES,
  DELETE_VEHICLE_DOCUMENT,
  GET_VEHICLE_DOCUMENTS,
  CREATE_VEHICLE_DOCUMENT,
  GET_VEHICLE,
  DELETE_VEHICLE_GROUPS,
  GET_VEHICLE_STATUSES,
  UPDATE_VEHICLE_GROUP,
} from "../../graphql/machines";
import { setVehicleGroups } from "../slices/machineSlice";
import { setRequest, removeRequest } from "../slices/sharedSlice";
import moment from "moment";
import toast from "react-hot-toast";
import { t } from "i18next";

export const getVehicleGroups =
  (first, skip, search, nextVgp, filters, haveVehicles) => async (dispatch) => {
    dispatch(setRequest("getVehicleGroups"));
    return client
      .query({
        query: GET_VEHICLE_GROUPS,
        variables: {
          first,
          skip,

          isDeleted: filters?.isDeleted ? filters?.isDeleted : false,
          vehicleFuelKindId: filters?.vehicleFuelKindId,
          vehicleGroupId: filters?.vehicleGroupId,
          nextVgp: nextVgp,
          haveVehicles: haveVehicles,
        },
      })
      .then((res) => {
        dispatch(setVehicleGroups(res.data.vehicleGroups));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(removeRequest("getVehicleGroups"));
      });
  };

export const getVehicleFuelKinds = () => async (dispatch) => {
  dispatch(setRequest("getVehicleFuelKinds"));
  return client
    .query({
      query: GET_VEHICLE_FUEL_KINDS,
    })
    .then((res) => {
      return res.data.vehicleFuelKinds;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("getVehicleFuelKinds"));
    });
};

export const getSuppliers = () => async () => {
  return client
    .query({
      query: GET_SUPPLIERS,
    })
    .then((res) => {
      return res.data.customers;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getVehicleKinds = () => async (dispatch) => {
  dispatch(setRequest("getVehicleKinds"));
  return client
    .query({
      query: GET_VEHICLE_KINDS,
    })
    .then((res) => {
      return res.data.vehicleKinds;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("getVehicleKinds"));
    });
};

export const getMachineGroups =
  (
    vehicleKindId,
    isAddNewMachineSupplier,
    isViewMachineDetailSupplier,
    idOfAddNewMachineSupplier,
    idOfViewDetailMachineSupplier,
    haveSupplier
  ) =>
  async (dispatch) => {
    dispatch(setRequest("getMachineGroups"));
    return client
      .query({
        query: GET_MACHINE_GROUPS,
        variables: {
          id: parseInt(vehicleKindId),
          isSupplier:
            isAddNewMachineSupplier || isViewMachineDetailSupplier
              ? true
              : false,
          supplierId:
            isAddNewMachineSupplier || isViewMachineDetailSupplier
              ? isAddNewMachineSupplier
                ? idOfAddNewMachineSupplier
                : idOfViewDetailMachineSupplier
              : null,
          haveSupplier,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(removeRequest("getMachineGroups"));
      });
  };

export const getAllMachineGroups = () => async (dispatch) => {
  dispatch(setRequest("getAllMachineGroups"));
  return client
    .query({
      query: GET_ALL_MACHINE_GROUPS,
    })
    .then((res) => {
      return res.data.vehicleGroups;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("getAllMachineGroups"));
    });
};

export const createVehicle =
  (informations, selectedCustomerId) => async (dispatch) => {
    dispatch(setRequest("createVehicle"));
    return client
      .mutate({
        mutation: CREATE_VEHICLE,
        variables: {
          brand: informations.brand,
          deport: informations.deport,
          guaranteeDeliveryDate: informations.guaranteeDeliveryDate
            ? moment(informations.guaranteeDeliveryDate).format("YYYY-MM-DD")
            : null,
          guaranteeNextDate: informations.guaranteeNextDate
            ? moment(informations.guaranteeNextDate).format("YYYY-MM-DD")
            : null,
          guaranteeNextRevision: informations.guaranteeNextRevision
            ? moment(informations.guaranteeNextRevision).format("YYYY-MM-DD")
            : null,
          guaranteePeriod: informations.guaranteePeriod,
          guaranteeRemainingPeriod: informations.guaranteeRemainingPeriod,
          length: informations.length,
          machineCode: informations.machineCode,
          maxWarehouse: informations.maxWarehouse,
          model: informations.model,
          note: informations.note,
          serialNumber: informations.serialNumber,
          supplierCustomerId: selectedCustomerId
            ? selectedCustomerId
            : informations.supplierCustomer,
          weight: informations.weight,
          width: informations.width,
          height: informations.height,
          year: informations.year,
          vehicleFuelKindId: informations.vehicleFuelKind,
          vehicleGroupId: informations.vehicleGroup,
          vehicleKindId: informations.vehicleKind,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        if (err.message.toLowerCase().includes("vehicle_group")) {
          toast.error(t("pages.machines.machineDuplicate"));
        }
      })
      .finally(() => {
        dispatch(removeRequest("createVehicle"));
      });
  };

export const updateVehicle =
  (id, selectedMachine, selectedCustomerId) => async (dispatch) => {
    dispatch(setRequest("updateVehicle"));
    return client
      .mutate({
        mutation: UPDATE_VEHICLE,
        variables: {
          id: id,
          brand: selectedMachine.brand,
          deport: selectedMachine.deport,
          guaranteeDeliveryDate: selectedMachine.guaranteeDeliveryDate
            ? moment(selectedMachine.guaranteeDeliveryDate).format("YYYY-MM-DD")
            : null,
          guaranteeNextDate: selectedMachine.guaranteeNextDate
            ? moment(selectedMachine.guaranteeNextDate).format("YYYY-MM-DD")
            : null,
          guaranteeNextRevision: selectedMachine.guaranteeNextRevision
            ? moment(selectedMachine.guaranteeNextRevision).format("YYYY-MM-DD")
            : null,
          guaranteePeriod: selectedMachine.guaranteePeriod,
          guaranteeRemainingPeriod: selectedMachine.guaranteeRemainingPeriod,
          length: selectedMachine.length,
          machineCode: selectedMachine.machineCode,
          maxWarehouse: selectedMachine.maxWarehouse,
          model: selectedMachine.model,
          note: selectedMachine.note,
          serialNumber: selectedMachine.serialNumber,
          supplierCustomerId: selectedCustomerId
            ? selectedCustomerId
            : selectedMachine.supplierCustomer
            ? selectedMachine.supplierCustomer.id
            : null,
          weight: selectedMachine.weight,
          width: selectedMachine.width,
          height: selectedMachine.height,
          year: selectedMachine.year,
          vehicleFuelKindId: selectedMachine.vehicleFuelKind.id,
          vehicleGroupId: selectedMachine.vehicleGroup.id,
          vehicleKindId: selectedMachine.vehicleKind.id,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        if (err.message.toLowerCase().includes("vehicle_group")) {
          toast.error(t("pages.machines.machineDuplicate"));
        }
      })
      .finally(() => {
        dispatch(removeRequest("updateVehicle"));
      });
  };

export const createVehicleGroup =
  (
    newMachineGroup,
    isAddNewMachineSupplier,
    isViewMachineDetailSupplier,
    idOfAddNewMachineSupplier,
    idOfViewDetailMachineSupplier
  ) =>
  async (dispatch) => {
    dispatch(setRequest("createVehicleGroup"));
    return client
      .mutate({
        mutation: CREATE_VEHICLE_GROUP,
        variables: {
          name: newMachineGroup.name,
          vehicleKindId: newMachineGroup.vehicleKindId,
          supplierId:
            isAddNewMachineSupplier || isViewMachineDetailSupplier
              ? isAddNewMachineSupplier
                ? idOfAddNewMachineSupplier
                : idOfViewDetailMachineSupplier
              : null,
          description: newMachineGroup.description,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(removeRequest("createVehicleGroup"));
      });
  };

export const deleteVehicles = (id) => async (dispatch) => {
  dispatch(setRequest("deleteVehicles"));
  return client
    .mutate({
      mutation: DELETE_VEHICLES,
      variables: {
        id: parseInt(id),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("deleteVehicles"));
    });
};

export const deleteVehicleDocuments = (id) => async (dispatch) => {
  dispatch(setRequest("deleteVehicleDocuments"));
  return client
    .mutate({
      mutation: DELETE_VEHICLE_DOCUMENT,
      variables: {
        ids: parseInt(id),
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("deleteVehicleDocuments"));
    });
};

export const getVehicleDocuments = (vehicleId) => async (dispatch) => {
  dispatch(setRequest("getVehicleDocuments"));
  return client
    .query({
      query: GET_VEHICLE_DOCUMENTS,
      variables: {
        vehicleId,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("getVehicleDocuments"));
    });
};

export const createVehicleDocument = (id, file) => async (dispatch) => {
  dispatch(setRequest("createVehicleDocument"));
  return client
    .mutate({
      mutation: CREATE_VEHICLE_DOCUMENT,
      variables: {
        vehicleId: id,
        document: file,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("createVehicleDocument"));
    });
};

export const getMachine = (id) => async (dispatch) => {
  dispatch(setRequest("getMachine"));
  return client
    .query({
      query: GET_VEHICLE,
      variables: {
        id,
      },
    })
    .then((res) => {
      return res.data.vehicle;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("getMachine"));
    });
};

export const deleteVehicleGroups = (ids) => async (dispatch) => {
  dispatch(setRequest("deleteVehicleGroups"));
  return client
    .mutate({
      mutation: DELETE_VEHICLE_GROUPS,
      variables: {
        ids,
      },
    })
    .then(() => {
      return ids;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("deleteVehicleGroups"));
    });
};

export const getVehicleStatuses = () => async (dispatch) => {
  dispatch(setRequest("getVehicleStatuses"));
  return client
    .query({
      query: GET_VEHICLE_STATUSES,
    })
    .then((res) => {
      return res.data.vehicleStatuses;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("getVehicleStatuses"));
    });
};

export const updateVehicleGroup = (data) => async (dispatch) => {
  dispatch(setRequest("updateVehicleGroup"));
  return client
    .mutate({
      mutation: UPDATE_VEHICLE_GROUP,
      variables: {
        data: data,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(removeRequest("updateVehicleGroup"));
    });
};
