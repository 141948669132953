import React, { useEffect, useState } from 'react';
import { Document, Page, View, Text, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { getPurchaseOrderPDF } from '../../store/apis/SharedApi';
import moment from 'moment';

const PurchaseOrderPDF = ({ data, items, transporterInputs, customers }) => {
   const dispatch = useDispatch();
   const [totalPrice, setTotalPrice] = useState(0);
   const [totalTaxFee, setTotalTaxFee] = useState(0);
   const [totalPriceWithTax, setTotalPriceWithTax] = useState(0);

   useEffect(() => {
      if (items.length === 0) return;
      setTotalPrice(items.reduce((acc, item) => acc + +item.price * +item.quantity, 0));
      setTotalTaxFee(items.reduce((acc, item) => acc + (+item.price * +item.quantity * 20) / 100, 0));
      setTotalPriceWithTax(items.reduce((acc, item) => acc + (+item.price * +item.quantity + (+item.price * +item.quantity * 20) / 100), 0));
   }, [items]);

   const toFixedLocale = (number, digits) => {
      var formattedNumber = number.toFixed(digits);
      var decimalSeparator = (1.1).toLocaleString('tr-TR').substring(1, 2);
      return formattedNumber.replace('.', decimalSeparator);
   };

   // Create styles
   const styles = StyleSheet.create({
      page: {
         flexDirection: 'row',
      },
      section: {
         marginVertical: 20,
         flexGrow: 1,
      },
      content: {
         fontSize: 10,
         textAlign: 'left',
         fontWeight: 'bold',
      },
      contentHeader: {
         fontSize: 10,
         textAlign: 'left',
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
      },
      greenBox: {
         backgroundColor: '#84bf60',
         padding: 10,
         alignItems: 'center',
      },
      greenHeader: {
         fontSize: 12,
         color: '#1E8449',
      },
      header: {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'center',
      },
      logo: {
         width: '100%',
         height: '7vw',
      },
      table: {
         display: 'table',
         width: 'auto',
      },
      tableRowHeader: {
         margin: 'auto',
         flexDirection: 'row',
         backgroundColor: '#6494ed',
         height: 30,
      },
      tableRow: {
         margin: 'auto',
         flexDirection: 'row',
         marginBottom: 10,
      },
      tableCell: {
         marginTop: 5,
         fontSize: 10,
      },
      tableCellHeader: {
         margin: 'auto',
         marginTop: 10,
         fontSize: 10,
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
         color: 'white',
      },
   });

   const downloadPDF = async (pdfData) => {
      const onRender = (e) => {
         return `${e.pageNumber}/${e.totalPages}`;
      };
      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }}>
               <View style={styles.header}>
                  <Image style={styles.logo} src={require('../../assets/header.png')} />
               </View>
               <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View style={styles.section}>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.pdf.transporterId')}: </Text>
                        <Text style={styles.content}>{transporterInputs?.supplierId || transporterInputs?.transporterId}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.companyName')}: </Text>
                        <Text style={styles.content}>{customers.find((c) => +c.id === +transporterInputs.transporterId)?.companyName}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.customers.address')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].address ? pdfData?.customer?.addresses[0].address + ', ' : ''}</Text>
                        <Text style={styles.content}>
                           {pdfData?.customer?.addresses[0].addressContinuation ? pdfData?.customer?.addresses[0].addressContinuation + ', ' : ''}
                        </Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].postCode ? pdfData?.customer?.addresses[0].postCode + ', ' : ''}</Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].city ? pdfData?.customer?.addresses[0].city : ''}</Text>
                     </View>
                     <View style={{ flexDirection: 'row', marginTop: 20 }}>
                        <Text style={styles.contentHeader}>{t('pages.offers.poNumber')}: </Text>
                        <Text style={styles.content}>{transporterInputs?.transporterOrderNumber}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.date')}: </Text>
                        <Text style={styles.content}>{moment(pdfData?.createdAt).format('DD/MM/YYYY')}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.planning.contractNo')}: </Text>
                        <Text style={styles.content}>{pdfData?.orderNumber}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.tours.contact')}: </Text>
                        <Text style={styles.content}>{transporterInputs?.contractPerson}</Text>
                     </View>
                  </View>
                  <View style={styles.section}>
                     <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: '#6494ed' }}>
                        {t('pages.customers.deliveryAddress')}
                     </Text>
                     <View style={{ border: 1, padding: 15, minHeight: 80, alignItems: 'center' }}>
                        <Text style={styles.content}>{pdfData?.address.placeName || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.address || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.addressContinuation || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.postCode + ', ' + pdfData?.address.city || ''}</Text>
                     </View>
                     <View style={[styles.greenBox, { marginTop: 10 }]}>
                        <Text style={{ fontSize: 12, color: 'white' }}>{t('global.table.purchaseOrder')}</Text>
                     </View>
                  </View>
               </View>

               <View style={styles.table}>
                  {/* Tablo başlıkları */}
                  <View style={styles.tableRowHeader}>
                     <View style={[styles.tableCol, { width: '18%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.reference')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '40%' }]}>
                        <Text style={styles.tableCellHeader}>{t('global.table.description')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.pdf.unitPrice')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.quantity')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.totalPrice')}</Text>
                     </View>
                  </View>
                  {/* Satırlar */}
                  {items &&
                     items.length > 0 &&
                     items.map((item, index) => (
                        <>
                           <View style={styles.tableRow} key={index}>
                              <View style={[styles.tableCol, { width: '18%' }]}>
                                 <Text style={styles.tableCell}>{item?.category}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '40%' }]}>
                                 <Text style={styles.tableCell}>{item?.description}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{(item?.price).replace('.', ',')} €</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{item?.quantity}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {parseFloat(item?.price * item?.quantity)
                                       .toFixed(2)
                                       .replace('.', ',') + ' €'}
                                 </Text>
                              </View>
                           </View>
                        </>
                     ))}
               </View>
               {/* Total Box */}
               <View style={{ marginTop: '20px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                     <View style={{ maxWidth: '49%' }}>
                        <View>
                           <Text style={{ fontSize: 10 }}>{transporterInputs?.description ? transporterInputs?.description : ''}</Text>
                        </View>
                     </View>
                     <View style={{ border: 1, alignSelf: 'flex-end', flexDirection: 'column', minWidth: '49%', borderColor: '#6494ed' }}>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.totalBeforeTax').toUpperCase()}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {parseFloat(totalPrice).toFixed(2).replace('.', ',')} €
                           </Text>
                        </View>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.tax').toUpperCase() + ' 20%'}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {parseFloat(totalTaxFee).toFixed(2).replace('.', ',')} €
                           </Text>
                        </View>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.totalAfterTax').toUpperCase()}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {parseFloat(totalPriceWithTax).toFixed(2).replace('.', ',')} €
                           </Text>
                        </View>
                     </View>
                  </View>
               </View>
               {/* Details */}
               <View style={{ flexGrow: 1, marginBottom: 50 }}>
                  <View style={{ marginTop: '20px' }}>
                     <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View>
                           <Text style={{ minWidth: '50%', maxWidth: '50%', fontSize: 10, marginTop: '10px' }}>{t('pages.pdf.purchasePdf1')}</Text>
                           <Text style={{ minWidth: '50%', maxWidth: '50%', fontSize: 10, marginTop: '10px', color: 'red' }}>
                              {t('pages.pdf.purchasePdf2')}
                           </Text>
                        </View>
                        <View>
                           <View style={{ border: 1, padding: 5, minWidth: '50%', minHeight: '100px', marginLeft: '10px', borderColor: '#6494ed' }}>
                              <Text style={{ maxWidth: '45%', fontSize: 10 }}>{t('pages.pdf.offersBoxText')}</Text>
                           </View>
                        </View>
                     </View>
                  </View>
               </View>
               {/* Footer */}
               <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                  <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  <View style={{ textAlign: 'right' }} fixed>
                     <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                  </View>
               </View>
            </Page>
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(blob, 'purchase_order'); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   const getPdfData = () => {
      dispatch(getPurchaseOrderPDF(data.id)).then((res) => {
         downloadPDF(res);
      });
   };

   return (
      <>
         <Button
            label={t('pages.contracts.po')}
            className="p-button-warning"
            onClick={() => {
               getPdfData(data);
            }}
            icon="pi pi-file-pdf"
            iconPos="right"
         />
      </>
   );
};

export default PurchaseOrderPDF;
