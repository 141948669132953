import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, pdf, Image } from '@react-pdf/renderer';
import { Button } from 'primereact/button';
import { getMalfunctionPDF } from '../../store/apis/SharedApi';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment';

const MalfunctionPDF = ({ data }) => {
   const dispatch = useDispatch();
   const graphqlURL = process.env.REACT_APP_LINK.split('/graphql')[0];

   const styles = StyleSheet.create({
      page: {
         padding: 30,
         fontSize: 10,
         fontFamily: 'Helvetica',
      },
      section: {
         marginBottom: 0,
      },
      title: {
         fontSize: 11,
         marginBottom: 3,
      },
      table: {
         display: 'flex',
         flexDirection: 'row',
         marginBottom: 5,
         minHeight: 50,
      },
      tableColumn: {
         borderRight: 1,
         borderTop: 1,
         borderBottom: 1,
         flexGrow: 1,
         padding: 5,
      },
      tableCellHeader: {
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
      },
      header: {
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
         fontSize: 12,
         textAlign: 'center',
      },
      textBox: {
         borderTop: 1,
         borderBottom: 1,
         minHeight: 25,
         padding: 5,
         marginBottom: 5,
      },
      noteBox: {
         borderTop: 1,
         padding: 5,
         minHeight: 40,
      },
      baseHeader: {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'center',
         marginBottom: 20,
      },
   });

   const getTrueMalfunctions = (data) => {
      const trueMalfunctions = Object.entries(data)
         .filter(([key, value]) => key.startsWith('isMalfunction') && value)
         .map(([key]) => t('pages.malfunctions.' + key));
      return trueMalfunctions.join(', ');
   };

   const getTrueSolutions = (data) => {
      const trueSolutions = Object.entries(data)
         .filter(([key, value]) => key.startsWith('isSolution') && value)
         .map(([key]) => t('pages.malfunctions.' + key));
      return trueSolutions.join(', ');
   };

   const downloadPDF = async (pdfData) => {
      console.log(pdfData);
      const MyDocument = () => (
         <Document>
            <Page size="A4" style={styles.page}>
               <View style={styles.baseHeader}>
                  <View>
                     <Text style={{ fontSize: '11px', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Metaloc</Text>
                     <Text style={{ fontSize: '11px' }}>3 rue du compas</Text>
                     <Text style={{ fontSize: '11px' }}>95310 Saint-Ouen-l'Aumône</Text>
                     <Text style={{ fontSize: '11px' }}>01 61 03 18 02</Text>
                  </View>
                  <View>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.customer?.companyName}</Text>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.customer?.addresses[0].address}</Text>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.customer?.phone}</Text>
                  </View>
                  <View>
                     <Text style={{ fontSize: '11px' }}>
                        {t('pages.malfunctions.date')}: {pdfData?.technicianDepartureDate ? moment(pdfData?.technicianDepartureDate).format('DD/MM/YYYY') : '-'}
                     </Text>
                     <Text style={{ fontSize: '11px' }}>
                        {t('pages.pdf.hour')}: {pdfData?.technicianArrivalTime ? moment(pdfData?.technicianArrivalTime, 'HH:mm:ss').format('HH:mm') : '-'}
                     </Text>
                  </View>
               </View>
               <Text style={{ textAlign: 'center', fontSize: '12px', marginBottom: '10px' }}>
                  {t('pages.pdf.malfunctionRecordNo')} {pdfData?.id}
               </Text>
               {/* Delivery Address Section */}
               <View style={{ border: 1 }}>
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('global.table.deliveryAddress').toUpperCase()}</Text>
                     <View style={styles.textBox}>
                        <Text>{pdfData?.address?.address}</Text>
                     </View>
                  </View>

                  {/* Machine Details Section */}
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('pages.malfunctions.machine').toUpperCase()}</Text>
                     <View style={styles.table}>
                        <View style={styles.tableColumn}>
                           <Text>{pdfData?.vehicle?.brand}</Text>
                           <Text>
                              {t('global.table.serialNumber')}: {pdfData?.vehicle?.serialNumber}
                           </Text>
                        </View>
                        <View style={styles.tableColumn}>
                           <Text>
                              {t('global.table.description')}: {pdfData?.vehicle?.note}
                           </Text>
                           <Text>{t('pages.pdf.mileage')}:</Text>
                        </View>
                        <View style={styles.tableColumn}>
                           <Text>
                              {t('pages.logistics.machineCode')}: {pdfData?.vehicle?.machineFullCode}
                           </Text>
                        </View>
                     </View>
                  </View>

                  {/* Malfunctions Section */}
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('pages.malfunctions.malfunctions').toUpperCase()}</Text>
                     <View style={styles.textBox}>
                        <Text>{getTrueMalfunctions(pdfData)}</Text>
                     </View>
                  </View>

                  {/* Solutions Section */}
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('pages.malfunctions.solutions').toUpperCase()}</Text>
                     <View style={styles.textBox}>
                        <Text>{getTrueSolutions(pdfData)}</Text>
                     </View>
                  </View>

                  {/* Report Section */}
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('pages.pdf.report').toUpperCase()}</Text>
                     <View style={styles.table}>
                        <View style={styles.tableColumn}>
                           <Text>
                              {t('pages.malfunctions.technician')}: {pdfData?.technicianUser?.name}
                           </Text>
                           <Text>{pdfData?.isPartsAndSmallSupplies ? t('pages.malfunctions.isPartsAndSmallSupplies') : ''}</Text>
                        </View>
                        <View style={styles.tableColumn}>
                           <Text>
                              {t('pages.malfunctions.arrivalTime')}:{' '}
                              {pdfData?.technicianArrivalTime ? moment(pdfData?.technicianArrivalTime, 'HH:mm:ss').format('HH:mm') : '-'}
                           </Text>
                        </View>
                        <View style={styles.tableColumn}>
                           <Text>
                              {t('pages.malfunctions.departureTime')}:{' '}
                              {pdfData?.technicianDepartureTime ? moment(pdfData?.technicianDepartureTime, 'HH:mm:ss').format('HH:mm') : '-'}
                           </Text>
                        </View>
                     </View>
                  </View>

                  {/* Next Step Section */}
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('pages.malfunctions.nextStep').toUpperCase()}</Text>
                     <View style={[styles.textBox, { flexDirection: 'row' }]}>
                        <Text>{pdfData?.isInvoiced && t('pages.malfunctions.isInvoiced') + ', '}</Text>
                        <Text>{pdfData?.isMakeAnOffer && t('pages.malfunctions.isMakeAnOffer') + ', '}</Text>
                        <Text>{pdfData?.isMaintenanceContract && t('pages.malfunctions.isMaintenanceContract')}</Text>
                     </View>
                  </View>

                  {/* Note Section */}
                  <View style={styles.section}>
                     <Text style={styles.header}>{t('pages.malfunctions.note').toUpperCase()}</Text>
                     <View style={styles.noteBox}>
                        <Text>{pdfData?.technicianNote}</Text>
                     </View>
                  </View>
               </View>
               <Text style={{ marginTop: '10px', fontWeight: 'bold' }}>{t('pages.malfunctions.customerSignature')}</Text>
               <Image src={graphqlURL + pdfData?.customerSignature} style={{ width: '120px', height: '70px', marginTop: 20 }} />
               {/* Footer */}
               <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                  <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  <View style={{ textAlign: 'right' }} fixed>
                     <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                  </View>
               </View>
            </Page>
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(blob, 'logistics_bd.pdf'); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   const getPdfData = (data) => {
      dispatch(getMalfunctionPDF(data.id)).then((res) => {
         downloadPDF(res);
      });
   };

   return (
      <>
         <Button
            severity="success"
            label={t('pages.malfunctions.downloadReport')}
            className="w-full mt-[20px]"
            onClick={() => {
               getPdfData(data);
            }}
         />
      </>
   );
};

export default MalfunctionPDF;
