import TableComponent from '../../../components/TableComponent';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { changeLoadingStatus } from '../../../store/slices/sharedSlice';
import client from '../../../graphql/apollo.config';
import { CREATE_DISCUSSION, GET_DISCUSSIONS, GET_DISCUSSION_KINDS, UPDATE_DISCUSSION } from '../../../graphql/customers';
import { GET_USERS } from '../../../graphql/users';
import toast from 'react-hot-toast';
import moment from 'moment';
import { createDiscussion, getDisscussions, updateDiscussion } from '../../../store/apis/Customers';

const Discussions = (props) => {
	const dispatch = useDispatch();

	const [showAddNewDiscussion, setShowAddNewDiscussion] = useState(false);

	const { discussions } = useSelector((state) => state.customer);

	const [users, setUsers] = useState();

	const [selectedDiscussion, setSelectedDiscussion] = useState();
	const [discussionKinds, setDiscussionKinds] = useState();

	const [discussionsInputs, setDiscussionsInputs] = useState({
		discussionKind: null,
		user: null,
		date: '',
		note: '',
	});

	const [errors, setErrors] = useState({});

	const { selectedCustomerId } = useSelector((state) => state.customer);

	const tableColumns = [
		{
			name: 'type',
			header: t('global.table.type'),
			body: (rowData) => {
				return <span>{rowData ? rowData.discussionKind.name : ''}</span>;
			},
		},
		{
			name: 'user',
			header: t('global.table.user'),
			body: (rowData) => {
				return <span>{rowData.user.name}</span>;
			},
		},
		{
			name: 'date',
			header: t('global.table.discussionDate'),
			body: (rowData) => {
				return <span>{moment(rowData.date).format('DD/MM/YYYY')}</span>;
			},
		},
		{
			name: 'notes',
			header: t('global.table.notes'),
			body: (rowData) => {
				return <span>{rowData.note}</span>;
			},
		},
	];

	useEffect(() => {
		dispatch(changeLoadingStatus(true));
		client
			.query({
				query: GET_USERS,
			})
			.then((res) => {
				setUsers(res.data.users);
				client
					.query({
						query: GET_DISCUSSION_KINDS,
					})
					.then((res) => {
						setDiscussionKinds(res.data.discussionKinds);
					})
					.catch((err) => {
						console.log(err);
					});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getDisscussions(selectedCustomerId));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// FORM VALIDATION
	const updateErrors = (name, value) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? t('global.requiredField') : '',
		}));
	};

	const handleInputChange = (name, value) => {
		if (selectedDiscussion) {
			setSelectedDiscussion((selectedDiscussion) => ({ ...selectedDiscussion, [name]: value }));
		}
		setDiscussionsInputs((prevInfos) => ({
			...prevInfos,
			[name]: value,
		}));
		updateErrors(name, value);
	};

	const validateForm = () => {
		const inputs = ['discussionKind', 'user', 'date'];
		let isValid = true;
		if (selectedDiscussion) {
			inputs.forEach((input) => {
				if (!selectedDiscussion[input]) {
					updateErrors(input, selectedDiscussion[input]);
					isValid = false;
					console.log(input);
				}
			});
		} else {
			inputs.forEach((input) => {
				if (!discussionsInputs[input]) {
					updateErrors(input, discussionsInputs[input]);
					isValid = false;
					console.log(input);
				}
			});
		}
		return isValid;
	};

	const handleRowClick = (e) => {
		console.log(e);
		setSelectedDiscussion(e);
		setShowAddNewDiscussion(true);
	};

	const handleDiscussionSave = () => {
		if (selectedDiscussion) {
			if (validateForm()) {
				dispatch(updateDiscussion(selectedDiscussion, moment(selectedDiscussion.date).format('YYYY-MM-DD'))).then((res) => {
					if (res) {
						toast.success(t('global.updateSuccess'));
						resetVariables();
						setShowAddNewDiscussion(false);
						setSelectedDiscussion(null);
					}
				});
			}
		} else {
			if (validateForm()) {
				dispatch(createDiscussion(props.customerData.id, moment(discussionsInputs.date).format('YYYY-MM-DD'), discussionsInputs)).then((res) => {
					if (res) {
						toast.success('Discussion created successfully!');
						resetVariables();
						setShowAddNewDiscussion(false);
						setSelectedDiscussion(null);
					}
				});
			}
		}
	};

	const resetVariables = () => {
		setDiscussionsInputs({
			discussionKind: null,
			user: null,
			date: '',
			note: '',
		});
	};

	return (
		<div className="h-full w-full">
			<div className="h-full w-full">
				<div className="w-full flex justify-end mb-3">
					<Button
						className=" col-12 md:col-3 mt-2"
						style={{ background: '#38b000', border: ' 1.5px solid #38b000' }}
						label={t('global.table.addNewDiscussion')}
						size="small"
						onClick={() => {
							setShowAddNewDiscussion(true);
							setSelectedDiscussion(null);
						}}
					></Button>
				</div>
				<div className="col-7">
					<TableComponent columns={tableColumns} size="medium" data={discussions} onRowClick={(e) => handleRowClick(e)} />
				</div>

				<Dialog
					visible={showAddNewDiscussion}
					onHide={() => {
						setShowAddNewDiscussion(false);
						resetVariables();
						setErrors({});
					}}
					draggable={false}
					header={selectedDiscussion ? t('pages.customers.discussionDetails') : t('pages.customers.addNewDiscussion')}
					className="md-width-dialog"
				>
					<div className="grid">
						<div className="md:col-6 col-12 flex flex-wrap">
							<label>{t('pages.customers.discussionType')}*</label>
							<Dropdown
								options={discussionKinds}
								optionLabel="name"
								optionValue="id"
								onChange={(e) => {
									if (selectedDiscussion) {
										handleInputChange('discussionKind', { id: e.value });
									} else {
										handleInputChange('discussionKind', e.value);
									}
								}}
								value={selectedDiscussion ? selectedDiscussion.discussionKind.id : discussionsInputs.discussionKind}
								placeholder="Select User"
								className={`w-full input-detail ${errors.discussionKind && 'input-error'}`}
								valueTemplate={(option) => {
									if (option) {
										return <span>{t(`pages.customers.discussionTypes.${option.name}`)}</span>;
									}
									return <span>&nbsp;</span>;
								}}
								itemTemplate={(option) => {
									return <span>{t(`pages.customers.discussionTypes.${option.name}`)}</span>;
								}}
							/>
							{errors.discussionKind && <small className="p-error">{errors.discussionKind}</small>}
						</div>
						<div className="md:col-6 col-12 flex flex-wrap">
							<label>{t('global.table.user')}*</label>
							<Dropdown
								options={users}
								optionLabel="name"
								optionValue="id"
								onChange={(e) => {
									if (selectedDiscussion) {
										handleInputChange('user', { id: e.value });
									} else {
										handleInputChange('user', e.value);
									}
								}}
								value={selectedDiscussion ? selectedDiscussion.user.id : discussionsInputs.user}
								className={`w-full input-detail ${errors.user && 'input-error'}`}
							/>
							{errors.user && <small className="p-error">{errors.user}</small>}
						</div>
						<div className="md:col-6 col-12 flex flex-wrap">
							<label>{t('global.table.discussionDate')}*</label>
							<Calendar
								onChange={(e) => {
									handleInputChange('date', e.value);
								}}
								dateFormat='dd/mm/yy'
								value={selectedDiscussion ? new Date(selectedDiscussion.date) : discussionsInputs.date}
								className={`w-full input-detail ${errors.date && 'input-error'}`}
							/>
							{errors.date && <small className="p-error">{errors.date}</small>}
						</div>
						<div className="col-12 flex flex-wrap">
							<label>{t('global.table.notes')}</label>
							<InputTextarea
								onChange={(e) => {
									handleInputChange('note', e.target.value);
								}}
								value={selectedDiscussion ? selectedDiscussion.note : discussionsInputs.note}
								className="w-full input-detail"
							/>
						</div>
					</div>
					<div className="grid mt-3">
						<div className="col-12 flex justify-end">
							<Button label={selectedDiscussion ? t('global.update') : t('global.create')} icon="pi pi-check" onClick={() => handleDiscussionSave()} />
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	);
};

export default Discussions;
