import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { t, use } from 'i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { createVehicleMalfunctionDocument, deleteVehicleMalfunctionDocument, getVehicleMalfunctionDocuments } from '../../../store/apis/MalfunctionApi';
import toast from 'react-hot-toast';

const MalfunctionDocuments = (props) => {
   const dispatch = useDispatch();
   const fileUploadRef = useRef(null);
   const url = process.env.REACT_APP_LINK.split('/graphql')[0];
   const documents = useSelector((state) => state.malfunctions.malfunctionDocuments);

   useEffect(() => {
      if (props.selectedId) {
         dispatch(getVehicleMalfunctionDocuments(props.selectedId));
      }
   }, []);

   const onFileSelect = async (e) => {
      const _data = {
         vehicleMalfunctionId: +props.selectedId,
         document: e.files[0],
      };
      const result = await dispatch(createVehicleMalfunctionDocument(_data));
      if (result) {
         toast.success(t('pages.malfunctions.createDocumentMessage'));
      }
   };

   const fileTemplate = (rowData) => {
      return (
         <>
            <Button
               icon="pi pi-eye"
               className="mr-2 p-button-rounded p-button-success min-w-[46px]"
               onClick={() => window.open(`${url}${rowData.document.url}`, '_blank')}
            />
            <Button icon="pi pi-times" className="p-button-rounded p-button-danger  min-w-[46px]" onClick={() => removeFile(rowData)} />
         </>
      );
   };

   const nameTemplate = (rowData) => {
      return (
         <div className="flex items-center">
            <span className="ml-2">{rowData.document.name.split('.')[0]}</span>
         </div>
      );
   };

   const sizeTemplate = (rowData) => {
      return `${(rowData.document.size / 1024).toFixed(2)} KB`;
   };

   const extensionTemplate = (rowData) => {
      return (
         <div className="flex items-center">
            <span className="ml-2">{rowData.document.name.lastIndexOf('.') <= 0 ? '' : rowData.document.name.split('.').pop()}</span>
         </div>
      );
   };

   const removeFile = (fileToRemove) => {
      const _id = parseInt(fileToRemove.id);
      dispatch(deleteVehicleMalfunctionDocument([_id]));
   };

   return (
      <>
         <div>
            <FileUpload
               ref={fileUploadRef}
               accept="*"
               chooseOptions={{ icon: 'pi pi-plus', className: 'bg-green-500 border-none' }}
               onSelect={onFileSelect}
               chooseLabel={t('pages.malfunctions.addNewMalfunctionDocument')}
               maxFileSize={9000000}
               uploadOptions={{ style: { display: 'none' } }}
               cancelOptions={{ style: { display: 'none' } }}
               pt={{
                  content: { style: { display: 'none' } },
                  buttonbar: { style: { background: 'none', border: 'none', display: 'flex', justifyContent: 'end' } },
               }}
            />
         </div>

         <div>
            <DataTable value={documents} size="small">
               <Column body={fileTemplate} header={t('global.table.action')} style={{ minWidth: '120px' }}></Column>
               <Column field="document.name" body={nameTemplate} header={t('global.table.fileName')} style={{ width: '30%' }}></Column>
               <Column field="document.size" body={sizeTemplate} header={t('global.table.size')} style={{ width: '25%' }}></Column>
               <Column field="extension" body={extensionTemplate} header={t('global.table.extension')} style={{ width: '20%' }}></Column>
            </DataTable>
         </div>

         <div className="col-12 flex justify-end">
            <Button
               label={t('pages.malfunctions.saveAndContinue')}
               className="w-[400px] mt-[20px]"
               onClick={() => toast.success(t('pages.malfunctions.malfunctionUpdated'))}
            />
         </div>
      </>
   );
};

export default MalfunctionDocuments;
