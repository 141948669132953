import { gql } from '@apollo/client';

export const LOGIN = gql`
   mutation signIn($language: Language!, $password: String!, $email: String!) {
      signIn(data: { password: $password, language: $language, email: $email }) {
         user {
            birthdate
            email
            firstName
            idNumber
            id
            lastName
            phone
            role {
               id
               name
            }
         }
         token
      }
   }
`;

export const GET_GENDERS = gql`
   query getGenders {
      genders(isDeleted: false) {
         id
         name
      }
   }
`;

export const GET_ROLES = gql`
   query getRoles {
      roles(isDeleted: false) {
         id
         name
      }
   }
`;
