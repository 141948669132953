import { t } from 'i18next';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableComponent from '../../../../components/TableComponent';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import {
   createMachineGroup,
   createOffer,
   createOfferItem,
   createVehicleReservation,
   deleteVehicleReservations,
   getPublicHolidays,
   getVehicleGroupPrices,
   getVehicleGroups,
   updateVehicleReservation,
} from '../../../../store/apis/OffersApi';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { OverlayPanel } from 'primereact/overlaypanel';

const InventoryMachine = (props) => {
   const { inventoryMachines, setInventoryMachines, setCreatedOrderId, informationsInputs, createdOrderId, customer } = props;
   const dispatch = useDispatch();
   const [actionType, setActionType] = useState('create');
   const [addMachineDialog, setAddMachineDialog] = useState(false);
   const [machineInputs, setMachineInputs] = useState({
      vehicleGroupId: null,
      beginDate: null,
      endDate: null,
      deliveryFee: 0,
      deliveryFeeCurrencyId: 2,
      pickupFee: 0,
      pickupFeeCurrencyId: 2,
      price: 0,
      currencyId: 2,
      isIncludingSaturdays: false,
      isIncludingSundays: false,
      isIncludingPublicHolidays: false,
      isEco: false,
      isInsurance: false,
      discountRate: 0,
      priceKind: 'DAILY',
   });
   const [selectedRowData, setSelectedRowData] = useState(null);
   const [errors, setErrors] = useState({});
   const [vehicleGroups, setVehicleGroups] = useState([]);
   const [totalPrices, setTotalPrices] = useState({
      totalPrice: 0,
      totalEcoFee: 0,
      totalInsurance: 0,
      totalDeliveryFee: 0,
      totalPickupFee: 0,
      totalBeforeTax: 0,
      totalAfterTax: 0,
      totalDiscount: 0,
   });
   const [createMachineGroupDialog, setCreateMachineGroupDialog] = useState(false);
   const [createdMachineGroup, setCreatedMachineGroup] = useState('');
   const [machineGroupPrices, setMachineGroupPrices] = useState(null);
   const op = useRef(null);
   const [publicHolidays, setPublicHolidays] = useState([]);
   const monthlyInsurancePrice = useRef(0);
   const monthlyQuantity = useRef(0);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      dispatch(getVehicleGroups({ isSupplier: false })).then((res) => {
         setVehicleGroups(res);
      });
   }, []);

   useEffect(() => {
      if (customer && addMachineDialog) {
         setMachineInputs({ ...machineInputs, isInsurance: customer.ownInsurance ? false : true });
      }
   }, [customer, addMachineDialog]);

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };

   const calculateWorkDaysMonthly = (item, inputs) => {
      const calculateWorkDays = (start, end) => {
         let workDays = 0;
         const totalDays = end.diff(start, 'days') + 1;
         for (let i = 0; i < totalDays; i++) {
            const currentDay = start.day();
            const currentDate = start.format('YYYY-MM-DD');
            if (
               (currentDay === 0 && !inputs.isIncludingSundays) ||
               (currentDay === 6 && !inputs.isIncludingSaturdays) ||
               (!inputs.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
            ) {
               start.add(1, 'days');
               continue;
            }
            workDays++;
            start.add(1, 'days');
         }
         return workDays;
      };
      const start = moment(item.beginDate);
      const end = moment(item.endDate);
      const firstDayOfMonth = moment(start).startOf('month');
      const lastDayOfMonth = moment(end).endOf('month');
      const workDays = calculateWorkDays(moment(item.beginDate), moment(item.endDate), item);
      const monthlyWorkDays = calculateWorkDays(firstDayOfMonth, lastDayOfMonth, item);
      const insuranceWorkDays = moment(item?.endDate).diff(moment(item?.beginDate), 'days') + 1;
      const data = {
         workDays,
         monthlyWorkDays,
         insuranceWorkDays,
      };
      return data;
   };

   const splitDateRangeByMonth = (startDate, endDate) => {
      const start = moment(startDate);
      const end = moment(endDate);
      const result = [];
      let current = start.clone().startOf('month');
      const last = end.clone().startOf('month');

      while (current.isSameOrBefore(last)) {
         let beginDate = current.clone().startOf('month');
         let endDate = current.clone().endOf('month');
         if (beginDate.isBefore(start)) {
            beginDate = start.clone();
         }
         if (endDate.isAfter(end)) {
            endDate = end.clone();
         }
         result.push({
            beginDate,
            endDate,
         });
         current.add(1, 'month');
      }
      return result;
   };

   const calculateMonthlyPrice = () => {
      const start = moment(machineInputs.beginDate).format('YYYY-MM-DD');
      const end = moment(machineInputs.endDate).format('YYYY-MM-DD');
      const result = splitDateRangeByMonth(start, end);
      let totalPrice = 0;
      let totalInsurance = 0;
      let totalQuantity = 0;
      result.forEach((item) => {
         const workingDays = calculateWorkDaysMonthly(item, machineInputs);
         const calculated = parseFloat(workingDays.workDays / workingDays.monthlyWorkDays);
         totalQuantity += calculated;
         const rentAmount = machineInputs.price * calculated;
         totalPrice += rentAmount;
         const insuranceUnitPrice = ((rentAmount * 10) / 100 / workingDays.workDays) * workingDays.insuranceWorkDays;
         totalInsurance += insuranceUnitPrice;
      });
      monthlyInsurancePrice.current = totalInsurance;
      monthlyQuantity.current = totalQuantity;
      return parseFloat(totalPrice).toFixed(2);
   };

   const calculateSubTotal = (inputs) => {
      const days = calculateWorkDays(inputs);
      const price = inputs.priceKind === 'DAILY' ? parseFloat(inputs?.price) * days : calculateMonthlyPrice();
      const subTotal = price - (price * inputs.discountRate) / 100;
      return parseFloat(subTotal);
   };

   const calculateInsurancePrice = (inputs) => {
      if (!inputs.isInsurance) return;
      let insurancePrice = 0;
      if (inputs.priceKind === 'DAILY') {
         insurancePrice = Number(inputs.price) * (moment(inputs?.endDate).diff(moment(inputs?.beginDate), 'days') + 1);
      } else {
         insurancePrice = monthlyInsurancePrice.current;
      }
      return insurancePrice;
   };

   useEffect(() => {
      if (selectedRowData && addMachineDialog) {
         setMachineInputs({
            vehicleGroupId: selectedRowData.vehicleGroup.id,
            beginDate: moment(selectedRowData.beginDate).toDate(),
            endDate: moment(selectedRowData.endDate).toDate(),
            price: selectedRowData.price,
            deliveryFee: selectedRowData.deliveryFee,
            pickupFee: selectedRowData.pickupFee,
            isIncludingSaturdays: selectedRowData.isIncludingSaturdays,
            isIncludingSundays: selectedRowData.isIncludingSundays,
            isIncludingPublicHolidays: selectedRowData.isIncludingPublicHolidays,
            isEco: selectedRowData.isEco,
            isInsurance: selectedRowData.isInsurance,
            discountRate: selectedRowData.discountRate,
            priceKind: selectedRowData.priceKind,
         });
      }
   }, [selectedRowData, addMachineDialog]);

   useEffect(() => {
      if (!inventoryMachines.length) {
         setTotalPrices({
            totalPrice: '0.00',
            totalEcoFee: '0.00',
            totalInsurance: '0.00',
            totalDeliveryFee: '0.00',
            totalPickupFee: '0.00',
            totalBeforeTax: '0.00',
            totalAfterTax: '0.00',
            totalDiscount: '0.00',
         });
         return;
      }

      let totalPrice = 0;
      let totalEcoFee = 0;
      let totalInsurance = 0;
      let totalDeliveryFee = 0;
      let totalPickupFee = 0;
      let totalDiscount = 0;

      inventoryMachines.forEach((item) => {
         const itemTotalPrice = parseFloat(item.subTotal);
         totalPrice += itemTotalPrice;
         if (item.isEco) {
            totalEcoFee += Number(item.ecoPrice || 0);
         }
         if (item.isInsurance) {
            totalInsurance += parseFloat(item.insurancePrice);
         }
         totalDeliveryFee += Number(item.deliveryFee || 0);
         totalPickupFee += Number(item.pickupFee || 0);
         totalDiscount += (itemTotalPrice * Number(item.discountRate || 0)) / 100;
      });

      const totalBeforeTax = totalPrice + totalEcoFee + totalInsurance + totalDeliveryFee + totalPickupFee - totalDiscount;
      const taxRate = inventoryMachines[0].tax / 100;
      const totalAfterTax = totalBeforeTax * (1 + taxRate);

      setTotalPrices({
         totalPrice: parseFloat(totalPrice).toFixed(2),
         totalEcoFee: totalEcoFee.toFixed(2),
         totalInsurance: parseFloat(totalInsurance).toFixed(2),
         totalDeliveryFee: totalDeliveryFee.toFixed(2),
         totalPickupFee: totalPickupFee.toFixed(2),
         totalBeforeTax: totalBeforeTax.toFixed(2),
         totalAfterTax: totalAfterTax.toFixed(2),
         totalDiscount: totalDiscount.toFixed(2),
      });
   }, [inventoryMachines]);

   useEffect(() => {
      if (addMachineDialog && machineInputs.vehicleGroupId) {
         dispatch(getVehicleGroupPrices(+machineInputs.vehicleGroupId, +customer?.id)).then((res) => {
            if (res.length > 0) {
               setMachineGroupPrices(res[0]);
            } else {
               dispatch(getVehicleGroupPrices(+machineInputs.vehicleGroupId)).then((res) => {
                  setMachineGroupPrices(res[0]);
               });
            }
         });
      }
   }, [addMachineDialog, machineInputs.vehicleGroupId]);

   const resetMachineInputs = () => {
      return {
         vehicleGroupId: null,
         beginDate: null,
         endDate: null,
         deliveryFee: 0,
         deliveryFeeCurrencyId: 2,
         pickupFee: 0,
         pickupFeeCurrencyId: 2,
         price: 0,
         currencyId: 2,
         isIncludingSaturdays: false,
         isIncludingSundays: false,
         isIncludingPublicHolidays: false,
         isEco: false,
         isInsurance: false,
         discountRate: 0,
         priceKind: 'DAILY',
      };
   };

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      if (name === 'beginDate') {
         setMachineInputs({ ...machineInputs, [name]: value, endDate: value });
         updateErrors(name, value);
      } else if (name === 'price' || name === 'deliveryFee' || name === 'pickupFee' || name === 'discountRate') {
         let onlyNums = value.replace(/[^0-9]/g, '');
         if (name === 'discountRate') {
            const numericValue = parseInt(onlyNums, 10);
            if (numericValue < 0) {
               onlyNums = 0;
            } else if (numericValue > 100) {
               onlyNums = 100;
            }
         }
         setMachineInputs({ ...machineInputs, [name]: onlyNums });
         updateErrors(name, onlyNums);
      } else {
         const { value } = e.target;
         setMachineInputs({ ...machineInputs, [name]: value });
         updateErrors(name, value);
      }
   };

   const handleFocus = (name) => {
      if (machineInputs[name] === 0) {
         setMachineInputs({ ...machineInputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const inputs = ['vehicleGroupId', 'beginDate', 'endDate', 'price'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!machineInputs[input]) {
            updateErrors(input, machineInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };
   const saveItems = async () => {
      if (!validateForm()) return;
      if (actionType === 'create') {
         if (!inventoryMachines.length && !createdOrderId) {
            await handleCreateOfferAndReservation();
         } else {
            await handleCreateReservation();
         }
      } else {
         const updateData = {
            ...machineInputs,
            id: selectedRowData.id,
            beginDate: moment(machineInputs.beginDate).format('YYYY-MM-DD'),
            endDate: moment(machineInputs.endDate).format('YYYY-MM-DD'),
            discountRate: machineInputs.discountRate ? parseInt(machineInputs.discountRate) : 0,
            subTotal: calculateSubTotal(machineInputs),
            insurancePrice: calculateInsurancePrice(machineInputs),
            quantity: monthlyQuantity.current,
         };
         const response = await dispatch(updateVehicleReservation(updateData));
         if (response) {
            const newInventoryMachines = inventoryMachines.map((i) => (i.id === response.id ? response : i));
            setInventoryMachines(newInventoryMachines);
            setAddMachineDialog(false);
            setMachineInputs(resetMachineInputs());
            setSelectedRowData(null);
            setMachineGroupPrices(null);
         }
      }
   };

   const handleCreateOfferAndReservation = async () => {
      const res = await dispatch(createOffer(informationsInputs));
      if (res) {
         setCreatedOrderId(res.id);
         const createData = formatMachineInputs(machineInputs);
         const response = await dispatch(createVehicleReservation(createData));
         if (response) {
            const offerItemData = {
               orderId: res.id,
               vehicleReservationId: response.id,
               price: machineInputs.price,
               quantity: 1,
               currencyId: 2,
               category: 'INV',
            };
            await dispatch(createOfferItem(offerItemData));
            updateInventoryMachines(response);
         }
      }
   };

   const handleCreateReservation = async () => {
      const createData = formatMachineInputs(machineInputs);
      const res = await dispatch(createVehicleReservation(createData));
      if (res) {
         const offerItemData = {
            orderId: createdOrderId,
            vehicleReservationId: res.id,
            price: machineInputs.price,
            quantity: 1,
            currencyId: 2,
            category: 'INV',
         };
         await dispatch(createOfferItem(offerItemData));
         updateInventoryMachines(res);
      }
   };

   const formatMachineInputs = (inputs) => {
      return {
         ...inputs,
         beginDate: moment(inputs.beginDate).format('YYYY-MM-DD'),
         endDate: moment(inputs.endDate).format('YYYY-MM-DD'),
         price: parseFloat(inputs.price),
         deliveryFee: inputs.deliveryFee ? parseFloat(inputs.deliveryFee) : 0,
         pickupFee: inputs.pickupFee ? parseFloat(inputs.pickupFee) : 0,
         discountRate: inputs.discountRate ? parseInt(inputs.discountRate) : 0,
         subTotal: calculateSubTotal(inputs),
         insurancePrice: calculateInsurancePrice(inputs),
         quantity: monthlyQuantity.current,
      };
   };

   const updateInventoryMachines = (item) => {
      setInventoryMachines([...inventoryMachines, item]);
      setAddMachineDialog(false);
      setMachineInputs(resetMachineInputs());
   };

   const removeItem = (item) => {
      dispatch(deleteVehicleReservations([+item.id])).then(() => {
         const newInventoryMachines = inventoryMachines.filter((i) => i.id !== item.id);
         setInventoryMachines(newInventoryMachines);
      });
   };

   const createNewMachineGroup = async () => {
      const data = {
         vehicleKindId: 1,
         name: createdMachineGroup,
      };

      const res = await dispatch(createMachineGroup(data));
      if (res) {
         setVehicleGroups([...vehicleGroups, res]);
         setMachineInputs({ ...machineInputs, vehicleGroupId: res.id });
         setCreatedMachineGroup(null);
         setCreateMachineGroupDialog(false);
      }
   };

   const formatPrice = (price) => `€${parseFloat(price).toFixed(2)}`;
   const calculateTotalPrice = (rowData) => {
      const subTotal = parseFloat(rowData.subTotal);
      const basePrice = subTotal - (subTotal * rowData.discountRate) / 100;
      const ecoPrice = rowData.isEco ? +rowData.ecoPrice : 0;
      const insurancePrice = parseFloat(rowData.insurancePrice);
      const deliveryFee = +rowData.deliveryFee;
      const pickupFee = +rowData.pickupFee;
      return basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee;
   };

   const calculateTotalPriceAfterTax = (rowData) => {
      const totalPrice = calculateTotalPrice(rowData);
      const taxAmount = (totalPrice * +rowData.tax) / 100;

      return totalPrice + taxAmount;
   };

   const tableColumns = [
      {
         name: 'actions',
         body: (rowData) => (
            <div className="flex items-center justify-center">
               <Button
                  className="mx-1"
                  icon="pi pi-pencil"
                  severity="warning"
                  onClick={() => {
                     setActionType('update');
                     setSelectedRowData(rowData);
                     setAddMachineDialog(true);
                  }}
               />
               <Button className="mx-1" icon="pi pi-trash" severity="danger" onClick={() => removeItem(rowData)} />
            </div>
         ),
         footer: t('pages.offers.total').toUpperCase(),
      },
      {
         name: 'reference',
         header: t('pages.offers.reference'),
         body: (rowData) => <span>{rowData?.vehicleGroup?.name}</span>,
      },
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => <span>{rowData.vehicle && rowData.vehicle.note ? rowData.vehicle.note : '-'}</span>,
      },
      {
         name: 'startDate',
         header: t('pages.offers.startDate'),
         body: (rowData) => <span>{moment(rowData.beginDate).format('DD/MM/YYYY')}</span>,
      },
      {
         name: 'endDate',
         header: t('pages.offers.endDate'),
         body: (rowData) => <span>{moment(rowData.endDate).format('DD/MM/YYYY')}</span>,
      },
      {
         name: 'price',
         header: t('pages.offers.price'),
         body: (rowData) => formatPrice(rowData.subTotal),
         footer: formatPrice(totalPrices.totalPrice),
      },
      {
         name: 'ecoFee',
         header: t('pages.offers.ecoFee'),
         body: (rowData) => formatPrice(rowData.isEco ? rowData.ecoPrice : 0),
         footer: formatPrice(totalPrices.totalEcoFee),
      },
      {
         name: 'insurance',
         header: t('pages.offers.insurance'),
         body: (rowData) => formatPrice(rowData.insurancePrice),
         footer: formatPrice(totalPrices.totalInsurance),
      },
      {
         name: 'deliveryFee',
         header: t('pages.offers.transporterDeliveryFee'),
         body: (rowData) => formatPrice(rowData?.deliveryFee),
         footer: formatPrice(totalPrices.totalDeliveryFee),
      },
      {
         name: 'pickupFee',
         header: t('pages.offers.transporterPickupFee'),
         body: (rowData) => formatPrice(rowData?.pickupFee),
         footer: formatPrice(totalPrices.totalPickupFee),
      },
      {
         name: 'discountRate',
         header: t('pages.offers.discountRate'),
         body: (rowData) => formatPrice((parseFloat(rowData.subTotal) * rowData.discountRate) / 100) + ' (%' + rowData.discountRate + ')',
         footer: formatPrice(totalPrices.totalDiscount),
      },
      {
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) => formatPrice(calculateTotalPrice(rowData)),
         footer: formatPrice(totalPrices.totalBeforeTax),
      },
      {
         name: 'taxFee',
         header: t('pages.offers.taxFee'),
         body: (rowData) => <span>%{parseInt(rowData.tax)}</span>,
      },
      {
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => formatPrice(calculateTotalPriceAfterTax(rowData)),
         footer: formatPrice(totalPrices.totalAfterTax),
      },
   ];

   return (
      <>
         <div className="flex justify-end">
            <Button
               label={t('pages.offers.addMachine')}
               className="mb-4 me-4"
               onClick={() => {
                  setActionType('create');
                  setSelectedRowData(null);
                  setAddMachineDialog(true);
               }}
            />
         </div>
         <div>
            <TableComponent data={inventoryMachines || []} columns={tableColumns} size="small" />
         </div>
         <div className="flex justify-between mt-4">
            <Button label={t('global.prev')} icon="pi pi-chevron-left" onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
            <Button label={t('global.continue')} icon="pi pi-chevron-right" iconPos="right" onClick={() => props.setActiveIndex(props.activeIndex + 1)} />
         </div>
         <Dialog
            header={actionType === 'create' ? t('pages.offers.addMachine') : t('pages.offers.updateMachine')}
            visible={addMachineDialog}
            onHide={() => {
               setMachineInputs(resetMachineInputs());
               setSelectedRowData(null);
               setAddMachineDialog(false);
               setMachineGroupPrices(null);
            }}
            className="w-[40vw]"
         >
            <div className="grid">
               <div className="col-12 lg:col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ marginRight: '10px', flex: '1' }}>
                     <label>{t('global.table.machineGroup')}</label>
                     <Dropdown
                        filter
                        options={vehicleGroups}
                        value={machineInputs.vehicleGroupId}
                        onChange={(e) => handleInputChange(e, 'vehicleGroupId')}
                        optionLabel="name"
                        optionValue="id"
                        className="w-full input-detail"
                     />
                     {errors.vehicleGroupId && <small className="p-error">{errors.vehicleGroupId}</small>}
                  </div>
                  <Button icon="pi pi-plus" className="mt-4 h-[35px] w-[35px]" onClick={() => setCreateMachineGroupDialog(true)} />
               </div>
               <div className="col-12 lg:col-3">
                  <label>{t('pages.offers.startDate')}</label>
                  <Calendar
                     dateFormat="dd/mm/yy"
                     value={machineInputs.beginDate}
                     onChange={(e) => handleInputChange(e, 'beginDate')}
                     className="w-full input-detail"
                  />
                  {errors.beginDate && <small className="p-error">{errors.beginDate}</small>}
               </div>
               <div className="col-12 lg:col-3">
                  <label>{t('pages.offers.endDate')}</label>
                  <Calendar
                     dateFormat="dd/mm/yy"
                     value={machineInputs.endDate}
                     onChange={(e) => handleInputChange(e, 'endDate')}
                     className="w-full input-detail"
                     minDate={machineInputs.beginDate}
                  />
                  {errors.endDate && <small className="p-error">{errors.endDate}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-3 rounded-lg border-[1px] flex items-center justify-around">
                  <div>
                     <label>{t('pages.offers.daily')}</label>
                     <div>
                        <InputSwitch
                           checked={machineInputs.priceKind === 'DAILY'}
                           onChange={() => setMachineInputs({ ...machineInputs, priceKind: 'DAILY' })}
                           className="mt-1"
                        />
                     </div>
                  </div>
                  <div>
                     <label>{t('pages.offers.monthly')}</label>
                     <div>
                        <InputSwitch
                           checked={machineInputs.priceKind === 'MONTHLY'}
                           onChange={() => setMachineInputs({ ...machineInputs, priceKind: 'MONTHLY' })}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-6 lg:col-3">
                  <OverlayPanel ref={op}>
                     <div className="flex flex-col">
                        <span>
                           {t('pages.offers.price1d')} : {machineGroupPrices ? '€' + machineGroupPrices.dailyPrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.price2to5d')} : {machineGroupPrices ? '€' + machineGroupPrices.twoToFivePrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.price6to15d')} : {machineGroupPrices ? '€' + machineGroupPrices.sixToFifteenPrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.priceGt15d')} : {machineGroupPrices ? '€' + machineGroupPrices.gtFifteenPrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.monthlyPrice')} : {machineGroupPrices ? '€' + machineGroupPrices.monthlyPrice : '-'}
                        </span>
                     </div>
                  </OverlayPanel>
                  <label>{t('pages.offers.price')}</label>
                  <InputText
                     value={machineInputs.price}
                     onChange={(e) => handleInputChange(e, 'price')}
                     onFocus={() => handleFocus('price')}
                     className="w-full input-detail"
                     onMouseEnter={(e) => op.current.toggle(e)}
                     onMouseLeave={(e) => op.current.hide(e)}
                  />
                  {errors.price && <small className="p-error">{errors.price}</small>}
               </div>
               <div className="col-6 lg:col-6">
                  <label>{t('pages.offers.discountRate')}</label>
                  <div className="p-inputgroup max-h-[38px]">
                     <span className="bg-gray-200 border-none p-inputgroup-addon">%</span>
                     <InputText
                        value={machineInputs.discountRate}
                        onChange={(e) => handleInputChange(e, 'discountRate')}
                        onFocus={() => handleFocus('discountRate')}
                        className="w-full border-none bg-[#f9f9f9]"
                     />
                  </div>
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.deliveryFee')}</label>
                  <InputText
                     value={machineInputs.deliveryFee}
                     onChange={(e) => handleInputChange(e, 'deliveryFee')}
                     onFocus={() => handleFocus('deliveryFee')}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.pickupFee')}</label>
                  <InputText
                     value={machineInputs.pickupFee}
                     onChange={(e) => handleInputChange(e, 'pickupFee')}
                     onFocus={() => handleFocus('pickupFee')}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSundays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isIncludingSundays} onChange={(e) => handleInputChange(e, 'isIncludingSundays')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSaturdays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           checked={machineInputs.isIncludingSaturdays}
                           onChange={(e) => handleInputChange(e, 'isIncludingSaturdays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingPublicHolidays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           checked={machineInputs.isIncludingPublicHolidays}
                           onChange={(e) => handleInputChange(e, 'isIncludingPublicHolidays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isEco')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isEco} onChange={(e) => handleInputChange(e, 'isEco')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isInsurance')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isInsurance} onChange={(e) => handleInputChange(e, 'isInsurance')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="mt-3 col-12">
                  <Button
                     label={actionType === 'create' ? t('global.save') : t('global.update')}
                     className="w-full"
                     onClick={() => saveItems()}
                     severity="success"
                  />
               </div>
            </div>
         </Dialog>

         <Dialog
            header={t('pages.offers.addMachineGroup')}
            visible={createMachineGroupDialog}
            onHide={() => setCreateMachineGroupDialog(false)}
            className="w-[20vw]"
         >
            <div className="grid">
               <div className="col-12">
                  <label>{t('global.table.machineGroup')}</label>
                  <InputText className="w-full input-detail" value={createdMachineGroup} onChange={(e) => setCreatedMachineGroup(e.target.value)} />
               </div>
               <div className="mt-3 col-12">
                  <Button label={t('global.save')} className="w-full" severity="success" onClick={createNewMachineGroup} disabled={!createdMachineGroup} />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default InventoryMachine;
