import React, { useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import Departures from './Departures';
import Returns from './Returns';

const Logistics = () => {
   const { t } = useTranslation();

   const [selectedTable, setSelectedTable] = useState('deparutres');
   const tableOptions = [
      { label: t('pages.departuresAndReturns.departures'), value: 'deparutres' },
      { label: t('pages.departuresAndReturns.returns'), value: 'returns' },
   ];

   return (
      <div className="w-full">
         <div className="text-center mr-[2vw]">
            <SelectButton className="mb-3" value={selectedTable} options={tableOptions} optionLabel="label" onChange={(e) => setSelectedTable(e.value)} />
         </div>
         {selectedTable === 'deparutres' ? <Departures /> : <Returns />}
      </div>
   );
};

export default Logistics;
