import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import CreateInformationAddress from './CreateInformationAddress';
import { useDispatch, useSelector } from 'react-redux';
import { getAddresses, updateVehicleReservation } from '../../../../store/apis/ContractsApi';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
const TransferOfMachine = ({ transferOfMachineVisible, setTransferOfMachineVisible, selectedItem, setSelectedItem }) => {
   const dispatch = useDispatch();
   const { contract } = useSelector((state) => state.contracts);
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [adresses, setAdresses] = useState([]);
   const [inputs, setInputs] = useState({
      addressId: null,
      deliveryFee: 0,
   });
   const [errors, setErrors] = useState({});

   useEffect(() => {
      dispatch(getAddresses({ customerId: contract?.customer?.id })).then((res) => setAdresses(res));
   }, []);

   useEffect(() => {
      if (createdAddress) {
         setAdresses([...adresses, createdAddress]);
         setCreateAddressDialog(false);
      }
   }, [createdAddress]);
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };
   const handleInputChange = (value, name) => {
      if (name.includes('fee')) {
         value = value.replace(/[^0-9]/g, '');
      }
      setInputs((_inputs) => ({
         ..._inputs,
         [name]: value,
      }));
      updateErrors(name, value);
   };
   const handleFocus = (name) => {
      if (inputs[name] === 0) {
         setInputs({ ...inputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const keys = ['addressId', 'deliveryFee'];
      let isValid = true;
      keys.forEach((input) => {
         if (!inputs[input]) {
            updateErrors(input, inputs[input]);
            isValid = false;
         }
      });

      return isValid;
   };
   const handleSave = () => {
      if (!validateForm()) return;
      /*  let updateData = {
         id: selectedItem?.vehicleReservation?.id,
         addressId: inputs.addressId,
         deliveryFee: parseFloat(selectedItem?.vehicleReservation?.deliveryFee) + parseFloat(inputs.deliveryFee),
         pickupFee: parseFloat(selectedItem?.vehicleReservation?.pickupFee) + parseFloat(inputs.pickupFee),
      };
      dispatch(updateVehicleReservation(updateData)).then((response) => {
         if (response) {
            setTransferOfMachineVisible(false);
            setSelectedItem(null);
            resetForm();
         }
      }); */
   };
   const resetForm = () => {
      setInputs({
         addressId: null,
         deliveryFee: 0,
      });
      setErrors({});
   };
   return (
      <Dialog
         header={t('pages.contracts.transferOfMachine')}
         visible={transferOfMachineVisible}
         style={{ width: '50vw' }}
         onHide={() => {
            setTransferOfMachineVisible(false);
            setSelectedItem(null);
            resetForm();
         }}
      >
         <>
            <div className="grid">
               <div className="col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ marginRight: '10px', flex: '1' }}>
                     <label>{t('global.table.placeName')}*</label>
                     <Dropdown
                        filter
                        options={adresses.filter((address) => +address.id !== +contract?.address?.id)}
                        value={inputs.addressId}
                        onChange={(e) => handleInputChange(e.target.value, 'addressId')}
                        optionLabel="placeName"
                        optionValue="id"
                        className={`w-full input-detail ${errors.addressId && 'input-error'}`}
                        showClear
                        disabled={!contract?.customer?.id}
                     />
                     {errors.addressId && <small className="p-error">{errors.addressId}</small>}
                  </div>
                  <Button
                     icon="pi pi-plus"
                     className="mt-4 h-[35px] w-[35px]"
                     onClick={() => setCreateAddressDialog(true)}
                     disabled={!contract?.customer?.id}
                  />
               </div>
               <div className="col-6">
                  <label>{t('pages.contracts.deliveryFee')}*</label>
                  <InputText
                     keyfilter="pint"
                     value={inputs.deliveryFee}
                     onChange={(e) => handleInputChange(e.target.value, 'deliveryFee')}
                     onFocus={() => handleFocus('deliveryFee')}
                     className={`w-full input-detail ${errors.deliveryFee && 'input-error'}`}
                  />
                  {errors.deliveryFee && <small className="p-error">{errors.deliveryFee}</small>}
               </div>
            </div>
            <div className="text-right mt-5">
               <Button label={t('global.save')} className="mt-1" severity="info" onClick={handleSave} />
            </div>
            <Dialog
               header={t('pages.contracts.createNewAddress')}
               className="w-[70vw]"
               visible={createAddressDialog}
               onHide={() => setCreateAddressDialog(false)}
            >
               <CreateInformationAddress
                  setCreateAddressDialog={setCreateAddressDialog}
                  setCreatedAddress={setCreatedAddress}
                  customerId={contract?.customer?.id}
               />
            </Dialog>
         </>
      </Dialog>
   );
};

export default TransferOfMachine;
