import client from '../../graphql/apollo.config';
import {
   CREATE_VEHICLE_MALFUNCTION,
   CREATE_VEHICLE_MALFUNCTION_DOCUMENT,
   CREATE_VEHICLE_RESERVATION_CONTROL,
   CREATE_VEHICLE_RESERVATION_CONTROL_DOCUMENT,
   DELETE_VEHICLE_MALFUNCTION_DOCUMENT,
   DELETE_VEHICLE_RESERVATION_CONTROLS,
   DELETE_VEHICLE_RESERVATION_CONTROL_DOCUMENT,
   GET_ADDRESS,
   GET_CUSTOMER_ID_NAME,
   GET_TECHNICIANS,
   GET_VEHICLE_ID_MACHINE_CODE,
   GET_VEHICLE_MALFUNCTION,
   GET_VEHICLE_MALFUNCTIONS,
   GET_VEHICLE_MALFUNCTION_COUNT,
   GET_VEHICLE_MALFUNCTION_DOCUMENTS,
   GET_VEHICLE_MALFUNCTION_KINDS,
   GET_VEHICLE_RESERVATIONS,
   GET_VEHICLE_RESERVATION_CONTROL,
   UPDATE_ADDRESS,
   UPDATE_VEHICLE_MALFUNCTION,
   UPDATE_VEHICLE_RESERVATION,
   UPDATE_VEHICLE_RESERVATION_CONTROL,
} from '../../graphql/malfunctions';
import {
   addMalfunctionDocuments,
   addVehicleMalfunction,
   removeMalfunctionDocument,
   setMalfunctionDocuments,
   setTechnicians,
   setVehicleMalfunction,
   setVehicleMalfunctionCompletedCount,
   setVehicleMalfunctionCount,
   setVehilceMalfunctions,
   setVehicleReservations,
   updatedAddress,
   updatedVehicleMalfunction,
   updatedVehicleReservation,
   addVehicleReservationControl,
   removeVehicleReservationControl,
} from '../slices/MalfunctionSlice';
import { removeRequest, setRequest } from '../slices/sharedSlice';

export const getVehicleMalfunctionCount = ($search, $filters) => async (dispatch) => {
   dispatch(setRequest('getVehicleMalfunctionCount'));
   return client
      .query({
         query: GET_VEHICLE_MALFUNCTION_COUNT,
         variables: {
            search: $search,
            filters: $filters,
         },
      })
      .then((res) => {
         dispatch(setVehicleMalfunctionCount(res.data.vehicleMalfunctionCount));
         return res.data.vehicleMalfunctionCount;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleMalfunctionCount'));
      });
};

export const getVehicleMalfunctionCompletedCount = ($search, $filters) => async (dispatch) => {
   dispatch(setRequest('GET_VEHICLE_MALFUNCTION_COUNT'));
   return client
      .query({
         query: GET_VEHICLE_MALFUNCTION_COUNT,
         variables: {
            search: $search,
            filters: {
               ...$filters,
               isCompleted: true,
            },
         },
      })
      .then((res) => {
         dispatch(setVehicleMalfunctionCompletedCount(res.data.vehicleMalfunctionCount));
         return res.data.vehicleMalfunctionCount;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_VEHICLE_MALFUNCTION_COUNT'));
      });
};

export const getVehicleMalfunctions = ($skip, $first, $search, $filters) => async (dispatch) => {
   dispatch(setRequest('getVehicleMalfunctions'));
   return client
      .query({
         query: GET_VEHICLE_MALFUNCTIONS,
         variables: {
            skip: $skip,
            first: $first,
            search: $search,
            filters: $filters,
         },
      })
      .then((res) => {
         dispatch(setVehilceMalfunctions(res.data.vehicleMalfunctions));
         return res.data.vehicleMalfunctions;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleMalfunctions'));
      });
};

export const getVehicleMalfunctionKinds = () => async (dispatch) => {
   dispatch(setRequest('getVehicleMalfunctionKinds'));

   return client
      .query({
         query: GET_VEHICLE_MALFUNCTION_KINDS,
      })
      .then((res) => {
         return res.data.vehicleMalfunctionKinds;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleMalfunctionKinds'));
      });
};

export const getVehicleIdMachineCode = ($filters) => async (dispatch) => {
   dispatch(setRequest('getVehicleIdMachineCode'));
   return client
      .query({
         query: GET_VEHICLE_ID_MACHINE_CODE,
         variables: {
            filters: $filters,
         },
      })
      .then((res) => {
         return res.data.vehicles;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleIdMachineCode'));
      });
};

export const getCustomerIdName = () => async (dispatch) => {
   dispatch(setRequest('getCustomerIdName'));
   return client
      .query({
         query: GET_CUSTOMER_ID_NAME,
      })
      .then((res) => {
         return res.data.customers;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomerIdName'));
      });
};

export const createVehicleMalfunction = (data) => async (dispatch) => {
   dispatch(setRequest('createVehicleMalfunction'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_MALFUNCTION,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(getVehicleMalfunctionCount());
         dispatch(addVehicleMalfunction(res.data.createVehicleMalfunction));
         return res.data.createVehicleMalfunction.vehicleMalfunction;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createVehicleMalfunction'));
      });
};

export const updateVehicleMalfunction = (data) => async (dispatch) => {
   dispatch(setRequest('updateVehicleMalfunction'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_MALFUNCTION,
         variables: {
            data: data,
         },
      })
      .then((res) => {
         dispatch(updatedVehicleMalfunction(res.data.updateVehicleMalfunction.vehicleMalfunction));
         return res.data.updateVehicleMalfunction;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateVehicleMalfunction'));
      });
};

export const updateVehicleMalfunctionStatus = (data) => async (dispatch) => {
   dispatch(setRequest('updateVehicleMalfunction'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_MALFUNCTION,
         variables: {
            data: {
               id: data.id,
               isCompleted: data.isCompleted,
               repairDate: data.repairDate,
               vehicleId: data.vehicleId,
            },
         },
      })
      .then((res) => {
         dispatch(updatedVehicleMalfunction(res.data.updateVehicleMalfunction.vehicleMalfunction));
         dispatch(getVehicleMalfunctionCompletedCount(data.search, data.filters));
         return res.data.updateVehicleMalfunction;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateVehicleMalfunction'));
      });
};

export const getVehicleMalfunction =
   ({ id, informations, malfunctions, solutions }) =>
   async (dispatch) => {
      dispatch(setRequest('GET_VEHICLE_MALFUNCTION'));
      return client
         .query({
            query: GET_VEHICLE_MALFUNCTION,
            variables: {
               id,
               informations,
               malfunctions,
               solutions,
            },
         })
         .then((res) => {
            dispatch(setVehicleMalfunction(res.data.vehicleMalfunction));
            return res.data.vehicleMalfunction;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('GET_VEHICLE_MALFUNCTION'));
         });
   };

export const getTechnicians = () => async (dispatch) => {
   dispatch(setRequest('GET_TECHNICIANS'));
   return client
      .query({
         query: GET_TECHNICIANS,
      })
      .then((res) => {
         dispatch(setTechnicians(res.data.users));
         return res.data.users;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_TECHNICIANS'));
      });
};

export const getVehicleMalfunctionDocuments = (vehicleMalfunctionId) => async (dispatch) => {
   dispatch(setRequest('GET_VEHICLE_MALFUNCTION_DOCUMENTS'));
   return client
      .query({
         query: GET_VEHICLE_MALFUNCTION_DOCUMENTS,
         variables: {
            vehicleMalfunctionId,
         },
      })
      .then((res) => {
         dispatch(setMalfunctionDocuments(res.data.vehicleMalfunctionDocuments));
         return res.data.vehicleMalfunctionDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_VEHICLE_MALFUNCTION_DOCUMENTS'));
      });
};

export const createVehicleMalfunctionDocument = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_VEHICLE_MALFUNCTION_DOCUMENT'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_MALFUNCTION_DOCUMENT,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addMalfunctionDocuments(res.data.createVehicleMalfunctionDocument.vehicleMalfunctionDocument));
         return res.data.createVehicleMalfunctionDocument;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_VEHICLE_MALFUNCTION_DOCUMENT'));
      });
};

export const deleteVehicleMalfunctionDocument = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_VEHICLE_MALFUNCTION_DOCUMENT'));
   return client
      .mutate({
         mutation: DELETE_VEHICLE_MALFUNCTION_DOCUMENT,
         variables: {
            ids,
         },
      })
      .then((res) => {
         dispatch(removeMalfunctionDocument(ids[0]));
         return res.data.deleteVehicleMalfunctionDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_VEHICLE_MALFUNCTION_DOCUMENT'));
      });
};

export const getAddress = (id) => async (dispatch) => {
   dispatch(setRequest('GET_ADDRESS'));
   return client
      .query({
         query: GET_ADDRESS,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.address;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_ADDRESS'));
      });
};

export const updateAddress = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_ADDRESS'));
   return client
      .mutate({
         mutation: UPDATE_ADDRESS,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.updateAddress.address;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_ADDRESS'));
      });
};

export const getVehicleReservations =
   ({ date, statuses, isContracted, isSupplier }) =>
   async (dispatch) => {
      dispatch(setRequest('GET_VEHICLE_RESERVATIONS'));
      return client
         .query({
            query: GET_VEHICLE_RESERVATIONS,
            variables: {
               statuses,
               date,
               isContracted,
               isSupplier,
            },
         })
         .then((res) => {
            dispatch(setVehicleReservations(res.data.vehicleReservations));
            return res.data.vehicleReservations;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('GET_VEHICLE_RESERVATIONS'));
         });
   };

export const updateVehicleReservation = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(updatedVehicleReservation(res.data.updateVehicleReservation.vehicleReservation));
         return res.data.updateVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_RESERVATION'));
      });
};

export const getVehicleReservationControl = (id) => async (dispatch) => {
   dispatch(setRequest('GET_VEHICLE_RESERVATION_CONTROL'));
   return client
      .query({
         query: GET_VEHICLE_RESERVATION_CONTROL,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.vehicleReservationControl;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_VEHICLE_RESERVATION_CONTROL'));
      });
};

export const createVehicleReservationControl = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_VEHICLE_RESERVATION_CONTROL'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_RESERVATION_CONTROL,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addVehicleReservationControl(res.data.createVehicleReservationControl.vehicleReservationControl));
         return res.data.createVehicleReservationControl.vehicleReservationControl;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_VEHICLE_RESERVATION_CONTROL'));
      });
};

export const updateVehicleReservationControl = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_RESERVATION_CONTROL'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION_CONTROL,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicleReservationControl.vehicleReservationControl;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_RESERVATION_CONTROL'));
      });
};

export const deleteVehicleReservationControls = (data) => async (dispatch) => {
   const _id = +data.vehicleReservationControlId;
   console.log('deleteVehicleReservationControls', data, _id);
   dispatch(setRequest('DELETE_VEHICLE_RESERVATION_CONTROLS'));
   return client
      .mutate({
         mutation: DELETE_VEHICLE_RESERVATION_CONTROLS,
         variables: {
            ids: [_id],
         },
      })
      .then((res) => {
         dispatch(removeVehicleReservationControl(data));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_VEHICLE_RESERVATION_CONTROLS'));
      });
};

export const createVehicleReservationControlDocument = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_VEHICLE_RESERVATION_CONTROL_DOCUMENT'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_RESERVATION_CONTROL_DOCUMENT,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.createVehicleReservationControlDocument.vehicleReservationControlDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_VEHICLE_RESERVATION_CONTROL_DOCUMENT'));
      });
};

export const deleteVehicleReservationControlDocument = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_VEHICLE_RESERVATION_CONTROL_DOCUMENT'));
   return client
      .mutate({
         mutation: DELETE_VEHICLE_RESERVATION_CONTROL_DOCUMENT,
         variables: {
            ids,
         },
      })
      .then((res) => {
         return res.data.deleteVehicleReservationControlDocuments;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_VEHICLE_RESERVATION_CONTROL_DOCUMENT'));
      });
};
