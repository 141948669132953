import { t } from 'i18next';
import { Steps } from 'primereact/steps';
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import Informations from './Informations';
import InventoryMachine from './InventoryMachine';
import SupplierMachine from './SupplierMachine';
import SaleItems from './SaleItems';

const AddNewOffer = (props) => {
   const [activeIndex, setActiveIndex] = useState(0);
   const steps = [
      {
         label: t('pages.offers.informations'),
      },
      {
         label: t('pages.offers.inventoryMachine'),
      },
      {
         label: t('pages.offers.supplierMachine'),
      },
      {
         label: t('pages.offers.saleItems'),
      },
   ];
   const [createdOrderId, setCreatedOrderId] = useState(null);
   const [informationsInputs, setInformationsInputs] = useState({
      orderKindId: 1,
      customerId: null,
      addressId: null,
      referenceNumber: '',
      description: '',
      privacyDescription: '',
      isFactor: false,
   });
   const [inventoryMachines, setInventoryMachines] = useState([]);
   const [supplierMachines, setSupplierMachines] = useState([]);
   const [items, setItems] = useState([]);
   const [customer, setCustomer] = useState({});

   return (
      <div>
         <Dialog
            header={t('pages.offers.addNewOffer')}
            visible={props.addNewOfferVisible}
            className="w-[85vw]"
            onHide={() => {
               setInformationsInputs({
                  orderKindId: 1,
                  customerId: null,
                  addressId: null,
                  referenceNumber: '',
                  description: '',
                  privacyDescription: '',
                  isFactor: false,
               });
               setInventoryMachines([]);
               setSupplierMachines([]);
               setItems([]);
               setCreatedOrderId(null);
               setActiveIndex(0);
               props.setAddNewOfferVisible(false);
            }}
         >
            <Steps model={steps} activeIndex={activeIndex} className="mb-5" />
            <div className="p-3">
               {activeIndex === 0 && (
                  <Informations
                     informationsInputs={informationsInputs}
                     setInformationsInputs={setInformationsInputs}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     createdOrderId={createdOrderId}
                     setCustomer={setCustomer}
                  />
               )}
               {activeIndex === 1 && (
                  <InventoryMachine
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     inventoryMachines={inventoryMachines}
                     setInventoryMachines={setInventoryMachines}
                     informationsInputs={informationsInputs}
                     createdOrderId={createdOrderId}
                     setCreatedOrderId={setCreatedOrderId}
                     customer={customer}
                  />
               )}
               {activeIndex === 2 && (
                  <SupplierMachine
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     supplierMachines={supplierMachines}
                     setSupplierMachines={setSupplierMachines}
                     informationsInputs={informationsInputs}
                     createdOrderId={createdOrderId}
                     setCreatedOrderId={setCreatedOrderId}
                     inventoryMachines={inventoryMachines}
                     customer={customer}
                  />
               )}
               {activeIndex === 3 && (
                  <SaleItems
                     items={items}
                     setItems={setItems}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     informationsInputs={informationsInputs}
                     setInformationsInputs={setInformationsInputs}
                     createdOrderId={createdOrderId}
                     setCreatedOrderId={setCreatedOrderId}
                     setAddNewOfferVisible={props.setAddNewOfferVisible}
                     setSupplierMachines={setSupplierMachines}
                     setInventoryMachines={setInventoryMachines}
                  />
               )}
            </div>
         </Dialog>
      </div>
   );
};

export default AddNewOffer;
