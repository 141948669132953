import React from "react";

import { Outlet } from "react-router-dom";

const AuthLayout = () => {
	return (
		<div className="h-full bg-slate-100">
			<Outlet />
		</div>
	);
};

export default AuthLayout;
