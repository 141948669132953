import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   showAddNewMachineDialog: false,
   showViewMachineDetailsDialog: false,
   stepCounter: 0,
   selectedMachineId: null,
   selectedMachine: null,
   isFromSupplierChecked: false,
   showAddSupplier: false,
   vehicleGroups: [],
};

export const machineSlice = createSlice({
   name: 'machine',
   initialState,
   reducers: {
      setShowAddNewMachineDialog: (state, action) => {
         state.showAddNewMachineDialog = action.payload;
      },
      setShowViewMachineDetailsDialog: (state, action) => {
         state.showViewMachineDetailsDialog = action.payload;
      },
      setStepCounter: (state, action) => {
         state.stepCounter = action.payload;
      },
      setSelectedMachineId: (state, action) => {
         state.selectedMachineId = action.payload;
      },
      setSelectedMachine: (state, action) => {
         state.selectedMachine = action.payload;
      },
      setIsFromSupplierChecked: (state, action) => {
         state.isFromSupplierChecked = action.payload ? true : false;
      },
      setShowAddSupplier: (state, action) => {
         state.showAddSupplier = action.payload;
      },
      setVehicleGroups: (state, action) => {
         state.vehicleGroups = action.payload;
      },
   },
});

export const {
   setShowAddNewMachineDialog,
   setShowViewMachineDetailsDialog,
   setStepCounter,
   setSelectedMachineId,
   setSelectedMachine,
   setIsFromSupplierChecked,
   setShowAddSupplier,
   setVehicleGroups,
} = machineSlice.actions;

export default machineSlice.reducer;
