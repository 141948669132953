import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   createNewTransporterOrder,
   createTransporterOrderItem,
   getAddresses,
   getCustomers,
   updateContract,
   updateTransporterOrderItem,
   updateTransporterOrder,
} from '../../../../store/apis/ContractsApi';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import CreateInformationAddress from './CreateInformationAddress';
import { Divider } from 'primereact/divider';
import TransportOrder from './TransportOrder';
import moment from 'moment';
import { setContract } from '../../../../store/slices/contractsSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import RentOffer1 from '../../../../components/PDFS/RentOffer/RentOffer1';
import SaleOfferPDF from '../../../../components/PDFS/SaleOfferPDF';

const Informations = (props) => {
   const dispatch = useDispatch();
   const navigation = useNavigate();
   const { contract } = useSelector((state) => state.contracts);
   const [customers, setCustomers] = useState([]);
   const [addresses, setAddresses] = useState([]);
   const [errors, setErrors] = useState({});
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [informationsInputs, setInformationsInputs] = useState({});
   const [transporterDialog, setTransporterDialog] = useState(false);
   const [departureTransporterId, setDepartureTransporterId] = useState(null);
   const [returnTransporterId, setReturnTransporterId] = useState(null);
   const [transporterInputs, setTransporterInputs] = React.useState();
   const [transporterErrors, setTransporterErrors] = React.useState();
   const [items, setItems] = React.useState([]);
   const [itemsError, setItemsError] = React.useState([]);
   const [transportKind, setTransportKind] = React.useState('');
   const [navigateCondition, setNavigateCondition] = React.useState(false);
   const [selectedAddressId, setSelectedAddressId] = useState(null);

   useEffect(() => {
      dispatch(getCustomers()).then((res) => setCustomers(res));
      setInformationsValues(contract);
      setReturnTransporterId(contract?.returnTransporterId);
      setDepartureTransporterId(contract?.departureTransporterId);
   }, []);

   useEffect(() => {
      if (informationsInputs.customerId) {
         dispatch(getAddresses({ customerId: informationsInputs.customerId })).then((res) => setAddresses(res));
      }
   }, [informationsInputs.customerId]);

   useEffect(() => {
      if (!createdAddress) return;
      if (selectedAddressId) {
         setAddresses(addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)));
      } else {
         setAddresses([...addresses, createdAddress]);
      }
      setSelectedAddressId(null);
      transporterDialog
         ? setTransporterInputs({
              ...transporterInputs,
              addressId: createdAddress.id,
           })
         : setInformationsInputs({
              ...informationsInputs,
              addressId: createdAddress.id,
           });
   }, [createdAddress]);

   useEffect(() => {
      if (navigateCondition) {
         navigation('/commerce/offers', { state: { orderId: contract?.id } });
         setNavigateCondition(false);
      }
   }, [navigateCondition]);
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (value, name) => {
      setInformationsInputs((prev) => ({ ...prev, [name]: value }));
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['customerId', 'addressId'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!informationsInputs[input]) {
            updateErrors(input, informationsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
         // If the offer is created, update the offer

         const data = {
            id: contract.id,
            ...informationsInputs,
         };
         delete data.orderKindId;
         delete data.orderNumber;
         delete data.returnTransporterOrder;
         delete data.departureTransporterOrder;

         dispatch(
            updateContract({
               ...data,
               returnTransporterId: returnTransporterId === undefined ? null : returnTransporterId,
               departureTransporterId: departureTransporterId === undefined ? null : departureTransporterId,
            }),
         ).then((res) => {
            if (res) {
               dispatch(setContract(res));
               setInformationsValues(res);
               toast.success(t('global.updateSuccess'));
               if (!res.isContracted) {
                  setNavigateCondition(true);
               }
            }
         });
      }
   };

   const setInformationsValues = (data) => {
      setInformationsInputs({
         orderNumber: data?.orderNumber || '',
         customerId: data?.customer?.id || null,
         addressId: data?.address?.id || null,
         referenceNumber: data?.referenceNumber || '',
         description: data?.description || '',
         privacyDescription: data?.privacyDescription || '',
         isFactor: data?.isFactor || false,
         isOffered: data?.isOffered || false,
         isContracted: data?.isContracted || false,
         departureTransporterId: data?.departureTransporterId || null,
         returnTransporterId: data?.returnTransporterId || null,
         departureTransporterOrder: data?.departureTransporterOrder || null,
         returnTransporterOrder: data?.returnTransporterOrder || null,
      });
   };

   const updateTransporterErrors = (name, value) => {
      setTransporterErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const updateItemsErrors = (name, value, index) => {
      setItemsError((prevErrors) => {
         let newErrors = [...prevErrors];
         newErrors[index] = {
            ...newErrors[index],
            [name]: !value ? t('global.requiredField') : '',
         };
         return newErrors;
      });
   };

   const handleTransporterInputChange = (value, name) => {
      setTransporterInputs((prev) => ({ ...prev, [name]: value }));
      updateTransporterErrors(name, value);
   };

   const handleItemsChange = (value, name, index) => {
      value = name === 'price' ? value.replace(/[^0-9]/g, '') : value;
      let newItems = [...items];
      newItems[index] = { ...newItems[index], [name]: value };
      setItems(newItems);
      updateItemsErrors(name, value, index);
   };

   const validateTransporter = () => {
      const inputs = ['transporterId', 'addressId'];
      let valid = true;
      inputs.forEach((input) => {
         if (!transporterInputs[input]) {
            updateTransporterErrors(input, false);
            valid = false;
         }
      });
      return valid;
   };
   const validateItems = () => {
      let inputs = ['category', 'description', 'beginDate', 'endDate', 'quantity', 'price'];
      let valid = true;
      items.forEach((item, index) => {
         inputs.forEach((input) => {
            if (!item[input]) {
               updateItemsErrors(input, false, index);
               valid = false;
            }
         });
      });
      return valid;
   };

   const handleSaveTransporter = async (e) => {
      e.preventDefault();
      if (!validateTransporter() || !validateItems()) return;
      if (transporterInputs.id) {
         delete transporterInputs.transporterOrderNumber;
         await dispatch(updateTransporterOrder(transporterInputs)).then(async (res) => {
            if (res) {
               const updatedItems = await Promise.all(
                  items.map(async (item) => {
                     if (item.id) {
                        const updatedItem = await dispatch(
                           updateTransporterOrderItem({
                              ...item,
                              currencyId: 2,
                              beginDate: moment(item.beginDate).format('YYYY-MM-DD'),
                              endDate: moment(item.endDate).format('YYYY-MM-DD'),
                           }),
                        );
                        return updatedItem;
                     } else {
                        const createdItem = await dispatch(
                           createTransporterOrderItem({
                              ...item,
                              transporterOrderId: res.id,
                              currencyId: 2,
                              beginDate: moment(item.beginDate).format('YYYY-MM-DD'),
                              endDate: moment(item.endDate).format('YYYY-MM-DD'),
                           }),
                        );
                        return createdItem;
                     }
                  }),
               );
               dispatch(
                  setContract({
                     ...contract,
                     [`${transportKind}TransporterOrder`]: {
                        ...res,
                        transporterOrderItems: updatedItems,
                     },
                  }),
               );
               setInformationsValues({
                  ...contract,
                  [`${transportKind}TransporterOrder`]: {
                     ...res,
                     transporterOrderItems: updatedItems,
                  },
               });
               setItems(updatedItems);
               /* setTransporterDialog(false); */ toast.success(t('pages.contracts.SavedTransporterOrder'));
            }
         });
      } else {
         let data = {
            ...transporterInputs,
         };
         delete data.id;
         await dispatch(createNewTransporterOrder(data)).then(async (res) => {
            if (res) {
               await dispatch(
                  updateContract({
                     id: contract.id,
                     [`${transportKind}TransporterOrderId`]: res.id,
                  }),
               );
               const updatedItems = await Promise.all(
                  items.map(async (item) => {
                     const createdItem = await dispatch(
                        createTransporterOrderItem({
                           ...item,
                           transporterOrderId: res.id,
                           currencyId: 2,
                           beginDate: moment(item.beginDate).format('YYYY-MM-DD'),
                           endDate: moment(item.endDate).format('YYYY-MM-DD'),
                        }),
                     );
                     return createdItem;
                  }),
               );
               dispatch(
                  setContract({
                     ...contract,
                     [`${transportKind}TransporterOrder`]: {
                        ...res,
                        transporterOrderItems: updatedItems,
                     },
                  }),
               );
               setInformationsValues({
                  ...contract,
                  [`${transportKind}TransporterOrder`]: {
                     ...res,
                     transporterOrderItems: updatedItems,
                  },
               });
               setItems(updatedItems);
               setTransporterDialog(false);
            }
         });
      }
   };
   const handleFocus = (name, index) => {
      if (items[index][name] === 0) {
         let newItems = [...items];
         newItems[index] = { ...newItems[index], [name]: '' };
         setItems(newItems);
      }
   };
   const updateAddress = () => {
      if (transporterDialog) {
         setSelectedAddressId(transporterInputs.addressId);
      } else {
         setSelectedAddressId(informationsInputs.addressId);
      }
      setCreateAddressDialog(true);
   };
   return (
      <>
         <div className="grid">
            <Divider align="start">
               <b>{t('pages.contracts.information')}</b>
            </Divider>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.contracts.number')}*</label>
               <InputText
                  value={informationsInputs.orderNumber}
                  onChange={(e) => handleInputChange(e.target.value, 'orderNumber')}
                  className="w-full input-detail"
                  disabled
               />
               {errors.orderNumber && <small className="p-error">{errors.orderNumber}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.contracts.customer')}*</label>
               <Dropdown
                  filter
                  options={customers.filter((customer) => customer.customerKind.name === 'CUSTOMER')}
                  value={informationsInputs?.customerId}
                  onChange={(e) => {
                     handleInputChange(e.target.value, 'customerId');
                     handleInputChange(null, 'addressId');
                  }}
                  optionLabel="companyName"
                  optionValue="id"
                  className={`w-full input-detail ${errors.customerId && 'input-error'}`}
               />
               {errors.customerId && <small className="p-error">{errors.customerId}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
               <div style={{ marginRight: '10px', flex: '1' }}>
                  <label>{t('global.table.placeName')}*</label>
                  <Dropdown
                     filter
                     options={addresses}
                     value={informationsInputs.addressId}
                     onChange={(e) => handleInputChange(e.target.value, 'addressId')}
                     optionLabel="placeName"
                     optionValue="id"
                     className={`w-full input-detail ${errors.addressId && 'input-error'}`}
                     showClear
                     disabled={!informationsInputs.customerId}
                  />
                  {errors.addressId && <small className="p-error">{errors.addressId}</small>}
               </div>
               {informationsInputs.addressId && informationsInputs.customerId && (
                  <Button
                     icon="pi pi-pencil"
                     severity="warning"
                     className="mt-4 me-2 h-[35px] w-[35px]"
                     onClick={updateAddress}
                     disabled={!informationsInputs.customerId}
                  />
               )}
               <Button
                  icon="pi pi-plus"
                  className="mt-4 h-[35px] w-[35px]"
                  onClick={() => setCreateAddressDialog(true)}
                  disabled={!informationsInputs.customerId}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.contracts.poNumber')}</label>
               <InputText
                  value={informationsInputs.referenceNumber}
                  onChange={(e) => handleInputChange(e.target.value, 'referenceNumber')}
                  className="w-full input-detail"
                  maxLength={50}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.contracts.explanation')}</label>
               <InputText
                  value={informationsInputs.description}
                  onChange={(e) => handleInputChange(e.target.value, 'description')}
                  className="w-full input-detail"
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.contracts.privacyExplanation')}</label>
               <InputText
                  value={informationsInputs.privacyDescription}
                  onChange={(e) => handleInputChange(e.target.value, 'privacyDescription')}
                  className="w-full input-detail"
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <div className="flex items-center ">
                  <label className="mb-2">{t('pages.contracts.factor')}</label>
                  <div>
                     <InputSwitch checked={informationsInputs.isFactor} onChange={(e) => handleInputChange(e.target.value, 'isFactor')} />
                  </div>
               </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4 ">
               <div className="flex ">
                  <label>{t('pages.contracts.offered')}</label>
                  <div>
                     <InputSwitch checked={informationsInputs.isOffered} onChange={(e) => handleInputChange(e.target.value, 'isOffered')} disabled />
                  </div>
               </div>
            </div>
            <div className=" col-12 md:col-6 lg:col-4">
               <div className="flex">
                  <label>{t('pages.contracts.contracted')}</label>
                  <div>
                     <InputSwitch checked={informationsInputs.isContracted} onChange={(e) => handleInputChange(e.target.value, 'isContracted')} />
                  </div>
               </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4"></div>
            <div className="col-3 md:col-3 lg:col-4">
               <div className="grid justify-end m-0">
                  {contract?.orderKind?.name === 'RENT' ? (
                     <RentOffer1 data={contract} header={'offered'} />
                  ) : (
                     <SaleOfferPDF data={contract} header={'offered'} />
                  )}
               </div>
            </div>
            <div className=" col-12 md:col-6 lg:col-4">
               <div className="grid justify-between m-0">
                  {contract?.orderKind?.name === 'RENT' ? (
                     <RentOffer1 data={contract} header={'contract'} />
                  ) : (
                     <SaleOfferPDF data={contract} header={'contract'} />
                  )}
                  {contract?.orderKind?.name === 'RENT' ? (
                     <RentOffer1 data={contract} header={'seeProforma'} />
                  ) : (
                     <SaleOfferPDF data={contract} header={'seeProforma'} />
                  )}
                  {contract?.orderKind?.name === 'RENT' ? (
                     <RentOffer1 data={contract} header={'deliveryNote'} />
                  ) : (
                     <SaleOfferPDF data={contract} header={'deliveryNote'} />
                  )}
                  {contract?.orderKind?.name === 'RENT' ? (
                     <RentOffer1 data={contract} header={'pickuppNote'} />
                  ) : (
                     <SaleOfferPDF data={contract} header={'pickuppNote'} />
                  )}
               </div>
            </div>
         </div>

         <div className="flex flex-col">
            <Divider align="start">
               <b>{t('pages.contracts.transporter')}</b>
            </Divider>
            <div className="flex items-end justify-start">
               <div className="w-[200px]">
                  <label>{t('pages.contracts.departure')}</label>
                  <Dropdown
                     filter
                     options={customers.filter((customer) => customer.customerKind.name === 'TRANSPORTER')}
                     value={departureTransporterId}
                     onChange={(e) => setDepartureTransporterId(e.target.value)}
                     optionLabel="companyName"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               {informationsInputs.departureTransporterId && informationsInputs.departureTransporterId === departureTransporterId && (
                  <Button
                     label={t('pages.contracts.poTransporter')}
                     className="ml-2 "
                     severity="success"
                     onClick={() => {
                        let _inputs = {
                           addressId: informationsInputs.departureTransporterOrder
                              ? informationsInputs.departureTransporterOrder.address.id
                              : informationsInputs.addressId,
                           transporterId: departureTransporterId,
                           referenceNumber: `${informationsInputs.orderNumber}(${contract.customer.companyName ?? ''})`,
                           contractPerson: informationsInputs.departureTransporterOrder?.contractPerson ?? '',
                           description: informationsInputs.departureTransporterOrder?.description ?? '',
                           privacyDescription: informationsInputs.departureTransporterOrder?.privacyDescription ?? '',
                           id: contract.departureTransporterOrder?.id,
                           status: informationsInputs.departureTransporterOrder?.status,
                           transporterOrderNumber: informationsInputs.departureTransporterOrder?.transporterOrderNumber,
                        };

                        setItems(
                           contract.departureTransporterOrder && contract.departureTransporterOrder.transporterOrderItems.length > 0
                              ? contract.departureTransporterOrder.transporterOrderItems.map((item) => ({
                                   id: item.id,
                                   category: 'TRANSPORT',
                                   description: item.description,
                                   quantity: item.quantity,
                                   price: item.price,
                                   beginDate: item.beginDate,
                                   endDate: item.endDate,
                                }))
                              : contract.orderItems && contract.orderItems.length > 0
                              ? contract.orderItems
                                   .filter((item) => item.vehicleReservation)
                                   .map((item) => ({
                                      description:
                                         item.vehicleReservation.vehicle && item.vehicleReservation.vehicle.note
                                            ? item.vehicleReservation.vehicle.note
                                            : item.vehicleReservation.vehicleGroup.description,
                                      category: 'TRANSPORT',
                                      quantity: item?.quantity,
                                      price: 0,
                                      beginDate: item.vehicleReservation?.beginDate,
                                      endDate: item.vehicleReservation?.endDate,
                                   }))
                              : [],
                        );
                        setTransporterInputs(_inputs);
                        setTransporterDialog(true);
                        setTransportKind('departure');
                     }}
                  />
               )}
            </div>
            <div className="flex items-end justify-start">
               <div className="w-[200px]">
                  <label>{t('pages.contracts.return')}</label>
                  <Dropdown
                     filter
                     options={customers.filter((customer) => customer.customerKind.name === 'TRANSPORTER')}
                     value={returnTransporterId}
                     onChange={(e) => setReturnTransporterId(e.target.value)}
                     optionLabel="companyName"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               {informationsInputs.returnTransporterId && informationsInputs.returnTransporterId === returnTransporterId && (
                  <Button
                     label={t('pages.contracts.poTransporter')}
                     className="ml-2 "
                     severity="success"
                     onClick={() => {
                        let _inputs = {
                           addressId: informationsInputs.returnTransporterOrder
                              ? informationsInputs.returnTransporterOrder.address.id
                              : informationsInputs.addressId,
                           transporterId: returnTransporterId,
                           referenceNumber: `${informationsInputs.orderNumber}(${contract.customer.companyName ?? ''})`,
                           contractPerson: informationsInputs.returnTransporterOrder?.contractPerson ?? '',
                           description: informationsInputs.returnTransporterOrder?.description ?? '',
                           privacyDescription: informationsInputs.returnTransporterOrder?.privacyDescription ?? '',
                           id: contract.returnTransporterOrder?.id,
                           status: informationsInputs.returnTransporterOrder?.status,
                           transporterOrderNumber: informationsInputs.returnTransporterOrder?.transporterOrderNumber,
                        };
                        setItems(
                           contract.returnTransporterOrder && contract.returnTransporterOrder.transporterOrderItems.length > 0
                              ? contract.returnTransporterOrder.transporterOrderItems.map((item) => ({
                                   id: item.id,
                                   category: 'TRANSPORT',
                                   description: item.description,
                                   quantity: item.quantity,
                                   price: item.price,
                                   beginDate: item.beginDate,
                                   endDate: item.endDate,
                                }))
                              : contract.orderItems && contract.orderItems.length > 0
                              ? contract.orderItems
                                   .filter((item) => item.vehicleReservation && item.vehicleReservation.vehicle)
                                   .map((item) => ({
                                      description: item.vehicleReservation.vehicle.note,
                                      category: 'TRANSPORT',
                                      quantity: 1,
                                      price: '0',
                                   }))
                              : [],
                        );
                        setTransporterInputs(_inputs);
                        setTransporterDialog(true);
                        setTransportKind('return');
                     }}
                  />
               )}
            </div>
         </div>
         <div className="flex justify-end mt-1">
            <Button label={t('global.update')} iconPos="right" onClick={handleSubmit} />
         </div>

         <Dialog
            header={selectedAddressId ? t('pages.contracts.updateAddress') : t('pages.contracts.createNewAddress')}
            className="w-[70vw]"
            visible={createAddressDialog}
            onHide={() => {
               setCreateAddressDialog(false);
               setSelectedAddressId(null);
            }}
         >
            <CreateInformationAddress
               setCreateAddressDialog={setCreateAddressDialog}
               setCreatedAddress={setCreatedAddress}
               customerId={informationsInputs.customerId}
               selectedAddressId={selectedAddressId}
            />
         </Dialog>
         <Dialog
            header={
               transporterInputs?.transporterOrderNumber
                  ? t('pages.contracts.transporterPO', { transporterOrderNumber: transporterInputs?.transporterOrderNumber })
                  : t('pages.contracts.createNewTransporterOrder')
            }
            className="w-[70vw]"
            visible={transporterDialog}
            onHide={() => setTransporterDialog(false)}
         >
            <TransportOrder
               transporterDialog={transporterDialog}
               setTransporterDialog={setTransporterDialog}
               transporterInputs={transporterInputs}
               transporterErrors={transporterErrors}
               handleTransporterInputChange={handleTransporterInputChange}
               setCreateAddressDialog={setCreateAddressDialog}
               customers={customers}
               addresses={addresses}
               handleSaveTransporter={handleSaveTransporter}
               setTransporterInputs={setTransporterInputs}
               items={items}
               setItems={setItems}
               handleItemsChange={handleItemsChange}
               itemsError={itemsError}
               setItemsError={setItemsError}
               handleFocus={handleFocus}
               updateAddress={updateAddress}
            />
         </Dialog>
      </>
   );
};

export default Informations;
