import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   cancelOfferWithReservations,
   getVehicleReservations,
   handleUpdateVehicleReservationDate,
   handleUpdateVehicleReservationStatus,
} from '../../store/apis/LogisticsApi';
import moment from 'moment';
import { t } from 'i18next';
import TableComponent from '../../components/TableComponent';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import toast from 'react-hot-toast';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';
import { updateVehicleStatus } from '../../store/apis/ManagementApi';

const Departures = () => {
   const dispatch = useDispatch();
   const navigation = useNavigate();
   const { vehicleReservations } = useSelector((state) => state.logistics);
   const [updateReservationStatus, setUpdateReservationStatus] = useState({
      visible: false,
      data: null,
      action: '',
   });
   const [updateReservationDates, setUpdateReservationDates] = useState({
      visible: false,
      data: null,
   });
   const [reservationDates, setReservationDates] = useState({
      beginDate: null,
      endDate: null,
   });
   const [cancelOfferAndReservations, setCancelOfferAndReservations] = useState({
      visible: false,
      data: null,
   });

   useEffect(() => {
      dispatch(getVehicleReservations(moment().add(5, 'days').format('YYYY-MM-DD'), undefined, ['READY_TO_DEPART', 'RENT']));
   }, []);

   useEffect(() => {
      if (updateReservationDates.data) {
         setReservationDates({
            beginDate: moment(updateReservationDates.data.beginDate).toDate(),
            endDate: moment(updateReservationDates.data.endDate).toDate(),
         });
      }
   }, [updateReservationDates.data]);

   const updateVehicleReservationStatus = () => {
      const data = {
         id: +updateReservationStatus.data.id,
         status: updateReservationStatus.action === 'confirm' ? 'RENT' : undefined,
         isDelivered: updateReservationStatus.action === 'delivered' ? true : undefined,
         vehicleId: +updateReservationStatus.data.vehicle.id,
      };
      dispatch(handleUpdateVehicleReservationStatus(data)).then(() => {
         if (updateReservationStatus?.data?.orderItems[0]?.category === 'SUP') {
            dispatch(updateVehicleStatus({ id: +updateReservationStatus.data.vehicle.id, status: 'RENT' }));
         }
         setUpdateReservationStatus({
            visible: false,
            data: null,
         });
         toast.success(t('pages.logistics.confirmMachineSuccess'));
      });
   };

   const updateVehicleReservationDate = () => {
      const data = {
         id: +updateReservationDates.data.id,
         beginDate: moment(reservationDates.beginDate).format('YYYY-MM-DD'),
         endDate: moment(reservationDates.endDate).format('YYYY-MM-DD'),
         status: updateReservationDates.data.orderItems[0].category === 'SUP' ? 'RESERVE' : 'READY_TO_DEPART',
      };
      dispatch(handleUpdateVehicleReservationDate(data)).then(() => {
         setUpdateReservationDates({
            visible: false,
            data: null,
         });
         toast.success(t('pages.logistics.updateReservationDatesSuccess'));
      });
   };

   const cancelReservations = () => {
      const data = {
         id: +cancelOfferAndReservations.data.orderItems[0].order.id,
         isContracted: false,
      };
      dispatch(cancelOfferWithReservations(data)).then(() => {
         setCancelOfferAndReservations({
            visible: false,
            data: null,
         });
         toast.success(t('pages.logistics.offerCancellationSuccess'));
      });
   };

   const tableColumns = [
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName = rowData.customer?.companyName;
            return companyName ? <span className="text-blue-500 underline cursor-pointer">{companyName}</span> : <span>-</span>;
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            const contractNo = rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.orderNumber;
            return contractNo ? <span className="text-blue-500 underline cursor-pointer">{contractNo}</span> : <span>-</span>;
         },
      },
      {
         name: 'transporterOrderNumber',
         header: t('pages.logistics.transporterOrderNumber'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.departureTransporterOrder
                     ? rowData.orderItems[0].order.departureTransporterOrder.transporterOrderNumber
                     : '-'}
               </span>
            );
         },
      },
      {
         field: 'machineCode',
         name: 'machineCode',
         header: t('pages.logistics.machineCode'),
         body: (rowData) => {
            const machineCode = rowData.vehicle?.machineFullCode.replace(/\s/g, '');
            return machineCode ? <span className="text-blue-500 underline cursor-pointer">{machineCode}</span> : <span>-</span>;
         },
      },
      {
         name: 'deliveryAddress',
         header: t('pages.logistics.deliveryAddress'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.address
                     ? rowData.orderItems[0].order.address.address
                     : '-'}
               </span>
            );
         },
      },
      {
         name: 'city',
         header: t('pages.logistics.city'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.address
                     ? rowData.orderItems[0].order.address.city
                     : '-'}
               </span>
            );
         },
      },
      {
         name: 'beginDate',
         header: t('pages.logistics.beginDate'),
         body: (rowData) => {
            return <span>{moment(rowData.beginDate).format('DD/MM/YYYY')}</span>;
         },
      },
   ];

   const readyToDispatchTableColumns = [
      ...tableColumns,
      {
         name: 'action',
         body: (rowData) => {
            return (
               <Button
                  onClick={() => setUpdateReservationStatus({ visible: true, data: rowData, action: 'confirm' })}
                  label={t('pages.logistics.confirmDelivery')}
                  className="text-[14px] !px-2 !py-[2px]"
               />
            );
         },
      },
   ];

   const rentTableColumns = [
      ...tableColumns,
      {
         name: 'action',
         body: (rowData) => {
            return (
               <>
                  <Button
                     label={t('pages.logistics.machineDelivered')}
                     className="text-[14px] !px-2 !py-[2px] mr-2"
                     onClick={() => setUpdateReservationStatus({ visible: true, data: rowData, action: 'delivered' })}
                  />
                  <Button
                     label={t('pages.offers.cancel')}
                     severity="danger"
                     className="text-[14px] !px-2 !py-[2px] mr-2"
                     onClick={() => setCancelOfferAndReservations({ visible: true, data: rowData })}
                  />
                  <Button
                     label={t('pages.logistics.postpone')}
                     severity="info"
                     className="text-[14px] !px-2 !py-[2px]"
                     onClick={() => setUpdateReservationDates({ visible: true, data: rowData })}
                  />
               </>
            );
         },
      },
   ];

   const onCellSelect = ({ field, rowData }) => {
      switch (field) {
         case 'machineCode':
            navigateToMachine(rowData);
            break;
         case 'contractNo':
            navigateToOrder(rowData);
            break;
         case 'customer':
            navigateToCustomer(rowData);
            break;
         default:
            break;
      }
   };

   const navigateToMachine = (rowData) => {
      if (rowData.vehicle && rowData.vehicle.supplierCustomerId) return;
      navigation(`/workshop/t-card`, { state: { machineId: rowData.vehicle.id } });
   };
   const navigateToOrder = (rowData) => {
      if (rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.id)
         navigation('/commerce/contracts', { state: { orderId: rowData.orderItems[0].order.id } });
   };
   const navigateToCustomer = (rowData) => {
      if (rowData.customer && rowData.customer.id) navigation('/customers', { state: { customerId: rowData.customer.id } });
   };

   return (
      <>
         <div className="p-3 bg-white rounded shadow-md">
            <div className="font-bold !text-[16px] mb-3">
               <span className="me-1 font-bold !text-[16px]">
                  (
                  {vehicleReservations &&
                     vehicleReservations.filter(
                        (item) => item.status === 'READY_TO_DEPART' || (item.status === 'RESERVE' && item.orderItems[0].category === 'SUP'),
                     ).length}
                  )
               </span>
               {t('pages.logistics.departureTableHeader')} ({moment().add(5, 'days').format('DD/MM/YYYY')})
            </div>
            <TableComponent
               data={
                  vehicleReservations &&
                  vehicleReservations.filter(
                     (item) => item.status === 'READY_TO_DEPART' || (item.status === 'RESERVE' && item.orderItems[0].category === 'SUP'),
                  )
               }
               columns={readyToDispatchTableColumns}
               scrollable={true}
               cellSelection={true}
               onCellSelect={onCellSelect}
            />
         </div>

         <div className="p-3 mt-5 bg-white rounded shadow-md">
            <div className="font-bold !text-[16px] mb-3">
               <span className="me-1 font-bold !text-[16px]">
                  (
                  {vehicleReservations &&
                     vehicleReservations
                        .filter((item) => item.status === 'RENT')
                        .filter((item) => item.isDelivered === false)
                        .filter((item) => item.beginDate < moment().add(5, 'days').format('YYYY-MM-DD')).length}
                  )
               </span>
               {t('pages.logistics.departureTableHeader2')} ({moment().add(5, 'days').format('DD/MM/YYYY')})
            </div>
            <TableComponent
               data={
                  vehicleReservations &&
                  vehicleReservations
                     .filter((item) => item.status === 'RENT')
                     .filter((item) => item.isDelivered === false)
                     .filter((item) => item.beginDate < moment().add(5, 'days').format('YYYY-MM-DD'))
               }
               columns={rentTableColumns}
               scrollable={true}
               cellSelection={true}
               onCellSelect={onCellSelect}
            />
         </div>

         {/* Update Reservation Status */}
         <Dialog
            header={updateReservationStatus.action === 'confirm' ? t('pages.logistics.confirmMachine') : t('pages.logistics.machineDelivered')}
            visible={updateReservationStatus.visible}
            style={{ width: '400px' }}
            onHide={() =>
               setUpdateReservationStatus({
                  visible: false,
                  data: null,
               })
            }
         >
            <div className="confirmation-content">
               {
                  <span>
                     {updateReservationStatus.action === 'confirm' ? t('pages.logistics.confirmMachineMessage') : t('pages.logistics.deliveredMachineMessage')}
                  </span>
               }
            </div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setUpdateReservationStatus({
                        visible: false,
                        data: null,
                     })
                  }
               />
               <Button label={t('global.save')} severity="success" onClick={updateVehicleReservationStatus} />
            </div>
         </Dialog>

         {/* Update Reservation Dates */}
         <Dialog
            header={t('pages.logistics.enterDates')}
            visible={updateReservationDates.visible}
            style={{ width: '500px' }}
            onHide={() =>
               setUpdateReservationDates({
                  visible: false,
                  data: null,
               })
            }
         >
            <div className="grid">
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.startDate')}</label>
                  <Calendar
                     dateFormat="dd/mm/yy"
                     value={reservationDates.beginDate}
                     onChange={(e) =>
                        setReservationDates({
                           ...reservationDates,
                           beginDate: e.value,
                           endDate: e.value,
                        })
                     }
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.endDate')}</label>
                  <Calendar
                     dateFormat="dd/mm/yy"
                     value={reservationDates.endDate}
                     onChange={(e) =>
                        setReservationDates({
                           ...reservationDates,
                           endDate: e.value,
                        })
                     }
                     className="w-full input-detail"
                  />
               </div>
            </div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setUpdateReservationDates({
                        visible: false,
                        data: null,
                     })
                  }
               />
               <Button label={t('global.save')} severity="success" onClick={updateVehicleReservationDate} />
            </div>
         </Dialog>

         {/* Cancel Offer & Reservations */}
         <Dialog
            header={t('pages.logistics.offerCancellation')}
            visible={cancelOfferAndReservations.visible}
            style={{ width: '400px' }}
            onHide={() =>
               setCancelOfferAndReservations({
                  visible: false,
                  data: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.logistics.offerCancellationMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setCancelOfferAndReservations({
                        visible: false,
                        data: null,
                     })
                  }
               />
               <Button label={t('global.save')} severity="success" onClick={cancelReservations} />
            </div>
         </Dialog>
      </>
   );
};

export default Departures;
