import { t } from 'i18next';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { createAddress, getAddress, updateAddress } from '../../../../store/apis/OffersApi';
import { InputTextarea } from 'primereact/inputtextarea';

const CreateInformationAddress = (props) => {
   const dispatch = useDispatch();
   const [createAddressInputs, setCreateAddressInputs] = useState({
      addressKindId: 2,
      country: 'FRANCE',
      city: '',
      customerId: props.customerId,
      address: '',
      addressContinuation: '',
      placeName: '',
      receiverName: '',
      districtName: '',
      postCode: '',
   });
   const [errors, setErrors] = useState({});

   useEffect(() => {
      if (props.selectedAddressId) {
         dispatch(getAddress(props.selectedAddressId)).then((res) => {
            setCreateAddressInputs({
               ...res,
               customerId: props.customerId,
               addressKindId: 2,
               country: res.country,
               city: res.city,
            });
         });
      }
   }, [props.selectedAddressId]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      setCreateAddressInputs({ ...createAddressInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['placeName', 'country', 'city', 'postCode', 'address'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!createAddressInputs[input]) {
            updateErrors(input, createAddressInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (validateForm()) {
         if (props.selectedAddressId) {
            const res = await dispatch(updateAddress(createAddressInputs));
            if (res) {
               props.setCreatedAddress(res);
               props.setCreateAddressDialog(false);
            }
         } else {
            const res = await dispatch(createAddress(createAddressInputs));
            if (res) {
               props.setCreatedAddress(res);
               props.setCreateAddressDialog(false);
            }
         }
      }
   };

   return (
      <>
         <div className="grid">
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('global.table.placeName')}*</label>
               <InputText
                  value={createAddressInputs.placeName}
                  onChange={(e) => handleInputChange(e, 'placeName')}
                  className={`w-full input-detail ${errors.placeName && 'input-error'}`}
               />
               {errors.placeName && <small className="p-error">{errors.placeName}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.country')}*</label>
               <InputText
                  value={createAddressInputs.country}
                  onChange={(e) => handleInputChange(e, 'country')}
                  className={`w-full input-detail ${errors.country && 'input-error'}`}
               />
               {errors.country && <small className="p-error">{errors.country}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('global.table.city')}*</label>
               <InputText
                  value={createAddressInputs.city}
                  onChange={(e) => handleInputChange(e, 'city')}
                  className={`w-full input-detail ${errors.city && 'input-error'}`}
               />
               {errors.city && <small className="p-error">{errors.city}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.receiverName')}</label>
               <InputText value={createAddressInputs.receiverName} onChange={(e) => handleInputChange(e, 'receiverName')} className="w-full input-detail" />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.districtName')}</label>
               <InputText
                  value={createAddressInputs.districtName}
                  onChange={(e) => handleInputChange(e, 'districtName')}
                  className="w-full input-detail"
                  maxLength={50}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('global.table.postCode')}*</label>
               <InputText
                  value={createAddressInputs.postCode}
                  onChange={(e) => handleInputChange(e, 'postCode')}
                  className={`w-full input-detail ${errors.postCode && 'input-error'}`}
                  maxLength={10}
               />
               {errors.postCode && <small className="p-error">{errors.postCode}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.address')}*</label>
               <InputTextarea
                  value={createAddressInputs.address}
                  onChange={(e) => handleInputChange(e, 'address')}
                  className={`w-full input-detail ${errors.address && 'input-error'}`}
                  rows={2}
                  maxLength={255}
               />
               {errors.address && <small className="p-error">{errors.address}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.addressContinuation')}</label>
               <InputTextarea
                  value={createAddressInputs.addressContinuation}
                  onChange={(e) => handleInputChange(e, 'addressContinuation')}
                  className="w-full input-detail"
                  rows={2}
                  maxLength={255}
               />
            </div>
         </div>
         <div className="flex justify-end">
            <Button label={props.selectedAddressId ? t('global.update') : t('global.save')} className="p-button-success" onClick={handleSubmit} />
         </div>
      </>
   );
};

export default CreateInformationAddress;
