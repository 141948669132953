import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   malfunctionCount: 0,
   malfunctionCompletedCount: 0,
   malfunctions: [],
   createdMalfunction: null,
   malfunction: {},
   technicians: [],
   malfunctionDocuments: [],
   vehicleReservations: [],
};

export const malfunctionSlice = createSlice({
   name: 'malfunctions',
   initialState,
   reducers: {
      setVehicleMalfunctionCount: (state, action) => {
         state.malfunctionCount = action.payload;
      },
      setVehicleMalfunctionCompletedCount: (state, action) => {
         state.malfunctionCompletedCount = action.payload;
      },
      setVehilceMalfunctions: (state, action) => {
         state.malfunctions = action.payload;
      },
      addVehicleMalfunction: (state, action) => {
         state.malfunctions.unshift(action.payload.vehicleMalfunction);
         state.createdMalfunction = action.payload.vehicleMalfunction;
      },
      setVehicleMalfunction: (state, action) => {
         state.malfunction = action.payload;
      },
      updatedVehicleMalfunction: (state, action) => {
         state.malfunctions = state.malfunctions.map((malfunction) => {
            if (malfunction.id === action.payload.id) {
               return action.payload;
            }
            return malfunction;
         });
         state.malfunction = action.payload;
      },
      setTechnicians: (state, action) => {
         state.technicians = action.payload;
      },
      setMalfunctionDocuments: (state, action) => {
         state.malfunctionDocuments = action.payload;
      },
      addMalfunctionDocuments: (state, action) => {
         state.malfunctionDocuments.push(action.payload);
      },
      removeMalfunctionDocument: (state, action) => {
         const _malfunctionDocuments = state.malfunctionDocuments.filter((document) => +document.id !== +action.payload);
         state.malfunctionDocuments = _malfunctionDocuments;
      },
      setVehicleReservations: (state, action) => {
         state.vehicleReservations = action.payload;
      },
      updatedVehicleReservation: (state, action) => {
         state.vehicleReservations = state.vehicleReservations.map((reservation) => {
            if (reservation.id === action.payload.id) {
               return action.payload;
            }
            return reservation;
         });
      },
      addVehicleReservationControl: (state, action) => {
         state.vehicleReservations.map((reservation) => {
            if (+reservation.id == +action.payload.vehicleReservationId) {
               reservation.vehicleReservationControlId = +action.payload.id;
            }
            return reservation;
         });
      },
      removeVehicleReservationControl: (state, action) => {
         state.vehicleReservations.map((reservation) => {
            if (+reservation.id == +action.payload.id) {
               reservation.vehicleReservationControlId = null;
            }
            return reservation;
         });
      },
   },
});

export const {
   setVehicleMalfunctionCount,
   setVehilceMalfunctions,
   addVehicleMalfunction,
   setVehicleMalfunction,
   updatedVehicleMalfunction,
   setTechnicians,
   setMalfunctionDocuments,
   addMalfunctionDocuments,
   removeMalfunctionDocument,
   setVehicleMalfunctionCompletedCount,
   setVehicleReservations,
   updatedVehicleReservation,
   addVehicleReservationControl,
   removeVehicleReservationControl,
} = malfunctionSlice.actions;
export default malfunctionSlice.reducer;
