import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stepCounter: 0,
  customersIndex: 0,
  customers: [],
  customer: null,
  contacts: [],
  reminders: [],
  discussions: [],
  selectedCustomerId: null,
  billingAddressId: null,
  mainAddressId: null,
  sameAddress: true,
  previousButton: false,
  customerInvoices: [],
  customerPaidInvoices: [],
  customerRefunds: [],
  vehicleGroupPrices: [],
  transporterOrders: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setNewCustomerStepCounter: (state, action) => {
      state.stepCounter = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    addCustomer: (state, action) => {
      state.customers.unshift(action.payload);
    },
    removeCustomer: (state, action) => {
      const ids = action.payload;
      const ind = state.customers.findIndex(
        (customer) => +customer.id === +ids[0]
      );
      if (ind !== -1) {
        state.customers.splice(ind, 1);
      }
    },
    handleUpdateCustomer: (state, action) => {
      const customer = action.payload;
      const ind = state.customers.findIndex((o) => +o.id === +customer.id);
      if (ind !== -1) {
        state.customers[ind] = customer;
      }
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    addContact: (state, action) => {
      state.contacts.unshift(action.payload);
    },
    handleUpdateContact: (state, action) => {
      const contact = action.payload;
      const ind = state.contacts.findIndex((o) => +o.id === +contact.id);
      if (ind !== -1) {
        state.contacts[ind] = contact;
      }
    },
    setReminders: (state, action) => {
      state.reminders = action.payload;
    },
    addReminder: (state, action) => {
      state.reminders.unshift(action.payload);
    },
    handleUpdateReminder: (state, action) => {
      const reminder = action.payload;
      const ind = state.reminders.findIndex((o) => +o.id === +reminder.id);
      if (ind !== -1) {
        state.reminders[ind] = reminder;
      }
    },
    setDiscussions: (state, action) => {
      state.discussions = action.payload;
    },
    addDiscussion: (state, action) => {
      state.discussions.unshift(action.payload);

      const cusIndex = state.customers.findIndex(
        (c) => +c.id === +action.payload.customer.id
      );

      if (cusIndex !== -1) {
        state.customers[cusIndex].discussions.push(action.payload);
      }
    },
    handleUpdateDiscussion: (state, action) => {
      const discussion = action.payload;
      const ind = state.discussions.findIndex((o) => +o.id === +discussion.id);
      if (ind !== -1) {
        state.discussions[ind] = discussion;
      }

      const cusIndex = state.customers.findIndex(
        (c) => +c.id === +discussion.customer.id
      );

      if (cusIndex !== -1) {
        const cusDisInd = state.customers[cusIndex].discussions.findIndex(
          (o) => +o.id === +discussion.id
        );
        state.customers[cusIndex].discussions[cusDisInd] = action.payload;
      }
    },
    setSelectedCustomerId: (state, action) => {
      state.selectedCustomerId = action.payload;
    },
    setSameAddress: (state, action) => {
      state.sameAddress = action.payload;
    },
    setPreviousButton: (state, action) => {
      state.previousButton = action.payload;
    },
    setShowAddNewCustomer: (state, action) => {
      state.showAddNewCustomer = action.payload;
      state.reminders = [];
      state.contacts = [];
    },
    setCustomerInvoices: (state, action) => {
      state.customerInvoices = action.payload;
    },
    setCustomerPaidInvoices: (state, action) => {
      state.customerPaidInvoices = action.payload;
    },
    setCustomerRefunds: (state, action) => {
      state.customerRefunds = action.payload;
    },
    setPurchaseOrder: (state, action) => {
      state.transporterOrders = action.payload;
    },
    payRefund: (state, action) => {
      const ids = action.payload.ids;
      for (let i = 0; i < ids.length; i++) {
        const index = state.customerRefunds.findIndex(
          (refund) => +refund.id === +ids[i]
        );
        if (index !== -1) {
          state.customerRefunds[index].isPaid = true;
        }
      }
    },
    setVehicleGroupPrices: (state, action) => {
      state.vehicleGroupPrices = action.payload;
    },
    createVehicleGroupPrices: (state, action) => {
      state.vehicleGroupPrices.unshift(action.payload);
    },
    removeVehicleGroupPrice: (state, action) => {
      const ids = action.payload;
      const ind = state.vehicleGroupPrices.findIndex(
        (vehicleGroupPrice) => +vehicleGroupPrice.id === +ids[0]
      );
      if (ind !== -1) {
        state.vehicleGroupPrices.splice(ind, 1);
      }
    },
    updateVehicleGroupPrices: (state, action) => {
      const vehicleGroupPrice = action.payload;
      const ind = state.vehicleGroupPrices.findIndex(
        (v) => +v.id === +vehicleGroupPrice.id
      );
      if (ind !== -1) {
        state.vehicleGroupPrices[ind] = vehicleGroupPrice;
      }
    },
  },
});

export const {
  setCustomers,
  setCustomer,
  addCustomer,
  removeCustomer,
  handleUpdateCustomer,
  setContacts,
  addContact,
  handleUpdateContact,
  setReminders,
  addReminder,
  handleUpdateReminder,
  setDiscussions,
  addDiscussion,
  handleUpdateDiscussion,
  setNewCustomerStepCounter,
  setSelectedCustomerId,
  setSameAddress,
  setPreviousButton,
  setBillingAddressId,
  setMainAddressId,
  setShowAddNewCustomer,
  setCustomerInvoices,
  setCustomerPaidInvoices,
  setCustomerRefunds,
  setVehicleGroupPrices,
  createVehicleGroupPrices,
  removeVehicleGroupPrice,
  setPurchaseOrder,
  updateVehicleGroupPrices,
} = customerSlice.actions;

export default customerSlice.reducer;
