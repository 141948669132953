import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import { getItemCategories } from '../../../../store/apis/OffersApi';
import { createInvoiceItem } from '../../../../store/apis/ManagementApi';
import toast from 'react-hot-toast';

const AddSaleItems = ({ createSaleDialog, setCreateSaleDialog }) => {
   const dispatch = useDispatch();
   const invoice = useSelector((state) => state.management.invoice);
   const [itemsInputs, setItemsInputs] = useState({
      invoiceId: null,
      description: '',
      quantity: 1,
      price: 0,
      currencyId: 2,
   });
   const [categories, setCategories] = useState([]);
   const [errors, setErrors] = useState({});

   useEffect(() => {
      dispatch(getItemCategories()).then((res) => setCategories(res.filter((c) => c.name !== 'INV' && c.name !== 'SUP')));
   }, []);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      if (name === 'price') {
         const onlyNums = value.replace(/[^0-9]/g, '');
         setItemsInputs({ ...itemsInputs, [name]: onlyNums });
         updateErrors(name, onlyNums);
      } else {
         setItemsInputs({ ...itemsInputs, [name]: value });
         updateErrors(name, value);
      }
   };

   const handleFocus = (name) => {
      if (itemsInputs[name] === 0) {
         setItemsInputs({ ...itemsInputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const inputs = ['description', 'price', 'category'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!itemsInputs[input]) {
            updateErrors(input, itemsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const resetItems = () => {
      setItemsInputs({
         description: '',
         quantity: 1,
         price: 0,
         currencyId: 2,
      });
      setCreateSaleDialog(false);
   };

   const saveItems = () => {
      if (!validateForm()) return;
      const data = {
         ...itemsInputs,
         invoiceId: invoice?.id,
      };
      dispatch(createInvoiceItem(data)).then(() => {
         toast.success(t('pages.invoices.createInvoiceItemsSuccess'));
         resetItems();
      });
   };

   return (
      <Dialog header="Add Sale Items" visible={createSaleDialog} className="w-[45vw]" onHide={() => resetItems()}>
         <div className="grid">
            <div className="col-12 lg:col-6">
               <label>{t('pages.offers.category')}</label>
               <Dropdown
                  filter
                  options={categories}
                  value={itemsInputs.category}
                  onChange={(e) => handleInputChange(e, 'category')}
                  optionLabel="name"
                  optionValue="name"
                  className="w-full input-detail"
                  showClear
               />
               {errors.category && <small className="p-error">{errors.category}</small>}
            </div>
            <div className="col-12 lg:col-6">
               <label>{t('global.table.explanation')}</label>
               <InputText value={itemsInputs.description} onChange={(e) => handleInputChange(e, 'description')} className="w-full input-detail" />
               {errors.description && <small className="p-error">{errors.description}</small>}
            </div>
            <div className="col-12 lg:col-6">
               <label>{t('pages.offers.quantity')}</label>
               <InputNumber
                  value={itemsInputs.quantity}
                  min={1}
                  onValueChange={(e) => setItemsInputs({ ...itemsInputs, quantity: e.value })}
                  showButtons
                  buttonLayout="horizontal"
                  className="w-full input-detail"
                  decrementButtonClassName="p-button-secondary"
                  incrementButtonClassName="p-button-secondary"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
               />
            </div>
            <div className="col-12 lg:col-6">
               <label>{t('pages.offers.price')}</label>
               <InputText
                  value={itemsInputs.price}
                  onChange={(e) => handleInputChange(e, 'price')}
                  onFocus={() => handleFocus('price')}
                  className="w-full input-detail"
               />
               {errors.price && <small className="p-error">{errors.price}</small>}
            </div>
            <div className="mt-3 col-12">
               <Button label={t('global.save')} className="w-full" onClick={() => saveItems()} severity="success" />
            </div>
         </div>
      </Dialog>
   );
};

export default AddSaleItems;
