import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   loading: false,
   showSidebar: true,
   genders: [],
   roles: [],
   dialogFlag: false,
   globalLoading: false,
   request: [],
   globalSettings: null,
   dashboardStatistics: null,
   financeStatistics: null,
   vehicleGroupPrices: [],
};

export const sharedSlice = createSlice({
   name: 'shared',
   initialState,
   reducers: {
      changeLoadingStatus: (state, action) => {
         state.loading = action.payload;
      },
      changeSidebarStatus: (state, action) => {
         state.showSidebar = action.payload;
      },
      setGenders: (state, action) => {
         state.genders = action.payload;
      },
      setRoles: (state, action) => {
         state.roles = action.payload;
      },
      setDialogFlag: (state, action) => {
         state.dialogFlag = action.payload;
      },
      setRequest: (state, action) => {
         state.request.push(action.payload);
         state.globalLoading = true;
      },
      removeRequest: (state, action) => {
         state.request = state.request.filter((item) => item !== action.payload);
         state.globalLoading = state.request.length > 0;
      },
      setGlobalSettings: (state, action) => {
         state.globalSettings = action.payload;
      },
      setDashboardStatistics: (state, action) => {
         state.dashboardStatistics = action.payload;
      },
      setFinanceStatistics: (state, action) => {
         state.financeStatistics = action.payload;
      },
      setVehicleGroupPrices: (state, action) => {
         state.vehicleGroupPrices = action.payload;
      },
      createVehicleGroupPrices: (state, action) => {
         state.vehicleGroupPrices.unshift(action.payload);
      },
      removeVehicleGroupPrice: (state, action) => {
         const ids = action.payload;
         const ind = state.vehicleGroupPrices.findIndex((vehicleGroupPrice) => +vehicleGroupPrice.id === +ids[0]);
         if (ind !== -1) {
            state.vehicleGroupPrices.splice(ind, 1);
         }
      },
      updateVehicleGroupPrices: (state, action) => {
         const vehicleGroupPrice = action.payload;
         const ind = state.vehicleGroupPrices.findIndex((v) => +v.id === +vehicleGroupPrice.id);
         if (ind !== -1) {
            state.vehicleGroupPrices[ind] = vehicleGroupPrice;
         }
      },
   },
});

export const {
   changeLoadingStatus,
   changeSidebarStatus,
   setGenders,
   setRoles,
   setDialogFlag,
   setRequest,
   removeRequest,
   setGlobalSettings,
   setDashboardStatistics,
   setFinanceStatistics,
   setVehicleGroupPrices,
   createVehicleGroupPrices,
   removeVehicleGroupPrice,
   updateVehicleGroupPrices,
} = sharedSlice.actions;

export default sharedSlice.reducer;
