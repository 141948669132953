import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../../components/TableComponent";
import client from "../../../graphql/apollo.config";
import { Dialog } from "primereact/dialog";
import {
  CREATE_DISCUSSION,
  GET_DISCUSSIONS,
  GET_DISCUSSION_KINDS,
  UPDATE_DISCUSSION,
} from "../../../graphql/customers";
import { changeLoadingStatus } from "../../../store/slices/sharedSlice";
import { GET_USERS } from "../../../graphql/users";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import toast from "react-hot-toast";
import moment from "moment";
import { setNewCustomerStepCounter } from "../../../store/slices/customerSlice";
import {
  createDiscussion,
  getDiscussionsKinds,
  getDisscussions,
  getUsers,
  updateDiscussion,
} from "../../../store/apis/Customers";

const Discussions = () => {
  const dispatch = useDispatch();

  const { selectedCustomerId, discussions } = useSelector(
    (state) => state.customer
  );

  const [showAddNewDiscussion, setShowAddNewDiscussion] = useState(false);
  const [users, setUsers] = useState();

  const [selectedDiscussion, setSelectedDiscussion] = useState();
  const [discussionKinds, setDiscussionKinds] = useState();

  const [discussionsInputs, setDiscussionsInputs] = useState({
    discussionKind: null,
    user: null,
    date: "",
    note: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(getUsers()).then((res) => {
      setUsers(res);
    });
    dispatch(getDiscussionsKinds()).then((res) => {
      setDiscussionKinds(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDisscussions(selectedCustomerId));
  }, []);

  const tableColumns = [
    {
      name: "type",
      header: t("global.table.type"),
      field: "discussionKind.name",
      body: (rowData) => {
        return (
          <span>
            {t(
              `pages.customers.discussionTypes.${rowData.discussionKind.name}`
            )}
          </span>
        );
      },
    },
    {
      name: "user",
      header: t("global.table.user"),
      field: "user.name",
    },
    {
      name: "date",
      header: t("global.table.discussionDate"),
      body: (rowData) => {
        return <span>{moment(rowData.date).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      name: "notes",
      header: t("global.table.notes"),
      field: "note",
    },
  ];

  // FORM VALIDATION
  const updateErrors = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value ? t("global.requiredField") : "",
    }));
  };

  const handleInputChange = (name, value) => {
    if (selectedDiscussion) {
      setSelectedDiscussion((selectedDiscussion) => ({
        ...selectedDiscussion,
        [name]: value,
      }));
    }
    setDiscussionsInputs((prevInfos) => ({
      ...prevInfos,
      [name]: value,
    }));
    updateErrors(name, value);
  };

  const validateForm = () => {
    const inputs = ["discussionKind", "user", "date"];
    let isValid = true;
    if (selectedDiscussion) {
      inputs.forEach((input) => {
        if (!selectedDiscussion[input]) {
          updateErrors(input, selectedDiscussion[input]);
          isValid = false;
          console.log(input);
        }
      });
    } else {
      inputs.forEach((input) => {
        if (!discussionsInputs[input]) {
          updateErrors(input, discussionsInputs[input]);
          isValid = false;
          console.log(input);
        }
      });
    }
    return isValid;
  };

  const handleRowClick = (e) => {
    console.log(e);
    setSelectedDiscussion(e);
    setShowAddNewDiscussion(true);
  };

  const handleDiscussionSave = () => {
    dispatch(changeLoadingStatus(true));
    if (selectedDiscussion) {
      if (validateForm()) {
        dispatch(
          updateDiscussion(
            selectedDiscussion,
            moment(selectedDiscussion.date).format("YYYY-MM-DD"),
            selectedDiscussion.discussionKind.id
          )
        ).then((res) => {
          toast.success(t("global.updateSuccess"));
          setShowAddNewDiscussion(false);
          setSelectedDiscussion(null);
          resetVariables();
        });
      }
    } else {
      if (validateForm()) {
        dispatch(
          createDiscussion(
            selectedCustomerId,
            moment(discussionsInputs.date).format("YYYY-MM-DD"),
            discussionsInputs
          )
        ).then((res) => {
          toast.success("Discussion created successfully!");
          setShowAddNewDiscussion(false);
          setSelectedDiscussion(null);
          resetVariables();
        });
      }
    }
  };

  const resetVariables = () => {
    setDiscussionsInputs({
      discussionKind: null,
      user: null,
      date: "",
      note: "",
    });
  };

  const handleSave = () => {
    dispatch(setNewCustomerStepCounter(4));
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-end w-full mb-3">
        <Button
          icon="pi pi-plus"
          label={t("pages.customers.addNewDiscussion")}
          size="small"
          onClick={() => setShowAddNewDiscussion(true)}
        />
      </div>
      <TableComponent
        data={discussions}
        columns={tableColumns}
        size="medium"
        onRowClick={(e) => handleRowClick(e)}
      />
      <div className="flex justify-end mt-3">
        <Button
          className="mr-auto"
          label={t("global.previous")}
          onClick={() => dispatch(setNewCustomerStepCounter(2))}
        ></Button>
        <Button
          icon="pi pi-check"
          label={t("global.save")}
          onClick={() => handleSave()}
        />
      </div>
      {/* NEW DISCUSSION */}
      <Dialog
        visible={showAddNewDiscussion}
        onHide={() => {
          setShowAddNewDiscussion(false);
          resetVariables();
          setDiscussionsInputs({
            ...discussionsInputs,
            selectedDiscussionKind: null,
          });
        }}
        draggable={false}
        header={
          selectedDiscussion
            ? t("pages.customers.discussionDetails")
            : t("pages.customers.addNewDiscussion")
        }
        className="md-width-dialog"
      >
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("pages.customers.discussionType")}*</label>
            <Dropdown
              options={discussionKinds}
              optionLabel="name"
              optionValue="id"
              onChange={(e) => {
                if (selectedDiscussion) {
                  handleInputChange("discussionKind", { id: e.value });
                } else {
                  handleInputChange("discussionKind", e.value);
                }
              }}
              value={
                selectedDiscussion
                  ? selectedDiscussion.discussionKind.id
                  : discussionsInputs.discussionKind
              }
              placeholder="Select User"
              className={`w-full input-detail ${
                errors.discussionKind && "input-error"
              }`}
              valueTemplate={(option) => {
                if (option) {
                  return (
                    <span>
                      {t(`pages.customers.discussionTypes.${option.name}`)}
                    </span>
                  );
                }
                return <span>&nbsp;</span>;
              }}
              itemTemplate={(option) => {
                return (
                  <span>
                    {t(`pages.customers.discussionTypes.${option.name}`)}
                  </span>
                );
              }}
              showClear={!selectedDiscussion}
            />
            {errors.discussionKind && (
              <small className="p-error">{errors.discussionKind}</small>
            )}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("global.table.user")}*</label>
            <Dropdown
              options={users}
              optionLabel="name"
              optionValue="id"
              onChange={(e) => {
                if (selectedDiscussion) {
                  handleInputChange("user", { id: e.value });
                } else {
                  handleInputChange("user", e.value);
                }
              }}
              value={
                selectedDiscussion
                  ? selectedDiscussion.user.id
                  : discussionsInputs.user
              }
              className={`w-full input-detail ${errors.user && "input-error"}`}
              showClear={!selectedDiscussion}
            />
            {errors.user && <small className="p-error">{errors.user}</small>}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("global.table.discussionDate")}*</label>
            <Calendar
              onChange={(e) => {
                handleInputChange("date", e.value);
              }}
              dateFormat="dd/mm/yy"
              value={
                selectedDiscussion
                  ? new Date(selectedDiscussion.date)
                  : discussionsInputs.date
              }
              className={`w-full input-detail ${errors.date && "input-error"}`}
            />
            {errors.date && <small className="p-error">{errors.date}</small>}
          </div>
          <div className="col-12">
            <label>{t("global.table.notes")}</label>
            <InputTextarea
              onChange={(e) => {
                handleInputChange("note", e.target.value);
              }}
              value={
                selectedDiscussion
                  ? selectedDiscussion.note
                  : discussionsInputs.note
              }
              className="w-full input-detail"
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="flex justify-end col-12">
            <Button
              label={
                selectedDiscussion ? t("global.update") : t("global.create")
              }
              icon="pi pi-check"
              onClick={() => handleDiscussionSave()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Discussions;
