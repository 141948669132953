import React, { useEffect, useState } from 'react';
import ContractsInfoCards from './ContractsInfoCards';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';
import { Button } from 'primereact/button';
import TableComponent from '../../../components/TableComponent';
import { Paginator } from 'primereact/paginator';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import toast from 'react-hot-toast';
import {
   removeContract,
   duplicateSelectedContract,
   getContract,
   getContractKinds,
   getContracts,
   getCustomers,
   getUsers,
} from '../../../store/apis/ContractsApi';
import ContractDetail from './ContractDetail/ContractDetail';
import { setContract } from '../../../store/slices/contractsSlice';
import { useLocation } from 'react-router-dom';
import { getOfferStatistics } from '../../../store/apis/OffersApi';
const Contracts = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const location = useLocation();

   const contracts = useSelector((state) => state.contracts.contracts);
   const contractCount = useSelector((state) => state.contracts.contractCount);

   const [rows, setRows] = useState(10);
   const [first, setFirst] = useState(0);
   const [searchKeyword, setsearchKeyword] = useState('');
   const [showFilters, setShowFilters] = useState(false);
   const [filters, setFilters] = useState(null);
   const [contractKinds, setContractKinds] = useState([]);
   const [customers, setCustomers] = useState([]);
   const [users, setUsers] = useState([]);
   const [deleteContract, setDeleteContract] = useState({
      dialogVisible: false,
      selectedContractId: null,
   });
   const [contractVisible, setContractVisible] = useState(false);
   const [offerStatistics, setOfferStatistics] = useState(null);
   const [duplicateContract, setDuplicateContract] = useState({
      dialogVisible: false,
      selectedContractId: null,
   });
   useEffect(() => {
      dispatch(getOfferStatistics()).then((res) => setOfferStatistics(res));
   }, []);

   useEffect(() => {
      fetchContracts(rows, first, searchKeyword, filters);
   }, [first, rows]);

   useEffect(() => {
      if (!showFilters) return;
      dispatch(getContractKinds()).then((res) => setContractKinds(res));
      dispatch(getCustomers()).then((res) => setCustomers(res));
      dispatch(getUsers()).then((res) => setUsers(res));
   }, [showFilters]);

   useEffect(() => {
      if (location.state?.orderId) {
         showContractDetail(location.state.orderId);
      }
      const onbeforeunload = () => {
         //clear location state
         window.history.replaceState(null, '');
      };
      window.addEventListener('beforeunload', onbeforeunload);
      return () => {
         window.removeEventListener('beforeunload', onbeforeunload);
      };
   }, [location.state]);

   const columns = [
      {
         name: 'type',
         header: t('global.table.type'),
         body: (rowData) => {
            return <span>{rowData?.orderKind?.name}</span>;
         },
      },
      {
         name: 'orderNumber',
         header: t('global.table.contractNumber'),
         body: (rowData) => {
            return <span>{rowData?.orderNumber}</span>;
         },
      },
      {
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.createdAt).format('DD-MM-YYYY')}</span>;
         },
      },
      {
         name: 'customerName',
         header: t('global.table.customerName'),
         body: (rowData) => {
            return <span>{rowData?.customer?.companyName}</span>;
         },
      },
      {
         name: 'placeName',
         header: t('global.table.placeName'),
         body: (rowData) => {
            return <span>{rowData?.address?.placeName}</span>;
         },
      },
      /* {
         name: 'machines',
         header: t('global.table.machines'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems &&
                     rowData.orderItems.length > 0 &&
                     rowData?.orderItems
                        .filter((item) => item?.vehicleReservation)
                        .map(
                           (item) =>
                              item.vehicleReservation &&
                              (item.vehicleReservation.vehicle
                                 ? `${item.vehicleReservation.vehicleGroup.name}${item.vehicleReservation.vehicle.machineCode}(${t(
                                      `pages.contracts.${item?.vehicleReservation?.status}`,
                                   )})`
                                 : `${item.vehicleReservation && item.vehicleReservation.vehicleGroup.name}(${t(
                                      `pages.contracts.${item?.vehicleReservation?.status}`,
                                   )})`),
                        )
                        .join(', ')}
               </span>
            );
         },
      }, */
      {
         name: 'machines',
         header: t('global.table.machines'),
         body: (rowData) => {
            if (rowData.orderItems && rowData.orderItems.length > 0) {
               const machines = rowData.orderItems
                  .filter((item) => item?.vehicleReservation)
                  .map((item) => {
                     return (
                        <div key={item.id} className="flex items-center">
                           <span>
                              {item.vehicleReservation.vehicle
                                 ? item.vehicleReservation?.vehicle?.machineFullCode.replace(/\s/g, '')
                                 : item.vehicleReservation.vehicleGroup.name}
                           </span>
                           <span
                              className={`text-[${
                                 item.vehicleReservation?.status === 'RENT'
                                    ? '#00A5D8'
                                    : item.vehicleReservation?.status === 'RESERVE'
                                    ? '#FF6DA8'
                                    : item.vehicleReservation?.status === 'RETURN_PLANED'
                                    ? '#F6E83F'
                                    : item.vehicleReservation?.status === 'INSPECTION'
                                    ? '#FF7F50'
                                    : null
                              }]`}
                           >
                              ({t(`pages.contracts.${item?.vehicleReservation?.status}`)})
                           </span>
                        </div>
                     );
                  });
               return machines;
            }
         },
      },
      {
         name: 'actions',
         body: (rowData) => {
            return (
               <div className="flex justify-center">
                  <div className="flex items-center">
                     <Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={() => showContractDetail(rowData?.id)} />
                     <Button
                        className="mx-1"
                        icon="pi pi-trash"
                        severity="danger"
                        onClick={() =>
                           setDeleteContract({
                              dialogVisible: true,
                              selectedContractId: rowData?.id,
                           })
                        }
                     />
                     <Button
                        className="mx-1"
                        icon="pi pi-copy"
                        severity="info"
                        onClick={() =>
                           setDuplicateContract({
                              dialogVisible: true,
                              selectedContractId: rowData?.id,
                           })
                        }
                     />
                  </div>
               </div>
            );
         },
      },
   ];

   const fetchContracts = async (_rows, _first, _searchKeyword, _filters) => {
      let queryOptions = {
         first: _rows,
         skip: _first,
         search: _searchKeyword,
         filters: { ..._filters, isDeleted: false, isContracted: true },
      };
      dispatch(getContracts(queryOptions));
   };

   const showContractDetail = (id) => {
      dispatch(getContract(+id)).then(async (res) => {
         if (!res) return;
         setContractVisible(true);
      });
   };

   const handleDuplicateContract = async () => {
      const res = await dispatch(duplicateSelectedContract(+duplicateContract.selectedContractId));
      if (res) {
         showContractDetail(res.id);
         toast.success(t('pages.contracts.duplicateContractSuccessMessage'));
         setDuplicateContract({
            dialogVisible: false,
            selectedContractId: null,
         });
      }
   };
   return (
      <div className="flex flex-col w-full">
         <ContractsInfoCards offerStatistics={offerStatistics} />
         <Card className="mt-4">
            <div className="flex justify-between mb-3">
               <div className="flex">
                  <div className="p-inputgroup" style={{ maxWidth: '200px' }}>
                     <InputText
                        value={searchKeyword}
                        id="mainSearch"
                        onChange={(e) => setsearchKeyword(e.target.value)}
                        placeholder={t('global.search')}
                        size="small"
                        style={{ background: '#f9f9f9', border: 'none' }}
                        onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                              fetchContracts(rows, first, searchKeyword, filters);
                           }
                        }}
                     />
                     <Button
                        icon="pi pi-search"
                        size="small"
                        onClick={() => fetchContracts(rows, first, searchKeyword, filters)}
                        style={{ background: '#f9f9f9', color: 'black', border: '#f9f9f9 none' }}
                     />
                  </div>
                  <Button
                     icon={filters ? 'pi pi-filter-slash' : 'pi pi-filter'}
                     className="ml-2"
                     onClick={() => {
                        if (filters) {
                           setFilters(null);
                           setsearchKeyword('');
                           fetchContracts(rows, first, '', null);
                        } else {
                           setShowFilters(true);
                        }
                     }}
                     size="small"
                     severity={filters && 'danger'}
                  />
               </div>
            </div>
            <TableComponent data={contracts || []} columns={columns} size="small" />
            <div className="flex items-center justify-center">
               <Paginator first={first} rows={rows} totalRecords={contractCount} onPageChange={(e) => setFirst(e.first)} />
            </div>
         </Card>
         {/* FILTERS DIALOG */}
         <Dialog header={t('global.filters')} visible={showFilters} onHide={() => setShowFilters(false)} className="md-width-dialog" draggable={false}>
            <div className="grid">
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.contracts.quantity')}</label>
                  <InputNumber
                     value={rows}
                     min={1}
                     onValueChange={(e) => setRows(e.value)}
                     showButtons
                     buttonLayout="horizontal"
                     className="w-full input-detail"
                     decrementButtonClassName="p-button-secondary"
                     incrementButtonClassName="p-button-secondary"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('global.search')}</label>
                  <InputText
                     className="w-full p-inputtext-sm input-detail"
                     name="search"
                     id="mainSearch"
                     value={searchKeyword}
                     onChange={(e) => setsearchKeyword(e.target.value)}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.contracts.type')}</label>
                  <Dropdown
                     options={contractKinds}
                     value={filters?.orderKindId}
                     onChange={(e) => setFilters({ ...filters, orderKindId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.contracts.customer')}</label>
                  <Dropdown
                     filter
                     options={customers}
                     value={filters?.customerId}
                     onChange={(e) => setFilters({ ...filters, customerId: e.value })}
                     optionLabel="companyName"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('global.table.user')}</label>
                  <Dropdown
                     filter
                     options={users}
                     value={filters?.userId}
                     onChange={(e) => setFilters({ ...filters, userId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
            </div>
            <div className="flex justify-center col-12 md:justify-end">
               <Button
                  icon="pi pi-check"
                  label={t('global.apply')}
                  onClick={() => {
                     fetchContracts(rows, first, searchKeyword, filters);
                     setShowFilters(false);
                  }}
                  size="small"
               />
            </div>
         </Dialog>
         {/* SEARCH TOOLTIP */}
         <Tooltip target="#mainSearch" position="top">
            <div style={{ backgroundColor: 'white', color: '#606266', padding: '5px' }}>
               <div className="font-semibold">{t('pages.contracts.searchedFields')}</div>
               <div className="w-48 text-[12px]">{t('pages.contracts.searchedFieldsList')}</div>
            </div>
         </Tooltip>
         {/* Delete Contract Dialog */}
         <Dialog
            header={t('pages.contracts.deleteContract')}
            visible={deleteContract.dialogVisible}
            style={{ width: '400px' }}
            onHide={() =>
               setDeleteContract({
                  dialogVisible: false,
                  selectedContractId: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.contracts.deleteContractMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.contracts.cancel')}
                  severity="warning"
                  className="mr-2 "
                  onClick={() =>
                     setDeleteContract({
                        dialogVisible: false,
                        selectedContractId: null,
                     })
                  }
               />
               <Button
                  label={t('pages.contracts.delete')}
                  severity="danger"
                  onClick={() => {
                     dispatch(removeContract(deleteContract.selectedContractId)).then(
                        setDeleteContract({
                           dialogVisible: false,
                           selectedContractId: null,
                        }),
                        toast.success(t('pages.contracts.deletedContractMessage')),
                     );
                  }}
               />
            </div>
         </Dialog>
         <Dialog
            header={t('pages.contracts.duplicateContract')}
            visible={duplicateContract.dialogVisible}
            style={{ width: '400px' }}
            onHide={() =>
               setDuplicateContract({
                  dialogVisible: false,
                  selectedContractId: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.contracts.duplicateContractMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.contracts.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setDuplicateContract({
                        dialogVisible: false,
                        selectedContractId: null,
                     })
                  }
               />
               <Button label={t('global.save')} severity="success" onClick={handleDuplicateContract} />
            </div>
         </Dialog>
         {/* Contract Detail */}
         <ContractDetail contractVisible={contractVisible} setContractVisible={setContractVisible} />
      </div>
   );
};

export default Contracts;
