import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import TableComponent from '../../components/TableComponent';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
   getCustomerIdName,
   getVehicleIdMachineCode,
   getVehicleMalfunctionCompletedCount,
   getVehicleMalfunctionCount,
   getVehicleMalfunctionKinds,
   getVehicleMalfunctions,
   updateVehicleMalfunctionStatus,
} from '../../store/apis/MalfunctionApi';
import { Paginator } from 'primereact/paginator';
import CreateVehicleMalfunction from './CreateVehicleMalfunction/Index';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import VehicleMalfuntionDetail from './VehicleMalfunctionDetail/Index';
import moment from 'moment';

const MalfunctionRecords = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const vehicleMalfunctionCount = useSelector((state) => state.malfunctions.malfunctionCount);
   const vehicleMalfunctionCompletedCount = useSelector((state) => state.malfunctions.malfunctionCompletedCount);
   const vehicleMalfunctions = useSelector((state) => state.malfunctions.malfunctions);
   const [rows, setRows] = useState(20);
   const [first, setFirst] = useState(0);
   const [search, setSearch] = useState('');
   const [createDialogVisible, setCreateDialogVisible] = useState(false);
   const [filters, setFilters] = useState();
   const [showFilters, setShowFilters] = useState(false);
   const [limitValue, setLimitValue] = useState(20);
   const [vehicleMalfunctionKinds, setVehicleMalfunctionKinds] = useState();
   const [vehicleIdMachineCode, setVehicleIdMachineCode] = useState([]);
   const [customerIdName, setCustomerIdName] = useState([]);
   const [technicans, setTechnicans] = useState([]);
   const [detailDialogVisible, setDetailDialogVisible] = useState(false);
   const [selectedId, setSelectedId] = useState(null);
   const [machineFullCode, setMachineFullCode] = useState(null);

   useEffect(() => {
      if (search == '') {
         console.log('rows', 'first');
         dispatch(getVehicleMalfunctions(first, rows, search, filters));
         dispatch(getVehicleMalfunctionCount());
         dispatch(getVehicleMalfunctionCompletedCount());
      }
   }, [first, rows, search]);

   useEffect(() => {
      const _kindsMap = new Map();
      const _machineCodesMap = new Map();
      const _customersMap = new Map();
      const _technicansMap = new Map();

      vehicleMalfunctions.forEach((element) => {
         if (element.vehicleMalfunctionKind) {
            _kindsMap.set(element.vehicleMalfunctionKind.id, { name: element.vehicleMalfunctionKind.name, id: element.vehicleMalfunctionKind.id });
         }
         if (element.vehicle) {
            _machineCodesMap.set(element.vehicle.id, {
               machineFullCode: element.vehicle.machineFullCode && element.vehicle.machineFullCode.replace(/\s/g, ''),
               id: element.vehicle.id,
            });
         }
         if (element.customer) {
            _customersMap.set(element.customer.id, { name: element.customer.companyName, id: element.customer.id });
         }
         if (element.technicanUser) {
            _technicansMap.set(element.technicanUser.id, { name: element.technicanUser.name, id: element.technicanUser.id });
         }
      });

      setVehicleMalfunctionKinds([..._kindsMap.values()]);
      setVehicleIdMachineCode([..._machineCodesMap.values()]);
      setCustomerIdName([..._customersMap.values()]);
      setTechnicans([..._technicansMap.values()]);
   }, [vehicleMalfunctions]);

   const handleChangePage = (e) => {
      setFirst(e.first);
      setRows(e.rows);
   };

   const handleSearch = () => {
      dispatch(getVehicleMalfunctions(null, null, search, filters));
      dispatch(getVehicleMalfunctionCount(search));
      dispatch(getVehicleMalfunctionCompletedCount(search));
   };

   const tableColumns = [
      {
         name: 'caseNumber',
         header: t('pages.offers.malfunctionCaseNumber'),
         body: (rowData) => {
            return <span>{rowData.id}</span>;
         },
      },
      {
         name: 'faultDate',
         header: t('pages.malfunctions.faultDate'),
         body: (rowData) => {
            return <span>{rowData.faultDate ? moment(rowData.faultDate).format('DD/MM/YYYY') : '-'}</span>;
         },
      },
      {
         name: 'technicianUser',
         header: t('pages.malfunctions.technican'),
         body: (rowData) => {
            return <span>{rowData.technicianUser ? rowData.technicianUser.name : '-'}</span>;
         },
      },
      {
         name: 'machineCode',
         header: t('pages.malfunctions.machineCode'),
         body: (rowData) => {
            return <span>{rowData.vehicle && rowData.vehicle.machineFullCode ? rowData.vehicle.machineFullCode.replace(/\s/g, '') : '-'}</span>;
         },
      },
      {
         name: 'customer',
         header: t('pages.malfunctions.customer'),
         body: (rowData) => {
            return <span>{rowData.customer && rowData.customer.user && rowData.customer.user.name ? rowData.customer.user.name : '-'}</span>;
         },
      },
      {
         name: 'customerPostCode',
         header: t('pages.malfunctions.customerPostCode'),
         body: (rowData) => {
            return <span>{rowData.address && rowData.address.postCode ? rowData.address.postCode : '-'}</span>;
         },
      },
      {
         name: 'customerCity',
         header: t('pages.malfunctions.customerCity'),
         body: (rowData) => {
            return <span>{rowData.address && rowData.address.city ? rowData.address.city : '-'}</span>;
         },
      },
      {
         name: 'view',
         header: t('pages.malfunctions.view'),
         body: (rowData) => {
            return (
               <Button
                  label={t('pages.malfunctions.view')}
                  severity="danger"
                  onClick={() => {
                     setDetailDialogVisible(true);
                     setSelectedId(rowData.id);
                     setMachineFullCode(rowData.vehicle && rowData.vehicle.machineFullCode ? rowData.vehicle.machineFullCode : '-');
                  }}
               />
            );
         },
      },
      {
         name: 'isCompleted',
         header: t('global.table.status'),
         body: (rowData) => {
            return (
               <div className="flex items-center ms-2">
                  <span className="text-[14px]">
                     {rowData.isCompleted ? <span className="text-green-400">{t('pages.malfunctions.resolved')}</span> : t('global.pending')}
                  </span>
                  {/* <InputSwitch
                     checked={rowData.isCompleted}
                     onChange={(e) =>
                        dispatch(
                           updateVehicleMalfunctionStatus({
                              id: +rowData.id,
                              isCompleted: e.value,
                              repairDate: moment().format('YYYY-MM-DD'),
                              vehicleId: rowData.vehicle.id,
                              search: search,
                              filters: filters,
                           }),
                        )
                     }
                  /> */}
               </div>
            );
         },
      },
   ];

   return (
      <div className="w-full">
         {/* CARDS */}
         <div className="grid !m-0 !p-0">
            <div className="col-12 md:col-4 p-1">
               <div className="bg-white shadow-md rounded-lg flex items-center p-3 h-full">
                  <div className="border-2 border-blue-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                     <i className="pi pi-car text-blue-500 text-[20px]"></i>
                  </div>
                  <div className="ml-4">
                     <div className="font-bold">{vehicleMalfunctionCount}</div>
                     <div className="font-light text-gray-500 text-[14px]">{t('pages.malfunctions.totalMachineMalfunctionRecord')}</div>
                  </div>
               </div>
            </div>

            <div className="col-12 md:col-4 p-1">
               <div className="bg-white shadow-md rounded-lg flex items-center p-3 h-full">
                  <div className="border-2 border-green-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                     <i className="pi pi-car text-green-500 text-[20px]"></i>
                  </div>
                  <div className="ml-4">
                     <div className="font-bold">{vehicleMalfunctionCompletedCount}</div>
                     <div className="font-light text-gray-500 text-[14px]">{t('pages.malfunctions.totalResolvedMachineMalfunctionRecord')}</div>
                  </div>
               </div>
            </div>

            <div className="col-12 md:col-4 p-1">
               <div className="bg-white shadow-md rounded-lg flex items-center p-3 h-full">
                  <div className="border-2 border-red-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                     <i className="pi pi-car text-red-500 text-[20px]"></i>
                  </div>
                  <div className="ml-4">
                     <div className="font-bold">{vehicleMalfunctionCount - vehicleMalfunctionCompletedCount}</div>
                     <div className="font-light text-gray-500 text-[14px]">{t('pages.malfunctions.totalPendingMachineMalfunctionRecord')}</div>
                  </div>
               </div>
            </div>
         </div>

         {/* TABLE */}
         <div className="bg-white mt-3 p-3 shadow-md rounded-md">
            <div className="flex justify-between items-center mb-3">
               <div className="flex">
                  {/* <span className="p-input-icon-right">
                     <i className="pi pi-search" />
                     <InputText
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('global.search')}
                        id="mainSearch"
                        size="small"
                        onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                              console.log('search', search);
                           }
                        }}
                     />
                     <Tooltip style={{ background: 'none' }} target="#mainSearch" position="top">
                        <div style={{ backgroundColor: 'white', color: '#606266', padding: '10px' }}>
                           <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Searched Fields</div>
                           <div style={{ fontSize: '14px' }} className="w-48">
                              {t('pages.malfunctions.caseNumber')}, {t('pages.malfunctions.technican')},{t('pages.malfunctions.machineCode')},
                              {t('pages.malfunctions.customer')}, {t('pages.malfunctions.customerPostCode')},{t('pages.malfunctions.customerCity')}
                           </div>
                        </div>
                     </Tooltip>
                  </span>
                  <Button icon="pi pi-search" className="mx-2" size="small" onClick={() => handleSearch()} /> */}

                  <div className="p-inputgroup mr-3">
                     <InputText
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('global.search')}
                        id="mainSearch"
                        size="small"
                        onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                              setSearch(e.target.value);
                           }
                        }}
                     />
                     {/* <Tooltip style={{ background: 'none' }} target="#mainSearch" position="top">
                        <div style={{ backgroundColor: 'white', color: '#606266', padding: '10px' }}>
                           <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Searched Fields</div>
                           <div style={{ fontSize: '14px' }} className="w-48">
                              {t('pages.malfunctions.caseNumber')}, {t('pages.malfunctions.technican')},{t('pages.malfunctions.machineCode')},
                              {t('pages.malfunctions.customer')}, {t('pages.malfunctions.customerPostCode')},{t('pages.malfunctions.customerCity')}
                           </div>
                        </div>
                     </Tooltip> */}
                     <Button
                        icon="pi pi-search"
                        size="small"
                        onClick={() => handleSearch()}
                        style={{ background: '#f9f9f9', color: 'black', border: '#f9f9f9 none' }}
                     />
                  </div>
                  <Button
                     icon={filters ? 'pi pi-filter-slash' : 'pi pi-filter'}
                     onClick={() => {
                        if (filters) {
                           setFilters(null);
                           dispatch(getVehicleMalfunctions(first, rows));
                           dispatch(getVehicleMalfunctionCount());
                           dispatch(getVehicleMalfunctionCompletedCount());
                        } else {
                           setShowFilters(true);
                        }
                     }}
                     size="small"
                     severity={filters && 'danger'}
                  />
               </div>
               <Button label={t('pages.malfunctions.addNewMalfunctionRecord')} severity="success" onClick={() => setCreateDialogVisible(true)} />
            </div>
            <TableComponent data={vehicleMalfunctions} columns={tableColumns} size="small" />
            <div className="flex items-center justify-center">
               <Paginator first={first} rows={rows} totalRecords={vehicleMalfunctionCount} onPageChange={(e) => handleChangePage(e)} />
            </div>
         </div>

         {/* CREATE VEHICLE MALFUNCTION DIALOG */}
         <CreateVehicleMalfunction createDialogVisible={createDialogVisible} setCreateDialogVisible={setCreateDialogVisible} />

         {/* FILTERS */}
         <Dialog header={t('global.filters')} visible={showFilters} onHide={() => setShowFilters(false)} className="md-width-dialog" draggable={false}>
            <div className="grid">
               <div className="md:col-6 col-12 flex flex-wrap">
                  <label>Limit</label>
                  <InputNumber
                     value={limitValue}
                     onValueChange={(e) => setLimitValue(e.value)}
                     showButtons
                     buttonLayout="horizontal"
                     className="w-full input-detail"
                     decrementButtonClassName="p-button-secondary"
                     incrementButtonClassName="p-button-secondary"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                  />
               </div>
               <div className="md:col-6 col-12 flex flex-wrap">
                  <label>Search</label>
                  <InputText
                     className="p-inputtext-sm w-full input-detail"
                     name="search"
                     id="filterSearch"
                     value={search}
                     onChange={(e) => setSearch(e.target.value)}
                  />
                  {/* <Tooltip style={{ background: 'none' }} target="#filterSearch" position="top">
                     <div style={{ backgroundColor: 'white', color: '#606266', padding: '10px' }}>
                        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>Searched Fields</div>
                        <div style={{ fontSize: '14px' }} className="w-48">
                           {t('pages.malfunctions.caseNumber')}, {t('pages.malfunctions.technican')},{t('pages.malfunctions.machineCode')},
                           {t('pages.malfunctions.customer')}, {t('pages.malfunctions.customerPostCode')},{t('pages.malfunctions.customerCity')}
                        </div>
                     </div>
                  </Tooltip> */}
               </div>

               <div className="md:col-6 col-12 flex flex-wrap">
                  <label>{t('pages.malfunctions.type')}</label>
                  <Dropdown
                     options={vehicleMalfunctionKinds}
                     value={filters?.vehicleMalfunctionKindId}
                     onChange={(e) => setFilters({ ...filters, vehicleMalfunctionKindId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               <div className="md:col-6 col-12 flex flex-wrap">
                  <label>{t('pages.malfunctions.machine')}</label>
                  <Dropdown
                     filter
                     options={vehicleIdMachineCode}
                     value={filters?.vehicleId}
                     onChange={(e) => setFilters({ ...filters, vehicleId: e.value })}
                     optionLabel="machineFullCode"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>

               <div className="md:col-6 col-12 flex flex-wrap">
                  <label>{t('pages.planning.customer')}</label>
                  <Dropdown
                     filter
                     options={customerIdName}
                     value={filters?.customerId}
                     onChange={(e) => setFilters({ ...filters, customerId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>

               <div className="md:col-6 col-12 flex flex-wrap">
                  <label>{t('pages.malfunctions.technican')}</label>
                  <Dropdown
                     filter
                     options={technicans}
                     value={filters?.technicanUserId}
                     onChange={(e) => setFilters({ ...filters, technicanUserId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>

               <div className="md:col-6 col-12 flex flex-wrap">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center w-full justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>Deleted</label>
                     <InputSwitch checked={filters?.isDeleted} onChange={(e) => setFilters({ ...filters, isDeleted: e.value })} />
                  </div>
               </div>
            </div>
            <div className="col-12 flex md:justify-end justify-center">
               <Button
                  icon="pi pi-check"
                  label={t('global.filter')}
                  severity="success"
                  onClick={() => {
                     dispatch(getVehicleMalfunctions(null, limitValue, search, filters));
                     dispatch(getVehicleMalfunctionCount(search, filters));
                     dispatch(getVehicleMalfunctionCompletedCount(search, filters));
                     setShowFilters(false);
                  }}
                  size="small"
               />
            </div>
         </Dialog>

         {/* DETAIL DIALOG */}
         <VehicleMalfuntionDetail
            detailDialogVisible={detailDialogVisible}
            setDetailDialogVisible={setDetailDialogVisible}
            selectedId={selectedId}
            machineFullCode={machineFullCode}
         />
      </div>
   );
};

export default MalfunctionRecords;
