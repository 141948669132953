import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../components/TableComponent';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { getFilteredFinanceStatistics } from '../../store/apis/SharedApi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const DailyRevenue = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [tableData, setTableData] = useState([]);
   const [dates, setDates] = useState([]);
   const [filterVisible, setFilterVisible] = useState(false);
   const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
   const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

   const [tableColumns, setTableColumns] = useState([
      {
         name: 'type',
         body: (rowData) => {
            return (
               <div className="min-w-[140px]">
                  {rowData.type === 'RENTAL_TURNOVER'
                     ? t('pages.dashboard.rentalTurnover')
                     : rowData.type === 'NUMBER_OF_RENTALS'
                     ? t('pages.dashboard.numberOfRentals')
                     : rowData.type === 'DAILY_AVERAGE_PRICE'
                     ? t('pages.dashboard.dailyAveragePrice')
                     : rowData.type === 'TRANSPORT_TURNOVER'
                     ? t('pages.dashboard.transportTurnover')
                     : rowData.type === 'ECO_TURNOVER'
                     ? t('pages.dashboard.ecoTurnover')
                     : rowData.type === 'INSURANCE_TURNOVER'
                     ? t('pages.dashboard.insuranceTurnover')
                     : rowData.type === 'FUEL_TURNOVER'
                     ? t('pages.dashboard.fuelTurnover')
                     : rowData.type === 'OTHER_SALES_TURNOVER'
                     ? t('pages.dashboard.otherSalesTurnover')
                     : ''}
               </div>
            );
         },
      },
   ]);

   const handleFilter = async () => {
      const res = await dispatch(
         getFilteredFinanceStatistics({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            dailyRevenueStartDate: moment(startDate).format('YYYY-MM-DD'),
            dailyRevenueEndDate: moment(endDate).format('YYYY-MM-DD'),
         }),
      );
      if (res.DAILY_REVENUE && Object.keys(res.DAILY_REVENUE).length !== 0) {
         setTableData([]);
         setTableColumns([]);
         Object.keys(res.DAILY_REVENUE).forEach((key) => {
            setTableData((prev) => [
               ...prev,
               {
                  type: key,
                  ...res.DAILY_REVENUE[key],
               },
            ]);
         });

         const _dates = [];
         Object.keys(res.DAILY_REVENUE.RENTAL_TURNOVER).forEach((key) => {
            _dates.push(key);
         });
         setDates(_dates);
      }

      setFilterVisible(false);
   };

   useEffect(() => {
      Object.keys(props.data.RENTAL_TURNOVER).forEach((key) => {
         setDates((prev) => [...prev, key]);
      });

      Object.keys(props.data).forEach((key) => {
         setTableData((prev) => [
            ...prev,
            {
               type: key,
               ...props.data[key],
            },
         ]);
      });
   }, []);

   useEffect(() => {
      setTableColumns([
         {
            name: 'type',
            frozen: true,
            body: (rowData) => {
               return (
                  <div className="min-w-[140px]">
                     {rowData.type === 'RENTAL_TURNOVER'
                        ? t('pages.dashboard.rentalTurnover')
                        : rowData.type === 'NUMBER_OF_RENTALS'
                        ? t('pages.dashboard.numberOfRentals')
                        : rowData.type === 'DAILY_AVERAGE_PRICE'
                        ? t('pages.dashboard.dailyAveragePrice')
                        : rowData.type === 'TRANSPORT_TURNOVER'
                        ? t('pages.dashboard.transportTurnover')
                        : rowData.type === 'ECO_TURNOVER'
                        ? t('pages.dashboard.ecoTurnover')
                        : rowData.type === 'INSURANCE_TURNOVER'
                        ? t('pages.dashboard.insuranceTurnover')
                        : rowData.type === 'FUEL_TURNOVER'
                        ? t('pages.dashboard.fuelTurnover')
                        : rowData.type === 'OTHER_SALES_TURNOVER'
                        ? t('pages.dashboard.otherSalesTurnover')
                        : ''}
                  </div>
               );
            },
         },
      ]);
      for (let i = 0; i < dates.length; i++) {
         const element = dates[i];
         setTableColumns((prev) => [
            ...prev,
            {
               name: `date${i + 1}`,
               header: moment(element).format('DD-MM-YYYY'),
               body: (rowData) => {
                  return (
                     <>
                        {rowData.type === 'NUMBER_OF_RENTALS' ? (
                           <div className="!min-w-[140px] flex justify-between">
                              <div>{rowData[element]}</div>
                           </div>
                        ) : (
                           <div className="min-w-[80px]">{rowData[element] ? rowData[element].toFixed(2).replace('.', ',') : '0,00'} €</div>
                        )}
                     </>
                  );
               },
            },
         ]);
      }
   }, [dates]);

   // Excel export
   const exportExcel = () => {
      const _data =
         tableData &&
         tableData.length > 0 &&
         tableData.map((item) => {
            return {
               ...item,
            };
         });
      import('xlsx').then((xlsx) => {
         const worksheet = xlsx.utils.json_to_sheet(_data);
         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
         import('file-saver').then((module) => {
            if (module && module.default) {
               const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
               const EXCEL_EXTENSION = '.xlsx';
               const data = new Blob([excelBuffer], {
                  type: EXCEL_TYPE,
               });
               module.default.saveAs(data, 'daily-revenue' + '_' + new Date().getTime() + EXCEL_EXTENSION);
            }
         });
      });
   };

   return (
      <>
         <div className="mb-3 mt-2 flex justify-between">
            <Button onClick={() => setFilterVisible(true)} label={t('global.filter')} severity="success" className="w-[10vw] min-w-[100px] h-[38px] me-2" />
            <Button onClick={exportExcel} icon="pi pi-file-excel" severity="success" aria-label="exel" />
         </div>
         <DataTable value={tableData} scrollable className="mt-4" size="small" stripedRows showGridlines>
            {tableColumns.map((column) => {
               if (column.body) {
                  return (
                     <Column
                        frozen={column.frozen}
                        key={column.name || column.field}
                        header={column.header}
                        footer={column.footer}
                        body={column.body}
                        field={column.field}
                        bodyClassName={column.bodyClassName}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        selectionMode={column.selectionMode}
                     />
                  );
               } else {
                  return (
                     <Column
                        key={column.name || column.field}
                        field={column.field}
                        header={column.header}
                        footer={column.footer}
                        sortable={props.sortable}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        bodyClassName={column.bodyClassName}
                        selectionMode={column.selectionMode}
                     />
                  );
               }
            })}
         </DataTable>

         <Dialog visible={filterVisible} onHide={() => setFilterVisible(false)} style={{ width: '500px' }}>
            <div className="grid">
               <div className="col-6">
                  <span>{t('pages.contracts.startDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(startDate)}
                     onChange={(e) => setStartDate(e.target.value)}
                  />
               </div>

               <div className="col-6">
                  <span>{t('pages.contracts.endDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(endDate)}
                     onChange={(e) => setEndDate(e.target.value)}
                  />
               </div>

               <div className="col-12">
                  <Button severity="success" onClick={handleFilter} label={t('global.filter')} className="w-full  mt-3" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default DailyRevenue;
