import { gql } from '@apollo/client';

export const CUSTOMERS_SCREEN = gql`
   query customersScreen {
      customerKinds {
         name
         id
      }
      companyKinds {
         id
         name
      }
      users {
         id
         name
      }
      customers {
         isBanned
         isDeleted
      }
   }
`;

export const GET_CITIES = gql`
   query cities {
      cities {
         id
         name
      }
   }
`;

export const GET_COUNTRIES = gql`
   query countries {
      countries {
         id
         name
      }
   }
`;

export const GET_USERS = gql`
   query users {
      users {
         id
         name
      }
   }
`;

export const GET_COMPANY_TYPES = gql`
   query companyKinds {
      companyKinds {
         id
         name
      }
   }
`;
export const GET_CUSTOMER_TYPES = gql`
   query customerKinds {
      customerKinds {
         id
         name
      }
   }
`;

export const PRE_CREATE_CUSTOMER_SCREEN = gql`
   query preCreateCustomerScreen {
      customerKinds(isDeleted: false) {
         name
         id
      }
      companyKinds(isDeleted: false) {
         id
         name
      }
      users(filters: { isActive: true }) {
         id
         name
      }
      addressKinds {
         id
         name
      }
      cities(filters: { isDeleted: false }) {
         id
         name
      }
      titles(isDeleted: false) {
         id
         name
      }
      countries {
         id
         name
      }
      customers {
         companyName
         companyKindId
         id
         companyKind {
            name
         }
      }
   }
`;

export const GET_CUSTOMERS = gql`
   query customers(
      $first: Int
      $skip: Int
      $search: String
      $companyKindId: ID
      $userId: ID
      $customerKindId: ID
      $followedUserId: ID
      $isDeleted: Boolean
      $isBanned: Boolean
   ) {
      customerCount(
         search: $search
         filters: {
            companyKindId: $companyKindId
            customerKindId: $customerKindId
            followedUserId: $followedUserId
            userId: $userId
            isDeleted: $isDeleted
            isBanned: $isBanned
         }
      )
      customers(
         first: $first
         skip: $skip
         search: $search
         filters: {
            companyKindId: $companyKindId
            customerKindId: $customerKindId
            followedUserId: $followedUserId
            userId: $userId
            isDeleted: $isDeleted
            isBanned: $isBanned
         }
      ) {
         companyKind {
            id
            name
         }
         overduePayables
         outstandingPayables
         maturity
         isFactor
         isDeleted
         bannedReason
         isBanned
         isActive
         isCheck
         idNumber
         id
         faxNumber
         factorLimit
         maximumCreditLimit
         factor
         email
         companyName
         averagePayment
         note
         ownInsurance
         phone
         receptionDate
         taxNumber
         taxOffice
         userId
         website
         activityName
         customerKind {
            id
            name
         }
         followedUser {
            id
            name
            lastName
            firstName
            email
            phone
         }
         id
         isDeleted
         user {
            id
            name
            lastName
            firstName
            email
            phone
         }
         companyKind {
            id
            name
         }
         addresses {
            id
            city
            country
            postCode
            address
            addressContinuation
            districtName
            isMainOrBillingAddress
            placeName
            addressKind {
               id
               name
            }
         }
         buyersBan
         buyersBic
         buyersRib
         buyersNote
         discussions {
            id
            note
            date
         }
      }
   }
`;

export const GET_CUSTOMER = gql`
   query getCustomer($id: ID!) {
      customer(id: $id) {
         overduePayables
         outstandingPayables
         activityName
         bannedReason
         averagePayment
         customerKindId
         companyKindId
         companyName
         buyersRib
         buyersNote
         buyersBic
         buyersBan
         email
         factorLimit
         maximumCreditLimit
         factor
         faxNumber
         id
         idNumber
         isActive
         isBanned
         isCheck
         isDeleted
         maturity
         note
         ownInsurance
         phone
         receptionDate
         taxNumber
         taxOffice
         userId
         website
         followedUserId
         isFactor
         followedUser {
            id
            name
         }
         customerKind {
            id
            name
         }
         companyKind {
            id
            name
         }
         addresses {
            address
            addressContinuation
            city
            addressKind {
               id
               name
            }
            country
            districtName
            isActive
            id
            isDeleted
            placeName
            postCode
            receiverName
            isMainOrBillingAddress
            addressKind {
               id
               name
            }
         }
      }
   }
`;

export const DELETE_CUSTOMER = gql`
   mutation deleteCustomers($ids: [Int!]!) {
      deleteCustomers(ids: $ids) {
         success
      }
   }
`;

export const UNDELETE_CUSTOMER = gql`
   mutation undeleteCustomers($id: [Int!]!) {
      undeleteCustomers(ids: $id) {
         success
      }
   }
`;

export const BLOCK_CUSTOMER = gql`
   mutation blockCustomers($id: ID!, $bannedReason: String, $isBanned: Boolean) {
      updateCustomer(data: { id: $id, bannedReason: $bannedReason, isBanned: $isBanned }) {
         customer {
            bannedAt
            bannedReason
         }
      }
   }
`;

export const UNBLOCK_CUSTOMER = gql`
   mutation updateCustomer($id: ID!) {
      updateCustomer(data: { id: $id, isBanned: false }) {
         customer {
            isDeleted
            isBanned
         }
      }
   }
`;

export const CREATE_CUSTOMER = gql`
   mutation createCustomer(
      $companyName: String!
      $companyKindId: ID
      $customerKindId: ID!
      $email: String!
      $idNumber: String!
      $followedUserId: ID
      $phone: String!
      $activityName: String
      $faxNumber: String
      $note: String
      $taxNumber: String
      $taxOffice: String
      $website: String
   ) {
      createCustomer(
         data: {
            companyName: $companyName
            companyKindId: $companyKindId
            customerKindId: $customerKindId
            email: $email
            idNumber: $idNumber
            followedUserId: $followedUserId
            phone: $phone
            activityName: $activityName
            faxNumber: $faxNumber
            note: $note
            taxNumber: $taxNumber
            taxOffice: $taxOffice
            website: $website
         }
      ) {
         customer {
            overduePayables
            outstandingPayables
            activityName
            averagePayment
            customerKindId
            companyKindId
            companyName
            buyersRib
            buyersNote
            buyersBic
            buyersBan
            email
            factorLimit
            maximumCreditLimit
            factor
            faxNumber
            id
            idNumber
            isActive
            isBanned
            isCheck
            isDeleted
            maturity
            note
            ownInsurance
            phone
            receptionDate
            taxNumber
            taxOffice
            userId
            website
            followedUserId
            isFactor
            followedUser {
               id
               name
            }
            customerKind {
               id
               name
            }
            companyKind {
               id
               name
            }
            addresses {
               address
               addressContinuation
               city
               addressKind {
                  id
                  name
               }
               country
               districtName
               isActive
               id
               isDeleted
               placeName
               postCode
               receiverName
               isMainOrBillingAddress
               addressKind {
                  id
                  name
               }
            }
            discussions {
               id
               note
               date
            }
         }
      }
   }
`;

export const CREATE_ADDRESS = gql`
   mutation createAddress(
      $address: String!
      $addressContinuation: String
      $addressKindId: ID!
      $city: String!
      $country: String!
      $districtName: String
      $customerId: ID!
      $postCode: String!
      $placeName: String!
      $isMainOrBillingAddress: Boolean
   ) {
      createAddress(
         data: {
            address: $address
            addressContinuation: $addressContinuation
            addressKindId: $addressKindId
            city: $city
            country: $country
            districtName: $districtName
            customerId: $customerId
            postCode: $postCode
            placeName: $placeName
            isMainOrBillingAddress: $isMainOrBillingAddress
         }
      ) {
         address {
            id
            city
            country
            postCode
            address
            addressContinuation
            districtName
            isMainOrBillingAddress
            placeName
            addressKind {
               id
               name
            }
         }
      }
   }
`;

export const UPDATE_ADDRESS = gql`
   mutation updateAddress(
      $id: ID!
      $address: String!
      $addressContinuation: String
      $addressKindId: ID!
      $city: String!
      $country: String!
      $districtName: String
      $customerId: ID!
      $postCode: String!
      $isMainOrBillingAddress: Boolean
   ) {
      updateAddress(
         data: {
            address: $address
            addressContinuation: $addressContinuation
            city: $city
            country: $country
            districtName: $districtName
            customerId: $customerId
            postCode: $postCode
            addressKindId: $addressKindId
            id: $id
            isMainOrBillingAddress: $isMainOrBillingAddress
         }
      ) {
         address {
            id
            city
            country
            postCode
            address
            addressContinuation
            districtName
            isMainOrBillingAddress
            placeName
            addressKind {
               id
               name
            }
         }
      }
   }
`;

export const GET_CUSTOMER_DOCUMENTS = gql`
   query customerDocuments($customerId: ID!) {
      customerDocumentCount
      customerDocuments(customerId: $customerId) {
         customerId
         document {
            name
            path
            url
         }
         id
      }
   }
`;

export const CREATE_CUSTOMER_DOCUMENT = gql`
   mutation createCustomerDocument($customerId: ID!, $document: Upload!) {
      createCustomerDocument(data: { customerId: $customerId, document: $document }) {
         customerDocument {
            id
            customerId
            document {
               name
               path
               url
            }
         }
      }
   }
`;

export const DELETE_CUSTOMER_DOCUMENT = gql`
   mutation deleteCustomerDocument($ids: [Int!]!) {
      deleteCustomerDocuments(ids: $ids) {
         success
      }
   }
`;

export const UPDATE_CUSTOMER = gql`
   mutation updateCustomer(
      $id: ID!
      $companyName: String!
      $companyKindId: ID
      $customerKindId: ID!
      $email: String!
      $idNumber: String!
      $followedUserId: ID
      $phone: String!
      $activityName: String
      $faxNumber: String
      $note: String
      $taxNumber: String
      $taxOffice: String
      $website: String
      $buyersBan: String
      $buyersBic: String
      $buyersRib: String
      $buyersNote: String
      $receptionDate: Date
      $isCheck: Boolean
      $factor: Boolean
      $ownInsurance: Boolean
      $maturity: MaturityKind
      $factorLimit: Int
      $maximumCreditLimit: Int
   ) {
      updateCustomer(
         data: {
            id: $id
            companyName: $companyName
            companyKindId: $companyKindId
            customerKindId: $customerKindId
            email: $email
            idNumber: $idNumber
            followedUserId: $followedUserId
            phone: $phone
            activityName: $activityName
            faxNumber: $faxNumber
            note: $note
            taxNumber: $taxNumber
            taxOffice: $taxOffice
            website: $website
            buyersBan: $buyersBan
            buyersBic: $buyersBic
            buyersRib: $buyersRib
            buyersNote: $buyersNote
            receptionDate: $receptionDate
            isCheck: $isCheck
            factor: $factor
            ownInsurance: $ownInsurance
            maturity: $maturity
            factorLimit: $factorLimit
            maximumCreditLimit: $maximumCreditLimit
         }
      ) {
         customer {
            companyKind {
               id
               name
            }
            overduePayables
            outstandingPayables
            maturity
            isFactor
            isDeleted
            isBanned
            isActive
            isCheck
            idNumber
            id
            faxNumber
            factorLimit
            maximumCreditLimit
            factor
            email
            companyName
            averagePayment
            note
            ownInsurance
            phone
            receptionDate
            taxNumber
            taxOffice
            userId
            website
            activityName
            customerKind {
               id
               name
            }
            followedUser {
               id
               name
               lastName
               firstName
               email
               phone
            }
            id
            isDeleted
            user {
               id
               name
               lastName
               firstName
               email
               phone
            }
            companyKind {
               id
               name
            }
            addresses {
               id
               city
               country
               postCode
               address
               addressContinuation
               districtName
               isMainOrBillingAddress
               placeName
               addressKind {
                  id
                  name
               }
            }
            buyersBan
            buyersBic
            buyersRib
            buyersNote
            discussions {
               id
               note
               date
            }
         }
      }
   }
`;

export const UPDATE_NEW_CUSTOMER = gql`
   mutation updateCustomer(
      $id: ID!
      $companyName: String
      $companyKindId: ID
      $customerKindId: ID!
      $email: String!
      $idNumber: String!
      $followedUserId: ID
      $phone: String!
      $activityName: String
      $faxNumber: String
      $note: String
      $taxNumber: String
      $taxOffice: String
      $website: String
   ) {
      updateCustomer(
         data: {
            id: $id
            companyName: $companyName
            companyKindId: $companyKindId
            customerKindId: $customerKindId
            email: $email
            idNumber: $idNumber
            followedUserId: $followedUserId
            phone: $phone
            activityName: $activityName
            faxNumber: $faxNumber
            note: $note
            taxNumber: $taxNumber
            taxOffice: $taxOffice
            website: $website
         }
      ) {
         customer {
            buyersBan
            activityName
            buyersBic
            buyersNote
            buyersRib
            receptionDate
            isCheck
            factor
            ownInsurance
            website
            id
            companyName
            customerKindId
            email
            idNumber
            followedUserId
            phone
            faxNumber
            note
            taxNumber
            taxOffice
         }
      }
   }
`;

export const UPDATE_NEW_CUSTOMER_ADDRESS = gql`
   mutation updateAddress(
      $id: ID!
      $address: String!
      $addressContinuation: String!
      $addressKindId: ID!
      $city: String!
      $country: String!
      $districtName: String
      $customerId: ID!
      $postCode: String!
      $isSame: Boolean
   ) {
      updateAddress(
         data: {
            address: $address
            addressContinuation: $addressContinuation
            city: $city
            country: $country
            districtName: $districtName
            customerId: $customerId
            postCode: $postCode
            addressKindId: $addressKindId
            id: $id
            isMainOrBillingAddress: $isSame
         }
      ) {
         address {
            address
            addressContinuation
            customerId
            customer {
               companyName
               email
            }
         }
      }
   }
`;

export const UPDATE_DELETED_CUSTOMER = gql`
   mutation updateDeletedCustomer($id: ID!, $customerKindId: ID!) {
      updateCustomer(data: { id: $id, customerKindId: $customerKindId }) {
         customer {
            id
            customerKind {
               id
            }
         }
      }
   }
`;

export const GET_COMPANYS_CONTACTS = gql`
   query getCompanysContacts($customerId: ID) {
      contacts(filters: { customerId: $customerId }) {
         email
         firstName
         id
         lastName
         phone
         role
         title {
            id
            name
         }
         description
      }
      titles(isDeleted: false) {
         id
         name
      }
      roles(isDeleted: false) {
         id
         name
      }
   }
`;

export const GET_TITLES = gql`
   query MyQuery {
      titles {
         id
         name
      }
   }
`;

export const CREATE_CUSTOMER_CONTACT = gql`
   mutation createContact(
      $customerId: ID!
      $titleId: ID!
      $firstName: String!
      $lastName: String!
      $role: String!
      $phone: String!
      $email: String!
      $description: String
   ) {
      createContact(
         data: {
            customerId: $customerId
            titleId: $titleId
            firstName: $firstName
            lastName: $lastName
            role: $role
            phone: $phone
            email: $email
            description: $description
         }
      ) {
         contact {
            email
            firstName
            id
            lastName
            phone
            role
            description
            title {
               id
               name
            }
         }
      }
   }
`;

export const UPDATE_CUSTOMER_CONTACTS = gql`
   mutation updateContact(
      $id: ID!
      $customerId: ID!
      $description: String
      $email: String
      $firstName: String
      $lastName: String
      $phone: String
      $role: String
      $titleId: ID!
   ) {
      updateContact(
         data: {
            id: $id
            customerId: $customerId
            description: $description
            email: $email
            firstName: $firstName
            lastName: $lastName
            phone: $phone
            role: $role
            titleId: $titleId
         }
      ) {
         contact {
            email
            firstName
            id
            lastName
            phone
            role
            description
            title {
               id
               name
            }
         }
      }
   }
`;

export const GET_REMINDERS = gql`
   query getReminders($customerId: ID, $userId: ID) {
      reminderCount(isDeleted: false)
      reminders(isDeleted: false, customerId: $customerId, userId: $userId) {
         customerId
         date
         description
         id
         user {
            name
            lastName
            firstName
            id
         }
      }
   }
`;

export const GET_REMINDERS_BY_USER_ID = gql`
   query reminders($userId: ID!) {
      reminders(userId: $userId) {
         date
         description
         customer {
            companyName
            customerKind {
               id
               name
            }
         }
      }
   }
`;

export const CREATE_REMINDER = gql`
   mutation createReminder($customerId: ID!, $date: Date!, $description: String!, $userId: ID!) {
      createReminder(data: { customerId: $customerId, date: $date, description: $description, userId: $userId }) {
         reminder {
            customerId
            date
            description
            id
            user {
               name
               lastName
               firstName
               id
            }
         }
      }
   }
`;

export const GET_DISCUSSIONS = gql`
   query getDiscussions($customerId: ID!) {
      discussions(filters: { customerId: $customerId }) {
         note
         user {
            name
            id
         }
         id
         discussionKind {
            id
            name
         }
         date
         customer {
            email
            customerKind {
               id
               name
            }
            discussions {
               note
               id
               discussionKind {
                  id
                  name
               }
            }
         }
      }
   }
`;

export const GET_DISCUSSION_KINDS = gql`
   query getDiscussionKinds {
      discussionKinds {
         id
         name
      }
   }
`;

export const CREATE_DISCUSSION = gql`
   mutation createDiscussion($customerId: ID!, $date: Date!, $discussionKindId: ID!, $userId: ID!, $note: String) {
      createDiscussion(data: { customerId: $customerId, date: $date, discussionKindId: $discussionKindId, userId: $userId, note: $note }) {
         discussion {
            note
            user {
               name
               id
            }
            id
            discussionKind {
               id
               name
            }
            date
            customer {
               id
               email
               customerKind {
                  id
                  name
               }
               discussions {
                  note
                  id
                  discussionKind {
                     id
                     name
                  }
               }
            }
         }
      }
   }
`;

export const UPDATE_REMINDER = gql`
   mutation updateReminder($date: Date, $description: String, $id: ID!, $userId: ID) {
      updateReminder(data: { id: $id, userId: $userId, description: $description, date: $date }) {
         reminder {
            customerId
            date
            description
            id
            user {
               name
               lastName
               firstName
               id
            }
         }
      }
   }
`;

export const UPDATE_DISCUSSION = gql`
   mutation updateDiscussion($id: ID!, $discussionKindId: ID, $date: Date, $note: String) {
      updateDiscussion(data: { id: $id, discussionKindId: $discussionKindId, date: $date, note: $note }) {
         discussion {
            note
            user {
               name
               id
            }
            id
            discussionKind {
               id
               name
            }
            date
            customer {
               id
               email
               customerKind {
                  id
                  name
               }
               discussions {
                  note
                  id
                  discussionKind {
                     id
                     name
                  }
               }
            }
         }
      }
   }
`;

export const GET_OFFERS = gql`
   query Offers($customerId: ID!, $orderKindId: ID) {
      orderCount(filters: { isContracted: false, customerId: $customerId, isDeleted: false, orderKindId: $orderKindId })
      orders(filters: { customerId: $customerId, isContracted: false, isDeleted: false, orderKindId: $orderKindId }) {
         id
         orderNumber
         createdAt
         address {
            placeName
         }
         orderItems {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
                  supplierCustomer {
                     id
                     companyName
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
            }
         }
         orderKind {
            id
            name
         }
         isContracted
         isDeleted
         isCompleted
         isRefused
         isOffered
         customer {
            id
            companyName
         }
      }
   }
`;

export const GET_OFFER = gql`
   query order($id: ID!) {
      order(id: $id) {
         id
         orderNumber
         isOffered
         isRefused
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
            ownInsurance
         }
         address {
            id
            placeName
         }
         referenceNumber
         description
         privacyDescription
         isFactor
         orderItems {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  supplierCustomer {
                     id
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
            }
         }
      }
   }
`;

export const GET_CONTRACTS = gql`
   query Contracts($customerId: ID!, $orderKindId: ID) {
      orderCount(filters: { isContracted: true, isDeleted: false, customerId: $customerId, orderKindId: $orderKindId })
      orders(filters: { isContracted: true, isDeleted: false, customerId: $customerId, orderKindId: $orderKindId }) {
         id
         orderNumber
         isOffered
         isFactor
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         orderItems {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
                  supplierCustomer {
                     id
                     companyName
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
            }
         }
      }
   }
`;

export const GET_CONTRACT = gql`
   query order($id: ID!) {
      order(id: $id) {
         id
         orderNumber
         isOffered
         isFactor
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         referenceNumber
         description
         privacyDescription
         orderItems {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
                  supplierCustomer {
                     id
                     companyName
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
            }
         }
         returnTransporterId
         departureTransporterId

         returnTransporterOrder {
            id
            transporterId
            status
            transporter {
               id
               companyName
            }
            address {
               id
               placeName
            }
            transporterOrderNumber
            transporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
         departureTransporterOrder {
            id
            transporterId

            status
            transporter {
               id
               companyName
            }
            address {
               id
               placeName
            }
            transporterOrderNumber
            transporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
         supplierTransporterOrders {
            id
            supplierId
            status
            supplier {
               id
               companyName
            }
            address {
               id
               placeName
            }
            supplierOrderNumber

            supplierTransporterOrderItems {
               id

               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
      }
   }
`;

export const GET_REFERENCE_NUMBERS = gql`
   query ReferenceNumbers {
      orders(filters: { isContracted: true, isDeleted: false }) {
         orderNumber
         customer {
            companyName
            companyKind {
               id
               name
            }
         }
      }
   }
`;

export const GET_CUSTOMER_INVOICES = gql`
   query invoices($customerId: ID, $status: InvoiceStatus, $startDate: Date, $endDate: Date) {
      invoices(customerId: $customerId, status: $status, startDate: $startDate, endDate: $endDate) {
         id
         status
         createdAt
         invoiceNumber
         orderNumber
         vehicle {
            id
         }
         customer {
            id
            companyName
         }
         dueDate
         totalBeforeTax
         totalAfterTax
         isPaid
         paymentMethod
         paymentDate
         isFactor
      }
   }
`;

export const GET_CUSTOMER_PAID_INVOICES = gql`
   query Invoices($customerId: ID!) {
      invoices(customerId: $customerId, isPaid: true) {
         amountPaid
         paymentDate
         dueDate
         endDate
         createdAt
         orderNumber
      }
   }
`;

export const CREATE_REFUND = gql`
   mutation createRefund($data: CreateRefundInput!) {
      createRefund(data: $data) {
         refund {
            id
         }
      }
   }
`;

export const GET_CUSTOMER_REFUNDS = gql`
   query refunds($customerId: ID) {
      refunds(customerId: $customerId) {
         id
         description
         price
         refundNumber
         createdAt
         dueDate
         isPaid
         invoice {
            createdAt
            invoiceNumber
            orderNumber
            customer {
               id
               companyName
            }
            dueDate
            totalAfterTax
            paymentDate
            isFactor
            tax
         }
      }
   }
`;

export const CREATE_VEHICLE_GROUP_PRICE = gql`
   mutation createVehicleGroupPrice($data: CreateVehicleGroupPriceInput!) {
      createVehicleGroupPrice(data: $data) {
         vehicleGroupPrice {
            id
            vehicleGroup {
               id
               name
               description
            }
            dailyPrice
            twoToFivePrice
            sixToFifteenPrice
            gtFifteenPrice
            monthlyPrice
         }
      }
   }
`;

export const GET_VEHICLE_GROUP_PRICES = gql`
   query vehicleGroupPrices($customerId: ID = null) {
      vehicleGroupPrices(customerId: $customerId, isDeleted: false) {
         id
         vehicleGroup {
            id
            name
            description
         }
         dailyPrice
         twoToFivePrice
         sixToFifteenPrice
         gtFifteenPrice
         monthlyPrice
      }
   }
`;

export const GET_VEHICLE_GROUPS = gql`
   query vehicleGroups($supplierId: ID = null) {
      vehicleGroups(supplierId: $supplierId, isDeleted: false) {
         id
         name
      }
   }
`;

export const DELETE_VEHICLE_GROUP_PRICES = gql`
   mutation deleteVehicleGroupPrices($ids: [Int!]!) {
      deleteVehicleGroupPrices(ids: $ids) {
         success
      }
   }
`;

export const GET_PURCHASE_ORDER = gql`
   query transporterOrders($filters: TransporterOrderFilter, $statuses: [TransporterOrderStatus!]) {
      transporterOrders(filters: $filters, statuses: $statuses) {
         id
         referenceNumber
         createdAt
         address {
            address
         }
         transporter {
            id
            addresses {
               address
            }
            followedUser {
               id
               name
            }
         }
         address {
            id
            address
         }
         status
         totalPrice
         transporterOrderNumber
      }
   }
`;

export const UPDATE_VEHICLE_GROUP_PRICE = gql`
   mutation updateVehicleGroupPrice($data: UpdateVehicleGroupPriceInput!) {
      updateVehicleGroupPrice(data: $data) {
         vehicleGroupPrice {
            id
            vehicleGroup {
               id
               name
               description
            }
            dailyPrice
            twoToFivePrice
            sixToFifteenPrice
            gtFifteenPrice
            monthlyPrice
         }
      }
   }
`;
