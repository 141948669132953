import React from "react";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import store from "./store/store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import "./i18n";

import "./index.scss";

import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import client from "./graphql/apollo.config";
import { ApolloProvider } from "@apollo/client";

const container = document.getElementById("root");

if (!container) throw new Error("Could not find root element with id 'root'.");

const root = createRoot(container);
const persistor = persistStore(store);

root.render(
	<ApolloProvider client={client}>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	</ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
