import React, { useState, useEffect } from 'react';

import { TabMenu } from 'primereact/tabmenu';
import Informations from './CustomerDetails/Informations.jsx';
import Contacts from './CustomerDetails/Contacts.jsx';
import Discussions from './CustomerDetails/Discussions.jsx';
import Reminders from './CustomerDetails/Reminders.jsx';
import { t } from 'i18next';
import './customer.scss';
import MachineGroupPrices from './CustomerDetails/MachineGroupPrices.jsx';
import Offers from './CustomerDetails/Offers.jsx';
import Contracts from './CustomerDetails/Contracts.jsx';
import SaleOffer from './CustomerDetails/SaleOffer.jsx';
import SaleContract from './CustomerDetails/SaleContract.jsx';
import Invoices from './CustomerDetails/Invoices.jsx';
import Refunds from './CustomerDetails/Refunds.jsx';
import Exchanges from './CustomerDetails/Exchanges.jsx';
import Transfers from './CustomerDetails/Transfers.jsx';
import PurchaseOrder from './CustomerDetails/PurchaseOrder.jsx';

const CustomerDetails = (props) => {
   const [activeIndex, setActiveIndex] = useState(0);

   let menuItems = [
      { label: t('global.table.informations') },
      { label: t('global.table.contacts') },
      { label: t('global.table.reminders') },
      { label: t('global.table.discussions') },
      { label: t('global.table.machineGroupPrices') },
      { label: t('global.table.offers') },
      { label: t('global.table.contracts') },
      { label: t('global.table.saleOffer') },
      { label: t('global.table.saleContract') },
      { label: t('global.table.invoices') },
      { label: t('global.table.refunds') },
      { label: t('global.table.exchanges') },
      { label: t('global.table.transfers') },
   ];

   if (props.customer.customerKind.id === '3' || props.customer.customerKind.id === '4') {
      menuItems = menuItems.slice(0, 5);
      menuItems.push({ label: t('global.table.purchaseOrder') });
   }

   return (
      <div className="card">
         <div style={{ maxWidth: '100%', overflowY: 'auto' }}>
            <TabMenu
               model={menuItems}
               activeIndex={activeIndex}
               onTabChange={(e) => {
                  setActiveIndex(e.index);
               }}
               style={{ overflowX: 'visible', overflowY: 'clip' }}
            />
         </div>
         {activeIndex === 0 ? (
            <Informations customerData={props.customer} />
         ) : activeIndex === 1 ? (
            <Contacts customerData={props.customer} />
         ) : activeIndex === 2 ? (
            <Reminders customerData={props.customer} />
         ) : activeIndex === 3 ? (
            <Discussions customerData={props.customer} />
         ) : activeIndex === 4 ? (
            <MachineGroupPrices customerData={props.customer} />
         ) : activeIndex === 5 ? (
            props.customer.customerKind.id === '3' || props.customer.customerKind.id === '4' ? (
               <PurchaseOrder />
            ) : (
               <Offers />
            )
         ) : activeIndex === 6 ? (
            <Contracts />
         ) : activeIndex === 7 ? (
            <SaleOffer />
         ) : activeIndex === 8 ? (
            <SaleContract />
         ) : activeIndex === 9 ? (
            <Invoices customerData={props.customer} />
         ) : activeIndex === 10 ? (
            <Refunds customerData={props.customer} />
         ) : activeIndex === 11 ? (
            <Exchanges />
         ) : activeIndex === 12 ? (
            <Transfers />
         ) : null}
      </div>
   );
};

export default CustomerDetails;
