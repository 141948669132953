import { gql } from '@apollo/client';

export const GET_USERS = gql`
   query users {
      users {
         name
         birthdate
         email
         firstName
         gender {
            id
            name
         }
         id
         idNumber
         isDeleted
         lastName
         phone
         role {
            id
            name
         }
      }
      userCount
   }
`;

export const CREATE_USER = gql`
   mutation createUser($firstName: String!, $lastName: String!, $email: String!, $phone: String!, $password: String!, $roleId: ID!, $idNumber: String) {
      createUser(
         data: { firstName: $firstName, lastName: $lastName, email: $email, phone: $phone, password: $password, roleId: $roleId, idNumber: $idNumber }
      ) {
         user {
            id
         }
      }
   }
`;

export const DELETE_USER = gql`
   mutation deleteUsers($ids: [Int!]!) {
      deleteUsers(ids: $ids) {
         success
      }
   }
`;
