import { t } from 'i18next';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { updateInvoice as handleUpdateInvoice } from '../../../../store/apis/ManagementApi';

const UpdateInvoiceDetail = ({ updateInvoice, setUpdateInvoice }) => {
   const dispatch = useDispatch();
   const invoice = useSelector((state) => state.management.invoice);
   const [invoiceInputs, setInvoiceInputs] = useState({});
   const [errors, setErrors] = useState({});
   const discountOptions = [
      { label: '50%', value: 50 },
      { label: '70%', value: 70 },
      { label: '100%', value: 100 },
   ];
   const [selectedDiscount, setSelectedDiscount] = useState(null);
   const [selectedDiscountValue, setSelectedDiscountValue] = useState(0);

   useEffect(() => {
      if (updateInvoice && updateInvoice.data) {
         setInvoiceInputs({
            deliveryFee: +updateInvoice.data.deliveryFee,
            pickupFee: +updateInvoice.data.pickupFee,
            isIncludingSaturdays: updateInvoice.data.isIncludingSaturdays,
            isIncludingSundays: updateInvoice.data.isIncludingSundays,
            isIncludingPublicHolidays: updateInvoice.data.isIncludingPublicHolidays,
            isEco: updateInvoice.data.isEco,
            isInsurance: updateInvoice.data.isInsurance,
            discount: updateInvoice.data.discount,
            fuel: +updateInvoice.data.fuel,
         });
         if (updateInvoice.data.fiftyDiscount !== 0) {
            setSelectedDiscount(50);
            setSelectedDiscountValue(updateInvoice.data.fiftyDiscount);
         } else if (updateInvoice.data.seventyDiscount !== 0) {
            setSelectedDiscount(70);
            setSelectedDiscountValue(updateInvoice.data.seventyDiscount);
         } else if (updateInvoice.data.oneHundredDiscount !== 0) {
            setSelectedDiscount(100);
            setSelectedDiscountValue(updateInvoice.data.oneHundredDiscount);
         } else {
            setSelectedDiscount(null);
            setSelectedDiscountValue(0);
         }
      }
   }, [updateInvoice]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      setInvoiceInputs({ ...invoiceInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['pickupFee'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!invoiceInputs[input]) {
            updateErrors(input, invoiceInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const saveItems = async () => {
      if (!validateForm()) return;
      const data = {
         id: updateInvoice?.data.id,
         deliveryFee: invoiceInputs.deliveryFee || 0,
         pickupFee: invoiceInputs.pickupFee || 0,
         discount: +invoiceInputs.discount || 0,
         fuel: invoiceInputs.fuel || 0,
         fiftyDiscount: selectedDiscount === 50 ? +selectedDiscountValue : 0,
         seventyDiscount: selectedDiscount === 70 ? +selectedDiscountValue : 0,
         oneHundredDiscount: selectedDiscount === 100 ? +selectedDiscountValue : 0,
         isIncludingSaturdays: invoiceInputs.isIncludingSaturdays,
         isIncludingSundays: invoiceInputs.isIncludingSundays,
         isIncludingPublicHolidays: invoiceInputs.isIncludingPublicHolidays,
         isEco: invoiceInputs.isEco,
         isInsurance: invoiceInputs.isInsurance,
      };
      dispatch(handleUpdateInvoice(data)).then((res) => {
         if (res) {
            setUpdateInvoice({
               visible: false,
               data: null,
            });
            toast.success(t('pages.invoices.updateInvoiceSuccess'));
         }
      });
   };

   const handleFocus = (name) => {
      if (name === 'discountValue' && selectedDiscountValue === 0) {
         setSelectedDiscountValue('');
      }
      if (invoiceInputs[name] === 0) {
         setInvoiceInputs({ ...invoiceInputs, [name]: '' });
      }
   };

   return (
      <>
         <Dialog
            header={t('pages.invoices.updateInvoice')}
            visible={updateInvoice.visible}
            className="w-[40vw]"
            onHide={() =>
               setUpdateInvoice({
                  visible: false,
                  item: null,
               })
            }
         >
            <div className="grid">
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.offers.pickupFee')}</label>
                  <div className="p-inputgroup max-h-[38px]">
                     <InputText
                        value={invoiceInputs.pickupFee}
                        onChange={(e) => handleInputChange(e, 'pickupFee')}
                        onFocus={() => handleFocus('pickupFee')}
                        className="w-full border-none bg-[#f9f9f9]"
                     />
                     <span className="bg-gray-200 border-none p-inputgroup-addon">€</span>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.offers.deliveryFee')}</label>
                  <div className="p-inputgroup max-h-[38px]">
                     <InputText
                        value={invoiceInputs.deliveryFee}
                        onChange={(e) => handleInputChange(e, 'deliveryFee')}
                        onFocus={() => handleFocus('deliveryFee')}
                        className="w-full border-none bg-[#f9f9f9]"
                     />
                     <span className="bg-gray-200 border-none p-inputgroup-addon">€</span>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.discount')}</label>
                  <div className="p-inputgroup max-h-[38px]">
                     <span className="bg-gray-200 border-none p-inputgroup-addon">%</span>
                     <InputText
                        value={invoiceInputs.discount}
                        onChange={(e) => handleInputChange(e, 'discount')}
                        onFocus={() => handleFocus('discount')}
                        className="w-full border-none bg-[#f9f9f9]"
                     />
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.discountIncrease')}</label>
                  <Dropdown
                     value={selectedDiscount}
                     options={discountOptions}
                     onChange={(e) => setSelectedDiscount(e.value)}
                     optionLabel="label"
                     optionValue="value"
                     placeholder={t('global.select')}
                     className="w-full"
                     showClear
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.discountValue')}</label>
                  <InputText
                     value={selectedDiscountValue}
                     onChange={(e) => setSelectedDiscountValue(e.target.value)}
                     onFocus={() => handleFocus('discountValue')}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.fuelWithLiter')}</label>
                  <InputText
                     value={invoiceInputs.fuel}
                     onChange={(e) => handleInputChange(e, 'fuel')}
                     onFocus={() => handleFocus('fuel')}
                     className="w-full input-detail"
                  />
                  {errors.fuel && <small className="p-error">{errors.fuel}</small>}
               </div>

               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSundays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={invoiceInputs.isIncludingSundays} onChange={(e) => handleInputChange(e, 'isIncludingSundays')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSaturdays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           checked={invoiceInputs.isIncludingSaturdays}
                           onChange={(e) => handleInputChange(e, 'isIncludingSaturdays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingPublicHolidays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           checked={invoiceInputs.isIncludingPublicHolidays}
                           onChange={(e) => handleInputChange(e, 'isIncludingPublicHolidays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isEco')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={invoiceInputs.isEco} onChange={(e) => handleInputChange(e, 'isEco')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isInsurance')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={invoiceInputs.isInsurance} onChange={(e) => handleInputChange(e, 'isInsurance')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="mt-3 col-12">
                  <Button label={t('global.update')} className="w-full" onClick={() => saveItems()} severity="success" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default UpdateInvoiceDetail;
