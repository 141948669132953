// eslint-disable-next-line import/no-anonymous-default-export
export default {
   translation: {
      global: {
         sidebar: {
            dashboard: 'Dashboard',
            customer: 'Customer',
            workshop: 'Workshop',
            allMachines: 'All Machines',
            tCard: 'T-Card',
            orders: 'Orders',
            offers: 'Offers',
            commerce: 'Commerce',
            contracts: 'Contracts',
            planning: 'Planning',
            departuresAndReturns: 'Departures & Returns',
            malfunctionRecords: 'Malfunction Records',
            logistics: 'Logistics',
            transports: 'Transports',
            tours: 'Tours',
            management: 'Management',
            invoices: 'Invoices',
            uninvoicedContract: 'Uninvoiced Contract',
            parameters: 'Parameters',
            reminderDate: 'Reminder Date',
            settings: 'Settings',
            logout: 'Logout',
            users: 'Users',
            order: 'Order',
            machineGroupPrices: 'Machine Group Prices',
            dropFileOrClickToUpload: 'Drop file here or click to upload',
         },
         table: {
            noData: 'No data to show...',
            fullName: 'Full Name',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'E-Mail',
            identityNumber: 'Identity Number',
            postCode: 'Post Code',
            dateOfBirth: 'Date Of Birth',
            reminderDate: 'Reminder Date',
            explanation: 'Explanation',
            description: 'Description',
            gender: 'Gender',
            role: 'Role',
            city: 'City',
            number: 'Number',
            referenceNumber: 'Reference Number',
            factor: 'Factor',
            refundOrder: 'Refund Order',
            refundPayed: 'Refund Payed',
            firstMachineCode: 'First Machine Code',
            newMachineCode: 'New Machine Code',
            deliveryAddress: 'Delivery Address',
            rentType: 'Rent Type',
            supplierReceivedInvoiced: 'Supplier Received Invoiced',
            discussion: 'Discussion',
            contract: 'Contract',
            requestor: 'Requestor',
            arrivalAddress: 'Arrival Address',
            departureAddress: 'Departure Address',
            machineCode: 'Machine Code',
            phoneNumber: 'Phone Number',
            birthdate: 'Birthdate',
            invoice: 'Invoice',
            deleted: 'Deleted',
            status: 'Status',
            createdDate: 'Created Date',
            placeName: 'Place Name',
            machines: 'Machines',
            groupCode: 'Group Code',
            groupCodes: 'Group Codes',
            contractNumber: 'Contract Number',
            type: 'Type',
            user: 'User',
            date: 'Date',
            notes: 'Notes',
            companyName: 'Company Name',
            followedUser: 'Following User',
            comment: 'Comment',
            honoraryTitle: 'Honorary Title',
            machineGroup: 'Machine Group',
            price1d: 'Price 1D',
            price2dTo5d: 'Price 2D to 5D',
            price6dTo15d: 'Price 6D to 15D',
            price15d: 'Price > 15D',
            offerNumber: 'Offer Number',
            monthlyPrice: 'Monthly Price',
            discussionDate: 'Discussion Date',
            year: 'Year',
            serialNumber: 'Serial Number',
            fuelKind: 'Fuel Kind',
            supplier: 'Supplier',
            brand: 'Brand',
            model: 'Model',
            active: 'Active',
            faxNumber: 'Fax Number',
            action: 'Action',
            fileName: 'File Name',
            size: 'Size',
            extension: 'Extension',
            creationDate: 'Creation Date',
            customerName: 'Customer Name',
            addNewReminder: 'ADD NEW REMINDER',
            addNewDiscussion: 'ADD NEW DISCUSSION',
            addNewContact: 'ADD NEW CONTACT',
            addNewMachineGroupPrice: 'ADD NEW MACHINE GROUP PRICE',
            informations: 'Informations',
            contacts: 'Contacts',
            reminders: 'Reminders',
            discussions: 'Discussions',
            machineGroupPrices: 'Machine Group Prices',
            offers: 'Offers',
            contracts: 'Contracts',
            saleOffer: 'Sale Offer',
            saleContract: 'Sale Contract',
            invoices: 'Invoices',
            refunds: 'Refunds',
            exchanges: 'Exchanges',
            transfers: 'Transfers',
            purchaseOrder: 'Purchase Order',
            totalBeforeTax: 'Total Before Tax',
            totalAfterTax: 'Total After Tax',
            dueDate: 'Due Date',
            paymentDate: 'Payment Date',
            invoiceNumber: 'Invoice Number',
            customerCode: 'Customer Code',
            createRefund: 'Create Refund',
            dailyPrice: 'Price 1D',
            twoToFivePrice: 'Price 2D to 5D',
            sixToFifteenPrice: 'Price 6D to 15D',
            gtFifteenPrice: 'Price > 15D',
         },
         genders: {
            1: 'Male',
            2: 'Female',
         },
         roles: {
            1: 'Admin',
            2: 'Transporter',
            3: 'Technician',
         },
         search: 'Search',
         filter: 'Filter',
         filters: 'Filters',
         warning: 'Warning',
         france: 'FRANCE',
         save: 'Save',
         previous: 'Previous',
         saveAndContinue: 'Save & Continue',
         delete: 'Delete',
         undelete: 'Undelete',
         fillOutRequiredFields: 'Please fill out all required fields to continue!',
         unexpectedError: 'Unexpected error!',
         tokenExpired: 'Token Expired!',
         unauthorized: "You're unauthorized to do this action.",
         networkError: 'Network error! Check your network connection.',
         duplicateEntry: 'Duplicate entry!',
         apply: 'Apply',
         create: 'Create',
         update: 'UPDATE',
         updateSuccess: 'Updated successfully!',
         add: 'Add',
         requiredField: 'This field is required',
         select: 'Select',
         next: 'Next',
         prev: 'Prev',
         saveAndFinish: 'Save & Finish',
         continue: 'Continue',
         REJECTED: 'Rejected',
         APPROVED: 'Approved',
         PENDING: 'Pending',
         finish: 'Finish',
         saveOrFinish: 'SAVE/FINISH',
         yes: 'Yes',
         no: 'No',
         reminders: 'Reminders',
         unreadNotification: 'You have {{count}} reminders for today',
         pending: 'Pending',
      },
      authorization: {
         login: {
            doesNotExist: 'Could not find a user with given information!',
         },
      },
      pages: {
         customers: {
            addNewCustomer: 'Add New Customer',
            bannedReason: 'Block Reason',
            generalInformation: 'General Information',
            addressInformation: 'Address Information',
            customerAddress: 'Customer Address',
            creditInformation: 'Credit Information',
            type: 'Type',
            typePlaceholder: 'Select Type',
            companyName: 'Company Name',
            companyNamePlaceholder: 'Enter company name',
            addNewContact: 'Add New Contact',
            contactDetails: 'Contact Details',
            addNewReminder: 'Add New Reminder',
            addNewDiscussion: 'Add New Discussion',
            discussionDetails: 'Discussion Details',
            activityName: 'Activity Name',
            eMail: 'E-Mail',
            phoneNumber: 'Phone Number',
            faxNumber: 'Fax Number',
            identityNumber: 'Identity Number',
            webSite: 'Web Site',
            taxNumber: 'Tax Number',
            taxOffice: 'Tax Office',
            notes: 'Notes',
            followedUser: 'Followed User',
            mainOfficeAddress: 'Main Office Address',
            country: 'Country',
            city: 'City',
            district: 'District',
            postCode: 'Post Code',
            address: 'Address',
            addressContinuation: 'Address Continuation',
            billingAddress: 'Billing Address',
            officeAndBillingAddressSame: 'Office Address & Billing Address is the same',
            averagePaymentsInDays: 'Average Payments In Days',
            overduePayables: 'Overdue Payables',
            outstandingPayables: 'Outstanding Payables',
            paymentMethod: 'Payment Method',
            maximumCreditLimit: 'Maximum Credit Limit',
            factorLimit: 'Factor Limit',
            factor: 'Factor',
            check: 'Check',
            receptionDate: 'Reception Date',
            hasItsOwnInsurance: 'Has its own insurance',
            buyersBankAccountNumber: `Buyer's bank account number'`,
            rib: 'RIB',
            iban: 'IBAN',
            bic: 'BIC',
            dropzoneSheet: 'Dropzone Sheet',
            listOfAllFilesLinkedToThisCustomer: 'List of all files linked to this customer',
            noFilesFound: 'No files found...',
            accountStatement: 'ACCOUNT STATEMENT',
            delete: 'DELETE',
            unDelete: 'UNDELETE',
            block: 'BLOCK',
            unBlock: 'UNBLOCK',
            open: 'OPEN',
            malfunctionRecord: 'MALFUNCTION RECORD',
            customerTypes: {
               PROSPECT: 'Prospect',
               CUSTOMER: 'Customer',
               SUPPLIER: 'Supplier',
               TRANSPORTER: 'Transporter',
            },
            discussionTypes: {
               ACCOUNTANT: 'Accountant',
               SALES_AGENT: 'Sales Agent',
            },
            discussionType: 'Discussion Type',
            reminderDetails: 'Reminder Details',
            blockConfirmation: 'Are you sure you want to block this customer?',
            blockConfirmationTitle: 'Confirm that you want to block',
            unblockConfirmation: 'Are you sure you want to unblock this customer?',
            blockSuccess: 'Customer blocked successfully!',
            unblockSuccess: 'Customer unblock operation completed successfully.',
            deleteConfirmation: 'Are you sure you want to delete this customer?',
            deleteConfirmationTitle: 'Confirm that you want to delete',
            undeleteConfirmation: 'Are you sure you want to undelete this customer?',
            deleteSuccess: 'Customer deleted successfully!',
            undeleteSuccess: 'Customer undeletion operation completed successfully.',
            updateSuccess: 'Customer Informations Updated Successfully!',
            customerCreatedSuccessfully: 'Customer created succesfully',
            contactCreatedSuccesfully: 'Contact created succesfully',
            purchaseOrder: 'PURCHASE ORDER',
            customerDetails: 'Customer Details',
            transporterOrderCreatedSuccessfully: 'Transporter order created successfully',
            supplierTransporterOrderCreatedSuccessfully: 'Supplier transporter order created successfully',
            purchaseOrderNumber: 'PO Number',
            creationDate: 'Creation Date',
            user: 'User',
            deliveryAddress: 'Delivery Address',
            amount: 'Amount',
            referenceOffer: 'Reference Offer',
            status: 'Status',
            thisCustomerAlreadyExist: 'This customer already exists',
            createRefundTitle: 'Create refund invoice',
            amountAfterTax: 'Amount After Tax',
            amountBeforeTax: 'Amount Before Tax',
            refundAll: 'Refund All',
            createRefundSuccess: 'Refund created successfully!',
            cash: 'CASH',
            days_30: '30 DAYS EOM',
            days_45: '45 DAYS EOM',
            deleteGroupPricesSuccess: 'Group prices deleted successfully!',
            updateGroupPricesSuccess: 'Group prices updated successfully!',
         },
         machines: {
            addMachines: 'Add Machines',
            addNewMachine: 'Add New Machine',
            addNewMachineGroup: 'Add New Machine Group',
            addNewSupplier: 'Add New Supplier',
            machineDetails: 'Machine Details',
            addNewMachineDocument: 'Add New Machine Document',
            createNewMachineDocument: 'Create New Machine Document',
            createNewSupplier: 'Create New Supplier',
            createNewMachineGroup: 'Create New Machine Group',
            machineTypes: {
               MACHINE: 'Machine',
               MACHINE_TOOL: 'Tool',
            },
            fuelTypes: {
               GASOLINE: 'Gasoline',
               DIESEL: 'Diesel',
               ELECTRIQUE: 'Electric',
               HYBRIDE: 'Hybrid',
               'BI-ENERGIE': 'Bio-Energy',
               MANUEL: 'Manuel',
            },
            machineCreateSuccess: 'Machine created successfully!',
            machineUpdateSuccess: 'Machine updated successfully!',
            machineDeleteSuccess: 'Machine deleted succesfully',
            machineDeleteConfirmation: 'Are you sure you want to delete this machine?',
            createNewVehicleGroupSuccess: 'New machine group created successfully!',
            returnPlanned: 'Return Planned',
            malfunction: 'Malfunction',
            rent: 'Rent',
            reserved: 'Reserved',
            available: 'Available',
            inspection: 'Inspection',
            leavingAddNewMachineGroup: 'You are leaving the Add New Machine&Tool Group page...',
            limit: 'Limit',
            search: 'Search',
            machineGroup: 'Machine Group',
            fuelType: 'Fuel Type',
            deleted: 'Deleted',
            nextVgp: 'Next VGP',
            ifSupplier: 'If Supplier',
            supplier: 'Supplier',
            machineType: 'Machine Type',
            machineOrToolGroup: 'Machine/Tool Group',
            machineCode: 'Machine Code',
            description: 'Description',
            serialNumber: 'Serial Number',
            brand: 'Brand',
            model: 'Model',
            year: 'Year',
            lengthSize: 'Length Size',
            widthSize: 'Width Size',
            heightSize: 'Height Size',
            weight: 'Weight',
            deport: 'Deport',
            maxWarehouse: 'Max Warehouse',
            guaranteeInformations: 'Guarantee Informations',
            deliveryDate: 'Delivery Date',
            nextRevision: 'Next Revision',
            guaranteePeriod: 'Guarantee Period (Month)',
            remainingPeriod: 'Remaining Period (Month)',
            name: 'Name',
            machineDuplicate: 'The machine code is already registered in the system. Please enter a different code.',
            totalMachines: 'Total Machine',
            totalActiveMachines: 'Total Active Machine',
            totalDeletedMachines: 'Total Deleted Machine',
            totalMalfunctionedMachine: 'Total Malfunctioned Machine',
            totalRentedMachine: 'Total Rented Machine',
            totalReadyToRentMachine: 'Total Ready To Rent Machine',
         },
         settings: {
            users: {
               selectGender: 'Select Gender',
               addNewUser: 'Add New User',
               selectBirthdate: 'Select Birthdate',
               password: 'Password',
               selectRole: 'Select Role',
               createUser: 'Create User',
               duplicateError: "There's already an user registered to system with given information!",
               userDetails: 'User Details',
               deleteUser: 'Delete User',
               deleteConfirmation: 'Are you sure you want to delete this user?',
               userDeleteSuccess: 'User deleted successfully!',
            },
         },
         offers: {
            addNewOffer: 'Add New Offer',
            createSale: 'Create Sale',
            offerDetails: 'Offer Details',
            quantity: 'Quantity',
            offerKinds: {
               1: 'Rent',
               2: 'Sale',
               3: 'Maintenance',
               4: 'Repair',
            },
            offerCreateSuccess: 'Offer created successfully!',
            offerUpdateSuccess: 'Offer updated successfully!',
            type: 'Type',
            customer: 'Customer',
            offered: 'Offered',
            refused: 'Refused',
            searchedFields: 'Searched Fields',
            deleteOfferMessage: 'Are you sure you want to delete this offer?',
            deletedOfferMessage: 'Offer deleted successfully!',
            cancel: 'Cancel',
            delete: 'Delete',
            deleteOffer: 'Delete Offer',
            createANewSale: 'Create a New Sale',
            poNumber: 'PO Number',
            privacyExplanation: 'Privacy Explanation',
            factor: 'Factor',
            informations: 'Informations',
            items: 'Items',
            documents: 'Documents',
            tax: 'Tax',
            price: 'Price',
            taxFee: 'Tax Fee',
            totalPrice: 'Total Price',
            category: 'Category',
            currency: 'Currency',
            total: 'Total',
            updateSale: 'Update Sale',
            malfunctionCaseNumber: 'Malfunction Case Number',
            createSaleMessage: 'The Sale has been successfully created.',
            addNewOfferDocument: 'Add New Offer Document',
            createANewAddress: 'Create a New Address',
            receiverName: 'Contact Name',
            districtName: 'District Name',
            address: 'Address',
            addressContinuation: 'Address Continuation',
            country: 'Country',
            totalOrder: 'Total Offer',
            invoicedOrders: 'Invoiced Offers',
            pendingOrders: 'Pending Offers',
            refusedOrders: 'Refused Offers',
            reference: 'Reference',
            startDate: 'Start Date',
            endDate: 'End Date',
            ecoFee: 'Eco Fee',
            insurance: 'Insurance',
            transporterDeliveryFee: 'Delivery Fee',
            transporterPickupFee: 'Pickup Fee',
            totalBeforeTax: 'Total Before Tax',
            totalAfterTax: 'Total After Tax',
            addMachine: 'Add Machine',
            inventoryMachine: 'Inventory Machine',
            supplierMachine: 'Supplier Machine',
            saleItems: 'Sale Items',
            updateMachine: 'Update Machine',
            pickupFee: 'Pickupp Fee',
            deliveryFee: 'Delivery Fee',
            isIncludingSaturdays: 'Including Saturdays',
            isIncludingSundays: 'Including Sundays',
            isIncludingPublicHolidays: 'Including Public Holidays',
            isEco: 'Eco',
            isInsurance: 'Insurance',
            pleaseAddAtLeastOneItem: 'Please add at least one item to continue!',
            isPickuppByMetaloc: 'Transport By Metaloc',
            isReserved: 'Reserved',
            addMachineGroup: 'Add Machine Group',
            machineFuelKind: 'Machine Fuel Kinds',
            createMachine: 'Create Machine',
            addNewOfferErrorMessage: 'Please add at least machine to continue!',
            price1d: 'Price 1d',
            price2to5d: 'Price 2 to 5d',
            price6to15d: 'Price 6 to 15d',
            priceGt15d: 'Price > 15d',
            monthlyPrice: 'Monthly Price',
            offerDetails: 'Offer Details',
            offerItemUpdated: 'Offer item updated successfully!',
            addInventoryMachine: 'Add Inventory Machine',
            updateInventoryMachine: 'Update Inventory Machine',
            addSupplierMachine: 'Add Supplier Machine',
            updateSupplierMachine: 'Update Supplier Machine',
            itemRemoved: 'Item removed successfully!',
            contractedRoleError: 'A contract cannot be created for a customer in the prospect role.',
            contractedSupplierWarning: 'A Purchase Order form has been created!Please go to the file of the Supplier to complete it.',
            updateAddress: 'Update Address',
            duplicateOfferSuccessMessage: 'The Offer has been successfully duplicated.',
            duplicateOffer: 'Duplicate Offer',
            duplicateOfferMessage: 'Are you sure you want to duplicate this offer?',
            acceptedOrders: 'Accepted Offers',
            conversionRate: 'Conversion Rate',
            discountRate: 'Discount Rate',
            createOfferMessage: 'The Offer has been successfully created.',
            monthly: 'Monthly',
            daily: 'Daily',
         },
         malfunctions: {
            caseNumber: 'Malfunction Case',
            faultDate: 'Fault Date',
            technican: 'Technican',
            machineCode: 'Code Machine',
            customer: 'Customer',
            customerPostCode: 'Customer Post Code',
            customerCity: 'Customer City',
            view: 'View',
            detail: 'Detail',
            totalMachineMalfunctionRecord: 'Total Machine Malfunction Record',
            totalResolvedMachineMalfunctionRecord: 'Total Resolved Machine Malfunction Record',
            totalPendingMachineMalfunctionRecord: 'Total Pending Machine Malfunction Record',
            addNewMalfunctionRecord: 'ADD NEW RECORD',
            informations: 'Informations',
            malfunctions: 'Malfunctions',
            malfunctionDocuments: 'Malfunction Documents',
            finish: 'Finish',
            type: 'Type',
            faultDate: 'Fault Date',
            contactName: 'Contact Name',
            contactEmail: 'Contact Email',
            contactPhone: 'Contact Phone',
            explanation: 'Explanation',
            notes: 'Notes',
            isMalfunctionOperatorDefault: 'Operator Default',
            isMalfunctionBatteryFailure: 'Operator Battery Failure',
            isMalfunctionEngineFailure: 'Engine Failure',
            isMalfunctionBodyDamage: 'Body Damage',
            isMalfunctionImproperHandling: 'Improper Handling',
            isMalfunctionChargerFailure: 'Charger Failure',
            isMalfunctionElectricalFailure: 'Electrical Failure',
            isMalfunctionSafetyAccessoriesFailure: 'Safety Accessories Failure',
            isMalfunctionRemoteControlFailure: 'Remote Control Failure',
            isMalfunctionBatteryCharger: 'Battery Charger',
            isMalfunctionTornCable: 'Torn Cable',
            isMalfunctionHydraulicFailure: 'Hydraulic Failure',
            isMalfunctionOther: 'Other',
            isMalfunctionOperatorDefaultTechnician: 'Operator Default',
            isMalfunctionBatteryFailureTechnician: 'Operator Battery Failure',
            isMalfunctionEngineFailureTechnician: 'Engine Failure',
            isMalfunctionBodyDamageTechnician: 'Body Damage',
            isMalfunctionImproperHandlingTechnician: 'Improper Handling',
            isMalfunctionChargerFailureTechnician: 'Charger Failure',
            isMalfunctionElectricalFailureTechnician: 'Electrical Failure',
            isMalfunctionSafetyAccessoriesFailureTechnician: 'Safety Accessories Failure',
            isMalfunctionRemoteControlFailureTechnician: 'Remote Control Failure',
            isMalfunctionBatteryChargerTechnician: 'Battery Charger',
            isMalfunctionTornCableTechnician: 'Torn Cable',
            isMalfunctionHydraulicFailureTechnician: 'Hydraulic Failure',
            isMalfunctionOtherTechnician: 'Other',
            saveAndContinue: 'Save & Continue',
            machine: 'Machine',
            MalfunctionRecordDetail: 'Malfunction Record Detail',
            generalInformations: 'General Informations',
            solutions: 'Solutions',
            malfunctionDocuments: 'Malfunction Documents',
            primaryContactName: 'Primary Contact Name',
            emailAddress: 'E-Mail Address',
            previous: 'Previous',
            malfunctionOther: 'Other',
            downloadReport: 'Download Malfunction Report',
            technician: 'Technician User',
            malfunctionUpdated: 'Malfunction updated successfully!',
            arrivalTime: 'Arrival Time',
            isSolutionWarranty: 'Warranty',
            isSolutionVehicleReplacement: 'Machine Replacement',
            isSolutionMaintenance: 'Maintenance',
            isSolutionDepotRepair: 'Depot Repair',
            isSolutionDefectDepotPreparation: 'Defect Depot Preparation',
            isSolutionOnSiteRepair: 'On-Site Repair',
            isSolutionAfterSalesServicePickup: 'After-Sales Service Pickup',
            isSolutionOther: 'Other',
            isJobDone: 'Job Done',
            isPartsAndSmallSupplies: 'Parts & Small Supplies',
            nextStep: 'Next Step',
            isInvoiced: 'Invoice',
            isMakeAnOffer: 'Make An Offer',
            isMaintenanceContract: 'Maintenance Contract',
            departureTime: 'Departure Time',
            note: 'Note',
            date: 'Date',
            placeName: 'Place Name',
            customerSignature: 'Customer Signature',
            clearSignature: 'Clear Signature',
            createDocumentMessage: 'Document created successfully!',
            addNewMalfunctionDocument: 'Add New Malfunction Record Document',
            isCompleted: 'Completed',
            resolved: 'Resolved',
            areYouSureResolve: 'Are you sure you want to resolve this malfunction?',
         },
         planning: {
            id: 'ID',
            vehicleReservations: 'Machine Reservations',
            machineCode: 'Code Machine',
            supplier: 'Supplier',
            supplierName: 'Supplier Name',
            address: 'Address',
            beginDate: 'Start Date',
            endDate: 'End Date',
            customer: 'Customer',
            inventory: 'Inventory',
            machineGroup: 'Machine Group',
            contractNo: 'Contract No',
            placeName: 'Place Name',
            returnPlanned: 'Return Planned',
            available: 'Available',
            rent: 'Rent',
            reserved: 'Reserved',
            malfunction: 'Malfunction',
         },
         contracts: {
            deleteContractMessage: 'Are you sure you want to delete this contract?',
            deletedContractMessage: 'Contract deleted successfully!',
            cancel: 'Cancel',
            delete: 'Delete',
            deleteContract: 'Delete Contract',
            totalOrder: 'Total Order',
            invoicedOrders: 'Invoiced Orders',
            pendingOrders: 'Pending Orders',
            refusedOrders: 'Refused Orders',
            informations: 'Informations',
            items: 'Items',
            documents: 'Documents',
            searchedFields: 'Searched Fields',
            searchedFieldsList: 'Number,PO Number, Explation,Privacy Explanation',
            type: 'Type',
            customer: 'Customer',
            quantity: 'Quantity',
            number: 'Number',
            poNumber: 'PO Number',
            privacyExplanation: 'Privacy Explanation',
            explanation: 'Explanation',
            factor: 'Factor',
            offered: 'Offered',
            contracted: 'Contracted',
            contract: 'Contract',
            seeProforma: 'See Proforma',
            deliveryNote: 'Delivery Note',
            pickuppNote: 'Pickup Note',
            contractDetail: 'Contract Detail',
            information: 'Information',
            transporter: 'Transporter',
            poTransporter: 'PO Transporter',
            departure: 'Departure',
            return: 'Return',
            createNewTransporterOrder: 'Create New Transporter Order',
            createNewAddress: 'Create New Address',
            referenceOffer: 'Reference Offer',
            contactPerson: 'Contact Person',
            category: 'Category',
            description: 'Description',
            beginDate: 'Begin Date',
            endDate: 'End Date',
            price: 'Price',
            READY_TO_DISPATCH: 'Ready to Dispatch',
            DISPATCHED: 'Dispatched',
            RESERVE: 'Reserve',
            READY_TO_DEPART: 'Ready to Depart',
            RENT: 'Rent',
            RETURN_PLANNED: 'Return Planned',
            RENT_FINISHED: 'Rent Finished',
            status: 'Status',
            po: 'PO',
            price: 'Price',
            totalPrice: 'Total Price',
            taxFee: 'Tax Fee',
            addNewContractDocument: 'Add New Contract Document',
            reference: 'Reference',
            startDate: 'Start Date',
            ecoFee: 'Eco Fee',
            insurance: 'Insurance',
            transporterDeliveryFee: 'Delivery Fee',
            transporterPickupFee: 'Pickup Fee',
            totalBeforeTax: 'Total Before Tax',
            totalAfterTax: 'Total After Tax',
            total: 'Total',
            updateSaleItem: 'Update Sale Item',
            price1d: 'Price 1d',
            price2to5d: 'Price 2 to 5d',
            price6to15d: 'Price 6 to 15d',
            priceGt15d: 'Price > 15d',
            monthlyPrice: 'Monthly Price',
            contractDetails: 'Contract Details',
            contractItemUpdated: 'Contract item updated successfully!',
            discountRate: 'Discount Rate',
            isIncludingSundays: 'Including Sundays',
            isIncludingSaturdays: 'Including Saturdays',
            isIncludingPublicHolidays: 'Including Public Holidays',
            isEco: 'Eco',
            isInsurance: 'Insurance',
            isPickuppByMetaloc: 'Transport By Metaloc',
            isReserved: 'Reserved',
            deliveryFee: 'Delivery Fee',
            pickupFee: 'Pickup Fee',
            acceptedOrders: 'Accepted Orders',
            exchangeMachine: 'Exchange Machine',
            exchange: 'Exchange',
            transferOfMachine: 'Transfer of Machine',
            transfer: 'Transfer',
            extendContract: 'Extend Contract',
            extend: 'Extend',
            originalMachine: 'Original Machine',
            newMachine: 'New Machine',
            currentEndDate: 'Current End Date',
            supplier: 'Supplier',
            createNewSupplierPurchaseOrder: 'Create New Supplier Purchase Order',
            supplierOrderNumber: 'Supplier Order Number',
            supplierPurchaseOrder: 'Supplier Purchase Order',
            conversionRate: 'Conversion Rate',
            deleteOrderItem: 'Delete Contract Item',
            deleteOrderItemMessage: 'Are you sure you want to delete this contract item?',
            itemRemoved: 'Item removed successfully!',
            duplicateContract: 'Duplicate Contract',
            duplicateContractMessage: 'Are you sure you want to duplicate this contract?',
            duplicateContractSuccessMessage: 'The Contract has been successfully duplicated.',
            updateInventoryMachine: 'Update Inventory Machine',
            updateSupplierMachine: 'Update Supplier Machine',
            updateAddress: 'Update Address',
            RETURN_PLANED: 'Return Planned',
            INSPECTION: 'Inspection',
            returnDate: 'Return Date',
            SavedTransporterOrder: 'Saved Successfully',
            transporterCostHT: 'Transporter Cost HT',
            transporterMarginHT: 'Transporter Margin HT',
            supplierCostHT: 'Supplier Cost HT',
            rentMarginHT: 'Rent Margin HT',
            transporterPO: 'Transporter PO ({{transporterOrderNumber}})',
         },
         departuresAndReturns: {
            departures: 'Departures',
            returns: 'Returns',
            beginDate: 'Start Date',
            readyToDispatchLabel: 'dispatching machine for date',
            reserveMachineLabel: 'reserve machine for date',
            readyToDepartLabel: 'ready to depart machine for date',
            contractNo: 'Contract No',
            dispatch: 'Dispatch',
            selectMachineGroup: 'Select Machine Group',
            updateSuccess: 'Updated successfully!',
            confirmMachine: 'Confirm Machine',
            vehicleReservationControlForm: 'Vehicle Reservation Control Form',
            time: 'Time',
            key: 'Key',
            vgp: 'VGP',
            good: 'Good',
            medium: 'Medium',
            bad: 'Bad',
            chassisStatus: 'Chassis',
            basketStatus: 'Basket',
            batteryStatus: 'Battery',
            cylindersStatus: 'Cylinders',
            doorsStatus: 'Doors',
            tiresStatus: 'Tiers',
            lowerBodyStatus: 'Lower Body',
            upperBodyStatus: 'Upper Body',
            paintStatus: 'Paint',
            mileage: 'Mileage (Km or Hour)',
            controlFormUpdateSuccess: 'Control form updated successfully!',
            receivingMachinesToday: 'receiving machines today',
            machinePreparation: 'machine is preparation',
            inspection: 'Inspection',
            nextVGP: 'Next VGP',
            nextRevision: 'Next Revision',
            makeAvailable: 'Make Available',
            confirm: "Are you sure? This action can't be undone!",
         },
         logistics: {
            customer: 'Customer',
            contractNo: 'Contract No',
            machineCode: 'Machine Code',
            deliveryAddress: 'Delivery Address',
            city: 'City',
            beginDate: 'Start Date',
            confirmMachine: 'Confirm Machine',
            confirmDelivery: 'Confirm Delivery',
            transporterOrderNumber: 'PO Transporter No',
            departureTableHeader: 'machine to be delivered',
            departureTableHeader2: 'deliveries validated for',
            confirmMachine: 'Confirm Machine',
            confirmMachineSuccess: 'Machine confirmed successfully!',
            confirmMachineMessage: 'Are you sure you want to confirm this machine?',
            machineDelivered: 'Machine Delivered',
            postpone: 'Postpone',
            enterDates: 'Please enter the new dates',
            deliveredMachineMessage: 'Are you sure you want to delivery this machine?',
            offerCancellation: 'Offer Cancellation',
            offerCancellationMessage: 'The contract for this machine will be canceled. It will transition to the contract offer status.',
            offerCancellationSuccess: 'Offer canceled successfully!',
            updateReservationDatesSuccess: 'Rent dates changed successfully!',
            toPickup: 'To Pickup',
            invoice: 'Invoice',
            toPickupSuccess: 'Machine to be picked up successfully!',
            toPickupMessage: 'Are you sure you want to pick up this machine?',
            endDate: 'End Date',
            machineRecovery: 'Machine Recovery',
            reRent: 'Re-Rent',
            machineRecoveryMessage: 'Are you sure you want to recover this machine?',
            createInvoiceSuccess: 'Invoice created successfully!',
            returnTableHeader: 'machine to pickup',
            returnTableHeader2: 'machine waiting to pickup',
            returnExcelButton: 'Recovery list for takeovers',
            reRentMessage: 'Do you want to rent the machine again?',
            reRentSuccess: 'Machine rented successfully!',
            createInvoiceMessage: 'Do you want to create a pre invoice?',
         },
         invoices: {
            preInvoices: 'Pre Invoices',
            invoiced: 'Invoiced',
            refunds: 'Refunds',
            createPrevMonth: "Create previous  month's pre invoices",
            createThisMonth: "Create this month's pre invoices",
            createMonthEnd: 'Create month-end pre invoices',
            downloadPrevMonth: "Download previous month's reports",
            preInvoiceCard1: 'Total invoices awaiting payment',
            preInvoiceCard2: 'Total invoices awaiting payment and not due',
            preInvoiceCard3: 'Total overdue and unpaid invoices',
            unpaidListButton: 'Follow-up list for unpaid due invoices',
            dueDate: 'Due Date',
            supplierInvoice: 'Supplier Invoice',
            invoiceLock: 'Invoice Lock',
            edit: 'Edit',
            pdf: 'PDF',
            updateInvoice: 'Update Invoice',
            newPrice: 'New Price',
            discount: 'Discount',
            fuel: 'Fuel',
            sale: 'Sale',
            discountValue: 'Discount/Increase Value',
            discountIncrease: 'Discount/Increase',
            fuelWithLiter: 'Fuel(L)',
            updateInvoiceSuccess: 'Pre Invoice updated successfully!',
            createInvoiceItemsSuccess: 'Invoice item created successfully!',
            updateInvoiceStatusSuccess: 'Invoice status updated successfully!',
            createPrevMonthSuccess: 'Previous month pre invoices created successfully!',
            createThisMonthSuccess: 'Current month pre invoices created successfully!',
            updateSupplierInvoiceSuccess: 'Supplier invoice updated successfully!',
            deleteInvoiceSuccess: 'Invoice deleted successfully!',
            paymentMethod: 'Payment Method',
            invoiceNumber: 'Invoice Number',
            paid: 'Paid',
            unpaid: 'Unpaid',
            amountPaid: 'Amount Paid',
            paymentDate: 'Payment Date',
            bankWire: 'Bank Wire',
            check: 'Check',
            creditCard: 'Credit Card',
            cameca: 'Cameca',
            cpt: 'CPT',
            directDebit: 'Direct Debit',
            lcr: 'LCR',
            judicialLiquidation: 'Judicial Liquidation',
            payInvoicesHeader: 'Assaign a payment for the',
            invoice: 'Invoice',
            refund: 'Refund',
            unpaidStatusMessage: 'The invoice will go into the unpaid status.',
            unpaidInvoice: 'Unpaid Invoice',
            unpaidStatusSuccess: 'Invoice status updated to unpaid successfully!',
            comment: 'Comment',
            refundNumber: 'Refund Number',
            createMonthEndPreSuccess: 'Month-end pre invoices created successfully!',
            uninvoicedContractsHeader: 'Contracts not invoiced last month',
            createInvoiceError: 'Assign a machine to the contract before creating an invoice!',
            alreadyPaid: 'Already Paid',
            unpaid: 'Unpaid',
            unpaidInvoicesHeader: 'List of overdue invoices awaiting payment from',
            previousMonthsReports: 'Previous Months Reports',
            journal: 'Journal',
            accountingReference: 'Accounting Reference',
            amountInvoicedBeforeTax: 'Amount Invoiced Before Tax',
            amountReceivedBeforeTax: 'Amount Received Before Tax',
            libelle: 'Libelle',
            updateSupplierInvoiceMessage: 'Have you received the supplier invoice?',
            updateSupplierInvoiceMessage2: "Haven't you received the supplier invoice?",
            updateInvoiceLockMessage: 'Do you want to complete the pre invoice?',
            deleteInvoiceMessage: 'Are you sure you want to delete the pre invoice?',
            deleteInvoice: 'Delete Invoice',
            invoicedUpdateInvoiceLockMessage: 'Do you want to convert the invoice to pre invoice?',
            payInvoiceSuccess: 'Invoices paid successfully!',
            createPrevMonthEndPreMessage: "Do you want create previous month's pre invoices?",
            createMonthEndPreMessage: 'Do you want create month-end pre invoices?',
            createThisMonthPreMessage: "Do you want create this month's pre invoices?",
            invoiceCreatedSuccessfully: 'Invoice created successfully!',
            updateSaleItemSuccess: 'Sale item updated successfully!',
            saleItems: 'Sale Items',
            invoiceCreationDate: 'Invoice Creation Date',
            updateRefund: 'Update Refund',
            updateRefundSuccess: 'Refund updated successfully!',
            payRefundSuccess: 'Refunds paid successfully!',
            for: 'For',
            deleteInvoiceSaleItem: 'Delete Invoice Sale Item',
            deleteInvoiceSaleItemMessage: 'Are you sure you want to delete this invoice sale item?',
         },
         tours: {
            editionByDriver: 'Edition By Driver',
            printingTourSheets: 'Printing Tour Sheets',
            contact: 'Contact',
            driver: 'Driver',
         },
         dashboard: {
            finance: 'Finance',
            exploitation: 'Exploitation',
            availableMachine: 'Available Machine',
            totalSublease: 'Total Sublease',
            parcRent: 'Parc Rent',
            totalOffersOfTheMonth: 'Total offers of the month',
            mySalesOfTheMonth: 'My sales of the month',
            pendingSubleaseOffers: 'Pending Sublease Offers',
            pendingOffers: 'Pending Offers',
            occupancyRate: 'Occupancy Rate',
            includingReturnPlaned: 'Including return planned',
            dailyAveragePrice: 'Daily Average Price',
            rentalVsParc: 'Rental vs Parc',
            rentalVsParcReturnPlaned: 'return planned',
            dailyRentalRevenue: 'Daily Rental Revenue',
            montlyRentalRevenue: 'Montly Rental Revenue',
            onlyRentalInvoices: 'only rental invoices',
            monthlyRevenuePerCustomer: 'Monthly Revenue Per Customer',
            totalRevenueFromCustomer: 'Total Revenue From Customer',
            dailyRevenue: 'Daily Revenue',
            onlyRentRevenueByMachineGroupOfParkMachine: 'Only Rent Revenue By Machine Group Of Park Machine',
            revenuePerMachineCode: 'Revenue Per Machine Code',
            revenuePerMachineGroupForSupplier: 'Revenue Per Machine Group For Supplier',
            profitabilityAnalysis: 'Profitability Analysis',
            transport: 'Transport',
            fuelSales: 'Fuel Sales',
            otherSales: 'Other Sales',
            rentalTurnover: 'Rental Turnover',
            numberOfRentals: 'Number Of Rentals',
            dailyAveragePrice: 'Daily Average Price',
            transportTurnover: 'Transport Turnover',
            ecoTurnover: 'Eco Turnover',
            insuranceTurnover: 'Insurance Turnover',
            fuelTurnover: 'Fuel Turnover',
            otherSalesTurnover: 'Other Sales Turnover',
            suplierTransporter: 'Suplier and Transporter',
            yourMonthlyTurnover: 'Your Monthly Turnover',
            detailedMonthlyTurnover: 'Detailed Monthly Turnover',
            RENTAL_TURNOVER: 'Rental Turnover',
            TRANSPORT_TURNOVER: 'Transport Turnover',
            INSURANCE_TURNOVER: 'Insurance Turnover',
            ECO_TURNOVER: 'Eco Turnover',
            FUEL_TURNOVER: 'Fuel Turnover',
            OTHER_SALES_TURNOVER: 'Other Sales Turnover',
            INCLUDING_STAGGERED_FUEL_SALES: 'Including Staggered Fuel Sales',
            revenueBySalesRepresentatives: 'Revenue By Sales Representatives',
            supplierAndTransporter: 'Supplier and Transporter',
         },
         pdf: {
            malfunctionRecordNo: 'MALFUNCTION RECORD NO',
            hour: 'Hour',
            report: 'Report',
            bdNo: 'BD NO',
            contactInfo: 'CONTRACT INFORMATIONS',
            generalCond: 'GENERAL CONDITION',
            userManuel: 'User Manuel',
            mileage: 'Mileage',
            keys: 'Keys',
            diesel: 'Diesel',
            userName: 'User Name',
            customerId: 'Customer ID',
            company: 'Company',
            offerNo: 'Offer No',
            rentOffer: 'Rent Offer',
            rentOfferPdf1: 'Subject to availability. In the event of early termination, prices will be revised according to our price list.',
            rentOfferPdf2:
               "Working at height presents risks, respecting the safety instructions described in the operator's manual is strongly recommended. It is important to have the CACES, to be authorized and authorized to use this machine. For better safety, please note the sale of a fall arrest harness, helmets and safety shoes.",
            rentOfferPdf3:
               'Acceptance of this proposal by the customer implies full and complete acceptance of our general rental conditions appearing attached. Any contrary condition posed by the customer will, in the absence of express acceptance, be unenforceable, regardless of when it may have been brought to our attention.',
            deliveryFee: 'Delivery Fee',
            invoicePdf1:
               '“Payment payable to La Banque Postale Leasing & Factoring, to be sent directly to Processing of collections TSA 30236 - 93736 BOBIGNY CEDEX 9 – or by transfer to the La Banque Postale Leasing & Factoring account n° RIB BIC CRNPFRP1XXX IBAN FR76 1647 8000 01 00000100447 24. She receives it by subrogation under the factoring contract. She must be notified of any request for information or complaints.”',
            invoicePdf2:
               'Any late payment will result in penalties equal to 3 times the legal interest rate in force and a fixed compensation for recovery costs of 40 euros will be applied.',
            purchasePdf1: 'Payment terms: check 45 days end of month',
            purchasePdf2: 'Order reference must be recalled on your BL and invoices, otherwise no invoice will be processed.',
            recoveryListHeader: 'List of equipment at the end of rental today',
            invoicedBeforeTax: 'Debit',
            receivedBeforeTax: 'Credit',
            customerInvoicesHeader: 'Customer Account Statement',
            start: 'Start',
            end: 'End',
            offersBoxText: 'Company stamp: Good for agreement',
            unitPrice: 'Unit Price',
            proformaNo: 'Proforma No',
            proforma: 'Proforma',
            technician: 'Technician',
            ourBankDetails: 'Our Bank Details',
            offer: 'Offer',
            rentOfferPdf4: 'You must return the vehicle with a a fuel tank otherwise, the fuel shall be charged 3 Euros per liter.',
            workshopObservations: 'Workshop Observations',
            contractNote: 'Contract Note',
            transporterNote: 'Transporter Note',
            transporterId: 'Transporter ID',
            refundDesc: 'Refund on invoice number {{invoiceNumber}}',
         },
      },
   },
};
