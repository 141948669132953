import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { setReminderButtonClicked, setReminderDialog, setShowReminders } from '../../store/slices/remindersSlice';
import { useTranslation } from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';
import Finance from './Finance';
import Exploitation from './Exploitation';

const Dashboard = () => {
   const dispatch = useDispatch();
   const { unreadNotifications, reminderDialog } = useSelector((state) => state.reminder);
   const { t } = useTranslation();

   const footerContent = (
      <div>
         <Button
            label="Ok"
            icon="pi pi-check"
            onClick={() => {
               dispatch(setShowReminders(true));
               dispatch(setReminderDialog(false));
               dispatch(setReminderButtonClicked(true));
            }}
            autoFocus
         />
      </div>
   );

   const [selectedTable, setSelectedTable] = useState('finance');
   const tableOptions = [
      { label: t('pages.dashboard.finance'), value: 'finance' },
      { label: t('pages.dashboard.exploitation'), value: 'exploitation' },
   ];
   return (
      <>
         <div className="w-full">
            <div className="text-center mr-[2vw]">
               <SelectButton className="mb-3" value={selectedTable} options={tableOptions} optionLabel="label" onChange={(e) => setSelectedTable(e.value)} />
            </div>
            {selectedTable === 'finance' ? <Finance /> : <Exploitation />}
         </div>

         {/* <div className="col-12 md:col-6 lg:col-3">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Orders</span>
							<div className="text-900 font-medium text-xl">152</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
							<i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
						</div>
					</div>
					<span className="text-green-500 font-medium">24 new </span>
					<span className="text-500">since last visit</span>
				</div>
			</div>
			<div className="col-12 md:col-6 lg:col-3">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Revenue</span>
							<div className="text-900 font-medium text-xl">$2.100</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
							<i className="pi pi-map-marker text-orange-500 text-xl"></i>
						</div>
					</div>
					<span className="text-green-500 font-medium">%52+ </span>
					<span className="text-500">since last week</span>
				</div>
			</div>
			<div className="col-12 md:col-6 lg:col-3">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Customers</span>
							<div className="text-900 font-medium text-xl">28441</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
							<i className="pi pi-inbox text-cyan-500 text-xl"></i>
						</div>
					</div>
					<span className="text-green-500 font-medium">520 </span>
					<span className="text-500">newly registered</span>
				</div>
			</div>
			<div className="col-12 md:col-6 lg:col-3">
				<div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
					<div className="flex justify-content-between mb-3">
						<div>
							<span className="block text-500 font-medium mb-3">Comments</span>
							<div className="text-900 font-medium text-xl">152 Unread</div>
						</div>
						<div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
							<i className="pi pi-comment text-purple-500 text-xl"></i>
						</div>
					</div>
					<span className="text-green-500 font-medium">85 </span>
					<span className="text-500">responded</span>
				</div>
			</div> */}

         {unreadNotifications !== 0 ? (
            <Dialog
               header={t('global.reminders')}
               footer={footerContent}
               visible={reminderDialog}
               style={{ width: '50vw' }}
               onHide={() => dispatch(setReminderDialog(false))}
            >
               <p>{t('global.unreadNotification', { count: unreadNotifications })}</p>
               {/* <p dangerouslySetInnerHTML={{ __html: t('global.unreadNotification', { count: unreadNotifications }) }}/> */}
            </Dialog>
         ) : null}
      </>
   );
};

export default Dashboard;
