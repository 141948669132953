import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';
import { Dialog } from 'primereact/dialog';
import Informations from './Informations.jsx';
import Items from './Items.jsx';
import Documents from './Documents.jsx';
import { useSelector } from 'react-redux';

const ContractDetail = (props) => {
   const [activeIndex, setActiveIndex] = useState(0);
   const { contract } = useSelector((state) => state.contracts);
   const steps = [
      {
         label: t('pages.contracts.informations'),
      },
      {
         label: t('pages.contracts.items'),
      },
      {
         label: t('pages.contracts.documents'),
      },
   ];

   return (
      <div>
         <Dialog
            header={`${t('pages.contracts.contractDetail')} (${contract?.orderNumber})`}
            visible={props.contractVisible}
            className="w-[85vw]"
            onHide={() => {
               setActiveIndex(0);
               props.setContractVisible(false);
            }}
         >
            <div style={{ maxWidth: '100%', overflowY: 'auto' }}>
               <TabMenu
                  model={steps}
                  activeIndex={activeIndex}
                  onTabChange={(e) => {
                     setActiveIndex(e.index);
                  }}
                  style={{ overflowX: 'visible', overflowY: 'clip' }}
               />
            </div>
            <div className="p-3">
               {activeIndex === 0 && (
                  <Informations
                     contractVisible={props.contractVisible}
                     setContractVisible={props.setContractVisible}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                  />
               )}
               {activeIndex === 1 && (
                  <Items
                     contractVisible={props.contractVisible}
                     setContractVisible={props.setContractVisible}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                  />
               )}
               {activeIndex === 2 && (
                  <Documents
                     contractVisible={props.contractVisible}
                     setContractVisible={props.setContractVisible}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                  />
               )}
            </div>
         </Dialog>
      </div>
   );
};

export default ContractDetail;
