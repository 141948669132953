import TableComponent from '../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { t } from 'i18next';
import { getOffer, getOffers } from '../../../store/apis/Customers';
import OfferDetail from '../../Commerce/Offers/OfferDetail/OfferDetail';
import { setOffer } from '../../../store/slices/offerSlice';
import toast from 'react-hot-toast';
import { duplicateSelectedOffer, deleteOffer as removeOffer } from '../../../store/apis/OffersApi';

const Offers = () => {
	const { selectedCustomerId } = useSelector((state) => state.customer);

	const { offers } = useSelector((state) => state.offers);

	const dispatch = useDispatch();

	const [deleteOffer, setDeleteOffer] = useState({
		dialogVisible: false,
		selectedOfferId: null,
	});

	const [duplicateOffer, setDuplicateOffer] = useState({
		dialogVisible: false,
		selectedOfferId: null,
	});

	const [offerDetailVisible, setOfferDetailVisible] = useState(false);

	useEffect(() => {
		dispatch(getOffers(selectedCustomerId, '1'));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableColumns = [
		{
			name: 'orderNumber',
			header: t('global.table.offerNumber'),
			field: 'orderNumber',
		},
		{
			name: 'status',
			header: t('global.table.status'),
			field: 'status',
			body: (rowData) => {
				return <span>{rowData.isRefused ? 'Refused' : rowData.isOffered ? 'Offered' : '-'}</span>;
			},
		},
		{
			name: 'creationDate',
			header: t('global.table.creationDate'),
			field: 'creationDate',
			body: (rowData) => {
				return <span>{moment(rowData.createdAt).format('DD/MM/YYYY')}</span>;
			},
		},
		{
			name: 'placeName',
			header: t('global.table.placeName'),
			field: 'placeName',
			body: (rowData) => {
				return <span>{rowData.address.placeName}</span>;
			},
		},
		{
			name: 'machines',
			header: t('global.table.machines'),
			field: 'machines',
			body: (rowData) => {
				return (
					<span>
						{rowData.orderItems &&
							rowData.orderItems.map((item) =>
								item.vehicleReservation && item.vehicleReservation.vehicle
									? item.vehicleReservation.vehicle.machineCode + ', '
									: item.vehicleReservation && item.vehicleReservation.vehicleGroup.name + ', '
							)}
					</span>
				);
			},
		},
		{
			name: 'actions',
			body: (rowData) => {
				return (
					<div className="flex justify-center">
						<div className="flex items-center">
							<Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={() => showOfferDetail(rowData)} />
							<Button
								className="mx-1"
								icon="pi pi-trash"
								severity="danger"
								onClick={() =>
									setDeleteOffer({
										dialogVisible: true,
										selectedOfferId: rowData.id,
									})
								}
							/>
							<Button
								className="mx-1"
								icon="pi pi-copy"
								severity="info"
								onClick={() =>
									setDuplicateOffer({
										dialogVisible: true,
										selectedOfferId: rowData.id,
									})
								}
							/>
						</div>
					</div>
				);
			},
		},
	];

	const showOfferDetail = (rowData) => {
		dispatch(getOffer(+rowData.id)).then((res) => {
			setOfferDetailVisible(true);
			dispatch(setOffer(res.data.order));
		});
	};

	const handleDuplicateOffer = async () => {
		const res = await dispatch(duplicateSelectedOffer(+duplicateOffer.selectedOfferId));
		if (res) {
			showOfferDetail(res);
			toast.success(t('pages.offers.duplicateOfferSuccessMessage'));
			setDuplicateOffer({
				dialogVisible: false,
				selectedOfferId: null,
			});
		}
	};

	return (
		<div>
			<div className="flex flex-col w-full">
				<div className="col-7  mt-10">
					<TableComponent columns={tableColumns} data={offers} size="small" />
				</div>
				<Dialog
					header={t('pages.offers.deleteOffer')}
					visible={deleteOffer.dialogVisible}
					style={{ width: '400px' }}
					onHide={() =>
						setDeleteOffer({
							dialogVisible: false,
							selectedOfferId: null,
						})
					}
				>
					<div className="confirmation-content">{<span>{t('pages.offers.deleteOfferMessage')}</span>}</div>
					<div className="flex justify-end mt-3">
						<Button
							label={t('pages.offers.cancel')}
							severity="warning"
							className="mr-2 "
							onClick={() =>
								setDeleteOffer({
									dialogVisible: false,
									selectedOfferId: null,
								})
							}
						/>
						<Button
							label={t('pages.offers.delete')}
							severity="danger"
							onClick={() => {
								dispatch(removeOffer([+deleteOffer.selectedOfferId])).then(
									setDeleteOffer({
										dialogVisible: false,
										selectedOfferId: null,
									}),
									toast.success(t('pages.offers.deletedOfferMessage'))
								);
							}}
						/>
					</div>
				</Dialog>

				{/* Duplicate Offer Dialog */}
				<Dialog
					header={t('pages.offers.duplicateOffer')}
					visible={duplicateOffer.dialogVisible}
					style={{ width: '400px' }}
					onHide={() =>
						setDuplicateOffer({
							dialogVisible: false,
							selectedOfferId: null,
						})
					}
				>
					<div className="confirmation-content">{<span>{t('pages.offers.duplicateOfferMessage')}</span>}</div>
					<div className="flex justify-end mt-3">
						<Button
							label={t('pages.offers.cancel')}
							severity="danger"
							className="mr-2 "
							onClick={() =>
								setDuplicateOffer({
									dialogVisible: false,
									selectedOfferId: null,
								})
							}
						/>
						<Button label={t('global.save')} severity="success" onClick={handleDuplicateOffer} />
					</div>
				</Dialog>

				<OfferDetail setOfferDetailVisible={setOfferDetailVisible} offerDetailVisible={offerDetailVisible} />
			</div>
		</div>
	);
};

export default Offers;
