import TableComponent from '../../../components/TableComponent';

import { t } from 'i18next';

const Exchanges = (props) => {
	const tableColumns = [
		{
			name: 'date',
			header: t('global.table.date'),
			field: 'date',
		},
		{
			name: 'user',
			header: t('global.table.user'),
			field: 'user',
		},
		{
			name: 'firstMachineCode',
			header: t('global.table.firstMachineCode'),
			field: 'firstMachineCode',
		},
		{
			name: 'newMachineCode',
			header: t('global.table.newMachineCode'),
			field: 'newMachineCode',
		},
		{
			name: 'deliveryAddress',
			header: t('global.table.deliveryAddress'),
			field: 'deliveryAddress',
		},
		{
			name: 'rentType',
			header: t('global.table.rentType'),
			field: 'rentType',
		},
	];

	return (
		<div>
			<div>
				<div className="flex flex-wrap justify-content-end mt-2"></div>
				<div className="col-8 mt-10">
					<TableComponent columns={tableColumns} size="large" />
				</div>
			</div>
		</div>
	);
};

export default Exchanges;
