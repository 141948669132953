import React, { useState } from 'react';
import { t } from 'i18next';
import { Dialog } from 'primereact/dialog';
import Informations from './Informations.jsx';
import Items from './Items.jsx';
import { useSelector } from 'react-redux';
import Documents from './Documents.jsx';
import { TabMenu } from 'primereact/tabmenu';

const OfferDetail = (props) => {
   const { offer } = useSelector((state) => state.offers);
   const [activeIndex, setActiveIndex] = useState(0);
   const steps = [
      {
         label: t('pages.contracts.informations'),
      },
      {
         label: t('pages.contracts.items'),
      },
      {
         label: t('pages.contracts.documents'),
      },
   ];

   return (
      <div>
         <Dialog
            header={`${t('pages.offers.offerDetails')} (${offer?.orderNumber})`}
            visible={props.offerDetailVisible}
            className="w-[85vw]"
            onHide={() => {
               props.setOfferDetailVisible(false);
            }}
         >
            <div style={{ maxWidth: '100%', overflowY: 'auto' }}>
               <TabMenu
                  model={steps}
                  activeIndex={activeIndex}
                  onTabChange={(e) => {
                     setActiveIndex(e.index);
                  }}
                  style={{ overflowX: 'visible', overflowY: 'clip' }}
               />
            </div>
            <div className="p-3">
               {activeIndex === 0 ? (
                  <Informations setOfferDetailVisible={props.setOfferDetailVisible} />
               ) : activeIndex === 1 ? (
                  <Items />
               ) : activeIndex === 2 ? (
                  <Documents />
               ) : null}
            </div>
         </Dialog>
      </div>
   );
};

export default OfferDetail;
