import client from '../../graphql/apollo.config';
import {
   CREATE_VEHICLE_GROUP_PRICE,
   DELETE_VEHICLE_GROUP_PRICES,
   GET_ATELIER_PDF,
   GET_CONTRACT_PDF,
   GET_DASHBOARD_STATISTICS,
   GET_FINANCE_STATISTICS,
   GET_GLOBAL_SEARCH,
   GET_GLOBAL_SETTINGS,
   GET_INVOICE_PDF,
   GET_MALFUNCTION_PDF,
   GET_OFFER_PDF,
   GET_REFUND_PDF,
   GET_VEHICLE_GROUPS,
   GET_VEHICLE_GROUP_PRICES,
   UPDATE_VEHICLE_GROUP_PRICE,
} from '../../graphql/shared';
import {
   createVehicleGroupPrices,
   removeRequest,
   removeVehicleGroupPrice,
   setDashboardStatistics,
   setFinanceStatistics,
   setGlobalSettings,
   setRequest,
   setVehicleGroupPrices,
   updateVehicleGroupPrices,
} from '../slices/sharedSlice';

export const getGlobalSettings = () => async (dispatch) => {
   dispatch(setRequest('getGlobalSettings'));
   return client
      .query({
         query: GET_GLOBAL_SETTINGS,
      })
      .then((res) => {
         dispatch(setGlobalSettings(res.data.globalSetting));
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getGlobalSettings'));
      });
};

export const getDashboardStatistics = () => async (dispatch) => {
   dispatch(setRequest('GET_DASHBOARD_STATISTICS'));
   return client
      .query({
         query: GET_DASHBOARD_STATISTICS,
      })
      .then((res) => {
         dispatch(setDashboardStatistics(res.data.dashboardStatistics));
         return res.data.dashboardStatistics;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_DASHBOARD_STATISTICS'));
      });
};

export const getFinanceStatistics =
   ({ startDate, endDate }) =>
   async (dispatch) => {
      dispatch(setRequest('GET_FINANCE_STATISTICS'));
      return client
         .query({
            query: GET_FINANCE_STATISTICS,
            variables: { startDate, endDate },
         })
         .then((res) => {
            dispatch(setFinanceStatistics(res.data.financeStatistics));
            return res.data.financeStatistics;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('GET_FINANCE_STATISTICS'));
         });
   };

export const getFilteredFinanceStatistics =
   ({ startDate, endDate, dailyRevenueStartDate, dailyRevenueEndDate }) =>
   async (dispatch) => {
      dispatch(setRequest('GET_FILTERED_FINANCE_STATISTICS'));
      return client
         .query({
            query: GET_FINANCE_STATISTICS,
            variables: { startDate, endDate, dailyRevenueStartDate, dailyRevenueEndDate },
         })
         .then((res) => {
            dispatch(setFinanceStatistics(res.data.financeStatistics));
            return res.data.financeStatistics;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('GET_FILTERED_FINANCE_STATISTICS'));
         });
   };

export const getAtelierPDF = (id) => async (dispatch) => {
   dispatch(setRequest('GET_ATELIER_PDF'));
   return client
      .query({
         query: GET_ATELIER_PDF,
         variables: { id },
      })
      .then((res) => {
         return res.data.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_ATELIER_PDF'));
      });
};

export const getMalfunctionPDF = (id) => async (dispatch) => {
   dispatch(setRequest('GET_MALFUNCTION_PDF'));
   return client
      .query({
         query: GET_MALFUNCTION_PDF,
         variables: { id },
      })
      .then((res) => {
         return res.data.vehicleMalfunction;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_MALFUNCTION_PDF'));
      });
};

export const getOfferPDF = (id) => async (dispatch) => {
   dispatch(setRequest('GET_OFFER_PDF'));
   return client
      .query({
         query: GET_OFFER_PDF,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_OFFER_PDF'));
      });
};

export const getInvoicePDF = (id) => async (dispatch) => {
   dispatch(setRequest('GET_INVOICE_PDF'));
   return client
      .query({
         query: GET_INVOICE_PDF,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.invoice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_INVOICE_PDF'));
      });
};

export const getRefundPDF = (id) => async (dispatch) => {
   dispatch(setRequest('GET_REFUND_PDF'));
   return client
      .query({
         query: GET_REFUND_PDF,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.refund;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_REFUND_PDF'));
      });
};

export const getPurchaseOrderPDF = (id) => async (dispatch) => {
   dispatch(setRequest('GET_CONTRACT_PDF'));
   return client
      .query({
         query: GET_CONTRACT_PDF,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_CONTRACT_PDF'));
      });
};

export const getGlobalSearch = (search) => async (dispatch) => {
   dispatch(setRequest('GET_GLOBAL_SEARCH'));
   return client
      .query({
         query: GET_GLOBAL_SEARCH,
         variables: {
            search,
         },
      })
      .then((res) => {
         console.log('res.data.searchResponse', res.data.search);
         return res.data.search;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_GLOBAL_SEARCH'));
      });
};

export const getVehicleGroupPrices = () => async (dispatch) => {
   dispatch(setRequest('getVehicleGroupPrices'));
   return client
      .query({
         query: GET_VEHICLE_GROUP_PRICES,
      })
      .then((res) => {
         dispatch(setVehicleGroupPrices(res.data.vehicleGroupPrices));
         return res.data.vehicleGroupPrices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleGroupPrices'));
      });
};

export const getVehicleGroups = () => async () => {
   return client
      .query({
         query: GET_VEHICLE_GROUPS,
         variables: {
            haveVehicles: true,
         },
      })
      .then((res) => {
         return res.data.vehicleGroups;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const createVehicleGroupPrice = (data) => async (dispatch) => {
   dispatch(setRequest('createVehicleGroupPrice'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_GROUP_PRICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(createVehicleGroupPrices(res.data.createVehicleGroupPrice.vehicleGroupPrice));
         return res.data.createVehicleGroupPrice.vehicleGroupPrice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createVehicleGroupPrice'));
      });
};

export const deleteVehicleGroupPrice = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_VEHICLE_GROUP_PRICES'));
   return client
      .mutate({
         mutation: DELETE_VEHICLE_GROUP_PRICES,
         variables: {
            ids,
         },
      })
      .then((res) => {
         dispatch(removeVehicleGroupPrice(ids));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_VEHICLE_GROUP_PRICES'));
      });
};

export const updateVehicleGroupPrice = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_GROUP_PRICE'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_GROUP_PRICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(updateVehicleGroupPrices(res.data.updateVehicleGroupPrice.vehicleGroupPrice));
         return res.data.updateVehicleGroupPrice.vehicleGroupPrice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_GROUP_PRICE'));
      });
};
