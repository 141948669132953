import { t } from 'i18next';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useDispatch, useSelector } from 'react-redux';
import {
   createMachine,
   createMachineGroup,
   createOfferItem,
   createVehicleReservation,
   getCustomerSuppliers,
   getPublicHolidays,
   getSupplierMachines,
   getVehicleFuelKinds,
   getVehicleGroupPrices,
   getVehicleGroups,
   updateVehicleReservation,
} from '../../../../store/apis/OffersApi';
import moment from 'moment';
import toast from 'react-hot-toast';
import { InputTextarea } from 'primereact/inputtextarea';

const AddSupplierMachine = ({ supplierMachine, setSupplierMachine }) => {
   const dispatch = useDispatch();
   const { offer } = useSelector((state) => state.offers);
   const [machineInputs, setMachineInputs] = useState({
      vehicleGroupId: null,
      vehicleId: null,
      beginDate: null,
      endDate: null,
      deliveryFee: 0,
      deliveryFeeCurrencyId: 2,
      pickupFee: 0,
      pickupFeeCurrencyId: 2,
      price: 0,
      currencyId: 2,
      isIncludingSaturdays: false,
      isIncludingSundays: false,
      isIncludingPublicHolidays: false,
      isEco: false,
      isInsurance: false,
      isPickuppByMetaloc: false,
      isReserved: false,
      discountRate: 0,
      priceKind: 'DAILY',
   });
   const [errors, setErrors] = useState({});
   const [vehicleGroups, setVehicleGroups] = useState([]);
   const [machineGroupPrices, setMachineGroupPrices] = useState(null);
   const op = useRef(null);
   const [suppliers, setSuppliers] = useState([]);
   const [selectedSupplier, setSelectedSupplier] = useState(null);
   const [vehicles, setVehicles] = useState([]);
   const [createMachineDialog, setCreateMachineDialog] = useState(false);
   const [vehicleFuelKinds, setVehicleFuelKinds] = useState([]);
   const [createVehicleInput, setCreateVehicleInput] = useState({
      machineGroup: '',
      machineCode: '',
      vehicleFuelKindId: null,
      note: '',
   });
   const [publicHolidays, setPublicHolidays] = useState([]);
   const monthlyInsurancePrice = useRef(0);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      dispatch(getCustomerSuppliers()).then((res) => {
         setSuppliers(res);
      });
      dispatch(getVehicleFuelKinds()).then((res) => {
         setVehicleFuelKinds(res);
      });
   }, []);

   useEffect(() => {
      if (offer && supplierMachine.visible === true) {
         setMachineInputs({ ...machineInputs, isInsurance: offer?.customer?.ownInsurance ? false : true });
      }
   }, [offer, supplierMachine.visible]);

   useEffect(() => {
      if (selectedSupplier) {
         dispatch(getVehicleGroups({ supplierCustomerId: selectedSupplier })).then((res) => {
            setVehicleGroups(res);
         });
         if (machineInputs.vehicleGroupId && machineInputs.beginDate && machineInputs.endDate) {
            const filters = {
               supplierCustomerId: selectedSupplier,
               vehicleGroupId: machineInputs.vehicleGroupId,
            };
            const startDate = moment(machineInputs.beginDate).format('YYYY-MM-DD');
            const endDate = moment(machineInputs.endDate).format('YYYY-MM-DD');
            dispatch(getSupplierMachines(startDate, endDate, filters)).then((res) => {
               if (supplierMachine.actionType === 'create') {
                  if (!res.length) {
                     setMachineInputs({ ...machineInputs, vehicleId: null });
                     setVehicles([]);
                  } else {
                     setVehicles(res);
                     const availableVehicle = res.find((v) => v.status === 'AVAILABLE');
                     if (availableVehicle) {
                        setMachineInputs({ ...machineInputs, vehicleId: availableVehicle.id });
                     }
                  }
               } else if (supplierMachine.actionType === 'update') {
                  setVehicles([...res, supplierMachine.item?.vehicleReservation?.vehicle]);
               }
            });
         }
      }
   }, [selectedSupplier, machineInputs.vehicleGroupId, machineInputs.beginDate, machineInputs.endDate, supplierMachine]);

   useEffect(() => {
      if (machineInputs.vehicleGroupId) {
         dispatch(getVehicleGroupPrices(+machineInputs.vehicleGroupId, +offer?.customer?.id)).then((res) => {
            if (res.length > 0) {
               setMachineGroupPrices(res[0]);
            } else {
               dispatch(getVehicleGroupPrices(+machineInputs.vehicleGroupId)).then((res) => {
                  setMachineGroupPrices(res[0]);
               });
            }
         });
      }
   }, [machineInputs.vehicleGroupId]);

   /* useEffect(() => {
      const { beginDate, endDate } = machineInputs;
      if (beginDate && endDate && machineGroupPrices && supplierMachine.actionType !== 'update') {
         const days = moment(endDate).diff(moment(beginDate), 'days');
         let price;
         if (days === 1) {
            price = machineGroupPrices.dailyPrice;
         } else if (days >= 2 && days <= 5) {
            price = machineGroupPrices.twoToFivePrice;
         } else if (days >= 6 && days <= 15) {
            price = machineGroupPrices.sixToFifteenPrice;
         } else if (days > 15 && days < 30) {
            price = machineGroupPrices.gtFifteenPrice;
         } else if (days >= 30) {
            price = machineGroupPrices.monthlyPrice;
         }
         price = parseInt(price);
         setMachineInputs({ ...machineInputs, price });
      } else {
         setMachineInputs({ ...machineInputs });
      }
   }, [machineInputs.beginDate, machineInputs.endDate, machineGroupPrices]); */

   useEffect(() => {
      if (supplierMachine.actionType === 'update') {
         setSelectedSupplier(supplierMachine?.item?.vehicleReservation?.vehicle?.supplierCustomer?.id);
         setMachineInputs({
            vehicleGroupId: supplierMachine?.item?.vehicleReservation?.vehicleGroup.id,
            vehicleId: supplierMachine?.item?.vehicleReservation?.vehicle?.id,
            beginDate: moment(supplierMachine?.item?.vehicleReservation.beginDate).toDate(),
            endDate: moment(supplierMachine?.item?.vehicleReservation.endDate).toDate(),
            deliveryFee: parseInt(supplierMachine?.item?.vehicleReservation.deliveryFee),
            pickupFee: parseInt(supplierMachine?.item?.vehicleReservation.pickupFee),
            price: parseInt(supplierMachine?.item?.vehicleReservation.price),
            isIncludingSaturdays: supplierMachine?.item?.vehicleReservation.isIncludingSaturdays,
            isIncludingSundays: supplierMachine?.item?.vehicleReservation.isIncludingSundays,
            isIncludingPublicHolidays: supplierMachine?.item?.vehicleReservation.isIncludingPublicHolidays,
            isEco: supplierMachine?.item?.vehicleReservation.isEco,
            isInsurance: supplierMachine?.item?.vehicleReservation.isInsurance,
            isPickuppByMetaloc: supplierMachine?.item?.vehicleReservation.isPickuppByMetaloc,
            isReserved: supplierMachine?.item?.vehicleReservation.isReserved,
            discountRate: supplierMachine?.item?.vehicleReservation.discountRate,
            priceKind: supplierMachine?.item?.vehicleReservation.priceKind,
         });
      }
   }, [supplierMachine]);

   const resetMachineInputs = () => {
      setMachineInputs({
         vehicleGroupId: null,
         vehicleId: null,
         beginDate: null,
         endDate: null,
         deliveryFee: 0,
         deliveryFeeCurrencyId: 2,
         pickupFee: 0,
         pickupFeeCurrencyId: 2,
         price: 0,
         currencyId: 2,
         isIncludingSaturdays: false,
         isIncludingSundays: false,
         isIncludingPublicHolidays: false,
         isEco: false,
         isInsurance: false,
         isPickuppByMetaloc: false,
         isReserved: false,
         discountRate: 0,
         priceKind: 'DAILY',
      });
      setSupplierMachine({
         visible: false,
         actionType: 'create',
         item: null,
      });
      setSelectedSupplier(null);
   };

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      if (name === 'beginDate') {
         setMachineInputs({ ...machineInputs, [name]: value, endDate: value });
         updateErrors(name, value);
      } else if (name === 'price' || name === 'deliveryFee' || name === 'pickupFee' || name === 'discountRate') {
         let onlyNums = value.replace(/[^0-9]/g, '');
         if (name === 'discountRate') {
            const numericValue = parseInt(onlyNums, 10);
            if (numericValue < 0) {
               onlyNums = 0;
            } else if (numericValue > 100) {
               onlyNums = 100;
            }
         }
         setMachineInputs({ ...machineInputs, [name]: onlyNums });
         updateErrors(name, onlyNums);
      } else {
         const { value } = e.target;
         setMachineInputs({ ...machineInputs, [name]: value });
         updateErrors(name, value);
      }
   };

   const handleFocus = (name) => {
      if (machineInputs[name] === 0) {
         setMachineInputs({ ...machineInputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const inputs = ['vehicleGroupId', 'beginDate', 'endDate', 'price'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!machineInputs[input]) {
            updateErrors(input, machineInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   /* Calculate sub total  */
   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };
   const calculateWorkDaysMonthly = (item, inputs) => {
      const calculateWorkDays = (start, end) => {
         let workDays = 0;
         const totalDays = end.diff(start, 'days') + 1;
         for (let i = 0; i < totalDays; i++) {
            const currentDay = start.day();
            const currentDate = start.format('YYYY-MM-DD');
            if (
               (currentDay === 0 && !inputs.isIncludingSundays) ||
               (currentDay === 6 && !inputs.isIncludingSaturdays) ||
               (!inputs.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
            ) {
               start.add(1, 'days');
               continue;
            }
            workDays++;
            start.add(1, 'days');
         }
         return workDays;
      };
      const start = moment(item.beginDate);
      const end = moment(item.endDate);
      const firstDayOfMonth = moment(start).startOf('month');
      const lastDayOfMonth = moment(end).endOf('month');
      const workDays = calculateWorkDays(moment(item.beginDate), moment(item.endDate), item);
      const monthlyWorkDays = calculateWorkDays(firstDayOfMonth, lastDayOfMonth, item);
      const insuranceWorkDays = moment(item?.endDate).diff(moment(item?.beginDate), 'days') + 1;
      const data = {
         workDays,
         monthlyWorkDays,
         insuranceWorkDays,
      };
      return data;
   };

   const splitDateRangeByMonth = (startDate, endDate) => {
      const start = moment(startDate);
      const end = moment(endDate);
      const result = [];
      let current = start.clone().startOf('month');
      const last = end.clone().startOf('month');

      while (current.isSameOrBefore(last)) {
         let beginDate = current.clone().startOf('month');
         let endDate = current.clone().endOf('month');
         if (beginDate.isBefore(start)) {
            beginDate = start.clone();
         }
         if (endDate.isAfter(end)) {
            endDate = end.clone();
         }
         result.push({
            beginDate,
            endDate,
         });
         current.add(1, 'month');
      }
      return result;
   };

   const calculateMonthlyPrice = () => {
      const start = moment(machineInputs.beginDate).format('YYYY-MM-DD');
      const end = moment(machineInputs.endDate).format('YYYY-MM-DD');
      const result = splitDateRangeByMonth(start, end);
      let totalPrice = 0;
      let totalInsurance = 0;
      result.forEach((item) => {
         const workingDays = calculateWorkDaysMonthly(item, machineInputs);
         const calculated = parseFloat(workingDays.workDays / workingDays.monthlyWorkDays);
         const rentAmount = machineInputs.price * calculated;
         totalPrice += rentAmount;
         const insuranceUnitPrice = ((rentAmount * 10) / 100 / workingDays.workDays) * workingDays.insuranceWorkDays;
         totalInsurance += insuranceUnitPrice;
      });
      monthlyInsurancePrice.current = totalInsurance;
      return parseFloat(totalPrice).toFixed(2);
   };

   const calculateSubTotal = (inputs) => {
      const days = calculateWorkDays(inputs);
      const price = inputs.priceKind === 'DAILY' ? parseFloat(inputs?.price) * days : calculateMonthlyPrice();
      const subTotal = price - (price * inputs.discountRate ? inputs.discountRate : 0) / 100;
      return parseFloat(subTotal);
   };

   const calculateInsurancePrice = (inputs) => {
      if (!inputs.isInsurance) return;
      let insurancePrice = 0;
      if (inputs.priceKind === 'DAILY') {
         insurancePrice = Number(inputs.price) * (moment(inputs?.endDate).diff(moment(inputs?.beginDate), 'days') + 1);
      } else {
         insurancePrice = monthlyInsurancePrice.current;
      }
      return insurancePrice;
   };
   /* Calculate sub total  */

   const formatMachineInputs = (inputs) => {
      return {
         ...inputs,
         beginDate: moment(inputs.beginDate).format('YYYY-MM-DD'),
         endDate: moment(inputs.endDate).format('YYYY-MM-DD'),
         price: parseFloat(inputs.price),
         deliveryFee: inputs.deliveryFee ? parseFloat(inputs.deliveryFee) : 0,
         pickupFee: inputs.pickupFee ? parseFloat(inputs.pickupFee) : 0,
         status: 'RESERVE',
         discountRate: inputs.discountRate ? parseInt(inputs.discountRate) : 0,
         subTotal: calculateSubTotal(inputs),
         insurancePrice: calculateInsurancePrice(inputs),
      };
   };

   const handleCreateReservation = async () => {
      const createData = formatMachineInputs(machineInputs);
      const res = await dispatch(createVehicleReservation(createData));
      if (res) {
         const offerItemData = {
            orderId: offer?.id,
            vehicleReservationId: res.id,
            price: machineInputs.price,
            quantity: 1,
            currencyId: 2,
            category: 'SUP',
         };
         await dispatch(createOfferItem(offerItemData)).then((response) => {
            if (response) {
               resetMachineInputs();
            }
         });
      }
   };

   const saveItems = async () => {
      if (!validateForm()) return;
      if (supplierMachine.actionType === 'create') {
         await handleCreateReservation();
      } else {
         const updateData = {
            ...machineInputs,
            id: supplierMachine?.item?.vehicleReservation.id,
            beginDate: moment(machineInputs.beginDate).format('YYYY-MM-DD'),
            endDate: moment(machineInputs.endDate).format('YYYY-MM-DD'),
            discountRate: machineInputs.discountRate ? parseInt(machineInputs.discountRate) : 0,
            subTotal: calculateSubTotal(machineInputs),
            insurancePrice: calculateInsurancePrice(machineInputs),
         };
         const res = await dispatch(updateVehicleReservation({ orderId: offer?.id, orderItemId: supplierMachine.item.id, updateData }));
         if (res) {
            resetMachineInputs();
            toast.success(t('pages.offers.offerItemUpdated'));
         }
      }
   };

   const createNewVehicle = async () => {
      const createVehicleGroupData = {
         vehicleKindId: 1,
         name: createVehicleInput.machineGroup,
      };
      const res = await dispatch(createMachineGroup(createVehicleGroupData));
      if (res) {
         setVehicleGroups([...vehicleGroups, res]);
         const createVehicleData = {
            vehicleKindId: 1,
            supplierCustomerId: selectedSupplier,
            vehicleGroupId: res.id,
            machineCode: createVehicleInput.machineCode,
            vehicleFuelKindId: createVehicleInput.vehicleFuelKindId,
            note: createVehicleInput.note,
         };
         dispatch(createMachine(createVehicleData)).then((response) => {
            setVehicles([...vehicles, res]);
            setMachineInputs({ ...machineInputs, vehicleGroupId: res.id, vehicleId: response.id });
            setCreateMachineDialog(false);
            setCreateVehicleInput({ machineGroup: '', machineCode: '', vehicleFuelKindId: 1 });
         });
      }
   };

   return (
      <>
         <Dialog
            header={supplierMachine.actionType === 'create' ? t('pages.offers.addSupplierMachine') : t('pages.offers.updateSupplierMachine')}
            visible={supplierMachine.visible}
            className="w-[40vw]"
            onHide={() => resetMachineInputs()}
         >
            <div className="grid">
               <div className="col-12 lg:col-6">
                  <label>{t('global.table.supplier')}</label>
                  <Dropdown
                     filter
                     options={suppliers}
                     value={selectedSupplier}
                     onChange={(e) => setSelectedSupplier(e.value)}
                     optionLabel="companyName"
                     optionValue="id"
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 lg:col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ marginRight: '10px', flex: '1' }}>
                     <label>{t('global.table.machineGroup')}</label>
                     <Dropdown
                        filter
                        options={vehicleGroups}
                        value={machineInputs.vehicleGroupId}
                        onChange={(e) => handleInputChange(e, 'vehicleGroupId')}
                        optionLabel="name"
                        optionValue="id"
                        className="w-full input-detail"
                        disabled={!selectedSupplier}
                     />
                     {errors.vehicleGroupId && <small className="p-error">{errors.vehicleGroupId}</small>}
                  </div>
                  <Button icon="pi pi-plus" className="mt-4 h-[35px] w-[35px]" onClick={() => setCreateMachineDialog(true)} disabled={!selectedSupplier} />
               </div>
               <div className="col-12 lg:col-3">
                  <label>{t('pages.offers.startDate')}</label>
                  <Calendar
                     dateFormat="dd/mm/yy"
                     value={machineInputs.beginDate}
                     onChange={(e) => handleInputChange(e, 'beginDate')}
                     className="w-full input-detail"
                  />
                  {errors.beginDate && <small className="p-error">{errors.beginDate}</small>}
               </div>
               <div className="col-12 lg:col-3">
                  <label>{t('pages.offers.endDate')}</label>
                  <Calendar
                     dateFormat="dd/mm/yy"
                     value={machineInputs.endDate}
                     onChange={(e) => handleInputChange(e, 'endDate')}
                     className="w-full input-detail"
                     minDate={machineInputs.beginDate}
                  />
                  {errors.endDate && <small className="p-error">{errors.endDate}</small>}
               </div>
               <div className="col-12 lg:col-4">
                  <label>{t('global.table.machineCode')}</label>
                  <Dropdown
                     filter
                     options={vehicles}
                     value={machineInputs.vehicleId}
                     onChange={(e) => handleInputChange(e, 'vehicleId')}
                     optionLabel="machineFullCode"
                     optionValue="id"
                     className="w-full input-detail"
                     disabled={!selectedSupplier || !machineInputs.vehicleGroupId || !machineInputs.beginDate || !machineInputs.endDate}
                  />
                  {errors.vehicleId && <small className="p-error">{errors.vehicleId}</small>}
               </div>
               <div className="mt-[4px] col-12 md:col-6 lg:col-2">
                  <div className="rounded-lg border-[1px] p-1">
                     <label className="flex justify-center">{t('pages.offers.isReserved')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isReserved} onChange={(e) => handleInputChange(e, 'isReserved')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-3 rounded-lg border-[1px] flex items-center justify-around">
                  <div>
                     <label>{t('pages.offers.daily')}</label>
                     <div>
                        <InputSwitch
                           checked={machineInputs.priceKind === 'DAILY'}
                           onChange={() => setMachineInputs({ ...machineInputs, priceKind: 'DAILY' })}
                           className="mt-1"
                        />
                     </div>
                  </div>
                  <div>
                     <label>{t('pages.offers.monthly')}</label>
                     <div>
                        <InputSwitch
                           checked={machineInputs.priceKind === 'MONTHLY'}
                           onChange={() => setMachineInputs({ ...machineInputs, priceKind: 'MONTHLY' })}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-6 lg:col-3">
                  <OverlayPanel ref={op}>
                     <div className="flex flex-col">
                        <span>
                           {t('pages.offers.price1d')} : {machineGroupPrices ? '€' + machineGroupPrices.dailyPrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.price2to5d')} : {machineGroupPrices ? '€' + machineGroupPrices.twoToFivePrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.price6to15d')} : {machineGroupPrices ? '€' + machineGroupPrices.sixToFifteenPrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.priceGt15d')} : {machineGroupPrices ? '€' + machineGroupPrices.gtFifteenPrice : '-'}
                        </span>
                        <span>
                           {t('pages.offers.monthlyPrice')} : {machineGroupPrices ? '€' + machineGroupPrices.monthlyPrice : '-'}
                        </span>
                     </div>
                  </OverlayPanel>
                  <label>{t('pages.offers.price')}</label>
                  <InputText
                     value={machineInputs.price}
                     onChange={(e) => handleInputChange(e, 'price')}
                     onFocus={() => handleFocus('price')}
                     className="w-full input-detail"
                     onMouseEnter={(e) => op.current.toggle(e)}
                     onMouseLeave={(e) => op.current.hide(e)}
                  />
                  {errors.price && <small className="p-error">{errors.price}</small>}
               </div>
               <div className="col-6 lg:col-6">
                  <label>{t('pages.offers.discountRate')}</label>
                  <div className="p-inputgroup max-h-[38px]">
                     <span className="bg-gray-200 border-none p-inputgroup-addon">%</span>
                     <InputText
                        value={machineInputs.discountRate}
                        onChange={(e) => handleInputChange(e, 'discountRate')}
                        onFocus={() => handleFocus('discountRate')}
                        className="w-full border-none bg-[#f9f9f9]"
                     />
                  </div>
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.deliveryFee')}</label>
                  <InputText
                     value={machineInputs.deliveryFee}
                     onChange={(e) => handleInputChange(e, 'deliveryFee')}
                     onFocus={() => handleFocus('deliveryFee')}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.pickupFee')}</label>
                  <InputText
                     value={machineInputs.pickupFee}
                     onChange={(e) => handleInputChange(e, 'pickupFee')}
                     onFocus={() => handleFocus('pickupFee')}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSundays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isIncludingSundays} onChange={(e) => handleInputChange(e, 'isIncludingSundays')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSaturdays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           checked={machineInputs.isIncludingSaturdays}
                           onChange={(e) => handleInputChange(e, 'isIncludingSaturdays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingPublicHolidays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           checked={machineInputs.isIncludingPublicHolidays}
                           onChange={(e) => handleInputChange(e, 'isIncludingPublicHolidays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isEco')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isEco} onChange={(e) => handleInputChange(e, 'isEco')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isInsurance')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isInsurance} onChange={(e) => handleInputChange(e, 'isInsurance')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isPickuppByMetaloc')}</label>
                     <div className="flex justify-center">
                        <InputSwitch checked={machineInputs.isPickuppByMetaloc} onChange={(e) => handleInputChange(e, 'isPickuppByMetaloc')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="mt-3 col-12">
                  <Button
                     label={supplierMachine.actionType === 'create' ? t('global.save') : t('global.update')}
                     className="w-full"
                     onClick={() => saveItems()}
                     severity="success"
                  />
               </div>
            </div>
         </Dialog>

         <Dialog header={t('pages.offers.createMachine')} visible={createMachineDialog} onHide={() => setCreateMachineDialog(false)} className="w-[20vw]">
            <div className="grid">
               <div className="col-12">
                  <label>{t('global.table.machineGroup')}</label>
                  <InputText
                     className="w-full input-detail"
                     value={createVehicleInput.machineGroup}
                     onChange={(e) => setCreateVehicleInput({ ...createVehicleInput, machineGroup: e.target.value })}
                  />
               </div>
               <div className="col-12">
                  <label>{t('global.table.machineCode')}</label>
                  <InputText
                     className="w-full input-detail"
                     value={createVehicleInput.machineCode}
                     onChange={(e) => setCreateVehicleInput({ ...createVehicleInput, machineCode: e.target.value })}
                  />
               </div>
               <div className="col-12">
                  <label>{t('pages.offers.machineFuelKind')}</label>
                  <Dropdown
                     options={vehicleFuelKinds}
                     value={createVehicleInput.vehicleFuelKindId}
                     onChange={(e) => setCreateVehicleInput({ ...createVehicleInput, vehicleFuelKindId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12">
                  <label>{t('global.table.description')}</label>
                  <InputTextarea
                     className="w-full input-detail"
                     value={createVehicleInput.note}
                     onChange={(e) => setCreateVehicleInput({ ...createVehicleInput, note: e.target.value })}
                     rows={2}
                  />
               </div>
               <div className="col-12">
                  <Button
                     label={t('global.save')}
                     className="w-full"
                     severity="success"
                     onClick={createNewVehicle}
                     disabled={!createVehicleInput.machineGroup || !createVehicleInput.machineCode || !createVehicleInput.vehicleFuelKindId}
                  />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default AddSupplierMachine;
