import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFinanceStatistics } from '../../store/apis/SharedApi';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import TotalRevenueFromCustomers from './TotalRevenueFromCustomers';
import DailyRevenue from './DailyRevenue';
import RevenuePerMachineCode from './RevenuePerMachineCode';
import RevenuePerMachineGroupForSupplier from './RevenuePerMachineGroupForSupplier';
import OnlyRentRevenueByMachineGroupOfParkMachine from './OnlyRentRevenueByMachineGroup';
import MonthlyRevenuePerCustomer from './MonthlyRevenuePerCustomer';
import YourMonthlyTurnover from './YourMonthlyTurnover';
import DetailedMonthlyTurnover from './DetailedMonthlyTurnover';
import RevenueBySalesRepresentatives from './RevenueBySalesRepresentatives';
import ProfitabilityAnalysis from './ProfitabilityAnalysis';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

const Finance = () => {
   const dispatch = useDispatch();
   const { token } = useSelector((state) => state.user);
   const { t } = useTranslation();
   const statistics = useSelector((state) => state.shared.financeStatistics);
   const [occupancyRate, setOccupancyRate] = useState(0);
   const [occupancyRateIncludingReturnPlaned, setOccupancyRateIncludingReturnPlaned] = useState(0);
   const [dailyAveragePrice, setDailyAveragePrice] = useState(0);
   const [rentalVsParc, setRentalVsParc] = useState(0);
   const [totalVehicles, setTotalVehicles] = useState(0);
   const [rentalVsParcReturnPlaned, setRentalVsParcReturnPlaned] = useState(0);
   const [dailyRentalRevenue, setDailyRentalRevenue] = useState(0);
   const [monthlyRentalRevenue, setMonthlyRentalRevenue] = useState(0);
   const [departures, setDepartures] = useState(0);
   const [returns, setReturns] = useState(0);
   const [totalRevenueFromCustomersVisible, setTotalRevenueFromCustomersVisible] = useState(false);
   const [totalRevenueFromCustomersData, setTotalRevenueFromCustomersData] = useState(null);
   const [dailyRevenueVisible, setDailyRevenueVisible] = useState(false);
   const [dailyRevenueData, setDailyRevenueData] = useState(null);
   const [revenuePerMachineCodeVisible, setRevenuePerMachineCodeVisible] = useState(false);
   const [revenuePerMachineCodeData, setRevenuePerMachineCodeData] = useState(null);
   const [revenuePerMachineGroupForSupplierVisible, setRevenuePerMachineGroupForSupplierVisible] = useState(false);
   const [revenuePerMachineGroupForSupplierData, setRevenuePerMachineGroupForSupplierData] = useState(null);
   const [onlyRentRevenueByMachineGroupOfParkMachineVisible, setOnlyRentRevenueByMachineGroupOfParkMachineVisible] = useState(false);
   const [onlyRentRevenueByMachineGroupOfParkMachineData, setOnlyRentRevenueByMachineGroupOfParkMachineData] = useState(null);
   const [monthlyRevenuePerCustomerVisible, setMonthlyRevenuePerCustomerVisible] = useState(false);
   const [monthlyRevenuePerCustomerData, setMonthlyRevenuePerCustomerData] = useState(null);
   const [yourMonthlyTurnoverData, setYourMonthlyTurnoverData] = useState(null);
   const [detailedMonthlyTurnoverData, setDetailedMonthlyTurnoverData] = useState(null);
   const [revenueBySalesRepresentativesData, setRevenueBySalesRepresentativesData] = useState(null);
   const [profitabilityAnalysisVisible, setProfitabilityAnalysisVisible] = useState(false);
   const [profitabilityAnalysisData, setProfitabilityAnalysisData] = useState(null);
   const [startDate, setStartDate] = useState(moment().subtract(12, 'months').format('YYYY-MM-DD'));
   const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
   const [filterVisible, setFilterVisible] = useState(false);

   useEffect(() => {
      if (!token) return;
      dispatch(
         getFinanceStatistics({
            startDate: startDate,
            endDate: endDate,
         }),
      );
   }, [token]);

   useEffect(() => {
      if (!statistics) return;

      // Occupancy Rate
      setOccupancyRate(statistics.OCCUPANCY_RATE.toFixed(0));

      // Occupancy Rate Including Return Planed
      setOccupancyRateIncludingReturnPlaned(statistics.OCCUPANCY_RATE_50_INCLUDING_RETURN_PLANED.toFixed(0));

      // Daily Average Price
      setDailyAveragePrice(statistics.DAILY_AVERAGE_PRICE);

      // Rental Vs Parc
      setRentalVsParc(statistics.RENTAL_VS_PARC);

      // Total Vehicles
      setTotalVehicles(statistics.TOTAL_VEHICLES);

      // Rental Vs Parc Return Planed
      setRentalVsParcReturnPlaned(statistics.RENTAL_VS_PARC_RETURN_PLANED);

      // Daily Rental Revenue
      setDailyRentalRevenue(statistics.DAILY_RENTAL_REVENUE);

      // Monthly Rental Revenue
      setMonthlyRentalRevenue(statistics.MONTHLY_RENTAL_REVENUE);

      // Departures
      setDepartures(statistics.DEPARTURES_LOGISTIC);

      // Returns
      setReturns(statistics.RETURN_LOGISTIC);

      // Total Revenue From Customers
      setTotalRevenueFromCustomersData(statistics.TOTAL_REVENUE_FROM_CUSTOMERS);

      // Daily Revenue
      setDailyRevenueData(statistics.DAILY_REVENUE);

      // Revenue Per Machine Code
      setRevenuePerMachineCodeData(statistics.REVENUE_PER_MACHINE_CODE);

      // Revenue Per Machine Group For Supplier
      setRevenuePerMachineGroupForSupplierData(statistics.REVENUE_PER_MACHINE_GROUP_FOR_SUPPLIER);

      // Only Rent Revenue By Machine Group Of Park Machine
      setOnlyRentRevenueByMachineGroupOfParkMachineData(statistics.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE);

      // Monthly Revenue Per Customer
      setMonthlyRevenuePerCustomerData(statistics.MONTHLY_REVENUE_PER_CUSTOMER);

      // Your Monthly Turnover
      setYourMonthlyTurnoverData(statistics.YOUR_MONTHLY_TURNOVER);

      // Detailed Monthly Turnover
      setDetailedMonthlyTurnoverData(statistics.DETAILED_MONTHLY_TURNOVER);

      // Revenue By Sales Representatives
      setRevenueBySalesRepresentativesData(statistics.REVENUE_BY_SALES_REPRESENTATIVES);

      // Profitability Analysis
      setProfitabilityAnalysisData(statistics.PROFITABILITY_ANALYSIS);
   }, [statistics]);

   const handleTotalRevenueFromCustomers = () => {
      setTotalRevenueFromCustomersVisible(true);
   };

   const handleDailyRevenue = () => {
      setDailyRevenueVisible(true);
   };

   const handleRevenuePerMachineCode = () => {
      setRevenuePerMachineCodeVisible(true);
   };

   const handleRevenuePerMachineGroupForSupplier = () => {
      setRevenuePerMachineGroupForSupplierVisible(true);
   };

   const handleOnlyRentRevenueByMachineGroupOfParkMachine = () => {
      setOnlyRentRevenueByMachineGroupOfParkMachineVisible(true);
   };

   const handleMonthlyRevenuePerCustomer = () => {
      // Total Revenue From Customers
      setTotalRevenueFromCustomersData(statistics.TOTAL_REVENUE_FROM_CUSTOMERS);
      setMonthlyRevenuePerCustomerVisible(true);
   };

   const handleProfitabilityAnalysis = () => {
      setProfitabilityAnalysisVisible(true);
   };

   const handleFilter = async () => {
      const res = await dispatch(
         getFinanceStatistics({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
         }),
      );
      if (res) {
         // Your Monthly Turnover
         setYourMonthlyTurnoverData(res.YOUR_MONTHLY_TURNOVER);

         // Detailed Monthly Turnover
         setDetailedMonthlyTurnoverData(res.DETAILED_MONTHLY_TURNOVER);

         // Revenue By Sales Representatives
         setRevenueBySalesRepresentativesData(res.REVENUE_BY_SALES_REPRESENTATIVES);
      }
      setFilterVisible(false);
   };

   return (
      <>
         {/* Top Cards */}
         <div className="grid">
            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold">{t('pages.dashboard.occupancyRate')}</div>
                     <div className="text-[12px]">
                        <span className="me-1">{occupancyRateIncludingReturnPlaned}%</span>
                        {t('pages.dashboard.includingReturnPlaned')}
                     </div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">{occupancyRate}%</div>
               </div>
            </div>

            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold mb-[16px]">{t('pages.dashboard.dailyAveragePrice')}</div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">{dailyAveragePrice.toFixed(2).replace('.', ',')} €</div>
               </div>
            </div>

            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold">{t('pages.dashboard.rentalVsParc')}</div>
                     <div className="text-[12px]">
                        <span className="me-1">(+{rentalVsParcReturnPlaned}</span>
                        {t('pages.dashboard.rentalVsParcReturnPlaned') + ')'}
                     </div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">
                     {rentalVsParc} / {totalVehicles}
                  </div>
               </div>
            </div>

            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold mb-[16px]">{t('pages.dashboard.dailyRentalRevenue')}</div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">{dailyRentalRevenue.toFixed(2).replace('.', ',')} €</div>
               </div>
            </div>

            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold">{t('pages.dashboard.montlyRentalRevenue')}</div>
                     <div className="text-[12px]">{'(' + t('pages.dashboard.onlyRentalInvoices') + ')'}</div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">{monthlyRentalRevenue.toFixed(2).replace('.', ',')} €</div>
               </div>
            </div>

            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold mb-[16px]">{t('pages.departuresAndReturns.departures')}</div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">{departures}</div>
               </div>
            </div>

            <div className="col">
               <div className="bg-white rounded-md shadow-sm p-2 h-[120px] w-[190px]">
                  <div className="pb-2 text-center border-b-2">
                     <div className="text-[14px] font-bold mb-[16px]">{t('pages.departuresAndReturns.returns')}</div>
                  </div>
                  <div className="text-center text-[18px] mt-[12px]">{returns}</div>
               </div>
            </div>
         </div>

         {/* Buttons */}
         <div className="grid mt-3">
            <div className="col-12 md:col-4 lg:col-3">
               <Button onClick={handleMonthlyRevenuePerCustomer} label={t('pages.dashboard.monthlyRevenuePerCustomer')} className="w-full" severity="info" />
            </div>
            <div className="col-12 md:col-4 lg:col-3">
               <Button onClick={handleTotalRevenueFromCustomers} label={t('pages.dashboard.totalRevenueFromCustomer')} className="w-full" severity="info" />
            </div>
            <div className="col-12 md:col-4 lg:col-3">
               <Button onClick={handleDailyRevenue} label={t('pages.dashboard.dailyRevenue')} className="w-full" severity="info" />
            </div>
            <div className="col-12 md:col-4 lg:col-3">
               <Button onClick={handleProfitabilityAnalysis} label={t('pages.dashboard.profitabilityAnalysis')} className="w-full" severity="info" />
            </div>

            <div className="col-12 md:col-4 lg:col-3">
               <Button onClick={handleRevenuePerMachineCode} label={t('pages.dashboard.revenuePerMachineCode')} className="w-full" severity="info" />
            </div>
            <div className="col-12 md:col-4 lg:col-3">
               <Button
                  onClick={handleRevenuePerMachineGroupForSupplier}
                  label={t('pages.dashboard.revenuePerMachineGroupForSupplier')}
                  className="w-full"
                  severity="info"
               />
            </div>

            <div className="col-12 md:col-4 lg:col-3">
               <Button
                  onClick={handleOnlyRentRevenueByMachineGroupOfParkMachine}
                  label={t('pages.dashboard.onlyRentRevenueByMachineGroupOfParkMachine')}
                  className="w-full"
                  severity="info"
               />
            </div>
         </div>

         {/* Filter */}
         <div className="mt-4">
            <Button onClick={() => setFilterVisible(true)} label={t('global.filter')} severity="success" className="w-[10vw] min-w-[100px]" />
         </div>

         <Dialog visible={filterVisible} onHide={() => setFilterVisible(false)} style={{ width: '500px' }}>
            <div className="grid">
               <div className="col-6">
                  <span>{t('pages.contracts.startDate')}</span>
                  <Calendar
                     className="w-full input-detail"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(startDate)}
                     onChange={(e) => setStartDate(e.target.value)}
                  />
               </div>

               <div className="col-6">
                  <span>{t('pages.contracts.endDate')}</span>
                  <Calendar
                     className="w-full input-detail"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(endDate)}
                     onChange={(e) => setEndDate(e.target.value)}
                  />
               </div>

               <div className="col-12">
                  <Button severity="success" onClick={handleFilter} label={t('global.filter')} className="w-full mt-3" />
               </div>
            </div>
         </Dialog>

         {/* Your Monthly Turnover */}
         <div className="mt-3 bg-white p-3 rounded shadow-md  w-[96vw] xl:w-[84vw]  overflow-hidden">
            <YourMonthlyTurnover data={yourMonthlyTurnoverData} />
         </div>

         {/* Detailed Monthly Turnover */}
         <div className="mt-3 bg-white p-3 rounded shadow-md w-[96vw] xl:w-[84vw] overflow-hidden">
            <DetailedMonthlyTurnover data={detailedMonthlyTurnoverData} />
         </div>

         {/* Revenue By Sales Representatives */}
         <div className="mt-3 bg-white p-3 rounded shadow-md w-[96vw] xl:w-[84vw] overflow-hidden">
            <RevenueBySalesRepresentatives data={revenueBySalesRepresentativesData} />
         </div>

         {/* Dialogs */}
         <Dialog
            header={t('pages.dashboard.totalRevenueFromCustomer')}
            visible={totalRevenueFromCustomersVisible}
            onHide={() => setTotalRevenueFromCustomersVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <TotalRevenueFromCustomers data={totalRevenueFromCustomersData} />
         </Dialog>

         <Dialog
            header={t('pages.dashboard.dailyRevenue')}
            visible={dailyRevenueVisible}
            onHide={() => setDailyRevenueVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <DailyRevenue data={dailyRevenueData} />
         </Dialog>

         <Dialog
            header={t('pages.dashboard.revenuePerMachineCode')}
            visible={revenuePerMachineCodeVisible}
            onHide={() => setRevenuePerMachineCodeVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <RevenuePerMachineCode data={revenuePerMachineCodeData} />
         </Dialog>

         <Dialog
            header={t('pages.dashboard.revenuePerMachineGroupForSupplier')}
            visible={revenuePerMachineGroupForSupplierVisible}
            onHide={() => setRevenuePerMachineGroupForSupplierVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <RevenuePerMachineGroupForSupplier data={revenuePerMachineGroupForSupplierData} />
         </Dialog>

         <Dialog
            header={t('pages.dashboard.onlyRentRevenueByMachineGroupOfParkMachine')}
            visible={onlyRentRevenueByMachineGroupOfParkMachineVisible}
            onHide={() => setOnlyRentRevenueByMachineGroupOfParkMachineVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <OnlyRentRevenueByMachineGroupOfParkMachine data={onlyRentRevenueByMachineGroupOfParkMachineData} />
         </Dialog>

         <Dialog
            header={t('pages.dashboard.monthlyRevenuePerCustomer')}
            visible={monthlyRevenuePerCustomerVisible}
            onHide={() => setMonthlyRevenuePerCustomerVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <MonthlyRevenuePerCustomer data={monthlyRevenuePerCustomerData} />
         </Dialog>

         <Dialog
            header={t('pages.dashboard.profitabilityAnalysis')}
            visible={profitabilityAnalysisVisible}
            onHide={() => setProfitabilityAnalysisVisible(false)}
            style={{ width: '90vw', height: '90vh' }}
         >
            <ProfitabilityAnalysis data={profitabilityAnalysisData} />
         </Dialog>
      </>
   );
};

export default Finance;
