import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en";
import fr from "./locales/fr";
import tr from "./locales/tr";

const resources = {
	"en-US": en,
	"fr-FR": fr,
	"tr-TR": tr,
};
export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).init({
	resources,
	lng: "en-US",
	fallbackLng: "en-US",
});

export default i18n;
