import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicleReservations, updateVehicleReservation } from '../../../store/apis/MalfunctionApi';
import moment from 'moment';
import TableComponent from '../../../components/TableComponent';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import CreateVehicleMalfunction from '../CreateVehicleMalfunction/Index';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const Returns = (props) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const navigation = useNavigate();
   const vehicleReservations = useSelector((state) => state.malfunctions.vehicleReservations);
   const [createDialogVisible, setCreateDialogVisible] = useState(false);
   const [selectedReservation, setSelectedReservation] = useState(null);
   const [comfirmMalfunction, setComfirmMalfunction] = useState(false);
   const [updateData, setUpdateData] = useState({
      id: null,
      status: null,
      vehicleId: null,
   });

   useEffect(() => {
      dispatch(getVehicleReservations({ statuses: ['RETURN_PLANED', 'INSPECTION'], isContracted: true, isSupplier: false }));
   }, []);

   useEffect(() => {
      if (comfirmMalfunction) {
         dispatch(updateVehicleReservation({ id: updateData.id, status: updateData.status, vehicleId: updateData.vehicleId })).then((res) => {
            setComfirmMalfunction(false);
         });
      }
   }, [comfirmMalfunction]);

   const handleUpdate = (id, status) => {
      dispatch(updateVehicleReservation({ id, status })).then((res) => {
         toast.success(t('pages.departuresAndReturns.updateSuccess'));
      });
   };

   const acceptInspection = (id, status) => {
      dispatch(updateVehicleReservation({ id, status, returnDate: moment().format('YYYY-MM-DD') })).then((res) => {
         toast.success(t('pages.departuresAndReturns.updateSuccess'));
      });
   };

   const confirmInspection = (id, status) => {
      confirmDialog({
         message: t('pages.departuresAndReturns.confirm'),
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         defaultFocus: 'accept',
         accept: () => acceptInspection(id, status),
      });
   };

   const acceptAvailable = (id, status, vehicleId) => {
      dispatch(updateVehicleReservation({ id, status, vehicleId })).then((res) => {
         toast.success(t('pages.departuresAndReturns.updateSuccess'));
      });
   };

   const confirmAvailable = (id, status, vehicleId) => {
      confirmDialog({
         message: t('pages.departuresAndReturns.confirm'),
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => acceptAvailable(id, status, vehicleId),
      });
   };

   const acceptMalfunction = (id, status, vehicleId) => {
      setCreateDialogVisible(true);
      setUpdateData({
         id: id,
         status: status,
         vehicleId: vehicleId,
      });
      // dispatch(updateVehicleReservation({ id: id, status: status, vehicleId: vehicleId })).then((res) => {});
   };

   const confirmMalfunction = (id, status, vehicleId) => {
      confirmDialog({
         message: t('pages.departuresAndReturns.confirm'),
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => acceptMalfunction(id, status, vehicleId),
      });
   };

   const returnTableColumns = [
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName = rowData.customer?.companyName;
            return companyName ? <span className="text-blue-500 underline cursor-pointer">{companyName}</span> : <span>-</span>;
         },
      },
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => {
            return <span>{rowData.vehicleGroup && rowData.vehicleGroup.name ? rowData.vehicleGroup.name : '-'}</span>;
         },
      },
      {
         field: 'machineCode',
         name: 'machineCode',
         header: t('pages.malfunctions.machineCode'),
         body: (rowData) => {
            return (
               <span className="text-blue-500 underline cursor-pointer">
                  {rowData.vehicle && rowData.vehicle.machineFullCode ? rowData.vehicle.machineFullCode.split(' ').join('') : '-'}
               </span>
            );
         },
      },
      {
         name: 'endDate',
         header: t('pages.planning.endDate'),
         body: (rowData) => {
            return <span>{moment(rowData.endDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'action',
         body: (rowData) => {
            return (
               <div className="text-end">
                  <Button
                     onClick={() => confirmInspection(rowData.id, 'INSPECTION')}
                     severity="warning"
                     label={t('pages.departuresAndReturns.inspection')}
                     className="text-[14px] !px-2 !py-[2px]"
                  />
               </div>
            );
         },
      },
   ];

   const inspactionTableColumns = [
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => {
            return <span>{rowData.vehicleGroup && rowData.vehicleGroup.name ? rowData.vehicleGroup.name : '-'}</span>;
         },
      },
      {
         field: 'machineCode',
         name: 'machineCode',
         header: t('pages.malfunctions.machineCode'),
         body: (rowData) => {
            return (
               <span className="text-blue-500 underline cursor-pointer">
                  {rowData.vehicle && rowData.vehicle.machineFullCode ? rowData.vehicle.machineFullCode.split(' ').join('') : '-'}
               </span>
            );
         },
      },
      {
         name: 'nextVGP',
         header: t('pages.departuresAndReturns.nextVGP'),
         body: (rowData) => {
            return <span>{rowData.nextVGP ? moment(rowData.nextVGP).format('MM/DD/YYYY') : '-'}</span>;
         },
      },
      {
         name: 'nextRevision',
         header: t('pages.departuresAndReturns.nextRevision'),
         body: (rowData) => {
            return <span>{rowData.nextRevision ? moment(rowData.nextRevision).format('MM/DD/YYYY') : '-'}</span>;
         },
      },
      {
         name: 'action',
         body: (rowData) => {
            return (
               <div className="text-end">
                  <Button
                     onClick={() => confirmAvailable(rowData.id, 'RENT_FINISHED', rowData.vehicle.id)}
                     severity="success"
                     label={t('pages.departuresAndReturns.makeAvailable')}
                     className="text-[14px] !px-2 !py-[2px]"
                  />
                  <Button
                     onClick={() => {
                        setSelectedReservation(rowData);
                        confirmMalfunction(rowData.id, 'RENT_FINISHED', rowData.vehicle.id);
                     }}
                     severity="danger"
                     label={t('pages.planning.malfunction')}
                     className="text-[14px] !px-2 !py-[2px] md:ms-2 mt-2 md:mt-0"
                  />
               </div>
            );
         },
      },
   ];

   const onCellSelect = ({ field, rowData }) => {
      switch (field) {
         case 'machineCode':
            navigateToMachine(rowData);
            break;
         case 'contractNo':
            navigateToOrder(rowData);
            break;
         case 'customer':
            navigateToCustomer(rowData);
            break;
         default:
            break;
      }
   };
   const navigateToMachine = (rowData) => {
      if (rowData.vehicle && rowData.vehicle.supplierCustomerId) return;
      navigation(`/workshop/t-card`, { state: { machineId: rowData.vehicle.id } });
   };
   const navigateToOrder = (rowData) => {
      if (rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.id)
         navigation('/commerce/contracts', { state: { orderId: rowData.orderItems[0].order.id } });
   };
   const navigateToCustomer = (rowData) => {
      if (rowData.customer && rowData.customer.id) navigation('/customers', { state: { customerId: rowData.customer.id } });
   };

   return (
      <>
         <ConfirmDialog />

         <div className="p-3 bg-white rounded shadow-md">
            <div className="font-bold !text-[16px] mb-3">
               <span className="me-1 font-bold !text-[16px]">
                  ({vehicleReservations && vehicleReservations.filter((item) => item.status === 'RETURN_PLANED').length})
               </span>
               {t('pages.departuresAndReturns.receivingMachinesToday')}
            </div>
            <TableComponent
               data={vehicleReservations && vehicleReservations.filter((item) => item.status === 'RETURN_PLANED')}
               columns={returnTableColumns}
               scrollable={true}
               size="small"
               cellSelection={true}
               onCellSelect={onCellSelect}
            />
         </div>

         <div className="p-3 mt-3 bg-white rounded shadow-md">
            <div className="font-bold !text-[16px] mb-3">
               <span className="me-1 font-bold !text-[16px]">
                  ({vehicleReservations && vehicleReservations.filter((item) => item.status === 'INSPECTION').length})
               </span>
               {t('pages.departuresAndReturns.machinePreparation')}
            </div>
            <TableComponent
               data={vehicleReservations && vehicleReservations.filter((item) => item.status === 'INSPECTION')}
               columns={inspactionTableColumns}
               scrollable={true}
               size="small"
               cellSelection={true}
               onCellSelect={onCellSelect}
            />
         </div>

         <CreateVehicleMalfunction
            createDialogVisible={createDialogVisible}
            setCreateDialogVisible={setCreateDialogVisible}
            reservation={selectedReservation}
            selectedType="4"
            comfirmMalfunction={comfirmMalfunction}
            setComfirmMalfunction={setComfirmMalfunction}
         />
      </>
   );
};

export default Returns;
