import { t } from 'i18next';
import React from 'react';

const OrdersInfoCards = ({ offerStatistics }) => {
   const stats = offerStatistics ? JSON.parse(offerStatistics) : {};
   const conversionRate = stats.totalOffers && stats.acceptedOffers ? ((stats.acceptedOffers / stats.totalOffers) * 100).toFixed(1) : 0;

   return (
      <div className="grid !m-0 !p-0">
         <div className="p-1 col-12 md:col-6 lg:col">
            <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
               <div className="border-2 border-blue-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                  <i className="pi pi-list text-blue-500 text-[20px]"></i>
               </div>
               <div className="ml-4">
                  <div className="font-bold">{stats.totalOffers}</div>
                  <div className="font-light text-gray-500 text-[14px]">{t('pages.offers.totalOrder')}</div>
               </div>
            </div>
         </div>

         <div className="p-1 col-12 md:col-6 lg:col">
            <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
               <div className="border-2 border-green-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                  <i className="pi pi-list text-green-500 text-[20px]"></i>
               </div>
               <div className="ml-4">
                  <div className="font-bold">{stats.acceptedOffers}</div>
                  <div className="font-light text-gray-500 text-[14px]">{t('pages.offers.acceptedOrders')}</div>
               </div>
            </div>
         </div>

         <div className="p-1 col-12 md:col-6 lg:col">
            <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
               <div className="border-2 border-red-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                  <i className="pi pi-list text-red-500 text-[20px]"></i>
               </div>
               <div className="ml-4">
                  <div className="font-bold">{stats.pendingOffers}</div>
                  <div className="font-light text-gray-500 text-[14px]">{t('pages.offers.pendingOrders')}</div>
               </div>
            </div>
         </div>

         <div className="p-1 col-12 md:col-6 lg:col">
            <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
               <div className="border-2 border-purple-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                  <i className="pi pi-list text-purple-500 text-[20px]"></i>
               </div>
               <div className="ml-4">
                  <div className="font-bold">{stats.refusedOffers}</div>
                  <div className="font-light text-gray-500 text-[14px]">{t('pages.offers.refusedOrders')}</div>
               </div>
            </div>
         </div>

         <div className="p-1 col-12 md:col-6 lg:col">
            <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
               <div className="border-2 border-gray-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                  <i className="pi pi-list text-gray-500 text-[20px]"></i>
               </div>
               <div className="ml-4">
                  <div className="font-bold">%{conversionRate}</div>
                  <div className="font-light text-gray-500 text-[14px]">{t('pages.offers.conversionRate')}</div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default OrdersInfoCards;
