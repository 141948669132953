import client from '../../graphql/apollo.config';
import {
   CANCEL_OFFER_WITH_RESERVATIONS,
   CREATE_INVOICE,
   CREATE_ITEM_TRANSPORTATION,
   DELETE_ITEM_TRANSPORTATION,
   GET_ITEM_TRANSPORTATIONS,
   GET_VEHICLE_RESERVATION,
   GET_VEHICLE_RESERVATIONS,
   GET_VEHICLE_RESERVATIONS_FOR_TOURS,
   GET_VEHICLE_RESERVATIONS_RETURN,
   RE_RENT,
   UPDATE_ADDRESS,
   UPDATE_ITEM_TRANSPORTATION,
   UPDATE_VEHICLE_RESERVATION,
   UPDATE_VEHICLE_RESERVATION_DRIVER,
} from '../../graphql/logistics';
import {
   handleCancelOfferWithReservations,
   handleCreateInvoice,
   reRentVehicleReservation,
   setVehicleReservations,
   updateVehicleReservationDate,
   updateVehicleReservationStatus,
} from '../slices/logisticsSlice';
import { removeRequest, setRequest } from '../slices/sharedSlice';

export const getVehicleReservations = (date, endDate, statuses) => async (dispatch) => {
   dispatch(setRequest('GET_LOGISTICS_VEHICLE_RESERVATIONS'));
   return client
      .query({
         query: GET_VEHICLE_RESERVATIONS,
         variables: { date, endDate, statuses },
      })
      .then((res) => {
         const combinedArray = [...res.data.withPickupByMetaloc, ...res.data.withStatuses];
         const uniqueArray = combinedArray.reduce((acc, current) => {
            const duplicate = acc.find((item) => item.id === current.id);
            if (!duplicate) {
               acc.push(current);
            }
            return acc;
         }, []);
         dispatch(setVehicleReservations(uniqueArray));
         return res.data;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_LOGISTICS_VEHICLE_RESERVATIONS'));
      });
};

export const getVehicleReservationsReturn = (date, endDate, statuses) => async (dispatch) => {
   dispatch(setRequest('GET_VEHICLE_RESERVATIONS_RETURN'));
   return client
      .query({
         query: GET_VEHICLE_RESERVATIONS_RETURN,
         variables: { date, endDate, statuses },
      })
      .then((res) => {
         dispatch(setVehicleReservations(res.data.vehicleReservations));
         return res.data.vehicleReservations;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_VEHICLE_RESERVATIONS_RETURN'));
      });
};

export const handleUpdateVehicleReservationStatus = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_LOGISTICS_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION,
         variables: { data },
      })
      .then((res) => {
         dispatch(updateVehicleReservationStatus(res.data.updateVehicleReservation.vehicleReservation));
         return res.data.updateVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_LOGISTICS_VEHICLE_RESERVATION'));
      });
};

export const handleUpdateVehicleReservationDate = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_LOGISTICS_VEHICLE_RESERVATION'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION,
         variables: { data },
      })
      .then((res) => {
         dispatch(updateVehicleReservationDate(res.data.updateVehicleReservation.vehicleReservation));
         return res.data.updateVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_LOGISTICS_VEHICLE_RESERVATION'));
      });
};

export const cancelOfferWithReservations = (data) => async (dispatch) => {
   dispatch(setRequest('CANCEL_OFFER_WITH_RESERVATIONS'));
   return client
      .mutate({
         mutation: CANCEL_OFFER_WITH_RESERVATIONS,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleCancelOfferWithReservations(res.data.updateOrder.order));
         return res.data.updateOrder.order;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CANCEL_OFFER_WITH_RESERVATIONS'));
      });
};

export const createInvoice = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_INVOICE'));
   return client
      .mutate({
         mutation: CREATE_INVOICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleCreateInvoice(data.vehicleReservationId));
         return res.data.createInvoice.invoice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_INVOICE'));
      });
};

export const getVehicleReservation = (id) => async (dispatch) => {
   dispatch(setRequest('GET_VEHICLE_RESERVATION'));
   return client
      .query({
         query: GET_VEHICLE_RESERVATION,
         variables: { id },
      })
      .then((res) => {
         return res.data.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_VEHICLE_RESERVATION'));
      });
};

export const getVehicleReservationsForTours =
   ({ date, statuses, isContracted, isSupplier, isNext }) =>
   async (dispatch) => {
      dispatch(setRequest('GET_VEHICLE_RESERVATIONS_FOR_TOURS'));
      return client
         .query({
            query: GET_VEHICLE_RESERVATIONS_FOR_TOURS,
            variables: {
               statuses,
               date,
               isContracted,
               isSupplier,
               isNext,
            },
         })
         .then((res) => {
            return res.data.vehicleReservations;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('GET_VEHICLE_RESERVATIONS_FOR_TOURS'));
         });
   };

export const getItemTransportations =
   ({ isNext }) =>
   async (dispatch) => {
      dispatch(setRequest('GET_ITEM_TRANSPORTATIONS'));
      return client
         .query({
            query: GET_ITEM_TRANSPORTATIONS,
            variables: {
               isNext,
            },
         })
         .then((res) => {
            return res.data.itemTransportations;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('GET_ITEM_TRANSPORTATIONS'));
         });
   };

export const createItemTransportation = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_ITEM_TRANSPORTATION'));
   return client
      .mutate({
         mutation: CREATE_ITEM_TRANSPORTATION,
         variables: { data },
      })
      .then((res) => {
         return res.data.createItemTransportation.itemTransportation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_ITEM_TRANSPORTATION'));
      });
};

export const deleteItemTransportation = (id) => async (dispatch) => {
   dispatch(setRequest('DELETE_ITEM_TRANSPORTATION'));
   return client
      .mutate({
         mutation: DELETE_ITEM_TRANSPORTATION,
         variables: { id },
      })
      .then((res) => {
         return res.data.deleteItemTransportations;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_ITEM_TRANSPORTATION'));
      });
};

export const updateItemTransportation = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_ITEM_TRANSPORTATION'));
   return client
      .mutate({
         mutation: UPDATE_ITEM_TRANSPORTATION,
         variables: { data },
      })
      .then((res) => {
         return res.data.updateItemTransportation.itemTransportation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_ITEM_TRANSPORTATION'));
      });
};

export const updateAddress = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_ADDRESS'));
   return client
      .mutate({
         mutation: UPDATE_ADDRESS,
         variables: { data },
      })
      .then((res) => {
         return res.data.updateAddress.address;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_ADDRESS'));
      });
};

export const updateVehicleReservationDriver = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_RESERVATION_DRIVER'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_RESERVATION_DRIVER,
         variables: { data },
      })
      .then((res) => {
         return res.data.updateVehicleReservation.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_RESERVATION_DRIVER'));
      });
};

export const reRent = (vehicleReservationId, endDate) => async (dispatch) => {
   dispatch(setRequest('RE_RENT'));
   return client
      .mutate({
         mutation: RE_RENT,
         variables: {
            vehicleReservationId,
            endDate,
         },
      })
      .then((res) => {
         dispatch(reRentVehicleReservation({ data: res.data.reRent.vehicleReservation, id: vehicleReservationId }));
         return res.data.reRent.vehicleReservation;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('RE_RENT'));
      });
};
