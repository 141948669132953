import React, { useState } from "react";

import { Dialog } from "primereact/dialog";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedMachine,
  setShowAddNewMachineDialog,
  setStepCounter,
} from "../../store/slices/machineSlice";
import { Steps } from "primereact/steps";
import Informations from "./NewMachineSteps/Informations";
import Documents from "./NewMachineSteps/Documents";
import Finish from "./NewMachineSteps/Finish";

const AddNewMachine = () => {
  const dispatch = useDispatch();
  const steps = [
    { label: "Informations" },
    { label: "Documents" },
    { label: "Finish" },
  ];
  const { showAddNewMachineDialog, stepCounter } = useSelector(
    (state) => state.machine
  );

  const [informations, setInformations] = useState({
    brand: "",
    deport: "",
    guaranteeDeliveryDate: null,
    guaranteeNextDate: null,
    guaranteeNextRevision: null,
    guaranteePeriod: null,
    guaranteeRemainingPeriod: null,
    length: undefined,
    machineCode: "",
    maxWarehouse: undefined,
    model: "",
    note: "",
    serialNumber: "",
    supplierCustomer: null,
    weight: undefined,
    width: undefined,
    height: undefined,
    year: "",
    vehicleFuelKind: "",
    vehicleGroup: "",
    vehicleKind: "",
  });

  const resetVariables = () => {
    setInformations({
      brand: "",
      deport: "",
      guaranteeDeliveryDate: null,
      guaranteeNextDate: null,
      guaranteeNextRevision: null,
      guaranteePeriod: null,
      guaranteeRemainingPeriod: null,
      length: undefined,
      machineCode: "",
      maxWarehouse: undefined,
      model: "",
      note: "",
      serialNumber: "",
      supplierCustomer: null,
      weight: undefined,
      width: undefined,
      height: undefined,
      year: "",
      vehicleFuelKind: "",
      vehicleGroup: "",
      vehicleKind: "",
    });
  };

  return (
    <Dialog
      visible={showAddNewMachineDialog}
      header={t("pages.machines.addNewMachine")}
      draggable={false}
      className="full-width-dialog"
      onHide={() => {
        dispatch(setShowAddNewMachineDialog(false));
        dispatch(setStepCounter(0));
        resetVariables();
      }}
    >
      <div className="h-full w-full">
        <Steps model={steps} activeIndex={stepCounter} className="mb-5" />
        <div className="p-3">
          {stepCounter === 0 ? (
            <Informations
              informations={informations}
              setInformations={setInformations}
              viewMachineDetails={false}
            />
          ) : stepCounter === 1 ? (
            <Documents viewMachineDetails={false} />
          ) : stepCounter === 2 ? (
            <Finish viewMachineDetails={false} />
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};

export default AddNewMachine;
