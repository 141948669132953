import { gql } from '@apollo/client';

export const GET_CONTRACTS = gql`
   query getContracts($first: Int, $skip: Int, $search: String, $filters: OrderFilter) {
      orderCount(search: $search, filters: $filters)
      orders(first: $first, skip: $skip, search: $search, filters: $filters) {
         id
         orderNumber
         isOffered
         isFactor
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         orderItems {
            id
            vehicleReservation {
               returnDate
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  machineCode
                  machineFullCode
               }
            }
         }
      }
   }
`;

export const GET_CONTRACT_KINDS = gql`
   query orderKinds {
      orderKinds {
         id
         name
      }
   }
`;

export const GET_USERS = gql`
   query users {
      users {
         id
         name
      }
   }
`;

export const DELETE_CONTRACT = gql`
   mutation updateOrder($data: UpdateOrderInput!) {
      updateOrder(data: $data) {
         order {
            id
         }
      }
   }
`;

export const UPDATE_CONTRACT = gql`
   mutation updateOrder($data: UpdateOrderInput!) {
      updateOrder(data: $data) {
         order {
            id
            orderNumber
            isOffered
            isFactor
            isContracted
            orderKind {
               id
               name
            }
            createdAt
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            referenceNumber
            description
            privacyDescription
            orderItems {
               id
               orderId
               category
               description
               quantity
               tax
               price
               vehicleReservation {
                  id
                  status
                  vehicleGroup {
                     id
                     name
                     description
                  }
                  vehicle {
                     id
                     note
                     machineCode
                     machineFullCode
                     supplierCustomer {
                        id
                        companyName
                     }
                  }
                  beginDate
                  endDate
                  price
                  isEco
                  ecoPrice
                  isInsurance
                  insuranceRate
                  insurancePrice
                  isIncludingSaturdays
                  isIncludingSundays
                  isIncludingPublicHolidays
                  isPickuppByMetaloc
                  deliveryFee
                  pickupFee
                  taxFee
                  tax
                  discountRate
                  subTotal
                  priceKind
               }
            }
            returnTransporterId
            departureTransporterId

            returnTransporterOrder {
               id
               transporterId
               status
               transporter {
                  id
                  companyName
               }
               address {
                  id
                  placeName
               }
               transporterOrderNumber
               transporterOrderItems {
                  id
                  transporterOrderId
                  description
                  quantity
                  price
                  beginDate
                  endDate
               }
               contractPerson
               referenceNumber
               description
               privacyDescription
               status
            }
            departureTransporterOrder {
               id
               transporterId
               status
               transporter {
                  id
                  companyName
               }
               transporterOrderNumber
               address {
                  id
                  placeName
               }
               transporterOrderItems {
                  id
                  transporterOrderId
                  description
                  quantity
                  price
                  beginDate
                  endDate
               }
               contractPerson
               referenceNumber
               description
               privacyDescription
               status
            }
            supplierTransporterOrders {
               id
               supplierId
               status
               supplier {
                  id
                  companyName
               }
               address {
                  id
                  placeName
               }
               supplierOrderNumber
               supplierTransporterOrderItems {
                  id
                  category
                  description
                  quantity
                  price
                  beginDate
                  endDate
               }
               contractPerson
               referenceNumber
               description
               privacyDescription
               status
            }
         }
      }
   }
`;

export const GET_CONTRACT = gql`
   query order($id: ID!) {
      order(id: $id) {
         id
         orderNumber
         isOffered
         isFactor
         isContracted
         orderKind {
            id
            name
         }
         createdAt
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         referenceNumber
         description
         privacyDescription
         orderItems {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               returnDate
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
                  supplierCustomer {
                     id
                     companyName
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               insurancePrice
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
               subTotal
               priceKind
            }
         }
         returnTransporterId
         departureTransporterId

         returnTransporterOrder {
            id
            transporterId
            status
            transporter {
               id
               companyName
            }
            address {
               id
               placeName
            }
            transporterOrderNumber
            transporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
         departureTransporterOrder {
            id
            transporterId

            status
            transporter {
               id
               companyName
            }
            address {
               id
               placeName
            }
            transporterOrderNumber
            transporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
         supplierTransporterOrders {
            id
            supplierId
            status
            supplier {
               id
               companyName
            }
            address {
               id
               placeName
            }
            supplierOrderNumber

            supplierTransporterOrderItems {
               id

               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
      }
   }
`;

export const CREATE_NEW_TRANSPORTER_ORDER = gql`
   mutation createNewTransporterOrder($data: CreateTransporterOrderInput!) {
      createTransporterOrder(data: $data) {
         transporterOrder {
            id
            transporterId

            status
            transporter {
               id
               companyName
            }
            address {
               id
               placeName
            }
            transporterOrderNumber
            transporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
      }
   }
`;

export const UPDATE_TRANSPORTER_ORDER = gql`
   mutation updateTransporterOrder($data: UpdateTransporterOrderInput!) {
      updateTransporterOrder(data: $data) {
         transporterOrder {
            id
            transporterId

            status
            transporter {
               id
               companyName
            }
            address {
               id
               placeName
            }
            transporterOrderNumber
            transporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
      }
   }
`;

export const CREATE_TRANSPORTER_ORDER_ITEM = gql`
   mutation createTransporterOrderItem($data: CreateTransporterOrderItemInput!) {
      createTransporterOrderItem(data: $data) {
         transporterOrderItem {
            id

            beginDate
            endDate
            description
            quantity
            price
         }
      }
   }
`;

export const UPDATE_TRANSPORTER_ORDER_ITEM = gql`
   mutation updateTransporterOrderItem($data: UpdateTransporterOrderItemInput!) {
      updateTransporterOrderItem(data: $data) {
         transporterOrderItem {
            id

            beginDate
            endDate
            description
            quantity
            price
         }
      }
   }
`;

export const GET_CUSTOMERS = gql`
   query customers {
      customers {
         id
         companyName
         customerKind {
            id
            name
         }
      }
   }
`;

export const GET_ADDRESSES = gql`
   query adresses($filters: AddressFilter) {
      addresses(filters: $filters) {
         id
         placeName
      }
   }
`;

export const GET_CONTRACT_DOCUMENTS = gql`
   query orderDocuments($orderId: ID!) {
      orderDocuments(orderId: $orderId) {
         id
         document {
            url
            size
            name
            path
         }
      }
   }
`;

export const DELETE_CONTRACT_DOCUMENT = gql`
   mutation deleteOrderDocuments($ids: [Int!]!) {
      deleteOrderDocuments(ids: $ids) {
         success
      }
   }
`;

export const CREATE_CONTRACT_DOCUMENT = gql`
   mutation createOrderDocument($data: CreateOrderDocumentInput!) {
      createOrderDocument(data: $data) {
         order {
            id
            orderDocuments {
               id
               document {
                  url
                  size
                  name
                  path
               }
            }
         }
      }
   }
`;

export const UPDATE_VEHICLE_RESERVATION = gql`
   mutation updateVehicleReservation($data: UpdateVehicleReservationInput!) {
      updateVehicleReservation(data: $data) {
         vehicleReservation {
            id
            status
            vehicleGroup {
               id
               name
               description
            }
            vehicle {
               id
               note
               machineCode
               machineFullCode
               supplierCustomer {
                  id
                  companyName
               }
            }
            beginDate
            endDate
            price
            isEco
            ecoPrice
            isInsurance
            insuranceRate
            insurancePrice
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isPickuppByMetaloc
            deliveryFee
            pickupFee
            taxFee
            tax
            discountRate
            subTotal
            priceKind
         }
      }
   }
`;
export const UPDATE_CONTRACT_ITEM = gql`
   mutation updateOrderItem($data: UpdateOrderItemInput!) {
      updateOrderItem(data: $data) {
         orderItem {
            id
            category
            description
            quantity
            tax
            price
         }
      }
   }
`;

export const DELETE_CONTRACT_ITEM = gql`
   mutation deleteContractItem($ids: [Int!]!) {
      deleteOrderItems(ids: $ids) {
         success
      }
   }
`;
export const GET_ITEM_CATEGORIES = gql`
   query getItemCategories {
      __type(name: "ItemCategory") {
         enumValues {
            name
         }
      }
   }
`;

export const GET_VEHICLE_GROUP_PRICE = gql`
   query vehicleGroupPrices($vehicleGroupId: ID, $customerId: ID) {
      vehicleGroupPrices(vehicleGroupId: $vehicleGroupId, customerId: $customerId) {
         id
         currencyId
         dailyPrice
         twoToFivePrice
         sixToFifteenPrice
         gtFifteenPrice
         monthlyPrice
      }
   }
`;
export const CREATE_MACHINE_GROUP = gql`
   mutation createVehicleGroup($data: CreateVehicleGroupInput!) {
      createVehicleGroup(data: $data) {
         vehicleGroup {
            id
            name
            description
         }
      }
   }
`;
export const GET_VEHICLE_GROUPS = gql`
   query vehicleGroups($filters: VehicleFilter, $haveVehicles: Boolean) {
      vehicleGroups(filters: $filters, haveVehicles: $haveVehicles) {
         id
         name
      }
   }
`;
export const CREATE_CONTRACT_ITEM = gql`
   mutation createOrderItem($data: CreateOrderItemInput!) {
      createOrderItem(data: $data) {
         orderItem {
            id
            orderId
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  machineCode
                  machineFullCode
                  supplierCustomer {
                     id
                     companyName
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               insurancePrice
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isPickuppByMetaloc
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
               subTotal
               priceKind
            }
         }
      }
   }
`;
export const GET_VEHICLE_FUEL_KINDS = gql`
   query vehicleFuelKinds {
      vehicleFuelKinds {
         id
         name
      }
   }
`;
export const GET_MACHINES = gql`
   query vehicles($filters: VehicleFilter) {
      vehicles(filters: $filters) {
         id
         machineFullCode
         status
      }
   }
`;
export const GET_CUSTOMER_SUPPLIERS = gql`
   query customers {
      customers(filters: { customerKindId: 3, isBanned: false, isDeleted: false }) {
         id
         companyName
      }
   }
`;
export const CREATE_MACHINE = gql`
   mutation createVehicle($data: CreateVehicleInput!) {
      createVehicle(data: $data) {
         vehicle {
            id
            machineFullCode
         }
      }
   }
`;

export const EXCHANGE_MACHINE = gql`
   mutation exchangeVehicle($data: ExchangeInput!) {
      exchangeVehicle(data: $data) {
         vehicleReservation {
            id
            status
            vehicleGroup {
               id
               name
               description
            }
            vehicle {
               id
               note
               machineCode
               machineFullCode
               supplierCustomer {
                  id
                  companyName
               }
            }
            beginDate
            endDate
            price
            isEco
            ecoPrice
            isInsurance
            insuranceRate
            insurancePrice
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isPickuppByMetaloc
            deliveryFee
            pickupFee
            taxFee
            tax
            discountRate
            subTotal
            priceKind
         }
      }
   }
`;

export const CREATE_SUPPLIER_TRANSPORTER_ORDER = gql`
   mutation createSupplierTransporterOrder($data: CreateSupplierTransporterOrderInput!) {
      createSupplierTransporterOrder(data: $data) {
         supplierTransporterOrder {
            id
            supplierId
            status
            supplier {
               id
               companyName
            }
            address {
               id
               placeName
            }
            supplierOrderNumber
            supplierTransporterOrderItems {
               id
               transporterOrderId
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
      }
   }
`;

export const UPDATE_SUPPLIER_TRANSPORTER_ORDER = gql`
   mutation updateSupplierTransporterOrder($data: UpdateSupplierTransporterOrderInput!) {
      updateSupplierTransporterOrder(data: $data) {
         supplierTransporterOrder {
            id
            supplierId
            status
            supplier {
               id
               companyName
            }
            address {
               id
               placeName
            }
            supplierOrderNumber
            supplierTransporterOrderItems {
               id
               category
               description
               quantity
               price
               beginDate
               endDate
            }
            contractPerson
            referenceNumber
            description
            privacyDescription
            status
         }
      }
   }
`;

export const DELETE_SUPPLIER_TRANSPORTER_ORDER = gql`
   mutation deleteSupplierTransporterOrder($ids: [Int!]!) {
      deleteSupplierTransporterOrders(ids: $ids) {
         success
      }
   }
`;

export const DUPLICATE_CONTRACT = gql`
   mutation duplicateOrder($id: ID!) {
      duplicateOrder(id: $id) {
         order {
            id
         }
      }
   }
`;
