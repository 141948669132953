import { t } from 'i18next';
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
// import { TabView, TabPanel } from 'primereact/tabview';
import GeneralInformations from './GeneralInformations';
import Malfunctions from './Malfunctions';
import Technician from './Technician';
import MalfunctionDocuments from './MalfunctionDocuments';
import { TabMenu } from 'primereact/tabmenu';

const CreateVehicleMalfunction = (props) => {
   const steps = [{ label: 'GeneralInformations' }, { label: 'Malfunctions' }, { label: 'Technician' }, { label: 'MalfunctionDocuments' }];
   const [activeIndex, setActiveIndex] = useState(0);

   return (
      <div>
         <Dialog
            header={t('pages.malfunctions.MalfunctionRecordDetail') + ' - ' + props.machineFullCode}
            visible={props.detailDialogVisible}
            className="w-[90vw] !mt-[60px]"
            position="top"
            onHide={() => props.setDetailDialogVisible(false)}
            draggable={false}
         >
            <div style={{ maxWidth: '100%', overflowY: 'auto' }}>
               <TabMenu
                  model={steps}
                  activeIndex={activeIndex}
                  onTabChange={(e) => {
                     setActiveIndex(e.index);
                  }}
                  style={{ overflowX: 'visible', overflowY: 'clip' }}
               />
            </div>
            <div className="p-3">
               {activeIndex === 0 ? (
                  <GeneralInformations selectedId={props.selectedId} />
               ) : activeIndex === 1 ? (
                  <Malfunctions selectedId={props.selectedId} />
               ) : activeIndex === 2 ? (
                  <Technician selectedId={props.selectedId} />
               ) : activeIndex === 3 ? (
                  <MalfunctionDocuments selectedId={props.selectedId} />
               ) : null}
            </div>
            {/* <TabView>
               <TabPanel header={t('pages.malfunctions.generalInformations')}>
                  <GeneralInformations selectedId={props.selectedId} />
               </TabPanel>
               <TabPanel header={t('pages.malfunctions.malfunctions')}>
                  <Malfunctions selectedId={props.selectedId} />
               </TabPanel>
               <TabPanel header={t('pages.malfunctions.technician')}>
                  <Technician selectedId={props.selectedId} />
               </TabPanel>
               <TabPanel header={t('pages.malfunctions.malfunctionDocuments')}>
                  <MalfunctionDocuments selectedId={props.selectedId} />
               </TabPanel>
            </TabView> */}
         </Dialog>
      </div>
   );
};

export default CreateVehicleMalfunction;
