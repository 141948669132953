import { t } from 'i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { updateVehicleMalfunction } from '../../../store/apis/MalfunctionApi';
import { InputText } from 'primereact/inputtext';

const Informations = (props) => {
   const dispatch = useDispatch();
   const createdMalfunctionId = useSelector((state) => state?.malfunctions?.createdMalfunction?.id);

   const handleInputChange = (e, name) => {
      props.setMalfunctionsInputs({ ...props.malfunctionsInputs, [name]: e });
   };

   const handleSubmit = (e) => {
      const _data = {
         id: createdMalfunctionId,
         ...props.malfunctionsInputs,
      };

      const result = dispatch(updateVehicleMalfunction(_data));
      if (result) {
         props.setCreateDialogVisible(false);
         props.setActiveIndex(0);
         props.setCreatedId(null);
      }
      if (props.ComfirmMalfunction) {
         props.setComfirmMalfunction(true);
      }
   };

   return (
      <>
         <div className="grid">
            {Object.keys(props.malfunctionsInputs)
               .map((key) => ({
                  key: key,
                  value: props.malfunctionsInputs[key],
               }))
               .map((item) => (
                  <div className={`col-12 md:col-6 lg:col-4 xl:col-3 ${item.key == 'malfunctionOther' && 'hidden'}`} key={item.key}>
                     <div className="flex align-items-center border-2 rounded p-2">
                        <Checkbox
                           inputId={item.key}
                           name={item.key}
                           value={item.key}
                           onChange={(e) => handleInputChange(e.checked, item.key)}
                           checked={item.value}
                        />
                        <label htmlFor={item.key} className="ml-2 cursor-pointer">
                           {t('pages.malfunctions.' + item.key)}
                        </label>
                     </div>
                  </div>
               ))}
            {props.malfunctionsInputs.isMalfunctionOther && (
               <div className="col-12 md:col-6 lg:col-4">
                  <InputText
                     value={props.malfunctionsInputs.malfunctionOther}
                     placeholder={t('pages.malfunctions.malfunctionOther')}
                     onChange={(e) => handleInputChange(e.target.value, 'malfunctionOther')}
                     maxLength={50}
                     className={`w-full input-detail`}
                  />
               </div>
            )}
         </div>

         <div className="flex justify-between mt-4">
            <Button
               label={t('pages.malfunctions.previous')}
               icon="pi pi-chevron-left"
               onClick={() => props.setActiveIndex(props.activeIndex - 1)}
               disabled={props.activeIndex == 0}
            />

            <Button
               label={t('pages.malfunctions.saveAndContinue')}
               icon="pi pi-chevron-right"
               iconPos="right"
               onClick={handleSubmit}
               disabled={props.activeIndex === 2}
            />
         </div>
      </>
   );
};

export default Informations;
