import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../components/TableComponent';
import { Button } from 'primereact/button';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { getFilteredFinanceStatistics } from '../../store/apis/SharedApi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const OnlyRentRevenueByMachineGroupOfParkMachine = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [tableData, setTableData] = useState([]);
   const [dates, setDates] = useState([]);
   const [filterVisible, setFilterVisible] = useState(false);
   const [startDate, setStartDate] = useState(moment().subtract(12, 'months').format('YYYY-MM-DD'));
   const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
   const [selectedMachineGroup, setSelectedMachineGroup] = useState('');

   const [tableColumns, setTableColumns] = useState([]);

   const handleFilter = async () => {
      const res = await dispatch(
         getFilteredFinanceStatistics({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
         }),
      );
      if (res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE && Object.keys(res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE).length !== 0) {
         setTableColumns([]);

         setTableData([]);
         Object.keys(res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE).forEach((key) => {
            setTableData((prev) => [
               ...prev,
               {
                  machineGroup: key,
                  ...res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE[key],
               },
            ]);
         });
         const _dates = [];
         Object.keys(
            res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE &&
               res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE[Object.keys(res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE)[0]] &&
               res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE[Object.keys(res.ONLY_RENT_REVENUE_BY_MACHINE_GROUP_OF_PARK_MACHINE)[0]],
         ).forEach((key) => {
            _dates.push(key);
            setDates(_dates);
         });
      } else {
         setTableData([]);
      }
      setFilterVisible(false);
   };

   useEffect(() => {
      if (props.data && Object.keys(props.data).length === 0) {
         return;
      } else if (!props.data) {
         return;
      }
      Object.keys(props.data).forEach((key) => {
         setTableData((prev) => [
            ...prev,
            {
               machineGroup: key,
               ...props.data[key],
            },
         ]);
      });

      Object.keys(props.data && props.data[Object.keys(props.data)[0]] && props.data[Object.keys(props.data)[0]]).forEach((key) => {
         setDates((prev) => [...prev, key]);
      });
   }, []);

   useEffect(() => {
      setTableColumns([
         {
            name: 'machineGroup',
            frozen: true,
            header: t('pages.machines.machineGroup'),
            body: (rowData) => {
               return <div className="w-[100px]">{rowData.machineGroup}</div>;
            },
         },
      ]);

      for (let i = 0; i < dates.length; i++) {
         const element = dates[i];

         setTableColumns((prev) => [
            ...prev,
            {
               name: `date${i + 1}`,
               header: moment(element, 'YYYY-MM').format('MMM YYYY'),
               body: (rowData) => {
                  return (
                     <div className="!min-w-[100px] flex justify-between">
                        <div>{rowData[element] ? rowData[element].toFixed(2).replace('.', ',') : '0,00'} €</div>
                        <span>
                           {rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')] ? (
                              <span
                                 className={`${
                                    rowData[element] < rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')] ? 'text-red-300' : 'text-green-300'
                                 }`}
                              >
                                 {(
                                    ((rowData[element] - rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')]) /
                                       rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')]) *
                                    100
                                 ).toFixed() + '%'}
                              </span>
                           ) : (
                              ''
                           )}
                        </span>
                     </div>
                  );
               },
            },
         ]);
      }
   }, [dates]);

   // Excel export
   const exportExcel = () => {
      const _data =
         tableData &&
         tableData.length > 0 &&
         tableData.map((item) => {
            return {
               ...item,
            };
         });
      import('xlsx').then((xlsx) => {
         const worksheet = xlsx.utils.json_to_sheet(_data);
         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
         import('file-saver').then((module) => {
            if (module && module.default) {
               const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
               const EXCEL_EXTENSION = '.xlsx';
               const data = new Blob([excelBuffer], {
                  type: EXCEL_TYPE,
               });
               module.default.saveAs(data, 'only-rent-revenue-by-machine-group-of-park-machine' + '_' + new Date().getTime() + EXCEL_EXTENSION);
            }
         });
      });
   };

   return (
      <>
         <div className="mb-3 mt-2 flex justify-between">
            <div>
               <Button onClick={() => setFilterVisible(true)} label={t('global.filter')} severity="success" className="w-[10vw] min-w-[100px] h-[38px] me-2" />
               <InputText
                  value={selectedMachineGroup}
                  onChange={(e) => setSelectedMachineGroup(e.target.value)}
                  placeholder={t('pages.machines.search')}
                  className="input-detail"
               />
            </div>
            <Button onClick={exportExcel} icon="pi pi-file-excel" severity="success" aria-label="exel" />
         </div>
         <DataTable
            value={selectedMachineGroup ? tableData.filter((item) => item.machineGroup.toLowerCase().includes(selectedMachineGroup.toLowerCase())) : tableData}
            scrollable
            className="mt-4"
            size="small"
            stripedRows
            showGridlines
            scrollHeight="60vh"
         >
            {tableColumns.map((column) => {
               if (column.body) {
                  return (
                     <Column
                        frozen={column.frozen}
                        key={column.name || column.field}
                        header={column.header}
                        footer={column.footer}
                        body={column.body}
                        field={column.field}
                        bodyClassName={column.bodyClassName}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        selectionMode={column.selectionMode}
                     />
                  );
               } else {
                  return (
                     <Column
                        key={column.name || column.field}
                        field={column.field}
                        header={column.header}
                        footer={column.footer}
                        sortable={props.sortable}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        bodyClassName={column.bodyClassName}
                        selectionMode={column.selectionMode}
                     />
                  );
               }
            })}
         </DataTable>
         {/* <TableComponent
            data={selectedMachineGroup ? tableData.filter((item) => item.machineGroup.toLowerCase().includes(selectedMachineGroup.toLowerCase())) : tableData}
            showGridlines={true}
            columns={tableColumns}
            size="small"
         /> */}

         <Dialog visible={filterVisible} onHide={() => setFilterVisible(false)} style={{ width: '500px' }}>
            <div className="grid">
               <div className="col-6">
                  <span>{t('pages.contracts.startDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(startDate)}
                     onChange={(e) => setStartDate(e.target.value)}
                  />
               </div>

               <div className="col-6">
                  <span>{t('pages.contracts.endDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(endDate)}
                     onChange={(e) => setEndDate(e.target.value)}
                  />
               </div>

               <div className="col-12">
                  <Button severity="success" onClick={handleFilter} label={t('global.filter')} className="w-full  mt-3" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default OnlyRentRevenueByMachineGroupOfParkMachine;
