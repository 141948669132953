import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';
import moment from 'moment';
import { t } from 'i18next';

const RecoveryListPDF = ({ data }) => {
   const styles = StyleSheet.create({
      table: {
         display: 'table',
         width: 'auto',
         borderStyle: 'solid',
         borderColor: '#bfbfbf',
         borderWidth: 1,
         borderRightWidth: 0,
         borderBottomWidth: 0,
      },
      tableRow: {
         margin: 'auto',
         flexDirection: 'row',
      },
      tableCol: {
         width: '12.5%',
         borderStyle: 'solid',
         borderColor: '#bfbfbf',
         borderBottomWidth: 1,
         borderRightWidth: 1,
      },
      tableCell: {
         margin: 'auto',
         marginTop: 8,
         fontSize: 10,
      },
   });

   const downloadPDF = async (data) => {
      if (!data) return;
      const pdfData =
         data &&
         data
            .filter(
               (item) => item.status === 'RENT' || (item.status === 'RESERVE' && item.orderItems[0].category === 'SUP' && item.isPickuppByMetaloc === false),
            )
            .filter((item) => item.endDate <= moment().format('YYYY-MM-DD'));

      const onRender = (e) => {
         return `${e.pageNumber}/${e.totalPages}`;
      };

      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }}>
               <Text style={{ textAlign: 'center', fontSize: 12, marginBottom: 20, fontFamily: 'Helvetica-Bold' }}>{t('pages.pdf.recoveryListHeader')}</Text>
               <View style={styles.table}>
                  {/* Tablo başlıkları */}
                  <View style={styles.tableRow}>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.offers.customer')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.offers.poNumber')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.logistics.machineCode')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.planning.beginDate')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.planning.endDate')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.malfunctions.placeName')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.malfunctions.contactName')}</Text>
                     </View>
                     <View style={styles.tableCol}>
                        <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.comment')}</Text>
                     </View>
                  </View>
                  {/* Satırlar */}
                  {pdfData &&
                     pdfData.length &&
                     pdfData.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{item.customer?.companyName}</Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                 {item.orderItems &&
                                    item.orderItems[0] &&
                                    item.orderItems[0].order &&
                                    item.orderItems[0].order?.departureTransporterOrder?.transporterOrderNumber}
                              </Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{item.vehicle?.machineFullCode.replace(/\s/g, '')}</Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{moment(item.beginDate).format('DD/MM/YYYY')}</Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{moment(item.endDate).format('DD/MM/YYYY')}</Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>
                                 {item.orderItems && item.orderItems[0] && item.orderItems[0].order && item.orderItems[0].order.address
                                    ? item.orderItems[0].order.address.placeName
                                    : '-'}
                              </Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}>{item?.orderItems[0]?.order?.address?.receiverName}</Text>
                           </View>
                           <View style={styles.tableCol}>
                              <Text style={styles.tableCell}></Text>
                           </View>
                        </View>
                     ))}
               </View>
               {/* Footer */}
               <View fixed style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                  <Text />
                  <View>
                     <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                     <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                     <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  </View>
                  <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
               </View>
            </Page>
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(blob, 'logistics_return.pdf'); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   return (
      <>
         <Button
            icon="pi pi-file-excel"
            label={t('pages.logistics.returnExcelButton')}
            className="text-[14px] !px-6 !py-[6px]"
            onClick={() => {
               downloadPDF(data);
            }}
         />
      </>
   );
};

export default RecoveryListPDF;
