import React, { useEffect, useState } from "react";

import Informations from "./NewCustomerSteps/Informations";
import Contacts from "./NewCustomerSteps/Contacts";
import Reminders from "./NewCustomerSteps/Reminders";
import Discussions from "./NewCustomerSteps/Discussions";
import {
  setNewCustomerStepCounter,
  setSelectedCustomerId,
  setPreviousButton,
  setShowAddNewCustomer,
} from "../../store/slices/customerSlice";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import { useDispatch, useSelector } from "react-redux";
import { setDialogFlag } from "../../store/slices/sharedSlice";
import { setShowAddSupplier } from "../../store/slices/machineSlice";
import { t } from "i18next";

const AddNewCustomer = (props) => {
  const dispatch = useDispatch();

  const { stepCounter } = useSelector((state) => state.customer);
  const { showAddNewCustomer } = useSelector((state) => state.customer);

  const steps = props.createNewSupplier
    ? [
        { label: t("global.table.informations") },
        { label: t("global.table.contacts") },
      ]
    : [
        { label: t("global.table.informations") },
        { label: t("global.table.contacts") },
        { label: t("global.table.reminders") },
        { label: t("global.table.discussions") },
      ];

  const [informationsInput, setInformationsInput] = useState({
    customerKindId: null,
    companyKindId: null,
    companyName: "",
    activityName: "",
    email: "",
    phone: "",
    idNumber: "",
    faxNo: "",
    website: "",
    taxNo: "",
    taxOffice: "",
    note: "",
    followedUserId: null,
    country: t("global.france"),
    city: "",
    districtName: "",
    postCode: "",
    address: "",
    addressContinuation: "",
    billingCountry: t("global.france"),
    billingCity: "",
    billingDistrictName: "",
    billingPostCode: "",
    billingAddress: "",
    billingAddressContinuation: "",
  });

  useEffect(() => {
    /*  console.log("step:", stepCounter); */
    if (stepCounter === 4) {
      dispatch(setDialogFlag(true));
      dispatch(setNewCustomerStepCounter(0));
    }
    if (props.createNewSupplier) {
      if (stepCounter === 2) {
        dispatch(setShowAddSupplier(false));
        dispatch(setNewCustomerStepCounter(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepCounter, props.createNewSupplier]);

  return (
    <div>
      <Dialog
        header={t("pages.customers.addNewCustomer")}
        visible={showAddNewCustomer}
        onHide={() => {
          dispatch(setShowAddNewCustomer(false));
          dispatch(setSelectedCustomerId(null));
          dispatch(setNewCustomerStepCounter(0));
          dispatch(setPreviousButton(false));
          setInformationsInput({
            customerKindId: null,
            companyKindId: null,
            companyName: "",
            activityName: "",
            email: "",
            phone: "",
            idNumber: "",
            faxNo: "",
            website: "",
            taxNo: "",
            taxOffice: "",
            note: "",
            followedUserId: null,
            country: t("global.france"),
            city: "",
            districtName: "",
            postCode: "",
            address: "",
            addressContinuation: "",
            billingCountry: t("global.france"),
            billingCity: "",
            billingDistrictName: "",
            billingPostCode: "",
            billingAddress: "",
            billingAddressContinuation: "",
          });
        }}
        className="full-width-dialog"
        draggable={false}
      >
        <Steps model={steps} activeIndex={stepCounter} className="mb-5" />
        <div className="p-3">
          {stepCounter === 0 ? (
            <Informations
              informationsInputs={informationsInput}
              setInformationsInputs={setInformationsInput}
            />
          ) : stepCounter === 1 ? (
            <Contacts informationsInputs={informationsInput} />
          ) : stepCounter === 2 ? (
            <Reminders />
          ) : stepCounter === 3 ? (
            <Discussions />
          ) : null}
        </div>
      </Dialog>
    </div>
  );
};

export default AddNewCustomer;
