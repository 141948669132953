import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';
import moment from 'moment';
import { t } from 'i18next';
import { getCustomerInvoices } from '../../store/apis/Customers';
import { useDispatch } from 'react-redux';

const CustomerInvoicesPDF = ({ generalInformationsInputs, customerId }) => {
   const dispatch = useDispatch();
   const styles = StyleSheet.create({
      table: {
         display: 'table',
         width: 'auto',
         borderStyle: 'solid',
         borderColor: '#bfbfbf',
         borderWidth: 1,
         borderRightWidth: 0,
         borderBottomWidth: 0,
      },
      tableRow: {
         margin: 'auto',
         flexDirection: 'row',
      },
      tableCol: {
         width: '12.5%',
         borderStyle: 'solid',
         borderColor: '#bfbfbf',
         borderBottomWidth: 1,
         borderRightWidth: 1,
      },
      tableCell: {
         margin: 'auto',
         marginTop: 8,
         fontSize: 10,
      },
      header: {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'center',
      },
      footer: {
         position: 'absolute',
         bottom: 20,
         left: 0,
         right: 0,
         textAlign: 'center',
      },
      pageNumber: {
         position: 'absolute',
         bottom: 30,
         left: 0,
         right: 10,
         textAlign: 'right',
      },
   });

   const downloadPDF = async (pdfData) => {
      if (!pdfData) return;

      const onRender = (e) => {
         return `${e.pageNumber}/${e.totalPages}`;
      };

      const calculateTotalPrice = () => {
         let total = 0;
         pdfData.forEach((item) => {
            total += parseFloat(item.totalBeforeTax);
         });
         return total.toFixed(2).replace('.', ',');
      };

      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }} wrap>
               <View style={styles.header}>
                  <Image style={styles.logo} src={require('../../assets/header.png')} />
               </View>
               <Text style={{ textAlign: 'center', fontSize: 12, marginBottom: 10, marginTop: 20, fontFamily: 'Helvetica-Bold' }}>
                  {t('pages.pdf.customerInvoicesHeader')}
               </Text>
               <View>
                  <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>
                     {t('global.table.companyName')}: {generalInformationsInputs?.companyName}{' '}
                  </Text>
                  <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>
                     {t('pages.offers.startDate')}: {moment().subtract(1, 'years').startOf('year').format('DD/MM/YYYY')}
                  </Text>
                  <Text style={{ fontSize: 10, marginBottom: 20, fontFamily: 'Helvetica-Bold' }}>
                     {t('pages.offers.endDate')}: {moment().format('DD/MM/YYYY')}
                  </Text>
               </View>
               <View style={{ flexGrow: 1, marginBottom: 50 }}>
                  <View style={styles.table}>
                     {/* Tablo başlıkları */}
                     <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.creationDate')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.sale')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.invoiceNumber')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.dueDate')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.paymentMethod')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.pdf.invoicedBeforeTax')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.pdf.receivedBeforeTax')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.paymentDate')}</Text>
                        </View>
                     </View>
                     {/* Satırlar */}
                     {pdfData &&
                        pdfData.length &&
                        pdfData.map((item, index) => (
                           <View style={styles.tableRow} key={index}>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>-</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.invoiceNumber}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{moment(item?.dueDate).format('DD/MM/YYYY')}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.isPaid ? item?.paymentMethod : '-'}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{parseFloat(item?.totalBeforeTax).toFixed(2).replace('.', ',')}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{parseFloat(item?.totalBeforeTax).toFixed(2).replace('.', ',')}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item.paymentDate ? moment(item?.paymentDate).format('DD/MM/YYYY') : '-'}</Text>
                              </View>
                           </View>
                        ))}
                     <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: '62.5%' }]}>
                           <Text style={[styles.tableCell, { marginLeft: '10px', fontFamily: 'Helvetica-Bold' }]}>TOTAL</Text>
                        </View>
                        <View style={[styles.tableCol, { width: '12.5%', fontFamily: 'Helvetica-Bold' }]}>
                           <Text style={styles.tableCell}>{calculateTotalPrice()}</Text>
                        </View>
                        <View style={[styles.tableCol, { width: '12.5%', fontFamily: 'Helvetica-Bold' }]}>
                           <Text style={styles.tableCell}>{calculateTotalPrice()}</Text>
                        </View>
                        <View style={[styles.tableCol, { width: '12.5%', fontFamily: 'Helvetica-Bold' }]}>
                           <Text style={styles.tableCell}></Text>
                        </View>
                     </View>
                  </View>
               </View>
               {/* Footer */}
               <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                  <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  <View style={{ textAlign: 'right' }} fixed>
                     <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                  </View>
               </View>
            </Page>
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(blob, 'customer_invoices.pdf'); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   const getPdfData = async (customerId) => {
      let startDate = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
      let endDate = moment().format('YYYY-MM-DD');
      await dispatch(getCustomerInvoices(customerId, 'INVOICED', startDate, endDate)).then((res) => downloadPDF(res));
   };

   return (
      <>
         <Button
            icon="pi pi-file-pdf"
            label={t('pages.customers.accountStatement')}
            className={
               generalInformationsInputs.customerKindId === '3' || generalInformationsInputs.customerKindId === '4'
                  ? 'col-12 md:col-2 mt-2 md:mr-3'
                  : 'col-12 md:col-2 mt-2 mr-auto'
            }
            style={{
               border: 'none',
               backgroundColor: 'green',
               textAlign: 'center',
               fontSize: '1rem',
            }}
            size="small"
            onClick={() => {
               getPdfData(customerId);
            }}
         />
      </>
   );
};

export default CustomerInvoicesPDF;
