import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent";
import AddNewCustomer from "./AddNewCustomer";
import CustomerDetails from "./CustomerDetails";
import { t } from "i18next";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { setDialogFlag } from "../../store/slices/sharedSlice";
import {
  setNewCustomerStepCounter,
  setSelectedCustomerId,
  setPreviousButton,
  setShowAddNewCustomer,
  setCustomer,
} from "../../store/slices/customerSlice";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { useLocation } from "react-router-dom";
import {
  getCompanyTypes,
  getCustomer,
  getCustomerTypes,
  getCustomers,
  getUsers,
} from "../../store/apis/Customers";

const Customers = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { dialogFlag } = useSelector((state) => state.shared);

  const { customers } = useSelector((state) => state.customer);

  const [totalCustomers, setTotalCustomer] = useState();

  const [searchKeyword, setsearchKeyword] = useState("");
  const [displayUpdateModal, setDisplayUpdateModal] = useState(false);

  // table
  const [rows, setRows] = useState(20);
  const [first, setFirst] = useState(0);
  const [limitValue, setLimitValue] = useState(20);

  const [filters, setFilters] = useState();
  const [showFilters, setShowFilters] = useState(false);
  // const [showAddNewCustomer, setShowAddNewCustomer] = useState(false);

  const [companyKinds, setCompanyKinds] = useState();
  const [customerKinds, setCustomerKinds] = useState();
  const [users, setUsers] = useState();

  const [selectedCustomer, setSelectedCustomer] = useState();

  useEffect(() => {
    if (location.state?.customerId) {
      dispatch(getCustomer(location.state.customerId)).then((res) => {
        setSelectedCustomer(res);
        dispatch(setSelectedCustomerId(res.id));
        setDisplayUpdateModal(true);
      });
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(getUsers()).then((res) => {
      setUsers(res);
    });
    dispatch(getCustomerTypes()).then((res) => {
      setCustomerKinds(res);
    });
    dispatch(getCompanyTypes()).then((res) => {
      setCompanyKinds(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchKeyword == "") {
      dispatch(getCustomers(rows, first, searchKeyword, filters)).then(
        (res) => {
          setTotalCustomer(res.data.customerCount);
        }
      );
    }
  }, [first, rows, searchKeyword]);

  useEffect(() => {
    if (dialogFlag) {
      setShowAddNewCustomer(false);
      dispatch(setDialogFlag(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogFlag]);

  const tableColumns = [
    {
      name: "companyName",
      header: t("global.table.companyName"),
      bodyClassName: "w-[150px]",
      body: (rowData) => {
        return (
          <span>
            {rowData.companyName}{" "}
            {rowData.companyKind && <span>{rowData.companyKind.name}</span>}
          </span>
        );
      },
    },
    {
      name: "postCode",
      header: t("global.table.postCode"),
      bodyClassName: "w-[100px]",
      body: (rowData) => {
        for (let i = 0; i < rowData.addresses.length; i++) {
          if (rowData.addresses[i].placeName === "MAIN OFFICE") {
            return (
              <span>
                {rowData.addresses[i] ? rowData.addresses[i].postCode : ""}
              </span>
            );
          }
        }
      },
    },
    {
      name: "city",
      header: t("global.table.city"),
      bodyClassName: "w-[100px]",
      body: (rowData) => {
        for (let i = 0; i < rowData.addresses.length; i++) {
          if (rowData.addresses[i].placeName === "MAIN OFFICE") {
            return (
              <span>
                {rowData.addresses[i] ? rowData.addresses[i].city : ""}
              </span>
            );
          }
        }
      },
    },
    {
      name: "phone",
      header: t("global.table.phoneNumber"),
      bodyClassName: "w-[100px]",
      field: "phone",
    },
    {
      name: "discussion",
      bodyClassName: "w-[300px]",
      header: t("global.table.discussion"),
      body: (rowData) => {
        let data = [...rowData.discussions];
        if (rowData.discussions.length !== 0) {
          data = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
        const latestRecord = data[0];
        return (
          <span>
            {rowData.discussions.length > 0
              ? latestRecord.note.length > 100
                ? latestRecord.note.slice(0, 75) + "..."
                : latestRecord.note
              : ""}
          </span>
        );
      },
    },
  ];

  const getAllCustomers = (filter) => {
    dispatch(getCustomers(rows, first, searchKeyword, filter)).then((res) => {
      setTotalCustomer(res.data.customerCount);
    });
  };

  const handleSearch = () => {
    dispatch(getCustomers(null, null, searchKeyword, filters)).then((res) => {
      setTotalCustomer(res.data.customerCount);
    });
  };

  const handleRowSelection = (e) => {
    setSelectedCustomer(e);
    console.log(e);
    dispatch(setCustomer(e));
    dispatch(setSelectedCustomerId(e.id));
    setDisplayUpdateModal(true);
  };

  const handleChangePage = (e) => {
    setFirst(e.first);
    setRows(e.rows);
  };

  return (
    <>
      <div className="w-full h-full">
        <div className="p-3 bg-white rounded">
          {/* HEADER */}
          <div className="flex items-center justify-between mb-3">
            <div className="flex items-center justify-center">
              <div className="p-inputgroup" style={{ maxWidth: "200px" }}>
                <InputText
                  value={searchKeyword}
                  id="tableSearch"
                  onChange={(e) => setsearchKeyword(e.target.value)}
                  placeholder={t("global.search")}
                  size="small"
                  style={{ background: "#f9f9f9", border: "none" }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
                <Button
                  icon="pi pi-search"
                  size="small"
                  onClick={() => handleSearch()}
                  style={{
                    background: "#f9f9f9",
                    color: "black",
                    border: "#f9f9f9 none",
                  }}
                />
              </div>
              <Button
                icon={filters ? "pi pi-filter-slash" : "pi pi-filter"}
                className="ml-3"
                onClick={() => {
                  if (filters) {
                    setFilters(null);
                    dispatch(getCustomers()).then((res) => {
                      setTotalCustomer(res.data.customerCount);
                    });
                  } else {
                    setShowFilters(true);
                  }
                }}
                size="small"
                severity={filters && "danger"}
              />
              <Dropdown
                value={rows}
                options={[5, 10, 20, 50]}
                onChange={(e) => setRows(e.value)}
                className="ml-2"
              />
            </div>
            <div className="flex-wrap items-center justify-between hidden lg:flex">
              <Button
                label={t("pages.customers.customerTypes.PROSPECT")}
                onClick={() => {
                  if (filters?.customerKindId === 1) {
                    setFilters();
                    dispatch(getCustomers(rows, first)).then((res) => {
                      setTotalCustomer(res.data.customerCount);
                    });
                  } else {
                    setFilters({ customerKindId: 1 });
                    getAllCustomers({ customerKindId: 1 });
                  }
                }}
                outlined={filters?.customerKindId === 1 ? false : true}
                size="small"
                className="mx-1"
                rounded
              />
              <Button
                label={t("pages.customers.customerTypes.CUSTOMER")}
                onClick={() => {
                  if (filters?.customerKindId === 2) {
                    setFilters();
                    dispatch(getCustomers(rows, first)).then((res) => {
                      setTotalCustomer(res.data.customerCount);
                    });
                  } else {
                    setFilters({ customerKindId: 2 });
                    getAllCustomers({ customerKindId: 2 });
                  }
                }}
                outlined={filters?.customerKindId === 2 ? false : true}
                size="small"
                className="mx-1"
                rounded
              />
              <Button
                label={t("pages.customers.customerTypes.SUPPLIER")}
                onClick={() => {
                  if (filters?.customerKindId === 3) {
                    setFilters();
                    dispatch(getCustomers(rows, first)).then((res) => {
                      setTotalCustomer(res.data.customerCount);
                    });
                  } else {
                    setFilters({ customerKindId: 3 });
                    getAllCustomers({ customerKindId: 3 });
                  }
                }}
                outlined={filters?.customerKindId === 3 ? false : true}
                size="small"
                className="mx-1"
                rounded
              />
              <Button
                label={t("pages.customers.customerTypes.TRANSPORTER")}
                onClick={() => {
                  if (filters?.customerKindId === 4) {
                    setFilters();
                    dispatch(getCustomers(rows, first)).then((res) => {
                      setTotalCustomer(res.data.customerCount);
                    });
                  } else {
                    setFilters({ customerKindId: 4 });
                    getAllCustomers({ customerKindId: 4 });
                  }
                }}
                outlined={filters?.customerKindId === 4 ? false : true}
                size="small"
                className="mx-1"
                rounded
              />
            </div>
            <Button
              label={t("pages.customers.addNewCustomer")}
              className="ml-2"
              severity="success"
              size="small"
              onClick={() => dispatch(setShowAddNewCustomer(true))}
            />
          </div>
          {/* TABLE */}
          <TableComponent
            data={customers}
            columns={tableColumns}
            size="medium"
            onRowClick={(e) => handleRowSelection(e)}
          />
          {/* FOOTER */}
          <div className="flex items-center justify-center">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={totalCustomers}
              onPageChange={(e) => handleChangePage(e)}
            />
          </div>
        </div>
      </div>
      {/* FILTERS */}
      <Dialog
        header={t("global.filters")}
        visible={showFilters}
        onHide={() => setShowFilters(false)}
        className="md-width-dialog"
        draggable={false}
      >
        <div className="grid">
          <div className="flex flex-wrap md:col-6 col-12">
            <label>Limit</label>
            <InputNumber
              value={limitValue}
              onValueChange={(e) => setLimitValue(e.value)}
              showButtons
              buttonLayout="horizontal"
              className="w-full input-detail"
              decrementButtonClassName="p-button-secondary"
              incrementButtonClassName="p-button-secondary"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
          </div>
          <div className="flex flex-wrap md:col-6 col-12">
            <label>Search</label>
            <InputText
              className="w-full p-inputtext-sm input-detail"
              name="search"
              id="filterSearch"
              value={searchKeyword}
              onChange={(e) => setsearchKeyword(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap md:col-6 col-12">
            <label>Type</label>
            <Dropdown
              options={customerKinds}
              value={filters?.customerKindId}
              onChange={(e) =>
                setFilters({ ...filters, customerKindId: e.value })
              }
              optionLabel="name"
              optionValue="id"
              className="w-full input-detail"
              valueTemplate={(option, props) => {
                console.log(props);
                if (option) {
                  return (
                    <span>
                      {t(`pages.customers.customerTypes.${option.name}`)}
                    </span>
                  );
                }
                return <span>&nbsp;</span>;
              }}
              itemTemplate={(option) => {
                return (
                  <span>
                    {t(`pages.customers.customerTypes.${option.name}`)}
                  </span>
                );
              }}
            />
          </div>
          <div className="flex flex-wrap md:col-6 col-12">
            <label>User</label>
            <Dropdown
              options={users}
              value={filters?.followedUserId}
              onChange={(e) =>
                setFilters({ ...filters, followedUserId: e.value })
              }
              optionLabel="name"
              optionValue="id"
              className="w-full input-detail"
            />
          </div>
          {/* <div className="flex flex-wrap md:col-6 col-12">
						<label>Sort By</label>
						<Dropdown
							options={sortOptions}
							value={filters?.sort}
							onChange={(e) => setFilters({ ...filters, sort: e.value })}
							optionLabel="name"
							optionValue="id"
							className="w-full"
						/>
					</div> */}
          {/* <div className="flex flex-wrap text-center md:col-6 col-12">
						<label className="w-full">Show Deleted</label>
						<div className="w-full">
							<InputSwitch
								checked={filters?.isDeleted}
								onChange={(e) => setFilters({ ...filters, isDeleted: e.value })}
							/>
						</div>
					</div> */}
          <div className="flex flex-wrap md:col-6 col-12">
            <label className="text-white">.</label>
            <div className="flex align-items-center w-full justify-content-between px-3 py-1.5 border rounded border-box input-detail">
              <label>Deleted</label>
              <InputSwitch
                checked={filters?.isDeleted}
                onChange={(e) => setFilters({ ...filters, isDeleted: e.value })}
              />
            </div>
          </div>

          <div className="flex flex-wrap md:col-6 col-12">
            <label className="text-white">.</label>
            <div className="flex align-items-center w-full justify-content-between px-3 py-1.5 border rounded border-box input-detail">
              <label>Banned</label>
              <InputSwitch
                checked={filters?.isBanned}
                onChange={(e) => setFilters({ ...filters, isBanned: e.value })}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center col-12 md:justify-end">
          <Button
            label={t("global.filter")}
            onClick={() => {
              setRows(limitValue);
              dispatch(
                getCustomers(limitValue, null, searchKeyword, filters)
              ).then((res) => {
                setTotalCustomer(res.data.customerCount);
              });
              setShowFilters(false);
            }}
            size="small"
            className="col-3"
            severity="success"
          />
        </div>
      </Dialog>
      {/* CUSTOMER DETAILS */}
      <Dialog
        header={`${t("pages.customers.customerDetails")} (${
          selectedCustomer?.companyName
        }${
          selectedCustomer?.companyKind
            ? " " + selectedCustomer.companyKind.name
            : ""
        })`}
        dismissableMask="true"
        visible={displayUpdateModal}
        className="full-width-dialog"
        onHide={() => {
          setDisplayUpdateModal(false);
          dispatch(setSelectedCustomerId(null));
          dispatch(setCustomer(null));
        }}
        draggable={false}
      >
        <CustomerDetails customer={selectedCustomer} />
      </Dialog>
      {/* ADD NEW CUSTOMER */}

      <AddNewCustomer />
    </>
  );
};

export default Customers;
