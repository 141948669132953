import TableComponent from '../../../components/TableComponent';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPurchaseOrder } from '../../../store/apis/Customers';

const PurchaseOrder = ({ purchaseOrderDialog, setPurchaseOrderDialog, props }) => {
   const { selectedCustomerId, transporterOrders } = useSelector((state) => state.customer);

   const dispatch = useDispatch();
   useEffect(() => {
      /* Ask Dorukcan Filters */
      dispatch(getPurchaseOrder({ transporterId: selectedCustomerId }, ['APPROVED', 'REJECTED']));
   }, []);

   const tableColumns = [
      {
         name: 'purchaseOrderNumber',
         header: t('pages.customers.purchaseOrderNumber'),
         field: 'referenceNumber',
      },
      {
         name: 'createdAt',
         header: t('pages.customers.creationDate'),
         field: 'createdAt',
      },
      {
         name: 'transporter.followedUser.name',
         header: t('pages.customers.user'),
         field: 'transporter.followedUser.name',
      },
      {
         name: 'address.address',
         header: t('pages.customers.deliveryAddress'),
         field: 'address.address',
      },
      {
         name: 'totalPrice',
         header: t('pages.customers.amount'),
         field: 'totalPrice',
      },
      {
         name: 'referenceNumber',
         header: t('pages.customers.referenceOffer'),
         field: 'referenceNumber',
      },
      {
         name: 'status',
         header: t('pages.customers.status'),
         field: 'status',
      },
      {
         name: 'edit',
         header: '',
         body: () => {
            return (
               <span>
                  <i className="pi pi-file-edit"></i>
                  <i className="pi pi-trash"></i>
               </span>
            );
         },
      },
   ];

   return (
      <div>
         <div>
            <div className="flex flex-wrap justify-content-end mt-2"></div>
            <div className="col-7 mt-10">
               <TableComponent columns={tableColumns} size="large" data={transporterOrders} />
            </div>

            {/* <Dialog
					header={t('pages.contracts.createNewTransporterOrder')}
					className="w-[70vw]"
					visible={purchaseOrderDialog}
					onHide={() => setPurchaseOrderDialog(false)}
				>
					<PurchaseOrder transporterDialog={transporterDialog} setTransporterDialog={setTransporterDialog}></PurchaseOrder>
				</Dialog> */}
         </div>
      </div>
   );
};

export default PurchaseOrder;
