import React, { useState, useEffect } from 'react';

import { t } from 'i18next';

import TableComponent from '../../../components/TableComponent';

import './users.scss';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import client from '../../../graphql/apollo.config';
import { GET_USERS } from '../../../graphql/users';
import { useDispatch, useSelector } from 'react-redux';
import { changeLoadingStatus, setDialogFlag } from '../../../store/slices/sharedSlice';

import toast from 'react-hot-toast';

import UserDetails from './UserDetails';
import AddNewUser from './AddNewUser';

const Table = () => {
	const dispatch = useDispatch();

	const { dialogFlag } = useSelector((state) => state.shared);

	const [users, setUsers] = useState([]);

	const [searchKeyword, setSearchKeyword] = useState('');
	const [filters, setFilters] = useState(null);
	const [showFilters, setShowFilters] = useState(false);
	const [showAddNewUser, setShowAddNewUser] = useState(false);
	const [showUserDetails, setShowUserDetails] = useState(false);

	const [selectedUser, setSelectedUser] = useState();

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		if (dialogFlag) {
			setShowAddNewUser(false);
			setShowUserDetails(false);
			dispatch(setDialogFlag(false));
			getUsers();
		}
	}, [dialogFlag]);

	const tableColumns = [
		{
			name: 'firstName',
			header: t('global.table.firstName'),
			field: 'firstName',
		},
		{
			name: 'lastName',
			header: t('global.table.lastName'),
			field: 'lastName',
		},
		{
			name: 'email',
			header: t('global.table.email'),
			field: 'email',
		},
		{
			name: 'role',
			header: t('global.table.role'),
			body: (rowData) => {
				return <span>{t(`global.roles.${rowData.role.id}`)}</span>;
			},
		},
		{
			name: 'phoneNumber',
			header: t('global.table.phoneNumber'),
			field: 'phone',
		},
		{
			name: 'status',
			header: t('global.table.status'),
			body: (rowData) => {
				if (rowData.isDeleted) {
					return <span>Deleted</span>;
				} else {
					return <span>Active</span>;
				}
			},
		},
	];

	const getUsers = () => {
		dispatch(changeLoadingStatus(true));
		client
			.query({
				query: GET_USERS,
			})
			.then((res) => {
				setUsers(res.data.users);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.message);
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};

	const handleShowDetails = (user) => {
		setSelectedUser(user);
		setShowUserDetails(true);
	};

	const Filters = () => {
		return (
			<div>
				<span>Filters</span>
			</div>
		);
	};

	return (
		<div className="h-full w-full">
			<div className="bg-white p-3 rounded">
				{/* HEADER */}
				<div className="flex items-center justify-between mb-3">
					<div className="flex items-center justify-center">
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								value={searchKeyword}
								onChange={(e) => {
									setSearchKeyword(e.target.value);
								}}
								placeholder={t('global.search')}
							/>
						</span>
						<Button
							icon={filters ? 'pi pi-filter-slash' : 'pi pi-filter'}
							onClick={() => {
								if (filters) {
									setFilters(null);
								} else {
									setShowFilters(true);
								}
							}}
							size="small"
							className="ml-3"
						/>
					</div>
					<Button label={t('pages.settings.users.addNewUser')} icon="pi pi-plus" size="small" onClick={() => setShowAddNewUser(true)} />
				</div>
				<TableComponent
					data={users.filter(
						(user) =>
							user.firstName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
							user.lastName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
							user.email.includes(searchKeyword.toLowerCase()) ||
							user.phone.replaceAll(' ', '').includes(searchKeyword)
					)}
					columns={tableColumns}
					size="medium"
					sortable={true}
					onRowClick={(e) => handleShowDetails(e)}
					className="w-full"
				/>
			</div>
			<Dialog
				visible={showUserDetails}
				onHide={() => {
					setShowUserDetails(false);
					setSelectedUser({});
				}}
				header={t('pages.settings.users.userDetails')}
				className="full-width-dialog"
				draggable={false}
			>
				<UserDetails user={selectedUser} />
			</Dialog>
			<Dialog visible={showFilters} onHide={() => setShowFilters(false)} header={t('global.filters')} draggable={false}>
				<Filters />
			</Dialog>
			<Dialog
				visible={showAddNewUser}
				onHide={() => setShowAddNewUser(false)}
				header={t('pages.settings.users.addNewUser')}
				className="full-width-dialog"
				draggable={false}
			>
				<AddNewUser />
			</Dialog>
		</div>
	);
};

export default Table;
