import { t } from 'i18next';
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { useDispatch } from 'react-redux';
import { getCustomerSuppliers, getSupplierMachines, getVehicleGroups } from '../../../store/apis/OffersApi';
import moment from 'moment';
import toast from 'react-hot-toast';
import { getVehicleReservation, handleUpdateVehicleReservationDate } from '../../../store/apis/LogisticsApi';

const UpdateSupplierMachine = ({ supplierMachine, setSupplierMachine }) => {
   const dispatch = useDispatch();
   const [machineInputs, setMachineInputs] = useState({});
   const [errors, setErrors] = useState({});
   const [vehicleGroups, setVehicleGroups] = useState([]);
   const [suppliers, setSuppliers] = useState([]);
   const [selectedSupplier, setSelectedSupplier] = useState(null);
   const [vehicles, setVehicles] = useState([]);
   const [vehicleReservation, setVehicleReservation] = useState(null);

   useEffect(() => {
      dispatch(getVehicleReservation(+supplierMachine.item.id)).then((res) => {
         setVehicleReservation(res);
      });
      dispatch(getCustomerSuppliers()).then((res) => {
         setSuppliers(res);
      });
   }, []);

   useEffect(() => {
      if (vehicleReservation) {
         setSelectedSupplier(vehicleReservation.vehicle.supplierCustomer?.id);
         setMachineInputs({
            vehicleGroupId: vehicleReservation.vehicleGroup.id,
            vehicleId: vehicleReservation.vehicle.id,
            beginDate: moment(vehicleReservation.beginDate).toDate(),
            endDate: moment(vehicleReservation.endDate).toDate(),
            price: vehicleReservation.price,
            deliveryFee: vehicleReservation.deliveryFee,
            pickupFee: vehicleReservation.pickupFee,
            isIncludingSaturdays: vehicleReservation.isIncludingSaturdays,
            isIncludingSundays: vehicleReservation.isIncludingSundays,
            isIncludingPublicHolidays: vehicleReservation.isIncludingPublicHolidays,
            isEco: vehicleReservation.isEco,
            isInsurance: vehicleReservation.isInsurance,
            discountRate: vehicleReservation.discountRate,
         });
      }
   }, [vehicleReservation]);

   useEffect(() => {
      if (selectedSupplier) {
         dispatch(getVehicleGroups({ supplierCustomerId: selectedSupplier })).then((res) => {
            setVehicleGroups(res);
         });
         if (machineInputs.vehicleGroupId) {
            const filters = {
               supplierCustomerId: selectedSupplier,
               vehicleGroupId: machineInputs.vehicleGroupId,
            };
            dispatch(getSupplierMachines(filters)).then((res) => {
               setVehicles(res);
               const availableVehicle = res.find((v) => v.status === 'AVAILABLE');
               if (availableVehicle) {
                  setMachineInputs({ ...machineInputs, vehicleId: availableVehicle.id });
               }
            });
         }
      }
   }, [selectedSupplier, machineInputs.vehicleGroupId]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      setMachineInputs({ ...machineInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['vehicleGroupId', 'beginDate', 'endDate', 'price'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!machineInputs[input]) {
            updateErrors(input, machineInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const saveItems = async () => {
      if (!validateForm()) return;
      const updateData = {
         id: vehicleReservation?.id,
         beginDate: moment(machineInputs.beginDate).format('YYYY-MM-DD'),
         endDate: moment(machineInputs.endDate).format('YYYY-MM-DD'),
         status: 'RENT',
         vehicleId: supplierMachine.item?.vehicle.id,
      };
      const res = await dispatch(handleUpdateVehicleReservationDate(updateData));
      if (res) {
         toast.success(t('pages.logistics.reRentSuccess'));
         setSupplierMachine({
            visible: false,
            item: null,
         });
      }
   };

   return (
      <>
         <Dialog
            header={t('pages.logistics.reRent')}
            visible={supplierMachine.visible}
            className="w-[40vw]"
            onHide={() =>
               setSupplierMachine({
                  visible: false,
                  item: null,
               })
            }
         >
            <div className="grid">
               <div className="col-12 lg:col-6">
                  <label>{t('global.table.supplier')}</label>
                  <Dropdown
                     disabled
                     filter
                     options={suppliers}
                     value={selectedSupplier}
                     onChange={(e) => setSelectedSupplier(e.value)}
                     optionLabel="companyName"
                     optionValue="id"
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 lg:col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ marginRight: '10px', flex: '1' }}>
                     <label>{t('global.table.machineGroup')}</label>
                     <Dropdown
                        disabled
                        filter
                        options={vehicleGroups}
                        value={machineInputs.vehicleGroupId}
                        onChange={(e) => handleInputChange(e, 'vehicleGroupId')}
                        optionLabel="name"
                        optionValue="id"
                        className="w-full input-detail"
                     />
                     {errors.vehicleGroupId && <small className="p-error">{errors.vehicleGroupId}</small>}
                  </div>
               </div>
               <div className="col-12 lg:col-4">
                  <label>{t('global.table.machineCode')}</label>
                  <Dropdown
                     disabled
                     filter
                     options={vehicles}
                     value={machineInputs.vehicleId}
                     onChange={(e) => handleInputChange(e, 'vehicleId')}
                     optionLabel="machineFullCode"
                     optionValue="id"
                     className="w-full input-detail"
                  />
                  {errors.vehicleId && <small className="p-error">{errors.vehicleId}</small>}
               </div>
               <div className="mt-[4px] col-12 md:col-6 lg:col-2">
                  <div className="rounded-lg border-[1px] p-1">
                     <label className="flex justify-center">{t('pages.offers.isReserved')}</label>
                     <div className="flex justify-center">
                        <InputSwitch disabled checked={machineInputs.isReserved} onChange={(e) => handleInputChange(e, 'isReserved')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.startDate')}</label>
                  <Calendar value={machineInputs.beginDate} onChange={(e) => handleInputChange(e, 'beginDate')} className="w-full input-detail" />
                  {errors.beginDate && <small className="p-error">{errors.beginDate}</small>}
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.endDate')}</label>
                  <Calendar value={machineInputs.endDate} onChange={(e) => handleInputChange(e, 'endDate')} className="w-full input-detail" />
                  {errors.endDate && <small className="p-error">{errors.endDate}</small>}
               </div>
               <div className="col-6 lg:col-3">
                  <label>{t('pages.offers.price')}</label>
                  <InputText disabled value={machineInputs.price} onChange={(e) => handleInputChange(e, 'price')} className="w-full input-detail" />
                  {errors.price && <small className="p-error">{errors.price}</small>}
               </div>
               <div className="col-6 lg:col-3">
                  <label>{t('pages.offers.discountRate')}</label>
                  <div className="p-inputgroup max-h-[38px]">
                     <span className="bg-gray-200 border-none p-inputgroup-addon">%</span>
                     <InputText
                        disabled
                        value={machineInputs.discountRate}
                        onChange={(e) => handleInputChange(e, 'discountRate')}
                        className="w-full border-none bg-[#f9f9f9]"
                     />
                  </div>
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.deliveryFee')}</label>
                  <InputText disabled value={machineInputs.deliveryFee} onChange={(e) => handleInputChange(e, 'deliveryFee')} className="w-full input-detail" />
               </div>
               <div className="col-12 lg:col-6">
                  <label>{t('pages.offers.pickupFee')}</label>
                  <InputText disabled value={machineInputs.pickupFee} onChange={(e) => handleInputChange(e, 'pickupFee')} className="w-full input-detail" />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSundays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           disabled
                           checked={machineInputs.isIncludingSundays}
                           onChange={(e) => handleInputChange(e, 'isIncludingSundays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingSaturdays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           disabled
                           checked={machineInputs.isIncludingSaturdays}
                           onChange={(e) => handleInputChange(e, 'isIncludingSaturdays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isIncludingPublicHolidays')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           disabled
                           checked={machineInputs.isIncludingPublicHolidays}
                           onChange={(e) => handleInputChange(e, 'isIncludingPublicHolidays')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isEco')}</label>
                     <div className="flex justify-center">
                        <InputSwitch disabled checked={machineInputs.isEco} onChange={(e) => handleInputChange(e, 'isEco')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isInsurance')}</label>
                     <div className="flex justify-center">
                        <InputSwitch disabled checked={machineInputs.isInsurance} onChange={(e) => handleInputChange(e, 'isInsurance')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <div className="p-2 rounded-lg border-[2px]">
                     <label className="flex justify-center">{t('pages.offers.isPickuppByMetaloc')}</label>
                     <div className="flex justify-center">
                        <InputSwitch
                           disabled
                           checked={machineInputs.isPickuppByMetaloc}
                           onChange={(e) => handleInputChange(e, 'isPickuppByMetaloc')}
                           className="mt-1"
                        />
                     </div>
                  </div>
               </div>
               <div className="mt-3 col-12">
                  <Button label={t('global.update')} className="w-full" onClick={() => saveItems()} severity="success" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default UpdateSupplierMachine;
