import React, { useEffect, useState } from "react";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { t } from "i18next";
import { InputTextarea } from "primereact/inputtextarea";

import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { useDispatch, useSelector } from "react-redux";
import {
  setNewCustomerStepCounter,
  setSameAddress,
} from "../../../store/slices/customerSlice";
import {
  getCompanyTypes,
  getCustomerTypes,
  getUsers,
  updateNewCustomer,
  updateNewCustomerAddress,
  getCustomers,
} from "../../../store/apis/Customers";

const Informations = ({ informationsInputs, setInformationsInputs }) => {
  const dispatch = useDispatch();

  const { showViewMachineDetailsDialog, showAddNewMachineDialog } = useSelector(
    (state) => state.machine
  );
  const {
    sameAddress,
    previousButton,
    selectedCustomerId,
    billingAddressId,
    mainAddressId,
    showAddNewCustomer,
  } = useSelector((state) => state.customer);

  const [customerTypes, setCustomerTypes] = useState();
  const [companyTypes, setCompanyTypes] = useState();
  const [users, setUsers] = useState();
  // const [cities, setCities] = useState();

  const [errors, setErrors] = useState({});

  const [inputsChanged, setInputsChanged] = useState(false);

  useEffect(() => {
    dispatch(getUsers()).then((res) => {
      setUsers(res);
    });
    dispatch(getCompanyTypes()).then((res) => {
      setCompanyTypes(res);
    });
    dispatch(getCustomerTypes()).then((res) => {
      setCustomerTypes(res);
    });
    // dispatch(getCities()).then((res) => {
    // 	setCities(res);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FORM VALIDATION
  const updateErrors = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value ? t("global.requiredField") : "",
    }));
  };

  const handleChange = (name, value) => {
    setInformationsInputs((prevInfos) => ({
      ...prevInfos,
      [name]: value,
    }));
    updateErrors(name, value);

    setInputsChanged(true);
  };

  const validateForm = () => {
    const inputs = sameAddress
      ? [
          "customerKindId",
          "companyName",
          "email",
          "phone",
          "idNumber",
          "followedUserId",
          "country",
          "city",
          "postCode",
          "address",
        ]
      : [
          "customerKindId",
          "companyName",
          "email",
          "phone",
          "idNumber",
          "followedUserId",
          "country",
          "billingCountry",
          "city",
          "billingCity",
          "postCode",
          "billingPostCode",
          "address",
          "billingAddress",
        ];
    let isValid = true;

    if (showAddNewMachineDialog || showViewMachineDetailsDialog) {
      delete inputs[0];
    }
    inputs.forEach((input) => {
      if (!informationsInputs[input]) {
        updateErrors(input, informationsInputs[input]);
        isValid = false;
        console.log(input);
      }
    });
    return isValid;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    console.log(informationsInputs);

    if (validateForm()) {
      if (previousButton && inputsChanged) {
        dispatch(
          updateNewCustomer(selectedCustomerId, informationsInputs)
        ).then((res) => {
          if (res) {
            dispatch(
              updateNewCustomerAddress(
                informationsInputs,
                billingAddressId,
                mainAddressId,
                selectedCustomerId,
                sameAddress
              )
            ); //billingAddressId and addressId required
          }
        });
      }
      dispatch(setNewCustomerStepCounter(1));
    }
  };

  return (
    <div className="w-full h-full">
      <div className="grid">
        <Divider align="left">
          <div className="inline-flex items-center">
            <span className="font-semibold text-md">
              {t("pages.customers.generalInformation")}
            </span>
          </div>
        </Divider>
      </div>
      {/* general information */}
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.customers.type")}*</label>
          <Dropdown
            options={customerTypes}
            name="customerKindId"
            value={
              showAddNewMachineDialog || showViewMachineDetailsDialog
                ? customerTypes
                  ? customerTypes[2].id
                  : null
                : informationsInputs.customerKindId
            }
            onChange={(e) => {
              if (showAddNewMachineDialog || showViewMachineDetailsDialog) {
                handleChange("customerKindId", customerTypes[2].id);
              } else {
                handleChange("customerKindId", e.target.value);
              }
            }}
            className={`w-full input-detail ${
              showAddNewMachineDialog || showViewMachineDetailsDialog
                ? null
                : errors.customerKindId && "input-error"
            }`}
            optionLabel="name"
            optionValue="id"
            showClear
          />
          {showAddNewMachineDialog || showViewMachineDetailsDialog
            ? null
            : errors.customerKindId && (
                <small className="p-error">{errors.customerKindId}</small>
              )}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.companyName")}*</label>
          <div>
            <InputText
              value={informationsInputs.companyName}
              disabled={previousButton}
              onChange={(e) => handleChange("companyName", e.target.value)}
              className={`w-7/12 lg:w-8/12 input-detail ${
                errors.companyName && "input-error"
              }`}
            />
            <Dropdown
              value={informationsInputs.companyKindId}
              options={companyTypes}
              onChange={(e) => handleChange("companyKindId", e.target.value)}
              className="w-5/12 lg:w-4/12 input-detail"
              optionLabel="name"
              optionValue="id"
              showClear
            />
          </div>
          {errors.companyName && (
            <small className="p-error">{errors.companyName}</small>
          )}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.activityName")}</label>
          <InputText
            name="activityName"
            value={informationsInputs.activityName}
            onChange={(e) => handleChange("activityName", e.target.value)}
            className="w-full input-detail"
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.eMail")}*</label>
          <InputText
            type="email"
            value={informationsInputs.email}
            onChange={(e) => handleChange("email", e.target.value)}
            className={`w-full input-detail ${errors.email && "input-error"}`}
          />
          {errors.email && <small className="p-error">{errors.email}</small>}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.phoneNumber")}*</label>
          <InputText
            type="text"
            value={informationsInputs.phone}
            onChange={(e) => {
              if (e.nativeEvent.data !== " ") {
                handleChange("phone", e.target.value);
              }
            }}
            className={`w-full input-detail ${errors.phone && "input-error"}`}
          />
          {errors.phone && <small className="p-error">{errors.phone}</small>}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.faxNumber")}</label>
          <InputText
            value={informationsInputs.faxNo}
            onChange={(e) => {
              if (e.nativeEvent.data !== " ") {
                handleChange("faxNo", e.target.value);
              }
            }}
            className="w-full input-detail"
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">
            {t("pages.customers.identityNumber")}*
          </label>
          <InputText
            value={informationsInputs.idNumber}
            onChange={(e) => {
              if (e.nativeEvent.data !== " ") {
                handleChange("idNumber", e.target.value);
              }
            }}
            className={`w-full input-detail ${
              errors.idNumber && "input-error"
            }`}
            maxLength={15}
          />
          {errors.idNumber && (
            <small className="p-error">{errors.idNumber}</small>
          )}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.webSite")}</label>
          <InputText
            value={informationsInputs.website}
            onChange={(e) => handleChange("website", e.target.value)}
            className="w-full input-detail"
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.taxNumber")}</label>
          <InputText
            value={informationsInputs.taxNo}
            onChange={(e) => handleChange("taxNo", e.target.value)}
            className="w-full input-detail"
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label className="w-full">{t("pages.customers.taxOffice")}</label>
          <InputText
            value={informationsInputs.taxOffice}
            onChange={(e) => handleChange("taxOffice", e.target.value)}
            className="w-full input-detail"
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.customers.followedUser")}*</label>
          <Dropdown
            options={users}
            value={informationsInputs.followedUserId}
            onChange={(e) => handleChange("followedUserId", e.target.value)}
            optionLabel="name"
            optionValue="id"
            showClear
            className={`w-full input-detail ${
              errors.followedUserId && "input-error"
            }`}
          />
          {errors.followedUserId && (
            <small className="p-error">{errors.followedUserId}</small>
          )}
        </div>
        <div className="col-12">
          <label className="w-full">{t("pages.customers.notes")}</label>
          <InputTextarea
            value={informationsInputs.note}
            onChange={(e) => handleChange("note", e.target.value)}
            className="w-full input-detail"
          />
        </div>
      </div>
      <div className="grid">
        <Divider align="left">
          <div className="inline-flex items-center">
            <span className="font-semibold text-md">
              {t("pages.customers.addressInformation")}
            </span>
          </div>
        </Divider>
      </div>
      {/* address information */}
      <div className="grid p-0 m-0">
        {/* main office address */}
        <div className="md:col-6 col-12">
          <div className="grid">
            <div className="col-12">
              <span className="underline">
                {t("pages.customers.mainOfficeAddress")}
              </span>
            </div>
          </div>
          <div className="grid">
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.country")}*</label>
              <InputText
                value={informationsInputs.country}
                disabled
                onChange={(e) => handleChange("country", e.target.value)}
                className={`w-full input-detail ${
                  errors.country && "input-error"
                }`}
              />
              {errors.country && (
                <small className="p-error">{errors.country}</small>
              )}
            </div>
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.city")}*</label>
              <InputText
                value={informationsInputs.city}
                onChange={(e) => {
                  handleChange("city", e.target.value);
                }}
                className={`w-full input-detail ${
                  errors.city && "input-error"
                }`}
              />
              {errors.city && <small className="p-error">{errors.city}</small>}
            </div>
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.district")}</label>
              <InputText
                value={informationsInputs.districtName}
                onChange={(e) => handleChange("districtName", e.target.value)}
                className="w-full input-detail"
                maxLength={50}
              />
            </div>
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.postCode")}*</label>
              <InputText
                value={informationsInputs.postCode}
                onChange={(e) => handleChange("postCode", e.target.value)}
                className={`w-full input-detail ${
                  errors.postCode && "input-error"
                }`}
              />
              {errors.postCode && (
                <small className="p-error">{errors.postCode}</small>
              )}
            </div>
            <div className="flex flex-wrap col-12">
              <label>{t("pages.customers.address")}*</label>
              <InputTextarea
                value={informationsInputs.address}
                onChange={(e) => handleChange("address", e.target.value)}
                className={`w-full input-detail ${
                  errors.address && "input-error"
                }`}
              />
              {errors.address && (
                <small className="p-error">{errors.address}</small>
              )}
            </div>
            <div className="flex flex-wrap col-12">
              <label>{t("pages.customers.addressContinuation")}</label>
              <InputTextarea
                value={informationsInputs.addressContinuation}
                onChange={(e) =>
                  handleChange("addressContinuation", e.target.value)
                }
                className="w-full input-detail"
              />
            </div>
          </div>
          <div className="grid mt-3">
            <div className="flex items-center justify-end col-12">
              <label>{t("pages.customers.officeAndBillingAddressSame")}</label>
              <InputSwitch
                checked={sameAddress}
                onChange={(e) => dispatch(setSameAddress(e.value))}
                size="small"
                className="ml-2"
              />
            </div>
          </div>
        </div>
        {/* billing address */}
        <div className="md:col-6 col-12">
          <div className="grid">
            <div className="col-12">
              <span className="underline">
                {t("pages.customers.billingAddress")}
              </span>
            </div>
          </div>
          <div className="grid">
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.country")}*</label>
              <InputText
                value={
                  sameAddress
                    ? informationsInputs.country
                    : informationsInputs.billingCountry
                }
                onChange={(e) => handleChange("billingCountry", e.target.value)}
                className={`w-full input-detail ${
                  errors.billingCountry && !sameAddress && "input-error"
                }`}
                disabled
              />
              {errors.billingCountry && !sameAddress && (
                <small className="p-error">{errors.billingCountry}</small>
              )}
            </div>
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.city")}*</label>
              <InputText
                value={
                  sameAddress
                    ? informationsInputs.city
                    : informationsInputs.billingCity
                }
                onChange={(e) => {
                  if (!sameAddress) {
                    handleChange("billingCity", e.target.value);
                  }
                }}
                className={`w-full input-detail ${
                  errors.billingCity && !sameAddress && "input-error"
                }`}
                disabled={sameAddress}
              />
              {errors.billingCity && !sameAddress && (
                <small className="p-error">{errors.billingCity}</small>
              )}
            </div>
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.district")}</label>
              <InputText
                value={
                  sameAddress
                    ? informationsInputs.districtName
                    : informationsInputs.billingDistrict
                }
                onChange={(e) => {
                  if (!sameAddress) {
                    handleChange("billingDistrict", e.target.value);
                  }
                }}
                className="w-full input-detail"
                maxLength={50}
                disabled={sameAddress}
              />
            </div>
            <div className="flex flex-wrap col-6">
              <label>{t("pages.customers.postCode")}*</label>
              <InputText
                value={
                  sameAddress
                    ? informationsInputs.postCode
                    : informationsInputs.billingPostCode
                }
                onChange={(e) => {
                  if (!sameAddress) {
                    handleChange("billingPostCode", e.target.value);
                  }
                }}
                className={`w-full input-detail ${
                  errors.billingPostCode && !sameAddress && "input-error"
                }`}
                disabled={sameAddress}
              />
              {errors.billingPostCode && !sameAddress && (
                <small className="p-error">{errors.billingPostCode}</small>
              )}
            </div>
            <div className="flex flex-wrap col-12">
              <label>{t("pages.customers.address")}*</label>
              <InputTextarea
                value={
                  sameAddress
                    ? informationsInputs.address
                    : informationsInputs.billingAddress
                }
                onChange={(e) => {
                  if (!sameAddress) {
                    handleChange("billingAddress", e.target.value);
                  }
                }}
                className={`w-full input-detail ${
                  errors.billingAddress && !sameAddress && "input-error"
                }`}
                disabled={sameAddress}
              />
              {errors.billingAddress && !sameAddress && (
                <small className="p-error">{errors.billingAddress}</small>
              )}
            </div>
            <div className="flex flex-wrap col-12">
              <label>{t("pages.customers.addressContinuation")}</label>
              <InputTextarea
                value={
                  sameAddress
                    ? informationsInputs.addressContinuation
                    : informationsInputs.billingAddressContinuation
                }
                onChange={(e) => {
                  if (!sameAddress) {
                    handleChange("billingAddressContinuation", e.target.value);
                  }
                }}
                className="w-full input-detail"
                disabled={sameAddress}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid">
        <div className="flex justify-end col-12">
          <Button
            size="small"
            label={t("global.saveAndContinue")}
            icon="pi pi-check"
            onClick={(e) => handleSave(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default Informations;
