import { gql } from '@apollo/client';

export const GET_VEHICLES = gql`
   query getVehicles(
      $first: Int
      $skip: Int
      $search: String
      $supplierCustomerId: ID
      $vehicleFuelKindId: ID
      $vehicleGroupId: ID
      $vehicleKindId: ID
      $isDeleted: Boolean
   ) {
      vehicleCount(
         search: $search
         filters: {
            supplierCustomerId: $supplierCustomerId
            vehicleFuelKindId: $vehicleFuelKindId
            vehicleGroupId: $vehicleGroupId
            vehicleKindId: $vehicleKindId
            isDeleted: $isDeleted
         }
      )
      vehicles(
         first: $first
         skip: $skip
         search: $search
         filters: {
            supplierCustomerId: $supplierCustomerId
            vehicleFuelKindId: $vehicleFuelKindId
            vehicleGroupId: $vehicleGroupId
            vehicleKindId: $vehicleKindId
            isDeleted: $isDeleted
         }
      ) {
         brand
         deport
         id
         isActive
         note
         isDeleted
         machineCode
         model
         width
         weight
         length
         serialNumber
         maxWarehouse
         height
         supplierCustomerId
         supplierCustomer {
            companyName
            id
         }

         vehicleFuelKind {
            id
            name
         }
         vehicleGroup {
            id
            name
         }
         vehicleKind {
            id
            name
         }
         year
         guaranteeNextDate
         guaranteeDeliveryDate
         guaranteeNextRevision
         guaranteePeriod
         guaranteeRemainingPeriod
      }
   }
`;

export const UPDATE_VEHICLE = gql`
   mutation updateVehicle(
      $id: ID!
      $brand: String
      $deport: String
      $guaranteeDeliveryDate: Date
      $guaranteeNextDate: Date
      $guaranteeNextRevision: Date
      $guaranteePeriod: Int
      $guaranteeRemainingPeriod: Date
      $length: Decimal
      $machineCode: String!
      $maxWarehouse: Decimal
      $model: String
      $note: String
      $serialNumber: String
      $supplierCustomerId: ID
      $weight: Decimal
      $width: Decimal
      $height: Decimal
      $year: String
      $vehicleFuelKindId: ID!
      $vehicleGroupId: ID!
      $vehicleKindId: ID!
   ) {
      updateVehicle(
         data: {
            id: $id
            brand: $brand
            deport: $deport
            guaranteeDeliveryDate: $guaranteeDeliveryDate
            guaranteeNextDate: $guaranteeNextDate
            guaranteeNextRevision: $guaranteeNextRevision
            guaranteePeriod: $guaranteePeriod
            guaranteeRemainingPeriod: $guaranteeRemainingPeriod
            length: $length
            machineCode: $machineCode
            maxWarehouse: $maxWarehouse
            model: $model
            note: $note
            serialNumber: $serialNumber
            supplierCustomerId: $supplierCustomerId
            weight: $weight
            width: $width
            height: $height
            year: $year
            vehicleFuelKindId: $vehicleFuelKindId
            vehicleGroupId: $vehicleGroupId
            vehicleKindId: $vehicleKindId
         }
      ) {
         vehicle {
            brand
            guaranteeDeliveryDate
            guaranteeNextDate
            guaranteeNextRevision
            guaranteePeriod
            guaranteeRemainingPeriod
            machineCode
            serialNumber
            year
            id
            weight
            width
            height
            model
            length
            maxWarehouse
            deport
            vehicleKind {
               id
               name
            }
            vehicleGroup {
               id
               name
            }
            vehicleFuelKind {
               id
               name
            }
            note
            supplierCustomerId
            supplierCustomer {
               companyName
               id
            }
         }
      }
   }
`;

export const DELETE_VEHICLES = gql`
   mutation deleteVehicles($id: [Int!]!) {
      deleteVehicles(ids: $id) {
         success
      }
   }
`;

export const GET_VEHICLE_GROUPS = gql`
   query vehicleGroups($first: Int, $skip: Int, $isDeleted: Boolean, $vehicleFuelKindId: ID, $vehicleGroupId: ID, $nextVgp: Boolean, $haveVehicles: Boolean) {
      vehicleGroups(
         filters: { isSupplier: false, vehicleFuelKindId: $vehicleFuelKindId, isDeleted: $isDeleted, vehicleGroupId: $vehicleGroupId }
         nextVgp: $nextVgp
         haveVehicles: $haveVehicles
         first: $first
         skip: $skip
      ) {
         id
         name
         supplierId
         vehicles(filters: { vehicleFuelKindId: $vehicleFuelKindId, isDeleted: $isDeleted }, nextVgp: $nextVgp) {
            brand
            deport
            id
            isActive
            note
            isDeleted
            machineCode
            machineFullCode
            model
            width
            weight
            length
            serialNumber
            maxWarehouse
            height
            supplierCustomerId
            supplierCustomer {
               companyName
               id
            }
            vehicleFuelKind {
               id
               name
            }
            vehicleGroup {
               id
               name
            }
            vehicleKind {
               id
               name
            }
            vehicleReservations {
               id
               vehicle {
                  id
                  machineFullCode
               }
               status
            }
            vehicleMalfunctions {
               vehicle {
                  id
               }
               id
               isCompleted
            }
            year
            guaranteeNextDate
            guaranteeDeliveryDate
            guaranteeNextRevision
            guaranteePeriod
            guaranteeRemainingPeriod
         }
      }
      vehicleGroupCount(
         filters: { isSupplier: false, vehicleFuelKindId: $vehicleFuelKindId, isDeleted: $isDeleted, vehicleGroupId: $vehicleGroupId }
         nextVgp: $nextVgp
         haveVehicles: $haveVehicles
      )
      vehicle_number: vehicleCount(filters: { vehicleKindId: "1", isSupplier: false, isDeleted: false })
      available_vehicles: vehicleCount(filters: { status: AVAILABLE, vehicleKindId: "1", isSupplier: false, isDeleted: false })
      malfunction_vehicles: vehicleCount(filters: { status: MALFUNCTION, vehicleKindId: "1", isSupplier: false, isDeleted: false })
      rent_vehicles: vehicleCount(filters: { status: RENT, vehicleKindId: "1", isSupplier: false, isDeleted: false })
   }
`;

export const GET_VEHICLE_FUEL_KINDS = gql`
   query getVehicleFuelKinds {
      vehicleFuelKindCount
      vehicleFuelKinds(isDeleted: false) {
         id
         name
      }
   }
`;

export const GET_SUPPLIERS = gql`
   query getSuppliers($customerKindId: ID = 3) {
      customers(filters: { customerKindId: $customerKindId, isDeleted: false, isActive: true }) {
         id
         companyName
      }
   }
`;

export const CREATE_SUPPLIER = gql`
   mutation createSupplier($customerKindId: ID = 3, $email: String!, $companyName: String!, $phone: String!, $idNumber: String!) {
      createCustomer(data: { customerKindId: $customerKindId, email: $email, companyName: $companyName, phone: $phone, idNumber: $idNumber }) {
         customer {
            id
         }
      }
   }
`;

export const NEW_MACHINE_SCREEN = gql`
   query newMachineScreenRequirements {
      vehicleFuelKinds(isDeleted: false) {
         id
         name
      }
      vehicleGroups(isDeleted: false) {
         id
         name
         vehicleKind {
            id
            name
         }
      }
      vehicleKinds {
         id
         name
      }
   }
`;

export const GET_VEHICLE_KINDS = gql`
   query vehicleKinds {
      vehicleKinds {
         id
         name
      }
   }
`;

export const CREATE_VEHICLE = gql`
   mutation createVehicle(
      $brand: String
      $deport: String
      $guaranteeDeliveryDate: Date
      $guaranteeNextDate: Date
      $guaranteeNextRevision: Date
      $guaranteePeriod: Int
      $guaranteeRemainingPeriod: Date
      $length: Decimal
      $machineCode: String!
      $maxWarehouse: Decimal
      $model: String
      $note: String
      $serialNumber: String
      $supplierCustomerId: ID
      $weight: Decimal
      $width: Decimal
      $height: Decimal
      $year: String
      $vehicleFuelKindId: ID!
      $vehicleGroupId: ID!
      $vehicleKindId: ID!
   ) {
      createVehicle(
         data: {
            vehicleKindId: $vehicleKindId
            vehicleGroupId: $vehicleGroupId
            machineCode: $machineCode
            vehicleFuelKindId: $vehicleFuelKindId
            brand: $brand
            deport: $deport
            guaranteeDeliveryDate: $guaranteeDeliveryDate
            guaranteeNextRevision: $guaranteeNextRevision
            guaranteeNextDate: $guaranteeNextDate
            guaranteePeriod: $guaranteePeriod
            guaranteeRemainingPeriod: $guaranteeRemainingPeriod
            length: $length
            maxWarehouse: $maxWarehouse
            model: $model
            note: $note
            serialNumber: $serialNumber
            supplierCustomerId: $supplierCustomerId
            weight: $weight
            width: $width
            year: $year
            height: $height
         }
      ) {
         vehicle {
            brand
            guaranteeDeliveryDate
            guaranteeNextDate
            guaranteeNextRevision
            guaranteePeriod
            guaranteeRemainingPeriod
            machineCode
            serialNumber
            year
            id
            weight
            width
            height
            model
            length
            maxWarehouse
            deport
            vehicleKind {
               id
               name
            }
            vehicleGroup {
               id
               name
            }
            vehicleFuelKind {
               id
               name
            }
            note
            supplierCustomerId
            supplierCustomer {
               companyName
               id
            }
         }
      }
   }
`;

export const CREATE_VEHICLE_GROUP = gql`
   mutation createVehicleGroup($vehicleKindId: ID!, $name: String!, $supplierId: ID, $description: String) {
      createVehicleGroup(data: { vehicleKindId: $vehicleKindId, name: $name, supplierId: $supplierId, description: $description }) {
         vehicleGroup {
            id
            name
            description
         }
      }
   }
`;

export const GET_MACHINE_GROUPS = gql`
   query getMachineGroups($id: Int, $isSupplier: Boolean, $supplierId: ID, $haveSupplier: Boolean) {
      vehicleGroups(vehicleKindId: $id, filters: { isSupplier: $isSupplier }, supplierId: $supplierId, haveSupplier: $haveSupplier, isDeleted: false) {
         id
         name
         description
         vehicles(filters: { isSupplier: $isSupplier }) {
            supplierCustomer {
               companyName
            }
         }
      }
   }
`;

export const GET_ALL_MACHINE_GROUPS = gql`
   query getMachineGroups {
      vehicleGroups(filters: { isDeleted: false }) {
         id
         name
      }
   }
`;

export const CREATE_VEHICLE_DOCUMENT = gql`
   mutation createVehicleDocument($vehicleId: ID!, $document: Upload!) {
      createVehicleDocument(data: { vehicleId: $vehicleId, document: $document }) {
         vehicleDocument {
            id
            vehicleId
         }
      }
   }
`;

export const GET_VEHICLE_DOCUMENTS = gql`
   query getVehicleDocuments($vehicleId: ID!) {
      vehicleDocuments(vehicleId: $vehicleId) {
         id
         vehicleId
         vehicle {
            id
         }
         document {
            name
            path
            size
            url
         }
      }
   }
`;

export const DELETE_VEHICLE_DOCUMENT = gql`
   mutation deleteVehicleDocuments($ids: [Int!]!) {
      deleteVehicleDocuments(ids: $ids) {
         success
      }
   }
`;

export const GET_VEHICLE = gql`
   query getVehicle($id: ID!) {
      vehicle(id: $id) {
         brand
         guaranteeDeliveryDate
         guaranteeNextDate
         guaranteeNextRevision
         guaranteePeriod
         guaranteeRemainingPeriod
         machineCode
         serialNumber
         year
         id
         weight
         width
         height
         model
         length
         maxWarehouse
         deport
         vehicleKind {
            id
            name
         }
         vehicleGroup {
            id
            name
         }
         vehicleFuelKind {
            id
            name
         }
         note
         supplierCustomerId
         supplierCustomer {
            companyName
            id
         }
      }
   }
`;

export const DELETE_VEHICLE_GROUPS = gql`
   mutation deleteVehicleGroups($ids: [Int!]!) {
      deleteVehicleGroups(ids: $ids) {
         success
      }
   }
`;

export const GET_VEHICLE_STATUSES = gql`
   query vehicleStatuses {
      vehicleStatuses
   }
`;

export const UPDATE_VEHICLE_GROUP = gql`
   mutation updateVehicleGroup($data: UpdateVehicleGroupInput!) {
      updateVehicleGroup(data: $data) {
         vehicleGroup {
            id
            name
            description
         }
      }
   }
`;
