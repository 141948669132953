import { gql } from '@apollo/client';

export const GET_VEHICLE_RESERVATIONS = gql`
   query vehicleReservations($date: Date, $endDate: Date, $statuses: [ReservationStatus!]) {
      withStatuses: vehicleReservations(date: $date, endDate: $endDate, statuses: $statuses) {
         id
         status
         isDelivered
         isPickuppByMetaloc
         customer {
            id
            companyName
         }
         orderItems {
            id
            category
            order {
               id
               orderNumber
               departureTransporterOrder {
                  id
                  transporterOrderNumber
               }
               address {
                  id
                  placeName
                  address
                  city
               }
            }
         }
         vehicle {
            id
            machineCode
            machineFullCode
            supplierCustomerId
         }
         beginDate
         endDate
      }
      withPickupByMetaloc: vehicleReservations(isContracted: true, filters: { isPickuppByMetaloc: true }) {
         id
         status
         isDelivered
         isPickuppByMetaloc
         customer {
            id
            companyName
         }
         orderItems {
            id
            category
            order {
               id
               orderNumber
               departureTransporterOrder {
                  id
                  transporterOrderNumber
               }
               address {
                  id
                  placeName
                  address
                  city
               }
            }
         }
         vehicle {
            id
            machineCode
            machineFullCode
            supplierCustomerId
         }
         beginDate
         endDate
      }
   }
`;

export const GET_VEHICLE_RESERVATIONS_RETURN = gql`
   query vehicleReservations($date: Date, $endDate: Date, $statuses: [ReservationStatus!]) {
      vehicleReservations(date: $date, endDate: $endDate, statuses: $statuses, isContracted: true) {
         id
         status
         isDelivered
         isPickuppByMetaloc
         isInvoiced
         customer {
            id
            companyName
         }
         orderItems {
            id
            category
            order {
               id
               orderNumber
               departureTransporterOrder {
                  id
                  transporterOrderNumber
               }
               address {
                  id
                  placeName
                  address
                  city
                  receiverName
               }
            }
         }
         vehicle {
            id
            machineCode
            machineFullCode
            supplierCustomerId
         }
         beginDate
         endDate
      }
   }
`;

export const UPDATE_VEHICLE_RESERVATION = gql`
   mutation updateVehicleReservation($data: UpdateVehicleReservationInput!) {
      updateVehicleReservation(data: $data) {
         vehicleReservation {
            id
            isDelivered
            status
            beginDate
            endDate
            deliverDriver
            returnDriver
            comment
         }
      }
   }
`;

export const CANCEL_OFFER_WITH_RESERVATIONS = gql`
   mutation updateOrder($data: UpdateOrderInput!) {
      updateOrder(data: $data) {
         order {
            id
            orderItems {
               id
               category
               vehicleReservationId
            }
         }
      }
   }
`;

export const CREATE_INVOICE = gql`
   mutation createInvoice($data: CreateInvoiceInput!) {
      createInvoice(data: $data) {
         invoice {
            id
         }
      }
   }
`;

export const GET_VEHICLE_RESERVATION = gql`
   query vehicleReservation($id: ID!) {
      vehicleReservation(id: $id) {
         id
         vehicleGroup {
            id
            name
         }
         vehicle {
            id
            note
            machineCode
            machineFullCode
            supplierCustomer {
               id
            }
         }
         beginDate
         endDate
         price
         isEco
         ecoPrice
         isInsurance
         insuranceRate
         isIncludingSaturdays
         isIncludingSundays
         isIncludingPublicHolidays
         isPickuppByMetaloc
         deliveryFee
         pickupFee
         taxFee
         tax
         discountRate
         isInvoiced
      }
   }
`;

export const GET_VEHICLE_RESERVATIONS_FOR_TOURS = gql`
   query vehicleReservations($date: Date, $statuses: [ReservationStatus!], $isContracted: Boolean, $isSupplier: Boolean, $isNext: Boolean) {
      vehicleReservations(date: $date, statuses: $statuses, isContracted: $isContracted, isSupplier: $isSupplier, isNext: $isNext) {
         id
         isDelivered
         beginDate
         endDate
         status
         vehicleReservationControlId
         vehicleGroup {
            id
            name
         }
         vehicle {
            id
            note
            machineFullCode
            guaranteeNextDate
            guaranteeNextRevision
         }
         orderItems {
            id
            description
            order {
               id
               orderNumber
               description
               address {
                  id
                  city
                  postCode
                  country
                  receiverName
                  address
                  placeName
               }
            }
         }
         customer {
            id
            companyName
         }
         deliverDriver
         returnDriver
         comment
      }
   }
`;

export const GET_ITEM_TRANSPORTATIONS = gql`
   query itemTransportations($isNext: Boolean) {
      itemTransportations(isNext: $isNext) {
         id
         customer
         contractNo
         deliveryAddress
         contact
         description
         machineCode
         comment
         driver
         status
      }
   }
`;

export const CREATE_ITEM_TRANSPORTATION = gql`
   mutation createItemTransportationInput($data: CreateItemTransportationInput!) {
      createItemTransportation(data: $data) {
         itemTransportation {
            id
            customer
            contractNo
            deliveryAddress
            contact
            description
            machineCode
            comment
            driver
            status
         }
      }
   }
`;

export const DELETE_ITEM_TRANSPORTATION = gql`
   mutation deleteItemTransportations($id: Int!) {
      deleteItemTransportations(id: $id) {
         success
      }
   }
`;

export const UPDATE_ITEM_TRANSPORTATION = gql`
   mutation updateItemTransportation($data: UpdateItemTransportationInput!) {
      updateItemTransportation(data: $data) {
         itemTransportation {
            id
            customer
            contractNo
            deliveryAddress
            contact
            description
            machineCode
            comment
            driver
            status
         }
      }
   }
`;

export const UPDATE_ADDRESS = gql`
   mutation updateAddress($data: UpdateAddressInput!) {
      updateAddress(data: $data) {
         address {
            id
            city
            postCode
            country
            receiverName
            address
            placeName
         }
      }
   }
`;

export const UPDATE_VEHICLE_RESERVATION_DRIVER = gql`
   mutation updateVehicleReservation($data: UpdateVehicleReservationInput!) {
      updateVehicleReservation(data: $data) {
         vehicleReservation {
            id
            beginDate
            endDate
            status
            vehicleReservationControlId
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
               guaranteeNextDate
               guaranteeNextRevision
            }
            orderItems {
               id
               order {
                  id
                  orderNumber
                  description
                  address {
                     id
                     city
                     country
                     receiverName
                     address
                     postCode
                     placeName
                  }
               }
            }
            customer {
               id
               companyName
            }
            deliverDriver
            returnDriver
            comment
         }
      }
   }
`;

export const RE_RENT = gql`
   mutation reRent($vehicleReservationId: ID!, $endDate: Date!) {
      reRent(vehicleReservationId: $vehicleReservationId, endDate: $endDate) {
         vehicleReservation {
            id
            status
            isDelivered
            isPickuppByMetaloc
            isInvoiced
            customer {
               id
               companyName
            }
            orderItems {
               id
               category
               order {
                  id
                  orderNumber
                  departureTransporterOrder {
                     id
                     transporterOrderNumber
                  }
                  address {
                     id
                     placeName
                     address
                     city
                  }
               }
            }
            vehicle {
               id
               machineCode
               machineFullCode
               supplierCustomerId
            }
            beginDate
            endDate
         }
      }
   }
`;
