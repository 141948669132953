import React, { useEffect, useState } from "react";

import routes from "../routes";

import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import { Button } from "primereact/button";
import { setToken, setUser } from "../store/slices/userSlice";
import { useDispatch } from "react-redux";

const Sidebar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showChilds, setShowChilds] = useState(null);

	useEffect(() => {
		if (!window.location.pathname.includes(showChilds)) {
			setShowChilds(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.pathname]);

	// each sidebar element
	const SidebarElement = (params) => {
		let isActive = false;
		if (!params.route.children && window.location.pathname.includes(params.route.path)) {
			isActive = true;
		}
		return (
			<Button
				outlined={isActive ? false : true}
				className="w-full my-1"
				label={t("global.sidebar." + params.route.name)}
				icon={"pi pi-" + params.route.icon}
				onClick={() => handleClick(params.route)}
				pt={{ root: "flex text-start", label: "ml-2" }}
				size="small"
			>
				{params.route.children ? (
					showChilds === params.route.name ? (
						<i className="pi pi-chevron-up" />
					) : (
						<i className="pi pi-chevron-down" />
					)
				) : null}
			</Button>
		);
	};
	const SidebarParent = (params) => {
		return (
			<>
				<SidebarElement route={params.route} />
				{showChilds === params.route.name
					? params.route.children?.map((child) => {
							return (
								<div key={child.name} className="ml-5">
									<SidebarElement route={child} />
								</div>
							);
					  })
					: null}
			</>
		);
	};
	const handleClick = (route) => {
		if (route.name === showChilds && !window.location.pathname.includes(route.name)) {
			setShowChilds(null);
			return 0;
		}
		if (route.children) {
			setShowChilds(route.name);
		} else {
			navigate(route.path.toString());
		}
	};
	return (
		<div className="w-full min-h-full h-auto flex flex-col justify-start">
			<div className="flex w-full justify-center items-center mt-3 mb-5">
				<img src={require("../assets/logo.png")} alt="logo" />
			</div>
			{routes[0].children
				.filter((route) => route.showOnSidebar)
				.map((route) => {
					if (route.children) {
						return <SidebarParent key={route.name} route={route} />;
					} else {
						return <SidebarElement key={route.name} route={route} />;
					}
				})}
			<Button
				text
				className="w-full mt-3"
				label={t("global.sidebar.logout")}
				icon="pi pi-sign-out"
				pt={{ root: "flex text-start", label: "ml-2" }}
				size="small"
				onClick={() => {
					dispatch(setUser({}));
					dispatch(setToken(""));
				}}
			/>
		</div>
	);
};

export default Sidebar;
