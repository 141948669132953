import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import store from '../store/store';
import { logout } from '../store/slices/userSlice';
import toast from 'react-hot-toast';
import { t } from 'i18next';

const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (networkError) {
      toast.error(t('global.networkError'));
   }
   if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
         console.log(error);
         if (error.message.toLowerCase().includes('token') || error.message.toLowerCase().includes('jeton')) {
            toast.error(t('global.tokenExpired'));
            store.dispatch(logout());
         }
         if (error.message.includes('permission')) {
            toast.error(t('global.unauthorized'));
         }
         if (error.message.includes('Duplicate entry')) {
            return;
         }
         if (error.message.includes('2006')) {
            toast.error('Database error! Please try again later.');
            store.dispatch(logout());
         }
         if (error.message.includes('Refund amount cannot exceed')) {
            return;
         } else {
            toast.error(t('global.unexpectedError'));
         }
      });
   }
});

const httpLink = createUploadLink({
   uri: process.env.REACT_APP_LINK,
});

const setAuthorizationLink = setContext((_, { headers }) => {
   const token = store.getState().user.token;
   return {
      headers: {
         ...headers,
         authorization: token ? `${token}` : '',
      },
   };
});

const client = new ApolloClient({
   link: setAuthorizationLink.concat(errorLink.concat(httpLink)),
   defaultOptions: {
      query: {
         fetchPolicy: 'network-only',
      },
   },
   cache: new InMemoryCache({ addTypename: false }),
});

export default client;
