import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../components/TableComponent';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { getCustomerRefunds } from '../../../store/apis/Customers';
import moment from 'moment';
import { Button } from 'primereact/button';
import RefundPDF from '../../../components/PDFS/RefundPDF';

const Refunds = ({ customerData }) => {
   const { customerRefunds } = useSelector((state) => state.customer);
   const dispatch = useDispatch();
   const [updateRefundVisible, setUpdateRefundVisible] = useState({
      visible: false,
      data: null,
   });

   useEffect(() => {
      dispatch(getCustomerRefunds(customerData?.id));
   }, [customerData]);

   const tableColumns = [
      {
         field: 'creationDate',
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'refundNumber',
         header: t('pages.invoices.refundNumber'),
         field: 'refundNumber',
         body: (rowData) => {
            return <span>{rowData?.refundNumber}</span>;
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.orderNumber}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.customer.companyName}</span>;
         },
      },
      {
         name: 'dueDate',
         header: t('global.table.dueDate'),
         field: 'dueDate',
         body: (rowData) => {
            return <span>{rowData?.dueDate ? moment(rowData?.dueDate).format('DD/MM/YYYY') : '-'}</span>;
         },
      },
      {
         field: 'totalAfterTax',
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{parseFloat(+rowData?.price + (+rowData?.price * +rowData?.invoice?.tax) / 100).toFixed(2)}</span>;
         },
      },
      {
         field: 'isFactor',
         name: 'isFactor',
         header: t('global.table.factor'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.isFactor ? t('global.yes') : t('global.no')}</span>;
         },
      },
      {
         field: 'pdf',
         name: 'pdf',
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return <RefundPDF data={rowData} />;
         },
      },
   ];

   return (
      <>
         <div className="mt-10">
            <TableComponent data={customerRefunds || []} columns={tableColumns} />
         </div>
      </>
   );
};

export default Refunds;
