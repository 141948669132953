import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUnpaidInvoices } from '../../../../store/apis/ManagementApi';
import TableComponent from '../../../../components/TableComponent';
import { t } from 'i18next';
import moment from 'moment';
import { Button } from 'primereact/button';
import FollowUpList from '../../../../components/PDFS/FollowUpList';

const UnpaidInvoices = ({ unpaidInvoicesDialog, setUnpaidInvoicesDialog }) => {
   const dispatch = useDispatch();
   const [unpaidInvoices, setUnpaidInvoices] = useState([]);

   useEffect(() => {
      dispatch(getUnpaidInvoices()).then((res) => {
         setUnpaidInvoices(res);
      });
   }, []);

   const tableColumns = [
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            return <span>{rowData?.customer.companyName}</span>;
         },
      },
      {
         field: 'phone',
         name: 'phone',
         header: t('global.table.phoneNumber'),
         body: (rowData) => {
            return <span>{rowData?.customer.phone}</span>;
         },
      },
      {
         field: 'invoiceNumber',
         name: 'invoiceNumber',
         header: t('pages.invoices.invoiceNumber'),
         body: (rowData) => {
            return <span>{rowData?.invoiceNumber}</span>;
         },
      },
      {
         field: 'totalAfterTax',
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{rowData?.totalAfterTax}</span>;
         },
      },
      {
         field: 'refund',
         name: 'refund',
         header: t('pages.invoices.refund'),
         body: (rowData) => {
            return <span>{rowData?.refund || '-'}</span>;
         },
      },
      {
         field: 'unpaid',
         name: 'unpaid',
         header: t('pages.invoices.unpaid'),
         body: (rowData) => {
            return <span>€{rowData?.totalAfterTax}</span>;
         },
      },
      {
         field: 'placeName',
         name: 'placeName',
         header: t('global.table.placeName'),
         body: (rowData) => {
            return <span>{rowData?.address.placeName}</span>;
         },
      },

      {
         field: 'creationDate',
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'dueDate',
         name: 'dueDate',
         header: t('pages.invoices.dueDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.dueDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'user',
         name: 'user',
         header: t('global.table.user'),
         body: (rowData) => {
            return <span>{rowData?.customer?.followedUser?.name || '-'}</span>;
         },
      },
      {
         field: 'comment',
         name: 'comment',
         header: t('global.table.comment'),
      },
   ];

   return (
      <>
         <Dialog
            header={t('pages.invoices.unpaidInvoicesHeader') + ' ' + moment().add(-1, 'day').format('DD/MM/YYYY')}
            visible={unpaidInvoicesDialog}
            style={{ width: '80vw' }}
            onHide={() => setUnpaidInvoicesDialog(false)}
         >
            <div>
               <FollowUpList data={unpaidInvoices} />
            </div>
            <TableComponent data={unpaidInvoices} columns={tableColumns} scrollable={true} />
         </Dialog>
      </>
   );
};

export default UnpaidInvoices;
