import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
   query invoices($status: InvoiceStatus, $first: Int, $skip: Int, $search: String, $startDate: Date, $endDate: Date, $paymentMethod: PaymentMethod) {
      invoiceCount(status: $status, search: $search, startDate: $startDate, endDate: $endDate, paymentMethod: $paymentMethod, isParent: true)
      invoices(
         status: $status
         first: $first
         skip: $skip
         search: $search
         startDate: $startDate
         endDate: $endDate
         paymentMethod: $paymentMethod
         isParent: true
      ) {
         id
         status
         orderId
         orderNumber
         invoiceNumber
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         vehicleGroup {
            id
            name
         }
         vehicle {
            id
            machineFullCode
         }
         vehicleReservations {
            id
            price
            subTotal
         }
         description
         privacyDescription
         beginDate
         endDate
         price
         ecoPrice
         insuranceRate
         insurancePrice
         subTotal
         quantity
         deliveryFee
         pickupFee
         discount
         fuel
         dieselPrice
         totalBeforeTax
         tax
         totalAfterTax
         createdAt
         dueDate
         isFactor
         isSupplierInvoice
         isSupplierInvoiceArrived
         isPaid
         amountPaid
         paymentDate
         paymentMethod
         discountRate
         invoiceItems {
            id
            quantity
            price
         }
         parent {
            id
            invoiceNumber
         }
         children {
            id
            invoiceNumber
         }
      }
   }
`;

export const GET_INVOICE = gql`
   query invoice($id: ID!) {
      invoice(id: $id) {
         id
         status
         orderId
         orderNumber
         invoiceNumber
         referenceNumber
         customer {
            id
            companyName
         }
         address {
            id
            placeName
         }
         vehicleGroup {
            id
            name
         }
         vehicle {
            id
            machineFullCode
            note
         }
         invoiceItems {
            id
            category
            description
            quantity
            tax
            price
         }
         saleDescription
         description
         privacyDescription
         beginDate
         endDate
         price
         ecoPrice
         insuranceRate
         insurancePrice
         subTotal
         quantity
         deliveryFee
         pickupFee
         discount
         fuel
         dieselPrice
         totalBeforeTax
         tax
         totalAfterTax
         createdAt
         dueDate
         isFactor
         isIncludingSaturdays
         isIncludingSundays
         isIncludingPublicHolidays
         isEco
         isInsurance
         fiftyDiscount
         seventyDiscount
         oneHundredDiscount
         isSupplierInvoice
         isSupplierInvoiceArrived
         category
         quantity
         discountRate
         children {
            id
            status
            orderId
            orderNumber
            invoiceNumber
            referenceNumber
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
               note
            }
            invoiceItems {
               id
               category
               description
               quantity
               tax
               price
            }
            description
            privacyDescription
            beginDate
            endDate
            price
            ecoPrice
            insuranceRate
            insurancePrice
            subTotal
            quantity
            deliveryFee
            pickupFee
            discount
            fuel
            dieselPrice
            totalBeforeTax
            tax
            totalAfterTax
            createdAt
            dueDate
            isFactor
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isEco
            isInsurance
            fiftyDiscount
            seventyDiscount
            oneHundredDiscount
            isSupplierInvoice
            isSupplierInvoiceArrived
            category
            quantity
            discountRate
         }
      }
   }
`;

export const UPDATE_INVOICE = gql`
   mutation updateInvoice($data: UpdateInvoiceInput!) {
      updateInvoice(data: $data) {
         invoice {
            id
            status
            orderId
            orderNumber
            invoiceNumber
            referenceNumber
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
               note
            }
            invoiceItems {
               id
               category
               description
               quantity
               tax
               price
            }
            saleDescription
            description
            privacyDescription
            beginDate
            endDate
            price
            ecoPrice
            insuranceRate
            insurancePrice
            subTotal
            quantity
            deliveryFee
            pickupFee
            discount
            fuel
            dieselPrice
            totalBeforeTax
            tax
            totalAfterTax
            createdAt
            dueDate
            isFactor
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isEco
            isInsurance
            fiftyDiscount
            seventyDiscount
            oneHundredDiscount
            isSupplierInvoice
            isSupplierInvoiceArrived
            isPaid
            category
            quantity
            discountRate
            parent {
               id
               status
               orderId
               orderNumber
               invoiceNumber
               referenceNumber
               customer {
                  id
                  companyName
               }
               address {
                  id
                  placeName
               }
               vehicleGroup {
                  id
                  name
               }
               vehicle {
                  id
                  machineFullCode
                  note
               }
               invoiceItems {
                  id
                  category
                  description
                  quantity
                  tax
                  price
               }
               description
               privacyDescription
               beginDate
               endDate
               price
               ecoPrice
               insuranceRate
               insurancePrice
               subTotal
               quantity
               deliveryFee
               pickupFee
               discount
               fuel
               dieselPrice
               totalBeforeTax
               tax
               totalAfterTax
               createdAt
               dueDate
               isFactor
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               isEco
               isInsurance
               fiftyDiscount
               seventyDiscount
               oneHundredDiscount
               isSupplierInvoice
               isSupplierInvoiceArrived
               category
               quantity
               discountRate
            }
            children {
               id
            }
         }
      }
   }
`;

export const CREATE_INVOICE_ITEM = gql`
   mutation createOrderItem($data: CreateOrderItemInput!) {
      createOrderItem(data: $data) {
         orderItem {
            id
            category
            description
            quantity
            tax
            price
         }
      }
   }
`;

export const CREATE_MONTHS_INVOICE = gql`
   mutation createMonthsInvoice($isPrev: Boolean) {
      createMonthsInvoice(isPrev: $isPrev) {
         invoices {
            id
            status
            orderId
            orderNumber
            invoiceNumber
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
            }
            description
            privacyDescription
            beginDate
            endDate
            price
            ecoPrice
            insuranceRate
            insurancePrice
            subTotal
            quantity
            deliveryFee
            pickupFee
            discount
            fuel
            dieselPrice
            totalBeforeTax
            tax
            totalAfterTax
            createdAt
            dueDate
            isFactor
            isSupplierInvoice
            isSupplierInvoiceArrived
            isPaid
            amountPaid
            paymentDate
            paymentMethod
            discountRate
            parent {
               id
            }
         }
      }
   }
`;

export const DELETE_INVOICE = gql`
   mutation deleteInvoices($id: Int!) {
      deleteInvoices(id: $id) {
         success
      }
   }
`;

export const PAY_INVOICES = gql`
   mutation payInvoices($data: PayInvoiceInput!) {
      payInvoices(data: $data) {
         invoices {
            id
            isPaid
            paymentDate
            paymentMethod
            amountPaid
         }
      }
   }
`;

export const GET_CUSTOMER_INVOICES = gql`
   query invoices($customerId: ID, $status: InvoiceStatus, $search: String) {
      invoices(customerId: $customerId, status: $status, isPaid: false, search: $search) {
         id
         isPaid
         status
         invoiceNumber
         totalAfterTax
         dueDate
      }
   }
`;

export const CREATE_MONTH_END_INVOICE = gql`
   mutation createMonthEndInvoice {
      createMonthEndInvoice {
         invoices {
            id
            status
            orderId
            orderNumber
            invoiceNumber
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
            }
            description
            privacyDescription
            beginDate
            endDate
            price
            ecoPrice
            insuranceRate
            insurancePrice
            subTotal
            quantity
            deliveryFee
            pickupFee
            discount
            fuel
            dieselPrice
            totalBeforeTax
            tax
            totalAfterTax
            createdAt
            dueDate
            isFactor
            isSupplierInvoice
            isSupplierInvoiceArrived
            isPaid
            amountPaid
            paymentDate
            paymentMethod
            discountRate
            parent {
               id
            }
         }
      }
   }
`;

export const UNINVOICED_CONTRACTS = gql`
   query vehicleReservations {
      vehicleReservations(isInvoiced: false, isPrev: true, isContracted: true) {
         id
         beginDate
         endDate
         isInvoiced
         orderItems {
            id
            order {
               id
               orderNumber
               address {
                  id
                  placeName
               }
            }
         }
         customer {
            id
            companyName
         }
         vehicle {
            id
         }
      }
   }
`;

export const CREATE_INVOICE = gql`
   mutation createInvoice($data: CreateInvoiceInput!) {
      createInvoice(data: $data) {
         invoice {
            id
         }
      }
   }
`;

export const GET_UNPAID_INVOICES = gql`
   query invoices {
      invoices(isPaid: false, isPrevDueDate: true) {
         id
         customer {
            id
            companyName
            phone
            followedUser {
               id
               name
            }
         }
         invoiceNumber
         totalAfterTax
         address {
            id
            placeName
         }
         createdAt
         dueDate
      }
   }
`;

export const INVOICE_STATISTICS = gql`
   query invoiceStatistics {
      invoiceStatistics
   }
`;

export const UPDATE_VEHICLE_STATUS = gql`
   mutation updateVehicleStatus($data: UpdateVehicleInput!) {
      updateVehicle(data: $data) {
         vehicle {
            id
            status
         }
      }
   }
`;

export const GET_REFUNDS = gql`
   query refunds($first: Int, $skip: Int) {
      refundCount
      refunds(first: $first, skip: $skip) {
         id
         description
         refundNumber
         price
         dueDate
         isPaid
         invoice {
            id
            createdAt
            tax
            invoiceNumber
            orderNumber
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            dueDate
            totalBeforeTax
            totalAfterTax
            createdAt
            isFactor
            isPaid
            isSupplierInvoice
            isSupplierInvoiceArrived
         }
      }
   }
`;

export const UPDATE_REFUND = gql`
   mutation updateRefund($data: UpdateRefundInput!) {
      updateRefund(data: $data) {
         refund {
            id
            description
         }
      }
   }
`;

export const PAY_REFUNDS = gql`
   mutation payRefunds($data: PayRefundInput!) {
      payRefunds(data: $data) {
         refunds {
            id
            description
            price
            refundNumber
            createdAt
            dueDate
            isPaid
            invoice {
               createdAt
               invoiceNumber
               orderNumber
               customer {
                  id
                  companyName
               }
               dueDate
               totalAfterTax
               paymentDate
               isFactor
               tax
            }
         }
      }
   }
`;

export const DELETE_ORDER_ITEMS = gql`
   mutation deleteOrderItems($ids: [Int!]!, $invoiceId: ID = null) {
      deleteOrderItems(ids: $ids, invoiceId: $invoiceId) {
         success
      }
   }
`;
