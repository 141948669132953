import { t } from 'i18next';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerInvoices, handlePayInvoices, handlePayRefunds } from '../../../../store/apis/ManagementApi';
import toast from 'react-hot-toast';
import { getCustomerRefunds } from '../../../../store/apis/Customers';

const PayInvoices = ({ payInvoice, setPayInvoice }) => {
   const { customerInvoices } = useSelector((state) => state.management);
   const { customerRefunds } = useSelector((state) => state.customer);
   const dispatch = useDispatch();
   const paymentMethods = [
      { id: 'BANK_WIRE', name: t('pages.invoices.bankWire') },
      { id: 'CHECK', name: t('pages.invoices.check') },
      { id: 'CREDIT_CARD', name: t('pages.invoices.creditCard') },
      { id: 'CEMECA', name: t('pages.invoices.cameca') },
      { id: 'CPT', name: t('pages.invoices.cpt') },
      { id: 'DIRECT_DEBIT', name: t('pages.invoices.directDebit') },
      { id: 'LCR', name: t('pages.invoices.lcr') },
      { id: 'JUDICIAL_LIQUIDATION', name: t('pages.invoices.judicialLiquidation') },
   ];
   const [errors, setErrors] = useState({});
   const [searchInvoiceNumber, setSearchInvoiceNumber] = useState('');
   const [payInvoiceInputs, setPayInvoiceInputs] = useState({
      ids: [],
      paymentDate: null,
      paymentMethod: null,
   });
   const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
   const [selectedInvoices, setSelectedInvoices] = useState([]);
   const [mergedInvoices, setMergedInvoices] = useState([]);

   useEffect(() => {
      if (customerInvoices && customerRefunds) {
         //merge invoices and refunds with options
         const refunds = customerRefunds.map((i) => ({
            id: i.id,
            isPaid: i.isPaid,
            status: 'REFUNDED',
            invoiceNumber: i.invoice.invoiceNumber,
            totalAfterTax: (parseFloat(i?.price) + (i?.price * i?.invoice?.tax) / 100).toFixed(2),
            dueDate: i.dueDate,
         }));
         setMergedInvoices([...customerInvoices, ...refunds]);
      }
   }, [customerInvoices, customerRefunds]);

   useEffect(() => {
      if (payInvoice.data) {
         const id = payInvoice.data.customer.id;
         dispatch(getCustomerInvoices(id, 'INVOICED'));
         dispatch(getCustomerRefunds(id));
      }
   }, [payInvoice.data]);

   useEffect(() => {
      if (selectedInvoices.length > 0) {
         const totalAmount = selectedInvoices.reduce((acc, item) => acc + parseFloat(item.totalAfterTax), 0);
         setTotalInvoiceAmount(totalAmount);
      } else {
         setTotalInvoiceAmount(0);
      }
   }, [selectedInvoices]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      setPayInvoiceInputs({ ...payInvoiceInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['paymentDate', 'paymentMethod'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!payInvoiceInputs[input]) {
            updateErrors(input, payInvoiceInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const saveItems = () => {
      if (!validateForm()) return;
      const dataForInvoice = {
         ...payInvoiceInputs,
         paymentDate: moment(payInvoiceInputs.paymentDate).format('YYYY-MM-DD'),
         ids: selectedInvoices.filter((i) => i.status === 'INVOICED').map((i) => i.id),
      };
      const dataForRefund = {
         ...payInvoiceInputs,
         paymentDate: moment(payInvoiceInputs.paymentDate).format('YYYY-MM-DD'),
         ids: selectedInvoices.filter((i) => i.status === 'REFUNDED').map((i) => i.id),
      };
      if (dataForInvoice.ids.length > 0) {
         dispatch(handlePayInvoices(dataForInvoice)).then(() => {
            toast.success(t('pages.invoices.payInvoiceSuccess'));
            setPayInvoice({
               visible: false,
               data: null,
            });
         });
      }
      if (dataForRefund.ids.length > 0) {
         dispatch(handlePayRefunds(dataForRefund)).then(() => {
            toast.success(t('pages.invoices.payRefundSuccess'));
            setPayInvoice({
               visible: false,
               data: null,
            });
         });
      }
   };

   const tableColumns = [
      {
         name: 'select',
         selectionMode: 'multiple',
      },
      {
         field: 'invoiceNumber',
         name: 'invoiceNumber',
         header: t('pages.invoices.invoiceNumber'),
         body: (rowData) => {
            return <span>{rowData.invoiceNumber}</span>;
         },
      },
      {
         field: 'type',
         name: 'type',
         header: t('global.table.type'),
         body: (rowData) => {
            return <span>{rowData.status === 'INVOICED' ? t('pages.invoices.invoice') : t('pages.invoices.refund')}</span>;
         },
      },
      {
         field: 'totalAfterTax',
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{rowData?.totalAfterTax}</span>;
         },
      },
      {
         field: 'dueDate',
         name: 'dueDate',
         header: t('pages.invoices.dueDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.dueDate).format('DD/MM/YYYY')}</span>;
         },
      },
   ];

   return (
      <>
         <Dialog
            header={t('pages.invoices.payInvoicesHeader') + ' ' + payInvoice.data?.customer?.companyName}
            visible={payInvoice.visible}
            style={{ width: '50vw' }}
            onHide={() =>
               setPayInvoice({
                  visible: false,
                  data: null,
               })
            }
         >
            <div className="grid">
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.paymentDate')}</label>
                  <Calendar value={payInvoiceInputs.paymentDate} onChange={(e) => handleInputChange(e, 'paymentDate')} className="w-full input-detail" />
                  {errors.paymentDate && <small className="p-error">{errors.paymentDate}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.paymentMethod')}</label>
                  <Dropdown
                     options={paymentMethods}
                     value={payInvoiceInputs.paymentMethod}
                     onChange={(e) => handleInputChange(e, 'paymentMethod')}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                  />
                  {errors.paymentMethod && <small className="p-error">{errors.paymentMethod}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.invoices.amountPaid')}</label>
                  <InputText className="w-full p-inputtext-sm input-detail" name="search" value={totalInvoiceAmount} disabled />
                  {errors.amountPaid && <small className="p-error">{errors.amountPaid}</small>}
               </div>
               <div className="col-12">
                  <hr />
               </div>
               <div className="m-auto col-4">
                  <InputText
                     className="w-full p-inputtext-sm input-detail"
                     name="search"
                     value={searchInvoiceNumber}
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           const id = payInvoice.data.customer.id;
                           dispatch(getCustomerInvoices(id, 'INVOICED', searchInvoiceNumber));
                        }
                     }}
                     onChange={(e) => setSearchInvoiceNumber(e.target.value)}
                     placeholder={t('global.search')}
                  />
               </div>

               <div className="col-12">
                  <TableComponent
                     data={mergedInvoices && mergedInvoices.filter((i) => i.isPaid === false)}
                     columns={tableColumns}
                     scrollable={true}
                     selectionMode="checkbox"
                     selection={selectedInvoices}
                     onSelectionChange={(e) => {
                        setSelectedInvoices(e.value);
                     }}
                  />
               </div>

               <div className="flex justify-center col-12 md:justify-end">
                  <Button severity="success" label={t('global.save')} onClick={saveItems} size="small" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default PayInvoices;
