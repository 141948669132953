import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
   createVehicleReservationControl,
   createVehicleReservationControlDocument,
   deleteVehicleReservationControlDocument,
   deleteVehicleReservationControls,
   getVehicleIdMachineCode,
   getVehicleReservationControl,
   getVehicleReservations,
   updateVehicleReservation,
   updateVehicleReservationControl,
} from '../../../store/apis/MalfunctionApi';
import moment from 'moment';
import TableComponent from '../../../components/TableComponent';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import toast from 'react-hot-toast';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import AtelierPDF from '../../../components/PDFS/AtelierPDF';

const Departures = (props) => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const navigation = useNavigate();
   const vehicleReservations = useSelector((state) => state.malfunctions.vehicleReservations);
   const [dispatchReservationVisible, setDispatchReservationVisible] = useState(false);
   const [selectedReservation, setSelectedReservation] = useState(null);
   const [vehicles, setVehicles] = useState([]);
   const [updateData, setUpdateData] = useState({
      id: null,
      vehicleId: null,
   });
   const [controlFormVisible, setControlFormVisible] = useState(false);
   const [selectedControlFormId, setSelectedControlFormId] = useState(null);
   const fileUploadRef = useRef(null);
   const [documents, setDocuments] = useState([]);
   const url = process.env.REACT_APP_LINK.split('/graphql')[0];

   useEffect(() => {
      dispatch(
         getVehicleReservations({
            date: moment().add(4, 'days').format('YYYY-MM-DD'),
            statuses: ['RESERVE', 'READY_TO_DISPATCH', 'READY_TO_DEPART'],
            isContracted: true,
            isSupplier: false,
         }),
      );
   }, []);

   const handleUpdateReservation = (rowData) => () => {
      setDispatchReservationVisible(true);
      setSelectedReservation(rowData);
      setUpdateData({ ...updateData, id: rowData.id, vehicleId: rowData.vehicle && rowData.vehicle.id ? rowData.vehicle.id : null });
   };

   useEffect(() => {
      if (dispatchReservationVisible) {
         dispatch(
            getVehicleIdMachineCode({
               status: 'AVAILABLE',
               isSupplier: false,
            }),
         ).then((res) => {
            if (selectedReservation.vehicle) {
               setVehicles([...res, selectedReservation.vehicle]);
               // setVehicles(res);
            } else {
               setVehicles(res);
            }
         });
      }
   }, [dispatchReservationVisible]);

   const dispatchTableColumns = [
      {
         name: 'beginDate',
         header: t('pages.departuresAndReturns.beginDate'),
         body: (rowData) => {
            return <span>{moment(rowData.beginDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName = rowData.customer?.companyName;
            return companyName ? <span className="text-blue-500 underline cursor-pointer">{companyName}</span> : <span>-</span>;
         },
      },
      {
         name: 'city',
         header: t('pages.customers.city'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems &&
                  rowData.orderItems[0] &&
                  rowData.orderItems[0].order &&
                  rowData.orderItems[0].order.address &&
                  rowData.orderItems[0].order.address.city
                     ? rowData.orderItems[0].order.address.city
                     : '-'}
               </span>
            );
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            const contractNo = rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.orderNumber;
            return contractNo ? <span className="text-blue-500 underline cursor-pointer">{contractNo}</span> : <span>-</span>;
         },
      },
      {
         name: 'machineGroup',
         header: t('global.table.machineGroup'),
         body: (rowData) => {
            return <span>{rowData.vehicleGroup && rowData.vehicleGroup.name ? rowData.vehicleGroup.name : '-'}</span>;
         },
      },
      {
         name: 'action',
         body: (rowData) => {
            return (
               <Button onClick={handleUpdateReservation(rowData)} label={t('pages.departuresAndReturns.dispatch')} className="text-[14px] !px-2 !py-[2px]" />
            );
         },
      },
   ];

   const reserveTableColumns = [
      {
         name: 'beginDate',
         header: t('pages.departuresAndReturns.beginDate'),
         body: (rowData) => {
            return <span>{moment(rowData.beginDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName = rowData.customer?.companyName;
            return companyName ? <span className="text-blue-500 underline cursor-pointer">{companyName}</span> : <span>-</span>;
         },
      },
      {
         name: 'city',
         header: t('pages.customers.city'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems &&
                  rowData.orderItems[0] &&
                  rowData.orderItems[0].order &&
                  rowData.orderItems[0].order.address &&
                  rowData.orderItems[0].order.address.city
                     ? rowData.orderItems[0].order.address.city
                     : '-'}
               </span>
            );
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            const contractNo = rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.orderNumber;
            return contractNo ? <span className="text-blue-500 underline cursor-pointer">{contractNo}</span> : <span>-</span>;
         },
      },
      {
         field: 'machineCode',
         name: 'machineCode',
         header: t('pages.malfunctions.machineCode'),
         body: (rowData) => {
            return (
               <span className="text-blue-500 underline cursor-pointer">
                  {rowData.vehicle && rowData.vehicle.machineFullCode ? rowData.vehicle.machineFullCode.split(' ').join('') : '-'}
               </span>
            );
         },
      },

      {
         name: 'action',
         body: (rowData) => {
            return (
               <Button
                  onClick={handleUpdateReservation(rowData)}
                  label={t('pages.departuresAndReturns.confirmMachine')}
                  className="text-[14px] !px-2 !py-[2px]"
               />
            );
         },
      },
   ];

   const departTableColumns = [
      {
         name: 'beginDate',
         header: t('pages.departuresAndReturns.beginDate'),
         body: (rowData) => {
            return <span>{moment(rowData.beginDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName = rowData.customer?.companyName;
            return companyName ? <span className="text-blue-500 underline cursor-pointer">{companyName}</span> : <span>-</span>;
         },
      },
      {
         name: 'city',
         header: t('pages.customers.city'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems &&
                  rowData.orderItems[0] &&
                  rowData.orderItems[0].order &&
                  rowData.orderItems[0].order.address &&
                  rowData.orderItems[0].order.address.city
                     ? rowData.orderItems[0].order.address.city
                     : '-'}
               </span>
            );
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            const contractNo = rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.orderNumber;
            return contractNo ? <span className="text-blue-500 underline cursor-pointer">{contractNo}</span> : <span>-</span>;
         },
      },
      {
         name: 'machineCode',
         header: t('pages.malfunctions.machineCode'),
         body: (rowData) => {
            return <span>{rowData.vehicle && rowData.vehicle.machineFullCode ? rowData.vehicle.machineFullCode.split(' ').join('') : '-'}</span>;
         },
      },
      {
         name: 'action',
         bodyClassName: 'text-center w-[220px]',
         body: (rowData) => {
            return (
               <>
                  {/* <Button
                  onClick={handleUpdateReservation(rowData)}
                  label={t('pages.departuresAndReturns.confirmMachine')}
                  className="text-[14px] !px-2 !py-[2px]"
               /> */}
                  <Button onClick={handleUpdateControlForm(rowData)} label="BD" className="text-[14px] !w-[40px] px-0 !py-[6px] me-2" />
                  <AtelierPDF data={rowData} disabled={!rowData.vehicleReservationControlId} />
                  <Button onClick={handleUpdateReservation(rowData)} severity="warning" icon="pi pi-file-edit" className="!w-[40px] px-0 !py-[6px]" />
               </>
            );
         },
      },
   ];

   const handleUpdate = (status) => {
      dispatch(updateVehicleReservation({ id: updateData.id, status, vehicleId: updateData.vehicleId, vehicleGroupId: updateData.vehicleGroupId })).then(
         (res) => {
            dispatch(deleteVehicleReservationControls(selectedReservation));
            setDispatchReservationVisible(false);
            setUpdateData({ id: null, vehicleId: null, vehicleGroupId: null });
            setDocuments([]);
            toast.success(t('pages.departuresAndReturns.updateSuccess'));
         },
      );
   };

   const onCellSelect = ({ field, rowData }) => {
      switch (field) {
         case 'machineCode':
            navigateToMachine(rowData);
            break;
         case 'contractNo':
            navigateToOrder(rowData);
            break;
         case 'customer':
            navigateToCustomer(rowData);
            break;
         default:
            break;
      }
   };

   const navigateToMachine = (rowData) => {
      if (rowData.vehicle && rowData.vehicle.supplierCustomerId) return;
      navigation(`/workshop/t-card`, { state: { machineId: rowData.vehicle.id } });
   };
   const navigateToOrder = (rowData) => {
      if (rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.id)
         navigation('/commerce/contracts', { state: { orderId: rowData.orderItems[0].order.id } });
   };
   const navigateToCustomer = (rowData) => {
      if (rowData.customer && rowData.customer.id) navigation('/customers', { state: { customerId: rowData.customer.id } });
   };

   // Control Form
   const controlFormStatus = [
      'chassisStatus',
      'basketStatus',
      'batteryStatus',
      'cylindersStatus',
      'doorsStatus',
      'tiresStatus',
      'lowerBodyStatus',
      'upperBodyStatus',
      'paintStatus',
   ];

   const [controlFormData, setControlFormData] = useState({
      date: moment().format('YYYY-MM-DD'),
      time: moment().format('HH:mm'),
      mileage: 0,
      atelierDescription: '',
      isKey: false,
      isVgp: false,
      isDocuments: false,
      chassisStatus: 'MEDIUM',
      basketStatus: 'MEDIUM',
      batteryStatus: 'MEDIUM',
      cylindersStatus: 'MEDIUM',
      doorsStatus: 'MEDIUM',
      tiresStatus: 'MEDIUM',
      lowerBodyStatus: 'MEDIUM',
      upperBodyStatus: 'MEDIUM',
      paintStatus: 'MEDIUM',
   });

   const handleUpdateControlForm = (rowData) => () => {
      setControlFormVisible(true);
      setSelectedReservation(rowData);
      setSelectedControlFormId(rowData.vehicleReservationControlId);
      if (rowData.vehicleReservationControlId) {
         dispatch(getVehicleReservationControl(rowData.vehicleReservationControlId)).then((res) => {
            setControlFormData({
               date: res.date,
               time: res.time,
               mileage: res.mileage,
               atelierDescription: res.atelierDescription,
               isKey: res.isKey,
               isVgp: res.isVgp,
               isDocuments: res.isDocuments,
               chassisStatus: res.chassisStatus,
               basketStatus: res.basketStatus,
               batteryStatus: res.batteryStatus,
               cylindersStatus: res.cylindersStatus,
               doorsStatus: res.doorsStatus,
               tiresStatus: res.tiresStatus,
               lowerBodyStatus: res.lowerBodyStatus,
               upperBodyStatus: res.upperBodyStatus,
               paintStatus: res.paintStatus,
            });
            setDocuments(res.vehicleReservationControlDocuments);
         });
      } else {
         setControlFormData({
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm'),
            mileage: 0,
            atelierDescription: '',
            isKey: false,
            isVgp: false,
            isDocuments: false,
            chassisStatus: 'MEDIUM',
            basketStatus: 'MEDIUM',
            batteryStatus: 'MEDIUM',
            cylindersStatus: 'MEDIUM',
            doorsStatus: 'MEDIUM',
            tiresStatus: 'MEDIUM',
            lowerBodyStatus: 'MEDIUM',
            upperBodyStatus: 'MEDIUM',
            paintStatus: 'MEDIUM',
         });
         setDocuments([]);
      }
   };

   const saveControlForm = () => {
      if (selectedControlFormId) {
         dispatch(updateVehicleReservationControl({ ...controlFormData, id: selectedControlFormId })).then((res) => {
            // setControlFormVisible(false);
            toast.success(t('pages.departuresAndReturns.controlFormUpdateSuccess'));
         });
      } else {
         const _data = { ...controlFormData, vehicleReservationId: selectedReservation.id };
         dispatch(createVehicleReservationControl(_data)).then((res) => {
            // setControlFormVisible(false);
            toast.success(t('pages.departuresAndReturns.controlFormUpdateSuccess'));
         });
      }
   };

   const removeFile = (fileToRemove) => {
      const _id = parseInt(fileToRemove.id);
      dispatch(deleteVehicleReservationControlDocument([_id])).then(() => {
         setDocuments(documents.filter((item) => item.id != _id));
      });
   };

   const fileTemplate = (rowData) => {
      return (
         <>
            <Button
               icon="pi pi-eye"
               className="mr-2 p-button-rounded p-button-success min-w-[46px]"
               onClick={() => window.open(`${url}${rowData.document.url}`, '_blank')}
            />
            <Button icon="pi pi-times" className="p-button-rounded p-button-danger  min-w-[46px]" onClick={() => removeFile(rowData)} />
         </>
      );
   };

   const nameTemplate = (rowData) => {
      return (
         <div className="flex items-center">
            <span className="ml-2">{rowData.document.name.split('.')[0]}</span>
         </div>
      );
   };

   const sizeTemplate = (rowData) => {
      return `${(rowData.document.size / 1024).toFixed(2)} KB`;
   };

   const extensionTemplate = (rowData) => {
      return (
         <div className="flex items-center">
            <span className="ml-2">{rowData.document.name.lastIndexOf('.') <= 0 ? '' : rowData.document.name.split('.').pop()}</span>
         </div>
      );
   };

   const onFileSelect = async (e) => {
      if (selectedControlFormId) {
         const _data = {
            vehicleReservationControlId: +selectedControlFormId,
            documents: e.files,
         };
         const result = await dispatch(createVehicleReservationControlDocument(_data));
         if (result) {
            setDocuments([...documents, ...result]);
            toast.success(t('pages.malfunctions.createDocumentMessage'));
         }
      } else {
         const _data = { ...controlFormData, vehicleReservationId: selectedReservation.id };
         dispatch(createVehicleReservationControl(_data)).then((res) => {
            setSelectedControlFormId(res.id);
            const _data = {
               vehicleReservationControlId: +res.id,
               documents: e.files,
            };
            dispatch(createVehicleReservationControlDocument(_data)).then((res) => {
               setDocuments([...documents, ...res]);
               toast.success(t('pages.malfunctions.createDocumentMessage'));
            });
         });
      }
   };

   return (
      <>
         <div className="grid">
            <div className="col-12 lg:col-6">
               <div className="bg-white rounded shadow-md p-3 min-h-[480px]">
                  <div className="font-bold !text-[16px] mb-3">
                     <span className="me-1 font-bold !text-[16px]">
                        ({vehicleReservations && vehicleReservations.filter((item) => item.status === 'READY_TO_DISPATCH').length})
                     </span>
                     {t('pages.departuresAndReturns.readyToDispatchLabel')} ({moment().add(4, 'days').format('DD/MM/YYYY')})
                  </div>
                  <TableComponent
                     data={vehicleReservations && vehicleReservations.filter((item) => item.status === 'READY_TO_DISPATCH')}
                     columns={dispatchTableColumns}
                     scrollable={true}
                     scrollHeight="400px"
                     size="small"
                     cellSelection={true}
                     onCellSelect={onCellSelect}
                  />
               </div>
            </div>

            <div className="col-12 lg:col-6">
               <div className="bg-white rounded shadow-md p-3 min-h-[480px]">
                  <div className="font-bold !text-[16px] mb-3">
                     <span className="me-1 font-bold !text-[16px]">
                        ({vehicleReservations && vehicleReservations.filter((item) => item.status === 'RESERVE').length})
                     </span>
                     {t('pages.departuresAndReturns.reserveMachineLabel')} ({moment().add(4, 'days').format('DD/MM/YYYY')})
                  </div>
                  <TableComponent
                     data={vehicleReservations && vehicleReservations.filter((item) => item.status === 'RESERVE')}
                     columns={reserveTableColumns}
                     scrollable={true}
                     scrollHeight="400px"
                     size="small"
                     cellSelection={true}
                     onCellSelect={onCellSelect}
                  />
               </div>
            </div>

            <div className="col-12">
               <div className="p-3 bg-white rounded shadow-md">
                  <div className="font-bold !text-[16px] mb-3">
                     <span className="me-1 font-bold !text-[16px]">
                        ({vehicleReservations && vehicleReservations.filter((item) => item.status === 'READY_TO_DEPART').length})
                     </span>
                     {t('pages.departuresAndReturns.readyToDepartLabel')} ({moment().add(4, 'days').format('DD/MM/YYYY')})
                  </div>
                  <TableComponent
                     data={vehicleReservations && vehicleReservations.filter((item) => item.status === 'READY_TO_DEPART')}
                     columns={departTableColumns}
                     scrollable={true}
                     cellSelection={true}
                     onCellSelect={onCellSelect}
                  />
               </div>
            </div>
         </div>

         {/* Update Reservation */}
         <Dialog
            header={
               t('pages.departuresAndReturns.selectMachineGroup') +
               ' : ' +
               (selectedReservation && selectedReservation.vehicleGroup ? selectedReservation.vehicleGroup.name : '-')
            }
            visible={dispatchReservationVisible}
            style={{ width: '460px' }}
            onHide={() => setDispatchReservationVisible(false)}
         >
            <div className="mt-2">
               <label>{t('pages.malfunctions.machineCode')}</label>
               <Dropdown
                  options={vehicles}
                  value={updateData.vehicleId}
                  onChange={(e) =>
                     setUpdateData({ ...updateData, vehicleId: e.value, vehicleGroupId: vehicles.find((item) => item.id === e.value).vehicleGroup.id })
                  }
                  optionLabel="machineFullCode"
                  optionValue="id"
                  className="w-full input-detail"
                  filter
               />
               <Button
                  disabled={!updateData.vehicleId}
                  onClick={() => handleUpdate('READY_TO_DEPART')}
                  className="w-full mt-3"
                  label={t('global.save')}
                  severity="success"
               ></Button>
            </div>
         </Dialog>

         {/* Control Form */}
         <Dialog
            header={t('pages.departuresAndReturns.vehicleReservationControlForm')}
            visible={controlFormVisible}
            style={{ width: '80vw' }}
            onHide={() => setControlFormVisible(false)}
         >
            <>
               <div className="grid">
                  <div className="col-6 lg:col-4">
                     <label>{t('pages.malfunctions.date')}</label>
                     <Calendar
                        value={moment(controlFormData.date, 'YYYY-MM-DD').toDate()}
                        onChange={(e) => setControlFormData({ ...controlFormData, date: moment(e.value).format('YYYY-MM-DD') })}
                        placeholder={t('global.select')}
                        className={`w-full input-detail`}
                        dateFormat="dd/mm/yy"
                     />
                  </div>
                  <div className="col-6 lg:col-4">
                     <label>{t('pages.departuresAndReturns.time')}</label>
                     <InputMask
                        value={controlFormData.time ? controlFormData.time : '00:00'}
                        onChange={(e) => {
                           const inputValue = e.target.value;
                           const isValidTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(inputValue);
                           if (isValidTime || inputValue === '') {
                              setControlFormData({
                                 ...controlFormData,
                                 time: inputValue,
                              });
                           }
                        }}
                        mask="99:99"
                        placeholder="--:--"
                        className={`w-full input-detail`}
                     />
                  </div>
               </div>

               <div className="grid mt-2">
                  <div className="col-12 lg:col-4">
                     <div className="flex items-center justify-between p-2 border-2 rounded">
                        <label>{t('pages.departuresAndReturns.key')}</label>
                        <div className="flex items-center justify-center">
                           <InputSwitch checked={controlFormData.isKey} onChange={(e) => setControlFormData({ ...controlFormData, isKey: e.value })} />
                        </div>
                     </div>
                  </div>
                  <div className="col-12 lg:col-4">
                     <div className="flex items-center justify-between p-2 border-2 rounded">
                        <label>{t('pages.departuresAndReturns.vgp')}</label>
                        <div className="flex items-center justify-center">
                           <InputSwitch checked={controlFormData.isVgp} onChange={(e) => setControlFormData({ ...controlFormData, isVgp: e.value })} />
                        </div>
                     </div>
                  </div>
                  <div className="col-12 lg:col-4">
                     <div className="flex items-center justify-between p-2 border-2 rounded">
                        <label>{t('pages.offers.documents')}</label>
                        <div className="flex items-center justify-center">
                           <InputSwitch
                              checked={controlFormData.isDocuments}
                              onChange={(e) => setControlFormData({ ...controlFormData, isDocuments: e.value })}
                           />
                        </div>
                     </div>
                  </div>

                  {controlFormStatus.map((item, index) => {
                     return (
                        <div key={index} className="col-12 lg:col-6">
                           <div className="flex items-center justify-between p-2 border-2 rounded">
                              <label>{t(`pages.departuresAndReturns.${item}`)}</label>
                              <div className="flex">
                                 <div className="flex items-center justify-between p-1 mr-2 border rounded">
                                    <span className="mr-1">{t('pages.departuresAndReturns.good')}</span>
                                    <InputSwitch
                                       checked={controlFormData[item] === 'GOOD'}
                                       onChange={(e) => setControlFormData({ ...controlFormData, [item]: e.value ? 'GOOD' : 'MEDIUM' })}
                                    />
                                 </div>
                                 <div className="flex items-center justify-between p-1 mr-2 border rounded">
                                    <span className="mr-1">{t('pages.departuresAndReturns.medium')}</span>
                                    <InputSwitch
                                       checked={controlFormData[item] === 'MEDIUM'}
                                       onChange={(e) => setControlFormData({ ...controlFormData, [item]: e.value ? 'MEDIUM' : 'MEDIUM' })}
                                    />
                                 </div>
                                 <div className="flex items-center justify-between p-1 mr-2 border rounded">
                                    <span className="mr-1">{t('pages.departuresAndReturns.bad')}</span>
                                    <InputSwitch
                                       checked={controlFormData[item] === 'BAD'}
                                       onChange={(e) => setControlFormData({ ...controlFormData, [item]: e.value ? 'BAD' : 'MEDIUM' })}
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </div>

               <div className="grid mt-2">
                  <div className="col-6 lg:col-4">
                     <label>{t('pages.departuresAndReturns.mileage')}</label>
                     <InputText
                        value={controlFormData.mileage}
                        onChange={(e) => setControlFormData({ ...controlFormData, mileage: e.target.value && e.target.value !== '' ? +e.target.value : null })}
                        maxLength={15}
                        className={`w-full input-detail`}
                        type={'number'}
                     />
                  </div>
                  <div className="col-6 lg:col-4">
                     <label>{t('pages.malfunctions.explanation')}</label>
                     <InputTextarea
                        value={controlFormData.atelierDescription}
                        onChange={(e) => setControlFormData({ ...controlFormData, atelierDescription: e.target.value })}
                        rows={1}
                        className={`w-full input-detail`}
                     />
                  </div>
               </div>

               <div className="mt-2">
                  <div className="flex items-center">
                     <div>{t('pages.customers.dropzoneSheet')}</div>
                     <div>
                        <FileUpload
                           ref={fileUploadRef}
                           accept="image/*"
                           multiple
                           chooseOptions={{ icon: 'pi pi-plus', className: 'bg-green-500 border-none' }}
                           onSelect={onFileSelect}
                           chooseLabel={t('global.add')}
                           maxFileSize={9000000}
                           uploadOptions={{ style: { display: 'none' } }}
                           cancelOptions={{ style: { display: 'none' } }}
                           pt={{
                              content: { style: { display: 'none' } },
                              buttonbar: { style: { background: 'none', border: 'none', display: 'flex', justifyContent: 'end' } },
                           }}
                        />
                     </div>
                  </div>

                  <div>
                     <DataTable value={documents} size="small">
                        <Column body={fileTemplate} header={t('global.table.action')} style={{ minWidth: '120px' }}></Column>
                        <Column field="document.name" body={nameTemplate} header={t('global.table.fileName')} style={{ width: '30%' }}></Column>
                        <Column field="document.size" body={sizeTemplate} header={t('global.table.size')} style={{ width: '25%' }}></Column>
                        <Column field="extension" body={extensionTemplate} header={t('global.table.extension')} style={{ width: '20%' }}></Column>
                     </DataTable>
                  </div>
               </div>

               <div className="flex justify-between mt-5">
                  <AtelierPDF data={selectedReservation} disabled={!selectedReservation?.vehicleReservationControlId} />
                  <Button onClick={saveControlForm} label={t('global.save')} severity="success" />
               </div>
            </>
         </Dialog>
      </>
   );
};

export default Departures;
