import client from '../../graphql/apollo.config';
import { GET_CUSTOMERS, GET_VEHICLES, GET_VEHICLE_GROUPS, GET_VEHICLE_PLANNING, GET_VEHICLE_RESERVATIONS } from '../../graphql/planning';
import { setVehicleReservations, setVehicles } from '../slices/planningSlice';
import { changeLoadingStatus, removeRequest, setRequest } from '../slices/sharedSlice';

export const getVehicles =
   ({ skip, first, startDate, endDate, filters: { supplierCustomerId, machineGroupId, isSupplier }, search }) =>
   async (dispatch) => {
      dispatch(setRequest('getVehicles'));
      return client
         .query({
            variables: {
               ...(skip && { skip }),
               ...(first && { first }),
               ...(startDate && { startDate }),
               ...(endDate && { endDate }),
               ...(search && { search }),
               filters: {
                  ...(supplierCustomerId && { supplierCustomerId }),
                  ...(machineGroupId && { machineGroupId }),
                  ...((isSupplier !== undefined || isSupplier !== null) && { isSupplier }),
               },
            },
            query: GET_VEHICLES,
         })
         .then((res) => {
            dispatch(setVehicles(res.data.vehicles));
            return res.data.vehicles;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(changeLoadingStatus(false));
         });
   };

export const getVehicleReservations = (skip, first) => async (dispatch) => {
   dispatch(setRequest('getVehicleReservations'));
   return client
      .query({
         variables: {
            ...(skip && { skip }),
            ...(first && { first }),
         },
         query: GET_VEHICLE_RESERVATIONS,
      })
      .then((res) => {
         dispatch(setVehicleReservations(res.data.vehicleReservations));
         return res.data.vehicleReservations;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleReservations'));
      });
};

export const getCustomers =
   ({ search, filters: { isActive } }) =>
   async (dispatch) => {
      dispatch(setRequest('getCustomers'));
      return client
         .query({
            variables: {
               ...(search && { search }),
               filters: {
                  ...(isActive !== undefined && { isActive }),
               },
            },
            query: GET_CUSTOMERS,
         })
         .then((res) => {
            return res.data.customers;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('getCustomers'));
         });
   };

export const getVehicleGroups = () => async (dispatch) => {
   dispatch(setRequest('getVehicleGroups'));
   return client
      .query({
         query: GET_VEHICLE_GROUPS,
      })
      .then((res) => {
         return res.data.vehicleGroups;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleGroups'));
      });
};

export const getVehiclePlanning =
   ({ skip, first, startDate, endDate, filters: { supplierCustomerId, vehicleGroupId, isSupplier }, search }) =>
   async (dispatch) => {
      dispatch(setRequest('getVehiclePlanning'));
      return client
         .query({
            variables: {
               ...(skip !== undefined && { skip }),
               ...(first !== undefined && { first }),
               startDate,
               endDate,
               ...(search && { search }),
               filters: {
                  ...(supplierCustomerId && { supplierCustomerId }),
                  ...(vehicleGroupId && { vehicleGroupId }),
                  ...((isSupplier !== undefined || isSupplier !== null) && { isSupplier }),
                  isActive: true,
                  isDeleted: false,
               },
            },
            query: GET_VEHICLE_PLANNING,
         })
         .then((res) => {
            return res.data.vehiclePlanning;
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            dispatch(removeRequest('getVehiclePlanning'));
         });
   };
