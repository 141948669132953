import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { createInvoice, getUninvoicedContracts } from '../../../store/apis/ManagementApi';
import TableComponent from '../../../components/TableComponent';

const Index = () => {
   const dispatch = useDispatch();
   const { uninvoicedContracts } = useSelector((state) => state.management);
   const { t } = useTranslation();

   useEffect(() => {
      dispatch(getUninvoicedContracts());
   }, []);

   const handleCreateInvoice = (rowData) => {
      if (!rowData.vehicle) {
         toast.error(t('pages.invoices.createInvoiceError'));
         return;
      }
      dispatch(createInvoice({ vehicleReservationId: +rowData.id })).then(() => {
         toast.success(t('pages.logistics.createInvoiceSuccess'));
      });
   };

   const tableColumns = [
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            const contractNo = rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.orderNumber;
            return contractNo ? <span>{contractNo}</span> : <span>-</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName = rowData.customer?.companyName;
            return companyName ? <span>{companyName}</span> : <span>-</span>;
         },
      },
      {
         name: 'placeName',
         header: t('global.table.placeName'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.address
                     ? rowData.orderItems[0].order.address.placeName
                     : '-'}
               </span>
            );
         },
      },
      {
         name: 'action',
         body: (rowData) => {
            return (
               <Button
                  label={t('pages.logistics.invoice')}
                  className="text-[14px] !px-2 !py-[2px] ml-2"
                  severity={rowData.isInvoiced ? 'success' : 'warning'}
                  onClick={() => handleCreateInvoice(rowData)}
                  disabled={rowData.isInvoiced}
               />
            );
         },
      },
   ];

   return (
      <>
         <Card className="w-full">
            <div className="mb-4 text-[16px] font-semibold">{t('pages.invoices.uninvoicedContractsHeader')}</div>
            <TableComponent
               data={uninvoicedContracts && uninvoicedContracts.filter((item) => item.isInvoiced === false)}
               columns={tableColumns}
               scrollable={true}
            />
         </Card>
      </>
   );
};

export default Index;
