import TableComponent from '../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { getContract, getContracts } from '../../../store/apis/Customers';
import { setContract } from '../../../store/slices/contractsSlice';
import ContractDetail from '../../Commerce/Contracts/ContractDetail/ContractDetail';
import { duplicateSelectedContract, removeContract } from '../../../store/apis/ContractsApi';

const Contracts = () => {
	const dispatch = useDispatch();

	const { selectedCustomerId } = useSelector((state) => state.customer);

	const contracts = useSelector((state) => state.contracts.contracts);

	const [contractVisible, setContractVisible] = useState(false);

	const [deleteContract, setDeleteContract] = useState({
		dialogVisible: false,
		selectedContractId: null,
	});
	const [duplicateContract, setDuplicateContract] = useState({
		dialogVisible: false,
		selectedContractId: null,
	});

	useEffect(() => {
		dispatch(getContracts(selectedCustomerId, '1'));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableColumns = [
		{
			name: 'contractNumber',
			header: t('global.table.contractNumber'),
			body: (rowData) => {
				return <span>{rowData.orderNumber}</span>;
			},
		},
		{
			name: 'creationDate',
			header: t('global.table.creationDate'),
			body: (rowData) => {
				return <span>{moment(rowData.createdAt).format('DD/MM/YYYY')}</span>;
			},
		},
		{
			name: 'placeName',
			header: t('global.table.placeName'),
			body: (rowData) => {
				return <span>{rowData.address.placeName}</span>;
			},
		},
		{
			name: 'machines',
			header: t('global.table.machines'),
			body: (rowData) => {
				return (
					<span>
						{rowData.orderItems
							.filter((item) => item.vehicleReservation && item.vehicleReservation.vehicle)
							.map(
								(item) =>
									item.vehicleReservation &&
									(item.vehicleReservation.vehicle
										? `${item.vehicleReservation.vehicle.machineCode}(${t(`pages.contracts.${item?.vehicleReservation?.status}`)})`
										: `${item.vehicleReservation && item.vehicleReservation.vehicleGroup.name}(${t(
												`pages.contracts.${item?.vehicleReservation?.status}`
										  )})`)
							)
							.join(', ')}
					</span>
				);
			},
		},
		{
			name: 'actions',
			body: (rowData) => {
				return (
					<div className="flex justify-center">
						<div className="flex items-center">
							<Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={() => showContractDetail(rowData.id)} />
							<Button
								className="mx-1"
								icon="pi pi-trash"
								severity="danger"
								onClick={() =>
									setDeleteContract({
										dialogVisible: true,
										selectedContractId: rowData.id,
									})
								}
							/>
							<Button
								className="mx-1"
								icon="pi pi-copy"
								severity="info"
								onClick={() =>
									setDuplicateContract({
										dialogVisible: true,
										selectedContractId: rowData.id,
									})
								}
							/>
						</div>
					</div>
				);
			},
		},
	];

	const showContractDetail = (id) => {
		dispatch(getContract(+id)).then(async (res) => {
			if (!res) return;
			dispatch(setContract(res.data.order));
			setContractVisible(true);
		});
	};

	const handleDuplicateContract = async () => {
		const res = await dispatch(duplicateSelectedContract(+duplicateContract.selectedContractId));
		if (res) {
			showContractDetail(res.id);
			toast.success(t('pages.contracts.duplicateContractSuccessMessage'));
			setDuplicateContract({
				dialogVisible: false,
				selectedContractId: null,
			});
		}
	};

	return (
		<div>
			<div>
				<div className="flex flex-wrap justify-content-end mt-2"></div>
				<div className="col-7 mt-10">
					<TableComponent columns={tableColumns} size="small" data={contracts} />
				</div>
				<Dialog
					header={t('pages.contracts.deleteContract')}
					visible={deleteContract.dialogVisible}
					style={{ width: '400px' }}
					onHide={() =>
						setDeleteContract({
							dialogVisible: false,
							selectedContractId: null,
						})
					}
				>
					<div className="confirmation-content">{<span>{t('pages.contracts.deleteContractMessage')}</span>}</div>
					<div className="flex justify-end mt-3">
						<Button
							label={t('pages.contracts.cancel')}
							severity="warning"
							className="mr-2 "
							onClick={() =>
								setDeleteContract({
									dialogVisible: false,
									selectedContractId: null,
								})
							}
						/>
						<Button
							label={t('pages.contracts.delete')}
							severity="danger"
							onClick={() => {
								dispatch(removeContract(deleteContract.selectedContractId)).then(
									setDeleteContract({
										dialogVisible: false,
										selectedContractId: null,
									}),
									toast.success(t('pages.contracts.deletedContractMessage'))
								);
							}}
						/>
					</div>
				</Dialog>

				<Dialog
					header={t('pages.contracts.duplicateContract')}
					visible={duplicateContract.dialogVisible}
					style={{ width: '400px' }}
					onHide={() =>
						setDuplicateContract({
							dialogVisible: false,
							selectedContractId: null,
						})
					}
				>
					<div className="confirmation-content">{<span>{t('pages.contracts.duplicateContractMessage')}</span>}</div>
					<div className="flex justify-end mt-3">
						<Button
							label={t('pages.contracts.cancel')}
							severity="danger"
							className="mr-2 "
							onClick={() =>
								setDuplicateContract({
									dialogVisible: false,
									selectedContractId: null,
								})
							}
						/>
						<Button label={t('global.save')} severity="success" onClick={handleDuplicateContract} />
					</div>
				</Dialog>
				<ContractDetail contractVisible={contractVisible} setContractVisible={setContractVisible} />
			</div>
		</div>
	);
};

export default Contracts;
