import client from '../../graphql/apollo.config';
import { removeRequest, setRequest } from '../slices/sharedSlice';
import {
   BLOCK_CUSTOMER,
   CREATE_ADDRESS,
   CREATE_CUSTOMER,
   CREATE_CUSTOMER_CONTACT,
   CREATE_CUSTOMER_DOCUMENT,
   CREATE_DISCUSSION,
   CREATE_REFUND,
   CREATE_REMINDER,
   CREATE_VEHICLE_GROUP_PRICE,
   DELETE_CUSTOMER,
   DELETE_CUSTOMER_DOCUMENT,
   DELETE_VEHICLE_GROUP_PRICES,
   GET_CITIES,
   GET_COMPANYS_CONTACTS,
   GET_COMPANY_TYPES,
   GET_CONTRACT,
   GET_CONTRACTS,
   GET_COUNTRIES,
   GET_CUSTOMER,
   GET_CUSTOMERS,
   GET_CUSTOMER_DOCUMENTS,
   GET_CUSTOMER_INVOICES,
   GET_CUSTOMER_PAID_INVOICES,
   GET_CUSTOMER_REFUNDS,
   GET_CUSTOMER_TYPES,
   GET_DISCUSSIONS,
   GET_DISCUSSION_KINDS,
   GET_OFFER,
   GET_OFFERS,
   GET_REFERENCE_NUMBERS,
   GET_REMINDERS,
   GET_REMINDERS_BY_USER_ID,
   GET_SALE_OFFERS,
   GET_TITLES,
   GET_USERS,
   GET_VEHICLE_GROUPS,
   GET_VEHICLE_GROUP_PRICES,
   UNBLOCK_CUSTOMER,
   UNDELETE_CUSTOMER,
   UPDATE_ADDRESS,
   UPDATE_CUSTOMER,
   UPDATE_CUSTOMER_CONTACTS,
   UPDATE_DELETED_CUSTOMER,
   UPDATE_DISCUSSION,
   UPDATE_INVOICE,
   UPDATE_NEW_CUSTOMER,
   UPDATE_NEW_CUSTOMER_ADDRESS,
   UPDATE_REMINDER,
   GET_PURCHASE_ORDER,
   UPDATE_VEHICLE_GROUP_PRICE,
} from '../../graphql/customers';

import {
   addContact,
   addDiscussion,
   addReminder,
   createVehicleGroupPrices,
   handleAddRefundToCustomerInvoice,
   handleUpdateContact,
   handleUpdateCustomer,
   handleUpdateDiscussion,
   handleUpdateReminder,
   removeCustomer,
   removeVehicleGroupPrice,
   setBillingAddress,
   setBillingAddressId,
   setContacts,
   setCustomerInvoices,
   setCustomerPaidInvoices,
   setCustomerRefunds,
   setCustomers,
   setDiscussions,
   setMainAddress,
   setMainAddressId,
   setReminders,
   setVehicleGroupPrices,
   setPurchaseOrder,
   updateVehicleGroupPrices,
} from '../slices/customerSlice';
import { setDate, setReminderButtonClicked, setReminderDialog, setUnreadNotifications, setUserReminders } from '../slices/remindersSlice';
import { setOffers } from '../slices/offerSlice';
import { setContracts } from '../slices/contractsSlice';

import toast from 'react-hot-toast';
import { t } from 'i18next';

export const getCustomer = (id) => async (dispatch) => {
   dispatch(setRequest('getCustomer'));
   return client
      .query({
         query: GET_CUSTOMER,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res.data.customer;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomer'));
      });
};

export const getCustomers = (rows, skip, searchKeyword, filter) => async (dispatch) => {
   dispatch(setRequest('getCustomers'));
   return client
      .query({
         query: GET_CUSTOMERS,
         variables: {
            first: rows,
            skip: skip,
            search: searchKeyword,
            companyKindId: filter?.companyKindId,
            customerKindId: filter?.customerKindId,
            followedUserId: filter?.followedUserId,
            isDeleted: filter?.isDeleted ? filter.isDeleted : false,
            isBanned: filter?.isBanned,
         },
      })
      .then((res) => {
         dispatch(setCustomers(res.data.customers));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomers'));
      });
};

// export const getCities = () => async () => {
// 	return client
// 		.query({
// 			query: GET_CITIES,
// 		})
// 		.then((res) => {
// 			return res.data.cities;
// 		})
// 		.catch((err) => {
// 			console.log(err);
// 		});
// };

// export const getCountries = () => async () => {
// 	return client
// 		.query({
// 			query: GET_COUNTRIES,
// 		})
// 		.then((res) => {
// 			return res.data.countries;
// 		})
// 		.catch((err) => {
// 			console.log(err);
// 		});
// };

export const getUsers = () => async (dispatch) => {
   dispatch(setRequest('getUsers'));
   return client
      .query({
         query: GET_USERS,
      })
      .then((res) => {
         return res.data.users;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => dispatch(removeRequest('getUsers')));
};

export const getCompanyTypes = () => async () => {
   return client
      .query({
         query: GET_COMPANY_TYPES,
      })
      .then((res) => {
         return res.data.companyKinds;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getCustomerTypes = () => async () => {
   return client
      .query({
         query: GET_CUSTOMER_TYPES,
      })
      .then((res) => {
         return res.data.customerKinds;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getTitles = () => async () => {
   return client
      .query({
         query: GET_TITLES,
      })
      .then((res) => {
         return res.data.titles;
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getDiscussionsKinds = () => async () => {
   return client
      .query({
         query: GET_DISCUSSION_KINDS,
      })
      .then((res) => {
         return res.data.discussionKinds;
         // setDiscussionKinds(res.data.discussionKinds);
      })
      .catch((err) => {
         console.log(err);
      });
};

export const getCompanyContacts = (customerId) => async (dispatch) => {
   dispatch(setRequest('getCompanyContacts'));
   return client
      .query({
         query: GET_COMPANYS_CONTACTS,
         variables: { customerId: customerId },
      })
      .then((res) => {
         dispatch(setContacts(res.data.contacts));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCompanyContacts'));
      });
};

export const createCustomer = (showAddNewMachineDialog, showViewMachineDetailsDialog, informationsInputs) => async (dispatch) => {
   dispatch(setRequest('createCustomer'));
   return client
      .mutate({
         mutation: CREATE_CUSTOMER,
         variables: {
            customerKindId: showAddNewMachineDialog || showViewMachineDetailsDialog ? '3' : informationsInputs.customerKindId,
            companyName: informationsInputs.companyName,
            companyKindId: informationsInputs.companyKindId,
            email: informationsInputs.email,
            idNumber: informationsInputs.idNumber,
            followedUserId: informationsInputs.followedUserId,
            phone: informationsInputs.phone,
            activityName: informationsInputs.activityName,
            faxNumber: informationsInputs.faxNo,
            note: informationsInputs.note,
            taxNumber: informationsInputs.taxNo,
            taxOffice: informationsInputs.taxOffice,
            website: informationsInputs.website,
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
         if (err.message.toLowerCase().includes('company_name')) {
            toast.error(t('pages.customers.thisCustomerAlreadyExist'));
         }
      })
      .finally(() => {
         dispatch(removeRequest('createCustomer'));
      });
};

export const updateCustomer = (id, generalInformationsInputs, creditInformationsInputs, checked, factorChecked, ownInsuranceCheck) => async (dispatch) => {
   dispatch(setRequest('updateCustomer'));
   return client
      .mutate({
         mutation: UPDATE_CUSTOMER,
         variables: {
            id,
            companyName: generalInformationsInputs.companyName,
            companyKindId: generalInformationsInputs.companyKindId ? generalInformationsInputs.companyKindId : null,
            customerKindId: generalInformationsInputs.customerKindId,
            email: generalInformationsInputs.email,
            idNumber: generalInformationsInputs.idNumber,
            followedUserId: generalInformationsInputs.followedUserId,
            phone: generalInformationsInputs.phone,
            activityName: generalInformationsInputs.activityName,
            faxNumber: generalInformationsInputs.faxNumber,
            note: generalInformationsInputs.note,
            taxNumber: generalInformationsInputs.taxNumber,
            taxOffice: generalInformationsInputs.taxOffice,
            website: generalInformationsInputs.website,
            buyersBan: creditInformationsInputs.buyersBan,
            buyersBic: creditInformationsInputs.buyersBic,
            buyersRib: creditInformationsInputs.buyersRib,
            buyersNote: creditInformationsInputs.buyersNote,
            receptionDate: creditInformationsInputs.receptionDate,
            isCheck: creditInformationsInputs.isCheck,
            factor: creditInformationsInputs.factor,
            ownInsurance: creditInformationsInputs.ownInsurance,
            maturity: creditInformationsInputs.maturity,
            factorLimit: +creditInformationsInputs.factorLimit,
            maximumCreditLimit: +creditInformationsInputs.maximumCreditLimit,
         },
      })
      .then((res) => {
         console.log(res.data);
         dispatch(handleUpdateCustomer(res.data.updateCustomer.customer));
         return res;
      })
      .catch((err) => {
         console.log(err);
         if (err.message.toLowerCase().includes('company_name')) {
            toast.error(t('pages.customer.thisCustomerAlreadyExist'));
         }
      })
      .finally(() => {
         dispatch(removeRequest('updateCustomer'));
      });
};

export const updateNewCustomer = (id, informationsInput) => async (dispatch) => {
   dispatch(setRequest('updateCustomer'));
   return client
      .mutate({
         mutation: UPDATE_NEW_CUSTOMER,
         variables: {
            id,
            companyName: informationsInput.companyName,
            companyKindId: informationsInput.companyKindId ? informationsInput.companyKindId : null,
            customerKindId: informationsInput.customerKindId,
            email: informationsInput.email,
            idNumber: informationsInput.idNumber,
            followedUserId: informationsInput.followedUserId,
            phone: informationsInput.phone,
            activityName: informationsInput.activityName,
            faxNumber: informationsInput.faxNo,
            note: informationsInput.note,
            taxNumber: informationsInput.taxNo,
            taxOffice: informationsInput.taxOffice,
            website: informationsInput.website,
         },
      })
      .then((res) => {
         console.log(res.data);
         return res;
      })
      .catch((err) => {
         console.log(err);
         if (err.message.toLowerCase().includes('company_name')) {
            toast.error(t('pages.customer.thisCustomerAlreadyExist'));
         }
      })
      .finally(() => {
         dispatch(removeRequest('updateCustomer'));
      });
};

export const updateNewCustomerAddress = (informationInputs, billingAddressId, mainAddressId, selectedCustomerId, isAddressSame) => async (dispatch) => {
   dispatch(setRequest('updateNewCustomerAddress'));
   return client
      .mutate({
         mutation: UPDATE_NEW_CUSTOMER_ADDRESS,
         variables: {
            id: mainAddressId,
            address: informationInputs.address,
            addressContinuation: informationInputs.addressContinuation,
            addressKindId: 2, // main
            city: informationInputs.city,
            country: informationInputs.country,
            districtName: informationInputs.districtName,
            customerId: selectedCustomerId,
            postCode: informationInputs.postCode,
            isSame: isAddressSame,
         },
      })
      .then(() => {
         if (isAddressSame) {
            client
               .mutate({
                  mutation: UPDATE_ADDRESS,
                  variables: {
                     id: billingAddressId,
                     address: informationInputs.address,
                     addressContinuation: informationInputs.addressContinuation,
                     addressKindId: 1, // billing
                     city: informationInputs.city,
                     // countryId: selectedMainCountry,
                     customerId: selectedCustomerId,
                     postCode: informationInputs.postCode,
                     isSame: isAddressSame,
                  },
               })
               .then((res) => {
                  console.log(res.data);
               })
               .catch((err) => {
                  console.log(err);
               });
         } else {
            client
               .mutate({
                  mutation: UPDATE_ADDRESS,
                  variables: {
                     id: billingAddressId,
                     address: informationInputs.billingAddress,
                     addressContinuation: informationInputs.billingAddressContinuation,
                     addressKindId: 1, // billing
                     city: informationInputs.billingCity,
                     // countryId: selectedBillingCountry,
                     customerId: selectedCustomerId,
                     postCode: informationInputs.billingPostCode,
                     isSame: isAddressSame,
                  },
               })
               .then((res) => {
                  console.log(res.data);
               })
               .catch((err) => {
                  console.log(err);
               });
         }
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateNewCustomerAddress'));
      });
};

export const deleteCustomer = (id) => async (dispatch) => {
   dispatch(setRequest('deleteCustomer'));
   return client
      .mutate({
         mutation: DELETE_CUSTOMER,
         variables: {
            ids: parseInt(id),
         },
      })
      .then((res) => {
         console.log(res);
         dispatch(removeCustomer(res.data.deleteCustomers));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('deleteCustomer'));
      });
};

export const undeleteCustomer = (id) => async (dispatch) => {
   dispatch(setRequest('undeleteCustomer'));
   return client
      .mutate({
         mutation: UNDELETE_CUSTOMER,
         variables: {
            id: parseInt(id),
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('undeleteCustomer'));
      });
};

export const updateDeletedCustomer = (id, customerKindId) => async (dispatch) => {
   dispatch(setRequest('updateDeletedCustomer'));
   return client
      .mutate({
         mutation: UPDATE_DELETED_CUSTOMER,
         variables: {
            id,
            customerKindId,
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateDeletedCustomer'));
      });
};

export const blockCustomer = (id, blockReason) => async (dispatch) => {
   dispatch(setRequest('blockCustomer'));
   return client
      .mutate({
         mutation: BLOCK_CUSTOMER,
         variables: {
            id: parseInt(id),
            bannedReason: blockReason,
            isBanned: true,
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('blockCustomer'));
      });
};

export const unBlockCustomer = (id) => async (dispatch) => {
   dispatch(setRequest('unBlockCustomer'));
   return client
      .mutate({
         mutation: UNBLOCK_CUSTOMER,
         variables: {
            id: parseInt(id),
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('unBlockCustomer'));
      });
};

export const createCustomerAddress = (informationsInputs, customerId, sameAddress) => async (dispatch) => {
   dispatch(setRequest('createCustomerAddress'));
   let addressData = [];
   return client
      .mutate({
         mutation: CREATE_ADDRESS,
         variables: {
            address: informationsInputs.address,
            addressContinuation: informationsInputs.addressContinuation,
            addressKindId: 2, // main
            city: informationsInputs.city,
            country: informationsInputs.country,
            districtName: informationsInputs.districtName,
            customerId: customerId,
            placeName: 'MAIN OFFICE',
            postCode: informationsInputs.postCode,
            isMainOrBillingAddress: sameAddress,
         },
      })
      .then(async (res) => {
         console.log(res.data.createAddress.address.id);
         addressData.push(res.data.createAddress.address);
         if (sameAddress) {
            return client
               .mutate({
                  mutation: CREATE_ADDRESS,
                  variables: {
                     address: informationsInputs.address,
                     addressContinuation: informationsInputs.addressContinuation,
                     addressKindId: 1, // billing
                     city: informationsInputs.city,
                     country: informationsInputs.country,
                     districtName: informationsInputs.districtName,
                     customerId: customerId,
                     placeName: 'BILLING ADDRESS',
                     postCode: informationsInputs.postCode,
                     isMainOrBillingAddress: sameAddress,
                  },
               })
               .then((res) => {
                  console.log(res.data.createAddress.address.id);
                  addressData.push(res.data.createAddress.address);
                  return addressData;
               })
               .catch((err) => {
                  console.log(err);
               });
         } else {
            return client
               .mutate({
                  mutation: CREATE_ADDRESS,
                  variables: {
                     address: informationsInputs.billingAddress,
                     addressContinuation: informationsInputs.billingAddressContinuation,
                     addressKindId: 1, // billing
                     city: informationsInputs.billingCity,
                     country: informationsInputs.billingCountry,
                     districtName: informationsInputs.billingDistrictName,
                     customerId: customerId,
                     placeName: 'BILLING ADDRESS',
                     postCode: informationsInputs.billingPostCode,
                     isMainOrBillingAddress: sameAddress,
                  },
               })
               .then((res) => {
                  addressData.push(res.data.createAddress.address);
                  return addressData;
               })
               .catch((err) => {
                  console.log(err);
               });
         }
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createCustomerAddress'));
      });
};

export const updateCustomerAddress = (addressInputs, billingAddressInputs, selectedCustomerId, isAddressSame) => async (dispatch) => {
   dispatch(setRequest('updateCustomerAddress'));
   console.log(isAddressSame);
   return client
      .mutate({
         mutation: UPDATE_ADDRESS,
         variables: {
            id: addressInputs.id,
            address: addressInputs.address,
            addressContinuation: addressInputs.addressContinuation,
            addressKindId: 2, // main
            city: addressInputs.city,
            country: addressInputs.country,
            districtName: addressInputs.districtName,
            customerId: selectedCustomerId,
            postCode: addressInputs.postCode,
            isMainOrBillingAddress: isAddressSame,
         },
      })
      .then((res) => {
         console.log(res.data);
         if (isAddressSame) {
            client
               .mutate({
                  mutation: UPDATE_ADDRESS,
                  variables: {
                     id: billingAddressInputs.id,
                     address: addressInputs.address,
                     addressContinuation: addressInputs.addressContinuation,
                     addressKindId: 1, // billing
                     city: addressInputs.city,
                     country: addressInputs.country,
                     districtName: addressInputs.districtName,
                     customerId: selectedCustomerId,
                     postCode: addressInputs.postCode,
                     isMainOrBillingAddress: isAddressSame,
                  },
               })
               .then((res) => {
                  console.log(res.data);
               })
               .catch((err) => {
                  console.log(err);
               });
         } else {
            client
               .mutate({
                  mutation: UPDATE_ADDRESS,
                  variables: {
                     id: billingAddressInputs.id,
                     address: billingAddressInputs.billingAddress,
                     addressContinuation: billingAddressInputs.billingAddressContinuation,
                     addressKindId: 1, // billing
                     city: billingAddressInputs.billingCity,
                     country: billingAddressInputs.billingCountry,
                     districtName: billingAddressInputs.billingDistrictName,
                     customerId: selectedCustomerId,
                     postCode: billingAddressInputs.billingPostCode,
                     isMainOrBillingAddress: isAddressSame,
                  },
               })
               .then((res) => {
                  console.log(res.data);
               })
               .catch((err) => {
                  console.log(err);
               });
         }
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateCustomerAddress'));
      });
};

export const getCustomerDocument = (customerId) => async (dispatch) => {
   dispatch(setRequest('getCustomerDocument'));
   return client
      .mutate({
         mutation: GET_CUSTOMER_DOCUMENTS,
         variables: {
            customerId,
         },
      })
      .then((res) => {
         // setFiles(res.data.customerDocuments);
         // console.log(res.data);
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomerDocument'));
      });
};

export const createCustomerDocument = (id, file) => async (dispatch) => {
   dispatch(setRequest('createCustomerDocument'));
   return client
      .mutate({
         mutation: CREATE_CUSTOMER_DOCUMENT,
         variables: {
            customerId: id,
            document: file,
         },
      })
      .then((res) => {
         console.log(res);
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createCustomerDocument'));
      });
};

export const deleteCustomerDocument = (id) => async (dispatch) => {
   dispatch(setRequest('deleteCustomerDocument'));
   return client
      .mutate({
         mutation: DELETE_CUSTOMER_DOCUMENT,
         variables: {
            ids: parseInt(id),
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('deleteCustomerDocument'));
      });
};

export const createCustomerContact = (contactsInputs, customerId) => async (dispatch) => {
   dispatch(setRequest('createCustomerContact'));
   return client
      .mutate({
         mutation: CREATE_CUSTOMER_CONTACT,
         variables: {
            customerId: customerId,
            email: contactsInputs.email,
            firstName: contactsInputs.firstName,
            lastName: contactsInputs.lastName,
            phone: contactsInputs.phone,
            role: contactsInputs.role,
            titleId: contactsInputs.title,
            description: contactsInputs.description,
         },
      })
      .then((res) => {
         dispatch(addContact(res.data.createContact.contact));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createCustomerContact'));
      });
};

export const updateCustomerContact = (selectedContact, customerId) => async (dispatch) => {
   dispatch(setRequest('updateCustomerContacts'));
   return client
      .mutate({
         mutation: UPDATE_CUSTOMER_CONTACTS,
         variables: {
            id: selectedContact.id,
            customerId: customerId,
            description: selectedContact.description,
            email: selectedContact.email,
            firstName: selectedContact.firstName,
            lastName: selectedContact.lastName,
            phone: selectedContact.phone,
            role: selectedContact.role,
            titleId: selectedContact.title.id,
         },
      })
      .then((res) => {
         dispatch(handleUpdateContact(res.data.updateContact.contact));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateCustomerContacts'));
      });
};

export const getReminders = (id) => async (dispatch) => {
   dispatch(setRequest('getReminders'));
   return client
      .query({
         query: GET_REMINDERS,
         variables: {
            customerId: id,
         },
      })
      .then((res) => {
         const reversedReminders = [...res.data.reminders].reverse();

         dispatch(setReminders(reversedReminders));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getReminders'));
      });
};

export const getRemindersByUserId = (id, reminderButtonClicked, date) => async (dispatch) => {
   dispatch(setRequest('getReminders'));
   return client
      .query({
         query: GET_REMINDERS_BY_USER_ID,
         variables: {
            userId: id,
         },
      })
      .then(async (res) => {
         const reversedReminders = [...res.data.reminders].reverse();
         const today = new Date().toISOString().slice(0, 10);
         const currentReminders = reversedReminders.filter((item) => item.date >= today);
         dispatch(setUserReminders(currentReminders));
         console.log(date, today);
         if (date !== today) {
            await dispatch(setReminderButtonClicked(false));
         }
         if (!reminderButtonClicked) {
            const todayData = currentReminders.filter((item) => item.date === today);
            dispatch(setDate(currentReminders[0].date));
            dispatch(setUnreadNotifications(todayData.length));
            dispatch(setReminderDialog(true));
         }
         return currentReminders;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getReminders'));
      });
};

export const updateReminder = (selectedReminder, date) => async (dispatch) => {
   dispatch(setRequest('updateReminder'));
   return client
      .mutate({
         mutation: UPDATE_REMINDER,
         variables: {
            id: selectedReminder.id,
            userId: selectedReminder.user.id ? selectedReminder.user.id : selectedReminder.user,
            description: selectedReminder.description,
            date: date,
         },
      })
      .then((res) => {
         console.log(res);
         dispatch(handleUpdateReminder(res.data.updateReminder.reminder));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateReminder'));
      });
};

export const createReminder = (selectedCustomerId, date, description, userId) => async (dispatch) => {
   dispatch(setRequest('createReminder'));
   return client
      .mutate({
         mutation: CREATE_REMINDER,
         variables: {
            customerId: selectedCustomerId,
            date,
            description,
            userId,
         },
      })
      .then((res) => {
         console.log(res);
         dispatch(addReminder(res.data.createReminder.reminder));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createReminder'));
      });
};

export const getDisscussions = (id) => async (dispatch) => {
   dispatch(setRequest('getDiscussions'));
   return client
      .query({
         query: GET_DISCUSSIONS,
         variables: {
            customerId: id,
         },
      })
      .then((res) => {
         const reversedDiscussions = [...res.data.discussions].reverse();
         dispatch(setDiscussions(reversedDiscussions));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getDiscussions'));
      });
};

export const updateDiscussion = (selectedDiscussion, date) => async (dispatch) => {
   dispatch(setRequest('updateDiscussion'));
   return client
      .mutate({
         mutation: UPDATE_DISCUSSION,
         variables: {
            id: selectedDiscussion.id,
            discussionKindId: +selectedDiscussion.discussionKind.id,
            note: selectedDiscussion.note,
            date: date,
         },
      })
      .then((res) => {
         console.log(res);
         dispatch(handleUpdateDiscussion(res.data.updateDiscussion.discussion));
         return res;
      })
      .catch((err) => {
         console.log(selectedDiscussion);
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateDiscussion'));
      });
};

export const createDiscussion = (selectedCustomerId, date, discussionsInputs) => async (dispatch) => {
   dispatch(setRequest('createDiscussion'));
   return client
      .mutate({
         mutation: CREATE_DISCUSSION,
         variables: {
            customerId: selectedCustomerId,
            date,
            note: discussionsInputs.note,
            discussionKindId: discussionsInputs.discussionKind,
            userId: discussionsInputs.user,
         },
      })
      .then((res) => {
         dispatch(addDiscussion(res.data.createDiscussion.discussion));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createDiscussion'));
      });
};

export const getOffers = (id, orderKindId) => async (dispatch) => {
   dispatch(setRequest('getOffers'));
   return client
      .query({
         query: GET_OFFERS,
         variables: {
            customerId: id,
            orderKindId,
         },
      })
      .then((res) => {
         dispatch(setOffers(res.data.orders));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getOffers'));
      });
};

export const getOffer = (id) => async (dispatch) => {
   dispatch(setRequest('getOffer'));
   return client
      .query({
         query: GET_OFFER,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getOffer'));
      });
};

export const getContracts = (id, orderKindId) => async (dispatch) => {
   dispatch(setRequest('getContracts'));
   return client
      .query({
         query: GET_CONTRACTS,
         variables: {
            customerId: id,
            orderKindId,
         },
      })
      .then((res) => {
         dispatch(setContracts(res.data.orders));
         console.log(res.data.orders);
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getContracts'));
      });
};

export const getContract = (id) => async (dispatch) => {
   dispatch(setRequest('getContract'));
   return client
      .query({
         query: GET_CONTRACT,
         variables: {
            id,
         },
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getContract'));
      });
};

export const getReferenceNumbers = () => async (dispatch) => {
   dispatch(setRequest('getReferenceNumbers'));
   return client
      .query({
         query: GET_REFERENCE_NUMBERS,
      })
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getReferenceNumbers'));
      });
};

export const getCustomerInvoices = (customerId, status, startDate, endDate) => async (dispatch) => {
   dispatch(setRequest('getCustomerInvoices'));
   return client
      .query({
         query: GET_CUSTOMER_INVOICES,
         variables: {
            customerId,
            status,
            startDate,
            endDate,
         },
      })
      .then((res) => {
         dispatch(setCustomerInvoices(res.data.invoices));
         return res.data.invoices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomerInvoices'));
      });
};

export const getCustomerPaidInvoices = (customerId) => async (dispatch) => {
   dispatch(setRequest('getCustomerInvoices'));
   return client
      .query({
         query: GET_CUSTOMER_PAID_INVOICES,
         variables: {
            customerId,
         },
      })
      .then((res) => {
         dispatch(setCustomerPaidInvoices(res.data.invoices));
         let totalDifference = 0;

         res.data.invoices.forEach((item) => {
            const paymentDate = new Date(item.paymentDate);
            const endDate = item.endDate ? new Date(item.endDate) : null;
            const createdDate = new Date(item.createdAt.slice(0, 10));
            console.log(new Date(item.createdAt.slice(0, 10)));
            const differenceInTime = endDate ? paymentDate.getTime() - endDate.getTime() : paymentDate.getTime() - createdDate.getTime();
            console.log(differenceInTime);
            const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days
            totalDifference += differenceInDays;
         });

         const average = res.data.invoices.length > 0 ? (totalDifference / res.data.invoices.length).toFixed(2) : 0;
         console.log(average.toFixed(2));
         return average;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomerInvoices'));
      });
};

export const createRefund = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_REFUND'));
   return client
      .mutate({
         mutation: CREATE_REFUND,
         variables: {
            data,
         },
      })
      .then((res) => {
         console.log(res);
         return res.data.createRefund.refund;
      })
      .catch((err) => {
         console.log(err);
         toast.error(t(err.message));
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_REFUND'));
      });
};

export const getCustomerRefunds = (customerId) => async (dispatch) => {
   dispatch(setRequest('GET_CUSTOMER_REFUNDS'));
   return client
      .query({
         query: GET_CUSTOMER_REFUNDS,
         variables: {
            customerId,
         },
      })
      .then((res) => {
         dispatch(setCustomerRefunds(res.data.refunds));
         return res.data.refunds;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_CUSTOMER_REFUNDS'));
      });
};

export const getVehicleGroupPrices = (customerId) => async (dispatch) => {
   dispatch(setRequest('getVehicleGroupPrices'));
   return client
      .query({
         query: GET_VEHICLE_GROUP_PRICES,
         variables: {
            customerId,
         },
      })
      .then((res) => {
         dispatch(setVehicleGroupPrices(res.data.vehicleGroupPrices));
         return res.data.vehicleGroupPrices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleGroupPrices'));
      });
};

export const createVehicleGroupPrice = (data) => async (dispatch) => {
   dispatch(setRequest('createVehicleGroupPrice'));
   return client
      .mutate({
         mutation: CREATE_VEHICLE_GROUP_PRICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(createVehicleGroupPrices(res.data.createVehicleGroupPrice.vehicleGroupPrice));
         return res.data.createVehicleGroupPrice.vehicleGroupPrice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createVehicleGroupPrice'));
      });
};

export const getVehicleGroups = (supplierId) => async (dispatch) => {
   dispatch(setRequest('getVehicleGroupPrices'));
   return client
      .query({
         query: GET_VEHICLE_GROUPS,
         variables: {
            supplierId,
         },
      })
      .then((res) => {
         return res.data.vehicleGroups;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getVehicleGroupPrices'));
      });
};

export const deleteVehicleGroupPrice = (ids) => async (dispatch) => {
   dispatch(setRequest('DELETE_VEHICLE_GROUP_PRICES'));
   return client
      .mutate({
         mutation: DELETE_VEHICLE_GROUP_PRICES,
         variables: {
            ids,
         },
      })
      .then((res) => {
         dispatch(removeVehicleGroupPrice(ids));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_VEHICLE_GROUP_PRICES'));
      });
};

export const getPurchaseOrder = (filters, statuses) => async (dispatch) => {
   console.log(statuses);
   dispatch(setRequest('getPurchaseOrder'));
   return client
      .query({
         query: GET_PURCHASE_ORDER,
         variables: {
            filters,
            statuses,
         },
      })
      .then((res) => {
         dispatch(setPurchaseOrder(res.data.transporterOrders));
         return res;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getPurchaseOrder'));
      });
};

export const updateVehicleGroupPrice = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_VEHICLE_GROUP_PRICE'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_GROUP_PRICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(updateVehicleGroupPrices(res.data.updateVehicleGroupPrice.vehicleGroupPrice));
         return res.data.updateVehicleGroupPrice.vehicleGroupPrice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_VEHICLE_GROUP_PRICE'));
      });
};
