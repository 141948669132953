import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectButton } from 'primereact/selectbutton';
import TableComponent from '../../components/TableComponent';
import { useDispatch } from 'react-redux';
import { getCustomers, getVehicleGroups, getVehiclePlanning } from '../../store/apis/PlanningApi';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Paginator } from 'primereact/paginator';
import { Card } from 'primereact/card';
import { Calendar } from 'primereact/calendar';
import { useNavigate } from 'react-router-dom';

const Planning = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const navigation = useNavigate();
   const tableOptions = [
      { label: t('pages.planning.inventory'), value: 'inventory' },
      { label: t('pages.planning.supplier'), value: 'supplier' },
   ];
   const [selectedTable, setSelectedTable] = useState('inventory');
   const [vehiclesData, setVehiclesData] = useState([]);
   const [first, setFirst] = useState(20);
   const [skip, setSkip] = useState(0);
   const [showFilterModal, setShowFilterModal] = useState(false);
   const [selectedCostemer, setSelectedCostemer] = useState(null);
   const [selectedMachineGroup, setSelectedMachineGroup] = useState(null);
   const [search, setSearch] = useState('');
   const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
   const [customers, setCustomers] = useState([]);
   const [machineGroups, setMachineGroups] = useState([]);
   const [count, setCount] = useState(0);

   useEffect(() => {
      dispatch(getCustomers({ filters: { isDeleted: false } })).then((res) => {
         setCustomers(res);
      });
      dispatch(getVehicleGroups()).then((res) => {
         setMachineGroups(res);
      });
   }, []);

   useEffect(() => {
      getVehiclesQuery(skip, first, selectedDate, moment(selectedDate).add(20, 'days').format('YYYY-MM-DD'), search, selectedCostemer, selectedMachineGroup);
   }, [selectedTable, selectedDate, skip]);

   const getVehiclesQuery = (_skip, _first, _startDate, _endDate, _search, _selectedCustomer, _selectedMachineGroup) => {
      let queryOptions = {
         skip: _skip,
         first: _first,
         startDate: _startDate,
         endDate: _endDate,
         ...(_search?.trim() !== '' && { search: _search }),
         filters: {
            ...(_selectedCustomer && { supplierCustomerId: _selectedCustomer }),
            ...(_selectedMachineGroup && { vehicleGroupId: _selectedMachineGroup }),
            ...(selectedTable === 'inventory' ? { isSupplier: false } : { isSupplier: true }),
         },
      };
      dispatch(getVehiclePlanning(queryOptions)).then((res) => {
         const _vehicles = JSON.parse(res?.vehicles);
         _vehicles.sort((a, b) => (a.vehicleGroupName + a.machineCode).localeCompare(b.vehicleGroupName + b.machineCode));

         setVehiclesData(_vehicles);
         setCount(res.count);
      });
   };
   const generateDateHeaders = () => {
      const startDate = moment(selectedDate);
      const endDate = moment(selectedDate).add(20, 'days');
      const dateHeaders = [];

      while (startDate.isSameOrBefore(endDate)) {
         dateHeaders.push({
            header: startDate.format('DD'),
            date: startDate.format('ddd'),
         });

         startDate.add(1, 'day');
      }

      return dateHeaders;
   };

   const resetFilters = () => {
      setSelectedCostemer(null);
      setSelectedMachineGroup(null);
      setSelectedDate(moment().format('YYYY-MM-DD'));
      setSearch('');
      setSkip(0);
      setFirst(10);
      getVehiclesQuery(0, 10, moment().format('YYYY-MM-DD'), moment().add(20, 'days').format('YYYY-MM-DD'), '', null, null);
   };

   const areThereFilters = () => {
      return selectedCostemer || selectedMachineGroup || search !== '';
   };
   const columns = [
      {
         ...(selectedTable === 'supplier' && {
            field: 'supplierCustomer.companyName',
            header: t('pages.planning.supplierName'),
            /* headerStyle: { minWidth: '150px' }, */
            bodyClassName: 'cursor-pointer',
            body: (rowData) => {
               return rowData.supplierCustomer && rowData.supplierCustomer.companyName ? (
                  <span className="underline text-blue-500">{rowData.supplierCustomer.companyName}</span>
               ) : (
                  '-'
               );
            },
         }),
      },
      {
         field: 'machineCode',
         header: t('pages.planning.machineCode'),
         /* headerStyle: { minWidth: '150px' }, */
         bodyClassName: 'cursor-pointer',
         body: (rowData) => {
            return rowData.machineCode && rowData.vehicleGroupName ? (
               <span className="underline text-blue-500">{`${rowData.vehicleGroupName}${rowData.machineCode}`}</span>
            ) : (
               '-'
            );
         },
      },
      {
         field: 'order.orderNumber',
         header: t('pages.planning.contractNo'),
         /* headerStyle: { minWidth: '150px' }, */
         bodyClassName: 'cursor-pointer',
         body: (rowData) => {
            return rowData.order && rowData.order.orderNumber ? <span className="underline text-blue-500">{rowData.order.orderNumber}</span> : '-';
         },
      },
      { field: 'order.placeName', header: t('pages.planning.placeName'), headerStyle: { minWidth: '150px' } },

      {
         field: 'customer.companyName',
         header: t('pages.planning.customer'),
         /* headerStyle: { minWidth: '150px' }, */
         bodyClassName: 'cursor-pointer',

         body: (rowData) => {
            return rowData.customer && rowData.customer.companyName ? <span className="underline text-blue-500">{rowData.customer.companyName}</span> : '-';
         },
      },

      {
         /* headerStyle: { minWidth: '120px' }, */
         body: (rowData) => {
            return (
               rowData.vehicleReservation &&
               rowData.vehicleReservation.beginDate &&
               moment(rowData.vehicleReservation.beginDate, 'DD-MM-YYYY').format('DD MM YYYY')
            );
         },
         name: 'beginDate',
         header: t('pages.planning.beginDate'),
      },
      {
         /* headerStyle: { minWidth: '120px' }, */
         body: (rowData) => {
            return (
               rowData.vehicleReservation && rowData.vehicleReservation.endDate && moment(rowData.vehicleReservation.endDate, 'DD-MM-YYYY').format('DD MM YYYY')
            );
         },
         name: 'endDate',
         header: t('pages.planning.endDate'),
      },

      {
         body: (rowData) => {
            return (
               <div className="flex p-0 m-0 ">
                  {Object.keys(rowData.days)
                     .sort((a, b) => new Date(a) - new Date(b))
                     .map((day, index) => {
                        return (
                           <div
                              id={`day-${day}-${rowData.id + index}`}
                              key={day}
                              className={`p-0 m-0 h-[40px] w-[40px] border  bg-[${
                                 rowData.days[day].isMalfunction
                                    ? '#ef5d4e'
                                    : rowData.days[day].isRent
                                    ? '#f0ad4e'
                                    : rowData.days[day].isReserved
                                    ? '#f5b0b0'
                                    : rowData.days[day].isReturnPlaned
                                    ? '#8eb3f9'
                                    : rowData.days[day].isInspection
                                    ? '#e8e824'
                                    : '#85de7f'
                              }]`}
                           ></div>
                        );
                     })}
               </div>
            );
         },
         bodyClassName: 'p-0 m-0',
         header: () => (
            <div className="flex flex-col items-center justify-center">
               <span>{`${moment(selectedDate).format('MMMM-YYYY')} | ${moment(selectedDate).add(20, 'days').format('MMMM-YYYY')}`}</span>
               <div className="flex justify-start items-start">
                  {generateDateHeaders().map((header, index) => (
                     <div key={index} className="flex flex-col text-center w-[40px]">
                        <span>{header.header}</span>
                        <span>{header.date}</span>
                     </div>
                  ))}
               </div>
            </div>
         ),
         name: 'days',
         headerClassName: 'm-0 p-0 overflow-scroll',
      },
   ];

   const onCellSelect = ({ field, rowData }) => {
      switch (field) {
         case 'machineCode':
            navigateToMachine(rowData);
            break;
         case 'supplierCustomer.companyName':
            navigateToSupplier(rowData);
            break;
         case 'order.orderNumber':
            navigateToOrder(rowData);
            break;
         case 'customer.companyName':
            navigateToCustomer(rowData);
            break;
         default:
            break;
      }
   };

   const navigateToMachine = (rowData) => {
      navigation(`/workshop/t-card`, { state: { machineId: rowData.id } });
   };
   const navigateToSupplier = (rowData) => {
      if (rowData.supplierCustomer && rowData.supplierCustomer.id) navigation('/customers', { state: { customerId: rowData.supplierCustomer.id } });
   };
   const navigateToOrder = (rowData) => {
      if (rowData.order && rowData.order.id) navigation('/commerce/contracts', { state: { orderId: rowData.order.id } });
   };
   const navigateToCustomer = (rowData) => {
      if (rowData.customer && rowData.customer.id) navigation('/customers', { state: { customerId: rowData.customer.id } });
   };
   return (
      <div className="flex flex-col w-full">
         <Card className="mt-4">
            <div className="flex  justify-center">
               <SelectButton
                  className="mb-5"
                  value={selectedTable}
                  options={tableOptions}
                  optionLabel="label"
                  onChange={(e) => {
                     if (!e.value) return;
                     setSelectedTable(e.value);
                  }}
               />
            </div>
            {/* HEADER */}
            <div className="grid justify-between md:mb-4 ">
               <div className="lg:col-4 col-12 flex">
                  <div className="p-inputgroup" style={{ maxWidth: '200px' }}>
                     <InputText
                        value={search}
                        id="tableSearch"
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder={t('global.search')}
                        size="small"
                        style={{ background: '#f9f9f9', border: 'none' }}
                        onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                              getVehiclesQuery(
                                 skip,
                                 first,
                                 selectedDate,
                                 moment(selectedDate).add(20, 'days').format('YYYY-MM-DD'),
                                 search,
                                 selectedCostemer,
                                 selectedMachineGroup,
                              );
                           }
                        }}
                     />
                     <Button
                        icon="pi pi-search"
                        size="small"
                        onClick={() =>
                           getVehiclesQuery(
                              skip,
                              first,
                              selectedDate,
                              moment(selectedDate).add(20, 'days').format('YYYY-MM-DD'),
                              search,
                              selectedCostemer,
                              selectedMachineGroup,
                           )
                        }
                        style={{ background: '#f9f9f9', color: 'black', border: '#f9f9f9 none' }}
                     />
                  </div>
                  <Button
                     icon={areThereFilters() ? 'pi pi-filter-slash' : ' pi pi-filter'}
                     className="ml-3"
                     size="small"
                     onClick={() => {
                        areThereFilters() ? resetFilters() : setShowFilterModal(true);
                     }}
                     severity={areThereFilters() ? 'danger' : 'primary'}
                  />
               </div>
               <div className="lg:col-8 col-12 flex items-center lg:justify-end">
                  <div className=" flex flex-col lg:flex-row md:justify-center justify-center items-center me-2 ">
                     <span className="h-[20px] w-[20px] rounded-full bg-[#8eb3f9] me-2" />
                     <span>{t('pages.planning.returnPlanned')}</span>
                  </div>
                  <div className=" flex  flex-col lg:flex-row md:justify-center justify-center items-center me-2">
                     <span className="h-[20px] w-[20px] rounded-full bg-[#85de7f] me-2" />
                     <span>{t('pages.planning.available')}</span>
                  </div>
                  <div className=" flex flex-col lg:flex-row md:justify-center justify-center items-center me-2">
                     <span className="h-[20px] w-[20px] rounded-full bg-[#f5b0b0] me-2" />
                     <span>{t('pages.planning.reserved')}</span>
                  </div>
                  <div className=" flex flex-col lg:flex-row md:justify-center justify-center items-center me-2">
                     <span className="h-[20px] w-[20px] rounded-full bg-[#f0ad4e] me-2" />
                     <span>{t('pages.planning.rent')}</span>
                  </div>
                  <div className=" flex flex-col lg:flex-row md:justify-center justify-center items-center me-2">
                     <span className="h-[20px] w-[20px] rounded-full bg-[#ef5d4e] me-2" />
                     <span>{t('pages.planning.malfunction')}</span>
                  </div>
                  <div className=" flex flex-col lg:flex-row md:justify-center justify-center items-center me-2">
                     <span className="h-[20px] w-[20px] rounded-full bg-[#e8e824] me-2" />
                     <span>{t('pages.machines.inspection')}</span>
                  </div>

                  <Calendar
                     value={new Date(selectedDate)}
                     onChange={(e) => setSelectedDate(moment(e.value).format('YYYY-MM-DD'))}
                     dateFormat="dd/mm/yy"
                     minDate={new Date()}
                     className="w-[100px] me-2"
                  />
                  <Button icon="pi pi-arrow-right" onClick={() => setSelectedDate(moment(selectedDate).add(20, 'days').format('YYYY-MM-DD'))} />
               </div>
            </div>
            <TableComponent
               data={vehiclesData}
               columns={columns.filter((x) => Object.keys(x).length > 0)}
               size={'small'}
               cellSelection={true}
               onCellSelect={onCellSelect}
            />
            <div className="flex items-center justify-center">
               <Paginator first={skip} rows={first} totalRecords={count} onPageChange={(e) => setSkip(e.first)}></Paginator>
            </div>
         </Card>
         {/*Filters*/}
         <Dialog header={t('global.filters')} visible={showFilterModal} onHide={() => setShowFilterModal(false)} className="md-width-dialog" draggable={false}>
            <div className="grid">
               <div className="flex flex-wrap md:col-6 col-12">
                  <InputNumber
                     value={first}
                     onValueChange={(e) => setFirst(e.value)}
                     showButtons
                     buttonLayout="horizontal"
                     step={1}
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                     className="w-full input-detail"
                     min={1}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <span className="p-input-icon-right  w-full input-detail">
                     <InputText placeholder={t('global.search')} value={search} onChange={(e) => setSearch(e.target.value)} className="w-full" />
                     <i
                        className={`pi pi-${search !== '' ? 'times' : 'search'}`}
                        onClick={() => {
                           search !== '' && setSearch('');
                        }}
                     />
                  </span>
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <Dropdown
                     filter
                     value={selectedCostemer}
                     options={customers.filter((x) => x.customerKindId === '3')}
                     onChange={(e) => setSelectedCostemer(e.value)}
                     optionLabel="companyName"
                     optionValue="id"
                     placeholder={t('pages.planning.supplier')}
                     className="w-full input-detail"
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <Dropdown
                     filter
                     value={selectedMachineGroup}
                     options={machineGroups}
                     onChange={(e) => setSelectedMachineGroup(e.value)}
                     optionLabel="name"
                     optionValue="id"
                     placeholder={t('pages.planning.machineGroup')}
                     className="w-full input-detail"
                  />
               </div>
            </div>
            <div className="grid  justify-end mt-2">
               <Button
                  icon="pi pi-check"
                  label={t('global.apply')}
                  onClick={() => {
                     getVehiclesQuery(
                        skip,
                        first,
                        selectedDate,
                        moment(selectedDate).add(20, 'days').format('YYYY-MM-DD'),
                        search,
                        selectedCostemer,
                        selectedMachineGroup,
                     );
                     setShowFilterModal(false);
                  }}
                  size="small"
               />
            </div>
         </Dialog>
      </div>
   );
};

export default Planning;
