import TableComponent from '../../../components/TableComponent';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { t } from 'i18next';
import { createCustomerContact, getCompanyContacts, getTitles, updateCustomerContact } from '../../../store/apis/Customers';

const Contacts = (props) => {
	// const { roles } = useSelector((state) => state.shared);

	const { selectedCustomerId } = useSelector((state) => state.customer);

	const [showContactDetails, setShowContactDetails] = useState(false);
	const dispatch = useDispatch();

	const { contacts } = useSelector((state) => state.customer);

	const [titles, setTitles] = useState();

	const [contactsInputs, setContactsInputs] = useState({
		title: null,
		firstName: '',
		lastName: '',
		role: '',
		phone: '',
		email: '',
		description: '',
	});

	const [selectedContact, setSelectedContact] = useState();

	const [errors, setErrors] = useState({});
	const [isEmailValid, setIsEmailValid] = useState(true);
	const [isPhoneValid, setIsPhoneValid] = useState(true);

	const tableColumns = [
		{
			name: 'fullName',
			header: t('global.table.fullName'),
			body: (rowData) => {
				return (
					<span>
						{rowData.firstName} {rowData.lastName}
					</span>
				);
			},
		},
		{
			name: 'role',
			header: t('global.table.role'),
			field: 'role',
		},
		{
			name: 'phone',
			header: t('global.table.phoneNumber'),
			field: 'phone',
		},
		{
			name: 'email',
			header: t('global.table.email'),
			field: 'email',
		},
		{
			name: 'comments',
			header: t('global.table.comment'),
			body: (rowData) => {
				return <span>{rowData.description}</span>;
			},
			field: 'comments',
		},
	];

	useEffect(() => {
		dispatch(getTitles()).then((res) => setTitles(res));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getCompanyContacts(selectedCustomerId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleContactSave = () => {
		if (selectedContact) {
			if (validateForm()) {
				dispatch(updateCustomerContact(selectedContact, selectedCustomerId)).then((res) => {
					if (res) {
						toast.success(t('global.updateSuccess'));
						setSelectedContact(null);
						setShowContactDetails(false);
						resetNewContactVariables();
					}
				});
			}
		} else {
			if (validateForm()) {
				dispatch(createCustomerContact(contactsInputs, props.customerData.id)).then((res) => {
					if (res) {
						toast.success('Contact created successfully!');
						setShowContactDetails(false);
						resetNewContactVariables();
					}
				});
			}
		}
	};

	// FORM VALIDATION
	const updateErrors = (name, value) => {
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? t('global.requiredField') : '',
		}));
	};

	const handleInputChange = (name, value) => {
		if (selectedContact) {
			setSelectedContact({ ...selectedContact, [name]: value });
		}
		setContactsInputs({
			...contactsInputs,
			[name]: value,
		});
		updateErrors(name, value);
	};

	const validateForm = () => {
		const inputs = ['title', 'firstName', 'lastName', 'role', 'phone', 'email'];
		let isValid = true;
		if (selectedContact) {
			inputs.forEach((input) => {
				if (!selectedContact[input]) {
					updateErrors(input, selectedContact[input]);
					isValid = false;
				}
			});
		} else {
			inputs.forEach((input) => {
				if (!contactsInputs[input]) {
					updateErrors(input, contactsInputs[input]);
					isValid = false;
					console.log(input);
				}
			});
		}
		return isValid;
	};

	const resetNewContactVariables = () => {
		setContactsInputs({
			title: null,
			firstName: '',
			lastName: '',
			role: '',
			phone: '',
			email: '',
			description: '',
		});
	};

	const handleRowClick = (e) => {
		console.log(e);
		setSelectedContact(e);
		setShowContactDetails(true);
	};

	return (
		<div>
			<div>
				<div className="flex flex-wrap justify-content-end mt-2">
					<Button
						className=" col-12 md:col-3"
						style={{ background: '#38b000', border: ' 1.5px solid #38b000' }}
						label={t('global.table.addNewContact')}
						onClick={() => {
							setShowContactDetails(true);
							setSelectedContact(null);
						}}
					></Button>
				</div>
				<div className="col-7">
					<TableComponent data={contacts} onRowClick={(e) => handleRowClick(e)} columns={tableColumns} size="medium" />
				</div>
				<Dialog
					visible={showContactDetails}
					draggable={false}
					onHide={() => {
						setShowContactDetails(false);
						resetNewContactVariables();
						setErrors({});
					}}
					header={selectedContact ? t('pages.customers.contactDetails') : t('pages.customers.addNewContact')}
					className="md-width-dialog"
				>
					<div className="grid">
						<div className="col-12 md:col-6 lg:col-6">
							<label>{t('global.table.honoraryTitle')}*</label>
							<Dropdown
								options={titles}
								optionLabel="name"
								optionValue="id"
								onChange={(e) => {
									if (selectedContact) {
										handleInputChange('title', { id: e.value });
									} else {
										handleInputChange('title', e.value);
									}
								}}
								value={selectedContact ? selectedContact.title.id : contactsInputs.title}
								className={`w-full input-detail ${errors.title && 'input-error'}`}
								showClear={!selectedContact}
							/>
							{errors.title && <small className="p-error">{errors.title}</small>}
						</div>
						<div className="col-12 md:col-6 lg:col-6">
							<label>{t('global.table.firstName')}*</label>
							<InputText
								value={selectedContact ? selectedContact.firstName : contactsInputs.firstName}
								onChange={(e) => {
									handleInputChange('firstName', e.target.value);
								}}
								className={`w-full input-detail ${errors.firstName && 'input-error'}`}
							/>
							{errors.firstName && <small className="p-error">{errors.firstName}</small>}
						</div>
						<div className="col-12 md:col-6 lg:col-6">
							<label>{t('global.table.lastName')}*</label>
							<InputText
								value={selectedContact ? selectedContact.lastName : contactsInputs.lastName}
								onChange={(e) => {
									handleInputChange('lastName', e.target.value);
								}}
								className={`w-full input-detail ${errors.lastName && 'input-error'}`}
							/>
							{errors.lastName && <small className="p-error">{errors.lastName}</small>}
						</div>
						<div className="col-12 md:col-6 lg:col-6">
							<label>{t('global.table.role')}*</label>
							<InputText
								value={selectedContact ? selectedContact.role : contactsInputs.role}
								onChange={(e) => {
									// if (selectedContact) {
									// 	setSelectedContact({ ...selectedContact, role: e.target.value });
									// } else {
									// 	setContactsInputs({ ...contactsInputs, role: e.target.value });
									// }
									handleInputChange('role', e.target.value);
								}}
								className={`w-full input-detail ${errors.role && 'input-error'}`}
							/>
							{errors.role && <small className="p-error">{errors.role}</small>}
						</div>
						<div className="col-12 md:col-6 lg:col-6">
							<label className="w-full">{t('global.table.phoneNumber')}*</label>
							<InputText
								value={selectedContact ? selectedContact.phone : contactsInputs.phone}
								onChange={(e) => {
									// if (selectedContact) {
									// 	setSelectedContact({ ...selectedContact, phone: e.target.value });
									// } else {
									// 	setContactsInputs({ ...contactsInputs, phone: e.target.value });
									// }
									handleInputChange('phone', e.target.value);
								}}
								className={`w-full input-detail ${errors.phone && 'input-error'}`}
							/>
							{errors.phone && <small className="p-error">{errors.phone}</small>}
						</div>
						<div className="col-12 md:col-6 lg:col-6">
							<label className="w-full">{t('global.table.email')}*</label>
							<InputText
								type="email"
								value={selectedContact ? selectedContact.email : contactsInputs.email}
								onChange={(e) => {
									// if (selectedContact) {
									// 	setSelectedContact({ ...selectedContact, email: e.target.value });
									// } else {
									// 	setContactsInputs({ ...contactsInputs, email: e.target.value });
									// }
									handleInputChange('email', e.target.value);
								}}
								className={`w-full input-detail ${errors.email && 'input-error'}`}
							/>
							{errors.email && <small className="p-error">{errors.email}</small>}
						</div>
						<div className="col-12">
							<label>{t('global.table.explanation')}</label>
							<InputTextarea
								value={selectedContact ? selectedContact.description : contactsInputs.description}
								onChange={(e) => {
									handleInputChange('description', e.target.value);
								}}
								className="w-full input-detail"
							/>
						</div>
					</div>
					<div className="grid mt-3">
						<div className="col-12 flex justify-end">
							<Button label={selectedContact ? t('global.update') : t('global.create')} icon="pi pi-check" onClick={() => handleContactSave()} />
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	);
};

export default Contacts;
