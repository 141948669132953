import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import CreateInformationAddress from './CreateInformationAddress';
import { getAddresses, getCustomers, updateDetailOffer } from '../../../../store/apis/OffersApi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import RentOffer1 from '../../../../components/PDFS/RentOffer/RentOffer1';
import SaleOfferPDF from '../../../../components/PDFS/SaleOfferPDF';

const Informations = (props) => {
   const { offer } = useSelector((state) => state.offers);
   const { user } = useSelector((state) => state.user);
   const navigation = useNavigate();
   const dispatch = useDispatch();
   const [customers, setCustomers] = useState([]);
   const [addresses, setAddresses] = useState([]);
   const [errors, setErrors] = useState({});
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [informationsInputs, setInformationsInputs] = useState({
      orderNumber: '',
      customerId: null,
      addressId: null,
      referenceNumber: '',
      description: '',
      privacyDescription: '',
      isFactor: false,
   });
   const [contactName, setContactName] = useState('');

   useEffect(() => {
      if (addresses && addresses.length > 0 && informationsInputs.addressId) {
         const address = addresses.find((address) => +address.id === +informationsInputs.addressId);
         setContactName(address.receiverName);
      } else {
         setContactName('');
      }
   }, [addresses, informationsInputs.addressId]);

   useEffect(() => {
      dispatch(getCustomers()).then((res) => setCustomers(res));
      setInformationsInputs({
         orderNumber: offer.orderNumber || '',
         customerId: offer.customer?.id || null,
         addressId: offer.address?.id || null,
         referenceNumber: offer.referenceNumber || '',
         description: offer.description || '',
         privacyDescription: offer.privacyDescription || '',
         isFactor: offer.isFactor || false,
         isRefused: offer.isRefused || false,
      });
   }, []);
   const [navigateCondition, setNavigateCondition] = useState(false);
   const [customerIdName, setCustomerIdName] = useState([]);
   const [selectedAddressId, setSelectedAddressId] = useState(null);

   useEffect(() => {
      if (navigateCondition) {
         navigation('/commerce/contracts', { state: { orderId: offer?.id } });
         setNavigateCondition(false);
      }
   }, [navigateCondition]);

   useEffect(() => {
      if (informationsInputs.customerId) {
         dispatch(getAddresses({ customerId: informationsInputs.customerId })).then((res) => setAddresses(res));
      }
   }, [informationsInputs.customerId]);

   useEffect(() => {
      if (createdAddress) {
         if (selectedAddressId) {
            setAddresses(addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)));
            setCustomerIdName(
               customerIdName.map((customer) => {
                  if (customer.id === informationsInputs.customerId) {
                     return {
                        ...customer,
                        addresses: customer.addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)),
                     };
                  }
                  return customer;
               }),
            );
            setInformationsInputs({ ...informationsInputs, addressId: createdAddress.id });
         } else {
            setAddresses([...addresses, createdAddress]);
            setInformationsInputs({ ...informationsInputs, addressId: createdAddress.id });
         }
      }
   }, [createdAddress]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (value, name) => {
      if (name === 'isContracted') {
         const customerKind = customers.find((customer) => +customer.id === +informationsInputs.customerId)?.customerKind?.name;
         if (customerKind === 'PROSPECT') {
            toast.error(t('pages.offers.contractedRoleError'));
            return;
         }
      }
      setInformationsInputs((prev) => ({ ...prev, [name]: value }));
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['customerId', 'addressId'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!informationsInputs[input]) {
            updateErrors(input, informationsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
         const data = {
            id: offer.id,
            contractedDate: informationsInputs.isContracted ? moment().format('YYYY-MM-DD') : undefined,
            ...informationsInputs,
         };
         delete data.orderKindId;
         delete data.orderNumber;
         console.log(data);
         dispatch(updateDetailOffer(data)).then((res) => {
            if (res) {
               if (res.isContracted) {
                  if (res.orderItems.filter((item) => item.category === 'SUP').length > 0) {
                     toast.success(t('pages.offers.contractedSupplierWarning'));
                  } else {
                     toast.success(t('global.updateSuccess'));
                  }
                  props.setOfferDetailVisible(false);
                  setNavigateCondition(true);
                  return;
               }
               toast.success(t('global.updateSuccess'));
            }
         });
      }
   };

   const updateAddress = () => {
      setSelectedAddressId(informationsInputs.addressId);
      setCreateAddressDialog(true);
   };

   return (
      <>
         <div className="grid">
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.contracts.number')}*</label>
               <InputText
                  value={informationsInputs.orderNumber}
                  onChange={(e) => handleInputChange(e.target.value, 'orderNumber')}
                  className="w-full input-detail"
                  disabled
               />
               {errors.orderNumber && <small className="p-error">{errors.orderNumber}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.customer')}*</label>
               <Dropdown
                  filter
                  options={customers}
                  value={informationsInputs.customerId}
                  onChange={(e) => {
                     handleInputChange(e.target.value, 'customerId');
                     handleInputChange(null, 'addressId');
                  }}
                  optionLabel="companyName"
                  optionValue="id"
                  className={`w-full input-detail ${errors.customerId && 'input-error'}`}
               />
               {errors.customerId && <small className="p-error">{errors.customerId}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
               <div style={{ marginRight: '10px', flex: '1' }}>
                  <label>{t('global.table.placeName')}*</label>
                  <Dropdown
                     filter
                     options={addresses}
                     value={informationsInputs.addressId}
                     onChange={(e) => handleInputChange(e.target.value, 'addressId')}
                     optionLabel="placeName"
                     optionValue="id"
                     className={`w-full input-detail ${errors.addressId && 'input-error'}`}
                     showClear
                     disabled={!informationsInputs.customerId}
                  />
                  {errors.addressId && <small className="p-error">{errors.addressId}</small>}
               </div>
               {informationsInputs.addressId && informationsInputs.customerId && (
                  <Button
                     icon="pi pi-pencil"
                     severity="warning"
                     className="mt-4 me-2 h-[35px] w-[35px]"
                     onClick={updateAddress}
                     disabled={!informationsInputs.customerId}
                  />
               )}
               <Button
                  icon="pi pi-plus"
                  className="mt-4 h-[35px] w-[35px]"
                  onClick={() => setCreateAddressDialog(true)}
                  disabled={!informationsInputs.customerId}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.receiverName')}</label>
               <InputText value={contactName} onChange={(e) => setContactName(e.target.value)} className="w-full input-detail" maxLength={50} disabled />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.poNumber')}</label>
               <InputText
                  value={informationsInputs.referenceNumber}
                  onChange={(e) => handleInputChange(e.target.value, 'referenceNumber')}
                  className="w-full input-detail"
                  maxLength={50}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('global.table.explanation')}</label>
               <InputText
                  value={informationsInputs.description}
                  onChange={(e) => handleInputChange(e.target.value, 'description')}
                  className="w-full input-detail"
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.privacyExplanation')}</label>
               <InputText
                  value={informationsInputs.privacyDescription}
                  onChange={(e) => handleInputChange(e.target.value, 'privacyDescription')}
                  className="w-full input-detail"
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <div className="flex items-center ">
                  <label className="mb-2">{t('pages.offers.factor')}</label>
                  <div>
                     <InputSwitch checked={informationsInputs.isFactor} onChange={(e) => handleInputChange(e.target.value, 'isFactor')} />
                  </div>
               </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4 ">
               <div className="flex justify-between">
                  <label>{t('pages.offers.offered')}</label>
                  <div>
                     <InputSwitch disabled checked={true} onChange={(e) => handleInputChange(e.target.value, 'isOffered')} />
                  </div>
               </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <div className="flex">
                  <label>{t('pages.contracts.contracted')}</label>
                  <div>
                     <InputSwitch checked={informationsInputs.isContracted} onChange={(e) => handleInputChange(e.target.value, 'isContracted')} />
                  </div>
               </div>
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <div className="flex">
                  <label>{t('pages.offers.refused')}</label>
                  <div>
                     <InputSwitch checked={informationsInputs.isRefused} onChange={(e) => handleInputChange(e.target.value, 'isRefused')} />
                  </div>
               </div>
            </div>
         </div>

         <div className="flex justify-between">
            <div className="w-[300px]">
               {offer?.orderKind?.name === 'RENT' ? <RentOffer1 data={offer} header={'offer'} /> : <SaleOfferPDF data={offer} header={'offer'} />}
            </div>

            <Button label={t('global.update')} iconPos="right" onClick={handleSubmit} />
         </div>

         <Dialog
            header={selectedAddressId ? t('pages.offers.updateAddress') : t('pages.offers.createANewAddress')}
            className="w-[70vw]"
            visible={createAddressDialog}
            onHide={() => {
               setCreateAddressDialog(false);
               setSelectedAddressId(null);
            }}
         >
            <CreateInformationAddress
               setCreateAddressDialog={setCreateAddressDialog}
               setCreatedAddress={setCreatedAddress}
               customerId={informationsInputs.customerId}
               selectedAddressId={selectedAddressId}
            />
         </Dialog>
      </>
   );
};

export default Informations;
