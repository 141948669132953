import { gql } from '@apollo/client';

export const GET_VEHICLE_MALFUNCTION_COUNT = gql`
   query vehicleMalfunctionCount($search: String, $filters: VehicleMalfunctionFilter) {
      vehicleMalfunctionCount(search: $search, filters: $filters)
   }
`;

export const GET_VEHICLE_MALFUNCTIONS = gql`
   query vehicleMalfunctions($skip: Int, $first: Int, $search: String, $filters: VehicleMalfunctionFilter) {
      vehicleMalfunctions(skip: $skip, first: $first, search: $search, filters: $filters) {
         id
         isCompleted
         vehicleMalfunctionKind {
            id
            name
         }
         faultDate
         technicianUser {
            id
            name
         }
         vehicle {
            id
            machineCode
            machineFullCode
         }
         customer {
            id
            companyName
            user {
               id
               name
            }
         }
         address {
            id
            postCode
            city
         }
         customerSignature
      }
   }
`;

export const GET_VEHICLE_MALFUNCTION_KINDS = gql`
   query vehicleMalfunctionKinds {
      vehicleMalfunctionKinds {
         id
         name
      }
   }
`;

export const GET_VEHICLE_ID_MACHINE_CODE = gql`
   query vehicles($filters: VehicleFilter) {
      vehicles(filters: $filters) {
         id
         machineCode
         machineFullCode
         status
         vehicleGroup {
            id
            name
         }
      }
   }
`;

export const GET_CUSTOMER_ID_NAME = gql`
   query customers {
      customers {
         id
         companyName
         addresses {
            id
            placeName
         }
      }
   }
`;

export const CREATE_VEHICLE_MALFUNCTION = gql`
   mutation createVehicleMalfunction($data: CreateVehicleMalfunctionInput!) {
      createVehicleMalfunction(data: $data) {
         vehicleMalfunction {
            id
            faultDate
            isCompleted
            technicianUser {
               id
               name
            }
            vehicle {
               id
               machineCode
               machineFullCode
               status
            }
            customer {
               id
               user {
                  id
                  name
               }
            }
            address {
               id
               postCode
               city
            }
         }
      }
   }
`;

export const UPDATE_VEHICLE_MALFUNCTION = gql`
   mutation updateVehicleMalfunction($data: UpdateVehicleMalfunctionInput!) {
      updateVehicleMalfunction(data: $data) {
         vehicleMalfunction {
            id
            faultDate
            isCompleted
            customerSignature
            technicianUser {
               id
               name
            }
            vehicle {
               id
               machineCode
               machineFullCode
               status
            }
            customer {
               id
               user {
                  id
                  name
               }
            }
            address {
               id
               postCode
               city
            }
         }
      }
   }
`;

export const GET_VEHICLE_MALFUNCTION = gql`
   query vehicleMalfunction($id: ID!) {
      vehicleMalfunction(id: $id) {
         id
         customerSignature
         vehicle {
            id
         }
         vehicleMalfunctionKind {
            id
            name
         }
         faultDate
         description
         note
         contactName
         contactPhone
         contactEmail
         customer {
            id
            companyName
            addresses {
               id
               placeName
            }
         }
         address {
            id
         }
         isMalfunctionOperatorDefault
         isMalfunctionBatteryFailure
         isMalfunctionEngineFailure
         isMalfunctionBodyDamage
         isMalfunctionImproperHandling
         isMalfunctionChargerFailure
         isMalfunctionElectricalFailure
         isMalfunctionSafetyAccessoriesFailure
         isMalfunctionRemoteControlFailure
         isMalfunctionBatteryCharger
         isMalfunctionTornCable
         isMalfunctionHydraulicFailure
         isMalfunctionOther
         malfunctionOther
         isSolutionWarranty
         isSolutionVehicleReplacement
         isSolutionMaintenance
         isSolutionDepotRepair
         isSolutionDefectDepotPreparation
         isSolutionOnSiteRepair
         isSolutionAfterSalesServicePickup
         isSolutionOther
         isJobDone
         isPartsAndSmallSupplies
         solutionOther
         isInvoiced
         isMakeAnOffer
         isMaintenanceContract
         technicianDepartureDate
         technicianDepartureTime
         technicianArrivalTime
         technicianNote
         technicianUserId
         isCompleted
         isMalfunctionOperatorDefaultTechnician
         isMalfunctionBatteryFailureTechnician
         isMalfunctionEngineFailureTechnician
         isMalfunctionBodyDamageTechnician
         isMalfunctionImproperHandlingTechnician
         isMalfunctionChargerFailureTechnician
         isMalfunctionElectricalFailureTechnician
         isMalfunctionSafetyAccessoriesFailureTechnician
         isMalfunctionRemoteControlFailureTechnician
         isMalfunctionBatteryChargerTechnician
         isMalfunctionTornCableTechnician
         isMalfunctionHydraulicFailureTechnician
         isMalfunctionOtherTechnician
         malfunctionOtherTechnician
      }
   }
`;

export const GET_TECHNICIANS = gql`
   query technicians {
      users {
         id
         name
      }
   }
`;

export const GET_VEHICLE_MALFUNCTION_DOCUMENTS = gql`
   query vehicleMalfunctionDocuments($vehicleMalfunctionId: ID!) {
      vehicleMalfunctionDocuments(vehicleMalfunctionId: $vehicleMalfunctionId) {
         id
         document {
            url
            size
            name
            path
         }
      }
   }
`;

export const CREATE_VEHICLE_MALFUNCTION_DOCUMENT = gql`
   mutation createVehicleMalfunctionDocuments($data: CreateVehicleMalfunctionDocumentInput!) {
      createVehicleMalfunctionDocument(data: $data) {
         vehicleMalfunctionDocument {
            id
            document {
               url
               size
               name
               path
            }
         }
      }
   }
`;

export const DELETE_VEHICLE_MALFUNCTION_DOCUMENT = gql`
   mutation deleteVehicleMalfunctionDocument($ids: [Int!]!) {
      deleteVehicleMalfunctionDocuments(ids: $ids) {
         success
      }
   }
`;

export const GET_ADDRESS = gql`
   query getAddress($id: ID!) {
      address(id: $id) {
         id
         addressKindId
         city
         country
         customerId
         placeName
         receiverName
         districtName
         postCode
         address
         addressContinuation
      }
   }
`;

export const UPDATE_ADDRESS = gql`
   mutation updateAddress($data: UpdateAddressInput!) {
      updateAddress(data: $data) {
         address {
            id
            addressKindId
            city
            country
            customerId
            placeName
            receiverName
            districtName
            postCode
            address
            addressContinuation
         }
      }
   }
`;

export const GET_VEHICLE_RESERVATIONS = gql`
   query vehicleReservations($date: Date, $statuses: [ReservationStatus!], $isContracted: Boolean, $isSupplier: Boolean) {
      vehicleReservations(date: $date, statuses: $statuses, isContracted: $isContracted, isSupplier: $isSupplier) {
         id
         beginDate
         endDate
         status
         vehicleReservationControlId
         vehicleGroup {
            id
            name
         }
         vehicle {
            id
            machineFullCode
            guaranteeNextDate
            guaranteeNextRevision
         }
         orderItems {
            id
            order {
               id
               orderNumber
               address {
                  id
                  city
               }
            }
         }
         customer {
            id
            companyName
         }
      }
   }
`;

export const UPDATE_VEHICLE_RESERVATION = gql`
   mutation updateVehicleReservation($data: UpdateVehicleReservationInput!) {
      updateVehicleReservation(data: $data) {
         vehicleReservation {
            id
            beginDate
            vehicleReservationControlId
            status
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
               guaranteeNextDate
               guaranteeNextRevision
            }
            orderItems {
               id
               order {
                  id
                  orderNumber
                  address {
                     id
                     city
                  }
               }
            }
            customer {
               id
               companyName
            }
         }
      }
   }
`;

export const GET_VEHICLE_RESERVATION_CONTROL = gql`
   query vehicleReservationControl($id: ID!) {
      vehicleReservationControl(id: $id) {
         id
         date
         time
         mileage
         atelierDescription
         isKey
         isVgp
         isDocuments
         chassisStatus
         basketStatus
         batteryStatus
         cylindersStatus
         doorsStatus
         tiresStatus
         lowerBodyStatus
         upperBodyStatus
         paintStatus
         vehicleReservationControlDocuments {
            id
            document {
               url
               size
               name
               path
            }
         }
         vehicleReservationId
      }
   }
`;

export const CREATE_VEHICLE_RESERVATION_CONTROL = gql`
   mutation createVehicleReservationControl($data: CreateVehicleReservationControlInput!) {
      createVehicleReservationControl(data: $data) {
         vehicleReservationControl {
            id
            vehicleReservationId
            date
            time
            mileage
            atelierDescription
            isKey
            isVgp
            isDocuments
            chassisStatus
            basketStatus
            batteryStatus
            cylindersStatus
            doorsStatus
            tiresStatus
            lowerBodyStatus
            upperBodyStatus
            paintStatus
         }
      }
   }
`;

export const UPDATE_VEHICLE_RESERVATION_CONTROL = gql`
   mutation updateVehicleReservationControl($data: UpdateVehicleReservationControlInput!) {
      updateVehicleReservationControl(data: $data) {
         vehicleReservationControl {
            id
            vehicleReservationId
            date
            time
            mileage
            atelierDescription
            isKey
            isVgp
            isDocuments
            chassisStatus
            basketStatus
            batteryStatus
            cylindersStatus
            doorsStatus
            tiresStatus
            lowerBodyStatus
            upperBodyStatus
            paintStatus
         }
      }
   }
`;

export const DELETE_VEHICLE_RESERVATION_CONTROLS = gql`
   mutation deleteVehicleReservationControls($ids: [Int!]!) {
      deleteVehicleReservationControls(ids: $ids) {
         success
      }
   }
`;

export const CREATE_VEHICLE_RESERVATION_CONTROL_DOCUMENT = gql`
   mutation createVehicleReservationControlDocument($data: CreateVehicleReservationControlDocumentInput!) {
      createVehicleReservationControlDocument(data: $data) {
         vehicleReservationControlDocuments {
            id
            document {
               url
               size
               name
               path
            }
         }
      }
   }
`;

export const DELETE_VEHICLE_RESERVATION_CONTROL_DOCUMENT = gql`
   mutation deleteVehicleReservationControlDocument($ids: [Int!]!) {
      deleteVehicleReservationControlDocuments(ids: $ids) {
         success
      }
   }
`;
