import React from 'react';
import { Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import { Button } from 'primereact/button';
import moment from 'moment';
import { t } from 'i18next';

const FollowUpList = ({ data }) => {
   const styles = StyleSheet.create({
      table: {
         display: 'table',
         width: 'auto',
         borderStyle: 'solid',
         borderColor: '#bfbfbf',
         borderWidth: 1,
         borderRightWidth: 0,
         borderBottomWidth: 0,
      },
      tableRow: {
         margin: 'auto',
         flexDirection: 'row',
      },
      tableCol: {
         width: '8%',
         borderStyle: 'solid',
         borderColor: '#bfbfbf',
         borderBottomWidth: 1,
         borderRightWidth: 1,
      },
      tableCell: {
         margin: 'auto',
         marginTop: 8,
         fontSize: 7,
      },
   });

   const downloadPDF = async (pdfData) => {
      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }} orientation="landscape">
               <Text style={{ textAlign: 'center', fontSize: 12, marginBottom: 20, fontFamily: 'Helvetica-Bold' }}>
                  {t('pages.invoices.unpaidInvoicesHeader') + ' ' + moment().add(-1, 'day').format('DD/MM/YYYY')}
               </Text>
               <View style={{ flexGrow: 1, marginBottom: 50 }}>
                  <View style={styles.table}>
                     {/* Tablo başlıkları */}
                     <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.offers.customer')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.phoneNumber')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.invoiceNumber')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.offers.totalAfterTax')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.refund')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.unpaid')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.placeName')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.creationDate')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('pages.invoices.dueDate')}</Text>
                        </View>
                        <View style={styles.tableCol}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.user')}</Text>
                        </View>
                        <View style={[styles.tableCol, { width: '20%' }]}>
                           <Text style={[styles.tableCell, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>{t('global.table.comment')}</Text>
                        </View>
                     </View>
                     {/* Satırlar */}
                     {pdfData &&
                        pdfData.length &&
                        pdfData.map((item, index) => (
                           <View style={styles.tableRow} key={index}>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item.customer?.companyName}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.customer.phone}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.invoiceNumber}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>€{item?.totalAfterTax}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.refund || '-'}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>€{item?.totalAfterTax}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.address.placeName}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{moment(item?.createdAt).format('DD/MM/YYYY')}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{moment(item?.dueDate).format('DD/MM/YYYY')}</Text>
                              </View>
                              <View style={styles.tableCol}>
                                 <Text style={styles.tableCell}>{item?.customer?.followedUser?.name || '-'}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={styles.tableCell}></Text>
                              </View>
                           </View>
                        ))}
                  </View>
               </View>
               {/* Footer */}
               <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                  <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  <View style={{ textAlign: 'right', marginTop: 5 }} fixed>
                     <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                  </View>
               </View>
            </Page>
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(blob, 'logistics_return.pdf'); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   return (
      <>
         <Button
            icon="pi pi-file-pdf"
            severity="info"
            className="!text-[14px] mb-3"
            onClick={() => {
               downloadPDF(data);
            }}
         />
      </>
   );
};

export default FollowUpList;
