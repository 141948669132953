import client from '../../graphql/apollo.config';
import {
   CREATE_INVOICE,
   CREATE_INVOICE_ITEM,
   CREATE_MONTHS_INVOICE,
   CREATE_MONTH_END_INVOICE,
   DELETE_INVOICE,
   DELETE_ORDER_ITEMS,
   GET_CUSTOMER_INVOICES,
   GET_INVOICE,
   GET_INVOICES,
   GET_REFUNDS,
   GET_UNPAID_INVOICES,
   INVOICE_STATISTICS,
   PAY_INVOICES,
   PAY_REFUNDS,
   UNINVOICED_CONTRACTS,
   UPDATE_INVOICE,
   UPDATE_REFUND,
   UPDATE_VEHICLE_STATUS,
} from '../../graphql/management';
import {
   addInvoiceItem,
   addMonthsInvoice,
   handleCreateInvoice,
   handleUpdateInvoice,
   handleUpdateRefund,
   handleUpdateRefundInvoice,
   payInvoice,
   removeInvoice,
   removeOrderItem,
   setCustomerInvoices,
   setInvoice,
   setInvoices,
   setRefunds,
   setUninvoicedContracts,
} from '../slices/managementSlice';
import { removeRequest, setRequest } from '../slices/sharedSlice';

export const getInvoices = (status, first, skip, search, startDate, endDate, paymentMethod) => async (dispatch) => {
   dispatch(setRequest('getInvoices'));
   return client
      .query({
         query: GET_INVOICES,
         variables: {
            status,
            first,
            skip,
            search,
            startDate,
            endDate,
            paymentMethod,
         },
      })
      .then((res) => {
         dispatch(setInvoices(res.data.invoices));
         return res.data;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getInvoices'));
      });
};

export const getInvoice = (id) => async (dispatch) => {
   dispatch(setRequest('getInvoice'));
   return client
      .query({
         query: GET_INVOICE,
         variables: {
            id,
         },
      })
      .then((res) => {
         dispatch(setInvoice(res.data.invoice));
         return res.data.invoice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getInvoice'));
      });
};

export const updateInvoice = (data) => async (dispatch) => {
   dispatch(setRequest('updateInvoiceStatus'));
   return client
      .mutate({
         mutation: UPDATE_INVOICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleUpdateInvoice(res.data.updateInvoice.invoice));
         return res.data.updateInvoice.invoice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateInvoiceStatus'));
      });
};

export const createInvoiceItem = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_OFFER_ITEM'));
   return client
      .mutate({
         mutation: CREATE_INVOICE_ITEM,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(addInvoiceItem({ res: res.data.createOrderItem.orderItem, data }));
         return res.data.createOrderItem.orderItem;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_OFFER_ITEM'));
      });
};

export const createMonthsInvoice = (isPrev) => async (dispatch) => {
   dispatch(setRequest('createMonthsInvoice'));
   return client
      .mutate({
         mutation: CREATE_MONTHS_INVOICE,
         variables: {
            isPrev,
         },
      })
      .then((res) => {
         dispatch(addMonthsInvoice(res.data.createMonthsInvoice.invoices));
         return res.data.createMonthsInvoice.invoices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createMonthsInvoice'));
      });
};

export const deleteInvoice = (id) => async (dispatch) => {
   dispatch(setRequest('deleteInvoice'));
   return client
      .mutate({
         mutation: DELETE_INVOICE,
         variables: {
            id,
         },
      })
      .then((res) => {
         dispatch(removeInvoice(id));
         return res.data.deleteInvoices.success;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('deleteInvoice'));
      });
};

export const handlePayInvoices = (data) => async (dispatch) => {
   dispatch(setRequest('payInvoices'));
   return client
      .mutate({
         mutation: PAY_INVOICES,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(payInvoice(data));
         return res.data.payInvoices.invoices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('payInvoices'));
      });
};

export const handlePayRefunds = (data) => async (dispatch) => {
   dispatch(setRequest('PAY_REFUNDS'));
   return client
      .mutate({
         mutation: PAY_REFUNDS,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(payInvoice(data));
         return res.data.payRefunds.refunds;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('PAY_REFUNDS'));
      });
};

export const getCustomerInvoices = (customerId, status, search) => async (dispatch) => {
   dispatch(setRequest('getCustomerInvoices'));
   return client
      .query({
         query: GET_CUSTOMER_INVOICES,
         variables: {
            customerId,
            status,
            search,
         },
      })
      .then((res) => {
         dispatch(setCustomerInvoices(res.data.invoices));
         return res.data.invoices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getCustomerInvoices'));
      });
};

export const createMonthEndInvoice = () => async (dispatch) => {
   dispatch(setRequest('createMonthEndInvoice'));
   return client
      .mutate({
         mutation: CREATE_MONTH_END_INVOICE,
      })
      .then((res) => {
         console.log(res);
         dispatch(addMonthsInvoice(res.data.createMonthEndInvoice.invoices));
         return res.data.createMonthEndInvoice.invoices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('createMonthEndInvoice'));
      });
};

export const getUninvoicedContracts = () => async (dispatch) => {
   dispatch(setRequest('uninvoicedContracts'));
   return client
      .query({
         query: UNINVOICED_CONTRACTS,
      })
      .then((res) => {
         dispatch(setUninvoicedContracts(res.data.vehicleReservations));
         return res.data.vehicleReservations;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('uninvoicedContracts'));
      });
};

export const createInvoice = (data) => async (dispatch) => {
   dispatch(setRequest('CREATE_INVOICE'));
   return client
      .mutate({
         mutation: CREATE_INVOICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleCreateInvoice(data.vehicleReservationId));
         return res.data.createInvoice.invoice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('CREATE_INVOICE'));
      });
};

export const getUnpaidInvoices = () => async (dispatch) => {
   dispatch(setRequest('getUnpaidInvoices'));
   return client
      .query({
         query: GET_UNPAID_INVOICES,
      })
      .then((res) => {
         return res.data.invoices;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getUnpaidInvoices'));
      });
};

export const getInvoiceStatistics = () => async (dispatch) => {
   dispatch(setRequest('getInvoiceStatistics'));
   return client
      .query({
         query: INVOICE_STATISTICS,
      })
      .then((res) => {
         return res.data.invoiceStatistics;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('getInvoiceStatistics'));
      });
};

export const updateVehicleStatus = (data) => async (dispatch) => {
   dispatch(setRequest('updateVehicleStatus'));
   return client
      .mutate({
         mutation: UPDATE_VEHICLE_STATUS,
         variables: {
            data,
         },
      })
      .then((res) => {
         return res.data.updateVehicle.vehicle;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateVehicleStatus'));
      });
};

export const getRefunds = (first, skip) => async (dispatch) => {
   dispatch(setRequest('GET_REFUNDS'));
   return client
      .query({
         query: GET_REFUNDS,
         variables: {
            first,
            skip,
         },
      })
      .then((res) => {
         dispatch(setRefunds(res.data.refunds));
         return res.data;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('GET_REFUNDS'));
      });
};

export const updateRefund = (data) => async (dispatch) => {
   dispatch(setRequest('UPDATE_REFUND'));
   return client
      .mutate({
         mutation: UPDATE_REFUND,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleUpdateRefund(res.data.updateRefund.refund));
         return res.data.updateRefund.refund;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('UPDATE_REFUND'));
      });
};

export const updateRefundInvoice = (data) => async (dispatch) => {
   dispatch(setRequest('updateInvoiceStatus'));
   return client
      .mutate({
         mutation: UPDATE_INVOICE,
         variables: {
            data,
         },
      })
      .then((res) => {
         dispatch(handleUpdateRefundInvoice(res.data.updateInvoice.invoice));
         return res.data.updateInvoice.invoice;
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('updateInvoiceStatus'));
      });
};

export const deleteOrderItem = (data) => async (dispatch) => {
   dispatch(setRequest('DELETE_ORDER_ITEMS'));
   return client
      .mutate({
         mutation: DELETE_ORDER_ITEMS,
         variables: {
            ids: [+data.id],
            invoiceId: data.invoiceId,
         },
      })
      .then(() => {
         dispatch(removeOrderItem(data));
      })
      .catch((err) => {
         console.log(err);
      })
      .finally(() => {
         dispatch(removeRequest('DELETE_ORDER_ITEMS'));
      });
};
