import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getTechnicians, getVehicleMalfunction, updateVehicleMalfunction } from '../../../store/apis/MalfunctionApi';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import toast from 'react-hot-toast';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';
import SignatureCanvas from 'react-signature-canvas';
import { InputMask } from 'primereact/inputmask';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const Technician = (props) => {
   const dispatch = useDispatch();
   const technicianOptions = useSelector((state) => state.malfunctions.technicians);
   const sigCanvas = useRef();
   const url = process.env.REACT_APP_LINK.split('/graphql')[0];

   const [malfunctionsData, setMalfunctionsData] = useState({
      id: props.selectedId,
      isMalfunctionOperatorDefault: false,
      isMalfunctionBatteryFailure: false,
      isMalfunctionEngineFailure: false,
      isMalfunctionBodyDamage: false,
      isMalfunctionImproperHandling: false,
      isMalfunctionChargerFailure: false,
      isMalfunctionElectricalFailure: false,
      isMalfunctionSafetyAccessoriesFailure: false,
      isMalfunctionRemoteControlFailure: false,
      isMalfunctionBatteryCharger: false,
      isMalfunctionTornCable: false,
      isMalfunctionHydraulicFailure: false,
      isMalfunctionOther: false,
      malfunctionOther: '',
   });

   const [solutionsData, setSolutionsData] = useState({
      isSolutionWarranty: false,
      isSolutionVehicleReplacement: false,
      isSolutionMaintenance: false,
      isSolutionDepotRepair: false,
      isSolutionDefectDepotPreparation: false,
      isSolutionOnSiteRepair: false,
      isSolutionAfterSalesServicePickup: false,
      isSolutionOther: false,
      isJobDone: false,
      isPartsAndSmallSupplies: false,
      solutionOther: '',
      isInvoiced: false,
      isMakeAnOffer: false,
      isMaintenanceContract: false,
      technicianDepartureDate: '',
      technicianDepartureTime: null,
      technicianArrivalTime: null,
      technicianNote: '',
      technicianUserId: null,
   });

   const [customerSignature, setCustomerSignature] = useState('');
   const [signed, setSigned] = useState(false);

   useEffect(() => {
      dispatch(getTechnicians());
      dispatch(getVehicleMalfunction({ id: props.selectedId, malfunctions: true, solutions: true })).then((res) => {
         setSolutionsData({
            id: res.id,
            isSolutionWarranty: res.isSolutionWarranty,
            isSolutionVehicleReplacement: res.isSolutionVehicleReplacement,
            isSolutionMaintenance: res.isSolutionMaintenance,
            isSolutionDepotRepair: res.isSolutionDepotRepair,
            isSolutionDefectDepotPreparation: res.isSolutionDefectDepotPreparation,
            isSolutionOnSiteRepair: res.isSolutionOnSiteRepair,
            isSolutionAfterSalesServicePickup: res.isSolutionAfterSalesServicePickup,
            isSolutionOther: res.isSolutionOther,
            isJobDone: res.isJobDone,
            isPartsAndSmallSupplies: res.isPartsAndSmallSupplies,
            solutionOther: res.solutionOther,
            isInvoiced: res.isInvoiced,
            isMakeAnOffer: res.isMakeAnOffer,
            isMaintenanceContract: res.isMaintenanceContract,
            technicianDepartureDate: res.technicianDepartureDate,
            technicianDepartureTime: res.technicianDepartureTime,
            technicianArrivalTime: res.technicianArrivalTime,
            technicianNote: res.technicianNote,
            technicianUserId: res.technicianUserId,
            isCompleted: res.isCompleted,
         });
         setMalfunctionsData({
            isMalfunctionOperatorDefaultTechnician: res.isMalfunctionOperatorDefaultTechnician,
            isMalfunctionBatteryFailureTechnician: res.isMalfunctionBatteryFailureTechnician,
            isMalfunctionEngineFailureTechnician: res.isMalfunctionEngineFailureTechnician,
            isMalfunctionBodyDamageTechnician: res.isMalfunctionBodyDamageTechnician,
            isMalfunctionImproperHandlingTechnician: res.isMalfunctionImproperHandlingTechnician,
            isMalfunctionChargerFailureTechnician: res.isMalfunctionChargerFailureTechnician,
            isMalfunctionElectricalFailureTechnician: res.isMalfunctionElectricalFailureTechnician,
            isMalfunctionSafetyAccessoriesFailureTechnician: res.isMalfunctionSafetyAccessoriesFailureTechnician,
            isMalfunctionRemoteControlFailureTechnician: res.isMalfunctionRemoteControlFailureTechnician,
            isMalfunctionBatteryChargerTechnician: res.isMalfunctionBatteryChargerTechnician,
            isMalfunctionTornCableTechnician: res.isMalfunctionTornCableTechnician,
            isMalfunctionHydraulicFailureTechnician: res.isMalfunctionHydraulicFailureTechnician,
            isMalfunctionOtherTechnician: res.isMalfunctionOtherTechnician,
            malfunctionOtherTechnician: res.malfunctionOtherTechnician,
         });
         if (res.customerSignature != '') {
            setCustomerSignature(res.customerSignature);
            setSigned(true);
         }
      });
   }, []);

   const handleUpdateMalfunctions = async () => {
      const _data = { ...malfunctionsData, ...solutionsData, customerSignature };
      const result = await dispatch(updateVehicleMalfunction(_data));
      if (result) {
         toast.success(t('pages.malfunctions.malfunctionUpdated'));
      }
   };

   const clearSignature = () => {
      if (signed) {
         setSigned(false);
      } else {
         sigCanvas.current.clear();
      }

      // sigCanvas.current.clear(); // Imza alanını temizleme
      setCustomerSignature('');
   };

   const acceptMalfunction = (e) => {
      setSolutionsData({ ...solutionsData, isCompleted: e.target.value });
   };

   const confirmMalfunction = (e) => {
      confirmDialog({
         message: t('pages.malfunctions.areYouSureResolve'),
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         defaultFocus: 'accept',
         accept: () => acceptMalfunction(e),
      });
   };

   return (
      <div className="max-h-[60vh] overflow-scroll">
         <ConfirmDialog />

         {/* TECHNICIAN */}
         <Divider align="left">
            <div className="inline-flex align-items-center">{t('pages.malfunctions.technician').toUpperCase()}</div>
         </Divider>
         <div className="grid mt-3">
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.technician')}</label>
               <Dropdown
                  options={technicianOptions}
                  value={solutionsData.technicianUserId}
                  onChange={(e) => setSolutionsData({ ...solutionsData, technicianUserId: e.value })}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t('global.select')}
                  className={`w-full input-detail`}
               />
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.date')}</label>
               <Calendar
                  value={moment(solutionsData.technicianDepartureDate, 'YYYY-MM-DD').toDate()}
                  onChange={(e) => setSolutionsData({ ...solutionsData, technicianDepartureDate: moment(e.value).format('YYYY-MM-DD') })}
                  placeholder={t('global.select')}
                  className={`w-full input-detail`}
                  dateFormat="dd/mm/yy"
               />
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.arrivalTime')}</label>
               {/* <Calendar
                  value={solutionsData.technicianArrivalTime ? moment(solutionsData.technicianArrivalTime, 'hh:mm').toDate() : '00:00'}
                  onChange={(e) => setSolutionsData({ ...solutionsData, technicianArrivalTime: e.value })}
                  placeholder={t('global.select')}
                  className={`w-full input-detail`}
                  mask="99:99"
                  timeOnly
               /> */}
               <InputMask
                  value={solutionsData.technicianArrivalTime ? solutionsData.technicianArrivalTime : '00:00'}
                  onChange={(e) => {
                     const inputValue = e.target.value;
                     const isValidTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(inputValue);
                     if (isValidTime || inputValue === '') {
                        setSolutionsData({
                           ...solutionsData,
                           technicianArrivalTime: inputValue,
                        });
                     }
                  }}
                  mask="99:99"
                  placeholder="--:--"
                  className={`w-full input-detail`}
               />
            </div>
         </div>

         {/* MALFUCTIONS */}
         <Divider align="left" className="mt-5">
            <div className="inline-flex align-items-center">{t('pages.malfunctions.malfunctions').toUpperCase()}</div>
         </Divider>
         <div className="grid mt-4">
            {Object.keys(malfunctionsData)
               .map((key) => ({
                  key: key,
                  value: malfunctionsData[key],
               }))
               .map((item) => (
                  <div
                     className={`col-12 md:col-6 lg:col-4 xl:col-3 ${(item.key == 'malfunctionOtherTechnician' || item.key == 'id') && 'hidden'}`}
                     key={item.key}
                  >
                     <div className="flex align-items-center border-2 rounded p-2">
                        <Checkbox
                           inputId={item.key}
                           name={item.key}
                           value={item.key}
                           onChange={(e) => setMalfunctionsData({ ...malfunctionsData, [item.key]: e.checked })}
                           checked={item.value}
                        />
                        <label htmlFor={item.key} className="ml-2 cursor-pointer">
                           {t('pages.malfunctions.' + item.key)}
                        </label>
                     </div>
                  </div>
               ))}
            {malfunctionsData.isMalfunctionOtherTechnician && (
               <div className="col-12 md:col-6 lg:col-4">
                  <InputText
                     value={malfunctionsData.malfunctionOtherTechnician}
                     placeholder={t('pages.malfunctions.malfunctionOther')}
                     onChange={(e) => setMalfunctionsData({ ...malfunctionsData, malfunctionOtherTechnician: e.target.value })}
                     maxLength={50}
                     className={`w-full input-detail`}
                  />
               </div>
            )}
         </div>

         {/* SOLUTIONS */}
         <Divider align="left" className="mt-5">
            <div className="inline-flex align-items-center">{t('pages.malfunctions.solutions').toUpperCase()}</div>
         </Divider>
         <div className="grid mt-4">
            {Object.keys(solutionsData)
               .map((key) => ({
                  key: key,
                  value: solutionsData[key],
               }))
               .map((item) => (
                  <div
                     className={`col-12 md:col-6 lg:col-4 xl:col-3 ${
                        (item.key == 'solutionOther' ||
                           item.key == 'id' ||
                           item.key == 'isJobDone' ||
                           item.key == 'isPartsAndSmallSupplies' ||
                           item.key == 'technicianDepartureTime' ||
                           item.key == 'technicianArrivalTime' ||
                           item.key == 'technicianDepartureDate' ||
                           item.key == 'technicianNote' ||
                           item.key == 'technicianUserId' ||
                           item.key == 'isMakeAnOffer' ||
                           item.key == 'isInvoiced' ||
                           item.key == 'isCompleted' ||
                           item.key == 'isMaintenanceContract') &&
                        'hidden'
                     }`}
                     key={item.key}
                  >
                     <div className="flex align-items-center border-2 rounded p-2">
                        <Checkbox
                           inputId={item.key}
                           name={item.key}
                           value={item.key}
                           onChange={(e) => setSolutionsData({ ...solutionsData, [item.key]: e.checked })}
                           checked={item.value}
                        />
                        <label htmlFor={item.key} className="ml-2 cursor-pointer">
                           {t('pages.malfunctions.' + item.key)}
                        </label>
                     </div>
                  </div>
               ))}
            {solutionsData.isSolutionOther && (
               <div className="col-12">
                  <InputText
                     value={solutionsData.solutionOther}
                     placeholder={t('pages.malfunctions.malfunctionOther')}
                     onChange={(e) => setSolutionsData({ ...solutionsData, solutionOther: e.target.value })}
                     maxLength={50}
                     className={`w-full input-detail mx-[2px]`}
                  />
               </div>
            )}

            <div className="col-12 md:col-6 lg:col-4 xl:col-3">
               <div className="flex align-items-center border-2 rounded p-2">
                  <InputSwitch
                     checked={solutionsData.isJobDone}
                     onChange={(e) => setSolutionsData({ ...solutionsData, isJobDone: !solutionsData.isJobDone })}
                  />
                  <span className="ml-2">{t('pages.malfunctions.isJobDone')}</span>
               </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4 xl:col-3">
               <div className="flex align-items-center border-2 rounded p-2">
                  <InputSwitch
                     checked={solutionsData.isPartsAndSmallSupplies}
                     onChange={(e) => setSolutionsData({ ...solutionsData, isPartsAndSmallSupplies: !solutionsData.isPartsAndSmallSupplies })}
                  />
                  <span className="ml-2">{t('pages.malfunctions.isPartsAndSmallSupplies')}</span>
               </div>
            </div>
         </div>

         {/* NEXT STEP */}
         <Divider align="left" className="mt-5">
            <div className="inline-flex align-items-center">{t('pages.malfunctions.nextStep').toUpperCase()}</div>
         </Divider>
         <div className="grid">
            <div className="col-12 md:col-6 lg:col-4 ">
               <div className="flex align-items-center border-2 rounded p-2">
                  <InputSwitch
                     checked={solutionsData.isInvoiced}
                     onChange={(e) => setSolutionsData({ ...solutionsData, isInvoiced: !solutionsData.isInvoiced })}
                  />
                  <span className="ml-2">{t('pages.malfunctions.isInvoiced')}</span>
               </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4 ">
               <div className="flex align-items-center border-2 rounded p-2">
                  <InputSwitch
                     checked={solutionsData.isMakeAnOffer}
                     onChange={(e) => setSolutionsData({ ...solutionsData, isMakeAnOffer: !solutionsData.isMakeAnOffer })}
                  />
                  <span className="ml-2">{t('pages.malfunctions.isMakeAnOffer')}</span>
               </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4 ">
               <div className="flex align-items-center border-2 rounded p-2">
                  <InputSwitch
                     checked={solutionsData.isMaintenanceContract}
                     onChange={(e) => setSolutionsData({ ...solutionsData, isMaintenanceContract: !solutionsData.isMaintenanceContract })}
                  />
                  <span className="ml-2">{t('pages.malfunctions.isMaintenanceContract')}</span>
               </div>
            </div>

            <div className="col-12 lg:col-4">
               <label>{t('pages.malfunctions.departureTime')}</label>
               {/* <Calendar
                  value={solutionsData.technicianDepartureTime ? moment(solutionsData.technicianDepartureTime, 'hh:mm').toDate() : '00:00'}
                  onChange={(e) => setSolutionsData({ ...solutionsData, technicianDepartureTime: moment(e.value).format('hh:mm') })}
                  placeholder={t('global.select')}
                  className={`w-full input-detail`}
                  timeOnly
               /> */}
               <InputMask
                  value={solutionsData.technicianDepartureTime ? solutionsData.technicianDepartureTime : '00:00'}
                  onChange={(e) => {
                     const inputValue = e.target.value;
                     const isValidTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(inputValue);
                     if (isValidTime || inputValue === '') {
                        setSolutionsData({
                           ...solutionsData,
                           technicianDepartureTime: inputValue,
                        });
                     }
                  }}
                  mask="99:99"
                  placeholder="--:--"
                  className={`w-full input-detail`}
               />
            </div>

            <div className="col-12  lg:col-8">
               <label>{t('pages.malfunctions.note')}</label>
               <InputTextarea
                  value={solutionsData.technicianNote}
                  onChange={(e) => setSolutionsData({ ...solutionsData, technicianNote: e.target.value })}
                  rows={1}
                  className={`w-full input-detail`}
               />
            </div>
         </div>

         {/* CUSTOMER SIGNATURE */}
         <Divider align="left" className="mt-5">
            <div className="inline-flex align-items-center">{t('pages.malfunctions.customerSignature').toUpperCase()}</div>
         </Divider>

         <div className="w-[100%] lg:w-[30vw]">
            {!signed ? (
               <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{ className: 'sigCanvas border-2 rounded bg-gray-100 h-[180px] w-[100%] lg:w-[30vw]' }}
                  onEnd={() => {
                     sigCanvas.current.getTrimmedCanvas().toBlob((blob) => {
                        let file = new File([blob], 'signature.png', { type: 'image/png' });
                        setCustomerSignature(file);
                     });
                  }}
               />
            ) : (
               <img
                  src={url + customerSignature}
                  alt="signature"
                  className="sigCanvas border-2 rounded bg-gray-100 h-[180px] w-[100%] lg:w-[30vw] p-4 object-contain"
               />
            )}

            <div className="mt-2 flex justify-end">
               <Button severity="danger" onClick={clearSignature} disabled={!customerSignature}>
                  {t('pages.malfunctions.clearSignature')}
               </Button>
            </div>
         </div>

         <div className="flex align-items-center justify-center mt-4 border-2 rounded p-2">
            <InputSwitch checked={solutionsData.isCompleted} onChange={(e) => confirmMalfunction(e)} />
            <span className="ml-2 !text-[20px]">{t('pages.malfunctions.resolved')}</span>
         </div>

         <div className="col-12 flex justify-end">
            <Button label={t('pages.malfunctions.saveAndContinue')} className="w-[400px] mt-[20px]" onClick={() => handleUpdateMalfunctions()} />
         </div>
      </div>
   );
};

export default Technician;
