import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   vehicles: [],
   vehicleReservations: [],
};

export const planningSlice = createSlice({
   name: 'planning',
   initialState,
   reducers: {
      setVehicles: (state, action) => {
         state.vehicles = action.payload;
      },
      setVehicleReservations: (state, action) => {
         state.vehicleReservations = action.payload;
      },
   },
});

export const { setVehicles, setVehicleReservations } = planningSlice.actions;
export default planningSlice.reducer;
