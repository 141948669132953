import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import moment from 'moment';
import TableComponent from '../../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import AddInventoryMachine from './AddInventoryMachine';
import AddSupplierMachine from './AddSupplierMachine';
import AddSaleItems from './AddSaleItems';
import toast from 'react-hot-toast';
import { deleteDetailOfferItem, getPublicHolidays } from '../../../../store/apis/OffersApi';

const Items = () => {
   const dispatch = useDispatch();
   const { offer } = useSelector((state) => state.offers);
   const op = useRef(null);
   const [inventoryMachine, setInventoryMachine] = useState({ visible: false, actionType: 'create', item: null });
   const [supplierMachine, setSupplierMachine] = useState({ visible: false, actionType: 'create', item: null });
   const [saleItems, setSaleItems] = useState({ visible: false, actionType: 'create', item: null });
   const [totalPrices, setTotalPrices] = useState({
      totalPrice: 0,
      totalEcoFee: 0,
      totalInsurance: 0,
      totalDeliveryFee: 0,
      totalPickupFee: 0,
      totalBeforeTax: 0,
      totalAfterTax: 0,
      totalDiscount: 0,
   });
   const [publicHolidays, setPublicHolidays] = useState([]);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      calculateTotalPrices();
   }, [offer]);

   const calculateTotalPrices = () => {
      const newTotalPrices = offer.orderItems.reduce(
         (accumulator, item) => {
            const basePrice = item.category === 'INV' || item.category === 'SUP' ? parseFloat(item.vehicleReservation.subTotal) : item?.price * item?.quantity;
            const discount = item.category === 'INV' || item.category === 'SUP' ? (basePrice * item?.vehicleReservation?.discountRate) / 100 : 0;
            const ecoPrice =
               item.category === 'INV' || item.category === 'SUP' ? (item?.vehicleReservation.isEco ? Number(item?.vehicleReservation.ecoPrice) : 0) : 0;
            const insurancePrice =
               item.category === 'INV' || item.category === 'SUP'
                  ? item?.vehicleReservation.isInsurance
                     ? parseFloat(item.vehicleReservation.insurancePrice)
                     : 0
                  : 0;
            const deliveryFee = item.category === 'INV' || item.category === 'SUP' ? +item?.vehicleReservation.deliveryFee : 0;
            const pickupFee = item.category === 'INV' || item.category === 'SUP' ? +item?.vehicleReservation.pickupFee : 0;
            accumulator.totalPrice += basePrice;
            accumulator.totalEcoFee += ecoPrice;
            accumulator.totalInsurance += insurancePrice;
            accumulator.totalDeliveryFee += deliveryFee;
            accumulator.totalPickupFee += pickupFee;
            accumulator.totalDiscount += discount;

            const totalPriceBeforeTax = basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee - discount;
            const taxAmount = (totalPriceBeforeTax * +item.tax) / 100;
            const totalPriceAfterTax = totalPriceBeforeTax + taxAmount;
            accumulator.totalBeforeTax += totalPriceBeforeTax;
            accumulator.totalAfterTax += totalPriceAfterTax;
            return accumulator;
         },
         {
            totalPrice: 0,
            totalEcoFee: 0,
            totalInsurance: 0,
            totalDeliveryFee: 0,
            totalPickupFee: 0,
            totalBeforeTax: 0,
            totalAfterTax: 0,
            totalDiscount: 0,
         },
      );
      setTotalPrices(newTotalPrices);
   };

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };

   const formatPrice = (price) => `€${parseFloat(price).toFixed(2)}`;
   const calculateTotalPrice = (rowData) => {
      const subTotal = parseFloat(rowData.subTotal);
      const basePrice = subTotal - (subTotal * rowData.discountRate) / 100;
      const ecoPrice = rowData.isEco ? +rowData.ecoPrice : 0;
      const insurancePrice = rowData.isInsurance ? parseFloat(rowData.insurancePrice) : 0;
      const deliveryFee = +rowData.deliveryFee;
      const pickupFee = +rowData.pickupFee;
      return basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee;
   };
   const calculateTotalPriceAfterTax = (rowData) => {
      const totalPrice =
         rowData.category === 'INV' || rowData.category === 'SUP' ? calculateTotalPrice(rowData?.vehicleReservation) : rowData?.price * rowData?.quantity;
      const taxAmount = (totalPrice * +rowData.tax) / 100;
      return totalPrice + taxAmount;
   };

   const removeItem = (rowData) => () => {
      const res = dispatch(deleteDetailOfferItem({ orderId: offer.id, orderItemId: rowData.id }));
      if (res) {
         toast.success(t('pages.offers.itemRemoved'));
      }
   };

   const updateItem = (rowData) => () => {
      if (rowData.category === 'INV') {
         setInventoryMachine({
            visible: true,
            actionType: 'update',
            item: rowData,
         });
      } else if (rowData.category === 'SUP') {
         setSupplierMachine({
            visible: true,
            actionType: 'update',
            item: rowData,
         });
      } else {
         setSaleItems({
            visible: true,
            actionType: 'update',
            item: rowData,
         });
      }
   };

   const tableColumns = [
      {
         name: 'actions',
         body: (rowData) => (
            <div className="flex items-center justify-center">
               <Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={updateItem(rowData)} />
               <Button className="mx-1" icon="pi pi-trash" severity="danger" onClick={removeItem(rowData)} />
            </div>
         ),
         footer: t('pages.offers.total').toUpperCase(),
      },
      {
         name: 'category',
         header: t('pages.offers.category'),
         body: (rowData) => <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? 'RENT' : 'SALE'}</span>,
      },
      {
         name: 'reference',
         header: t('pages.offers.reference'),
         body: (rowData) => (
            <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? rowData?.vehicleReservation?.vehicleGroup?.name : rowData?.category}</span>
         ),
      },
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => (
            <span>
               {rowData?.vehicleReservation?.vehicle && rowData?.vehicleReservation?.vehicle.note
                  ? rowData?.vehicleReservation?.vehicle.note
                  : rowData?.description}
            </span>
         ),
      },
      {
         name: 'startDate',
         header: t('pages.offers.startDate'),
         body: (rowData) => (
            <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? moment(rowData?.vehicleReservation?.beginDate).format('DD/MM/YYYY') : '-'}</span>
         ),
      },
      {
         name: 'endDate',
         header: t('pages.offers.endDate'),
         body: (rowData) => (
            <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? moment(rowData?.vehicleReservation?.endDate).format('DD/MM/YYYY') : '-'}</span>
         ),
      },
      {
         name: 'quantity',
         header: t('pages.offers.quantity'),
         body: (rowData) => (
            <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? calculateWorkDays(rowData?.vehicleReservation) : rowData?.quantity}</span>
         ),
      },
      {
         name: 'price',
         header: t('pages.offers.price'),
         body: (rowData) =>
            formatPrice(
               rowData.category === 'INV' || rowData.category === 'SUP'
                  ? rowData?.vehicleReservation?.subTotal
                  : (rowData?.price * rowData?.quantity).toFixed(2),
            ),
         footer: formatPrice(totalPrices.totalPrice),
      },
      {
         name: 'ecoFee',
         header: t('pages.offers.ecoFee'),
         body: (rowData) =>
            rowData.category === 'INV' || rowData.category === 'SUP'
               ? formatPrice(rowData?.vehicleReservation?.isEco ? rowData?.vehicleReservation?.ecoPrice : 0)
               : '-',
         footer: formatPrice(totalPrices.totalEcoFee),
      },
      {
         name: 'insurance',
         header: t('pages.offers.insurance'),
         body: (rowData) => (rowData.category === 'INV' || rowData.category === 'SUP' ? formatPrice(rowData?.vehicleReservation?.insurancePrice) : '-'),
         footer: formatPrice(totalPrices.totalInsurance),
      },
      {
         name: 'deliveryFee',
         header: t('pages.offers.transporterDeliveryFee'),
         body: (rowData) => (rowData.category === 'INV' || rowData.category === 'SUP' ? formatPrice(rowData?.vehicleReservation?.deliveryFee) : '-'),
         footer: formatPrice(totalPrices.totalDeliveryFee),
      },
      {
         name: 'pickupFee',
         header: t('pages.offers.transporterPickupFee'),
         body: (rowData) => (rowData.category === 'INV' || rowData.category === 'SUP' ? formatPrice(rowData?.vehicleReservation?.pickupFee) : '-'),
         footer: formatPrice(totalPrices.totalPickupFee),
      },
      {
         name: 'discountRate',
         header: t('pages.offers.discountRate'),
         body: (rowData) =>
            rowData.category === 'INV' || rowData.category === 'SUP'
               ? formatPrice((parseFloat(rowData.vehicleReservation.subTotal) * rowData?.vehicleReservation.discountRate) / 100) +
                 ' (%' +
                 rowData?.vehicleReservation.discountRate +
                 ')'
               : '-',
         footer: formatPrice(totalPrices.totalDiscount),
      },
      {
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) =>
            rowData.category === 'INV' || rowData.category === 'SUP'
               ? formatPrice(calculateTotalPrice(rowData.vehicleReservation))
               : '€' + (rowData?.price * rowData?.quantity).toFixed(2),
         footer: formatPrice(totalPrices.totalBeforeTax),
      },
      {
         name: 'taxFee',
         header: t('pages.offers.taxFee'),
         body: (rowData) => <span>%{parseInt(rowData.tax)}</span>,
      },
      {
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => formatPrice(calculateTotalPriceAfterTax(rowData)),
         footer: formatPrice(totalPrices.totalAfterTax),
      },
   ];

   return (
      <>
         <div className="flex justify-end">
            <Button icon="pi pi-plus" className="mb-4 me-4" onClick={(e) => op.current.toggle(e)} severity="success" />
            <OverlayPanel ref={op}>
               <div className="flex gap-x-3">
                  <Button
                     label={t('pages.offers.inventoryMachine')}
                     disabled={offer?.orderKind?.name === 'SALE'}
                     onClick={() =>
                        setInventoryMachine({
                           visible: true,
                           actionType: 'create',
                        })
                     }
                  />
                  <Button
                     label={t('pages.offers.supplierMachine')}
                     disabled={offer?.orderKind?.name === 'SALE'}
                     onClick={() =>
                        setSupplierMachine({
                           visible: true,
                           actionType: 'create',
                        })
                     }
                  />
                  <Button
                     label={t('pages.offers.saleItems')}
                     onClick={() =>
                        setSaleItems({
                           visible: true,
                           actionType: 'create',
                        })
                     }
                  />
               </div>
            </OverlayPanel>
         </div>
         <div>
            <TableComponent data={offer.orderItems || []} columns={tableColumns} size="small" />
         </div>

         <AddInventoryMachine inventoryMachine={inventoryMachine} setInventoryMachine={setInventoryMachine} />
         <AddSupplierMachine supplierMachine={supplierMachine} setSupplierMachine={setSupplierMachine} />
         <AddSaleItems saleItems={saleItems} setSaleItems={setSaleItems} />
      </>
   );
};

export default Items;
