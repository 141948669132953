import React, { useEffect, useRef } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { t } from 'i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { createContractDocument, deleteContractDocuments, getContractDocuments } from '../../../../store/apis/ContractsApi';

const ContractDocumenst = () => {
   const dispatch = useDispatch();
   const { contract, contractDocuments } = useSelector((state) => state.contracts);
   const fileUploadRef = useRef(null);
   const url = process.env.REACT_APP_LINK.split('/graphql')[0];

   useEffect(() => {
      if (contract) {
         dispatch(getContractDocuments(contract.id));
      }
   }, [contract]);

   const onFileSelect = async (e) => {
      const _data = {
         orderId: +contract.id,
         documents: e.files,
      };
      const result = await dispatch(createContractDocument(_data));
      if (result) {
         toast.success(t('pages.contracts.createDocumentMessage'));
      }
   };

   const fileTemplate = (rowData) => {
      return (
         <>
            <Button
               icon="pi pi-eye"
               className="mr-2 p-button-rounded p-button-success min-w-[46px]"
               onClick={() => window.open(`${url}${rowData.document.url}`, '_blank')}
            />
            <Button icon="pi pi-times" className="p-button-rounded p-button-danger  min-w-[46px]" onClick={() => removeFile(rowData)} />
         </>
      );
   };

   const nameTemplate = (rowData) => {
      return (
         <div className="flex items-center">
            <span className="ml-2">{rowData?.document?.name?.split('.')[0]}</span>
         </div>
      );
   };

   const sizeTemplate = (rowData) => {
      return `${(rowData?.document?.size / 1024).toFixed(2)} KB`;
   };

   const extensionTemplate = (rowData) => {
      return (
         <div className="flex items-center">
            <span className="ml-2">{rowData?.document?.name?.lastIndexOf('.') <= 0 ? '' : rowData?.document?.name?.split('.').pop()}</span>
         </div>
      );
   };

   const removeFile = (fileToRemove) => {
      const _id = parseInt(fileToRemove.id);
      dispatch(deleteContractDocuments([_id]));
   };

   return (
      <>
         <div>
            <FileUpload
               ref={fileUploadRef}
               accept="*"
               onSelect={onFileSelect}
               chooseLabel={t('pages.contracts.addNewContractDocument')}
               maxFileSize={9000000}
               uploadOptions={{ style: { display: 'none' } }}
               cancelOptions={{ style: { display: 'none' } }}
               pt={{
                  content: { style: { display: 'none' } },
                  buttonbar: { style: { background: 'none', border: 'none', display: 'flex', justifyContent: 'end' } },
               }}
            />
         </div>

         <div>
            <DataTable value={contractDocuments} size="small">
               <Column field="document.name" body={nameTemplate} header={t('global.table.fileName')} style={{ width: '30%' }}></Column>
               <Column field="document.size" body={sizeTemplate} header={t('global.table.size')} style={{ width: '25%' }}></Column>
               <Column field="extension" body={extensionTemplate} header={t('global.table.extension')} style={{ width: '20%' }}></Column>
               <Column body={fileTemplate} header={t('global.table.action')} style={{ minWidth: '120px' }}></Column>
            </DataTable>
         </div>
      </>
   );
};

export default ContractDocumenst;
