import React, { useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import PreInvoices from './PreInvoices/PreInvoices';
import { useTranslation } from 'react-i18next';
import Refunds from './Refunds/Refunds';
import Invoiced from './Invoiced/Invoiced';

const Index = () => {
   const { t } = useTranslation();

   const [selectedButton, setSelectedButton] = useState('preInvoices');
   const buttonOptions = [
      { label: t('pages.invoices.preInvoices'), value: 'preInvoices' },
      { label: t('pages.invoices.invoiced'), value: 'invoiced' },
      { label: t('pages.invoices.refunds'), value: 'refunds' },
   ];

   return (
      <div className="w-full">
         <div className="text-center mr-[2vw]">
            <SelectButton
               allowEmpty={false}
               className="mb-3"
               value={selectedButton}
               options={buttonOptions}
               optionLabel="label"
               onChange={(e) => setSelectedButton(e.value)}
            />
         </div>
         {selectedButton === 'preInvoices' ? <PreInvoices /> : selectedButton === 'invoiced' ? <Invoiced /> : <Refunds />}
      </div>
   );
};

export default Index;
