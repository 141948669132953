import React, { useState } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import AtelierPDF from './AtelierPDF';
import MalfunctionPDF from './MalfunctionPDF';
import RentOffer1 from './RentOffer/RentOffer1';

const PDFS = () => {
   const [selectedButton, setSelectedButton] = useState('pdf1');
   const buttonOptions = [
      { label: 'PDF1', value: 'pdf1' },
      { label: 'PDF2', value: 'pdf2' },
      { label: 'PDF3', value: 'pdf3' },
   ];

   return (
      <div className="w-full">
         <div className="text-center mr-[2vw]">
            <SelectButton
               allowEmpty={false}
               className="mb-3"
               value={selectedButton}
               options={buttonOptions}
               optionLabel="label"
               onChange={(e) => setSelectedButton(e.value)}
            />
         </div>
         {selectedButton === 'pdf1' ? <AtelierPDF /> : selectedButton === 'pdf2' ? <MalfunctionPDF /> : <RentOffer1 />}
      </div>
   );
};

export default PDFS;
