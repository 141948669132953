import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../components/TableComponent';

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { createRefund, getCustomerInvoices } from '../../../store/apis/Customers';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import toast from 'react-hot-toast';
import { InputTextarea } from 'primereact/inputtextarea';
import InvoiceOffer1 from '../../../components/PDFS/InvoiceOffer/InvoiceOffer1';
import SaleInvoicePDF from '../../../components/PDFS/SaleInvoicePDF';

const Invoices = ({ customerData }) => {
   const { customerInvoices } = useSelector((state) => state.customer);
   const dispatch = useDispatch();
   const [createRefundVisible, setCreateRefundVisible] = useState({
      visible: false,
      data: null,
   });
   const [createRefundInputs, setCreateRefundInputs] = useState({
      amountBeforeTax: 0,
      explanation: '',
      refundAll: false,
   });
   const [errors, setErrors] = useState({});

   useEffect(() => {
      dispatch(getCustomerInvoices(customerData?.id, 'INVOICED'));
   }, [customerData]);

   const tableColumns = [
      {
         field: 'creationDate',
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'invoiceNumber',
         header: t('global.table.invoiceNumber'),
         field: 'invoiceNumber',
         body: (rowData) => {
            return <span>{rowData?.invoiceNumber}</span>;
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            return <span>{rowData?.orderNumber}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            return <span>{rowData?.customer.companyName}</span>;
         },
      },
      {
         name: 'dueDate',
         header: t('global.table.dueDate'),
         field: 'dueDate',
         body: (rowData) => {
            return <span>{rowData?.dueDate ? moment(rowData?.dueDate).format('DD/MM/YYYY') : '-'}</span>;
         },
      },
      {
         field: 'totalAfterTax',
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{rowData?.totalAfterTax}</span>;
         },
      },
      {
         name: 'paymentDate',
         header: t('global.table.paymentDate'),
         field: 'paymentDate',
         body: (rowData) => {
            return <span>{rowData?.paymentDate ? moment(rowData?.paymentDate).format('DD/MM/YYYY') : '-'}</span>;
         },
      },
      {
         field: 'isFactor',
         name: 'isFactor',
         header: t('global.table.factor'),
         body: (rowData) => {
            return <span>{rowData?.isFactor ? t('global.yes') : t('global.no')}</span>;
         },
      },
      {
         field: 'pdf',
         name: 'pdf',
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return rowData.vehicle ? <InvoiceOffer1 data={rowData} /> : <SaleInvoicePDF data={rowData} />;
         },
      },
      {
         field: 'plus',
         name: 'plus',
         bodyClassName: 'w-[180px]',
         body: (rowData) => {
            return (
               <Button
                  severity="warning"
                  className="text-[14px] !py-[3px]"
                  label={t('global.table.createRefund')}
                  disabled={rowData.status !== 'INVOICED'}
                  onClick={() =>
                     setCreateRefundVisible({
                        visible: true,
                        data: rowData,
                     })
                  }
               />
            );
         },
      },
   ];

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      if (name === 'amountBeforeTax') {
         let onlyNums = value.replace(/[^0-9]/g, '');
         setCreateRefundInputs({ ...createRefundInputs, [name]: onlyNums });
         updateErrors(name, onlyNums);
      } else {
         const { value } = e.target;
         setCreateRefundInputs({ ...createRefundInputs, [name]: value });
         updateErrors(name, value);
      }
   };

   const handleFocus = (name) => {
      if (createRefundInputs[name] === 0) {
         setCreateRefundInputs({ ...createRefundInputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const inputs = ['explanation'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!createRefundInputs[input]) {
            updateErrors(input, createRefundInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleCreateRefund = () => {
      if (!validateForm()) return;
      const data = {
         invoiceId: createRefundVisible.data.id,
         price: createRefundInputs.refundAll ? parseFloat(createRefundVisible.data.totalAfterTax) : parseFloat(createRefundInputs.amountBeforeTax),
         description: createRefundInputs.explanation,
      };
      dispatch(createRefund(data)).then((res) => {
         if (res) {
            toast.success(t('pages.customers.createRefundSuccess'));
            setCreateRefundVisible({ visible: false, data: null });
            setCreateRefundInputs({
               amountBeforeTax: 0,
               explanation: '',
               refundAll: false,
            });
         }
      });
   };

   return (
      <>
         <div className="mt-10">
            <TableComponent
               data={(customerInvoices && customerInvoices.length > 0 && customerInvoices.filter((item) => item.status === 'INVOICED')) || []}
               columns={tableColumns}
            />
         </div>

         <Dialog
            header={t('pages.customers.createRefundTitle') + ':' + createRefundVisible.data?.invoiceNumber}
            visible={createRefundVisible.visible}
            style={{ width: '30vw' }}
            onHide={() => setCreateRefundVisible({ visible: false, data: null })}
         >
            <span className="!text-[14px] font-semibold">{t('pages.customers.amountAfterTax') + ': €' + createRefundVisible.data?.totalAfterTax}</span>
            <div className="grid mt-2">
               <div className="col-12 md:col-6 lg:col-6">
                  <label>{t('pages.customers.amountBeforeTax')}</label>
                  <InputText
                     value={createRefundInputs.amountBeforeTax}
                     onChange={(e) => handleInputChange(e, 'amountBeforeTax')}
                     onFocus={() => handleFocus('amountBeforeTax')}
                     className="w-full input-detail"
                     disabled={createRefundInputs.refundAll}
                  />
                  {errors.amountBeforeTax && <small className="p-error">{errors.amountBeforeTax}</small>}
               </div>
               <div className="mt-4 col-12 md:col-6 lg:col-6">
                  <div className="px-2 rounded-lg border-[2px] flex justify-center items-center">
                     <label>{t('pages.customers.refundAll')}</label>
                     <div>
                        <InputSwitch checked={createRefundInputs.refundAll} onChange={(e) => handleInputChange(e, 'refundAll')} className="mt-1" />
                     </div>
                  </div>
               </div>
               <div className="col-12">
                  <label>{t('pages.contracts.explanation')}</label>
                  <InputTextarea
                     rows={2}
                     value={createRefundInputs.explanation}
                     onChange={(e) => handleInputChange(e, 'explanation')}
                     className="w-full input-detail"
                  />
                  {errors.explanation && <small className="p-error">{errors.explanation}</small>}
               </div>
               <div className="mt-3 col-12">
                  <Button label={t('global.save')} className="w-full" onClick={() => handleCreateRefund()} severity="success" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default Invoices;
