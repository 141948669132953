import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import { t } from 'i18next';
import { Calendar } from 'primereact/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { updateVehicleReservation } from '../../../../store/apis/ContractsApi';
import { Button } from 'primereact/button';
import moment from 'moment';
const ExtendContract = ({ selectedItem, setSelectedItem, extendContractVisible, setExtendContractVisible }) => {
   const { contract } = useSelector((state) => state.contracts);
   const dispatch = useDispatch();
   const [inputs, setInputs] = useState({
      newEndDate: null,
   });
   const [errors, setErrors] = useState({});

   const validateForm = () => {
      const keys = ['newEndDate'];
      let isValid = true;
      keys.forEach((input) => {
         if (!inputs[input]) {
            updateErrors(input, inputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (value, name) => {
      setInputs((_inputs) => ({
         ..._inputs,
         [name]: value,
      }));
      updateErrors(name, value);
   };

   const handleSave = () => {
      if (!validateForm()) return;
      let updateData = {
         id: selectedItem?.vehicleReservation?.id,
         endDate: moment(inputs.newEndDate).format('YYYY-MM-DD'),
      };

      dispatch(updateVehicleReservation({ orderItemId: selectedItem?.id, orderId: contract?.id, updateData })).then((response) => {
         if (response) {
            setExtendContractVisible(false);
            setSelectedItem(null);
            resetForm();
         }
      });
   };
   const resetForm = () => {
      setInputs({
         newEndDate: null,
      });
      setErrors({});
   };
   return (
      <Dialog
         header={t('pages.contracts.extendContract')}
         visible={extendContractVisible}
         style={{ width: '50vw' }}
         onHide={() => {
            setExtendContractVisible(false);
            setSelectedItem(null);
            resetForm();
         }}
      >
         <div className="grid">
            <div className="col-6">
               <label>{t('pages.contracts.currentEndDate')}</label>
               <Calendar
                  value={new Date(selectedItem?.vehicleReservation?.endDate)}
                  readOnly
                  showIcon
                  dateFormat="dd/mm/yy"
                  className="w-full input-detail"
                  disabled
               />
            </div>
            <div className="col-6">
               <label>{t('pages.contracts.endDate')}</label>
               <Calendar
                  value={inputs.newEndDate}
                  onChange={(e) => handleInputChange(e.target.value, 'newEndDate')}
                  showIcon
                  dateFormat="dd/mm/yy"
                  className={errors.newEndDate ? 'input-error w-full input-detail' : 'w-full input-detail'}
               />
               {errors.newEndDate && <small className="p-error">{errors.newEndDate}</small>}
            </div>
         </div>
         <div className="text-end ">
            <Button label={t('global.save')} onClick={handleSave} severity="info" />
         </div>
      </Dialog>
   );
};

export default ExtendContract;
