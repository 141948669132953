import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { getVehicleMalfunction, getVehicleMalfunctionKinds, updateVehicleMalfunction } from '../../../store/apis/MalfunctionApi';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import toast from 'react-hot-toast';
import MalfunctionPDF from '../../../components/PDFS/MalfunctionPDF';

const GeneralMalfunctions = (props) => {
   const dispatch = useDispatch();
   const [vehicleMalfunctionKinds, setVehicleMalfunctionKinds] = useState([]);

   const [updateData, setUpdateData] = useState({
      id: props.selectedId,
      vehicleId: null,
      vehicleMalfunctionKindId: null,
      faultDate: '',
      description: '',
      contactName: '',
      contactPhone: '',
      note: '',
      contactEmail: '',
   });

   useEffect(() => {
      dispatch(getVehicleMalfunction({ id: props.selectedId, informations: true })).then((res) =>
         setUpdateData({
            id: res.id,
            vehicleMalfunctionKindId: res.vehicleMalfunctionKind.id,
            vehicleId: res.vehicle.id,
            faultDate: res.faultDate,
            description: res.description,
            contactName: res.contactName,
            contactPhone: res.contactPhone,
            note: res.note,
            contactEmail: res.contactEmail,
         }),
      );
      dispatch(getVehicleMalfunctionKinds()).then((res) => setVehicleMalfunctionKinds(res));
   }, []);

   const handleUpdateInformations = async () => {
      const result = await dispatch(updateVehicleMalfunction(updateData));
      if (result) {
         toast.success(t('pages.malfunctions.malfunctionUpdated'));
      }
   };

   return (
      <div className="grid">
         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.type')}</label>
            <Dropdown
               options={vehicleMalfunctionKinds}
               value={updateData.vehicleMalfunctionKindId}
               onChange={(e) => setUpdateData({ ...updateData, vehicleMalfunctionKindId: e.value })}
               optionLabel="name"
               optionValue="id"
               placeholder={t('global.select')}
               className={`w-full input-detail`}
            />
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.faultDate')}</label>
            <Calendar
               value={moment(updateData.faultDate, 'YYYY-MM-DD').toDate()}
               onChange={(e) => setUpdateData({ ...updateData, faultDate: moment(e.value).format('YYYY-MM-DD') })}
               placeholder={t('global.select')}
               className={`w-full input-detail`}
               dateFormat="dd/mm/yy"
            />
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.explanation')}</label>
            <InputTextarea
               value={updateData.description}
               onChange={(e) => setUpdateData({ ...updateData, description: e.target.value })}
               rows={1}
               className={`w-full input-detail`}
            />
            {/* {!updateData.description && <small className="p-error">{t('global.requiredField')}</small>} */}
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.primaryContactName')}</label>
            <InputText
               value={updateData.contactName}
               onChange={(e) => setUpdateData({ ...updateData, contactName: e.target.value })}
               maxLength={50}
               className={`w-full input-detail`}
            />
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.contactPhone')}</label>
            <InputText
               value={updateData.contactPhone}
               onChange={(e) => setUpdateData({ ...updateData, contactPhone: e.target.value })}
               maxLength={15}
               className={`w-full input-detail`}
               type={'number'}
            />
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.notes')}</label>
            <InputTextarea
               value={updateData.note}
               onChange={(e) => setUpdateData({ ...updateData, note: e.target.value })}
               rows={1}
               className={`w-full input-detail`}
            />
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <label>{t('pages.malfunctions.emailAddress')}</label>
            <InputText
               value={updateData.contactEmail}
               onChange={(e) => setUpdateData({ ...updateData, contactEmail: e.target.value })}
               maxLength={50}
               className={`w-full input-detail`}
            />
         </div>

         <div className="col-12 md:col-6 lg:col-4">
            <MalfunctionPDF data={updateData} />
         </div>

         <div className="flex justify-end col-12">
            <Button label={t('pages.malfunctions.saveAndContinue')} className="w-[400px] mt-[20px]" onClick={() => handleUpdateInformations()} />
         </div>
      </div>
   );
};

export default GeneralMalfunctions;
