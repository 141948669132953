import React, { useState } from 'react';
import { t } from 'i18next';

import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';

import client from '../../../graphql/apollo.config';
import { CREATE_USER } from '../../../graphql/users';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { changeLoadingStatus, setDialogFlag } from '../../../store/slices/sharedSlice';
import moment from 'moment/moment';
import i18n from '../../../i18n';

const AddNewUser = () => {
   const dispatch = useDispatch();

   const { loading, genders, roles } = useSelector((state) => state.shared);

   const [firstName, setFirstName] = useState('');
   const [lastName, setLastName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [birthdate, setBirthdate] = useState(null);
   const [selectedGender, setSelectedGender] = useState(null);
   const [password, setPassword] = useState('');
   const [selectedRole, setSelectedRole] = useState(null);
   const [idNumber, setIdNumber] = useState('');
   const [note, setNote] = useState('');
   const handleCreateNewUser = () => {
      dispatch(changeLoadingStatus(true));
      if (firstName === '' || lastName === '' || email === '' || phone === '' || password === '' || selectedRole === null) {
         toast.error('Please fill out all fields.');
         dispatch(changeLoadingStatus(false));
         return 0;
      }
      const tempBirthdate = moment(birthdate).format('YYYY-MM-DD');
      client
         .mutate({
            mutation: CREATE_USER,
            variables: {
               firstName,
               lastName,
               email,
               phone,
               password,
               roleId: selectedRole.id,
               note,
            },
         })
         .then((res) => {
            toast.success('User generated successfully!');
            dispatch(setDialogFlag(true));
         })
         .catch((err) => {
            if (err.message.toLowerCase().includes('duplicate')) {
               toast.error(t('pages.settings.users.duplicateError'));
            } else {
               toast.error(err.message);
            }
         })
         .finally(() => {
            dispatch(changeLoadingStatus(false));
         });
   };

   return (
      <div className="grid">
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.firstName')}*</label>
            <InputText value={firstName} onChange={(e) => setFirstName(e.target.value)} className="w-full" />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.lastName')}*</label>
            <InputText value={lastName} onChange={(e) => setLastName(e.target.value)} className="w-full" />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.email')}*</label>
            <InputText value={email} onChange={(e) => setEmail(e.target.value)} className="w-full" />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.phoneNumber')}*</label>
            <InputMask value={phone} onChange={(e) => setPhone(e.target.value)} mask="99 99 99 99 99" className="w-full" />
         </div>
         {/* <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.birthdate')}*</label>
            <Calendar
               value={birthdate}
               onChange={(e) => setBirthdate(e.value)}
               className="w-full"
               dateFormat="dd/mm/yy"
               locale={i18n.language.toLowerCase().slice(0, 2)}
               maxDate={new Date()}
               showIcon
            />
         </div> */}
         {/* <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.gender')}*</label>
            <Dropdown options={genders} optionLabel="name" value={selectedGender} onChange={(e) => setSelectedGender(e.value)} className="w-full" />
         </div> */}
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('pages.settings.users.password')}*</label>
            <InputText value={password} onChange={(e) => setPassword(e.target.value)} className="w-full" />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.role')}*</label>
            <Dropdown options={roles} optionLabel="name" value={selectedRole} onChange={(e) => setSelectedRole(e.value)} className="w-full" />
         </div>
         {/* <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.identityNumber')}</label>
            <InputText value={idNumber} onChange={(e) => setIdNumber(e.value)} className="w-full" maxLength={16} />
         </div> */}
         <div className="flex flex-wrap col-12">
            <label>{t('global.table.notes')}</label>
            <InputTextarea value={note} onChange={(e) => setNote(e.value)} className="w-full" />
         </div>
         <div className="grid items-center justify-end mt-3">
            <Button onClick={() => handleCreateNewUser()} icon="pi pi-check" loading={loading} />
         </div>
      </div>
   );
};

export default AddNewUser;
