import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   invoices: [],
   invoice: null,
   customerInvoices: [],
   uninvoicedContracts: [],
   refunds: [],
};

export const managementSlice = createSlice({
   name: 'management',
   initialState,
   reducers: {
      setInvoices: (state, action) => {
         state.invoices = action.payload;
      },
      setInvoice: (state, action) => {
         state.invoice = action.payload;
      },
      handleUpdateInvoice: (state, action) => {
         if (state.invoice && action.payload.parent) {
            state.invoice.totalAfterTax = action.payload.parent.totalAfterTax;
            const childrenIndex = state.invoice?.children?.findIndex((item) => +item.id === +action.payload.id);
            if (childrenIndex !== -1 && state.invoice?.children) {
               state.invoice.children[childrenIndex] = action.payload;
            }
            const index = state.invoices && state.invoices.length && state.invoices.findIndex((invoice) => +invoice.id === +action.payload?.parent?.id);
            if (index !== -1) {
               state.invoices[index] = action.payload.parent;
            }
         } else {
            if (state.invoice) {
               state.invoice = action.payload;
            }
            const index = state.invoices && state.invoices.length && state.invoices.findIndex((invoice) => +invoice.id === +action.payload.id);
            if (index !== -1) {
               state.invoices[index] = action.payload;
            }
         }
      },
      addInvoiceItem: (state, action) => {
         const itemTotalBeforeTax = parseFloat(action.payload.res.price) * parseInt(action.payload.res.quantity);
         const itemTotalAfterTax = itemTotalBeforeTax + itemTotalBeforeTax * 0.2;
         if (state.invoice) {
            state.invoice.invoiceItems.push(action.payload.res);
            const newTotalBeforeTax = parseFloat(state.invoice.totalBeforeTax) + itemTotalBeforeTax;
            const newTotalAfterTax = parseFloat(state.invoice.totalAfterTax) + itemTotalAfterTax;
            state.invoice.totalBeforeTax = newTotalBeforeTax;
            state.invoice.totalAfterTax = newTotalAfterTax;
         }
         const index = state.invoices && state.invoices.length && state.invoices.findIndex((invoice) => +invoice.id === +action.payload.data.invoiceId);
         if (index !== -1) {
            const newTotalBeforeTax = parseFloat(state.invoices[index].totalBeforeTax) + itemTotalBeforeTax;
            const newTotalAfterTax = parseFloat(state.invoices[index].totalAfterTax) + itemTotalAfterTax;
            state.invoices[index].totalBeforeTax = newTotalBeforeTax;
            state.invoices[index].totalAfterTax = newTotalAfterTax;
         }
      },
      addMonthsInvoice: (state, action) => {
         const updatedInvoices = action.payload
            .map((invoice) => {
               invoice.status = 'PRE_INVOICE';
               return invoice;
            })
            .filter((i) => i.parent === null);
         state.invoices = [...state.invoices, ...updatedInvoices];
      },
      removeInvoice: (state, action) => {
         const index = state.invoices.findIndex((invoice) => +invoice.id === +action.payload);
         if (index !== -1) {
            state.invoices.splice(index, 1);
         }
      },
      payInvoice: (state, action) => {
         const ids = action.payload.ids;
         for (let i = 0; i < ids.length; i++) {
            const index = state.invoices.findIndex((invoice) => +invoice.id === +ids[i]);
            if (index !== -1) {
               state.invoices[index].isPaid = true;
               state.invoices[index].amountPaid = state.invoices[index].totalAfterTax;
               state.invoices[index].paymentDate = action.payload.paymentDate;
               state.invoices[index].paymentMethod = action.payload.paymentMethod;
            }
         }
      },
      setCustomerInvoices: (state, action) => {
         state.customerInvoices = action.payload;
      },
      setUninvoicedContracts: (state, action) => {
         state.uninvoicedContracts = action.payload;
      },
      handleCreateInvoice: (state, action) => {
         const index = state.uninvoicedContracts.findIndex((item) => +item.id === +action.payload);
         if (index !== -1) {
            state.uninvoicedContracts[index].isInvoiced = true;
         }
      },
      setRefunds: (state, action) => {
         state.refunds = action.payload;
      },
      handleUpdateRefund: (state, action) => {
         const index = state.refunds.findIndex((refund) => +refund.id === +action.payload.id);
         if (index !== -1) {
            state.refunds[index].description = action.payload.description;
         }
      },
      handleUpdateRefundInvoice: (state, action) => {
         const index = state.refunds.findIndex((refund) => +refund?.invoice.id === +action.payload.id);
         if (index !== -1) {
            state.refunds[index].invoice.isSupplierInvoiceArrived = action.payload.isSupplierInvoiceArrived;
         }
      },
      removeOrderItem: (state, action) => {
         let itemTotalBeforeTax = 0;
         let itemTotalAfterTax = 0;
         const invoiceItemInd = state.invoice.invoiceItems.findIndex((item) => +item.id === +action.payload.id);
         if (invoiceItemInd !== -1) {
            itemTotalBeforeTax = parseFloat(state.invoice.invoiceItems[invoiceItemInd].price) * parseInt(state.invoice.invoiceItems[invoiceItemInd].quantity);
            itemTotalAfterTax = itemTotalBeforeTax + itemTotalBeforeTax * 0.2;
            state.invoice.totalBeforeTax = parseFloat(state.invoice.totalBeforeTax) - itemTotalBeforeTax;
            state.invoice.totalAfterTax = parseFloat(state.invoice.totalAfterTax) - itemTotalAfterTax;
            state.invoice.invoiceItems.splice(invoiceItemInd, 1);
         }
         const invoiceInd = state.invoices.findIndex((invoice) => +invoice.id === +action.payload.invoiceId);
         if (invoiceInd !== -1) {
            state.invoices[invoiceInd].totalBeforeTax = parseFloat(state.invoices[invoiceInd].totalBeforeTax) - itemTotalBeforeTax;
            state.invoices[invoiceInd].totalAfterTax = parseFloat(state.invoices[invoiceInd].totalAfterTax) - itemTotalAfterTax;
         }
      },
   },
});

export const {
   setInvoices,
   setInvoice,
   handleUpdateInvoice,
   addInvoiceItem,
   addMonthsInvoice,
   removeInvoice,
   payInvoice,
   setCustomerInvoices,
   setUninvoicedContracts,
   handleCreateInvoice,
   setRefunds,
   handleUpdateRefund,
   handleUpdateRefundInvoice,
   removeOrderItem,
} = managementSlice.actions;

export default managementSlice.reducer;
