import TableComponent from '../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { getContract, getContracts } from '../../../store/apis/Customers';
import { setContract } from '../../../store/slices/contractsSlice';
import ContractDetail from '../../Commerce/Contracts/ContractDetail/ContractDetail';
import { duplicateSelectedContract, removeContract } from '../../../store/apis/ContractsApi';
import { getPublicHolidays } from '../../../store/apis/OffersApi';

const SaleContract = () => {
   const dispatch = useDispatch();

   const { selectedCustomerId } = useSelector((state) => state.customer);

   const saleContracts = useSelector((state) => state.contracts.contracts);

   const [deleteSaleContract, setDeleteSaleContract] = useState({
      dialogVisible: false,
      selectedContractId: null,
   });

   const [duplicateSaleContract, setDuplicateSaleContract] = useState({
      dialogVisible: false,
      selectedContractId: null,
   });

   const [saleContractVisible, setSaleContractVisible] = useState(false);

   const formatPrice = (price) => `€${parseFloat(price).toFixed(2)}`;
   const dateDiffInDays = (rowData) => calculateWorkDays(rowData);
   const [publicHolidays, setPublicHolidays] = useState([]);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      dispatch(getContracts(selectedCustomerId, '2'));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const calculateTotalPrice = (rowData) => {
      const days = dateDiffInDays(rowData);
      const basePrice = rowData?.price * days - (rowData?.price * days * rowData.discountRate) / 100;
      const ecoPrice = rowData.isEco ? +rowData.ecoPrice : 0;
      const insurancePrice = rowData.isInsurance ? (basePrice * rowData.insuranceRate) / 100 : 0;
      const deliveryFee = +rowData.deliveryFee;
      const pickupFee = +rowData.pickupFee;
      return basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee;
   };

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };

   const tableColumns = [
      {
         name: 'contractNumber',
         header: t('global.table.contractNumber'),
         body: (rowData) => {
            return <span>{rowData.orderNumber}</span>;
         },
      },
      {
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'user',
         header: t('global.table.user'),
         body: (rowData) => {
            return <span>{rowData.customer?.companyName}</span>;
         },
      },
      {
         name: 'totalBeforeTax',
         header: t('global.table.totalBeforeTax'),
         body: (rowData) => {
            console.log(rowData);
            const totals = rowData.orderItems.map((item) => item?.price * item.quantity);
            const totalPrice = totals.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            return rowData.orderItems.category === 'INV' || rowData.orderItems.category === 'SUP'
               ? formatPrice(calculateTotalPrice(rowData.vehicleReservation))
               : '€' + totalPrice;
         },
      },
      {
         name: 'actions',
         body: (rowData) => {
            return (
               <div className="flex justify-center">
                  <div className="flex items-center">
                     <Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={() => showSaleContractDetail(rowData.id)} />
                     <Button
                        className="mx-1"
                        icon="pi pi-trash"
                        severity="danger"
                        onClick={() =>
                           setDeleteSaleContract({
                              dialogVisible: true,
                              selectedContractId: rowData.id,
                           })
                        }
                     />
                     <Button
                        className="mx-1"
                        icon="pi pi-copy"
                        severity="info"
                        onClick={() =>
                           setDuplicateSaleContract({
                              dialogVisible: true,
                              selectedContractId: rowData.id,
                           })
                        }
                     />
                  </div>
               </div>
            );
         },
      },
   ];

   const showSaleContractDetail = (id) => {
      dispatch(getContract(+id)).then(async (res) => {
         if (!res) return;
         dispatch(setContract(res.data.order));
         setSaleContractVisible(true);
      });
   };

   const handleDuplicateContract = async () => {
      const res = await dispatch(duplicateSelectedContract(+duplicateSaleContract.selectedContractId));
      if (res) {
         showSaleContractDetail(res.id);
         toast.success(t('pages.contracts.duplicateContractSuccessMessage'));
         setDuplicateSaleContract({
            dialogVisible: false,
            selectedContractId: null,
         });
      }
   };

   return (
      <div>
         <div>
            <div className="flex flex-wrap mt-2 justify-content-end"></div>
            <div className="mt-10 col-7">
               <TableComponent columns={tableColumns} size="small" data={saleContracts} />
            </div>
            <Dialog
               header={t('pages.contracts.deleteContract')}
               visible={deleteSaleContract.dialogVisible}
               style={{ width: '400px' }}
               onHide={() =>
                  setDeleteSaleContract({
                     dialogVisible: false,
                     selectedContractId: null,
                  })
               }
            >
               <div className="confirmation-content">{<span>{t('pages.contracts.deleteContractMessage')}</span>}</div>
               <div className="flex justify-end mt-3">
                  <Button
                     label={t('pages.contracts.cancel')}
                     severity="warning"
                     className="mr-2 "
                     onClick={() =>
                        setDeleteSaleContract({
                           dialogVisible: false,
                           selectedContractId: null,
                        })
                     }
                  />
                  <Button
                     label={t('pages.contracts.delete')}
                     severity="danger"
                     onClick={() => {
                        dispatch(removeContract(deleteSaleContract.selectedContractId)).then(
                           setDeleteSaleContract({
                              dialogVisible: false,
                              selectedContractId: null,
                           }),
                           toast.success(t('pages.contracts.deletedContractMessage')),
                        );
                     }}
                  />
               </div>
            </Dialog>

            <Dialog
               header={t('pages.contracts.duplicateContract')}
               visible={duplicateSaleContract.dialogVisible}
               style={{ width: '400px' }}
               onHide={() =>
                  setDuplicateSaleContract({
                     dialogVisible: false,
                     selectedContractId: null,
                  })
               }
            >
               <div className="confirmation-content">{<span>{t('pages.contracts.duplicateContractMessage')}</span>}</div>
               <div className="flex justify-end mt-3">
                  <Button
                     label={t('pages.contracts.cancel')}
                     severity="danger"
                     className="mr-2 "
                     onClick={() =>
                        setDuplicateSaleContract({
                           dialogVisible: false,
                           selectedContractId: null,
                        })
                     }
                  />
                  <Button label={t('global.save')} severity="success" onClick={handleDuplicateContract} />
               </div>
            </Dialog>

            <ContractDetail contractVisible={saleContractVisible} setContractVisible={setSaleContractVisible} />
         </div>
      </div>
   );
};

export default SaleContract;
