import React, { useRef } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { getAtelierPDF } from '../../store/apis/SharedApi';
import moment from 'moment';
import { t } from 'i18next';

const AtelierPDF = ({ data, disabled }) => {
   const dispatch = useDispatch();
   const url = process.env.REACT_APP_LINK.split('/graphql')[0];

   const styles = StyleSheet.create({
      header: {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'center',
      },
      content: {
         marginTop: 10,
         paddingHorizontal: 10,
      },
      defaultText: {
         fontSize: '11px',
         minWidth: '120px',
      },
      table: {
         display: 'table',
         width: 'auto',
         borderStyle: 'solid',
         borderColor: '#ecf3f1',
         borderWidth: 1,
         borderRightWidth: 0,
         borderBottomWidth: 0,
      },
      tableRow: {
         margin: 'auto',
         flexDirection: 'row',
      },
      tableRowHeader: {
         margin: 'auto',
         flexDirection: 'row',
         height: 20,
      },
      tableCol: {
         borderStyle: 'solid',
         borderColor: '#ecf3f1',
         borderBottomWidth: 1,
         borderRightWidth: 1,
      },
      tableCell: {
         margin: 'auto',
         marginTop: 8,
         fontSize: 9,
      },
      tableCellHeader: {
         margin: 'auto',
         fontSize: 9,
      },
      logo: {
         width: '35vw',
         height: '7vw',
      },
   });

   const downloadPDF = async (pdfData) => {
      const excludedKeys = ['id', 'isVgp', 'mileage', 'atelierDescription', 'isKey', 'isDocuments', 'date', 'time', 'vehicleReservationControlDocuments'];
      const departureConditions = Object.entries(pdfData?.vehicleReservationControl)
         .filter(([key]) => !excludedKeys.includes(key))
         .map(([key, value]) => {
            let status;
            if (value === 'GOOD') {
               status = 'good';
            } else if (value === 'MEDIUM') {
               status = 'medium';
            } else {
               status = 'bad';
            }
            return {
               name: key.replace('Status', ''),
               label: key.replace('Status', '').charAt(0).toUpperCase() + key.replace('Status', '').slice(1),
               status: status,
            };
         });

      const onRender = (e) => {
         return `${e.pageNumber}/${e.totalPages}`;
      };

      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }}>
               <View style={styles.header}>
                  <View>
                     <Text style={{ fontSize: '11px', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Metaloc</Text>
                     <Text style={{ fontSize: '11px' }}>3 rue du compas</Text>
                     <Text style={{ fontSize: '11px' }}>95310 Saint-Ouen-l'Aumône</Text>
                     <Text style={{ fontSize: '11px' }}>01 61 03 18 02</Text>
                  </View>
                  <Image style={styles.logo} src={require('../../assets/logo.png')} />
               </View>
               <View style={styles.content}>
                  <Text style={{ textAlign: 'center', fontSize: '11px', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                     {t('pages.pdf.bdNo')} {pdfData?.id}
                  </Text>
                  <Text style={{ textAlign: 'center', fontSize: '11px', marginTop: 10, backgroundColor: '#9acf7a', padding: 3 }}>
                     {t('pages.pdf.contactInfo')}
                  </Text>
                  <View style={{ flexDirection: 'row', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                     <Text style={styles.defaultText}>{t('pages.offers.customer')} </Text>
                     <Text style={{ fontSize: '11px', maxWidth: '75%' }}>
                        {pdfData?.customer?.companyName} -{' '}
                        {pdfData?.customer?.addresses && pdfData?.customer?.addresses.length && pdfData?.customer?.addresses[0]?.address} -{' '}
                        {pdfData?.customer?.phone}
                     </Text>
                  </View>
                  <View style={{ flexDirection: 'row', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                     <Text style={styles.defaultText}>{t('global.table.contract')} </Text>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.orderItems[0]?.order?.orderNumber} </Text>
                  </View>
                  <View style={{ flexDirection: 'row', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                     <Text style={styles.defaultText}>{t('global.table.placeName')} </Text>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontSize: '11px' }}>
                           {(pdfData?.orderItems[0]?.order?.address.placeName && pdfData?.orderItems[0]?.order?.address.placeName.concat(', ')) || ''}
                        </Text>
                        <Text style={{ fontSize: '11px' }}>
                           {(pdfData?.orderItems[0]?.order?.address.address && pdfData?.orderItems[0]?.order?.address.address.concat(', ')) || ''}
                        </Text>
                        <Text style={{ fontSize: '11px' }}>
                           {(pdfData?.orderItems[0]?.order?.address.addressContinuation &&
                              pdfData?.orderItems[0]?.order?.address.addressContinuation.concat(', ')) ||
                              ''}
                        </Text>
                        <Text style={{ fontSize: '11px' }}>
                           {(pdfData?.orderItems[0]?.order?.address.postCode && pdfData?.orderItems[0]?.order?.address.postCode.concat(', ')) || ''}
                        </Text>
                        <Text style={{ fontSize: '11px' }}>
                           {(pdfData?.orderItems[0]?.order?.address.city && pdfData?.orderItems[0]?.order?.address.city) || ''}
                        </Text>
                     </View>
                  </View>
                  <Text style={{ textAlign: 'center', fontSize: '11px', marginTop: 10, backgroundColor: '#9acf7a', padding: 3 }}>MACHINE INFORMATIONS</Text>
                  <View style={{ flexDirection: 'row', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                     <Text style={styles.defaultText}>{t('global.table.brand')} </Text>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.vehicle?.brand || '-'} </Text>
                  </View>
                  <View style={{ flexDirection: 'row', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                     <Text style={styles.defaultText}>{t('global.table.description')} </Text>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.vehicle?.note} </Text>
                  </View>
                  <View style={{ flexDirection: 'row', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                     <Text style={styles.defaultText}>{t('global.table.machineCode')} </Text>
                     <Text style={{ fontSize: '11px' }}>{pdfData?.vehicle?.machineFullCode} </Text>
                  </View>
                  <View style={{ marginTop: 10, flexDirection: 'row' }}>
                     <View style={{ width: '100%', marginHorizontal: '5px' }}>
                        <Text
                           style={{
                              textAlign: 'center',
                              fontSize: '11px',
                              backgroundColor: '#9acf7a',
                              padding: 3,
                              width: '100%',
                           }}
                        >
                           {t('pages.contracts.departure').toUpperCase()}
                        </Text>
                        <View style={{ flexDirection: 'row', padding: '5px', alignItems: 'center', border: 1, padding: 5, borderColor: '#ecf3f1' }}>
                           <Text style={[styles.defaultText, { minWidth: 70 }]}>{t('global.table.date')} </Text>
                           <Text style={{ fontSize: '11px' }}>
                              {moment(pdfData?.vehicleReservationControl?.date).format('DD/MM/YYYY') +
                                 ' ' +
                                 moment(pdfData?.vehicleReservationControl?.time, 'HH:mm:ss').format('HH:mm')}
                           </Text>
                        </View>
                        <Text
                           style={{
                              textAlign: 'center',
                              fontSize: '11px',
                              backgroundColor: '#9acf7a',
                              padding: 3,
                              width: '100%',
                              marginTop: 10,
                           }}
                        >
                           {t('pages.pdf.generalCond')}
                        </Text>
                        <View style={styles.table}>
                           {/* Tablo başlıkları */}
                           <View style={styles.tableRowHeader}>
                              <View style={[styles.tableCol, { width: '40%' }]}>
                                 <Text style={styles.tableCellHeader}></Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.departuresAndReturns.good')}
                                 </Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.departuresAndReturns.medium')}
                                 </Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.departuresAndReturns.bad')}
                                 </Text>
                              </View>
                           </View>
                           {/* Satırlar */}
                           {departureConditions &&
                              departureConditions.length &&
                              departureConditions.map((condition, index) => (
                                 <View style={styles.tableRow} key={index}>
                                    <View style={[styles.tableCol, { width: '40%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{condition?.label}</Text>
                                    </View>
                                    <View style={[styles.tableCol, { width: '20%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{condition?.status === 'good' ? 'X' : ''}</Text>
                                    </View>
                                    <View style={[styles.tableCol, { width: '20%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{condition?.status === 'medium' ? 'X' : ''}</Text>
                                    </View>
                                    <View style={[styles.tableCol, { width: '20%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{condition?.status === 'bad' ? 'X' : ''}</Text>
                                    </View>
                                 </View>
                              ))}
                           <View style={{ borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#ecf3f1', marginTop: 10 }}>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.offers.documents')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}>{t('pages.departuresAndReturns.vgp')}</Text>
                                    <Text style={{ marginRight: 15 }}>{pdfData?.vehicleReservationControl?.isVgp ? 'X' : ''}</Text>
                                    <Text style={{ marginRight: 15 }}>-</Text>
                                    <Text style={{ marginRight: 10 }}>{t('pages.pdf.userManuel')}</Text>
                                    <Text>{pdfData?.vehicleReservationControl?.isDocuments ? 'X' : ''}</Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.mileage')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}>{pdfData?.vehicleReservationControl?.mileage}</Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.keys')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}>{pdfData?.vehicleReservationControl?.isKey ? 'X' : ''}</Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.diesel')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingVertical: 5 }]}>
                                       {t('pages.pdf.workshopObservations').split(' ')[0]}
                                    </Text>
                                    <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingBottom: 5 }]}>
                                       {t('pages.pdf.workshopObservations').split(' ')[1]}
                                    </Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}>{pdfData?.vehicleReservationControl?.atelierDescription || '-'}</Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.technician')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}>{pdfData?.customer?.user?.name}</Text>
                                 </View>
                              </View>
                           </View>
                        </View>
                     </View>
                     <View style={{ width: '100%', marginHorizontal: '5px' }}>
                        <Text
                           style={{
                              textAlign: 'center',
                              fontSize: '11px',
                              backgroundColor: '#9acf7a',
                              padding: 3,
                              width: '100%',
                           }}
                        >
                           {t('pages.contracts.return').toUpperCase()}
                        </Text>
                        <View style={{ flexDirection: 'row', padding: '5px', alignItems: 'center', border: 1, padding: 5, borderColor: '#ecf3f1' }}>
                           <Text style={[styles.defaultText, { minWidth: 70 }]}>{t('global.table.date')} </Text>
                           <Text style={{ fontSize: '11px' }}></Text>
                        </View>
                        <Text
                           style={{
                              textAlign: 'center',
                              fontSize: '11px',
                              backgroundColor: '#9acf7a',
                              padding: 3,
                              width: '100%',
                              marginTop: 10,
                           }}
                        >
                           {t('pages.pdf.generalCond')}
                        </Text>
                        <View style={styles.table}>
                           {/* Tablo başlıkları */}
                           <View style={styles.tableRowHeader}>
                              <View style={[styles.tableCol, { width: '40%' }]}>
                                 <Text style={styles.tableCellHeader}></Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.departuresAndReturns.good')}
                                 </Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.departuresAndReturns.medium')}
                                 </Text>
                              </View>
                              <View style={[styles.tableCol, { width: '20%' }]}>
                                 <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.departuresAndReturns.bad')}
                                 </Text>
                              </View>
                           </View>
                           {/* Satırlar */}
                           {departureConditions &&
                              departureConditions.length &&
                              departureConditions.map((condition, index) => (
                                 <View style={styles.tableRow} key={index}>
                                    <View style={[styles.tableCol, { width: '40%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{condition?.label}</Text>
                                    </View>
                                    <View style={[styles.tableCol, { width: '20%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}></Text>
                                    </View>
                                    <View style={[styles.tableCol, { width: '20%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}></Text>
                                    </View>
                                    <View style={[styles.tableCol, { width: '20%' }]}>
                                       <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}></Text>
                                    </View>
                                 </View>
                              ))}
                           <View style={{ borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#ecf3f1', marginTop: 10 }}>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.offers.documents')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.mileage')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.keys')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.diesel')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingVertical: 5 }]}>
                                       {t('pages.pdf.workshopObservations').split(' ')[0]}
                                    </Text>
                                    <Text style={[styles.tableCellHeader, { fontWeight: 'bold', fontFamily: 'Helvetica-Bold', paddingBottom: 5 }]}>
                                       {t('pages.pdf.workshopObservations').split(' ')[1]}
                                    </Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                              <View style={{ flexDirection: 'row' }}>
                                 <View style={[styles.tableCol, { width: '40%' }]}>
                                    <Text style={[styles.tableCellHeader, { paddingVertical: 5 }]}>{t('pages.pdf.technician')}</Text>
                                 </View>
                                 <View style={{ flexDirection: 'row', fontSize: 10, marginTop: 5 }}>
                                    <Text style={{ marginRight: 10, paddingLeft: 5 }}></Text>
                                 </View>
                              </View>
                           </View>
                        </View>
                     </View>
                  </View>
               </View>
               <View style={{ alignItems: 'center', textAlign: 'center', position: 'absolute', bottom: 20, left: '40%' }}>
                  <Text style={{ fontSize: '11px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '11px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '11px' }}>Email: contact@metaloc.fr</Text>
               </View>
               <View style={{ alignItems: 'center', textAlign: 'center', position: 'absolute', bottom: 30, right: 40 }}>
                  <Text style={{ fontSize: '10px' }} render={(e) => onRender(e)}>
                     {onRender}
                  </Text>
               </View>
            </Page>
            {pdfData &&
               pdfData.vehicleReservationControl &&
               pdfData.vehicleReservationControl.vehicleReservationControlDocuments &&
               pdfData.vehicleReservationControl.vehicleReservationControlDocuments.length > 0 &&
               pdfData.vehicleReservationControl.vehicleReservationControlDocuments.map((doc, index) => {
                  return (
                     <Page size="A4" style={{ padding: 20 }} key={index}>
                        <View style={styles.header}>
                           <View>
                              <Text style={{ fontSize: '11px', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>Metaloc</Text>
                              <Text style={{ fontSize: '11px' }}>3 rue du compas</Text>
                              <Text style={{ fontSize: '11px' }}>95310 Saint-Ouen-l'Aumône</Text>
                              <Text style={{ fontSize: '11px' }}>01 61 03 18 02</Text>
                           </View>
                           <Image style={styles.logo} src={require('../../assets/logo.png')} />
                        </View>
                        <View style={styles.content}>
                           <View style={{ flexDirection: 'row', justifyContent: 'center', borderBottom: 1, borderColor: '#ecf3f1', padding: '5px' }}>
                              <Image
                                 src={process.env.REACT_APP_LINK.split('/graphql')[0] + doc?.document?.url}
                                 style={{ maxWidth: '90%', maxHeight: '90%', marginTop: 20 }}
                              />
                           </View>
                        </View>
                        {/* Footer */}
                        <View fixed style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                           <Text />
                           <View>
                              <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                              <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                              <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                           </View>
                           <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
                        </View>
                     </Page>
                  );
               })}
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(blob, 'atelier_bd.pdf'); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   const getPdfData = (data) => {
      dispatch(getAtelierPDF(data.id)).then((res) => {
         if (res && res.vehicleReservationControl) {
            downloadPDF(res);
         }
      });
   };

   return (
      <>
         <Button
            severity="success"
            icon="pi pi-file-pdf"
            className="!w-[40px] px-0 !py-[6px] me-2"
            onClick={() => {
               getPdfData(data);
            }}
            disabled={disabled}
         />
      </>
   );
};

export default AtelierPDF;
