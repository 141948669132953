// eslint-disable-next-line import/no-anonymous-default-export
export default {
   translation: {
      global: {
         sidebar: {
            dashboard: 'Anasayfa',
            customers: 'Musteriler',
            orders: 'Faturalar',
            offers: 'Teklifler',
            settings: 'Ayarlar',
            logout: 'Cikis Yap',
            users: 'Kullanicilar',
         },
      },
      pages: {
         settings: {
            users: {
               firstName: 'Ad',
               lastName: 'Soyad',
               email: 'E-Posta',
               identityNumber: 'Kimlik Numarası',
               dateOfBirth: 'Doğum Tarihi',
               gender: 'Cinsiyet',
               notes: 'Notlar',
               role: 'Rol',
               phoneNumber: 'Telefon Numarasi',
               deleted: 'Silindi',
            },
         },
         malfunctions: {
            caseNumber: 'Arıza Numarası',
            faultDate: 'Ariza Tarihi',
            technican: 'Teknisyen',
            machineCode: 'Makine Kodu',
            customer: 'Müşteri',
            customerPostCode: 'Müşteri Posta Kodu',
            customerCity: 'Müşteri Şehir',
            view: 'Görüntüle',
            detail: 'Detay',
            totalMachineMalfunctionRecord: 'Toplam Makine Ariza Kaydi',
            totalResolvedMachineMalfunctionRecord: 'Toplam Çözülmüş Makine Arıza Kaydı',
            totalPendingMachineMalfunctionRecord: 'Toplam Bekleyen Makine Arıza Kaydı',
            addNewMalfunctionRecord: 'YENİ ARIZA KAYDI EKLE',
            informations: 'Genel Bilgiler',
            malfunctions: 'Arizalar',
            malfunctionDocuments: 'Ariza Belgeleri',
            finish: 'Bitir',
            type: 'Tip',
            faultDate: 'Ariza Tarihi',
            contactName: 'İletisim Adi',
            contactEmail: 'İletisim E-Posta',
            contactPhone: 'İletisim Telefon',
            explanation: 'Açıklama',
            notes: 'Not',
            isMalfunctionOperatorDefault: 'Operatör Hatası',
            isMalfunctionBatteryFailure: 'Batarya Arızası',
            isMalfunctionEngineFailure: 'Motor Arızası',
            isMalfunctionBodyDamage: 'Gövde Hasarı',
            isMalfunctionImproperHandling: 'Uygunsuz Kullanım',
            isMalfunctionChargerFailure: 'Şarj Cihazı Arızası',
            isMalfunctionElectricalFailure: 'Elektrik Arızası',
            isMalfunctionSafetyAccessoriesFailure: 'Güvenlik Aksesuarları Arızası',
            isMalfunctionRemoteControlFailure: 'Uzaktan Kumanda Arızası',
            isMalfunctionBatteryCharger: 'Batarya Şarj Cihazı Arızası',
            isMalfunctionTornCable: 'Yırtık Kablo',
            isMalfunctionHydraulicFailure: 'Hidrolik Arızası',
            isMalfunctionOther: 'Diğer',
            saveAndContinue: 'Kaydet & Devam Et',
            machine: 'Makine',
            MalfunctionRecordDetail: 'Ariza Kaydi Detayi',
            generalInformations: 'Genel Bilgiler',
            solutions: 'Çözümler',
            malfunctionDocuments: 'Ariza Belgeleri',
            primaryContactName: 'Birincil İletişim Adı',
            emailAddress: 'E-Posta Adresi',
            previous: 'Geri',
            malfunctionOther: 'Diğer',
            downloadReport: 'Ariza Raporunu İndir',
            technician: 'Teknisyen',
            malfunctionUpdated: 'Ariza kaydı başarıyla güncellendi!',
            arrivalTime: 'Varış Zamanı',
            isSolutionWarranty: 'Garanti',
            isSolutionVehicleReplacement: 'Araç Değişimi',
            isSolutionMaintenance: 'Bakım',
            isSolutionDepotRepair: 'Depo Onarımı',
            isSolutionDefectDepotPreparation: 'Defo Hazırlığı',
            isSolutionOnSiteRepair: 'Yerinde Onarım',
            isSolutionAfterSalesServicePickup: 'Satış Sonrası Servis Alımı',
            isSolutionOther: 'Diğer',
            isJobDone: 'İş Tamamlandı',
            isPartsAndSmallSupplies: 'Parçalar ve Küçük Malzemeler',
            nextStep: 'Sonraki Adım',
            isInvoiced: 'Faturalandı',
            isMakeAnOffer: 'Teklif Yap',
            isMaintenanceContract: 'Bakım Sözleşmesi',
            departureTime: 'Çıkış Zamanı',
            note: 'Not',
            date: 'Tarih',
            placeName: 'Yer Adı',
            customerSignature: 'Müşteri İmzası',
            clearSignature: 'İmzayı Temizle',
            createDocumentMessage: 'Belge başarıyla oluşturuldu!',
            addNewMalfunctionDocument: 'Yeni Arıza Kaydı Belgesi Ekle',
            isComplated: 'Tamamlandı',
         },
         planning: {
            id: 'ID',
            vehicleReservations: 'Makine Rezervasyonları',
            machine: 'Makine',
            machineCode: 'Makine Kodu',
            supplier: 'Tedarikci',
            supplierName: 'Tedarikci Adı',
            address: 'Adres',
            beginDate: 'Baslangiç Tarihi',
            endDate: 'Bitiş Tarihi',
            customer: 'Müşteri',
            inventory: 'Envanter',
            machineGroup: 'Makine Grubu',
            contractNo: 'Sözleşme No',
            placeName: 'Yer Adı',
            rent: 'Kiralık',
            malfunction: 'Arızalı',
            reserved: 'Rezerve',
            available: 'Müsait',
            returnPlanned: 'Planlı Dönüş',
         },
         departuresAndReturns: {
            departures: 'Departures',
            returns: 'Returns',
         },
      },
   },
};
