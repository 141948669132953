import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import {
   createVehicleMalfunction,
   getCustomerIdName,
   getVehicleIdMachineCode,
   getVehicleMalfunctionKinds,
   updateVehicleMalfunction,
} from '../../../store/apis/MalfunctionApi';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import moment from 'moment';
import CreateInformationAddress from './CreateInformationAddress';
import { Dialog } from 'primereact/dialog';
import { set } from 'react-hook-form';

const Informations = (props) => {
   const dispatch = useDispatch();
   const [vehicleMalfunctionKinds, setVehicleMalfunctionKinds] = useState();
   const [vehicleIdMachineCode, setVehicleIdMachineCode] = useState([]);
   const [customerIdName, setCustomerIdName] = useState([]);
   const [addresses, setAddresses] = useState([]);
   const [errors, setErrors] = useState({});
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [selectedAddressId, setSelectedAddressId] = useState(null);

   useEffect(() => {
      dispatch(getVehicleMalfunctionKinds()).then((res) => setVehicleMalfunctionKinds(res));
      dispatch(getVehicleIdMachineCode({ isDeleted: false })).then((res) => setVehicleIdMachineCode(res));
      dispatch(getCustomerIdName()).then((res) => setCustomerIdName(res));
      setAddresses([]);
   }, []);

   useEffect(() => {
      if (props.informationsInputs.customerId) {
         setAddresses(
            customerIdName.find((item) => item.id === props.informationsInputs.customerId) &&
               customerIdName.find((item) => item.id === props.informationsInputs.customerId).addresses
               ? customerIdName.find((item) => item.id === props.informationsInputs.customerId).addresses
               : [],
         );
      }
   }, [props.informationsInputs.customerId]);

   useEffect(() => {
      if (createdAddress) {
         if (selectedAddressId) {
            setAddresses(addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)));
            setCustomerIdName(
               customerIdName.map((customer) => {
                  if (customer.id === props.informationsInputs.customerId) {
                     return {
                        ...customer,
                        addresses: customer.addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)),
                     };
                  }
                  return customer;
               }),
            );
            props.setInformationsInputs({ ...props.informationsInputs, addressId: createdAddress.id });
         } else {
            setAddresses([...addresses, createdAddress]);

            props.setInformationsInputs({ ...props.informationsInputs, addressId: createdAddress.id });
         }
      }
   }, [createdAddress]);

   // FORM VALIDATION
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      props.setInformationsInputs({ ...props.informationsInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['vehicleMalfunctionKindId', 'vehicleId', 'faultDate', 'description'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!props.informationsInputs[input]) {
            updateErrors(input, props.informationsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (validateForm()) {
         let _data = { ...props.informationsInputs, faultDate: moment(props.informationsInputs.faultDate).format('YYYY-MM-DD') };
         if (props.createdId) {
            const result = await dispatch(updateVehicleMalfunction({ ..._data, id: props.createdId }));
            if (result) {
               props.setActiveIndex(props.activeIndex + 1);
            }
         } else {
            const result = await dispatch(createVehicleMalfunction(_data));
            if (result) {
               props.setActiveIndex(props.activeIndex + 1);
               props.setCreatedId(result.id);
            }
         }
      }
   };

   const updateAddress = () => {
      setSelectedAddressId(props.informationsInputs.addressId);
      setCreateAddressDialog(true);
   };

   return (
      <>
         <div className="grid">
            <div className="col-12 md:col-6 lg:col-4">
               <label>
                  {t('pages.malfunctions.type')} <span className="text-red-500">*</span>
               </label>
               <Dropdown
                  options={vehicleMalfunctionKinds}
                  value={props.informationsInputs.vehicleMalfunctionKindId}
                  onChange={(e) => handleInputChange(e, 'vehicleMalfunctionKindId')}
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t('global.select')}
                  className={`w-full input-detail ${errors.vehicleMalfunctionKindId && 'input-error'}`}
                  showClear
               />
               {errors.vehicleMalfunctionKindId && <small className="p-error">{errors.vehicleMalfunctionKindId}</small>}
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>
                  {t('pages.malfunctions.machine')} <span className="text-red-500">*</span>
               </label>
               <Dropdown
                  filter
                  options={vehicleIdMachineCode}
                  value={props.informationsInputs.vehicleId}
                  onChange={(e) => handleInputChange(e, 'vehicleId')}
                  optionLabel="machineFullCode"
                  optionValue="id"
                  placeholder={t('global.select')}
                  className={`w-full input-detail ${errors.vehicleId && 'input-error'}`}
                  showClear
               />
               {errors.vehicleId && <small className="p-error">{errors.vehicleId}</small>}
            </div>

            {props.informationsInputs.vehicleMalfunctionKindId == 2 || props.informationsInputs.vehicleMalfunctionKindId == 3 ? (
               <>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>
                        {t('pages.malfunctions.customer')} <span className="text-red-500">*</span>
                     </label>
                     <Dropdown
                        filter
                        options={customerIdName}
                        value={props.informationsInputs.customerId}
                        onChange={(e) => handleInputChange(e, 'customerId')}
                        optionLabel="companyName"
                        optionValue="id"
                        placeholder={t('global.select')}
                        className={`w-full input-detail ${errors.customerId && 'input-error'}`}
                        showClear
                     />
                     {errors.customerId && <small className="p-error">{errors.customerId}</small>}
                  </div>

                  <div className="col-12 md:col-6 lg:col-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
                     <div style={{ marginRight: '10px', flex: '1' }}>
                        <label className={`${!props.informationsInputs.customerId && 'text-gray-300'}`}>{t('pages.planning.placeName')}</label>
                        <Dropdown
                           filter
                           disabled={!props.informationsInputs.customerId}
                           options={addresses ? addresses : []}
                           value={props.informationsInputs.addressId}
                           onChange={(e) => handleInputChange(e, 'addressId')}
                           optionLabel="placeName"
                           optionValue="id"
                           placeholder={t('global.select')}
                           className={`w-full input-detail`}
                           showClear
                        />
                     </div>
                     {props.informationsInputs.addressId && props.informationsInputs.customerId && (
                        <Button
                           icon="pi pi-pencil"
                           severity="warning"
                           className="mt-4 me-2 h-[35px] w-[35px]"
                           onClick={updateAddress}
                           disabled={!props.informationsInputs.customerId}
                        />
                     )}

                     <Button
                        icon="pi pi-plus"
                        className="mt-4 h-[35px] w-[35px]"
                        onClick={() => setCreateAddressDialog(true)}
                        disabled={!props.informationsInputs.customerId}
                     />
                  </div>
               </>
            ) : (
               ''
            )}

            <div className="col-12 md:col-6 lg:col-4">
               <label>
                  {t('pages.malfunctions.faultDate')} <span className="text-red-500">*</span>
               </label>
               <Calendar
                  value={props.informationsInputs.faultDate}
                  onChange={(e) => handleInputChange(e, 'faultDate')}
                  dateFormat="dd/mm/yy"
                  placeholder={t('global.select')}
                  className={`w-full input-detail ${errors.faultDate && 'input-error'}`}
               />
               {errors.faultDate && <small className="p-error">{errors.faultDate}</small>}
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.primaryContactName')}</label>
               <InputText
                  value={props.informationsInputs.contactName}
                  onChange={(e) => handleInputChange(e, 'contactName')}
                  maxLength={50}
                  className={`w-full input-detail`}
               />
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.emailAddress')}</label>
               <InputText
                  value={props.informationsInputs.contactEmail}
                  onChange={(e) => handleInputChange(e, 'contactEmail')}
                  maxLength={50}
                  className={`w-full input-detail`}
               />
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.contactPhone')}</label>
               <InputText
                  value={props.informationsInputs.contactPhone}
                  onChange={(e) => handleInputChange(e, 'contactPhone')}
                  maxLength={15}
                  className={`w-full input-detail`}
                  type="number"
               />
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>
                  {t('pages.malfunctions.explanation')} <span className="text-red-500">*</span>
               </label>
               <InputTextarea
                  value={props.informationsInputs.description}
                  onChange={(e) => handleInputChange(e, 'description')}
                  rows={1}
                  className={`w-full input-detail ${errors.description && 'input-error'}`}
               />
               {errors.description && <small className="p-error">{errors.description}</small>}
            </div>

            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.malfunctions.notes')}</label>
               <InputTextarea value={props.informationsInputs.note} onChange={(e) => handleInputChange(e, 'note')} rows={1} className={`w-full input-detail`} />
            </div>
         </div>

         <div className="flex justify-end mt-4">
            <Button
               label={t('pages.malfunctions.saveAndContinue')}
               icon="pi pi-chevron-right"
               iconPos="right"
               onClick={handleSubmit}
               disabled={props.activeIndex === 2}
            />
         </div>

         <Dialog
            header={t('pages.offers.createANewAddress')}
            className="w-[70vw]"
            visible={createAddressDialog}
            onHide={() => {
               setCreateAddressDialog(false);
               setSelectedAddressId(null);
            }}
         >
            <CreateInformationAddress
               setCreateAddressDialog={setCreateAddressDialog}
               setCreatedAddress={setCreatedAddress}
               customerId={props.informationsInputs.customerId}
               selectedAddressId={selectedAddressId}
            />
         </Dialog>
      </>
   );
};

export default Informations;
