import { gql } from '@apollo/client';

export const GET_GLOBAL_SETTINGS = gql`
   query globalSetting {
      globalSetting {
         id
         tax
         ecoPrice
         insuranceRate
      }
   }
`;

export const GET_DASHBOARD_STATISTICS = gql`
   query dashboardStatistics {
      dashboardStatistics
   }
`;

export const GET_FINANCE_STATISTICS = gql`
   query financeStatistics($startDate: Date!, $endDate: Date!, $dailyRevenueStartDate: Date, $dailyRevenueEndDate: Date) {
      financeStatistics(startDate: $startDate, endDate: $endDate, dailyRevenueStartDate: $dailyRevenueStartDate, dailyRevenueEndDate: $dailyRevenueEndDate)
   }
`;

export const GET_ATELIER_PDF = gql`
   query vehicleReservation($id: ID!) {
      vehicleReservation(id: $id) {
         id
         customer {
            id
            companyName
            addresses {
               id
               address
               addressContinuation
               city
               postCode
            }
            phone
            user {
               id
               name
            }
         }
         orderItems {
            id
            category
            order {
               id
               orderNumber
               address {
                  id
                  placeName
                  address
                  addressContinuation
                  city
                  postCode
               }
            }
         }
         vehicle {
            id
            machineFullCode
            brand
            note
         }
         beginDate
         vehicleReservationControl {
            id
            date
            time
            chassisStatus
            batteryStatus
            doorsStatus
            lowerBodyStatus
            paintStatus
            basketStatus
            cylindersStatus
            tiresStatus
            upperBodyStatus
            isVgp
            mileage
            atelierDescription
            isDocuments
            isKey
            vehicleReservationControlDocuments {
               id
               document {
                  url
                  size
                  name
                  path
               }
            }
         }
      }
   }
`;

export const GET_MALFUNCTION_PDF = gql`
   query vehicleMalfunction($id: ID!) {
      vehicleMalfunction(id: $id) {
         id
         address {
            id
            address
         }
         vehicle {
            id
            brand
            note
            machineFullCode
            serialNumber
         }
         customer {
            id
            companyName
            addresses {
               id
               address
               addressContinuation
               city
               postCode
            }
            phone
         }
         isMalfunctionOperatorDefault
         isMalfunctionBatteryFailure
         isMalfunctionEngineFailure
         isMalfunctionBodyDamage
         isMalfunctionImproperHandling
         isMalfunctionChargerFailure
         isMalfunctionElectricalFailure
         isMalfunctionSafetyAccessoriesFailure
         isMalfunctionRemoteControlFailure
         isMalfunctionBatteryCharger
         isMalfunctionTornCable
         isMalfunctionHydraulicFailure
         isMalfunctionOther
         isSolutionWarranty
         isSolutionVehicleReplacement
         isSolutionMaintenance
         isSolutionDepotRepair
         isSolutionDefectDepotPreparation
         isSolutionOnSiteRepair
         isSolutionAfterSalesServicePickup
         isSolutionOther
         isPartsAndSmallSupplies
         technicianUser {
            id
            name
         }
         technicianArrivalTime
         technicianDepartureTime
         technicianDepartureDate
         isInvoiced
         isMakeAnOffer
         isMaintenanceContract
         technicianNote
         customerSignature
      }
   }
`;

export const GET_OFFER_PDF = gql`
   query order($id: ID!) {
      order(id: $id) {
         id
         customer {
            id
            customerKind {
               id
               name
            }
            companyName
            phone
            addresses {
               id
               address
               addressContinuation
               city
               postCode
            }
         }
         orderNumber
         referenceNumber
         createdAt
         description
         address {
            id
            placeName
            address
            addressContinuation
            city
            postCode
            receiverName
         }
         orderItems {
            id
            category
            description
            quantity
            tax
            price
            vehicleReservation {
               id
               status
               vehicleGroup {
                  id
                  name
                  description
               }
               vehicle {
                  id
                  note
                  vehicleFuelKind {
                     id
                  }
               }
               beginDate
               endDate
               price
               isEco
               ecoPrice
               isInsurance
               insuranceRate
               insurancePrice
               isIncludingSaturdays
               isIncludingSundays
               isIncludingPublicHolidays
               deliveryFee
               pickupFee
               taxFee
               tax
               discountRate
               subTotal
               priceKind
            }
         }
      }
   }
`;

export const GET_INVOICE_PDF = gql`
   query invoice($id: ID!) {
      invoice(id: $id) {
         id
         status
         orderNumber
         invoiceNumber
         referenceNumber
         customer {
            id
            buyersBic
            buyersBan
            idNumber
            customerKind {
               id
               name
            }
            companyName
            phone
            addresses {
               id
               address
               addressContinuation
               city
               postCode
            }
         }
         address {
            id
            placeName
            address
            addressContinuation
            city
            postCode
            receiverName
         }
         vehicleGroup {
            id
            name
         }
         vehicle {
            id
            machineFullCode
            note
         }
         invoiceItems {
            id
            category
            description
            quantity
            tax
            price
         }
         saleDescription
         description
         privacyDescription
         beginDate
         endDate
         price
         ecoPrice
         insuranceRate
         deliveryFee
         pickupFee
         discount
         fuel
         dieselPrice
         totalBeforeTax
         tax
         totalAfterTax
         createdAt
         dueDate
         isFactor
         isIncludingSaturdays
         isIncludingSundays
         isIncludingPublicHolidays
         isEco
         isInsurance
         fiftyDiscount
         seventyDiscount
         oneHundredDiscount
         isSupplierInvoice
         isSupplierInvoiceArrived
         category
         quantity
         subTotal
         children {
            id
            status
            orderId
            orderNumber
            invoiceNumber
            referenceNumber
            customer {
               id
               companyName
            }
            address {
               id
               placeName
            }
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
               note
            }
            invoiceItems {
               id
               category
               description
               quantity
               tax
               price
            }
            description
            privacyDescription
            beginDate
            endDate
            price
            ecoPrice
            insuranceRate
            deliveryFee
            pickupFee
            discount
            fuel
            dieselPrice
            totalBeforeTax
            tax
            totalAfterTax
            createdAt
            dueDate
            isFactor
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isEco
            isInsurance
            fiftyDiscount
            seventyDiscount
            oneHundredDiscount
            isSupplierInvoice
            isSupplierInvoiceArrived
            category
            quantity
            subTotal
         }
      }
   }
`;

export const GET_REFUND_PDF = gql`
   query refund($id: ID!) {
      refund(id: $id) {
         id
         description
         refundNumber
         price
         invoice {
            id
            status
            orderNumber
            invoiceNumber
            referenceNumber
            customer {
               id
               buyersBic
               buyersBan
               idNumber
               customerKind {
                  id
                  name
               }
               companyName
               phone
               addresses {
                  id
                  address
                  addressContinuation
                  city
                  postCode
               }
            }
            address {
               id
               placeName
               address
               addressContinuation
               city
               postCode
            }
            vehicleGroup {
               id
               name
            }
            vehicle {
               id
               machineFullCode
               note
            }
            invoiceItems {
               id
               category
               description
               quantity
               tax
               price
            }
            description
            privacyDescription
            beginDate
            endDate
            price
            ecoPrice
            insuranceRate
            deliveryFee
            pickupFee
            discount
            fuel
            dieselPrice
            totalBeforeTax
            tax
            totalAfterTax
            createdAt
            dueDate
            isFactor
            isIncludingSaturdays
            isIncludingSundays
            isIncludingPublicHolidays
            isEco
            isInsurance
            fiftyDiscount
            seventyDiscount
            oneHundredDiscount
            isSupplierInvoice
            isSupplierInvoiceArrived
            category
            quantity
         }
      }
   }
`;

export const GET_CONTRACT_PDF = gql`
   query order($id: ID!) {
      order(id: $id) {
         id
         customer {
            id
            customerKind {
               id
               name
            }
            companyName
            phone
            addresses {
               id
               address
               addressContinuation
               city
               postCode
            }
         }
         orderNumber
         referenceNumber
         createdAt
         description
         address {
            id
            placeName
            address
            addressContinuation
            city
            postCode
         }
         orderItems {
            vehicleReservation {
               id
               vehicle {
                  id
                  supplierCustomer {
                     id
                  }
               }
            }
         }
      }
   }
`;

export const GET_GLOBAL_SEARCH = gql`
   query search($search: String!) {
      search(search: $search) {
         vehicles {
            id
            machineFullCode
         }
         offers {
            id
            orderNumber
         }
         contracts {
            id
            orderNumber
         }
         customers {
            id
            companyName
         }
      }
   }
`;

export const GET_VEHICLE_GROUP_PRICES = gql`
   query vehicleGroupPrices {
      vehicleGroupPrices(isDeleted: false) {
         id
         vehicleGroup {
            id
            name
            description
         }
         dailyPrice
         twoToFivePrice
         sixToFifteenPrice
         gtFifteenPrice
         monthlyPrice
      }
   }
`;

export const GET_VEHICLE_GROUPS = gql`
   query vehicleGroups {
      vehicleGroups(isDeleted: false) {
         id
         name
      }
   }
`;

export const CREATE_VEHICLE_GROUP_PRICE = gql`
   mutation createVehicleGroupPrice($data: CreateVehicleGroupPriceInput!) {
      createVehicleGroupPrice(data: $data) {
         vehicleGroupPrice {
            id
            vehicleGroup {
               id
               name
               description
            }
            dailyPrice
            twoToFivePrice
            sixToFifteenPrice
            gtFifteenPrice
            monthlyPrice
         }
      }
   }
`;

export const DELETE_VEHICLE_GROUP_PRICES = gql`
   mutation deleteVehicleGroupPrices($ids: [Int!]!) {
      deleteVehicleGroupPrices(ids: $ids) {
         success
      }
   }
`;

export const UPDATE_VEHICLE_GROUP_PRICE = gql`
   mutation updateVehicleGroupPrice($data: UpdateVehicleGroupPriceInput!) {
      updateVehicleGroupPrice(data: $data) {
         vehicleGroupPrice {
            id
            vehicleGroup {
               id
               name
               description
            }
            dailyPrice
            twoToFivePrice
            sixToFifteenPrice
            gtFifteenPrice
            monthlyPrice
         }
      }
   }
`;
