import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import TableComponent from '../../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getRefunds, updateInvoice, updateRefund, updateRefundInvoice } from '../../../../store/apis/ManagementApi';
import toast from 'react-hot-toast';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Paginator } from 'primereact/paginator';
import RefundPDF from '../../../../components/PDFS/RefundPDF';

const Refunds = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const { refunds } = useSelector((state) => state.management);
   const [refundCount, setRefundCount] = useState(0);
   const [first, setFirst] = useState(0);
   const [rows] = useState(20);
   const [updateRefundVisible, setUpdateRefundVisible] = useState({
      visible: false,
      data: null,
   });
   const [explanation, setExplanation] = useState('');
   const [confirmDialog, setConfirmDialog] = useState({
      visible: false,
      data: null,
   });

   useEffect(() => {
      dispatch(getRefunds(rows, first)).then((res) => {
         setRefundCount(res.refundCount);
      });
   }, [first]);

   useEffect(() => {
      setExplanation(updateRefundVisible.data?.description);
   }, [updateRefundVisible]);

   const updateSupplierInvoice = () => {
      const data = {
         id: confirmDialog?.data.invoice.id,
         isSupplierInvoiceArrived: confirmDialog?.data.invoice.isSupplierInvoiceArrived ? false : true,
      };
      dispatch(updateRefundInvoice(data)).then(() => {
         toast.success(t('pages.invoices.updateSupplierInvoiceSuccess'));
         setConfirmDialog({
            visible: false,
            data: null,
            type: null,
         });
      });
   };

   const updateInvoiceStatus = (rowData) => {
      const data = {
         id: rowData.invoice.id,
         status: 'PRE_INVOICE',
      };
      dispatch(updateInvoice(data)).then(() => {
         toast.success(t('pages.invoices.updateInvoiceStatusSuccess'));
      });
   };

   const tableColumns = [
      {
         field: 'supplierInvoice',
         name: 'supplierInvoice',
         header: t('pages.invoices.supplierInvoice'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  disabled={!rowData?.invoice?.isSupplierInvoice}
                  icon={!rowData?.invoice?.isSupplierInvoiceArrived ? 'pi pi-lock-open' : 'pi pi-lock'}
                  severity={!rowData?.invoice?.isSupplierInvoiceArrived ? 'warning' : 'success'}
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setConfirmDialog({
                        visible: true,
                        data: rowData,
                     })
                  }
               />
            );
         },
      },
      {
         field: 'invoiceLock',
         name: 'invoiceLock',
         header: t('pages.invoices.invoiceLock'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return <Button disabled icon="pi pi-lock" severity="warning" className="text-[14px] !py-[3px]" onClick={() => updateInvoiceStatus(rowData)} />;
         },
      },
      {
         field: 'pdf',
         name: 'pdf',
         header: t('pages.invoices.pdf'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return <RefundPDF data={rowData} />;
         },
      },
      {
         field: 'refundNumber',
         name: 'refundNumber',
         header: t('pages.invoices.refundNumber'),
         body: (rowData) => {
            return <span>{rowData.refundNumber ? rowData.refundNumber : '-'}</span>;
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.orderNumber}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.customer.companyName}</span>;
         },
      },
      {
         field: 'placeName',
         name: 'placeName',
         header: t('global.table.placeName'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.address.placeName}</span>;
         },
      },
      {
         field: 'totalBeforeTax',
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price).toFixed(2)}</span>;
         },
      },
      {
         field: 'totalAfterTax',
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{(parseFloat(rowData?.price) + (rowData?.price * rowData?.invoice?.tax) / 100).toFixed(2)}</span>;
         },
      },
      {
         field: 'creationDate',
         name: 'creationDate',
         header: t('pages.invoices.invoiceCreationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.invoice?.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'isFactor',
         name: 'isFactor',
         header: t('global.table.factor'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.isFactor ? t('global.yes') : t('global.no')}</span>;
         },
      },
      {
         field: 'isPaid',
         name: 'isPaid',
         header: t('global.table.status'),
         body: (rowData) => {
            return <span>{rowData?.invoice?.isPaid ? t('pages.invoices.paid') : t('pages.invoices.unpaid')}</span>;
         },
      },
      {
         field: 'comment',
         name: 'comment',
         header: t('pages.invoices.comment'),
         body: (rowData) => {
            return <span>{rowData.description ? rowData.description : '-'}</span>;
         },
      },
      {
         field: 'save',
         name: 'save',
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  icon="pi pi-pencil"
                  severity="warning"
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setUpdateRefundVisible({
                        visible: true,
                        data: rowData,
                     })
                  }
               />
            );
         },
      },
   ];

   const rowClassName = (rowData) => {
      const isDueDatePast = moment(rowData?.invoice?.dueDate).isBefore(moment(), 'day');
      return {
         'bg-green-100': rowData?.invoice?.isPaid,
         'bg-red-100': isDueDatePast && !rowData?.invoice?.isPaid,
      };
   };

   const handleUpdateRefund = () => {
      const data = {
         id: updateRefundVisible.data.id,
         description: explanation,
      };
      dispatch(updateRefund(data)).then(() => {
         toast.success(t('pages.invoices.updateRefundSuccess'));
         setUpdateRefundVisible({ visible: false, data: null });
      });
   };

   return (
      <>
         <Card>
            <TableComponent data={refunds || []} columns={tableColumns} scrollable={true} rowClassName={rowClassName} />
            <div className="flex items-center justify-center">
               <Paginator first={first} rows={rows} totalRecords={refundCount} onPageChange={(e) => setFirst(e.first)} />
            </div>
         </Card>

         <Dialog
            header={t('pages.invoices.updateRefund')}
            visible={updateRefundVisible.visible}
            style={{ width: '20vw' }}
            onHide={() => setUpdateRefundVisible({ visible: false, data: null })}
         >
            <div>
               <label>{t('pages.contracts.explanation')}</label>
               <InputTextarea rows={2} value={explanation} onChange={(e) => setExplanation(e.target.value)} className="w-full input-detail" />
            </div>
            <div className="mt-3">
               <Button label={t('global.save')} className="w-full" onClick={handleUpdateRefund} severity="success" />
            </div>
         </Dialog>

         {/* confirm dialog */}
         <Dialog
            header={t('pages.invoices.supplierInvoice')}
            visible={confirmDialog.visible}
            style={{ width: '400px' }}
            onHide={() =>
               setConfirmDialog({
                  visible: false,
                  data: null,
                  type: null,
               })
            }
         >
            <div className="confirmation-content">
               {
                  <span>
                     {!confirmDialog?.data?.invoice.isSupplierInvoiceArrived
                        ? t('pages.invoices.updateSupplierInvoiceMessage')
                        : t('pages.invoices.updateSupplierInvoiceMessage2')}
                  </span>
               }
            </div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setConfirmDialog({
                        visible: false,
                        data: null,
                        type: null,
                     })
                  }
               />
               <Button label={t('global.apply')} severity="success" onClick={updateSupplierInvoice} />
            </div>
         </Dialog>
      </>
   );
};

export default Refunds;
