import React, { useEffect, useState } from 'react';
import { Document, Page, View, Text, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { getInvoicePDF } from '../../../store/apis/SharedApi';
import moment from 'moment';
import { getPublicHolidays } from '../../../store/apis/OffersApi';

const InvoiceOffer1 = ({ data, header }) => {
   const dispatch = useDispatch();
   const [publicHolidays, setPublicHolidays] = useState([]);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   // Create styles
   const styles = StyleSheet.create({
      page: {
         flexDirection: 'row',
      },
      section: {
         marginVertical: 20,
         flexGrow: 1,
      },
      content: {
         fontSize: 10,
         textAlign: 'left',
         fontWeight: 'bold',
      },
      contentHeader: {
         fontSize: 10,
         textAlign: 'left',
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
      },
      greenBox: {
         backgroundColor: '#84bf60',
         padding: 10,
         alignItems: 'center',
      },
      greenHeader: {
         fontSize: 12,
         color: '#1E8449',
      },
      header: {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'center',
      },
      logo: {
         width: '100%',
         height: '7vw',
      },
      table: {
         display: 'table',
         width: 'auto',
      },
      tableRowHeader: {
         margin: 'auto',
         flexDirection: 'row',
         backgroundColor: '#6494ed',
         height: 30,
      },
      tableRow: {
         margin: 'auto',
         marginBottom: 10,
         flexDirection: 'row',
      },
      tableCell: {
         marginTop: 5,
         fontSize: 10,
      },
      tableCellHeader: {
         margin: 'auto',
         marginTop: 10,
         fontSize: 10,
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
         color: 'white',
      },
   });

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };
   const formatPrice = (price) => `${parseFloat(price).toFixed(2).replace('.', ',')} €`;

   const downloadPDF = async (pdfData) => {
      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }}>
               <View style={styles.header}>
                  <Image style={styles.logo} src={require('../../../assets/header.png')} />
               </View>
               <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View style={styles.section}>
                     <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: '#6494ed' }}>
                        {t('pages.customers.customerDetails')}
                     </Text>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.pdf.customerId')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.id}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.companyName')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.companyName}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.customers.address')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].address ? pdfData?.customer?.addresses[0].address + ', ' : ''}</Text>
                        <Text style={styles.content}>
                           {pdfData?.customer?.addresses[0].addressContinuation ? pdfData?.customer?.addresses[0].addressContinuation + ', ' : ''}
                        </Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].postCode ? pdfData?.customer?.addresses[0].postCode + ', ' : ''}</Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].city ? pdfData?.customer?.addresses[0].city : ''}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.identityNumber')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.idNumber}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.offers.receiverName')}: </Text>
                        <Text style={styles.content}>{pdfData?.address?.receiverName}</Text>
                     </View>
                     <View style={{ flexDirection: 'row', marginTop: 20 }}>
                        <Text style={styles.contentHeader}>{t('global.table.contractNumber')}: </Text>
                        <Text style={styles.content}>{pdfData?.orderNumber}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.date')}: </Text>
                        <Text style={styles.content}>{moment(pdfData?.createdAt).format('DD/MM/YYYY')}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.invoices.dueDate')}: </Text>
                        <Text style={styles.content}>{moment(pdfData?.dueDate).format('DD/MM/YYYY')}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.offers.poNumber')}: </Text>
                        <Text style={styles.content}>{pdfData?.referenceNumber}</Text>
                     </View>
                  </View>
                  <View style={styles.section}>
                     <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: '#6494ed' }}>
                        {t('pages.customers.deliveryAddress')}
                     </Text>
                     <View style={{ border: 1, padding: 15, minHeight: 80, alignItems: 'center' }}>
                        <Text style={styles.content}>{pdfData?.address.placeName || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.address || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.addressContinuation || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.postCode + ', ' + pdfData?.address.city || ''}</Text>
                     </View>
                     <View style={[styles.greenBox, { marginTop: 10 }]}>
                        <Text style={{ fontSize: 12, color: 'white' }}>
                           {pdfData?.status === 'PRE_INVOICE' ? t('global.PENDING') : t('global.table.invoiceNumber') + ' ' + pdfData?.invoiceNumber}
                        </Text>
                     </View>
                  </View>
               </View>

               <View style={styles.table}>
                  {/* Tablo başlıkları */}
                  <View style={styles.tableRowHeader}>
                     <View style={[styles.tableCol, { width: '18%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.reference')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '40%' }]}>
                        <Text style={styles.tableCellHeader}>{t('global.table.description')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.price')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.quantity')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.totalPrice')}</Text>
                     </View>
                  </View>
                  {/* Satırlar */}
                  {pdfData?.children && pdfData?.children.length > 0 ? (
                     pdfData?.children.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                           <View style={[styles.tableCol, { width: '14%', marginTop: 10 }]}>
                              <Text style={styles.tableCell}>{item?.vehicleGroup?.name}</Text>
                           </View>
                           <View style={[styles.tableCol, { width: '40%' }]}>
                              <View>
                                 <Text style={styles.tableCell}>
                                    {item?.vehicle
                                       ? item?.vehicle.machineFullCode.replace(/\s/g, '') + ': ' + item?.vehicle.note
                                       : item?.description
                                       ? item?.description
                                       : '-'}
                                 </Text>
                                 <Text style={[styles.tableCell, { fontFamily: 'Helvetica-Bold' }]}>
                                    {t('pages.pdf.start')}: {moment(item?.vehicleReservation?.beginDate).format('DD/MM/YYYY')} {t('pages.pdf.end')}:{' '}
                                    {moment(item?.vehicleReservation?.endDate).format('DD/MM/YYYY')}
                                 </Text>
                              </View>
                              <View style={{ marginTop: 10 }}>
                                 <Text style={styles.tableCell}>{t('pages.offers.isEco')}</Text>
                                 <Text style={styles.tableCell}>{t('pages.offers.isInsurance') + ' 10%'}</Text>
                                 <Text style={styles.tableCell}>{t('pages.offers.deliveryFee')}</Text>
                                 <Text style={styles.tableCell}>{t('pages.offers.pickupFee')}</Text>
                                 <Text style={styles.tableCell}>{t('pages.planning.rent')}</Text>
                              </View>
                           </View>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                              <View style={{ marginTop: 25 }}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {item?.isEco ? (item?.ecoPrice).replace('.', ',') : 0.0} €
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {item?.isInsurance ? formatPrice((item?.price * item?.insuranceRate) / 100) : 0.0 + ' €'}
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {(item?.deliveryFee).replace('.', ',')} €
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {(item?.pickupFee).replace('.', ',')} €
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{(item?.price).replace('.', ',')} €</Text>
                              </View>
                           </View>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                              <View style={{ marginTop: 25 }}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>1</Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {moment(item?.endDate).diff(moment(item?.beginDate), 'days') + 1}
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>1</Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>1</Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{calculateWorkDays(item)}</Text>
                              </View>
                           </View>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                              <View style={{ marginTop: 25 }}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {item?.isEco ? (item?.ecoPrice).replace('.', ',') : 0.0} €
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {item?.isInsurance
                                       ? formatPrice(
                                            (item?.price * item?.insuranceRate * (moment(item?.endDate).diff(moment(item?.beginDate), 'days') + 1)) / 100,
                                         )
                                       : 0.0 + ' €'}
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {(item?.deliveryFee).replace('.', ',')} €
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {(item?.pickupFee).replace('.', ',')} €
                                 </Text>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {formatPrice(item?.price * calculateWorkDays(item))}
                                 </Text>
                              </View>
                           </View>
                        </View>
                     ))
                  ) : (
                     <View style={styles.tableRow}>
                        <View style={[styles.tableCol, { width: '14%', marginTop: 10 }]}>
                           <Text style={styles.tableCell}>{pdfData?.vehicleGroup?.name}</Text>
                        </View>
                        <View style={[styles.tableCol, { width: '40%' }]}>
                           <View>
                              <Text style={styles.tableCell}>
                                 {pdfData?.vehicle ? pdfData?.vehicle.machineFullCode.replace(/\s/g, '') + ': ' + pdfData?.vehicle.note : pdfData?.description}
                              </Text>
                              <Text style={[styles.tableCell, { fontFamily: 'Helvetica-Bold' }]}>
                                 {t('pages.pdf.start')}: {moment(pdfData?.beginDate).format('DD/MM/YYYY')} {t('pages.pdf.end')}:{' '}
                                 {moment(pdfData?.endDate).format('DD/MM/YYYY')}
                              </Text>
                           </View>
                           <View style={{ marginTop: 10 }}>
                              <Text style={styles.tableCell}>{t('pages.offers.isEco')}</Text>
                              <Text style={styles.tableCell}>{t('pages.offers.isInsurance') + ' 10%'}</Text>
                              <Text style={styles.tableCell}>{t('pages.offers.deliveryFee')}</Text>
                              <Text style={styles.tableCell}>{t('pages.offers.pickupFee')}</Text>
                              {pdfData.fuel !== '0.00' && <Text style={styles.tableCell}>{t('pages.invoices.fuel')}</Text>}
                              <Text style={styles.tableCell}>{t('pages.planning.rent')}</Text>
                              {pdfData.discount !== 0 && <Text style={styles.tableCell}>{t('pages.invoices.discount') + ' ' + pdfData?.discount + '%'}</Text>}
                           </View>
                        </View>
                        <View style={[styles.tableCol, { width: '14%' }]}>
                           <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                           <View style={{ marginTop: 25 }}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {pdfData?.isEco ? (pdfData?.ecoPrice).replace('.', ',') : 0.0} €
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {pdfData?.isInsurance ? formatPrice((pdfData?.price * pdfData?.insuranceRate) / 100) : 0.0 + ' €'}
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {(pdfData?.deliveryFee).replace('.', ',')} €
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {(pdfData?.pickupFee).replace('.', ',')} €
                              </Text>
                              {pdfData.fuel !== '0.00' && (
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {(pdfData?.dieselPrice).replace('.', ',')} €
                                 </Text>
                              )}
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{(pdfData?.price).replace('.', ',')} €</Text>
                              {pdfData.discount !== 0 && <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>}
                           </View>
                        </View>
                        <View style={[styles.tableCol, { width: '14%' }]}>
                           <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                           <View style={{ marginTop: 25 }}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>1</Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {moment(pdfData?.endDate).diff(moment(pdfData?.beginDate), 'days') + 1}
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>1</Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>1</Text>
                              {pdfData.fuel !== '0.00' && (
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{parseInt(pdfData?.fuel)}</Text>
                              )}
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{pdfData?.quantity}</Text>
                              {pdfData.discount !== 0 && <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>}
                           </View>
                        </View>
                        <View style={[styles.tableCol, { width: '14%' }]}>
                           <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                           <View style={{ marginTop: 25 }}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {pdfData?.isEco ? (pdfData?.ecoPrice).replace('.', ',') : 0.0} €
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {pdfData?.isInsurance
                                    ? formatPrice(
                                         (pdfData?.price * pdfData?.insuranceRate * (moment(pdfData?.endDate).diff(moment(pdfData?.beginDate), 'days') + 1)) /
                                            100,
                                      )
                                    : 0.0 + ' €'}
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {(pdfData?.deliveryFee).replace('.', ',')} €
                              </Text>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {(pdfData?.pickupFee).replace('.', ',')} €
                              </Text>
                              {pdfData.fuel !== '0.00' && (
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {parseFloat(pdfData?.dieselPrice * pdfData?.fuel)
                                       .toFixed(2)
                                       .replace('.', ',')}{' '}
                                    €
                                 </Text>
                              )}
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {formatPrice(pdfData?.price * pdfData?.quantity)}
                              </Text>
                              {pdfData.discount !== 0 && (
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                    {'-' + formatPrice((pdfData?.price * pdfData?.quantity * pdfData?.discount) / 100)}
                                 </Text>
                              )}
                           </View>
                        </View>
                     </View>
                  )}

                  {pdfData?.invoiceItems &&
                     pdfData?.invoiceItems.length > 0 &&
                     pdfData?.invoiceItems.map((item, index) => (
                        <View style={styles.tableRow} key={index}>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={styles.tableCell}>{item?.category}</Text>
                           </View>
                           <View style={[styles.tableCol, { width: '40%' }]}>
                              <Text style={styles.tableCell}>{item?.description}</Text>
                           </View>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {parseFloat(item?.price).toFixed(2).replace('.', ',')} €
                              </Text>
                           </View>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{item?.quantity}</Text>
                           </View>
                           <View style={[styles.tableCol, { width: '14%' }]}>
                              <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                 {parseFloat(item?.price * item.quantity)
                                    .toFixed(2)
                                    .replace('.', ',')}{' '}
                                 €
                              </Text>
                           </View>
                        </View>
                     ))}
               </View>
               {/* Total Box */}
               <View style={{ marginTop: '20px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                     <View>
                        <Text style={{ fontSize: 10, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', maxWidth: '49%' }}>{pdfData?.description}</Text>
                     </View>
                     <View style={{ border: 1, alignSelf: 'flex-end', flexDirection: 'column', minWidth: '49%', borderColor: '#6494ed' }}>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.totalBeforeTax').toUpperCase()}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {parseFloat(pdfData?.totalBeforeTax).toFixed(2).replace('.', ',')} €
                           </Text>
                        </View>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.tax').toUpperCase() + ' 20%'}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {((pdfData?.totalBeforeTax * pdfData?.tax) / 100).toFixed(2).replace('.', ',')} €
                           </Text>
                        </View>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.totalAfterTax').toUpperCase()}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {parseFloat(pdfData?.totalAfterTax).toFixed(2).replace('.', ',')} €
                           </Text>
                        </View>
                     </View>
                  </View>
               </View>
               {/* Details */}
               <View style={{ flexGrow: 1, marginBottom: 50 }}>
                  <View style={{ marginTop: '20px' }}>
                     <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View>
                           {pdfData?.isFactor ? (
                              <Text style={{ fontSize: 10, minWidth: '50%', maxWidth: '50%', marginTop: '10px', color: 'red' }}>
                                 {t('pages.pdf.invoicePdf1')}
                              </Text>
                           ) : (
                              <>
                                 <Text style={{ minWidth: '50%', maxWidth: '50%', fontSize: 10, marginTop: '10px', fontFamily: 'Helvetica-Bold' }}>
                                    {t('pages.pdf.ourBankDetails') + ':'}
                                 </Text>
                                 <Text style={{ minWidth: '50%', maxWidth: '50%', fontSize: 10, marginTop: '10px' }}>IBAN: {pdfData?.customer?.buyersBan}</Text>
                                 <Text style={{ minWidth: '50%', maxWidth: '50%', fontSize: 10, marginTop: '10px' }}>BIC: {pdfData?.customer?.buyersBic}</Text>
                              </>
                           )}
                        </View>
                        <View>
                           <View style={{ border: 1, padding: 5, minWidth: '50%', minHeight: '100px', marginLeft: '10px', borderColor: '#6494ed' }}>
                              <Text style={{ maxWidth: '45%', fontSize: 10 }}></Text>
                           </View>
                        </View>
                     </View>
                     <Text style={{ fontSize: 10, marginTop: '10px', fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{t('pages.pdf.invoicePdf2')}</Text>
                  </View>
               </View>
               {/* Footer */}
               <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                  <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  <View style={{ textAlign: 'right' }} fixed>
                     <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                  </View>
               </View>
            </Page>
            {pdfData?.isFactor && (
               <Page size="A4" style={{ padding: 10 }}>
                  <Image style={{}} src={require('../../../assets/invoicePdf.png')} />
               </Page>
            )}
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(
         blob,
         header === 'contract'
            ? 'contract'
            : header === 'seeProforma'
            ? 'see_proforma'
            : header === 'deliveryNote'
            ? 'delivery_note'
            : header === 'pickuppNote'
            ? 'pickup_note'
            : 'invoice',
      ); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   const getPdfData = () => {
      dispatch(getInvoicePDF(data.id)).then((res) => {
         downloadPDF(res);
      });
   };

   return (
      <>
         <Button
            icon="pi pi-file-pdf"
            severity="info"
            className="text-[14px] !py-[3px]"
            onClick={() => {
               getPdfData(data);
            }}
         />
      </>
   );
};

export default InvoiceOffer1;
