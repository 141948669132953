import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Outlet, useNavigate } from 'react-router-dom';

import '../../components/components.scss';

import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Loading from '../../components/Loading';

import { changeSidebarStatus, setGenders, setRoles } from '../../store/slices/sharedSlice';
import { changeLoadingStatus } from '../../store/slices/sharedSlice';

import client from '../../graphql/apollo.config';

import { GET_GENDERS, GET_ROLES } from '../../graphql/authorization';
import { t } from 'i18next';
import { getGlobalSettings } from '../../store/apis/SharedApi';
import Reminders from '../../components/Reminders';

const AppLayout = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { showSidebar, loading, globalLoading } = useSelector((state) => state.shared);
   const { token } = useSelector((state) => state.user);
   const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
   useEffect(() => {
      if (!token) return;
      dispatch(changeLoadingStatus(true));
      let tempGenders = [];
      let tempRoles = [];

      client
         .query({
            query: GET_GENDERS,
         })
         .then((res) => {
            res.data.genders.forEach((gender) => {
               tempGenders.push({
                  id: gender.id,
                  name: t(`global.genders.${gender.id}`),
               });
            });
            dispatch(setGenders(tempGenders));
            client
               .query({
                  query: GET_ROLES,
               })
               .then((res) => {
                  res.data.roles.forEach((role) => {
                     tempRoles.push({
                        id: role.id,
                        name: t(`global.roles.${role.id}`),
                     });
                  });
                  dispatch(setRoles(tempRoles));
               })
               .catch((err) => {
                  console.log(err);
               })
               .finally(() => {
                  dispatch(changeLoadingStatus(false));
               });
         })
         .catch((err) => {
            console.log(err);
            dispatch(changeLoadingStatus(false));
         });

      dispatch(getGlobalSettings());
   }, [token]);

   useEffect(() => {
      if (!token) {
         navigate('/authorization/login');
      }
   }, [navigate, token]);

   useEffect(() => {
      const handleWindowResize = () => {
         setWindowSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
         window.removeEventListener('resize', handleWindowResize);
      };
   }, []);
   useEffect(() => {
      if (windowSize[0] < 1200) {
         dispatch(changeSidebarStatus(false));
      } else {
         dispatch(changeSidebarStatus(true));
      }
   }, [windowSize[0]]);

   return (
      <div className="flex flex-1 h-full">
         <div className={showSidebar ? 'sidebar-container border-r' : 'hidden'}>
            <Sidebar />
         </div>
         <div>
            <Reminders />
         </div>
         <div className="flex flex-col flex-1">
            <div className="w-full p-3 bg-white border-b">
               <Header />
            </div>

            <div className="flex w-full p-3">
               <Outlet />
            </div>
         </div>

         {globalLoading && <Loading />}
      </div>
   );
};

export default AppLayout;
