import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { t } from "i18next";
import { getItemCategories } from "../../../../store/apis/OffersApi";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import PurchaseOrderPDF from "../../../../components/PDFS/PurchaseOrderPDF";
const TransportOrder = (props) => {
  const {
    customers,
    addresses,
    transporterInputs,
    transporterErrors,
    setCreateAddressDialog,
    updateAddress,
    handleSaveTransporter,
    handleTransporterInputChange,
    handleItemsChange,
    handleFocus,
    items,
    setItems,
    itemsError,
    setItemsError,
  } = props;

  const dispatch = useDispatch();
  const tax = useSelector((state) => state.shared.globalSettings.tax);
  const { contract } = useSelector((state) => state.contracts);
  const [itemCategories, setItemCategories] = useState([]);
  useEffect(() => {
    dispatch(getItemCategories()).then((response) =>
      setItemCategories(response)
    );
  }, []);
  const price = items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const taxfee = (price * (tax || 0.18)) / 100;
  const totalPrice = price + taxfee;
  return (
    <>
      <div className="grid">
        <Divider align="start">
          <b>{t("pages.contracts.information")}</b>
        </Divider>
        <div className="col-12 md:col-6 lg:col-4">
          <label>
            {transporterInputs.supplierId
              ? t("pages.contracts.supplier")
              : t("pages.contracts.transporter")}
            *
          </label>
          <Dropdown
            filter
            options={customers}
            value={
              transporterInputs?.supplierId || transporterInputs?.transporterId
            }
            readOnly
            optionLabel="companyName"
            optionValue="id"
            className={`w-full input-detail`}
            disabled
          />
        </div>
        <div
          className="col-12 md:col-6 lg:col-4"
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <div style={{ marginRight: "10px", flex: "1" }}>
            <label>{t("global.table.placeName")}*</label>
            <Dropdown
              filter
              options={addresses}
              value={transporterInputs?.addressId}
              onChange={(e) =>
                handleTransporterInputChange(e.target.value, "addressId")
              }
              optionLabel="placeName"
              optionValue="id"
              className={`w-full input-detail ${
                transporterErrors?.addressId && "input-error"
              }`}
              showClear
            />
            {transporterErrors?.addressId && (
              <small className="p-error">{transporterErrors?.addressId}</small>
            )}
          </div>
          <Button
            icon="pi pi-pencil"
            severity="warning"
            className="mt-4 me-2 h-[35px] w-[35px]"
            onClick={updateAddress}
          />
          <Button
            icon="pi pi-plus"
            className="mt-4 h-[35px] w-[35px]"
            onClick={() => setCreateAddressDialog(true)}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.contactPerson")}</label>
          <InputText
            value={transporterInputs?.contractPerson}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "contractPerson")
            }
            className={`w-full input-detail ${
              transporterErrors?.contractPerson && "input-error"
            }`}
          />

          {transporterErrors?.contractPerson && (
            <small className="p-error">
              {transporterErrors?.contractPerson}
            </small>
          )}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.referenceOffer")}</label>
          <InputText
            value={transporterInputs?.referenceNumber}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "referenceNumber")
            }
            className={`w-full input-detail`}
            disabled
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.explanation")}</label>
          <InputText
            value={transporterInputs?.description}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "description")
            }
            className={`w-full input-detail`}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.privacyExplanation")}</label>
          <InputText
            value={transporterInputs?.privacyDescription}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "privacyDescription")
            }
            className={`w-full input-detail`}
          />
        </div>
      </div>
      <div className="grid">
        <Divider align="start">
          <b>{t("pages.contracts.items")}</b>
        </Divider>
        <div className="flex justify-end mb-5 col-12">
          <Button
            label={t("global.add")}
            icon="pi pi-plus"
            onClick={() => {
              setItems([
                ...items,
                {
                  category: "TRANSPORT",
                  description: "",
                  quantity: 1,
                  price: 0,
                },
              ]);
            }}
          />
        </div>
        <div className="grid items-center justify-center">
          {items?.map((item, index) => {
            return (
              <div
                key={index}
                className="relative grid items-start mb-3 border rounded-md col-12 "
              >
                {!item.id && (
                  <span
                    className="cursor-pointer text-white bg-red-500 rounded-full w-[30px] h-[30px] flex items-center justify-center absolute top-0 right-0 -mt-2 -mr-2 z-10"
                    onClick={() => {
                      let newItems = [...items];
                      newItems.splice(index, 1);
                      setItems(newItems);
                      let newItemsError = [...itemsError];
                      newItemsError.splice(index, 1);
                      setItemsError(newItemsError);
                    }}
                  >
                    &#10005;
                  </span>
                )}
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.category")}*</label>
                  <Dropdown
                    options={itemCategories}
                    value={item?.category}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "category", index)
                    }
                    className={`w-full input-detail`}
                    optionLabel="name"
                    optionValue="name"
                    disabled
                  />
                  {itemsError[index]?.category && (
                    <small className="p-error">
                      {itemsError[index]?.category}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.description")}*</label>
                  <InputText
                    value={item?.description}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "description", index)
                    }
                    className={`w-full input-detail`}
                  />
                  {itemsError[index]?.description && (
                    <small className="p-error">
                      {itemsError[index]?.description}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.beginDate")}*</label>

                  <Calendar
                    value={new Date(item?.beginDate)}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "beginDate", index)
                    }
                    dateFormat="dd/mm/yy"
                    minDate={new Date()}
                    className={`w-full input-detail`}
                  />
                  {itemsError[index]?.beginDate && (
                    <small className="p-error">
                      {itemsError[index]?.beginDate}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.endDate")}*</label>
                  <Calendar
                    value={new Date(item?.endDate)}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "endDate", index)
                    }
                    dateFormat="dd/mm/yy"
                    minDate={new Date(item?.beginDate)}
                    className={`w-full input-detail`}
                  />
                  {itemsError[index]?.endDate && (
                    <small className="p-error">
                      {itemsError[index]?.endDate}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.quantity")}*</label>
                  <InputNumber
                    value={item?.quantity}
                    min={1}
                    onValueChange={(e) =>
                      handleItemsChange(e.value, "quantity", index)
                    }
                    showButtons
                    buttonLayout="horizontal"
                    className="w-full input-detail"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                  />
                  {itemsError[index]?.quantity && (
                    <small className="p-error">
                      {itemsError[index]?.quantity}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.price")}*</label>

                  <InputText
                    value={item.price}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "price", index)
                    }
                    onFocus={() => handleFocus("price", index)}
                    className="w-full input-detail"
                  />
                  {itemsError[index]?.price && (
                    <small className="p-error">
                      {itemsError[index]?.price}
                    </small>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid p-0 m-0 border border-black col-12">
          <div className="flex items-center text-3xl col-6">
            {t("pages.contracts.total")}
          </div>
          <div className="grid p-0 m-0 col-6">
            <div className="flex flex-col items-center p-0 m-0 border-l border-black col-4 justify-evenly ">
              <span>{t("pages.contracts.price")}</span>
              <hr className="w-full border-black" />
              <span>€ {price}</span>
            </div>
            <div className="flex flex-col items-center p-0 m-0 border-l border-black col-4 justify-evenly ">
              <span>{t("pages.contracts.taxFee")}</span>
              <hr className="w-full border-black" />
              <span>€ {taxfee}</span>
            </div>
            <div className="flex flex-col items-center p-0 m-0 border-l border-black col-4 justify-evenly ">
              <span>{t("pages.contracts.totalPrice")}</span>
              <hr className="w-full border-black" />
              <span>€ {totalPrice}</span>
            </div>
          </div>
        </div>

        {/* <div className="flex col-12 md:justify-end">

            </div> */}
        <div className="flex items-end justify-between mt-5 col-12">
          <div className="flex items-end ">
            {transporterInputs?.status && (
              <div className="flex flex-col items-start mr-2">
                <label className="mb-2">{t("pages.contracts.status")}</label>
                <Dropdown
                  value={transporterInputs?.status}
                  options={[
                    { label: t("global.APPROVED"), value: "APPROVED" },
                    { label: t("global.REJECTED"), value: "REJECTED" },
                    { label: t("global.PENDING"), value: "PENDING" },
                  ]}
                  onChange={(e) =>
                    handleTransporterInputChange(e.target.value, "status")
                  }
                  optionLabel="label"
                  optionValue="value"
                />
              </div>
            )}
            <PurchaseOrderPDF
              data={contract}
              items={items}
              transporterInputs={transporterInputs}
              customers={customers}
            />
          </div>

          <Button
            label={t("global.saveOrFinish")}
            className="p-button-success"
            onClick={(e) => handleSaveTransporter(e)}
          />
        </div>
      </div>
    </>
  );
};

export default TransportOrder;
