import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useState, useEffect } from "react";
import {
  getCustomers,
  getAddresses,
  updateContract,
  updateTransporterOrder,
  updateTransporterOrderItem,
  createNewTransporterOrder,
  createTransporterOrderItem,
  createSupplierTransporterOrder,
} from "../../../../store/apis/ContractsApi";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { getItemCategories } from "../../../../store/apis/OffersApi";
import moment from "moment";
import { setContract } from "../../../../../src/store/slices/contractsSlice";
import CreateInformationAddress from "../../../Workshop/CreateVehicleMalfunction/CreateInformationAddress";
import { Dialog } from "primereact/dialog";
import { getReferenceNumbers } from "../../../../store/apis/Customers";
import toast from "react-hot-toast";

const PurchaseOrder = ({
  customerKindId,
  customerId,
  setPurchaseOrderDialog,
  purchaseOrderDialog,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { contract } = useSelector((state) => state.contracts);
  const [customers, setCustomers] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [createAddressDialog, setCreateAddressDialog] = useState(false);
  const [createdAddress, setCreatedAddress] = useState(null);
  const [transporterDialog, setTransporterDialog] = useState(false);
  const [purchaseOrderInputs, setPurchaseOrderInputs] = useState();
  const [purchaseOrderErrors, setPurchaseOrderErrors] = useState();
  const [items, setItems] = useState([]);
  const [itemsError, setItemsError] = useState([]);
  const [transportKind, setTransportKind] = useState("");
  const [navigateCondition, setNavigateCondition] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [referenceNumbers, setReferenceNumbers] = useState();

  const tax = useSelector((state) => state.shared.globalSettings.tax);
  const [itemCategories, setItemCategories] = useState([]);

  useEffect(() => {
    dispatch(getItemCategories()).then((response) =>
      setItemCategories(response)
    );
  }, []);
  const price = items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const taxfee = (price * (tax || 0.18)) / 100;
  const totalPrice = price + taxfee;

  useEffect(() => {
    dispatch(getCustomers()).then((res) => setCustomers(res));
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(getAddresses({ customerId })).then((res) => {
        console.log(customerId);
        setAddresses(res);
      });
    }
  }, [customerId]);

  useEffect(() => {
    if (!createdAddress) return;
    if (selectedAddressId) {
      setAddresses(
        addresses.map((address) =>
          address.id === createdAddress.id ? createdAddress : address
        )
      );
    } else {
      setAddresses([...addresses, createdAddress]);
    }
    setSelectedAddressId(null);
    setPurchaseOrderInputs({
      ...purchaseOrderInputs,
      addressId: createdAddress.id,
    });
  }, [createdAddress]);

  useEffect(() => {
    dispatch(getReferenceNumbers()).then((res) => {
      setReferenceNumbers(res.data.orders);
    });
  }, []);

  const updatePurchaseOrderErrors = (name, value) => {
    setPurchaseOrderErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value ? t("global.requiredField") : "",
    }));
  };

  const handleTransporterInputChange = (value, name) => {
    setPurchaseOrderInputs((prev) => ({ ...prev, [name]: value }));
    updatePurchaseOrderErrors(name, value);
  };

  const handleSaveTransporter = async (e) => {
    e.preventDefault();
    if (!validateTransporter() || !validateItems()) return;
    let data =
      customerKindId === "4"
        ? {
            ...purchaseOrderInputs,
            transporterId: +customerId,
          }
        : { ...purchaseOrderInputs, supplierId: +customerId };
    console.log(data);
    (await customerKindId) === "4"
      ? dispatch(createNewTransporterOrder(data)).then(async (res) => {
          if (res) {
            console.log(res);
            toast.success(t("pages.customers.customerCreatedSuccessfully"));
          }
        })
      : dispatch(createSupplierTransporterOrder(data)).then(async (res) => {
          if (res) {
            console.log(res);
            toast.success(t("pages.customers.customerCreatedSuccessfully"));
          }
        });
    setPurchaseOrderDialog(false);
  };

  const updateItemsErrors = (name, value, index) => {
    setItemsError((prevErrors) => {
      let newErrors = [...prevErrors];
      newErrors[index] = {
        ...newErrors[index],
        [name]: !value ? t("global.requiredField") : "",
      };
      return newErrors;
    });
  };

  const handleItemsChange = (value, name, index) => {
    value = name === "price" ? value.replace(/[^0-9]/g, "") : value;
    let newItems = [...items];
    newItems[index] = { ...newItems[index], [name]: value };
    setItems(newItems);
    updateItemsErrors(name, value, index);
  };
  const validateTransporter = () => {
    const inputs = ["addressId", "referenceNumber"];
    let valid = true;
    inputs.forEach((input) => {
      if (!purchaseOrderInputs[input]) {
        updatePurchaseOrderErrors(input, false);
        valid = false;
      }
    });
    return valid;
  };
  const validateItems = () => {
    let inputs = [
      "category",
      "description",
      "beginDate",
      "endDate",
      "quantity",
      "price",
    ];
    let valid = true;
    items.forEach((item, index) => {
      inputs.forEach((input) => {
        if (!item[input]) {
          updateItemsErrors(input, false, index);
          valid = false;
        }
      });
    });
    return valid;
  };

  const handleFocus = (name, index) => {
    if (items[index][name] === 0) {
      let newItems = [...items];
      newItems[index] = { ...newItems[index], [name]: "" };
      setItems(newItems);
    }
  };
  const updateAddress = () => {
    if (transporterDialog) {
      setSelectedAddressId(purchaseOrderInputs.addressId);
    }
    setCreateAddressDialog(true);
  };

  const optionTemplate = (option) => {
    return `${option.orderNumber}(${option.customer.companyName ?? ""})`;
  };

  return (
    <>
      <div className="grid">
        <Divider align="start">
          <b>{t("pages.contracts.information")}</b>
        </Divider>
        <div className="col-12 md:col-6 lg:col-4">
          <label>
            {customerKindId === "3"
              ? t("pages.contracts.supplier")
              : t("pages.contracts.transporter")}
            *
          </label>
          <Dropdown
            filter
            options={customers}
            value={customerId}
            readOnly
            optionLabel="companyName"
            optionValue="id"
            className={`w-full input-detail`}
            disabled
          />
        </div>
        <div
          className="col-12 md:col-6 lg:col-4"
          style={{ display: "flex", alignItems: "flex-start" }}
        >
          <div style={{ marginRight: "10px", flex: "1" }}>
            <label>{t("global.table.placeName")}*</label>
            <Dropdown
              filter
              options={addresses}
              value={purchaseOrderInputs?.addressId}
              onChange={(e) =>
                handleTransporterInputChange(e.target.value, "addressId")
              }
              optionLabel="placeName"
              optionValue="id"
              className={`w-full input-detail ${
                purchaseOrderErrors?.addressId && "input-error"
              }`}
              showClear
            />
            {purchaseOrderErrors?.addressId && (
              <small className="p-error">
                {purchaseOrderErrors?.addressId}
              </small>
            )}
          </div>
          <Button
            icon="pi pi-pencil"
            severity="warning"
            className="mt-4 me-2 h-[35px] w-[35px]"
            onClick={updateAddress}
          />
          <Button
            icon="pi pi-plus"
            className="mt-4 h-[35px] w-[35px]"
            onClick={() => setCreateAddressDialog(true)}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.contactPerson")}</label>
          <InputText
            value={purchaseOrderInputs?.contractPerson}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "contractPerson")
            }
            className={`w-full input-detail ${
              purchaseOrderErrors?.contractPerson && "input-error"
            }`}
          />

          {purchaseOrderErrors?.contractPerson && (
            <small className="p-error">
              {purchaseOrderErrors?.contractPerson}
            </small>
          )}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.referenceOffer")}</label>
          <Dropdown
            value={purchaseOrderInputs?.referenceNumber}
            filter
            options={referenceNumbers}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "referenceNumber")
            }
            optionLabel={optionTemplate}
            optionValue="orderNumber"
            className={`w-full input-detail ${
              purchaseOrderErrors?.referenceNumber && "input-error"
            }`}
            showClear
          />
          {purchaseOrderErrors?.referenceNumber && (
            <small className="p-error">
              {purchaseOrderErrors?.referenceNumber}
            </small>
          )}
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.explanation")}</label>
          <InputText
            value={purchaseOrderInputs?.description}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "description")
            }
            className={`w-full input-detail`}
          />
        </div>
        <div className="col-12 md:col-6 lg:col-4">
          <label>{t("pages.contracts.privacyExplanation")}</label>
          <InputText
            value={purchaseOrderInputs?.privacyDescription}
            onChange={(e) =>
              handleTransporterInputChange(e.target.value, "privacyDescription")
            }
            className={`w-full input-detail`}
          />
        </div>
      </div>
      <div className="grid">
        <Divider align="start">
          <b>{t("pages.contracts.items")}</b>
        </Divider>
        <div className="col-12 flex justify-end mb-5">
          <Button
            label={t("global.add")}
            icon="pi pi-plus"
            onClick={() => {
              setItems([
                ...items,
                {
                  category: "TRANSPORT",
                  description: "",
                  quantity: 1,
                  price: 0,
                },
              ]);
            }}
          />
        </div>
        <div className="grid items-center justify-center">
          {items?.map((item, index) => {
            return (
              <div
                key={index}
                className="col-12 grid items-start relative border rounded-md mb-3 "
              >
                {!item.id && (
                  <span
                    className="cursor-pointer text-white bg-red-500 rounded-full w-[30px] h-[30px] flex items-center justify-center absolute top-0 right-0 -mt-2 -mr-2 z-10"
                    onClick={() => {
                      let newItems = [...items];
                      newItems.splice(index, 1);
                      setItems(newItems);
                      let newItemsError = [...itemsError];
                      newItemsError.splice(index, 1);
                      setItemsError(newItemsError);
                    }}
                  >
                    &#10005;
                  </span>
                )}
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.category")}*</label>
                  <Dropdown
                    options={itemCategories}
                    value={item?.category}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "category", index)
                    }
                    className={`w-full input-detail`}
                    optionLabel="name"
                    optionValue="name"
                  />
                  {itemsError[index]?.category && (
                    <small className="p-error">
                      {itemsError[index]?.category}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.description")}*</label>
                  <InputText
                    value={item?.description}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "description", index)
                    }
                    className={`w-full input-detail`}
                  />
                  {itemsError[index]?.description && (
                    <small className="p-error">
                      {itemsError[index]?.description}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.beginDate")}*</label>

                  <Calendar
                    value={new Date(item?.beginDate)}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "beginDate", index)
                    }
                    dateFormat="dd/mm/yy"
                    minDate={new Date()}
                    className={`w-full input-detail`}
                  />
                  {itemsError[index]?.beginDate && (
                    <small className="p-error">
                      {itemsError[index]?.beginDate}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.endDate")}*</label>
                  <Calendar
                    value={new Date(item?.endDate)}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "endDate", index)
                    }
                    dateFormat="dd/mm/yy"
                    minDate={new Date(item?.beginDate)}
                    className={`w-full input-detail`}
                  />
                  {itemsError[index]?.endDate && (
                    <small className="p-error">
                      {itemsError[index]?.endDate}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.quantity")}*</label>
                  <InputNumber
                    value={item?.quantity}
                    min={1}
                    onValueChange={(e) =>
                      handleItemsChange(e.value, "quantity", index)
                    }
                    showButtons
                    buttonLayout="horizontal"
                    className="w-full input-detail"
                    decrementButtonClassName="p-button-secondary"
                    incrementButtonClassName="p-button-secondary"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                  />
                  {itemsError[index]?.quantity && (
                    <small className="p-error">
                      {itemsError[index]?.quantity}
                    </small>
                  )}
                </div>
                <div className="col-6 md:col-4 lg:col-2 ">
                  <label>{t("pages.contracts.price")}*</label>

                  <InputText
                    value={item.price}
                    onChange={(e) =>
                      handleItemsChange(e.target.value, "price", index)
                    }
                    onFocus={() => handleFocus("price", index)}
                    className="w-full input-detail"
                  />
                  {itemsError[index]?.price && (
                    <small className="p-error">
                      {itemsError[index]?.price}
                    </small>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid col-12 border border-black m-0 p-0">
          <div className="col-6 flex items-center text-3xl">
            {t("pages.contracts.total")}
          </div>
          <div className="grid col-6 m-0 p-0">
            <div className="col-4 flex flex-col m-0 p-0 border-l border-black items-center justify-evenly ">
              <span>{t("pages.contracts.price")}</span>
              <hr className="w-full border-black" />
              <span>€ {price}</span>
            </div>
            <div className="col-4 flex flex-col  m-0 p-0 border-l border-black items-center justify-evenly ">
              <span>{t("pages.contracts.taxFee")}</span>
              <hr className="w-full border-black" />
              <span>€ {taxfee}</span>
            </div>
            <div className="col-4 flex flex-col  m-0 p-0 border-l border-black items-center justify-evenly ">
              <span>{t("pages.contracts.totalPrice")}</span>
              <hr className="w-full border-black" />
              <span>€ {totalPrice}</span>
            </div>
          </div>
        </div>

        {/* <div className="col-12 flex  md:justify-end">

            </div> */}
        <div className="col-12 flex justify-between items-end mt-5">
          <div className="flex items-end ">
            {purchaseOrderInputs?.status && (
              <div className="flex flex-col items-start mr-2">
                <label className="mb-2">{t("pages.contracts.status")}</label>
                <Dropdown
                  value={purchaseOrderInputs?.status}
                  options={[
                    { label: t("global.APPROVED"), value: "APPROVED" },
                    { label: t("global.REJECTED"), value: "REJECTED" },
                    { label: t("global.PENDING"), value: "PENDING" },
                  ]}
                  onChange={(e) =>
                    handleTransporterInputChange(e.target.value, "status")
                  }
                  optionLabel="label"
                  optionValue="value"
                />
              </div>
            )}
            <Button
              label={t("pages.contracts.po")}
              className="p-button-warning"
              onClick={() => {}}
              icon="pi pi-file-pdf"
              iconPos="right"
            />
          </div>

          <Button
            label={t("global.saveOrFinish")}
            className="p-button-success"
            onClick={(e) => handleSaveTransporter(e)}
          />
        </div>
        <Dialog
          header={
            selectedAddressId
              ? t("pages.contracts.updateAddress")
              : t("pages.contracts.createNewAddress")
          }
          className="w-[70vw]"
          visible={createAddressDialog}
          onHide={() => {
            setCreateAddressDialog(false);
            setSelectedAddressId(null);
          }}
        >
          <CreateInformationAddress
            setCreateAddressDialog={setCreateAddressDialog}
            setCreatedAddress={setCreatedAddress}
            customerId={customerId}
            selectedAddressId={purchaseOrderInputs?.addressId}
          />
        </Dialog>
      </div>
    </>
  );
};

export default PurchaseOrder;
