import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	reminders: [],
	reminderButtonClicked: false,
	unreadNotifications: 0,
	reminderDialog: false,
	showReminders: false,
	date: null,
};

export const reminderSlice = createSlice({
	name: 'reminder',
	initialState,
	reducers: {
		setReminderButtonClicked: (state, action) => {
			state.reminderButtonClicked = action.payload;
		},
		setUserReminders: (state, action) => {
			state.reminders = action.payload;
		},
		setUnreadNotifications: (state, action) => {
			state.unreadNotifications = action.payload;
		},
		setReminderDialog: (state, action) => {
			state.reminderDialog = action.payload;
		},
		setShowReminders: (state, action) => {
			state.showReminders = action.payload;
		},
		setDate: (state, action) => {
			state.date = action.payload;
		},
	},
});

export const { setReminderButtonClicked, setUserReminders, setUnreadNotifications, setReminderDialog, setShowReminders, setDate } = reminderSlice.actions;

export default reminderSlice.reducer;
