import React, { useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../components/TableComponent';
import { Dialog } from 'primereact/dialog';
import { changeLoadingStatus } from '../../../store/slices/sharedSlice';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import toast from 'react-hot-toast';
import moment from 'moment';
import { setNewCustomerStepCounter } from '../../../store/slices/customerSlice';
import { createReminder, getReminders, getUsers, updateReminder } from '../../../store/apis/Customers';

const Reminders = () => {
   const dispatch = useDispatch();

   const { user } = useSelector((state) => state.user);
   const { selectedCustomerId, reminders } = useSelector((state) => state.customer);

   const [showAddNewReminder, setShowAddNewReminder] = useState(false);

   const [users, setUsers] = useState();

   const [selectedReminder, setSelectedReminder] = useState();

   const [remindersInputs, setRemindersInputs] = useState({
      user: user.id,
      date: null,
      description: '',
   });

   const [errors, setErrors] = useState({});

   useEffect(() => {
      dispatch(getUsers()).then((res) => {
         setUsers(res);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (!selectedCustomerId) return;
      dispatch(getReminders(selectedCustomerId));
   }, []);

   const tableColumns = [
      {
         name: 'user',
         header: t('global.table.user'),
         field: 'user.name',
      },
      {
         name: 'reminderDate',
         header: t('global.table.reminderDate'),
         field: 'reminderDate',
         body: (rowData) => {
            return <span>{moment(rowData.date).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'note',
         header: t('global.table.notes'),
         field: 'description',
      },
   ];

   // FORM VALIDATION
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (name, value) => {
      if (selectedReminder) {
         setSelectedReminder((selectedReminder) => ({
            ...selectedReminder,
            [name]: value,
         }));
      }
      setRemindersInputs((prevInfos) => ({
         ...prevInfos,
         [name]: value,
      }));
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['user', 'date', 'description'];
      let isValid = true;
      if (selectedReminder) {
         inputs.forEach((input) => {
            if (!selectedReminder[input]) {
               updateErrors(input, selectedReminder[input]);
               isValid = false;
               console.log(input);
            }
         });
      } else {
         inputs.forEach((input) => {
            if (!remindersInputs[input]) {
               updateErrors(input, remindersInputs[input]);
               isValid = false;
               console.log(input);
            }
         });
      }
      return isValid;
   };

   const handleRowClick = (e) => {
      console.log(e);
      setSelectedReminder(e);

      setShowAddNewReminder(true);
   };

   const handleReminderSave = () => {
      dispatch(changeLoadingStatus(true));
      if (selectedReminder) {
         if (validateForm()) {
            dispatch(updateReminder(selectedReminder, moment(selectedReminder.date).format('YYYY-MM-DD'))).then((res) => {
               setShowAddNewReminder(false);
               setSelectedReminder(null);
               resetVariables();
               toast.success(t('global.updateSuccess'));
            });
         }
      } else {
         if (validateForm()) {
            dispatch(
               createReminder(selectedCustomerId, moment(remindersInputs.date).format('YYYY-MM-DD'), remindersInputs.description, remindersInputs.user),
            ).then((res) => {
               setShowAddNewReminder(false);
               setSelectedReminder(null);
               resetVariables();
               toast.success('Reminder created successfully!');
            });
         }
      }
   };

   const resetVariables = () => {
      setRemindersInputs({
         user: null,
         date: null,
         description: '',
      });
   };

   const handleSave = () => {
      dispatch(setNewCustomerStepCounter(3));
   };

   const getCurrentDate = () => {
      const currentDate = new Date();
      return currentDate;
   };

   return (
      <div className="w-full h-full">
         <div className="flex justify-end w-full mb-3">
            <Button icon="pi pi-plus" label={t('pages.customers.addNewReminder')} size="small" onClick={() => setShowAddNewReminder(true)} />
         </div>
         <TableComponent data={reminders} columns={tableColumns} size="medium" onRowClick={(e) => handleRowClick(e)} />
         <div className="flex justify-end mt-3">
            <Button className="mr-auto" label={t('global.previous')} onClick={() => dispatch(setNewCustomerStepCounter(1))}></Button>
            <Button icon="pi pi-check" label={t('global.saveAndContinue')} onClick={() => handleSave()} />
         </div>
         <Dialog
            visible={showAddNewReminder}
            onHide={() => {
               setShowAddNewReminder(false);
               resetVariables();
               setSelectedReminder(null);
            }}
            draggable={false}
            header={selectedReminder ? t('pages.customers.reminderDetails') : t('pages.customers.addNewReminder')}
            className="md-width-dialog"
         >
            <div className="grid">
               <div className="col-12 md:col-6 lg:col-6">
                  <label>{t('global.table.user')}*</label>
                  <Dropdown
                     options={users}
                     optionLabel="name"
                     optionValue="id"
                     onChange={(e) => {
                        if (selectedReminder) {
                           handleInputChange('user', { id: e.value });
                        } else {
                           handleInputChange('user', e.value);
                        }
                     }}
                     value={selectedReminder ? selectedReminder.user.id : remindersInputs.user}
                     className={`w-full input-detail ${errors.user && 'input-error'}`}
                     showClear={!selectedReminder}
                  />
                  {errors.user && <small className="p-error">{errors.user}</small>}
               </div>
               <div className="col-12 md:col-6 lg:col-6">
                  <label>{t('global.table.reminderDate')}*</label>
                  <Calendar
                     onChange={(e) => {
                        if (selectedReminder) {
                           handleInputChange('date', { id: e.value });
                        } else {
                           handleInputChange('date', e.value);
                        }
                     }}
                     dateFormat="dd/mm/yy"
                     value={selectedReminder ? new Date(selectedReminder.date) : remindersInputs.date}
                     minDate={getCurrentDate()}
                     className={`w-full input-detail ${errors.date && 'input-error'}`}
                  />
                  {errors.date && <small className="p-error">{errors.date}</small>}
               </div>
               <div className="col-12">
                  <label>{t('global.table.description')}*</label>
                  <InputTextarea
                     onChange={(e) => {
                        handleInputChange('description', e.target.value);
                     }}
                     value={selectedReminder ? selectedReminder.description : remindersInputs.description}
                     className={`w-full input-detail ${errors.description && 'input-error'}`}
                  />
                  {errors.description && <small className="p-error">{errors.description}</small>}
               </div>
            </div>
            <div className="grid mt-3">
               <div className="flex justify-end col-12">
                  <Button label={selectedReminder ? t('global.update') : t('global.create')} icon="pi pi-check" onClick={() => handleReminderSave()} />
               </div>
            </div>
         </Dialog>
      </div>
   );
};

export default Reminders;
