import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import TableComponent from '../../../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
   createMonthEndInvoice,
   createMonthsInvoice,
   deleteInvoice,
   getInvoiceStatistics,
   getInvoices,
   updateInvoice,
} from '../../../../store/apis/ManagementApi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import UpdateInvoice from './UpdateInvoice';
import toast from 'react-hot-toast';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import UnpaidInvoices from './UnpaidInvoices';
import PrevMonthsReports from './PrevMonthsReports';
import InvoiceOffer1 from '../../../../components/PDFS/InvoiceOffer/InvoiceOffer1';
import SaleInvoicePDF from '../../../../components/PDFS/SaleInvoicePDF';

const PreInvoices = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const invoices = useSelector((state) => state.management.invoices);
   const [showFilters, setShowFilters] = useState(false);
   const [invoiceCount, setInvoiceCount] = useState(0);
   const [rows, setRows] = useState(20);
   const [first, setFirst] = useState(0);
   const paymentMethods = [
      { id: 'BANK_WIRE', name: t('pages.invoices.bankWire') },
      { id: 'CHECK', name: t('pages.invoices.check') },
      { id: 'CREDIT_CARD', name: t('pages.invoices.creditCard') },
      { id: 'CEMECA', name: t('pages.invoices.cameca') },
      { id: 'CPT', name: t('pages.invoices.cpt') },
      { id: 'DIRECT_DEBIT', name: t('pages.invoices.directDebit') },
      { id: 'LCR', name: t('pages.invoices.lcr') },
      { id: 'JUDICIAL_LIQUIDATION', name: t('pages.invoices.judicialLiquidation') },
   ];
   const [filters, setFilters] = useState({
      searchKeyword: '',
      paymentMethod: null,
      startDate: null,
      endDate: null,
   });
   const [selectedInvoice, setSelectedInvoice] = useState({
      visible: false,
      data: null,
   });
   const [unpaidInvoicesDialog, setUnpaidInvoicesDialog] = useState(false);
   const [invoiceStatistics, setInvoiceStatistics] = useState(null);
   const [prevMonthsReportsDialog, setPrevMonthsReportsDialog] = useState(false);
   const [confirmDialog, setConfirmDialog] = useState({
      visible: false,
      data: null,
      type: null,
   });

   useEffect(() => {
      dispatch(getInvoiceStatistics()).then((res) => {
         setInvoiceStatistics(res);
      });
      dispatch(
         getInvoices(
            'PRE_INVOICE',
            rows,
            first,
            filters?.searchKeyword,
            filters?.startDate && moment(filters?.startDate).format('YYYY-MM-DD'),
            filters?.endDate && moment(filters?.endDate).format('YYYY-MM-DD'),
            filters?.paymentMethod,
         ),
      ).then((res) => {
         setInvoiceCount(res.invoiceCount);
      });
   }, [first, rows]);

   const updateInvoiceStatus = (rowData) => {
      const data = {
         id: rowData.id,
         status: 'INVOICED',
      };
      dispatch(updateInvoice(data)).then(() => {
         toast.success(t('pages.invoices.updateInvoiceStatusSuccess'));
      });
   };

   const createMonthlyInvoices = (type) => {
      if (type === 'prev') {
         dispatch(createMonthsInvoice(true)).then(() => {
            toast.success(t('pages.invoices.createPrevMonthSuccess'));
         });
      } else {
         dispatch(createMonthsInvoice(false)).then(() => {
            toast.success(t('pages.invoices.createThisMonthSuccess'));
         });
      }
   };

   const updateSupplierInvoice = (rowData) => {
      const data = {
         id: rowData.id,
         isSupplierInvoiceArrived: rowData.isSupplierInvoiceArrived ? false : true,
      };
      dispatch(updateInvoice(data)).then(() => {
         toast.success(t('pages.invoices.updateSupplierInvoiceSuccess'));
      });
   };

   const removeInvoice = (rowData) => {
      dispatch(deleteInvoice(+rowData.id)).then(() => {
         toast.success(t('pages.invoices.deleteInvoiceSuccess'));
      });
   };

   const createMonthEndPreInvoice = () => {
      dispatch(createMonthEndInvoice()).then(() => {
         toast.success(t('pages.invoices.createMonthEndPreSuccess'));
      });
   };

   const handleConfirm = () => {
      if (confirmDialog.type === 'supplierInvoice') {
         updateSupplierInvoice(confirmDialog.data);
      } else if (confirmDialog.type === 'invoiceLock') {
         updateInvoiceStatus(confirmDialog.data);
      } else if (confirmDialog.type === 'delete') {
         removeInvoice(confirmDialog.data);
      } else if (confirmDialog.type === 'createPrevMonth') {
         createMonthlyInvoices('prev');
      } else if (confirmDialog.type === 'createThisMonth') {
         createMonthlyInvoices('current');
      } else if (confirmDialog.type === 'createMonthEnd') {
         createMonthEndPreInvoice();
      }
      setConfirmDialog({
         visible: false,
         data: null,
         type: null,
      });
   };

   const tableColumns = [
      {
         field: 'supplierInvoice',
         name: 'supplierInvoice',
         header: t('pages.invoices.supplierInvoice'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  disabled={!rowData?.isSupplierInvoice}
                  icon={!rowData?.isSupplierInvoiceArrived ? 'pi pi-lock-open' : 'pi pi-lock'}
                  severity={!rowData?.isSupplierInvoiceArrived ? 'warning' : 'success'}
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setConfirmDialog({
                        visible: true,
                        data: rowData,
                        type: 'supplierInvoice',
                     })
                  }
               />
            );
         },
         headerClassName: 'bg-green-100',
      },
      {
         field: 'invoiceLock',
         name: 'invoiceLock',
         header: t('pages.invoices.invoiceLock'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  icon="pi pi-lock-open"
                  severity="warning"
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setConfirmDialog({
                        visible: true,
                        data: rowData,
                        type: 'invoiceLock',
                     })
                  }
               />
            );
         },
      },
      {
         field: 'edit',
         name: 'edit',
         header: t('pages.invoices.edit'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  icon="pi pi-pencil"
                  severity="warning"
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setSelectedInvoice({
                        visible: true,
                        data: rowData,
                        type: rowData.vehicle !== null ? 'machine' : 'item',
                     })
                  }
               />
            );
         },
      },
      {
         field: 'pdf',
         name: 'pdf',
         header: t('pages.invoices.pdf'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return rowData.vehicle ? <InvoiceOffer1 data={rowData} /> : <SaleInvoicePDF data={rowData} />;
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            return <span>{rowData?.orderNumber}</span>;
         },
      },
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            return <span>{rowData?.customer.companyName}</span>;
         },
      },
      {
         field: 'placeName',
         name: 'placeName',
         header: t('global.table.placeName'),
         body: (rowData) => {
            return <span>{rowData?.address.placeName}</span>;
         },
      },
      {
         field: 'totalBeforeTax',
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.totalBeforeTax).toFixed(2)}</span>;
         },
      },
      {
         field: 'totalAfterTax',
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.totalAfterTax).toFixed(2)}</span>;
         },
      },
      {
         field: 'creationDate',
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'dueDate',
         name: 'dueDate',
         header: t('pages.invoices.dueDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.dueDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'isFactor',
         name: 'isFactor',
         header: t('global.table.factor'),
         body: (rowData) => {
            return <span>{rowData?.isFactor ? t('global.yes') : t('global.no')}</span>;
         },
      },
      {
         field: 'delete',
         name: 'delete',
         header: t('global.delete'),
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  icon="pi pi-trash"
                  severity="danger"
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setConfirmDialog({
                        visible: true,
                        data: rowData,
                        type: 'delete',
                     })
                  }
               />
            );
         },
      },
   ];

   return (
      <>
         <Card>
            {/* change invoice status buttons */}
            <div className="flex justify-between">
               <div>
                  <Button
                     label={t('pages.invoices.createPrevMonth')}
                     severity="warning"
                     className="mr-3"
                     onClick={() =>
                        setConfirmDialog({
                           visible: true,
                           data: null,
                           type: 'createPrevMonth',
                        })
                     }
                  />
                  <Button
                     label={t('pages.invoices.createThisMonth')}
                     severity="info"
                     onClick={() =>
                        setConfirmDialog({
                           visible: true,
                           data: null,
                           type: 'createThisMonth',
                        })
                     }
                  />
               </div>
               <div>
                  <Button
                     label={t('pages.invoices.createMonthEnd')}
                     severity="danger"
                     className="mr-3"
                     onClick={() =>
                        setConfirmDialog({
                           visible: true,
                           data: null,
                           type: 'createMonthEnd',
                        })
                     }
                     /* disabled={moment().date() < 25} */
                  />
                  <Button label={t('pages.invoices.downloadPrevMonth')} severity="success" onClick={() => setPrevMonthsReportsDialog(true)} />
               </div>
            </div>

            {/* invoices statistics */}
            <div className="flex justify-between mt-10">
               <div style={{ width: '17%' }} className="p-3 mr-3 bg-white rounded-md shadow-md border-1">
                  <div className="mb-1 !text-[15px] font-medium min-h-[40px] text-center">{t('pages.invoices.preInvoiceCard1')}</div>
                  <hr className="mb-1" />
                  <div className="text-center">€{JSON.parse(invoiceStatistics)?.WAITING_PAYMENT}</div>
               </div>
               <div style={{ width: '17%' }} className="p-3 mr-3 bg-white rounded-md shadow-md border-1">
                  <div className="mb-1 !text-[15px] font-medium min-h-[40px] text-center">{t('pages.invoices.preInvoiceCard2')}</div>
                  <hr className="mb-1" />
                  <div className="text-center">€{JSON.parse(invoiceStatistics)?.UNPAID_PAYMENT_OVER_DUE}</div>
               </div>
               <div style={{ width: '17%' }} className="p-3 mr-3 bg-white rounded-md shadow-md border-1">
                  <div className="mb-1 !text-[15px] font-medium min-h-[40px] text-center">{t('pages.invoices.preInvoiceCard3')}</div>
                  <hr className="mb-1" />
                  <div className="text-center">€{JSON.parse(invoiceStatistics)?.WAITING_PAYMENT_NOT_DUE}</div>
               </div>
            </div>

            {/* invoice table & filters button */}
            <div className="flex justify-between mt-10">
               <Button
                  icon={filters?.searchKeyword || filters?.paymentMethod || filters?.startDate || filters?.endDate ? 'pi pi-filter-slash' : 'pi pi-filter'}
                  className="ml-2"
                  onClick={() => {
                     if (filters?.searchKeyword || filters?.paymentMethod || filters?.startDate || filters?.endDate) {
                        setFilters({ searchKeyword: '', paymentMethod: null, startDate: null, endDate: null });
                        dispatch(getInvoices('PRE_INVOICE', rows, first));
                     } else {
                        setShowFilters(true);
                     }
                  }}
                  size="small"
                  severity={(filters?.searchKeyword || filters?.paymentMethod || filters?.startDate || filters?.endDate) && 'danger'}
               />
               <Button label={t('pages.invoices.unpaidListButton')} severity="info" onClick={() => setUnpaidInvoicesDialog(true)} />
            </div>

            <div className="mt-3">
               <TableComponent data={invoices && invoices.filter((i) => i.status === 'PRE_INVOICE')} columns={tableColumns} scrollable={true} />
            </div>
            <div className="flex items-center justify-center">
               <Paginator first={first} rows={rows} totalRecords={invoiceCount} onPageChange={(e) => setFirst(e.first)} />
            </div>
         </Card>
         {/* FILTERS DIALOG */}
         <Dialog header={t('global.filters')} visible={showFilters} onHide={() => setShowFilters(false)} className="w-[40vw]" draggable={false}>
            <div className="grid">
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.offers.quantity')}</label>
                  <InputNumber
                     value={rows}
                     min={1}
                     onValueChange={(e) => setRows(e.value)}
                     showButtons
                     buttonLayout="horizontal"
                     className="w-full input-detail"
                     decrementButtonClassName="p-button-secondary"
                     incrementButtonClassName="p-button-secondary"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('global.search')}</label>
                  <InputText
                     className="w-full p-inputtext-sm input-detail"
                     name="search"
                     value={filters.searchKeyword}
                     onChange={(e) => setFilters({ ...filters, searchKeyword: e.target.value })}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.offers.startDate')}</label>
                  <Calendar value={filters.startDate} onChange={(e) => setFilters({ ...filters, startDate: e.value })} className="w-full input-detail" />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.offers.endDate')}</label>
                  <Calendar value={filters.endDate} onChange={(e) => setFilters({ ...filters, endDate: e.value })} className="w-full input-detail" />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.invoices.paymentMethod')}</label>
                  <Dropdown
                     options={paymentMethods}
                     value={filters.paymentMethod}
                     onChange={(e) => setFilters({ ...filters, paymentMethod: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
            </div>
            <div className="flex justify-center col-12 md:justify-end">
               <Button
                  icon="pi pi-check"
                  label={t('global.apply')}
                  onClick={() => {
                     dispatch(
                        getInvoices(
                           'PRE_INVOICE',
                           rows,
                           first,
                           filters?.searchKeyword,
                           filters?.startDate && moment(filters?.startDate).format('YYYY-MM-DD'),
                           filters?.endDate && moment(filters?.endDate).format('YYYY-MM-DD'),
                           filters?.paymentMethod,
                        ),
                     ).then((res) => {
                        setInvoiceCount(res.invoiceCount);
                        setShowFilters(false);
                     });
                  }}
                  size="small"
               />
            </div>
         </Dialog>
         {/* update invoice dialog */}
         {selectedInvoice.visible && <UpdateInvoice selectedInvoice={selectedInvoice} setSelectedInvoice={setSelectedInvoice} />}
         {/* unpaid invoices dialog */}
         {unpaidInvoicesDialog && <UnpaidInvoices unpaidInvoicesDialog={unpaidInvoicesDialog} setUnpaidInvoicesDialog={setUnpaidInvoicesDialog} />}
         {/* previous months reports dialog */}
         {prevMonthsReportsDialog && (
            <PrevMonthsReports prevMonthsReportsDialog={prevMonthsReportsDialog} setPrevMonthsReportsDialog={setPrevMonthsReportsDialog} />
         )}
         {/* confirm dialogs */}
         <Dialog
            header={
               confirmDialog?.type === 'supplierInvoice'
                  ? t('pages.invoices.supplierInvoice')
                  : confirmDialog?.type === 'invoiceLock'
                  ? t('pages.invoices.invoiceLock')
                  : confirmDialog?.type === 'delete'
                  ? t('pages.invoices.deleteInvoice')
                  : confirmDialog?.type === 'createPrevMonth'
                  ? t('pages.invoices.createPrevMonth')
                  : confirmDialog?.type === 'createMonthEnd'
                  ? t('pages.invoices.createMonthEnd')
                  : confirmDialog?.type === 'createThisMonth'
                  ? t('pages.invoices.createThisMonth')
                  : null
            }
            visible={confirmDialog.visible}
            style={{ width: '400px' }}
            onHide={() =>
               setConfirmDialog({
                  visible: false,
                  data: null,
                  type: null,
               })
            }
         >
            <div className="confirmation-content">
               {
                  <span>
                     {confirmDialog?.type === 'supplierInvoice' && !confirmDialog?.data?.isSupplierInvoiceArrived
                        ? t('pages.invoices.updateSupplierInvoiceMessage')
                        : confirmDialog?.type === 'supplierInvoice' && confirmDialog?.data?.isSupplierInvoiceArrived
                        ? t('pages.invoices.updateSupplierInvoiceMessage2')
                        : confirmDialog?.type === 'invoiceLock'
                        ? t('pages.invoices.updateInvoiceLockMessage')
                        : confirmDialog?.type === 'delete'
                        ? t('pages.invoices.deleteInvoiceMessage')
                        : confirmDialog?.type === 'createPrevMonth'
                        ? t('pages.invoices.createPrevMonthEndPreMessage')
                        : confirmDialog?.type === 'createMonthEnd'
                        ? t('pages.invoices.createMonthEndPreMessage')
                        : confirmDialog?.type === 'createThisMonth'
                        ? t('pages.invoices.createThisMonthPreMessage')
                        : null}
                  </span>
               }
            </div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setConfirmDialog({
                        visible: false,
                        data: null,
                        type: null,
                     })
                  }
               />
               <Button label={t('global.apply')} severity="success" onClick={handleConfirm} />
            </div>
         </Dialog>
      </>
   );
};

export default PreInvoices;
