import { t } from 'i18next';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAddresses, getCustomers, updateOffer } from '../../../../store/apis/OffersApi';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import CreateInformationAddress from './CreateInformationAddress';

const Informations = (props) => {
   const dispatch = useDispatch();
   const [customers, setCustomers] = useState();
   const [addresses, setAddresses] = useState();
   const [errors, setErrors] = useState({});
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [customerIdName, setCustomerIdName] = useState([]);
   const [selectedAddressId, setSelectedAddressId] = useState(null);

   useEffect(() => {
      dispatch(getCustomers()).then((res) => setCustomers(res));
   }, []);

   useEffect(() => {
      if (props.informationsInputs.customerId) {
         dispatch(getAddresses({ customerId: props.informationsInputs.customerId })).then((res) => setAddresses(res));
      }
   }, [props.informationsInputs.customerId]);

   useEffect(() => {
      if (createdAddress) {
         if (selectedAddressId) {
            setAddresses(addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)));
            setCustomerIdName(
               customerIdName.map((customer) => {
                  if (customer.id === props.informationsInputs.customerId) {
                     return {
                        ...customer,
                        addresses: customer.addresses.map((address) => (address.id === createdAddress.id ? createdAddress : address)),
                     };
                  }
                  return customer;
               }),
            );
            props.setInformationsInputs({ ...props.informationsInputs, addressId: createdAddress.id });
         } else {
            setAddresses([...addresses, createdAddress]);
            props.setInformationsInputs({ ...props.informationsInputs, addressId: createdAddress.id });
         }
      }
   }, [createdAddress]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      props.setInformationsInputs({ ...props.informationsInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['customerId', 'addressId'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!props.informationsInputs[input]) {
            updateErrors(input, props.informationsInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
         // If the offer is created, update the offer
         if (props.createdOrderId !== null) {
            const data = {
               id: props.createdOrderId,
               ...props.informationsInputs,
            };
            delete data.orderKindId;
            dispatch(updateOffer(data)).then((res) => {
               if (res) {
                  props.setActiveIndex(props.activeIndex + 1);
               }
            });
         } else {
            props.setActiveIndex(props.activeIndex + 1);
         }
      }
   };

   const updateAddress = () => {
      setSelectedAddressId(props.informationsInputs.addressId);
      setCreateAddressDialog(true);
   };

   return (
      <>
         <div className="grid">
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.customer')}*</label>
               <Dropdown
                  filter
                  options={customers}
                  value={props.informationsInputs.customerId}
                  onChange={(e) => handleInputChange(e, 'customerId')}
                  optionLabel="companyName"
                  optionValue="id"
                  className={`w-full input-detail ${errors.customerId && 'input-error'}`}
                  showClear
               />
               {errors.customerId && <small className="p-error">{errors.customerId}</small>}
            </div>
            <div className="col-12 md:col-6 lg:col-4" style={{ display: 'flex', alignItems: 'flex-start' }}>
               <div style={{ marginRight: '10px', flex: '1' }}>
                  <label>{t('global.table.placeName')}*</label>
                  <Dropdown
                     filter
                     options={addresses}
                     value={props.informationsInputs.addressId}
                     onChange={(e) => handleInputChange(e, 'addressId')}
                     optionLabel="placeName"
                     optionValue="id"
                     className={`w-full input-detail ${errors.addressId && 'input-error'}`}
                     showClear
                     disabled={!props.informationsInputs.customerId}
                  />
                  {errors.addressId && <small className="p-error">{errors.addressId}</small>}
               </div>
               {props.informationsInputs.addressId && props.informationsInputs.customerId && (
                  <Button
                     icon="pi pi-pencil"
                     severity="warning"
                     className="mt-4 me-2 h-[35px] w-[35px]"
                     onClick={updateAddress}
                     disabled={!props.informationsInputs.customerId}
                  />
               )}
               <Button
                  icon="pi pi-plus"
                  className="mt-4 h-[35px] w-[35px]"
                  onClick={() => setCreateAddressDialog(true)}
                  disabled={!props.informationsInputs.customerId}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.poNumber')}</label>
               <InputText
                  value={props.informationsInputs.referenceNumber}
                  onChange={(e) => handleInputChange(e, 'referenceNumber')}
                  className="w-full input-detail"
                  maxLength={50}
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('global.table.explanation')}</label>
               <InputText value={props.informationsInputs.description} onChange={(e) => handleInputChange(e, 'description')} className="w-full input-detail" />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.privacyExplanation')}</label>
               <InputText
                  value={props.informationsInputs.privacyDescription}
                  onChange={(e) => handleInputChange(e, 'privacyDescription')}
                  className="w-full input-detail"
               />
            </div>
            <div className="col-12 md:col-6 lg:col-4">
               <label>{t('pages.offers.malfunctionCaseNumber')}</label>
               <InputText
                  value={props.informationsInputs.malfunctionCaseNumber}
                  onChange={(e) => handleInputChange(e, 'malfunctionCaseNumber')}
                  className="w-full input-detail"
                  maxLength={25}
               />
            </div>
            <div className="col-2">
               <div className="flex items-center">
                  <label className="mb-2">{t('pages.offers.factor')}</label>
                  <div>
                     <InputSwitch checked={props.informationsInputs.isFactor} onChange={(e) => handleInputChange(e, 'isFactor')} className="ml-2" />
                  </div>
               </div>
            </div>
         </div>
         <div className="flex justify-between">
            <Button label={t('global.prev')} icon="pi pi-chevron-left" disabled />
            <Button label={t('global.continue')} icon="pi pi-chevron-right" iconPos="right" onClick={handleSubmit} />
         </div>

         <Dialog
            header={selectedAddressId ? t('pages.offers.updateAddress') : t('pages.offers.createANewAddress')}
            className="w-[70vw]"
            visible={createAddressDialog}
            onHide={() => {
               setCreateAddressDialog(false);
               setSelectedAddressId(null);
            }}
         >
            <CreateInformationAddress
               setCreateAddressDialog={setCreateAddressDialog}
               setCreatedAddress={setCreatedAddress}
               customerId={props.informationsInputs.customerId}
               selectedAddressId={selectedAddressId}
            />
         </Dialog>
      </>
   );
};

export default Informations;
