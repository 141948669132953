import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import '../Customers/customer.scss';
import { setSelectedMachine, setShowViewMachineDetailsDialog } from '../../store/slices/machineSlice';
import Informations from './NewMachineSteps/Informations';
import Documents from './NewMachineSteps/Documents';
import { TabMenu } from 'primereact/tabmenu';

const ViewMachineDetails = () => {
	const dispatch = useDispatch();
	const steps = [{ label: 'Informations' }, { label: 'Documents' }];
	const { showViewMachineDetailsDialog, selectedMachine } = useSelector((state) => state.machine);
	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<Dialog
			visible={showViewMachineDetailsDialog}
			header={`${t('pages.machines.machineDetails')} (${selectedMachine?.machineFullCode})`}
			draggable={false}
			className="full-width-dialog"
			onHide={() => {
				dispatch(setShowViewMachineDetailsDialog(false));
				setActiveIndex(0);
			}}
		>
			<div style={{ maxWidth: '100%', overflowY: 'auto' }}>
				<TabMenu
					model={steps}
					activeIndex={activeIndex}
					onTabChange={(e) => {
						setActiveIndex(e.index);
					}}
					style={{ overflowX: 'visible', overflowY: 'clip' }}
				/>
			</div>
			<div className="p-3">
				{activeIndex === 0 ? <Informations viewMachineDetails={true} /> : activeIndex === 1 ? <Documents viewMachineDetails={true} /> : null}
			</div>
		</Dialog>
	);
};

export default ViewMachineDetails;
