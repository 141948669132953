import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useState, useRef, useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method
import { Toast } from 'primereact/toast';
import { setDialogFlag } from '../../../store/slices/sharedSlice';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
   blockCustomer,
   createCustomerDocument,
   deleteCustomer,
   deleteCustomerDocument,
   getCompanyTypes,
   getCustomerDocument,
   getCustomerPaidInvoices,
   getCustomerTypes,
   getUsers,
   unBlockCustomer,
   undeleteCustomer,
   updateCustomer,
   updateCustomerAddress,
   updateDeletedCustomer,
} from '../../../store/apis/Customers';
import { useTranslation } from 'react-i18next';
import CreateVehicleMalfunction from '../../Workshop/CreateVehicleMalfunction/Index';
import { Dialog } from 'primereact/dialog';
import PurchaseOrder from './CreateNewPurchaseOrder/PurchaseOrder';
import CustomerInvoicesPDF from '../../../components/PDFS/CustomerInvoicesPDF';

const Informations = (props) => {
   console.log(props);
   const dispatch = useDispatch();

   const { t } = useTranslation();

   const [companyTypes, setCompanyTypes] = useState();
   const [customerTypes, setCustomerTypes] = useState();
   const [users, setUsers] = useState();
   const [createDialogVisible, setCreateDialogVisible] = useState();
   const [purchaseOrderDialog, setPurchaseOrderDialog] = useState(false);
   const [averagePaymentInDays, setAveragePaymentInDays] = useState();
   const [bannedReason, setBannedReason] = useState(props.customerData.bannedReason);

   const [generalInformationsInputs, setGeneralInformationsInputs] = useState({
      id: null,
      customerKindId: '',
      companyKindId: null,
      companyName: null,
      activityName: '',
      email: '',
      phone: '',
      idNumber: '',
      faxNumber: '',
      website: '',
      taxNumber: '',
      taxOffice: '',
      note: '',
      followedUserId: null,
   });

   const [addressInputs, setAddressInputs] = useState({
      id: null,
      country: t('global.france'),
      city: '',
      districtName: '',
      postCode: '',
      address: '',
      addressContinuation: '',
   });

   const [billingAddressInputs, setBillingAddressInputs] = useState({
      id: null,
      billingCountry: t('global.france'),
      billingCity: '',
      billingDistrictName: '',
      billingPostCode: '',
      billingAddress: '',
      billingAddressContinuation: '',
   });

   const [creditInformationsInputs, setCreditInformationsInputs] = useState({
      maturity: '',
      maximumCreditLimit: '',
      factorLimit: '',
      factor: false,
      isCheck: false,
      receptionDate: null,
      ownInsurance: false,
      buyersBan: '',
      buyersBic: '',
      buyersRib: '',
      buyersNote: '',
   });

   const [isAddressSame, setIsAddressSame] = useState(false);

   const [selectedFile, setSelectedFile] = useState(null);
   const [files, setFiles] = useState([]);
   const [isCustomerDeleted, setIsCustomerDeleted] = useState();
   const [isCustomerBlocked, setIsCustomerBlocked] = useState();
   const [errors, setErrors] = useState({});
   const [inputsChanged, setInputsChanged] = useState(false);
   const fileUploadRef = useRef(null);
   const refToast = useRef(null);
   const bannedReasonRef = useRef(null);

   const { selectedCustomerId } = useSelector((state) => state.customer);

   const paymentMethods = [
      { id: 'CASH', name: t('pages.customers.cash') },
      { id: 'DAYS_30', name: t('pages.customers.days_30'), value: 30 },
      { id: 'DAYS_45', name: t('pages.customers.days_45'), value: 45 },
   ];

   useEffect(() => {
      dispatch(getCustomerPaidInvoices(props.customerData?.id)).then((res) => {
         setAveragePaymentInDays(res);
      });
   }, [props.customerData]);

   useEffect(() => {
      dispatch(getCompanyTypes()).then((res) => {
         setCompanyTypes(res);
      });

      dispatch(getCustomerTypes()).then((res) => {
         setCustomerTypes(res);
      });
      dispatch(getUsers()).then((res) => {
         setUsers(res);
      });
   }, []);

   useEffect(() => {
      dispatch(getCustomerDocument(props.customerData.id)).then((res) => {
         setFiles(res.data.customerDocuments);
      });
   }, [props.customerData.id]);

   useEffect(() => {
      setGeneralInformationsInputs({
         id: props.customerData.id,
         customerKindId: props.customerData.customerKind.id,
         companyName: props.customerData.companyName,
         companyKindId: props.customerData.companyKind ? props.customerData.companyKind.id : null,
         activityName: props.customerData.activityName,
         email: props.customerData.email,
         phone: props.customerData.phone,
         idNumber: props.customerData.idNumber,
         faxNumber: props.customerData.faxNumber,
         website: props.customerData.website,
         taxNumber: props.customerData.taxNumber,
         taxOffice: props.customerData.taxOffice,
         followedUserId: props.customerData.followedUser ? props.customerData.followedUser.id : null,
         note: props.customerData.note,
         overduePayables: props.customerData.overduePayables,
         outstandingPayables: props.customerData.outstandingPayables,
         averagePayment: props.customerData.averagePayment,
      });
      for (let i = 0; i < props.customerData.addresses.length; i++) {
         if (props.customerData.addresses[i].placeName === 'BILLING ADDRESS') {
            setBillingAddressInputs({
               id: props.customerData.addresses[i].id,
               billingCountry: props.customerData.addresses[i].country,
               billingCity: props.customerData.addresses[i].city,
               billingDistrictName: props.customerData.addresses[i].districtName,
               billingPostCode: props.customerData.addresses[i].postCode,
               billingAddress: props.customerData.addresses[i].address,
               billingAddressContinuation: props.customerData.addresses[i].addressContinuation,
            });
            if (props.customerData.addresses[i].isMainOrBillingAddress) {
               setIsAddressSame(true);
            } else {
               setIsAddressSame(false);
            }
         } else if (props.customerData.addresses[i].placeName === 'MAIN OFFICE') {
            setAddressInputs({
               id: props.customerData.addresses[i].id,
               country: props.customerData.addresses[i].country,
               city: props.customerData.addresses[i].city,
               districtName: props.customerData.addresses[i].districtName,
               postCode: props.customerData.addresses[i].postCode,
               address: props.customerData.addresses[i].address,
               addressContinuation: props.customerData.addresses[i].addressContinuation,
            });
         }
      }

      setCreditInformationsInputs({
         averagePayment: props.customerData.averagePayment,
         overduePayables: '',
         outstandingPayables: '',
         maturity: props.customerData.maturity,
         maximumCreditLimit: '',
         factorLimit: props.customerData.factorLimit,
         maximumCreditLimit: props.customerData.maximumCreditLimit,
         factor: props.customerData.factor,
         isCheck: props.customerData.isCheck,
         receptionDate: props.customerData.receptionDate,
         ownInsurance: props.customerData.ownInsurance,
         buyersBan: props.customerData.buyersBan,
         buyersBic: props.customerData.buyersBic,
         buyersRib: props.customerData.buyersRib,
         buyersNote: props.customerData.buyersNote,
      });

      setIsCustomerDeleted(props.customerData.isDeleted);
      setIsCustomerBlocked(props.customerData.isBanned);
   }, []);

   const onTemplateRemove = () => {
      setSelectedFile(null);
   };

   const onTemplateSave = (selectedCustomerId, document) => {
      dispatch(createCustomerDocument(selectedCustomerId, document)).then((res) => {
         dispatch(getCustomerDocument(props.customerData.id)).then((res) => {
            setFiles(res.data.customerDocuments);
         });
      });
   };

   const ItemTemplate = (props) => {
      return (
         <div style={{ width: '100%' }} className="flex flex-wrap align-items-center">
            <div className="flex align-items-center" style={{ width: '40%' }}>
               <a target="_blank" href={URL.createObjectURL(props.file)} rel="noreferrer">
                  <span style={{ color: 'black' }} className="flex ml-3 text-left flex-column">
                     {props.file.name}
                  </span>
               </a>
            </div>
            <div className="ml-auto">
               <Button
                  type="button"
                  icon="pi pi-check"
                  className="p-button-outlined p-button-rounded p-button-success"
                  onClick={() => onTemplateSave(selectedCustomerId, props.file)}
               />
               <Button
                  type="button"
                  icon="pi pi-times"
                  className="ml-2 p-button-outlined p-button-rounded p-button-danger"
                  onClick={() => onTemplateRemove()}
               />
            </div>
         </div>
      );
   };

   const EmptyTemplate = () => {
      return (
         <div style={{ width: '100%', borderColor: 'gray' }} className="flex align-items-center flex-column">
            <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
               Drag a file here or click
            </span>
         </div>
      );
   };

   const handleFileSelection = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > 9000000) {
         return;
      }
      if (selectedFile) {
         setSelectedFile(selectedFile);
      }
   };

   const openFileChooser = () => {
      if (!selectedFile) {
         fileUploadRef.current.click();
      }
   };

   const handleDragOver = (e) => {
      e.preventDefault();
   };

   const handleDrop = (e) => {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (file) {
         setSelectedFile(file);
      }
   };

   const handleFileClick = (link) => {
      let baseUrl = process.env.REACT_APP_LINK;
      baseUrl = baseUrl.replace('/graphql/', '');
      window.open(baseUrl + link, '_blank');
   };

   const handleFileDelete = (indexToDelete, indexOfFile) => {
      const newArray = files.filter((_, index) => index !== indexToDelete);

      dispatch(deleteCustomerDocument(indexOfFile)).then((res) => {
         console.log(res.data);
      });
      setFiles(newArray);
   };

   const showBlockTemplate = (event) => {
      if (isCustomerBlocked) {
         confirmDialog({
            message: (
               <div className="grid grid-rows-2 pt-2 !w-[400px] ">
                  <div className="w-full">{`${t('pages.customers.bannedReason')}:${bannedReason}`}</div>
                  <div className="w-full">{t('pages.customers.unblockConfirmation')}</div>
               </div>
            ),
            header: t('global.warning'),
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: () => {
               dispatch(unBlockCustomer(selectedCustomerId)).then((res) => {
                  toast.success(t('pages.customers.unblockSuccess'));
                  dispatch(setDialogFlag(true));
                  setIsCustomerBlocked(false);
               });
            },
            reject: () => {},
         });
      } else {
         confirmPopup({
            target: event.currentTarget,
            group: 'templating',
            header: 'Block',
            message: (
               <div className="flex flex-wrap justify-content-center">
                  <span>{t('pages.customers.blockConfirmationTitle')}</span>
                  <InputText
                     className="w-full mb-2 input-detail"
                     placeholder="Reason..."
                     onChange={(e) => {
                        bannedReasonRef.current = e.target.value;
                     }}
                  ></InputText>
                  <Button onClick={() => handleBlockCustomer(selectedCustomerId)} label="CONFIRM"></Button>
               </div>
            ),
         });
      }
   };

   const handleBlockCustomer = (id) => {
      confirmDialog({
         message: t('pages.customers.blockConfirmation'),
         header: t('global.warning'),
         icon: 'pi pi-exclamation-triangle',
         acceptClassName: 'p-button-danger',
         accept: () => {
            dispatch(blockCustomer(id, bannedReasonRef.current)).then((res) => {
               toast.success(t('pages.customers.blockSuccess'));
               dispatch(setDialogFlag(true));
               setIsCustomerBlocked(true);
               setBannedReason(res.data.updateCustomer.customer.bannedReason);
            });
         },
         reject: () => {},
      });
   };

   const showDeleteTemplate = (event) => {
      if (isCustomerDeleted) {
         confirmDialog({
            message: t('pages.customers.undeleteConfirmation'),
            header: t('global.warning'),
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: () => {
               dispatch(undeleteCustomer(selectedCustomerId)).then((res) => {
                  dispatch(setDialogFlag(true));
                  if (generalInformationsInputs.customerKindId === '1') {
                     setGeneralInformationsInputs({
                        ...generalInformationsInputs,
                        customerKindId: '2',
                     });
                     dispatch(updateDeletedCustomer(selectedCustomerId, '2')).then((res) => {
                        toast.success(t('pages.customers.undeleteSuccess'));
                     });
                  }
                  setIsCustomerDeleted(false);
               });
            },
            reject: () => {},
         });
      } else {
         confirmPopup({
            target: event.currentTarget,
            group: 'templating',
            header: 'Delete',
            message: (
               <div className="flex flex-wrap justify-content-center">
                  <span>{t('pages.customers.deleteConfirmationTitle')}</span>
                  <InputText className="w-full mb-2 input-detail" placeholder="Reason..."></InputText>
                  <Button onClick={() => handleDeleteCustomer(selectedCustomerId)} label="CONFIRM"></Button>
               </div>
            ),
         });
      }
   };

   const handleDeleteCustomer = (id) => {
      confirmDialog({
         message: t('pages.customers.deleteConfirmation'),
         header: t('global.warning'),
         icon: 'pi pi-exclamation-triangle',
         acceptClassName: 'p-button-danger',
         accept: () => {
            dispatch(deleteCustomer(id)).then((res) => {
               toast.success(t('pages.customers.deleteSuccess'));
               if (generalInformationsInputs.customerKindId === '2') {
                  setGeneralInformationsInputs({
                     ...generalInformationsInputs,
                     customerKindId: '1',
                  });
                  dispatch(updateDeletedCustomer(selectedCustomerId, '1')).then((res) => {
                     console.log(res.data);
                  });
               }
               setIsCustomerDeleted(true);
               dispatch(setDialogFlag(true));
            });
         },
         reject: () => {},
      });
   };

   // FORM VALIDATION
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (name, value, field) => {
      if (field === 1) {
         setGeneralInformationsInputs({
            ...generalInformationsInputs,
            [name]: value,
         });
         updateErrors(name, value);
      } else if (field === 2) {
         setAddressInputs({ ...addressInputs, [name]: value });
         updateErrors(name, value);
      } else if (field === 3) {
         setBillingAddressInputs({ ...billingAddressInputs, [name]: value });
         updateErrors(name, value);
      } else {
         setCreditInformationsInputs({
            ...creditInformationsInputs,
            [name]: value,
         });
         updateErrors(name, value);
      }
      setInputsChanged(true);
   };

   const validateGeneralInformationForm = () => {
      const inputs = ['customerKindId', 'companyName', 'email', 'phone', 'idNumber', 'followedUserId'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!generalInformationsInputs[input]) {
            updateErrors(input, generalInformationsInputs[input]);
            isValid = false;
         }
      });

      return isValid;
   };

   const validateAddressForm = () => {
      const inputs = ['country', 'city', 'postCode', 'address'];

      let isValid = true;
      inputs.forEach((input) => {
         if (!addressInputs[input]) {
            updateErrors(input, addressInputs[input]);
            isValid = false;
         }
      });

      return isValid;
   };

   const validateBillingAddressForm = () => {
      const inputs = ['billingCountry', 'billingCity', 'billingPostCode', 'billingAddress'];

      let isValid = true;
      inputs.forEach((input) => {
         if (!billingAddressInputs[input]) {
            updateErrors(input, billingAddressInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleUpdate = async () => {
      console.log(creditInformationsInputs);
      if (inputsChanged) {
         if (isAddressSame) {
            if (validateGeneralInformationForm() && validateAddressForm()) {
               dispatch(updateCustomerAddress(addressInputs, billingAddressInputs, selectedCustomerId, isAddressSame)).then((res) => {
                  if (res) {
                     dispatch(updateCustomer(selectedCustomerId, generalInformationsInputs, creditInformationsInputs)).then((res) => {
                        toast.success(t('pages.customers.updateSuccess'));
                     });
                  }
               });
            }
         } else {
            if (validateGeneralInformationForm() && validateAddressForm() && validateBillingAddressForm()) {
               dispatch(updateCustomerAddress(addressInputs, billingAddressInputs, selectedCustomerId, isAddressSame)).then((res) => {
                  if (res) {
                     dispatch(updateCustomer(selectedCustomerId, generalInformationsInputs, creditInformationsInputs)).then((res) => {
                        toast.success(t('pages.customers.updateSuccess'));
                     });
                  }
               });
            }
         }
      }
   };

   return (
      <div>
         <div className="flex w-full ps-3">
            <div className="flex grid flex-wrap card p-fluid">
               <Divider align="left">
                  <div className="inline-flex align-items-center">
                     <b>{t('pages.customers.generalInformation')}</b>
                  </div>
               </Divider>
               <div className="grid">
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.type')}*</label>
                     <Dropdown
                        value={generalInformationsInputs.customerKindId}
                        className={`w-full input-detail ${errors.customerKindId && 'input-error'}`}
                        options={customerTypes}
                        optionLabel="name"
                        optionValue="id"
                        onChange={(e) => {
                           handleInputChange('customerKindId', e.value, 1);
                        }}
                     />
                     {errors.customerKindId && <small className="p-error">{errors.customerKindId}</small>}
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label className="w-full">{t('pages.customers.companyName')}*</label>
                     <div className="flex">
                        <InputText
                           className={`p-inputtext-sm w-7/12 lg:w-8/12 input-detail ${errors.companyName && 'input-error'}`}
                           value={generalInformationsInputs.companyName}
                           onChange={(e) => handleInputChange('companyName', e.target.value, 1)}
                        ></InputText>
                        <Dropdown
                           value={generalInformationsInputs.companyKindId}
                           className="w-5/12 lg:w-4/12 input-detail"
                           options={companyTypes}
                           onChange={(e) => handleInputChange('companyKindId', e.value, 1)}
                           optionLabel="name"
                           optionValue="id"
                           showClear
                        />
                     </div>
                     {errors.companyName && <small className="p-error">{errors.companyName}</small>}
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.activityName')}</label>
                     <InputText
                        className="p-inputtext-sm input-detail"
                        value={generalInformationsInputs.activityName}
                        onChange={(e) => handleInputChange('activityName', e.target.value, 1)}
                     ></InputText>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.eMail')}*</label>
                     <InputText
                        className={`p-inputtext-sm w-full input-detail ${errors.email && 'input-error'}`}
                        value={generalInformationsInputs.email}
                        onChange={(e) => handleInputChange('email', e.target.value, 1)}
                     ></InputText>
                     {errors.email && <small className="p-error">{errors.email}</small>}
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.phoneNumber')}*</label>
                     <InputText
                        className={`p-inputtext-sm w-full input-detail ${errors.phone && 'input-error'}`}
                        value={generalInformationsInputs.phone}
                        onChange={(e) => handleInputChange('phone', e.target.value, 1)}
                     ></InputText>
                     {errors.phone && <small className="p-error">{errors.phone}</small>}
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.faxNumber')}</label>
                     <InputText
                        className="p-inputtext-sm input-detail"
                        name="faxNumber"
                        value={generalInformationsInputs.faxNumber}
                        onChange={(e) => handleInputChange('faxNumber', e.target.value, 1)}
                     ></InputText>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.identityNumber')}*</label>
                     <InputText
                        className={`p-inputtext-sm w-full input-detail ${errors.idNumber && 'input-error'}`}
                        name="identityNumber"
                        value={generalInformationsInputs.idNumber}
                        onChange={(e) => handleInputChange('idNumber', e.target.value, 1)}
                     ></InputText>
                     {errors.idNumber && <small className="p-error">{errors.idNumber}</small>}
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.webSite')}</label>
                     <InputText
                        className="p-inputtext-sm input-detail"
                        value={generalInformationsInputs.website}
                        onChange={(e) => {
                           handleInputChange('website', e.target.value, 1);
                        }}
                     ></InputText>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.taxNumber')}</label>
                     <InputText
                        className="p-inputtext-sm input-detail"
                        value={generalInformationsInputs.taxNumber}
                        onChange={(e) => {
                           handleInputChange('taxNumber', e.target.value, 1);
                        }}
                     ></InputText>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.taxOffice')}</label>
                     <InputText
                        className="p-inputtext-sm input-detail"
                        value={generalInformationsInputs.taxOffice}
                        onChange={(e) => {
                           handleInputChange('taxOffice', e.target.value, 1);
                        }}
                     ></InputText>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.notes')}</label>
                     <InputText
                        className="p-inputtext-sm input-detail"
                        value={generalInformationsInputs.note}
                        onChange={(e) => {
                           handleInputChange('note', e.target.value, 1);
                        }}
                     ></InputText>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <label>{t('pages.customers.followedUser')}</label>
                     <Dropdown
                        value={generalInformationsInputs.followedUserId}
                        className={`p-inputtext-sm w-full input-detail ${errors.followedUserId && 'input-error'}`}
                        placeholder="Select User"
                        options={users}
                        optionValue="id"
                        optionLabel="name"
                        onChange={(e) => {
                           handleInputChange('followedUserId', e.value, 1);
                        }}
                        showClear
                     />
                     {errors.followedUserId && <small className="p-error">{errors.followedUserId}</small>}
                  </div>
               </div>

               <Divider align="left">
                  <div className="inline-flex align-items-center">
                     <b>{t('pages.customers.customerAddress')}</b>
                  </div>
               </Divider>
               <div className="col-12 md:col-6">
                  <span className="underline">{t('pages.customers.mainOfficeAddress')}</span>
                  <div className="flex flex-wrap">
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.country')}*</label>
                        <InputText
                           className={`p-inputtext-sm w-full input-detail ${errors.country && 'input-error'}`}
                           value={addressInputs.country}
                           disabled
                           onChange={(e) => handleInputChange('country', e.target.value, 2)}
                        ></InputText>
                        {errors.country && <small className="p-error">{errors.country}</small>}
                     </div>
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.city')}*</label>
                        <InputText
                           value={addressInputs.city}
                           onChange={(e) => {
                              handleInputChange('city', e.target.value, 2);
                           }}
                           className={`p-inputtext-sm w-full input-detail ${errors.city && 'input-error'}`}
                        />
                        {errors.city && <small className="p-error">{errors.city}</small>}
                     </div>
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.district')}</label>
                        <InputText
                           className="p-inputtext-sm input-detail"
                           onChange={(e) => {
                              handleInputChange('districtName', e.target.value, 2);
                           }}
                           value={addressInputs.districtName}
                        ></InputText>
                     </div>
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.postCode')}*</label>
                        <InputText
                           className={`p-inputtext-sm w-full input-detail ${errors.postCode && 'input-error'}`}
                           value={addressInputs.postCode}
                           onChange={(e) => handleInputChange('postCode', e.target.value, 2)}
                        ></InputText>
                        {errors.postCode && <small className="p-error">{errors.postCode}</small>}
                     </div>
                     <div className="col-12">
                        <label>{t('pages.customers.address')}*</label>
                        <InputText
                           className={`p-inputtext-sm w-full input-detail ${errors.address && 'input-error'}`}
                           value={addressInputs.address}
                           onChange={(e) => handleInputChange('address', e.target.value, 2)}
                        ></InputText>
                        {errors.address && <small className="p-error">{errors.address}</small>}
                     </div>
                     <div className="col-12">
                        <label>{t('pages.customers.addressContinuation')}</label>
                        <InputText
                           className="p-inputtext-sm input-detail"
                           value={addressInputs.addressContinuation}
                           onChange={(e) => handleInputChange('addressContinuation', e.target.value, 2)}
                        ></InputText>
                     </div>
                  </div>
                  <div className="flex items-center justify-end col-12">
                     <label>{t('pages.customers.officeAndBillingAddressSame')}</label>
                     <InputSwitch
                        checked={isAddressSame}
                        onChange={(e) => {
                           setInputsChanged(true);
                           setIsAddressSame(e.value);
                        }}
                        size="small"
                        className="ml-2"
                     />
                  </div>
               </div>
               <div className="col-12 md:col-6" style={{ marginLeft: 'auto' }}>
                  <span className="underline">{t('pages.customers.billingAddress')}</span>
                  <div className="flex flex-wrap">
                     <div className="md:col-6 col-12 ">
                        <label>{t('pages.customers.country')}*</label>
                        <InputText
                           disabled
                           className={`p-inputtext-sm w-full input-detail`}
                           value={isAddressSame ? addressInputs.country : billingAddressInputs.billingCountry}
                           onChange={(e) => handleInputChange('billingCountry', e.target.value, 3)}
                        ></InputText>
                     </div>
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.city')}*</label>
                        <InputText
                           disabled={isAddressSame}
                           value={isAddressSame ? addressInputs.city : billingAddressInputs.billingCity}
                           onChange={(e) => {
                              handleInputChange('billingCity', e.target.value, 3);
                           }}
                           className={`p-inputtext-sm w-full input-detail ${errors.billingCity && !isAddressSame && 'input-error'}`}
                        />
                        {errors.billingCity && !isAddressSame && <small className="p-error">{errors.billingCity}</small>}
                     </div>
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.district')}</label>
                        <InputText
                           disabled={isAddressSame}
                           className="p-inputtext-sm input-detail"
                           value={isAddressSame ? addressInputs.districtName : billingAddressInputs.billingDistrictName}
                           onChange={(e) => {
                              handleInputChange('billingDistrictName', e.target.value, 3);
                           }}
                        ></InputText>
                     </div>
                     <div className="md:col-6 col-12">
                        <label>{t('pages.customers.postCode')}*</label>
                        <InputText
                           disabled={isAddressSame}
                           className={`p-inputtext-sm w-full input-detail ${errors.billingPostCode && !isAddressSame && 'input-error'}`}
                           value={isAddressSame ? addressInputs.postCode : billingAddressInputs.billingPostCode}
                           onChange={(e) => {
                              handleInputChange('billingPostCode', e.target.value, 3);
                           }}
                        />
                        {errors.billingPostCode && !isAddressSame && <small className="p-error">{errors.billingPostCode}</small>}
                     </div>

                     <div className="col-12">
                        <label>{t('pages.customers.address')}*</label>
                        <InputText
                           disabled={isAddressSame}
                           className={`p-inputtext-sm w-full input-detail ${errors.billingAddress && !isAddressSame && 'input-error'}`}
                           value={isAddressSame ? addressInputs.address : billingAddressInputs.billingAddress}
                           onChange={(e) => {
                              handleInputChange('billingAddress', e.target.value, 3);
                           }}
                        />
                        {errors.billingAddress && !isAddressSame && <small className="p-error">{errors.billingAddress}</small>}
                     </div>
                     <div className="col-12">
                        <label>{t('pages.customers.addressContinuation')}</label>
                        <InputText
                           disabled={isAddressSame}
                           className="p-inputtext-sm input-detail"
                           value={isAddressSame ? addressInputs.addressContinuation : billingAddressInputs.billingAddressContinuation}
                           onChange={(e) => {
                              handleInputChange('billingAddressContinuation', e.target.value, 3);
                           }}
                        ></InputText>
                     </div>
                  </div>
               </div>

               <Divider align="left">
                  <div className="inline-flex align-items-center">
                     <b>{t('pages.customers.creditInformation')}</b>
                  </div>
               </Divider>
               <div
                  className="flex flex-wrap"
                  style={{
                     width: '100%',
                     marginBottom: '1rem',
                     backgroundColor: `${
                        creditInformationsInputs.maturity === 'CASH'
                           ? '#FF4500'
                           : creditInformationsInputs.maturity === 'DAYS_45' && averagePaymentInDays > 45
                           ? '#FF4500'
                           : creditInformationsInputs.maturity === 'DAYS_45' && averagePaymentInDays <= 45
                           ? '#65c065'
                           : creditInformationsInputs.maturity === 'DAYS_30' && averagePaymentInDays > 30
                           ? '#FF4500'
                           : creditInformationsInputs.maturity === 'DAYS_30' && averagePaymentInDays <= 30
                           ? '#65c065'
                           : null
                     }`,
                  }}
               >
                  <div className="flex flex-col col-12 md:col-4">
                     <label style={{ color: 'black' }}>{t('pages.customers.averagePaymentsInDays')}</label>
                     <InputText
                        className="p-inputtext-sm"
                        value={generalInformationsInputs.averagePayment}
                        onChange={(e) => handleInputChange('averagePayment', e.target.value, 4)}
                        disabled
                     ></InputText>
                  </div>
                  <div className="flex flex-col col-12 md:col-4">
                     <label style={{ color: 'black' }}>{t('pages.customers.overduePayables')}</label>
                     <InputText
                        className="mb-auto p-inputtext-sm"
                        value={parseFloat(generalInformationsInputs.overduePayables).toFixed(2)}
                        disabled
                     ></InputText>
                  </div>
                  <div className="flex flex-col col-12 md:col-4">
                     <label style={{ color: 'black' }}>{t('pages.customers.outstandingPayables')}</label>
                     <InputText
                        className="p-inputtext-sm"
                        name="outstandingPayables"
                        value={parseFloat(generalInformationsInputs.outstandingPayables).toFixed(2)}
                        disabled
                     ></InputText>
                  </div>
                  <div className="flex flex-col col-12 md:col-4">
                     <label style={{ color: 'black' }}>{t('pages.customers.paymentMethod')}</label>
                     <Dropdown
                        value={creditInformationsInputs.maturity}
                        options={paymentMethods}
                        optionLabel="name"
                        optionValue="id"
                        onChange={(e) => {
                           handleInputChange('maturity', e.value, 4);
                        }}
                     />
                  </div>
                  <div className="flex flex-col col-12 md:col-4">
                     <label style={{ color: 'black' }}>{t('pages.customers.maximumCreditLimit')}</label>
                     <InputText
                        className="p-inputtext-sm"
                        value={creditInformationsInputs.maximumCreditLimit}
                        onChange={(e) => {
                           handleInputChange('maximumCreditLimit', e.target.value, 4);
                        }}
                     ></InputText>
                  </div>
                  <div style={{ marginBottom: '1rem' }} className="flex flex-col col-12 md:col-4">
                     <label style={{ color: 'black' }}>{t('pages.customers.factorLimit')}</label>
                     <InputText
                        className="p-inputtext-sm"
                        name="factorLimit"
                        value={creditInformationsInputs.factorLimit}
                        onChange={(e) => {
                           handleInputChange('factorLimit', e.target.value, 4);
                        }}
                     ></InputText>
                  </div>
               </div>
               <div className="flex flex-col col-12 sm:col-4">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>{t('pages.customers.factor')}</label>
                     <InputSwitch checked={creditInformationsInputs.factor} onChange={(e) => handleInputChange('factor', e.target.value, 4)} />
                  </div>
               </div>
               <div className="flex flex-col col-12 sm:col-4">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>{t('pages.customers.check')}</label>
                     <InputSwitch checked={creditInformationsInputs.isCheck} onChange={(e) => handleInputChange('isCheck', e.target.value, 4)} />
                  </div>
               </div>
               <div className="flex flex-col col-12 sm:col-4">
                  <label>{t('pages.customers.receptionDate')}</label>
                  <Calendar
                     className="input-detail"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={creditInformationsInputs.receptionDate ? new Date(creditInformationsInputs.receptionDate) : null}
                     onChange={(e) => {
                        const date = new Date(e.value);
                        const convertedDate = date.toString().substring(0, 28);
                        const selectedDate = new Date(convertedDate);
                        const isoDate = selectedDate.toISOString();
                        handleInputChange('receptionDate', isoDate.substring(0, 10), 4);
                     }}
                  />
               </div>
               <div className="flex flex-col col-12 sm:col-6 md:col-4">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>{t('pages.customers.hasItsOwnInsurance')}</label>
                     <InputSwitch checked={creditInformationsInputs.ownInsurance} onChange={(e) => handleInputChange('ownInsurance', e.target.value, 4)} />
                  </div>
               </div>
               <div
                  style={{
                     width: '100%',
                     marginTop: '1rem',
                     backgroundColor: 'rgba(112, 128, 144, 0.063)',
                  }}
               >
                  <div style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}>
                     <label>{t('pages.customers.buyersBankAccountNumber')}</label>
                  </div>
                  <div className="flex flex-wrap">
                     <div className="col-12 md:col-4">
                        <label style={{ color: 'black' }}>{t('pages.customers.rib')}</label>
                        <InputText
                           value={creditInformationsInputs.buyersRib}
                           onChange={(e) => handleInputChange('buyersRib', e.target.value, 4)}
                           className="p-inputtext-sm"
                        ></InputText>
                     </div>
                     <div className="col-12 md:col-4">
                        <label style={{ color: 'black' }}>{t('pages.customers.iban')}</label>
                        <InputText
                           value={creditInformationsInputs.buyersBan}
                           onChange={(e) => handleInputChange('buyersBan', e.target.value, 4)}
                           className="p-inputtext-sm"
                        ></InputText>
                     </div>
                     <div className="col-12 md:col-4">
                        <label style={{ color: 'black' }}>{t('pages.customers.bic')}</label>
                        <InputText
                           value={creditInformationsInputs.buyersBic}
                           onChange={(e) => handleInputChange('buyersBic', e.target.value, 4)}
                           className="p-inputtext-sm"
                           name="bic"
                        ></InputText>
                     </div>
                  </div>
                  <div className="col-12">
                     <InputTextarea
                        autoResize
                        value={creditInformationsInputs.buyersNote}
                        style={{ marginBottom: '1rem' }}
                        onChange={(e) => handleInputChange('buyersNote', e.target.value, 4)}
                     />
                  </div>
               </div>
               <div className="col-12 md:col-6" style={{ marginTop: '1.8rem' }}>
                  <label style={{ color: 'black' }}>{t('pages.customers.dropzoneSheet')}</label>
                  <div style={{ backgroundColor: '#dcdcdc' }} className="p-2">
                     <div className="flex flex-col col-12">
                        <Button
                           onClick={(e) => openFileChooser(e)}
                           onDragOver={(e) => handleDragOver(e)}
                           onDrop={(e) => handleDrop(e)}
                           style={{
                              padding: '10px',
                              cursor: 'pointer',
                              border: 'solid black',
                              background: 'white',
                              height: '200px',
                           }}
                        >
                           {selectedFile === null && <EmptyTemplate />}
                           {selectedFile && <ItemTemplate file={selectedFile} />}
                           <input type="file" ref={fileUploadRef} style={{ display: 'none' }} onChange={(e) => handleFileSelection(e)} />
                        </Button>
                     </div>
                  </div>
               </div>
               <div className="col-12 md:col-5 " style={{ marginLeft: 'auto', marginTop: '1.8rem' }}>
                  <label style={{ color: 'black' }}>{t('pages.customers.listOfAllFilesLinkedToThisCustomer')}</label>
                  <div style={{ backgroundColor: '#dcdcdc' }} className="p-3">
                     <div
                        className="flex flex-col p-3 col-12"
                        style={{
                           border: 'solid black',
                           backgroundColor: 'white',
                           height: '200px',
                           overflow: 'auto',
                        }}
                     >
                        {files.length === 0 && <label>{t('pages.customers.noFilesFound')}</label>}
                        <div className="align-items-center justify-content-between px-0.5 py-1 border-box">
                           {files.map((item, index) => (
                              <div key={index}>
                                 <hr style={{ borderColor: 'black' }} />
                                 <div className="flex my-1 align-items-center">
                                    <label className="mr-auto">
                                       {item.document.name.length > 30
                                          ? item.document.name.slice(0, 15) + '...' + item.document.name.slice(-10)
                                          : item.document.name}
                                    </label>
                                    <Button
                                       label={t('pages.customers.open')}
                                       className="py-1 rounded-none col-4 md:col-3 lg:col-2"
                                       onClick={() => handleFileClick(item.document.url)}
                                       style={{
                                          backgroundColor: 'blue',
                                          textAlign: 'center',
                                          fontSize: '1rem',
                                          border: 'none',
                                       }}
                                    ></Button>
                                    <Button
                                       label={t('pages.customers.delete')}
                                       className="py-1 ml-2 rounded-none col-4 md:col-3 lg:col-2"
                                       onClick={() => handleFileDelete(index, item.id)}
                                       style={{
                                          backgroundColor: 'red',
                                          textAlign: 'center',
                                          fontSize: '1rem',
                                          border: 'none',
                                       }}
                                    ></Button>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>

               <div className="col-12 ms-auto">
                  <div className="flex flex-wrap justify-content-end" style={{ marginTop: '2rem' }}>
                     <CustomerInvoicesPDF generalInformationsInputs={generalInformationsInputs} customerId={selectedCustomerId} />
                     {generalInformationsInputs.customerKindId === '3' || generalInformationsInputs.customerKindId === '4' ? (
                        <Button
                           label={t('pages.customers.purchaseOrder')}
                           className="mt-2 mr-auto col-12 md:col-2"
                           onClick={() => setPurchaseOrderDialog(true)}
                           style={{
                              border: 'none',
                              backgroundColor: 'green',
                              textAlign: 'center',
                              fontSize: '1rem',
                           }}
                           size="small"
                        ></Button>
                     ) : null}

                     <Button
                        label={isCustomerDeleted ? t('pages.customers.unDelete') : t('pages.customers.delete')}
                        className="mt-2 ml-3 col-12 md:col-1"
                        onClick={(e) => showDeleteTemplate(e)}
                        style={{
                           border: 'none',
                           backgroundColor: 'red',
                           textAlign: 'center',
                           fontSize: '1rem',
                        }}
                        size="small"
                     ></Button>
                     <Button
                        className="mt-2 ml-3 col-12 md:col-1"
                        onClick={(e) => showBlockTemplate(e)}
                        label={isCustomerBlocked ? t('pages.customers.unBlock') : t('pages.customers.block')}
                        style={{
                           border: 'none',
                           backgroundColor: 'red',
                           textAlign: 'center',
                           fontSize: '1rem',
                        }}
                        size="small"
                     />
                     <Button
                        label={t('pages.customers.malfunctionRecord')}
                        className="mt-2 ml-3 col-12 md:col-2"
                        onClick={(e) => setCreateDialogVisible(true)}
                        style={{
                           border: 'none',
                           backgroundColor: 'red',
                           textAlign: 'center',
                           fontSize: '1rem',
                        }}
                        size="small"
                     ></Button>
                     <Button label={t('global.update')} onClick={() => handleUpdate()} className="mt-2 ml-3 col-12 md:col-1" size="small"></Button>
                  </div>
               </div>
            </div>
         </div>
         {
            <div>
               <Toast ref={refToast} />
               <ConfirmPopup group="templating" />
            </div>
         }
         <ConfirmDialog />

         <Dialog
            header={
               generalInformationsInputs.customerKindId === '4'
                  ? t('pages.contracts.createNewTransporterOrder')
                  : t('pages.contracts.createNewSupplierPurchaseOrder')
            }
            className="w-[70vw]"
            visible={purchaseOrderDialog}
            onHide={() => setPurchaseOrderDialog(false)}
         >
            <PurchaseOrder
               customerKindId={generalInformationsInputs.customerKindId}
               customerId={selectedCustomerId}
               setPurchaseOrderDialog={setPurchaseOrderDialog}
               purchaseOrderDialog={purchaseOrderDialog}
            ></PurchaseOrder>
         </Dialog>

         <CreateVehicleMalfunction createDialogVisible={createDialogVisible} setCreateDialogVisible={setCreateDialogVisible} />
      </div>
   );
};

export default Informations;
