import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { exchangeMachine, getMachines, updateVehicleReservation } from '../../../../store/apis/ContractsApi';
import { Button } from 'primereact/button';
const ExchangeMachine = ({ exchangeMachineVisible, setExchangeMachineVisible, selectedItem, setSelectedItem }) => {
   const dispatch = useDispatch();
   const { contract } = useSelector((state) => state.contracts);
   const [inputs, setInputs] = useState({
      newMachineId: null,
      deliveryFee: 0,
      pickupFee: 0,
   });

   const [errors, setErrors] = useState({});
   const [vehicles, setVehicles] = useState([]);
   useEffect(() => {
      dispatch(
         getMachines({
            isDeleted: false,
            isActive: true,
            status: 'AVAILABLE',
            isSupplier: false,
         }),
      ).then((response) => {
         if (response) {
            setVehicles(response);
         }
      });
   }, []);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };
   const handleInputChange = (value, name) => {
      if (name.includes('fee')) {
         value = value.replace(/[^0-9]/g, '');
      }
      setInputs((_inputs) => ({
         ..._inputs,
         [name]: value,
      }));
      updateErrors(name, value);
   };
   const handleFocus = (name) => {
      if (inputs[name] === 0) {
         setInputs({ ...inputs, [name]: '' });
      }
   };

   const validateForm = () => {
      const keys = ['newMachineId', 'deliveryFee', 'pickupFee'];
      let isValid = true;
      keys.forEach((input) => {
         if (!inputs[input]) {
            updateErrors(input, inputs[input]);
            isValid = false;
         }
      });

      return isValid;
   };
   const handleSave = () => {
      if (!validateForm()) return;
      let data = {
         id: selectedItem?.vehicleReservation?.id,
         vehicleId: inputs.newMachineId,
         deliveryFee: parseFloat(selectedItem?.vehicleReservation?.deliveryFee) + parseFloat(inputs.deliveryFee),
         pickupFee: parseFloat(selectedItem?.vehicleReservation?.pickupFee) + parseFloat(inputs.pickupFee),
      };

      dispatch(exchangeMachine({ orderItemId: selectedItem?.id, orderId: contract?.id, data })).then((response) => {
         if (response) {
            setExchangeMachineVisible(false);
            setSelectedItem(null);
            resetForm();
         }
      });
   };

   const resetForm = () => {
      setInputs({
         newMachineId: null,
         deliveryFee: 0,
         pickupFee: 0,
      });
      setErrors({});
   };
   return (
      <Dialog
         header={t('pages.contracts.exchangeMachine')}
         visible={exchangeMachineVisible}
         style={{ width: '50vw' }}
         onHide={() => {
            setExchangeMachineVisible(false);
            setSelectedItem(null);
            resetForm();
         }}
      >
         <>
            <div className="grid">
               <div className="col-6">
                  <label>{t('pages.contracts.originalMachine')}*</label>
                  <InputText value={selectedItem?.vehicleReservation?.vehicle?.machineFullCode} readOnly className="w-full input-detail" />
               </div>
               <div className="col-6">
                  <label>{t('pages.contracts.newMachine')}*</label>
                  <Dropdown
                     filter
                     options={vehicles.filter((vehicle) => vehicle.id !== selectedItem?.vehicleReservation?.vehicle?.id)}
                     value={inputs?.newMachineId}
                     onChange={(e) => {
                        handleInputChange(e.target.value, 'newMachineId');
                     }}
                     optionLabel="machineFullCode"
                     optionValue="id"
                     className={`w-full input-detail ${errors.newMachineId && 'input-error'}`}
                  />
                  {errors.newMachineId && <small className="p-error">{errors.newMachineId}</small>}
               </div>
               <div className="col-6">
                  <label>{t('pages.contracts.deliveryFee')}*</label>
                  <InputText
                     keyfilter="pint"
                     value={inputs?.deliveryFee}
                     onChange={(e) => handleInputChange(e.target.value, 'deliveryFee')}
                     onFocus={() => handleFocus('deliveryFee')}
                     className={`w-full input-detail ${errors.deliveryFee && 'input-error'}`}
                  />
                  {errors.deliveryFee && <small className="p-error">{errors.deliveryFee}</small>}
               </div>
               <div className="col-6">
                  <label>{t('pages.contracts.pickupFee')}*</label>
                  <InputText
                     keyfilter="pint"
                     value={inputs?.pickupFee}
                     onChange={(e) => handleInputChange(e.target.value, 'pickupFee')}
                     onFocus={() => handleFocus('pickupFee')}
                     className={`w-full input-detail ${errors.pickupFee && 'input-error'}`}
                  />
                  {errors.pickupFee && <small className="p-error">{errors.pickupFee}</small>}
               </div>
            </div>
            <div className="flex justify-end">
               <Button className="mt-2" onClick={handleSave} label={t('global.save')} severity="info" />
            </div>
         </>
      </Dialog>
   );
};

export default ExchangeMachine;
