import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { changeLoadingStatus } from '../../../store/slices/sharedSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { setSelectedMachineId, setSelectedMachine, setStepCounter, setIsFromSupplierChecked, setShowAddSupplier } from '../../../store/slices/machineSlice';
import { setNewCustomerStepCounter, setSelectedCustomerId, setShowAddNewCustomer } from '../../../store/slices/customerSlice';
import moment from 'moment';
import AddNewCustomer from '../../Customers/AddNewCustomer';
import {
   getSuppliers,
   getVehicleKinds,
   getMachineGroups,
   getVehicleFuelKinds,
   createVehicle,
   updateVehicle,
   createVehicleGroup,
   deleteVehicles,
   deleteVehicleGroups,
   updateVehicleGroup,
} from '../../../store/apis/TCardApi';
import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method

const Informations = (props) => {
   const dispatch = useDispatch();

   const { t } = useTranslation();

   const { informations, setInformations } = props;
   const [macGroupDesc, setMacGroupDesc] = useState('');

   const { selectedMachineId, selectedMachine, isFromSupplierChecked, showAddSupplier } = useSelector((state) => state.machine);
   const { selectedCustomerId, showAddNewCustomer } = useSelector((state) => state.customer);

   const [isSupplier, setIsSupplier] = useState(false);

   const [machineTypes, setMachineTypes] = useState();
   const [suppliers, setSuppliers] = useState();
   const [machineGroups, setMachineGroups] = useState();
   const [fuelTypes, setFuelTypes] = useState();
   const [selectedMachineGroupName, setSelectedMachineGroupName] = useState();
   const [showAddMachineGroup, setShowAddMachineGroup] = useState(false);
   const [newMachineGroup, setNewMachineGroup] = useState({
      vehicleKindId: '',
      name: '',
      description: '',
   });
   const [errors, setErrors] = useState({});

   useEffect(() => {
      dispatch(getVehicleKinds()).then((res) => setMachineTypes(res));
      dispatch(getVehicleFuelKinds()).then((res) => setFuelTypes(res));
      dispatch(getSuppliers()).then((res) => setSuppliers(res));
   }, []);

   useEffect(() => {
      if (!showAddSupplier) dispatch(getSuppliers()).then((res) => setSuppliers(res));
   }, [showAddSupplier]);

   useEffect(() => {
      if (!showAddMachineGroup) {
         if (props.viewMachineDetails) {
            dispatch(getMachineGroups(selectedMachine.vehicleKind.id, false, isFromSupplierChecked, null, selectedMachine.supplierCustomerId)).then((res) => {
               setMachineGroups(res.data.vehicleGroups);
            });
         } else {
            dispatch(getMachineGroups(informations.vehicleKind, isSupplier, false, informations.supplierCustomer, null)).then((res) => {
               if (res && res.data) {
                  setMachineGroups(res.data.vehicleGroups);
               }
            });
         }
      }
   }, [showAddMachineGroup]);

   // FORM VALIDATION
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (name, value) => {
      if (props.viewMachineDetails) {
         dispatch(setSelectedMachine({ ...selectedMachine, [name]: value }));
      } else {
         setInformations({
            ...informations,
            [name]: value,
         });
      }
      updateErrors(name, value);
   };

   const validateForm = () => {
      const input = ['machineCode', 'vehicleKind', 'note', 'vehicleFuelKind', 'vehicleGroup'];
      const inputs = isFromSupplierChecked || isSupplier ? [...input, 'supplierCustomer'] : input;

      let isValid = true;
      if (props.viewMachineDetails) {
         inputs.forEach((input) => {
            if (!selectedMachine[input]) {
               updateErrors(input, selectedMachine[input]);
               isValid = false;
            }
         });
      } else {
         inputs.forEach((input) => {
            if (!informations[input]) {
               updateErrors(input, informations[input]);
               isValid = false;
            }
         });
      }
      return isValid;
   };

   const monthSubtraction = () => {
      if (props.viewMachineDetails) {
         if (moment(selectedMachine.guaranteeDeliveryDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD')) {
            return selectedMachine.guaranteePeriod;
         } else {
            return parseInt(
               selectedMachine.guaranteePeriod -
                  parseFloat(((new Date() - new Date(selectedMachine.guaranteeDeliveryDate)) / (1000 * 60 * 60 * 24 * 30.44)).toFixed(2)) +
                  1,
            );
         }
      } else {
         if (moment(informations.guaranteeDeliveryDate).format('YYYY-MM-DD') >= moment(new Date()).format('YYYY-MM-DD')) {
            return informations.guaranteePeriod;
         }
         return parseInt(
            informations.guaranteePeriod - parseFloat(((new Date() - informations.guaranteeDeliveryDate) / (1000 * 60 * 60 * 24 * 30.44)).toFixed(2)) + 1,
         );
      }
   };

   const handleSave = async () => {
      dispatch(changeLoadingStatus(true));
      if (props.viewMachineDetails) {
         if (validateForm()) {
            updateMachine(selectedMachineId);
         }
      } else {
         createMachine();
      }
      if (newMachineGroup.vehicleKindId !== '' && newMachineGroup.name !== '') {
         handleSaveNewMachineGroup();
      }
   };

   const handleDelete = (id) => {
      dispatch(deleteVehicles(id)).then((res) => {
         toast.success(t('pages.machines.machineDeleteSuccess'));
      });
   };

   const createMachine = () => {
      dispatch(createVehicle(informations, selectedCustomerId)).then((res) => {
         if (res) {
            toast.success(t('pages.machines.machineCreateSuccess'));
            dispatch(setSelectedMachineId(res.data.createVehicle.vehicle.id));
            dispatch(setSelectedMachine(res.data.createVehicle.vehicle));
            dispatch(setStepCounter(1));
            dispatch(setSelectedCustomerId(null));
            const data = {
               id: +informations.vehicleGroup,
               description: macGroupDesc,
            };
            dispatch(updateVehicleGroup(data));
         }
      });
   };

   const updateMachine = (id) => {
      dispatch(updateVehicle(id, selectedMachine, selectedCustomerId)).then((res) => {
         if (res) {
            toast.success(t('pages.machines.machineUpdateSuccess'));
            dispatch(setSelectedMachineId(res.data.updateVehicle.vehicle.id));
            dispatch(setSelectedMachine(res.data.updateVehicle.vehicle));
            dispatch(setSelectedCustomerId(null));
            dispatch(setStepCounter(1));
         }
      });
   };

   const handleSaveNewMachineGroup = () => {
      dispatch(changeLoadingStatus(true));
      if (newMachineGroup.vehicleKindId === '' || newMachineGroup.vehicleKindId === null || newMachineGroup.name === '') {
         toast.error(t('global.fillOutRequiredFields'));
         dispatch(changeLoadingStatus(false));
         return;
      }
      dispatch(
         createVehicleGroup(
            newMachineGroup,
            isSupplier,
            isFromSupplierChecked,
            isSupplier ? informations.supplierCustomer : null,
            isFromSupplierChecked ? selectedMachine.supplierCustomer.id : null,
         ),
      ).then((res) => {
         toast.success(t('pages.machines.createNewVehicleGroupSuccess'));
         if (res) {
            if (props.viewMachineDetails) {
               dispatch(
                  setSelectedMachine({
                     ...selectedMachine,
                     vehicleGroup: { id: res.data.createVehicleGroup.vehicleGroup.id },
                  }),
               );
            } else {
               setInformations({
                  ...informations,
                  vehicleGroup: res.data.createVehicleGroup.vehicleGroup.id,
               });
            }
            setMacGroupDesc(res.data.createVehicleGroup.vehicleGroup.description);
            setNewMachineGroup({ vehicleKindId: '', name: '', description: '' });
            setShowAddMachineGroup(false);
         }
      });
   };

   const AddMachineFooterTemplate = () => {
      return (
         <div className="flex justify-center">
            <Button label={t('pages.machines.addNewMachineGroup')} onClick={() => setShowAddMachineGroup(true)} className="p-button-info" />
         </div>
      );
   };

   const SupplierFooterTemplate = () => {
      return (
         <div className="flex justify-center">
            <Button label={t('pages.machines.addNewSupplier')} onClick={() => dispatch(setShowAddNewCustomer(true))} className="p-button-info" />
         </div>
      );
   };

   const handleExitAddMachineGroup = () => {
      confirmDialog({
         message: t('pages.machines.leavingAddNewMachineGroup'),
         header: t('global.warning'),
         icon: 'pi pi-exclamation-triangle',
         acceptClassName: 'p-button-danger',
         accept: () => {
            setShowAddMachineGroup(false);
         },
         reject: () => {},
      });
   };

   const handleMachineDelete = (id) => {
      confirmDialog({
         message: t('pages.machines.machineDeleteConfirmation'),
         header: t('global.warning'),
         icon: 'pi pi-exclamation-triangle',
         acceptClassName: 'p-button-danger',
         accept: () => {
            handleDelete(id);
         },
         reject: () => {},
      });
   };

   const resetVariables = () => {
      setIsFromSupplierChecked(false);
      setIsSupplier(false);
      setInformations({
         brand: '',
         deport: '',
         guaranteeDeliveryDate: null,
         guaranteeNextDate: null,
         guaranteeNextRevision: null,
         guaranteePeriod: null,
         guaranteeRemainingPeriod: null,
         length: undefined,
         machineCode: '',
         maxWarehouse: undefined,
         model: '',
         note: '',
         serialNumber: '',
         supplierCustomer: null,
         weight: undefined,
         width: undefined,
         height: undefined,
         year: '',
         vehicleFuelKind: '',
         vehicleGroup: '',
         vehicleKind: '',
      });
   };

   const handleDeleteVehicleGroup = (e, option) => {
      e.stopPropagation();
      dispatch(deleteVehicleGroups([+option.id])).then((res) => {
         const index = machineGroups.findIndex((g) => +g.id === +option.id);

         if (index !== -1) {
            const vehicleGroups = [...machineGroups];
            vehicleGroups.splice(index, 1);
            setMachineGroups(vehicleGroups);
         }
      });
   };

   const handleDeleteVehicleGroupDialog = (e, option) => {
      confirmDialog({
         message: t('pages.machines.machineDeleteConfirmation'),
         header: t('global.warning'),
         icon: 'pi pi-exclamation-triangle',
         acceptClassName: 'p-button-danger',
         accept: () => {
            handleDeleteVehicleGroup(e, option);
         },
         reject: () => {},
      });
   };

   useEffect(() => {
      if (selectedMachine && selectedMachine.supplierCustomer) {
         setIsFromSupplierChecked(true);
      }
   }, []);

   return (
      <div className="w-full h-full">
         <div className="grid">
            <div className="flex flex-wrap items-center justify-center md:col-1 col-6">
               <span>{t('pages.machines.ifSupplier')}</span>
               <InputSwitch
                  checked={props.viewMachineDetails ? isFromSupplierChecked : isSupplier}
                  onChange={(e) => {
                     if (props.viewMachineDetails) {
                        if (isFromSupplierChecked) {
                           dispatch(
                              setSelectedMachine({
                                 ...selectedMachine,
                                 supplierCustomer: null,
                              }),
                           );
                           dispatch(getMachineGroups(selectedMachine.vehicleKind.id, false, true, null, null, false)).then((res) =>
                              setMachineGroups(res.data.vehicleGroups),
                           );
                        } else {
                           dispatch(getMachineGroups(selectedMachine.vehicleKind.id, false, true, null, null, true)).then((res) =>
                              setMachineGroups(res.data.vehicleGroups),
                           );
                        }
                        dispatch(setIsFromSupplierChecked(e.value));
                     } else {
                        if (isSupplier) {
                           resetVariables();
                           setSelectedMachineGroupName(null);
                           dispatch(getMachineGroups(parseInt(informations.vehicleKind), false, false, null, null, false)).then((res) => {
                              setMachineGroups(res.data.vehicleGroups);
                           });
                        } else {
                           handleInputChange('vehicleKind', '1');
                           setInformations({ ...informations, vehicleKind: '1' });
                           dispatch(getMachineGroups(parseInt(informations.vehicleKind), false, false, null, null, true)).then((res) => {
                              setMachineGroups(res.data.vehicleGroups);
                           });
                        }
                        setIsSupplier(e.value);
                     }
                  }}
                  className="ml-2"
                  size="small"
               />
            </div>
            <div className="md:col-3 col-6">
               <label>{t('pages.machines.supplier')}*</label>
               <Dropdown
                  value={
                     props.viewMachineDetails
                        ? selectedMachine.supplierCustomer && isFromSupplierChecked
                           ? selectedMachine.supplierCustomer.id
                           : null
                        : informations.supplierCustomer
                  }
                  options={suppliers}
                  optionLabel="companyName"
                  optionValue="id"
                  onChange={(e) => {
                     if (props.viewMachineDetails) {
                        handleInputChange('supplierCustomer', { id: e.value });
                        dispatch(getMachineGroups(parseInt(selectedMachine.vehicleKind.id), false, true, null, e.value, true)).then((res) =>
                           setMachineGroups(res.data.vehicleGroups),
                        );
                     } else {
                        handleInputChange('supplierCustomer', e.value);
                        dispatch(getMachineGroups(parseInt(informations.vehicleKind), true, false, e.value, null, true)).then((res) =>
                           setMachineGroups(res.data.vehicleGroups),
                        );
                     }
                  }}
                  className={`w-full ${isFromSupplierChecked || isSupplier ? errors.supplierCustomer && 'input-error' : ''}`}
                  filter
                  disabled={props.viewMachineDetails ? !isFromSupplierChecked : !isSupplier}
                  panelFooterTemplate={SupplierFooterTemplate}
               />
               {isFromSupplierChecked || isSupplier ? errors.supplierCustomer && <small className="p-error">{errors.supplierCustomer}</small> : null}
            </div>
         </div>

         <div className="grid">
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.machineType')}*</label>
               <Dropdown
                  value={props.viewMachineDetails ? selectedMachine.vehicleKind.id : informations.vehicleKind}
                  options={machineTypes}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                     if (props.viewMachineDetails) {
                        handleInputChange('vehicleKind', { id: e.value });
                        if (isFromSupplierChecked) {
                           dispatch(
                              getMachineGroups(
                                 parseInt(e.value),
                                 false,
                                 isFromSupplierChecked,
                                 null,
                                 selectedMachine.supplierCustomer ? selectedMachine.supplierCustomer.id : null,
                                 true,
                              ),
                           ).then((res) => setMachineGroups(res.data.vehicleGroups));
                        } else {
                           dispatch(getMachineGroups(parseInt(e.value), false, false, null, null, false)).then((res) =>
                              setMachineGroups(res.data.vehicleGroups),
                           );
                        }
                     } else {
                        handleInputChange('vehicleKind', e.value);
                        if (isSupplier) {
                           dispatch(
                              getMachineGroups(
                                 parseInt(e.value),
                                 isSupplier,
                                 false,
                                 informations.supplierCustomer ? informations.supplierCustomer : null,
                                 null,
                                 true,
                              ),
                           ).then((res) => setMachineGroups(res.data.vehicleGroups));
                        } else {
                           dispatch(getMachineGroups(parseInt(e.value), false, false, null, null, false)).then((res) =>
                              setMachineGroups(res.data.vehicleGroups),
                           );
                        }
                     }
                  }}
                  className={`w-full ${errors.vehicleKind && 'input-error'}`}
                  valueTemplate={(option) => {
                     if (option) {
                        return <span>{t(`pages.machines.machineTypes.${option.name}`)}</span>;
                     }
                     return <span>&nbsp;</span>;
                  }}
                  itemTemplate={(option) => {
                     return <span>{t(`pages.machines.machineTypes.${option.name}`)}</span>;
                  }}
               />
               {errors.vehicleKind && <small className="p-error">{errors.vehicleKind}</small>}
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.machineOrToolGroup')}*</label>
               <Dropdown
                  value={props.viewMachineDetails ? selectedMachine.vehicleGroup.id : informations.vehicleGroup}
                  options={machineGroups}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                     if (props.viewMachineDetails) {
                        handleInputChange('vehicleGroup', { id: e.value });
                        setInformations({
                           ...informations,
                           vehicleGroup: e.value,
                           note: machineGroups.filter((group) => +group.id === +e.target.value)[0].description,
                        });
                     } else {
                        handleInputChange('vehicleGroup', e.value);
                        setInformations({
                           ...informations,
                           vehicleGroup: e.value,
                           note: machineGroups.filter((group) => +group.id === +e.target.value)[0].description,
                        });
                     }
                     setSelectedMachineGroupName(e.originalEvent.target.textContent);

                     const machineGroup = machineGroups.filter((group) => +group.id === +e.target.value);

                     setMacGroupDesc(machineGroup[0].description);
                  }}
                  className={`w-full ${errors.vehicleGroup && 'input-error'}`}
                  panelFooterTemplate={AddMachineFooterTemplate}
                  filter
                  itemTemplate={(option) => {
                     return (
                        <div className="flex justify-between">
                           <span>{option.name}</span>
                           <Button
                              className="h-4 "
                              severity="danger"
                              onClick={(e) => {
                                 handleDeleteVehicleGroupDialog(e, option);
                                 /*   handleDeleteVehicleGroup(e, option); */
                              }}
                           >
                              x
                           </Button>
                        </div>
                     );
                  }}
               />
               {errors.vehicleGroup && <small className="p-error">{errors.vehicleGroup}</small>}
            </div>
            {/* {
               <div className="md:col-4 col-12">
                  <label>{t('pages.machines.description')}</label>
                  <InputText
                     value={macGroupDesc}
                     onChange={(e) => {
                        setMacGroupDesc(e.target.value);
                     }}
                     className={`w-full ${errors.macGroupDesc && 'input-error'}`}
                  />
                  {errors.macGroupDesc && <small className="p-error">{errors.macGroupDesc}</small>}
               </div>
            } */}
            <div className="md:col-4 col-12">
               <label>Description</label>
               <InputText
                  value={props.viewMachineDetails ? selectedMachine.note : informations.note}
                  onChange={(e) => {
                     handleInputChange('note', e.target.value);
                  }}
                  className={`w-full ${errors.note && 'input-error'}`}
               />
               {errors.note && <small className="p-error">{errors.note}</small>}
            </div>
            <div className="col-12 md:col-4">
               <label>{t('pages.machines.machineCode')}*</label>
               <div className="p-inputgroup">
                  <span className="p-inputgroup-addon w-[1/4]">
                     {props.viewMachineDetails
                        ? selectedMachine.vehicleGroup.name
                           ? selectedMachine.vehicleGroup.name
                           : selectedMachineGroupName
                        : selectedMachineGroupName}
                  </span>
                  <InputText
                     value={props.viewMachineDetails ? selectedMachine.machineCode : informations.machineCode}
                     onChange={(e) => {
                        handleInputChange('machineCode', e.target.value);
                     }}
                     className={`w-[3/4] ${errors.machineCode && 'input-error'}`}
                  />
               </div>
               {errors.machineCode && <small className="p-error">{errors.machineCode}</small>}
            </div>

            <div className="md:col-4 col-12">
               <label>{t('pages.machines.serialNumber')}</label>
               <InputText
                  value={props.viewMachineDetails ? selectedMachine.serialNumber : informations.serialNumber}
                  onChange={(e) => {
                     handleInputChange('serialNumber', e.target.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.brand')}</label>
               <InputText
                  value={props.viewMachineDetails ? selectedMachine.brand : informations.brand}
                  onChange={(e) => {
                     handleInputChange('brand', e.target.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.model')}</label>
               <InputText
                  value={props.viewMachineDetails ? selectedMachine.model : informations.model}
                  onChange={(e) => {
                     handleInputChange('model', e.target.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.year')}</label>
               <InputText
                  maxLength={4}
                  type="number"
                  value={props.viewMachineDetails ? selectedMachine.year : informations.year}
                  onChange={(e) => {
                     handleInputChange('year', e.target.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.fuelType')}*</label>
               <Dropdown
                  value={props.viewMachineDetails ? selectedMachine.vehicleFuelKind.id : informations.vehicleFuelKind}
                  options={fuelTypes}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                     if (props.viewMachineDetails) {
                        handleInputChange('vehicleFuelKind', { id: e.value });
                     } else {
                        handleInputChange('vehicleFuelKind', e.value);
                     }
                  }}
                  className={`w-full ${errors.vehicleFuelKind && 'input-error'}`}
                  valueTemplate={(option) => {
                     if (option) {
                        return <span>{t(`pages.machines.fuelTypes.${option.name}`)}</span>;
                     }
                     return <span>&nbsp;</span>;
                  }}
                  itemTemplate={(option) => {
                     return <span>{t(`pages.machines.fuelTypes.${option.name}`)}</span>;
                  }}
               />
               {errors.vehicleFuelKind && <small className="p-error">{errors.vehicleFuelKind}</small>}
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.lengthSize')}</label>
               <InputNumber
                  value={props.viewMachineDetails ? (selectedMachine.length === '0.00' ? null : selectedMachine.length) : informations.length}
                  onChange={(e) => {
                     handleInputChange('length', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.widthSize')}</label>
               <InputNumber
                  value={props.viewMachineDetails ? (selectedMachine.width === '0.00' ? null : selectedMachine.width) : informations.width}
                  onChange={(e) => {
                     handleInputChange('width', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.heightSize')}</label>
               <InputNumber
                  value={props.viewMachineDetails ? (selectedMachine.height === '0.00' ? null : selectedMachine.height) : informations.height}
                  onChange={(e) => {
                     handleInputChange('height', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.weight')}</label>
               <InputNumber
                  value={props.viewMachineDetails ? (selectedMachine.weight === '0.00' ? null : selectedMachine.weight) : informations.weight}
                  onChange={(e) => {
                     handleInputChange('weight', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.deport')}</label>
               <InputText
                  value={props.viewMachineDetails ? (selectedMachine.deport === '0' ? null : selectedMachine.deport) : informations.deport}
                  onChange={(e) => {
                     handleInputChange('deport', e.target.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="md:col-4 col-12">
               <label>{t('pages.machines.maxWarehouse')}</label>
               <InputText
                  value={props.viewMachineDetails ? (selectedMachine.maxWarehouse === '0.00' ? null : selectedMachine.maxWarehouse) : informations.maxWarehouse}
                  onChange={(e) => {
                     handleInputChange('maxWarehouse', e.target.value);
                  }}
                  className="w-full"
               />
            </div>
         </div>
         <div className="grid">
            <Divider align="left">
               <div className="inline-flex items-center">
                  <span className="font-semibold text-md">{t('pages.machines.guaranteeInformations')}</span>
               </div>
            </Divider>
         </div>
         <div className="grid">
            <div className="flex flex-wrap md:col-4 col-12">
               <label> {t('pages.machines.deliveryDate')}</label>
               <Calendar
                  value={
                     props.viewMachineDetails
                        ? selectedMachine.guaranteeDeliveryDate
                           ? new Date(selectedMachine.guaranteeDeliveryDate)
                           : null
                        : informations.guaranteeDeliveryDate
                  }
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                     handleInputChange('guaranteeDeliveryDate', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="flex flex-wrap md:col-4 col-12">
               <label> {t('pages.machines.nextVgp')}</label>
               <Calendar
                  value={
                     props.viewMachineDetails
                        ? selectedMachine.guaranteeNextDate
                           ? new Date(selectedMachine.guaranteeNextDate)
                           : null
                        : informations.guaranteeNextDate
                  }
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                     handleInputChange('guaranteeNextDate', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="flex flex-wrap md:col-4 col-12">
               <label> {t('pages.machines.nextRevision')}</label>
               <Calendar
                  value={
                     props.viewMachineDetails
                        ? selectedMachine.guaranteeNextRevision
                           ? new Date(selectedMachine.guaranteeNextRevision)
                           : null
                        : informations.guaranteeNextRevision
                  }
                  dateFormat="dd/mm/yy"
                  onChange={(e) => {
                     handleInputChange('guaranteeNextRevision', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="flex flex-wrap md:col-4 col-12">
               <label> {t('pages.machines.guaranteePeriod')}</label>
               <InputNumber
                  value={props.viewMachineDetails ? (selectedMachine.guaranteePeriod ? selectedMachine.guaranteePeriod : null) : informations.guaranteePeriod}
                  onChange={(e) => {
                     handleInputChange('guaranteePeriod', e.value);
                  }}
                  className="w-full"
               />
            </div>
            <div className="flex flex-wrap md:col-4 col-12">
               <label>{t('pages.machines.remainingPeriod')}</label>
               <InputNumber
                  value={monthSubtraction() < 0 ? null : monthSubtraction()}
                  className="w-full"
                  disabled
                  onClick={() => {
                     if (moment(selectedMachine.guaranteeDeliveryDate).format('YYYY-MM-DD') < moment(new Date()).format('YYYY-MM-DD')) {
                        console.log(
                           selectedMachine.guaranteePeriod -
                              parseFloat(((new Date() - new Date(selectedMachine.guaranteeDeliveryDate)) / (1000 * 60 * 60 * 24 * 31)).toFixed(2)) +
                              1,
                        );
                     } else {
                        console.log(moment(selectedMachine.guaranteeDeliveryDate).format('YYYY-MM-DD'));
                     }
                  }}
               />
            </div>
         </div>
         <div className="grid justify-end mt-5">
            {props.viewMachineDetails ? (
               <Button
                  className="col-12 lg:col-2 md:col-3 md:mr-3"
                  label={t('global.delete')}
                  size="small"
                  style={{ background: 'red', border: 'none' }}
                  onClick={() => handleMachineDelete(selectedMachineId)}
               />
            ) : null}

            <Button
               label={props.viewMachineDetails ? t('global.update') : t('global.saveAndContinue')}
               className="mt-3 col-12 lg:col-2 md:col-3 md:mt-0"
               size="small"
               onClick={() => handleSave()}
            />
         </div>
         <Dialog
            visible={showAddNewCustomer}
            onHide={() => {
               dispatch(setShowAddSupplier(false));
               dispatch(setNewCustomerStepCounter(0));
               dispatch(setSelectedCustomerId(null));
            }}
            draggable={false}
            className="md-width-dialog"
            header={t('pages.machines.createNewSupplier')}
         >
            <AddNewCustomer createNewSupplier={true} />
         </Dialog>
         <Dialog
            visible={showAddMachineGroup}
            onHide={() => {
               setNewMachineGroup({ vehicleKindId: '', name: '' });
               handleExitAddMachineGroup();
            }}
            draggable={false}
            className="md-width-dialog"
            header={t('pages.machines.createNewMachineGroup')}
         >
            <div className="grid">
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.machines.machineType')}</label>
                  <Dropdown
                     options={machineTypes}
                     optionLabel="name"
                     optionValue="id"
                     value={newMachineGroup?.vehicleKindId}
                     onChange={(e) =>
                        setNewMachineGroup({
                           ...newMachineGroup,
                           vehicleKindId: e.value,
                        })
                     }
                     className="w-full"
                     valueTemplate={(option) => {
                        if (option) {
                           return <span>{t(`pages.machines.machineTypes.${option.name}`)}</span>;
                        }
                        return <span>&nbsp;</span>;
                     }}
                     itemTemplate={(option) => {
                        return <span>{t(`pages.machines.machineTypes.${option.name}`)}</span>;
                     }}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.machines.name')}</label>
                  <InputText
                     value={newMachineGroup?.name}
                     onClick={() => console.log(newMachineGroup)}
                     onChange={(e) => setNewMachineGroup({ ...newMachineGroup, name: e.target.value })}
                     className="w-full"
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.machines.description')}</label>
                  <InputText
                     value={newMachineGroup?.description}
                     onClick={() => console.log(newMachineGroup)}
                     onChange={(e) => {
                        setNewMachineGroup({
                           ...newMachineGroup,
                           description: e.target.value,
                        });
                     }}
                     className="w-full"
                  />
               </div>
            </div>
            <div className="grid mt-3">
               <div className="flex justify-end col-12">
                  <Button label={t('global.continue')} onClick={() => handleSaveNewMachineGroup()} icon="pi pi-check" size="small" />
               </div>
            </div>
         </Dialog>

         <ConfirmDialog />
      </div>
   );
};

export default Informations;
