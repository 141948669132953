import React, { useEffect, useState } from "react";

import TableComponent from "../../../components/TableComponent";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import toast from "react-hot-toast";
import {
  addContact,
  addCustomer,
  setNewCustomerStepCounter,
  setPreviousButton,
  setSelectedCustomerId,
} from "../../../store/slices/customerSlice";
import { InputTextarea } from "primereact/inputtextarea";
import {
  getTitles,
  getCompanyContacts,
  createCustomer,
  updateCustomerContact,
  createCustomerContact,
  createCustomerAddress,
} from "../../../store/apis/Customers";

const Contacts = ({ informationsInputs }) => {
  const dispatch = useDispatch();

  const { selectedCustomerId, sameAddress, previousButton } = useSelector(
    (state) => state.customer
  );

  const { showViewMachineDetailsDialog, showAddNewMachineDialog } = useSelector(
    (state) => state.machine
  );

  // const [contacts, setContacts] = useState([]);
  const { contacts } = useSelector((state) => state.customer);
  const [showContactDetails, setShowContactDetails] = useState(false);

  const [titles, setTitles] = useState();

  const [contactsInputs, setContactsInputs] = useState({
    title: null,
    firstName: "",
    lastName: "",
    role: "",
    phone: "",
    email: "",
    description: "",
  });

  const [selectedContact, setSelectedContact] = useState();

  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(getTitles()).then((res) => setTitles(res));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCustomerId) {
      dispatch(getCompanyContacts(selectedCustomerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = [
    {
      name: "fullName",
      header: t("global.table.fullName"),
      body: (rowData) => {
        return (
          <span>
            {rowData.firstName} {rowData.lastName}
          </span>
        );
      },
    },
    {
      name: "role",
      header: t("global.table.role"),
      field: "role",
    },
    {
      name: "phone",
      header: t("global.table.phoneNumber"),
      field: "phone",
    },
    {
      name: "email",
      header: t("global.table.email"),
      field: "email",
    },
    {
      name: "comments",
      header: t("global.table.comment"),
      field: "description",
    },
  ];

  const handleRowClick = (e) => {
    console.log(e);
    setSelectedContact(e);
    setShowContactDetails(true);
  };

  // FORM VALIDATION
  const updateErrors = (name, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value ? t("global.requiredField") : "",
    }));
  };

  const handleInputChange = (name, value) => {
    if (selectedContact) {
      setSelectedContact({ ...selectedContact, [name]: value });
    }
    setContactsInputs({
      ...contactsInputs,
      [name]: value,
    });
    updateErrors(name, value);
  };

  const validateForm = () => {
    const inputs = ["title", "firstName", "lastName", "role", "phone", "email"];
    let isValid = true;
    if (selectedContact) {
      inputs.forEach((input) => {
        if (!selectedContact[input]) {
          updateErrors(input, selectedContact[input]);
          isValid = false;
          console.log(input);
        }
      });
    } else {
      inputs.forEach((input) => {
        if (!contactsInputs[input]) {
          updateErrors(input, contactsInputs[input]);
          isValid = false;
          console.log(input);
        }
      });
    }
    return isValid;
  };

  const handleSave = () => {
    if (contacts.length === 0) {
      toast.error("You must atleast have 1 contact before continue!");
      return 0;
    }
    dispatch(setNewCustomerStepCounter(2));
  };

  const handleContactSave = async () => {
    if (!selectedCustomerId) {
      if (validateForm()) {
        await dispatch(
          createCustomer(
            showAddNewMachineDialog,
            showViewMachineDetailsDialog,
            informationsInputs
          )
        ).then((res) => {
          if (res) {
            let customerData = res.data.createCustomer.customer;
            dispatch(
              setSelectedCustomerId(res.data.createCustomer.customer.id)
            );
            dispatch(
              createCustomerAddress(
                informationsInputs,
                res.data.createCustomer.customer.id,
                sameAddress
              )
            ).then((res) => {
              customerData = { ...customerData, addresses: res };
              /*  customerData.addresses.push(res); */

              dispatch(addCustomer(customerData));
              toast.success(t("pages.customers.customerCreatedSuccessfully"));
            });

            dispatch(
              createCustomerContact(
                contactsInputs,
                res.data.createCustomer.customer.id
              )
            ).then((res) => {
              toast.success(t("pages.customers.contactCreatedSuccesfully"));
              resetNewContactVariables();
              setShowContactDetails(false);
              setSelectedContact(null);
            });
          }
        });
      }
    } else {
      if (selectedContact) {
        if (validateForm()) {
          console.log(selectedCustomerId);
          dispatch(
            updateCustomerContact(selectedContact, selectedCustomerId)
          ).then((res) => {
            console.log(res);
            resetNewContactVariables();
            toast.success(t("global.updateSuccess"));
            setShowContactDetails(false);
            setSelectedContact(null);
          });
        }
      } else {
        if (validateForm()) {
          dispatch(
            createCustomerContact(contactsInputs, selectedCustomerId)
          ).then((res) => {
            console.log(res);
            resetNewContactVariables();
            toast.success("Contact created successfully!");
            setShowContactDetails(false);
            setSelectedContact(null);
          });
        }
      }
    }
  };

  const resetNewContactVariables = () => {
    setContactsInputs({
      titles: null,
      title: null,
      firstName: "",
      lastName: "",
      role: "",
      phone: "",
      email: "",
      description: "",
    });
  };

  return (
    <div className="w-full h-full">
      <div className="flex justify-end w-full mb-3">
        <Button
          icon="pi pi-plus"
          label={t("pages.customers.addNewContact")}
          onClick={() => {
            setShowContactDetails(true);
          }}
          size="small"
        />
      </div>
      <TableComponent
        data={contacts}
        columns={tableColumns}
        size="medium"
        onRowClick={(e) => handleRowClick(e)}
      />
      <div className="flex justify-end mt-3">
        <Button
          className="mr-auto"
          label={t("global.previous")}
          onClick={() => {
            if (selectedCustomerId) {
              dispatch(setPreviousButton(true));
            }
            dispatch(setNewCustomerStepCounter(0));
          }}
        ></Button>
        <Button
          icon="pi pi-check"
          label={t("global.saveAndContinue")}
          onClick={() => handleSave()}
        />
      </div>
      <Dialog
        visible={showContactDetails}
        draggable={false}
        onHide={() => {
          setShowContactDetails(false);
          resetNewContactVariables();
          setSelectedContact(null);
        }}
        header={
          selectedContact
            ? t("pages.customers.contactDetails")
            : t("pages.customers.addNewContact")
        }
        className="md-width-dialog"
      >
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("global.table.honoraryTitle")}*</label>
            <Dropdown
              options={titles}
              optionLabel="name"
              optionValue="id"
              onChange={(e) => {
                if (selectedContact) {
                  handleInputChange("title", { id: e.value });
                } else {
                  handleInputChange("title", e.value);
                }
              }}
              value={
                selectedContact
                  ? selectedContact.title.id
                  : contactsInputs.title
              }
              showClear={!selectedContact}
              className={`w-full input-detail ${errors.title && "input-error"}`}
            />
            {errors.title && <small className="p-error">{errors.title}</small>}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("global.table.firstName")}*</label>
            <InputText
              value={
                selectedContact
                  ? selectedContact.firstName
                  : contactsInputs.firstName
              }
              onChange={(e) => {
                handleInputChange("firstName", e.target.value);
              }}
              className={`w-full input-detail ${
                errors.firstName && "input-error"
              }`}
            />
            {errors.firstName && (
              <small className="p-error">{errors.firstName}</small>
            )}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("global.table.lastName")}*</label>
            <InputText
              value={
                selectedContact
                  ? selectedContact.lastName
                  : contactsInputs.lastName
              }
              onChange={(e) => {
                handleInputChange("lastName", e.target.value);
              }}
              className={`w-full input-detail ${
                errors.lastName && "input-error"
              }`}
            />
            {errors.lastName && (
              <small className="p-error">{errors.lastName}</small>
            )}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label>{t("global.table.role")}*</label>
            <InputText
              value={
                selectedContact ? selectedContact.role : contactsInputs.role
              }
              onChange={(e) => {
                handleInputChange("role", e.target.value);
              }}
              className={`w-full input-detail ${errors.role && "input-error"}`}
            />
            {errors.role && <small className="p-error">{errors.role}</small>}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label className="w-full">{t("global.table.phoneNumber")}*</label>
            <InputText
              value={
                selectedContact ? selectedContact.phone : contactsInputs.phone
              }
              onChange={(e) => {
                handleInputChange("phone", e.target.value);
              }}
              className={`w-full input-detail ${errors.phone && "input-error"}`}
            />
            {errors.phone && <small className="p-error">{errors.phone}</small>}
          </div>
          <div className="col-12 md:col-6 lg:col-6">
            <label className="w-full">{t("global.table.email")}*</label>
            <InputText
              type="email"
              value={
                selectedContact ? selectedContact.email : contactsInputs.email
              }
              onChange={(e) => {
                handleInputChange("email", e.target.value);
              }}
              className={`w-full input-detail ${errors.email && "input-error"}`}
            />
            {errors.email && <small className="p-error">{errors.email}</small>}
          </div>
          <div className="col-12">
            <label>{t("global.table.explanation")}</label>
            <InputTextarea
              value={
                selectedContact
                  ? selectedContact.description
                  : contactsInputs.description
              }
              onChange={(e) => {
                handleInputChange("description", e.target.value);
              }}
              className="w-full input-detail"
            />
          </div>
        </div>
        <div className="grid mt-3">
          <div className="flex justify-end col-12">
            <Button
              label={selectedContact ? t("global.update") : t("global.create")}
              icon="pi pi-check"
              onClick={() => handleContactSave()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Contacts;
