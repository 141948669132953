import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../components/TableComponent';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getFilteredFinanceStatistics } from '../../store/apis/SharedApi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const TotalRevenueFromCustomers = (props) => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [tableData, setTableData] = useState([]);
   const [filterVisible, setFilterVisible] = useState(false);
   const [startDate, setStartDate] = useState(moment().subtract(12, 'months').format('YYYY-MM-DD'));
   const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
   const [selectedCustomer, setSelectedCustomer] = useState('');

   useEffect(() => {
      const _tableData = [];
      for (var key in props.data) {
         _tableData.push({
            customer: key,
            rent: props.data[key].RENT.toFixed(2),
            transport: props.data[key].TRANSPORT.toFixed(2),
            fuelSales: props.data[key].FUEL_SALES.toFixed(2),
            ecoFee: props.data[key].ECO_FEE.toFixed(2),
            insurance: props.data[key].INSURANCE.toFixed(2),
            otherSales: props.data[key].OTHER_SALES.toFixed(2),
            refund: props.data[key].REFUND.toFixed(2),
         });
      }
      setTableData(_tableData);
   }, []);

   const handleFilter = async () => {
      const res = await dispatch(
         getFilteredFinanceStatistics({
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
         }),
      );

      if (res.TOTAL_REVENUE_FROM_CUSTOMERS && Object.keys(res.TOTAL_REVENUE_FROM_CUSTOMERS).length !== 0) {
         const _tableData = [];
         for (var key in res.TOTAL_REVENUE_FROM_CUSTOMERS) {
            _tableData.push({
               customer: key,
               rent: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].RENT.toFixed(2),
               transport: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].TRANSPORT.toFixed(2),
               fuelSales: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].FUEL_SALES.toFixed(2),
               ecoFee: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].ECO_FEE.toFixed(2),
               insurance: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].INSURANCE.toFixed(2),
               otherSales: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].OTHER_SALES.toFixed(2),
               refund: res.TOTAL_REVENUE_FROM_CUSTOMERS[key].REFUND.toFixed(2),
            });
         }
         setTableData(_tableData);
      } else {
         setTableData([]);
      }
      setFilterVisible(false);
   };

   // Excel export
   const exportExcel = () => {
      const _data =
         tableData &&
         tableData.length > 0 &&
         tableData.map((item) => {
            return {
               ...item,
            };
         });
      import('xlsx').then((xlsx) => {
         const worksheet = xlsx.utils.json_to_sheet(_data);
         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
         import('file-saver').then((module) => {
            if (module && module.default) {
               const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
               const EXCEL_EXTENSION = '.xlsx';
               const data = new Blob([excelBuffer], {
                  type: EXCEL_TYPE,
               });
               module.default.saveAs(data, 'total-revenue-from-customer' + '_' + new Date().getTime() + EXCEL_EXTENSION);
            }
         });
      });
   };

   return (
      <>
         <div className="mb-3 mt-2 flex justify-between">
            <div>
               <Button onClick={() => setFilterVisible(true)} label={t('global.filter')} severity="success" className="w-[10vw] min-w-[100px] h-[38px] me-2" />
               <InputText
                  value={selectedCustomer}
                  onChange={(e) => setSelectedCustomer(e.target.value)}
                  placeholder={t('pages.machines.search')}
                  className="input-detail"
               />
            </div>
            <Button onClick={exportExcel} icon="pi pi-file-excel" severity="success" aria-label="exel" />
         </div>

         <DataTable
            value={selectedCustomer ? tableData.filter((item) => item.customer.toLowerCase().includes(selectedCustomer.toLowerCase())) : tableData}
            scrollable
            className="mt-4"
            size="small"
            stripedRows
            showGridlines
         >
            <Column field="customer" header={t('global.sidebar.customer')} frozen />
            <Column
               field="rent"
               header={t('pages.planning.rent')}
               body={(rowData) => {
                  return <div>{rowData.rent.replace('.', ',')} €</div>;
               }}
            />
            <Column
               field="transport"
               header={t('pages.dashboard.transport')}
               body={(rowData) => {
                  return <div>{rowData.transport.replace('.', ',')} €</div>;
               }}
            />
            <Column
               field="fuelSales"
               header={t('pages.dashboard.fuelSales')}
               body={(rowData) => {
                  return <div>{rowData.fuelSales.replace('.', ',')} €</div>;
               }}
            />
            <Column
               field="ecoFee"
               header={t('pages.contracts.ecoFee')}
               body={(rowData) => {
                  return <div>{rowData.ecoFee.replace('.', ',')} €</div>;
               }}
            />
            <Column
               field="insurance"
               header={t('pages.offers.insurance')}
               body={(rowData) => {
                  return <div>{rowData.insurance.replace('.', ',')} €</div>;
               }}
            />
            <Column
               field="otherSales"
               header={t('pages.dashboard.otherSales')}
               body={(rowData) => {
                  return <div>{rowData.otherSales.replace('.', ',')} €</div>;
               }}
            />
            <Column
               field="refund"
               header={t('pages.invoices.refund')}
               body={(rowData) => {
                  return <div>{rowData.refund.replace('.', ',')} €</div>;
               }}
            />
         </DataTable>

         {/* <TableComponent
            data={selectedCustomer ? tableData.filter((item) => item.customer.toLowerCase().includes(selectedCustomer.toLowerCase())) : tableData}
            showGridlines={true}
            columns={[
               {
                  name: 'custormer',

                  header: t('global.sidebar.customer'),
                  body: (rowData) => {
                     return <span>{rowData.customer}</span>;
                  },
               },
               {
                  name: 'rent',
                  header: t('pages.planning.rent'),
                  body: (rowData) => {
                     return <div>{rowData.rent} €</div>;
                  },
               },
               {
                  name: 'transport',
                  header: t('pages.dashboard.transport'),
                  body: (rowData) => {
                     return <div>{rowData.transport} €</div>;
                  },
               },
               {
                  name: 'fuelSales',
                  header: t('pages.dashboard.fuelSales'),
                  body: (rowData) => {
                     return <div>{rowData.fuelSales} €</div>;
                  },
               },
               {
                  name: 'ecoFee',
                  header: t('pages.contracts.ecoFee'),
                  body: (rowData) => {
                     return <div>{rowData.ecoFee} €</div>;
                  },
               },
               {
                  name: 'insurance',
                  header: t('pages.offers.insurance'),
                  body: (rowData) => {
                     return <div>{rowData.insurance} €</div>;
                  },
               },
               {
                  name: 'otherSales',
                  header: t('pages.dashboard.otherSales'),
                  body: (rowData) => {
                     return <div>{rowData.otherSales} €</div>;
                  },
               },
               {
                  name: 'refund',
                  header: t('pages.invoices.refund'),
                  body: (rowData) => {
                     return <div>{rowData.refund} €</div>;
                  },
               },
            ]}
            size="small"
         /> */}

         <Dialog visible={filterVisible} onHide={() => setFilterVisible(false)} style={{ width: '500px' }}>
            <div className="grid">
               <div className="col-6">
                  <span>{t('pages.contracts.startDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(startDate)}
                     onChange={(e) => setStartDate(e.target.value)}
                  />
               </div>

               <div className="col-6">
                  <span>{t('pages.contracts.endDate')}</span>
                  <Calendar
                     className="input-detail w-full"
                     name="receptionDate"
                     dateFormat="dd/mm/yy"
                     value={new Date(endDate)}
                     onChange={(e) => setEndDate(e.target.value)}
                  />
               </div>

               <div className="col-12">
                  <Button severity="success" onClick={handleFilter} label={t('global.filter')} className="w-full  mt-3" />
               </div>
            </div>
         </Dialog>
      </>
   );
};

export default TotalRevenueFromCustomers;
