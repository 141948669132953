import React, { useEffect, useState } from 'react';

import TableComponent from '../../components/TableComponent';

import { t } from 'i18next';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';

import { useDispatch, useSelector } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import moment from 'moment';
import ViewMachineDetails from './ViewMachineDetails';
import {
   setSelectedMachineId,
   setShowAddNewMachineDialog,
   setStepCounter,
   setShowViewMachineDetailsDialog,
   setSelectedMachine,
   setIsFromSupplierChecked,
} from '../../store/slices/machineSlice';
import AddNewMachine from './AddNewMachine';

import { getVehicleGroups, getVehicleFuelKinds, getSuppliers, getAllMachineGroups, getMachine, getVehicleStatuses } from '../../store/apis/TCardApi';
import { Card } from 'primereact/card';
import { setVehicleReservations } from '../../store/slices/planningSlice';
import { useLocation } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';

const TCard = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const { showViewMachineDetailsDialog, showAddNewMachineDialog, vehicleGroups } = useSelector((state) => state.machine);
   const { showSidebar } = useSelector((state) => state.shared);

   const [suppliers, setSuppliers] = useState();
   const [fuelKinds, setVehicleFuelKinds] = useState();

   const [searchKeyword, setsearchKeyword] = useState('');

   // table
   const [rows, setRows] = useState(30);
   const [first, setFirst] = useState(0);
   const [limitValue, setLimitValue] = useState(30);

   const [filters, setFilters] = useState(null);
   const [showFilters, setShowFilters] = useState(false);
   const [nextVgp, setNextVgp] = useState(false);

   const [vehicleGroupsCount, setVehicleGroupsCount] = useState();

   const [allMachineGroups, setAllMachineGroups] = useState();

   const [reservationNumber, setReservationNumber] = useState(0);
   const [malfunctionNumber, setMalfunctionNumber] = useState(0);
   const [vehicleNumber, setVehicleNumber] = useState(0);

   const [notCompletedMalfunction, setNotCompletedMalfunction] = useState(false);
   const [vehicleStatuses, setVehicleStatuses] = useState({});

   async function fetchVehicleGroups() {
      await dispatch(getVehicleGroups(rows, first, searchKeyword, nextVgp, filters, filters ? true : false)).then((res) => {
         setVehicleGroupsCount(res.vehicleGroupCount);
         setVehicleNumber(res.vehicle_number);
      });
   }

   useEffect(() => {
      if (showViewMachineDetailsDialog === false || showViewMachineDetailsDialog === true || showAddNewMachineDialog === false) {
         fetchVehicleGroups();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showViewMachineDetailsDialog, showAddNewMachineDialog]);

   useEffect(() => {
      dispatch(getVehicleFuelKinds()).then((res) => setVehicleFuelKinds(res));
      dispatch(getSuppliers()).then((res) => setSuppliers(res));
      dispatch(getAllMachineGroups()).then((res) => setAllMachineGroups(res));
      dispatch(getVehicleStatuses()).then((res) => setVehicleStatuses(res));
   }, []);

   useEffect(() => {
      fetchVehicleGroups();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rows, first]);

   const tableColumns = [
      {
         name: 'groupCode',
         header: t('global.table.machineGroup'),
         bodyClassName: 'w-[100px] bg-[#d4d4d4]',
         body: (rowData) => {
            return (
               <div className="flex p-0 m-0" style={{ fontWeight: 1000 }}>
                  <div className={`p-0 m-[1px] w-[150px]`}>
                     {rowData.name} ({rowData.vehicles.length})
                  </div>
               </div>
            );
         },
      },
      {
         name: 'groupCodes',
         header: t('global.table.machineCode'),
         body: (rowData) => {
            const arrayForSort = [...rowData.vehicles];

            const orderedData = arrayForSort.sort(function (a, b) {
               if (a.machineFullCode < b.machineFullCode) {
                  return -1;
               }
               if (a.machineFullCode > b.machineFullCode) {
                  return 1;
               }
               return 0;
            });
            return (
               <div className="flex p-0 m-0" style={{ fontWeight: 600 }}>
                  {orderedData.map((vehicle, index) => (
                     <div
                        onClick={() => handleShowDetails(vehicle)}
                        style={{
                           background: `${
                              vehicle.vehicleMalfunctions.length > 0
                                 ? vehicle.vehicleMalfunctions.some((malfunction) => !malfunction.isCompleted)
                                    ? '#ef5d4e'
                                    : vehicle.vehicleReservations.length > 0
                                    ? vehicle.vehicleReservations.some(
                                         (reservation) => reservation.status === 'RENT' || reservation.status === 'READY_TO_DEPART',
                                      )
                                       ? '#f0ad4e'
                                       : vehicle.vehicleReservations.some((reservation) => reservation.status === 'INSPECTION')
                                       ? '#e8e824'
                                       : vehicle.vehicleReservations.some((reservation) => reservation.status === 'RETURN_PLANED')
                                       ? '#8eb3f9'
                                       : vehicle.vehicleReservations.some((reservation) => reservation.status === 'RESERVE')
                                       ? '#f5b0b0'
                                       : '#85de7f'
                                    : '#85de7f'
                                 : vehicle.vehicleReservations.length > 0
                                 ? vehicle.vehicleReservations.some((reservation) => reservation.status === 'RENT' || reservation.status === 'READY_TO_DEPART')
                                    ? '#f0ad4e'
                                    : vehicle.vehicleReservations.some((reservation) => reservation.status === 'INSPECTION')
                                    ? '#e8e824'
                                    : vehicle.vehicleReservations.some((reservation) => reservation.status === 'RETURN_PLANED')
                                    ? '#8eb3f9'
                                    : vehicle.vehicleReservations.some((reservation) => reservation.status === 'RESERVE')
                                    ? '#f5b0b0'
                                    : '#85de7f'
                                 : '#85de7f'
                           }`,
                        }}
                        className={`p-0 m-[1px] w-[150px]`}
                        key={index}
                     >
                        <div className="h-[25px]" style={{ wordWrap: 'break-word', textAlign: 'center' }}>
                           {vehicle.machineFullCode.replace(/\s/g, '')}
                        </div>
                        <div className="flex justify-end mr-2 h-[20px]" style={{ fontSize: 10 }}>
                           {vehicle.guaranteeNextDate
                              ? `VGP(${parseInt(parseFloat(((new Date(vehicle.guaranteeNextDate) - new Date()) / (1000 * 60 * 60 * 24)).toFixed(2)))})`
                              : null}
                        </div>
                     </div>
                  ))}
               </div>
            );
         },
         bodyClassName: 'p-0 m-0',
      },
   ];

   const handleShowDetails = (machine) => {
      dispatch(setSelectedMachine(machine));
      dispatch(setSelectedMachineId(machine.id));
      dispatch(setIsFromSupplierChecked(machine.supplierCustomerId));
      dispatch(setShowViewMachineDetailsDialog(true));
   };

   useEffect(() => {
      if (!location.state?.machineId) return;
      dispatch(getMachine(+location.state?.machineId)).then((res) => {
         if (res) {
            handleShowDetails(res);
         }
      });

      const onbeforeunload = () => {
         //clear location state
         window.history.replaceState(null, '');
      };
      window.addEventListener('beforeunload', onbeforeunload);
      return () => {
         window.removeEventListener('beforeunload', onbeforeunload);
      };
   }, [location.state]);

   const tableOptions = [
      { label: t('pages.planning.inventory'), value: 'inventory' },
      { label: t('pages.planning.supplier'), value: 'supplier' },
   ];
   const [selectedTable, setSelectedTable] = useState('inventory');

   return (
      <>
         <div className="w-full h-full">
            <div className="grid !m-0 !p-0">
               <div className="p-1 col-12 md:col-4">
                  <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
                     <div className="border-2 border-blue-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                        <i className="pi pi-user text-blue-500 text-[20px]"></i>
                     </div>
                     <div className="ml-4">
                        <div className="font-bold">{vehicleStatuses.TOTAL_MACHINE}</div>
                        <div className="font-light text-gray-500 text-[14px]">{t('pages.machines.totalMachines')}</div>
                     </div>
                  </div>
               </div>
               <div className="p-1 col-12 md:col-4">
                  <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
                     <div className="border-2 border-green-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                        <i className="pi pi-user text-green-500 text-[20px]"></i>
                     </div>
                     <div className="ml-4">
                        <div className="font-bold">{vehicleStatuses.TOTAL_ACTIVE_MACHINE}</div>
                        <div className="font-light text-gray-500 text-[14px]">{t('pages.machines.totalActiveMachines')}</div>
                     </div>
                  </div>
               </div>

               <div className="p-1 col-12 md:col-4">
                  <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
                     <div className="border-2 border-red-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                        <i className="pi pi-trash text-red-500 text-[20px]"></i>
                     </div>
                     <div className="ml-4">
                        <div className="font-bold">{vehicleStatuses.TOTAL_DELETED_MACHINE}</div>
                        <div className="font-light text-gray-500 text-[14px]">{t('pages.machines.totalDeletedMachines')}</div>
                     </div>
                  </div>
               </div>

               <div className="p-1 col-12 md:col-4">
                  <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
                     <div className="border-2 border-red-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                        <i className="pi pi-car text-red-500 text-[20px]"></i>
                     </div>
                     <div className="ml-4">
                        <div className="font-bold">{vehicleStatuses.TOTAL_MALFUNCTIONED_MACHINE}</div>
                        <div className="font-light text-gray-500 text-[14px]">{t('pages.machines.totalMalfunctionedMachine')}</div>
                     </div>
                  </div>
               </div>

               <div className="p-1 col-12 md:col-4">
                  <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
                     <div className="border-2 border-blue-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                        <i className="pi pi-car text-blue-500 text-[20px]"></i>
                     </div>
                     <div className="ml-4">
                        <div className="font-bold">{vehicleStatuses.TOTAL_RENTED_MACHINE}</div>
                        <div className="font-light text-gray-500 text-[14px]">{t('pages.machines.totalRentedMachine')}</div>
                     </div>
                  </div>
               </div>

               {/*   <div className="p-1 col-12 md:col-4">
            <div className="flex items-center h-full p-3 bg-white rounded-lg shadow-md">
              <div className="border-2 border-purple-500 rounded-full p-3 w-[60px] h-[60px] flex justify-center items-center">
                <i className="pi pi-car text-purple-500 text-[20px]"></i>
              </div>
              <div className="ml-4">
                <div className="font-bold">{0}</div>
                <div className="font-light text-gray-500 text-[14px]">
                  {t("pages.machines.totalReadyToRentMachine")}
                </div>
              </div>
            </div>
          </div> */}
            </div>
            <Card className={showSidebar ? 'mt-4 w-[95vw] lg:w-[80vw]' : 'mt-4 w-[95vw] lg:w-[95vw]'}>
               {/* HEADER */}
               <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center justify-center">
                     <div className="p-inputgroup" style={{ maxWidth: '200px' }}>
                        <InputText
                           value={searchKeyword}
                           id="tableSearch"
                           onChange={(e) => setsearchKeyword(e.target.value)}
                           placeholder={t('global.search')}
                           size="small"
                           style={{ background: '#f9f9f9', border: 'none' }}
                           onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                 dispatch(getVehicleGroups(rows, first, e.target.value));
                              }
                           }}
                        />
                        <Button
                           icon="pi pi-search"
                           size="small"
                           onClick={() => dispatch(getVehicleGroups(rows, first, searchKeyword, nextVgp, filters))}
                           style={{
                              background: '#f9f9f9',
                              color: 'black',
                              border: '#f9f9f9 none',
                           }}
                        />
                     </div>
                     {/* <Calendar
								id="range"
								value={dates2}
								onChange={(e) => setDates2(e.value)}
								size="small"
								dateFormat="dd M yy"
								style={{ maxWidth: "250px" }}
								className="mx-3"
								selectionMode="range"
								showIcon
								readOnlyInput
							/> */}
                     {/* <Button
								icon={filters ? 'pi pi-filter-slash' : 'pi pi-filter'}
								label="Filter"
								className="px-5 mx-2"
								style={{ color: 'black', background: '#f9f9f9', border: '#f9f9f9 none' }}
								onClick={() => {
									if (filters) {
										setFilters(null);
										getVehicleGroups();
									} else {
										setShowFilters(true);
									}
								}}
								size="small"
							/> */}
                     <Button
                        icon={filters || nextVgp ? 'pi pi-filter-slash' : 'pi pi-filter'}
                        className="ml-3"
                        size="small"
                        onClick={() => {
                           if (filters || nextVgp) {
                              setFilters(null);
                              setNextVgp(false);
                              dispatch(getVehicleGroups(rows, first));
                           } else {
                              setShowFilters(true);
                           }
                        }}
                        severity={filters || nextVgp ? 'danger' : 'primary'}
                     />
                     {/* <Button
								icon={'pi pi-sort-amount-down'}
								label="Sort"
								className="px-5 mx-2"
								style={{ color: 'black', background: '#f9f9f9', border: '#f9f9f9 none' }}
								onClick={() => {
									// if (filters) {
									// 	setFilters(null);
									// 	setDeleted(null);
									// 	setBanned(null);
									// 	// getCustomers();
									// } else {
									// 	setShowFilters(true);
									// }
								}}
								size="small"
							/> */}
                  </div>
                  <div className="flex items-center md:col-7 col-12 md:justify-end">
                     <div style={{ fontSize: '12px' }} className="flex items-center justify-center me-2">
                        <span className="h-[40px] w-[40px] rounded-full bg-[#e8e824] me-2 flex align-items-center justify-center">
                           {((vehicleStatuses.TOTAL_INSPECTION_MACHINE * 100) / vehicleStatuses.TOTAL_MACHINE).toFixed(1) + '%'}
                        </span>
                        <span>{t('pages.machines.inspection')}</span>
                     </div>
                     <div style={{ fontSize: '12px' }} className="flex items-center justify-center me-2">
                        <span className="h-[40px] w-[40px] rounded-full bg-[#8eb3f9] me-2 flex align-items-center justify-center">
                           {((vehicleStatuses.TOTAL_RETURN_PLANED_MACHINE * 100) / vehicleStatuses.TOTAL_MACHINE).toFixed(1) + '%'}
                        </span>
                        <span>{t('pages.machines.returnPlanned')}</span>
                     </div>
                     <div className="flex items-center justify-center me-2">
                        <span style={{ fontSize: '12px' }} className="h-[40px] w-[40px] rounded-full bg-[#ef5d4e] me-2 flex align-items-center justify-center">
                           {((vehicleStatuses.TOTAL_MALFUNCTIONED_MACHINE * 100) / vehicleStatuses.TOTAL_MACHINE).toFixed(1) + '%'}
                        </span>
                        <span>{t('pages.machines.malfunction')}</span>
                     </div>
                     <div className="flex items-center justify-center me-2">
                        <span style={{ fontSize: '12px' }} className="h-[40px] w-[40px] rounded-full bg-[#f0ad4e] me-2 flex align-items-center justify-center">
                           {((vehicleStatuses.TOTAL_RENTED_MACHINE * 100) / vehicleStatuses.TOTAL_MACHINE).toFixed(1) + '%'}
                        </span>
                        <span>{t('pages.machines.rent')}</span>
                     </div>
                     <div className="flex items-center justify-center me-2">
                        <span style={{ fontSize: '12px' }} className="h-[40px] w-[40px] rounded-full bg-[#f5b0b0] me-2 flex align-items-center justify-center">
                           {((vehicleStatuses.TOTAL_RESERVED_MACHINE * 100) / vehicleStatuses.TOTAL_MACHINE).toFixed(1) + '%'}
                        </span>
                        <span>{t('pages.machines.reserved')}</span>
                     </div>
                     <div className="flex items-center justify-center me-2">
                        <span style={{ fontSize: '12px' }} className="h-[40px] w-[40px] rounded-full bg-[#85de7f] me-2 flex align-items-center justify-center">
                           {((vehicleStatuses.TOTAL_AVAILABLE_MACHINE * 100) / vehicleStatuses.TOTAL_MACHINE).toFixed(1) + '%'}
                        </span>
                        <span>{t('pages.machines.available')}</span>
                     </div>
                  </div>
                  <Button
                     label={t('pages.machines.addNewMachine')}
                     size="small"
                     severity="success"
                     onClick={() => {
                        dispatch(setShowAddNewMachineDialog(true));
                        dispatch(setStepCounter(0));
                     }}
                  />
               </div>

               <div className="flex mt-10 justify-center">
                  <SelectButton
                     className="mb-5"
                     value={selectedTable}
                     options={tableOptions}
                     optionLabel="label"
                     onChange={(e) => {
                        if (!e.value) return;
                        setSelectedTable(e.value);
                     }}
                  />
               </div>
               {/* TABLE */}
               <TableComponent
                  data={
                     selectedTable === 'inventory'
                        ? vehicleGroups.filter((vehicleGroup) => vehicleGroup.supplierId === null)
                        : vehicleGroups.filter((vehicleGroup) => vehicleGroup.supplierId !== null)
                  }
                  columns={tableColumns}
                  size="small"
               />
               {/* FOOTER */}
               <div className="flex items-center justify-center">
                  <Paginator first={first} rows={rows} totalRecords={vehicleGroupsCount} onPageChange={(e) => setFirst(e.first)} />
               </div>
            </Card>
         </div>

         {/* FILTERS */}
         <Dialog header={t('global.filters')} visible={showFilters} onHide={() => setShowFilters(false)} className="md-width-dialog" draggable={false}>
            <div className="grid">
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>Limit</label>
                  <InputNumber
                     value={rows}
                     onValueChange={(e) => setLimitValue(e.value)}
                     showButtons
                     buttonLayout="horizontal"
                     className="w-full input-detail"
                     decrementButtonClassName="p-button-secondary"
                     incrementButtonClassName="p-button-secondary"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>Search</label>
                  <InputText
                     className="w-full p-inputtext-sm input-detail"
                     name="search"
                     id="filterSearch"
                     value={searchKeyword}
                     onChange={(e) => setsearchKeyword(e.target.value)}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>Machine Group</label>
                  <Dropdown
                     options={allMachineGroups}
                     value={filters?.vehicleGroupId}
                     onChange={(e) => {
                        setFilters({ ...filters, vehicleGroupId: e.value });
                     }}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     // valueTemplate={(option, props) => {
                     // 	if (option) {
                     // 		return <span>{option.companyKind ? `${option.companyName} - ${option.companyKind.name} ` : `${option.companyName}`}</span>;
                     // 	}
                     // 	return <span>&nbsp;</span>;
                     // }}
                     // itemTemplate={(option) => {
                     // 	if (option) return <span>{option.companyKind ? `${option.companyName} - ${option.companyKind.name} ` : `${option.companyName}`}</span>;
                     // }}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>Fuel Type</label>
                  <Dropdown
                     options={fuelKinds}
                     value={filters?.vehicleFuelKindId}
                     onChange={(e) => {
                        setFilters({ ...filters, vehicleFuelKindId: e.value });
                     }}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     valueTemplate={(option) => {
                        if (option) {
                           return <span>{t(`pages.machines.fuelTypes.${option.name}`)}</span>;
                        }
                        return <span>&nbsp;</span>;
                     }}
                     itemTemplate={(option) => {
                        return <span>{t(`pages.machines.fuelTypes.${option.name}`)}</span>;
                     }}
                  />
               </div>
               <div className="flex flex-col col-12 sm:col-6">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>Deleted</label>
                     <InputSwitch checked={filters?.isDeleted} onChange={(e) => setFilters({ ...filters, isDeleted: e.value })} />
                  </div>
               </div>
               <div className="flex flex-col col-12 sm:col-6">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>Next VGP</label>
                     <InputSwitch
                        checked={nextVgp}
                        onChange={(e) => {
                           setNextVgp(e.value);
                        }}
                     />
                  </div>
               </div>
            </div>
            <div className="flex justify-center col-12 md:justify-end">
               <Button
                  label={t('global.filter')}
                  onClick={() => {
                     dispatch(getVehicleGroups(limitValue, first, searchKeyword, nextVgp, filters, filters || nextVgp ? true : false)).then((res) =>
                        setVehicleGroupsCount(res.vehicleGroupCount),
                     );
                     setRows(limitValue);
                     setShowFilters(false);
                  }}
                  severity="success"
                  className="col-3"
                  size="small"
               />
            </div>
         </Dialog>

         {/* ADD NEW MACHINE */}
         <AddNewMachine />

         {/* VIEW MACHINE DETAILS */}
         <ViewMachineDetails />
      </>
   );
};

export default TCard;
