import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../components/TableComponent';
import { Button } from 'primereact/button';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';

const YourMonthlyTurnover = (props) => {
   const { t } = useTranslation();
   const [tableData, setTableData] = useState([]);
   const [dates, setDates] = useState([]);
   const [tableColumns, setTableColumns] = useState([]);
   const { selectedLang } = useSelector((state) => state.language);

   useEffect(() => {
      moment.locale(selectedLang.split('-')[0]);
      setTableColumns([
         {
            name: 'type',
            frozen: true,
            body: (rowData) => {
               return <div className="w-[200px]">{rowData.type}</div>;
            },
         },
      ]);
      for (let i = 0; i < dates.length; i++) {
         const element = dates[i];
         setTableColumns((prev) => [
            ...prev,
            {
               name: `date${i + 1}`,
               header: moment(element, 'YYYY-MM').format('MMM YYYY'),
               body: (rowData) => {
                  return (
                     <div className="!min-w-[100px] flex justify-between">
                        <div>{rowData[element] ? rowData[element].toFixed(2).replace('.', ',') : '0,00'} €</div>
                        <span>
                           {rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')] ? (
                              <span
                                 className={`${
                                    rowData[element] < rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')] ? 'text-red-300' : 'text-green-300'
                                 }`}
                              >
                                 {(
                                    ((rowData[element] - rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')]) /
                                       rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')]) *
                                    100
                                 ).toFixed() + '%'}
                              </span>
                           ) : (
                              ''
                           )}
                        </span>
                     </div>
                  );
               },
            },
         ]);
      }
   }, [selectedLang]);

   useEffect(() => {
      if (!props.data || Object.keys(props.data).length === 0) {
         setTableData([]);
         setDates([]);
         return;
      }

      const _cumulativeTurnover = {};
      Object.keys(props.data.TURNOVER).forEach((key, index) => {
         Object.keys(props.data.TURNOVER).forEach((key2, index2) => {
            if (!_cumulativeTurnover[key2]) {
               _cumulativeTurnover[key2] = 0;
            }
            if (index <= index2) {
               _cumulativeTurnover[key2] += props.data.TURNOVER[key];
            }
         });
      });

      Object.keys(props.data).forEach((key) => {
         setTableData((prev) => [
            {
               type: 'Turnover',
               ...props.data[key],
            },
            {
               type: 'Cumulative Turnover',
               ..._cumulativeTurnover,
            },
         ]);
      });

      const _dates = [];
      Object.keys(props.data && props.data.TURNOVER).forEach((key) => {
         _dates.push(key);
         setDates(_dates);
      });
   }, [props.data]);

   useEffect(() => {
      setTableColumns([
         {
            name: 'type',
            frozen: true,
            body: (rowData) => {
               return <div className="w-[200px]">{rowData.type}</div>;
            },
         },
      ]);
      for (let i = 0; i < dates.length; i++) {
         const element = dates[i];
         setTableColumns((prev) => [
            ...prev,
            {
               name: `date${i + 1}`,
               header: moment(element, 'YYYY-MM').format('MMM YYYY'),
               body: (rowData) => {
                  return (
                     <div className="!min-w-[100px] flex justify-between">
                        <div>{rowData[element] ? rowData[element].toFixed(2).replace('.', ',') : '0,00'} €</div>
                        <span>
                           {rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')] ? (
                              <span
                                 className={`${
                                    rowData[element] < rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')] ? 'text-red-300' : 'text-green-300'
                                 }`}
                              >
                                 {(
                                    ((rowData[element] - rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')]) /
                                       rowData[moment(element, 'YYYY-MM').add(-30, 'days').format('YYYY-M')]) *
                                    100
                                 ).toFixed() + '%'}
                              </span>
                           ) : (
                              ''
                           )}
                        </span>
                     </div>
                  );
               },
            },
         ]);
      }
   }, [dates]);

   // Excel export
   const exportExcel = () => {
      const _data =
         tableData &&
         tableData.length > 0 &&
         tableData.map((item) => {
            return {
               type: item.type,
               ...item,
            };
         });
      import('xlsx').then((xlsx) => {
         const worksheet = xlsx.utils.json_to_sheet(_data);
         const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
         const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
         import('file-saver').then((module) => {
            if (module && module.default) {
               const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
               const EXCEL_EXTENSION = '.xlsx';
               const data = new Blob([excelBuffer], {
                  type: EXCEL_TYPE,
               });
               module.default.saveAs(data, 'your-monthly-turnover' + '_' + new Date().getTime() + EXCEL_EXTENSION);
            }
         });
      });
   };

   return (
      <>
         <div className="mb-3 mt-2 flex justify-between items-center">
            <span className="!text-[18px] font-bold">{t('pages.dashboard.yourMonthlyTurnover')}</span>
            <Button onClick={exportExcel} icon="pi pi-file-excel" severity="success" aria-label="exel" />
         </div>
         <div className="m-1">
            <DataTable value={tableData} scrollable scrollHeight="400px" className="mt-4" size="small" stripedRows showGridlines>
               {tableColumns.map((column) => {
                  if (column.body) {
                     return (
                        <Column
                           frozen={column.frozen}
                           key={column.name || column.field}
                           header={column.header}
                           footer={column.footer}
                           body={column.body}
                           field={column.field}
                           bodyClassName={column.bodyClassName}
                           headerClassName={column.headerClassName}
                           headerStyle={column.headerStyle}
                           selectionMode={column.selectionMode}
                        />
                     );
                  } else {
                     return (
                        <Column
                           key={column.name || column.field}
                           field={column.field}
                           header={column.header}
                           footer={column.footer}
                           sortable={props.sortable}
                           headerClassName={column.headerClassName}
                           headerStyle={column.headerStyle}
                           bodyClassName={column.bodyClassName}
                           selectionMode={column.selectionMode}
                        />
                     );
                  }
               })}
            </DataTable>
         </div>
         {/* <div className="max-w-[83vw]">
            <TableComponent data={tableData} showGridlines={true} columns={tableColumns} size="small" />
         </div> */}
      </>
   );
};

export default YourMonthlyTurnover;
