import { gql } from '@apollo/client';

export const GET_VEHICLES = gql`
   query ($skip: Int, $first: Int, $startDate: Date, $endDate: Date, $filters: VehicleFilter, $search: String) {
      vehicles(skip: $skip, first: $first, filters: $filters, search: $search) {
         id
         machineCode
         supplierCustomer {
            id
            companyName
            addresses {
               address
            }
         }
         vehicleReservations(startDate: $startDate, endDate: $endDate) {
            id
            customer {
               id
               companyName
               addresses {
                  address
               }
            }
            beginDate
            endDate
         }
         vehicleMalfunctions(startDate: $startDate, endDate: $endDate) {
            id
            faultDate
            repairDate
         }
      }
   }
`;

export const GET_VEHICLE_RESERVATIONS = gql`
   query ($skip: Int, $first: Int) {
      vehicleReservations(skip: $skip, first: $first) {
         beginDate
         endDate
         id
         customer {
            id
            companyName
         }
         vehicle {
            id
            supplierCustomer {
               id
               companyName
               addresses {
                  address
               }
            }
            machineCode
            vehicleMalfunctions {
               id
               faultDate
               repairDate
            }
         }
      }
   }
`;

export const GET_CUSTOMERS = gql`
   query customers($first: Int, $skip: Int, $search: String, $filters: CustomerFilter) {
      customers(first: $first, skip: $skip, search: $search, filters: $filters) {
         id
         companyName
         customerKindId
      }
   }
`;

export const GET_VEHICLE_GROUPS = gql`
   query vehicleGroups {
      vehicleGroups(isActive: true) {
         id
         name
      }
   }
`;

export const GET_VEHICLE_PLANNING = gql`
   query vehiclePlanning($startDate: Date!, $endDate: Date!, $filters: VehicleFilter, $search: String, $first: Int, $skip: Int) {
      vehiclePlanning(startDate: $startDate, endDate: $endDate, filters: $filters, search: $search, first: $first, skip: $skip) {
         vehicles
         count
      }
   }
`;
