import React from "react";
import { t } from "i18next";
import TableComponent from "../../../components/TableComponent";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setShowViewMachineDetailsDialog,
  setStepCounter,
} from "../../../store/slices/machineSlice";
import {
  createVehicleDocument,
  deleteVehicleDocuments,
  getVehicleDocuments,
} from "../../../store/apis/TCardApi";

const Documents = (props) => {
  const [displayCreateDocumentModal, setDisplayCreateDocumentModal] =
    useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileUploadRef = useRef(null);
  const [files, setFiles] = useState();
  const dispatch = useDispatch();

  const { selectedMachineId } = useSelector((state) => state.machine);

  useEffect(() => {
    getMachineDocuments();
  }, []);

  const tableColumns = [
    {
      name: "action",
      header: t("global.table.action"),
      field: "action",
      body: (rowData) => {
        const handleFileClick = (link) => {
          let baseUrl = process.env.REACT_APP_LINK;
          // https://backend-albatrack-dev.tourtalia.online/graphql/
          baseUrl = baseUrl.replace("/graphql/", "");
          window.open(baseUrl + link, "_blank");
        };
        const handleFileDelete = (indexOfFile) => {
          deleteVehicleDocument(indexOfFile);
          getMachineDocuments();
        };
        return (
          <div className="flex">
            <Button
              onClick={() => handleFileClick(rowData.document.url)}
              label="View"
            ></Button>
            <Button
              onClick={() => handleFileDelete(rowData.id)}
              className="ml-2"
              style={{ background: "red", border: "none" }}
              label="Delete"
            ></Button>
          </div>
        );
      },
    },
    {
      name: "fileName",
      header: t("global.table.fileName"),
      field: "machineCode",
      body: (rowData) => {
        return (
          <span>
            {rowData.document.name.length > 30
              ? rowData.document.name.slice(0, 15) +
                "..." +
                rowData.document.name.slice(-10)
              : rowData.document.name}
          </span>
        );
      },
    },
    {
      name: "size",
      header: t("global.table.size"),
      body: (rowData) => {
        const size = rowData.document.size / 1024;
        return <span>{size.toFixed(2) + " KB"}</span>;
      },
    },
    {
      name: "extension",
      header: t("global.table.extension"),
      body: (rowData) => {
        const fileExtension = rowData.document.name.split(".").pop();
        return <span>{fileExtension}</span>;
      },
    },
  ];

  const handleFileSelection = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedFile(selectedFile);
    }
  };

  const openFileChooser = () => {
    if (!selectedFile) {
      fileUploadRef.current.click();
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const ItemTemplate = (props) => {
    return (
      <div
        style={{ width: "100%" }}
        className="flex flex-wrap align-items-center"
      >
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <a
            target="_blank"
            href={URL.createObjectURL(props.file)}
            rel="noreferrer"
          >
            <span
              style={{ color: "black" }}
              className="flex ml-3 text-left flex-column"
            >
              {props.file.name}
            </span>
          </a>
        </div>
        <div className="ml-auto">
          <Button
            type="button"
            icon="pi pi-check"
            className="p-button-outlined p-button-rounded p-button-success"
            onClick={() => onTemplateSave(props.file)}
          />
          <Button
            type="button"
            icon="pi pi-times"
            className="ml-2 p-button-outlined p-button-rounded p-button-danger"
            onClick={() => onTemplateRemove()}
          />
        </div>
      </div>
    );
  };

  const EmptyTemplate = () => {
    return (
      <div
        style={{ width: "100%", borderColor: "gray" }}
        className="flex align-items-center flex-column"
      >
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          {t("dropFileOrClickToUpload")}
        </span>
      </div>
    );
  };

  const createMachineDocument = (id, file) => {
    dispatch(createVehicleDocument(id, file)).then((res) => {
      console.log(res);
      getMachineDocuments();
    });
  };

  const getMachineDocuments = () => {
    dispatch(getVehicleDocuments(selectedMachineId)).then((res) => {
      setFiles(res.data.vehicleDocuments);
      console.log(res.data);
    });
  };

  const deleteVehicleDocument = (index) => {
    dispatch(deleteVehicleDocuments(index)).then((res) => {
      console.log(res);
      getMachineDocuments();
    });
  };

  const onTemplateRemove = () => {
    setSelectedFile(null);
  };

  const onTemplateSave = (document) => {
    console.log(selectedMachineId);
    createMachineDocument(selectedMachineId, document);
    getMachineDocuments();
  };

  const handleSave = async () => {
    if (props.viewMachineDetails) {
      dispatch(setShowViewMachineDetailsDialog(false));
      dispatch(setStepCounter(0));
    } else {
      dispatch(setStepCounter(2));
    }
  };

  return (
    <div>
      <div className="flex mt-2 justify-content-end">
        <Button
          label={t("pages.machines.addNewMachineDocument")}
          size="small"
          style={{ background: "#38b000", border: "none" }}
          onClick={() => setDisplayCreateDocumentModal(true)}
        />
      </div>
      <div className="col-8">
        <TableComponent
          data={files}
          columns={tableColumns}
          size="medium"
          // onRowClick={(e) => handleShowDetails(e)}
        />
      </div>
      <div className="grid justify-end">
        <Button
          icon="pi pi-check"
          label={
            props.viewMachineDetails
              ? t("global.save")
              : t("global.saveAndContinue")
          }
          size="small"
          onClick={() => handleSave()}
        />
      </div>
      <Dialog
        visible={displayCreateDocumentModal}
        header={t("pages.machines.createNewMachineDocument")}
        draggable={false}
        className="row"
        onHide={() => setDisplayCreateDocumentModal(false)}
      >
        <div className="col-12" style={{ marginTop: "1.8rem" }}>
          <div className="p-2">
            <div className="flex flex-col col-12">
              <Button
                onClick={(e) => openFileChooser(e)}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e)}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  border: "dashed 1px black",
                  background: "white",
                  height: "200px",
                }}
              >
                {selectedFile === null && <EmptyTemplate />}
                {selectedFile && <ItemTemplate file={selectedFile} />}
                <input
                  type="file"
                  ref={fileUploadRef}
                  style={{ display: "none" }}
                  onChange={(e) => handleFileSelection(e)}
                />
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Documents;
