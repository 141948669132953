import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, View, Text, StyleSheet, pdf, Image } from '@react-pdf/renderer';
import { t } from 'i18next';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { getOfferPDF } from '../../../store/apis/SharedApi';
import moment from 'moment';
import { getPublicHolidays } from '../../../store/apis/OffersApi';

const RentOfferForInvoice = ({ data, header, type }) => {
   const dispatch = useDispatch();
   const [publicHolidays, setPublicHolidays] = useState([]);
   const totalPrices = useRef({
      totalBeforeTax: 0,
      totalTax: 0,
      totalAfterTax: 0,
   });

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   const calculateTotalPrices = (offerData) => {
      const newTotalPrices = offerData?.orderItems.reduce(
         (accumulator, item) => {
            const basePrice = item.category === 'INV' || item.category === 'SUP' ? parseFloat(item?.vehicleReservation.subTotal) : item?.price * item?.quantity;
            const discount = item.category === 'INV' || item.category === 'SUP' ? (basePrice * item?.vehicleReservation?.discountRate) / 100 : 0;
            const ecoPrice =
               item.category === 'INV' || item.category === 'SUP' ? (item?.vehicleReservation.isEco ? Number(item?.vehicleReservation.ecoPrice) : 0) : 0;
            const insurancePrice =
               item.category === 'INV' || item.category === 'SUP'
                  ? item?.vehicleReservation.isInsurance
                     ? parseFloat(item.vehicleReservation.insurancePrice)
                     : 0
                  : 0;
            const deliveryFee = item.category === 'INV' || item.category === 'SUP' ? +item?.vehicleReservation.deliveryFee : 0;
            const pickupFee = item.category === 'INV' || item.category === 'SUP' ? +item?.vehicleReservation.pickupFee : 0;
            const totalPriceBeforeTax = basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee - discount;
            const taxAmount = (totalPriceBeforeTax * +item.tax) / 100;
            const totalPriceAfterTax = totalPriceBeforeTax + taxAmount;
            accumulator.totalBeforeTax += totalPriceBeforeTax;
            accumulator.totalTax += taxAmount;
            accumulator.totalAfterTax += totalPriceAfterTax;
            return accumulator;
         },
         {
            totalBeforeTax: 0,
            totalTax: 0,
            totalAfterTax: 0,
         },
      );
      totalPrices.current = newTotalPrices;
   };

   // Create styles
   const styles = StyleSheet.create({
      page: {
         flexDirection: 'row',
      },
      section: {
         marginVertical: 20,
         flexGrow: 1,
      },
      content: {
         fontSize: 10,
         textAlign: 'left',
         fontWeight: 'bold',
      },
      contentHeader: {
         fontSize: 10,
         textAlign: 'left',
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
      },
      greenBox: {
         backgroundColor: '#84bf60',
         padding: 10,
         alignItems: 'center',
      },
      greenHeader: {
         fontSize: 12,
         color: '#1E8449',
      },
      header: {
         flexDirection: 'row',
         justifyContent: 'space-between',
         alignItems: 'center',
      },
      logo: {
         width: '100%',
         height: '7vw',
      },
      table: {
         display: 'table',
         width: 'auto',
      },
      tableRowHeader: {
         margin: 'auto',
         flexDirection: 'row',
         backgroundColor: '#6494ed',
         height: 30,
      },
      tableRow: {
         margin: 'auto',
         marginBottom: 10,
         flexDirection: 'row',
      },
      tableCell: {
         marginTop: 5,
         fontSize: 10,
      },
      tableCellHeader: {
         margin: 'auto',
         fontSize: 10,
         fontWeight: 'bold',
         fontFamily: 'Helvetica-Bold',
         color: 'white',
      },
   });

   const calculateWorkDays = (item) => {
      let start = moment(item?.beginDate);
      let end = moment(item?.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };
   const formatPrice = (price) => `${parseFloat(price).toFixed(2).replace('.', ',')} €`;
   const dateDiffInDays = (rowData) => calculateWorkDays(rowData);

   const downloadPDF = async (pdfData) => {
      calculateTotalPrices(pdfData);
      const onRender = (e) => {
         return `${e.pageNumber}/${e.totalPages}`;
      };

      const MyDocument = () => (
         <Document>
            <Page size="A4" style={{ padding: 20 }}>
               <View style={styles.header}>
                  <Image style={styles.logo} src={require('../../../assets/header.png')} />
               </View>
               <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <View style={styles.section}>
                     <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: '#6494ed' }}>
                        {t('pages.customers.customerDetails')}
                     </Text>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.pdf.customerId')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.id}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.companyName')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.companyName}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.customers.address')}: </Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].address ? pdfData?.customer?.addresses[0].address + ', ' : ''}</Text>
                        <Text style={styles.content}>
                           {pdfData?.customer?.addresses[0].addressContinuation ? pdfData?.customer?.addresses[0].addressContinuation + ', ' : ''}
                        </Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].postCode ? pdfData?.customer?.addresses[0].postCode + ', ' : ''}</Text>
                        <Text style={styles.content}>{pdfData?.customer?.addresses[0].city ? pdfData?.customer?.addresses[0].city : ''}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.offers.receiverName')}: </Text>
                        <Text style={styles.content}>{pdfData?.address?.receiverName}</Text>
                     </View>
                     <View style={{ flexDirection: 'row', marginTop: 20 }}>
                        <Text style={styles.contentHeader}>{t('pages.planning.contractNo')}: </Text>
                        <Text style={styles.content}>{pdfData?.orderNumber}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('global.table.date')}: </Text>
                        <Text style={styles.content}>{moment(pdfData?.createdAt).format('DD/MM/YYYY')}</Text>
                     </View>
                     <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.contentHeader}>{t('pages.offers.poNumber')}: </Text>
                        <Text style={styles.content}>{pdfData?.referenceNumber}</Text>
                     </View>
                  </View>
                  <View style={styles.section}>
                     <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', color: '#6494ed' }}>
                        {t('pages.customers.deliveryAddress')}
                     </Text>
                     <View style={{ border: 1, padding: 15, minHeight: 80, alignItems: 'center' }}>
                        <Text style={styles.content}>{pdfData?.address.placeName || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.address || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.addressContinuation || ''}</Text>
                        <Text style={styles.content}>{pdfData?.address.postCode + ', ' + pdfData?.address.city || ''}</Text>
                     </View>
                     <View style={[styles.greenBox, { marginTop: 10 }]}>
                        <Text style={{ fontSize: 12, color: 'white' }}>{type === 'contract' ? t('global.table.contract') : t('pages.pdf.rentOffer')}</Text>
                     </View>
                  </View>
               </View>

               <View style={styles.table}>
                  {/* Tablo başlıkları */}
                  <View style={styles.tableRowHeader}>
                     <View style={[styles.tableCol, { width: '18%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.reference')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '40%' }]}>
                        <Text style={styles.tableCellHeader}>{t('global.table.description')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.price')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.quantity')}</Text>
                     </View>
                     <View style={[styles.tableCol, { width: '14%' }]}>
                        <Text style={styles.tableCellHeader}>{t('pages.offers.totalPrice')}</Text>
                     </View>
                  </View>
                  {/* Satırlar */}
                  {pdfData?.orderItems.map((item, index) =>
                     item.category === 'INV' || item.category === 'SUP' ? (
                        <>
                           <View style={styles.tableRow} key={index}>
                              <View style={[styles.tableCol, { width: '14%', marginTop: 10 }]}>
                                 <Text style={styles.tableCell}>{item?.vehicleReservation?.vehicleGroup.name}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '40%' }]}>
                                 <View>
                                    <Text style={styles.tableCell}>
                                       {item?.vehicleReservation?.vehicleGroup && item?.vehicleReservation?.vehicleGroup.description
                                          ? item?.vehicleReservation?.vehicleGroup.description
                                          : item?.description
                                          ? item?.description
                                          : '-'}
                                    </Text>
                                    <Text style={[styles.tableCell, { fontFamily: 'Helvetica-Bold' }]}>
                                       {t('pages.pdf.start')}: {moment(item?.vehicleReservation?.beginDate).format('DD/MM/YYYY')} {t('pages.pdf.end')}:{' '}
                                       {moment(item?.vehicleReservation?.endDate).format('DD/MM/YYYY')}
                                    </Text>
                                 </View>
                                 <View style={{ marginTop: 10 }}>
                                    <Text style={styles.tableCell}>{t('pages.offers.isEco')}</Text>
                                    <Text style={styles.tableCell}>{t('pages.offers.isInsurance') + ' 10%'}</Text>
                                    <Text style={styles.tableCell}>{t('pages.offers.deliveryFee')}</Text>
                                    <Text style={styles.tableCell}>{t('pages.offers.pickupFee')}</Text>
                                    <Text style={styles.tableCell}>{t('pages.planning.rent')}</Text>
                                    {item?.vehicleReservation?.discountRate !== 0 && (
                                       <Text style={styles.tableCell}>{t('pages.invoices.discount') + ' ' + item?.vehicleReservation?.discountRate + '%'}</Text>
                                    )}
                                 </View>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                                 <View style={{ marginTop: 25 }}>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {item?.vehicleReservation?.isEco ? (item?.vehicleReservation?.ecoPrice).replace('.', ',') : 0.0} €
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {item?.vehicleReservation?.isInsurance
                                          ? formatPrice(
                                               item?.vehicleReservation?.insurancePrice /
                                                  (moment(item?.vehicleReservation?.endDate).diff(moment(item?.vehicleReservation?.beginDate), 'days') + 1),
                                            )
                                          : 0.0 + ' €'}
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {(item?.vehicleReservation?.deliveryFee).replace('.', ',')} €
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {(item?.vehicleReservation?.pickupFee).replace('.', ',')} €
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {(item?.vehicleReservation?.price).replace('.', ',')} €
                                    </Text>
                                    {item?.vehicleReservation?.discountRate !== 0 && (
                                       <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                                    )}
                                 </View>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                                 <View style={{ marginTop: 25 }}>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{item?.quantity}</Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {moment(item?.vehicleReservation?.endDate).diff(moment(item?.vehicleReservation?.beginDate), 'days') + 1}
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{item?.quantity}</Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>{item?.quantity}</Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {parseFloat(item.vehicleReservation.subTotal / item.vehicleReservation.price).toFixed(2)}
                                    </Text>
                                    {item?.vehicleReservation?.discountRate !== 0 && (
                                       <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                                    )}
                                 </View>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>&nbsp;</Text>
                                 <View style={{ marginTop: 25 }}>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {item?.vehicleReservation?.isEco ? (item?.vehicleReservation?.ecoPrice).replace('.', ',') : '0,00'} €
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {item?.vehicleReservation?.isInsurance
                                          ? parseFloat(item.vehicleReservation.insurancePrice).toFixed(2).replace('.', ',') + ' €'
                                          : 0.0 + ' €'}
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {(item?.vehicleReservation?.deliveryFee).replace('.', ',')} €
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {(item?.vehicleReservation?.pickupFee).replace('.', ',')} €
                                    </Text>
                                    <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                       {formatPrice(item?.vehicleReservation?.subTotal)}
                                    </Text>
                                    {item?.vehicleReservation?.discountRate !== 0 && (
                                       <Text style={[styles.tableCell, { flexDirection: 'row', alignSelf: 'flex-end' }]}>
                                          {item?.discount}{' '}
                                          {formatPrice((parseFloat(item?.vehicleReservation?.subTotal) * item?.vehicleReservation?.discountRate) / 100)}
                                       </Text>
                                    )}
                                 </View>
                              </View>
                           </View>
                        </>
                     ) : (
                        <>
                           <View style={styles.tableRow} key={index}>
                              <View style={[styles.tableCol, { width: '18%' }]}>
                                 <Text style={styles.tableCell}>{item?.category}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '40%' }]}>
                                 <Text style={styles.tableCell}>{item?.description}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={styles.tableCell}>{(item?.price).replace('.', ',')} €</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={styles.tableCell}>{item?.quantity}</Text>
                              </View>
                              <View style={[styles.tableCol, { width: '14%' }]}>
                                 <Text style={styles.tableCell}>
                                    {parseFloat(item?.price * item?.quantity)
                                       .toFixed(2)
                                       .replace('.', ',')}{' '}
                                    €
                                 </Text>
                              </View>
                           </View>
                        </>
                     ),
                  )}
               </View>
               {/* Total Box */}
               <View style={{ marginTop: '20px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                     <View>
                        <Text style={{ fontSize: 10, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{pdfData?.description}</Text>
                     </View>
                     <View style={{ border: 1, alignSelf: 'flex-end', flexDirection: 'column', minWidth: '49%', borderColor: '#6494ed' }}>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.totalBeforeTax').toUpperCase()}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {formatPrice(totalPrices.current.totalBeforeTax)}
                           </Text>
                        </View>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.tax').toUpperCase() + ' 20%'}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>{formatPrice(totalPrices.current.totalTax)}</Text>
                        </View>
                        <View style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', minWidth: 130 }}>
                           <Text style={{ fontSize: 10 }}>{t('pages.offers.totalAfterTax').toUpperCase()}</Text>
                           <Text style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'Helvetica-Bold' }}>
                              {formatPrice(totalPrices.current.totalAfterTax)}
                           </Text>
                        </View>
                     </View>
                  </View>
               </View>
               {/* Details */}
               <View style={{ flexGrow: 1, marginBottom: 50 }}>
                  <View style={{ marginTop: '20px' }}>
                     <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View>
                           <Text style={{ fontSize: 10, fontWeight: 'bold', fontFamily: 'Helvetica-Bold', maxWidth: '50%' }}>
                              {t('pages.pdf.rentOfferPdf1')}
                           </Text>
                           <Text style={{ fontSize: 10, maxWidth: '50%', marginTop: '10px' }}>{t('pages.pdf.rentOfferPdf2')}</Text>
                        </View>
                        <View>
                           <View style={{ border: 1, padding: 5, minWidth: '50%', minHeight: '100px', marginLeft: '10px', borderColor: '#6494ed' }}>
                              <Text style={{ maxWidth: '45%', fontSize: 10 }}>{t('pages.pdf.offersBoxText')}</Text>
                           </View>
                        </View>
                     </View>
                     {pdfData?.orderItems.filter((i) => i?.vehicleReservation?.vehicle?.vehicleFuelKind?.id === '2').length > 0 && (
                        <Text style={{ fontSize: 10, marginTop: '10px' }}>{t('pages.pdf.rentOfferPdf4')}</Text>
                     )}
                     <Text style={{ fontSize: 10, color: 'red', marginTop: '10px' }}>{t('pages.pdf.rentOfferPdf3')}</Text>
                  </View>
               </View>
               {/* Footer */}
               <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                  <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                  <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                  <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                  <View style={{ textAlign: 'right' }} fixed>
                     <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                  </View>
               </View>
            </Page>
            {header === 'offer' && pdfData?.customer?.customerKind.name === 'PROSPECT' && (
               <Page size="A4" style={{ paddingHorizontal: 10 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                     <Image style={{ width: '90%', height: '100%' }} src={require('../../../assets/offerPdf2New.png')} />
                  </View>
                  <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                     <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                     <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                     <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                     <View style={{ textAlign: 'right' }} fixed>
                        <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                     </View>
                  </View>
               </Page>
            )}
            {(header === 'offer' || header === 'offered') && (
               <Page size="A4" style={{ paddingHorizontal: 10 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                     <Image style={{ width: '90%', height: '85%' }} src={require('../../../assets/offerpdfNew.png')} />
                  </View>
                  <View style={{ marginTop: 10, textAlign: 'center' }} fixed>
                     <Text style={{ fontSize: '9px' }}>Siret: 750 980 00071 - APE 7732Z</Text>
                     <Text style={{ fontSize: '9px' }}>N°TVA Intra: FR 40750980476</Text>
                     <Text style={{ fontSize: '9px' }}>Email: contact@metaloc.fr</Text>
                     <View style={{ textAlign: 'right' }} fixed>
                        <Text style={{ fontSize: '9px' }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                     </View>
                  </View>
               </Page>
            )}
         </Document>
      );
      const blob = await pdf(<MyDocument />).toBlob();
      /* saveAs(
         blob,
         header === 'contract'
            ? 'contract'
            : header === 'seeProforma'
            ? 'see_proforma'
            : header === 'deliveryNote'
            ? 'delivery_note'
            : header === 'pickuppNote'
            ? 'pickup_note'
            : 'rent_offer',
      ); */
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
   };

   const getPdfData = () => {
      dispatch(getOfferPDF(data.orderId)).then((res) => {
         downloadPDF(res);
      });
   };

   return (
      <>
         <Button
            icon="pi pi-file-pdf"
            severity="info"
            className="!w-[30px] !h-[25px] mx-3"
            onClick={() => {
               getPdfData(data);
            }}
         />
      </>
   );
};

export default RentOfferForInvoice;
