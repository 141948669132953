import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import TableComponent from '../../../../components/TableComponent';
import moment from 'moment';
import AddInventoryMachine from './AddInventoryMachine';
import AddSupplierMachine from './AddSupplierMachine';
import AddSaleItems from './AddSaleItems';
import ExchangeMachine from './ExchangeMachine';
import TransferOfMachine from './TransferOfMachine';
import ExtendContract from './ExtendContract';
import TransportOrder from './TransportOrder';
import { Dialog } from 'primereact/dialog';
import {
   getAddresses,
   getCustomers,
   createSupplierTransporterOrder,
   updateSupplierTransporterOrder,
   createTransporterOrderItem,
   updateTransporterOrderItem,
   updateContract,
   deleteDetailContractItem,
   deleteSupplierTransporterOrder,
} from '../../../../store/apis/ContractsApi';
import CreateInformationAddress from './CreateInformationAddress';
import { setContract } from '../../../../store/slices/contractsSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getPublicHolidays } from '../../../../store/apis/OffersApi';
const Items = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const tax = useSelector((state) => state.shared.globalSettings.tax);
   const { contract } = useSelector((state) => state.contracts);
   const [inventoryMachine, setInventoryMachine] = useState({
      visible: false,
      actionType: 'create',
      item: null,
   });
   const [supplierMachine, setSupplierMachine] = useState({
      visible: false,
      actionType: 'create',
      item: null,
   });
   const [saleItems, setSaleItems] = useState({
      visible: false,
      actionType: 'create',
      item: null,
   });
   const [exchangeMachineVisible, setExchangeMachineVisible] = useState(false);
   const [transferOfMachineVisible, setTransferOfMachineVisible] = useState(false);
   const [extendContractVisible, setExtendContractVisible] = useState(false);
   const [selectedItem, setSelectedItem] = useState(null);
   const [transporterDialog, setTransporterDialog] = useState(false);
   const [transporterInputs, setTransporterInputs] = useState({});
   const [transporterErrors, setTransporterErrors] = useState({});
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [items, setItems] = useState([]);
   const [itemsError, setItemsError] = useState([]);
   const [customers, setCustomers] = useState([]);
   const [addresses, setAddresses] = useState([]);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [deleteOrderItem, setDeleteOrderItem] = useState({
      dialogVisible: false,
      selectedOrderItemId: null,
   });
   const [totalPrices, setTotalPrices] = useState({
      totalPrice: 0,
      totalEcoFee: 0,
      totalInsurance: 0,
      totalDeliveryFee: 0,
      totalPickupFee: 0,
      totalBeforeTax: 0,
      totalAfterTax: 0,
      totalDiscount: 0,
   });
   const [selectedAddressId, setSelectedAddressId] = useState(null);
   const [publicHolidays, setPublicHolidays] = useState([]);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      calculateTotalPrices();
   }, [contract]);

   useEffect(() => {
      if (contract?.customer?.id) {
         dispatch(getAddresses({ customerId: contract.customer.id })).then((res) => setAddresses(res));
      }
      dispatch(getCustomers()).then((res) => setCustomers(res));
   }, []);

   useEffect(() => {
      if (!createdAddress) return;
      if (selectedAddressId) {
         setAddresses([...addresses.filter((address) => +address.id !== +selectedAddressId), createdAddress]);
      } else {
         setAddresses([...addresses, createdAddress]);
      }
      setSelectedAddressId(null);
      setTransporterInputs({
         ...transporterInputs,
         addressId: createdAddress.id,
      });
   }, [createdAddress]);
   const calculateTotalPrices = () => {
      const newTotalPrices = contract.orderItems.reduce(
         (accumulator, item) => {
            const basePrice = item.category === 'INV' || item.category === 'SUP' ? parseFloat(item.vehicleReservation.subTotal) : item?.price * item?.quantity;
            const discount = item.category === 'INV' || item.category === 'SUP' ? (basePrice * item?.vehicleReservation?.discountRate) / 100 : 0;
            const ecoPrice =
               item.category === 'INV' || item.category === 'SUP' ? (item?.vehicleReservation.isEco ? Number(item?.vehicleReservation.ecoPrice) : 0) : 0;
            const insurancePrice =
               item.category === 'INV' || item.category === 'SUP'
                  ? item?.vehicleReservation.isInsurance
                     ? parseFloat(item.vehicleReservation.insurancePrice)
                     : 0
                  : 0;
            const deliveryFee = item.category === 'INV' || item.category === 'SUP' ? +item?.vehicleReservation.deliveryFee : 0;
            const pickupFee = item.category === 'INV' || item.category === 'SUP' ? +item?.vehicleReservation.pickupFee : 0;
            accumulator.totalPrice += basePrice;
            accumulator.totalEcoFee += ecoPrice;
            accumulator.totalInsurance += insurancePrice;
            accumulator.totalDeliveryFee += deliveryFee;
            accumulator.totalPickupFee += pickupFee;
            accumulator.totalDiscount += discount;

            const totalPriceBeforeTax = basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee - discount;
            const taxAmount = (totalPriceBeforeTax * +item.tax) / 100;
            const totalPriceAfterTax = totalPriceBeforeTax + taxAmount;
            accumulator.totalBeforeTax += totalPriceBeforeTax;
            accumulator.totalAfterTax += totalPriceAfterTax;
            return accumulator;
         },
         {
            totalPrice: 0,
            totalEcoFee: 0,
            totalInsurance: 0,
            totalDeliveryFee: 0,
            totalPickupFee: 0,
            totalBeforeTax: 0,
            totalAfterTax: 0,
            totalDiscount: 0,
         },
      );
      setTotalPrices(newTotalPrices);
   };

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };
   const formatPrice = (price) => `€${parseFloat(price).toFixed(2)}`;
   const calculateTotalPrice = (rowData) => {
      const subTotal = parseFloat(rowData.subTotal);
      const basePrice = subTotal - (subTotal * rowData.discountRate) / 100;
      const ecoPrice = rowData.isEco ? +rowData.ecoPrice : 0;
      const insurancePrice = rowData.isInsurance ? parseFloat(rowData.insurancePrice) : 0;
      const deliveryFee = +rowData.deliveryFee;
      const pickupFee = +rowData.pickupFee;
      return basePrice + ecoPrice + insurancePrice + deliveryFee + pickupFee;
   };
   const calculateTotalPriceAfterTax = (rowData) => {
      const totalPrice =
         rowData.category === 'INV' || rowData.category === 'SUP' ? calculateTotalPrice(rowData?.vehicleReservation) : rowData?.price * rowData?.quantity;
      const taxAmount = (totalPrice * +rowData.tax) / 100;
      return totalPrice + taxAmount;
   };

   const updateItem = (rowData) => () => {
      if (rowData.category === 'INV') {
         setInventoryMachine({
            visible: true,
            actionType: 'update',
            item: rowData,
         });
      } else if (rowData.category === 'SUP') {
         setSupplierMachine({
            visible: true,
            actionType: 'update',
            item: rowData,
         });
      } else {
         setSaleItems({
            visible: true,
            actionType: 'update',
            item: rowData,
         });
      }
   };
   const tableColumns = [
      {
         name: 'actions',
         body: (rowData) => (
            <div className="flex items-center justify-start">
               <Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={updateItem(rowData)} />
               <Button
                  className="mx-1"
                  icon="pi pi-trash"
                  severity="danger"
                  onClick={() => {
                     setDeleteOrderItem({
                        dialogVisible: true,
                        selectedOrderItemId: +rowData.id,
                     });
                  }}
                  // disabled={contract.orderItems.length === 1}
               />
               {rowData.category === 'INV' && <Button className="mx-1" icon="pi pi-wrench" severity="danger " onClick={() => navigateToMalfunction(rowData)} />}
               {rowData.category === 'SUP' && (
                  <Button
                     className="mx-1"
                     label="PO"
                     severity="warning "
                     onClick={() => {
                        handleOpenPurchaseOrder(rowData);
                     }}
                  />
               )}
            </div>
         ),
         footer: t('pages.contracts.total').toUpperCase(),
      },
      {
         name: 'category',
         header: t('pages.contracts.category'),
         body: (rowData) => <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? 'RENT' : 'SALE'}</span>,
      },
      {
         name: 'reference',
         header: t('pages.contracts.reference'),
         body: (rowData) => (
            <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? rowData?.vehicleReservation?.vehicleGroup?.name : rowData?.category}</span>
         ),
      },
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => (
            <span>
               {rowData?.vehicleReservation?.vehicle && rowData?.vehicleReservation?.vehicle.note
                  ? rowData?.vehicleReservation?.vehicle.note
                  : rowData?.description}
            </span>
         ),
      },
      {
         name: 'startDate',
         header: `${t('pages.contracts.startDate')}`,
         body: (rowData) => (
            <div className="w-[80px]">
               <span>
                  {rowData.category === 'INV' || rowData.category === 'SUP' ? moment(rowData?.vehicleReservation?.beginDate).format('DD-MM-YYYY') : '-'}
               </span>
            </div>
         ),
      },
      {
         name: 'endDate',
         header: t('pages.contracts.endDate'),
         body: (rowData) => (
            <div className="w-[80px]">
               <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? moment(rowData?.vehicleReservation?.endDate).format('DD-MM-YYYY') : '-'}</span>
            </div>
         ),
      },
      {
         name: 'quantity',
         header: t('pages.contracts.quantity'),
         body: (rowData) => (
            <span>{rowData.category === 'INV' || rowData.category === 'SUP' ? calculateWorkDays(rowData?.vehicleReservation) : rowData?.quantity}</span>
         ),
      },
      {
         name: 'price',
         header: t('pages.contracts.price'),
         body: (rowData) =>
            formatPrice(
               rowData.category === 'INV' || rowData.category === 'SUP'
                  ? rowData?.vehicleReservation?.subTotal
                  : (rowData?.price * rowData?.quantity).toFixed(2),
            ),
         footer: formatPrice(totalPrices.totalPrice),
      },
      {
         name: 'ecoFee',
         header: t('pages.contracts.ecoFee'),
         body: (rowData) =>
            rowData.category === 'INV' || rowData.category === 'SUP'
               ? formatPrice(rowData?.vehicleReservation?.isEco ? rowData?.vehicleReservation?.ecoPrice : 0)
               : '-',
         footer: formatPrice(totalPrices.totalEcoFee),
      },
      {
         name: 'insurance',
         header: t('pages.contracts.insurance'),
         body: (rowData) => (rowData.category === 'INV' || rowData.category === 'SUP' ? formatPrice(rowData?.vehicleReservation?.insurancePrice) : '-'),
         footer: formatPrice(totalPrices.totalInsurance),
      },
      {
         name: 'deliveryFee',
         header: t('pages.contracts.transporterDeliveryFee'),
         body: (rowData) => (rowData.category === 'INV' || rowData.category === 'SUP' ? formatPrice(rowData?.vehicleReservation?.deliveryFee) : '-'),
         footer: formatPrice(totalPrices.totalDeliveryFee),
      },
      {
         name: 'pickupFee',
         header: t('pages.contracts.transporterPickupFee'),
         body: (rowData) => (rowData.category === 'INV' || rowData.category === 'SUP' ? formatPrice(rowData?.vehicleReservation?.pickupFee) : '-'),
         footer: formatPrice(totalPrices.totalPickupFee),
      },
      {
         name: 'discountRate',
         header: t('pages.offers.discountRate'),
         body: (rowData) =>
            rowData.category === 'INV' || rowData.category === 'SUP'
               ? formatPrice((parseFloat(rowData.vehicleReservation.subTotal) * rowData?.vehicleReservation.discountRate) / 100) +
                 ' (%' +
                 rowData?.vehicleReservation.discountRate +
                 ')'
               : '-',
         footer: formatPrice(totalPrices.totalDiscount),
      },
      {
         name: 'totalBeforeTax',
         header: t('pages.contracts.totalBeforeTax'),
         body: (rowData) =>
            rowData.category === 'INV' || rowData.category === 'SUP'
               ? formatPrice(calculateTotalPrice(rowData.vehicleReservation))
               : '€' + (rowData?.price * rowData?.quantity).toFixed(2),
         footer: formatPrice(totalPrices.totalBeforeTax),
      },
      {
         name: 'taxFee',
         header: t('pages.contracts.taxFee'),
         body: (rowData) => <span>%{parseInt(rowData.tax)}</span>,
      },
      {
         name: 'totalAfterTax',
         header: t('pages.contracts.totalAfterTax'),
         body: (rowData) => formatPrice(calculateTotalPriceAfterTax(rowData)),
         footer: formatPrice(totalPrices.totalAfterTax),
      },
      {
         name: 'returnDate',
         header: t('pages.contracts.returnDate'),
         body: (rowData) => <span>{moment(rowData.returnDate).format('DD/MM/YYYY')}</span>,
      },
      {
         name: 'exchange',
         body: (rowData) => (
            <span
               className={`${
                  rowData?.vehicleReservation?.status === 'RENT' && moment().isSameOrAfter(rowData?.vehicleReservation?.beginDate) ? 'cursor-pointer' : 'hidden'
               }  hover:underline text-primary-500`}
               onClick={() => {
                  if (rowData?.vehicleReservation?.status === 'RENT' && moment().isSameOrAfter(rowData?.vehicleReservation?.beginDate)) {
                     setSelectedItem(rowData);
                     setExchangeMachineVisible(true);
                  }
               }}
            >
               {t('pages.contracts.exchange')}
            </span>
         ),
      },
      /* {
         name: 'transfer',
         body: (rowData) => (
            <span
               className={`${
                  rowData?.vehicleReservation?.status === 'RENT' && moment().isSameOrAfter(rowData?.vehicleReservation?.beginDate) ? 'cursor-pointer' : 'hidden'
               }  hover:underline text-primary-500`}
               onClick={() => {
                  if (rowData?.vehicleReservation?.status === 'RENT' && moment().isSameOrAfter(rowData?.vehicleReservation?.beginDate)) {
                     setSelectedItem(rowData);
                     setTransferOfMachineVisible(true);
                  }
               }}
            >
               {t('pages.contracts.transfer')}
            </span>
         ),
      }, */
      {
         name: 'extend',
         body: (rowData) => (
            <span
               className={`${rowData?.vehicleReservation?.status === 'RETURN_PLANED' ? 'cursor-pointer' : 'hidden'}  hover:underline text-primary-500`}
               onClick={() => {
                  if (rowData?.vehicleReservation?.status === 'RETURN_PLANED') {
                     setSelectedItem(rowData);
                     setExtendContractVisible(true);
                  }
               }}
            >
               {t('pages.contracts.extend')}
            </span>
         ),
      },
   ];

   const updateTransporterErrors = (name, value) => {
      setTransporterErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const updateItemsErrors = (name, value, index) => {
      setItemsError((prevErrors) => {
         let newErrors = [...prevErrors];
         newErrors[index] = {
            ...newErrors[index],
            [name]: !value ? t('global.requiredField') : '',
         };
         return newErrors;
      });
   };

   const handleTransporterInputChange = (value, name) => {
      setTransporterInputs((prev) => ({ ...prev, [name]: value }));
      updateTransporterErrors(name, value);
   };

   const handleItemsChange = (value, name, index) => {
      value = name === 'price' ? value.replace(/[^0-9]/g, '') : value;
      let newItems = [...items];
      newItems[index] = { ...newItems[index], [name]: value };
      setItems(newItems);
      updateItemsErrors(name, value, index);
   };

   const validateTransporter = () => {
      const inputs = ['supplierId', 'addressId'];
      let valid = true;
      inputs.forEach((input) => {
         if (!transporterInputs[input]) {
            updateTransporterErrors(input, false);
            valid = false;
         }
      });
      return valid;
   };
   const validateItems = () => {
      let inputs = ['category', 'description', 'beginDate', 'endDate', 'quantity', 'price'];
      let valid = true;
      items.forEach((item, index) => {
         inputs.forEach((input) => {
            if (!item[input]) {
               updateItemsErrors(input, false, index);
               valid = false;
            }
         });
      });
      return valid;
   };
   const handleFocus = (name, index) => {
      if (items[index][name] === 0) {
         let newItems = [...items];
         newItems[index] = { ...newItems[index], [name]: '' };
         setItems(newItems);
      }
   };
   const handleSaveTransporter = async (e) => {
      e.preventDefault();
      if (!validateTransporter() || !validateItems()) return;
      if (transporterInputs.id) {
         await dispatch(
            updateSupplierTransporterOrder({
               ...transporterInputs,
               orderId: contract.id,
            }),
         ).then(async (res) => {
            if (res) {
               const updatedItems = await Promise.all(
                  items.map(async (item) => {
                     if (item.id) {
                        const updatedItem = await dispatch(
                           updateTransporterOrderItem({
                              ...item,
                              currencyId: 2,
                              beginDate: moment(item.beginDate).format('YYYY-MM-DD'),
                              endDate: moment(item.endDate).format('YYYY-MM-DD'),
                           }),
                        );
                        return updatedItem;
                     } else {
                        const createdItem = await dispatch(
                           createTransporterOrderItem({
                              ...item,
                              supplierTransporterOrderId: res.id,
                              currencyId: 2,
                              beginDate: moment(item.beginDate).format('YYYY-MM-DD'),
                              endDate: moment(item.endDate).format('YYYY-MM-DD'),
                           }),
                        );
                        return createdItem;
                     }
                  }),
               );
               dispatch(
                  setContract({
                     ...contract,
                     supplierTransporterOrders: contract.supplierTransporterOrders.map((order) => {
                        if (+order.id === +res.id) {
                           return {
                              ...res,
                              supplierTransporterOrderItems: updatedItems,
                           };
                        }
                        return order;
                     }),
                  }),
               );
               setItems(updatedItems);
               setTransporterDialog(false);
            }
         });
      } else {
         let data = {
            ...transporterInputs,
            orderId: contract.id,
         };
         delete data.id;
         await dispatch(createSupplierTransporterOrder(data)).then(async (res) => {
            if (res) {
               const createdItems = await Promise.all(
                  items.map(async (item) => {
                     const createdItem = await dispatch(
                        createTransporterOrderItem({
                           ...item,
                           supplierTransporterOrderId: res.id,
                           currencyId: 2,
                           beginDate: moment(item.beginDate).format('YYYY-MM-DD'),
                           endDate: moment(item.endDate).format('YYYY-MM-DD'),
                        }),
                     );
                     return createdItem;
                  }),
               );
               dispatch(
                  setContract({
                     ...contract,
                     supplierTransporterOrders: [...contract.supplierTransporterOrders, { ...res, supplierTransporterOrderItems: createdItems }],
                  }),
               );
               setItems(createdItems);
               setTransporterDialog(false);
            }
         });
      }
   };

   const handleOpenPurchaseOrder = (rowData) => {
      let supplierId = rowData?.vehicleReservation && rowData?.vehicleReservation?.vehicle && rowData?.vehicleReservation?.vehicle.supplierCustomer?.id;
      let supplierTransporterOrder = contract?.supplierTransporterOrders?.find((order) => +order.supplierId === +supplierId);
      let inputs = {
         supplierId: supplierId,
         addressId: supplierTransporterOrder?.address ? supplierTransporterOrder?.address.id : contract?.address?.id,
         referenceNumber: `${contract?.orderNumber}(${contract?.customer.companyName ?? ''})`,
         contractPerson: supplierTransporterOrder?.contractPerson ?? '',
         description: supplierTransporterOrder?.description ?? '',
         privacyDescription: supplierTransporterOrder?.privacyDescription ?? '',
         id: supplierTransporterOrder?.id,
         status: supplierTransporterOrder?.status,
      };
      let _items =
         supplierTransporterOrder && supplierTransporterOrder?.supplierTransporterOrderItems.length > 0
            ? supplierTransporterOrder?.supplierTransporterOrderItems.map((item) => {
                 return {
                    category: 'TRANSPORT',
                    description: item.description,
                    beginDate: item.beginDate,
                    endDate: item.endDate,
                    quantity: item.quantity,
                    price: item.price,
                    id: item.id,
                 };
              })
            : contract.orderItems && contract.orderItems.length > 0
            ? contract.orderItems
                 .filter(
                    (item) =>
                       item.vehicleReservation && item.vehicleReservation.vehicle && +item.vehicleReservation.vehicle.supplierCustomer?.id === +supplierId,
                 )
                 .map((item) => ({
                    description: item.vehicleReservation.vehicle.note,
                    category: 'TRANSPORT',
                    quantity: item?.quantity,
                    price: item?.price,
                    beginDate: item.vehicleReservation?.beginDate,
                    endDate: item.vehicleReservation?.endDate,
                 }))
            : [];

      setTransporterInputs(inputs);
      setItems(_items);
      setTransporterDialog(true);
   };
   const navigateToMalfunction = (rowData) => {
      if (!rowData.vehicleReservation || !rowData.vehicleReservation.vehicle) return;
      navigate(`/workshop/malfunction-records`, {
         state: {
            vehicleId: rowData.vehicleReservation.vehicle.id,
            type: '2',
            customerId: contract.customer.id,
         },
      });
   };
   const handleDeleteOrderItem = async () => {
      let supplierId = contract?.orderItems.find((item) => +item.id === +deleteOrderItem.selectedOrderItemId)?.vehicleReservation?.vehicle?.supplierCustomer
         ?.id;
      let supplierTransporterOrder = contract?.supplierTransporterOrders?.find((order) => +order.supplierId === +supplierId);
      let sameSupplierItems = contract.orderItems.filter(
         (item) => item.vehicleReservation && item.vehicleReservation.vehicle && +item.vehicleReservation.vehicle.supplierCustomer?.id === +supplierId,
      );

      if (supplierTransporterOrder && sameSupplierItems.length === 1) {
         await dispatch(
            deleteSupplierTransporterOrder({
               orderId: contract.id,
               supplierTransporterOrderId: supplierTransporterOrder.id,
            }),
         );
      }
      dispatch(
         deleteDetailContractItem({
            orderId: contract.id,
            orderItemId: deleteOrderItem.selectedOrderItemId,
         }),
      ).then((res) => {
         toast.success(t('pages.contracts.itemRemoved'));

         setDeleteOrderItem({
            dialogVisible: false,
            selectedOrderItemId: null,
         });
      });
   };
   const updateAddress = () => {
      setSelectedAddressId(transporterInputs?.addressId);
      setCreateAddressDialog(true);
   };
   const calculatePrices = (type) => {
      let price = 0;
      const totalDepartureTransporter = contract?.departureTransporterOrder?.transporterOrderItems.reduce((acc, item) => {
         return acc + parseFloat(item?.price);
      }, 0);
      const totalReturnTransporter = contract?.returnTransporterOrder?.transporterOrderItems.reduce((acc, item) => {
         return acc + parseFloat(item?.price);
      }, 0);
      const totalDeliveryFee = contract?.orderItems?.reduce((acc, item) => {
         return acc + parseFloat(item && item.vehicleReservation ? item.vehicleReservation.deliveryFee : 0);
      }, 0);
      const totalPickupFee = contract?.orderItems?.reduce((acc, item) => {
         return acc + parseFloat(item && item.vehicleReservation ? item.vehicleReservation.pickupFee : 0);
      }, 0);
      switch (type) {
         case 'transporterC':
            price =
               contract?.departureTransporterId && !contract?.returnTransporterOrder
                  ? totalDepartureTransporter
                  : contract?.returnTransporterOrder && !contract?.departureTransporterId
                  ? totalReturnTransporter
                  : contract?.departureTransporterId && contract?.returnTransporterOrder
                  ? totalDepartureTransporter + totalReturnTransporter
                  : 0;
            break;
         case 'transporterM':
            price = totalDeliveryFee + totalPickupFee - (totalDepartureTransporter + totalReturnTransporter);
            break;
         case 'supplierC':
            contract?.supplierTransporterOrders.forEach((order) => {
               order.supplierTransporterOrderItems.forEach((item) => {
                  price += parseFloat(item.price);
               });
            });
            break;
         case 'rentM':
            let supplierC = 0;
            contract?.supplierTransporterOrders.forEach((order) => {
               order.supplierTransporterOrderItems.forEach((item) => {
                  supplierC += parseFloat(item.price);
               });
            });
            let rentM = 0;
            contract?.orderItems
               .filter((item) => item?.category === 'SUP')
               .forEach((i) => {
                  rentM += parseFloat(i?.vehicleReservation?.subTotal);
               });
            price = rentM - supplierC;
            break;
         default:
            break;
      }
      return formatPrice(price);
   };
   return (
      <>
         <div>
            <TableComponent data={contract.orderItems || []} columns={tableColumns} size="small" />
         </div>
         <div className="flex justify-end mt-4">
            <table className="border-solid border-1">
               <tr className="border-solid border-1">
                  <td className="border-solid border-1 min-w-[180px] p-1">{t('pages.contracts.transporterCostHT')}</td>
                  <td className="border-solid border-1 min-w-[80px] text-center">{calculatePrices('transporterC')}</td>
               </tr>
               <tr className="border-solid border-1">
                  <td className="p-1 border-solid border-1">{t('pages.contracts.transporterMarginHT')}</td>
                  <td className="text-center border-solid border-1">{calculatePrices('transporterM')}</td>
               </tr>
               <tr className="border-solid border-1">
                  <td className="p-1 border-solid border-1">{t('pages.contracts.supplierCostHT')}</td>
                  <td className="text-center border-solid border-1">{calculatePrices('supplierC')}</td>
               </tr>
               <tr className="border-solid border-1">
                  <td className="p-1 border-solid border-1">{t('pages.contracts.rentMarginHT')}</td>
                  <td className="text-center border-solid border-1">{calculatePrices('rentM')}</td>
               </tr>
            </table>
         </div>

         <AddInventoryMachine inventoryMachine={inventoryMachine} setInventoryMachine={setInventoryMachine} />
         <AddSupplierMachine supplierMachine={supplierMachine} setSupplierMachine={setSupplierMachine} />
         <AddSaleItems saleItems={saleItems} setSaleItems={setSaleItems} />
         <ExchangeMachine
            exchangeMachineVisible={exchangeMachineVisible}
            setExchangeMachineVisible={setExchangeMachineVisible}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
         />
         <TransferOfMachine
            transferOfMachineVisible={transferOfMachineVisible}
            setTransferOfMachineVisible={setTransferOfMachineVisible}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
         />
         <ExtendContract
            extendContractVisible={extendContractVisible}
            setExtendContractVisible={setExtendContractVisible}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
         />
         <Dialog
            header={t('pages.contracts.createNewSupplierPurchaseOrder')}
            className="w-[70vw]"
            visible={transporterDialog}
            onHide={() => setTransporterDialog(false)}
         >
            <TransportOrder
               transporterDialog={transporterDialog}
               setTransporterDialog={setTransporterDialog}
               transporterInputs={transporterInputs}
               transporterErrors={transporterErrors}
               handleTransporterInputChange={handleTransporterInputChange}
               setCreateAddressDialog={setCreateAddressDialog}
               updateAddress={updateAddress}
               customers={customers}
               addresses={addresses}
               handleSaveTransporter={handleSaveTransporter}
               setTransporterInputs={setTransporterInputs}
               items={items}
               setItems={setItems}
               handleItemsChange={handleItemsChange}
               itemsError={itemsError}
               setItemsError={setItemsError}
               handleFocus={handleFocus}
            />
         </Dialog>
         <Dialog
            header={selectedAddressId ? t('pages.contracts.updateAddress') : t('pages.contracts.createNewAddress')}
            className="w-[70vw]"
            visible={createAddressDialog}
            onHide={() => {
               setCreateAddressDialog(false);
               setSelectedAddressId(null);
            }}
            closeOnEscape={false}
         >
            <CreateInformationAddress
               setCreateAddressDialog={setCreateAddressDialog}
               setCreatedAddress={setCreatedAddress}
               customerId={contract?.customer?.id}
               selectedAddressId={selectedAddressId}
            />
         </Dialog>

         <Dialog
            header={t('pages.contracts.deleteOrderItem')}
            visible={deleteOrderItem.dialogVisible}
            style={{ width: '400px' }}
            onHide={() =>
               setDeleteOrderItem({
                  dialogVisible: false,
                  selectedOrderItemId: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.contracts.deleteOrderItemMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.contracts.cancel')}
                  severity="warning"
                  className="mr-2 "
                  onClick={() =>
                     setDeleteOrderItem({
                        dialogVisible: false,
                        selectedOrderItemId: null,
                     })
                  }
               />
               <Button label={t('pages.contracts.delete')} severity="danger" onClick={() => handleDeleteOrderItem()} />
            </div>
         </Dialog>
      </>
   );
};

export default Items;
