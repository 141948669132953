import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardStatistics } from '../../store/apis/SharedApi';
import TableComponent from '../../components/TableComponent';
import { useTranslation } from 'react-i18next';
import { InputText } from 'primereact/inputtext';
import moment from 'moment';

const Exploitation = () => {
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const statistics = useSelector((state) => state.shared.dashboardStatistics);
   const [search, setSearch] = useState('');
   const [searchPendingSubleaseOffers, setSearchPendingSubleaseOffers] = useState('');
   const [searchPendingOffers, setSearchPendingOffers] = useState('');

   const [vehicleGroups, setVehicleGroups] = useState([]);
   const [totalAvailable, setTotalAvailable] = useState(0);
   const [totalSublease, setTotalSublease] = useState(0);
   const [totalVehicles, setTotalVehicles] = useState(0);
   const [totalRentVehicles, setTotalRentVehicles] = useState(0);
   const [totalOffersOfTheMonth, setTotalOffersOfTheMonth] = useState(0);
   const [totalSalesOfTheMonth, setTotalSalesOfTheMonth] = useState(0);
   const [totalPendingSubleaseOffers, setTotalPendingSubleaseOffers] = useState([]);
   const [totalPendingOffers, setTotalPendingOffers] = useState([]);

   useEffect(() => {
      dispatch(getDashboardStatistics());
   }, []);

   useEffect(() => {
      if (!statistics) return;

      // Vehicle Groups
      const vehicleGroups = JSON.parse(statistics).VEHICLE_GROUPS;
      let _vehicleGroups = [];
      Object.keys(vehicleGroups).forEach((key) => {
         console.log('KEY ', key, vehicleGroups[key]);
         _vehicleGroups.push({
            name: key,
            count: vehicleGroups[key],
         });
      });
      setVehicleGroups(_vehicleGroups.sort((a, b) => a.name.localeCompare(b.name)));

      // Total Available
      setTotalAvailable(JSON.parse(statistics).AVAILABLE_VEHICLES);

      // Total Sublease
      setTotalSublease(JSON.parse(statistics).RENT_SUPPLIER_VEHICLES);

      // Total Vehicles
      setTotalVehicles(JSON.parse(statistics).TOTAL_VEHICLES);

      // Total Rent Vehicles
      setTotalRentVehicles(JSON.parse(statistics).RENT_VEHICLES);

      // Total Offers Of The Month
      setTotalOffersOfTheMonth(JSON.parse(statistics).TOTAL_CURRENT_MONTH_OFFERS);

      // Total Sales Of The Month ---- NOT WORKING
      const mySales = JSON.parse(statistics).CUSTOMER_CURRENT_MONTH_OFFERS;
      let _mySales = [];
      let _totalCount = 0;
      Object.keys(mySales).forEach((key) => {
         console.log('KEY ', key, mySales[key]);
         _mySales.push({
            name: key,
            count: mySales[key],
         });
         _totalCount += mySales[key];
      });

      setTotalSalesOfTheMonth(_totalCount);

      // Total Pending Sublease Offers
      const pendingSubleaseOffers = JSON.parse(statistics).PENDING_SUBLEASE_OFFERS;
      let _pendingSubleaseOffers = [];
      Object.keys(pendingSubleaseOffers).forEach((key) => {
         _pendingSubleaseOffers.push({
            customer: pendingSubleaseOffers[key].customer,
            startDate: pendingSubleaseOffers[key].start_date,
            machineGroup: pendingSubleaseOffers[key].machine_group,
         });
      });
      setTotalPendingSubleaseOffers(_pendingSubleaseOffers);

      // Total Pending Offers
      const pendingOffers = JSON.parse(statistics).PENDING_OFFERS;
      let _pendingOffers = [];
      Object.keys(pendingOffers).forEach((key) => {
         _pendingOffers.push({
            customer: pendingOffers[key].customer,
            startDate: pendingOffers[key].start_date,
            machineGroup: pendingOffers[key].machine_group,
         });
      });
      setTotalPendingOffers(_pendingOffers);
   }, [statistics]);

   return (
      <div className="grid !p-0 !m-0 mt-2">
         <div className="col-12 lg:col-4 ">
            <div className="bg-white rounded-md shadow-sm p-3">
               <div className="relative">
                  <InputText
                     value={search}
                     onChange={(e) => setSearch(e.target.value)}
                     placeholder={t('global.search')}
                     id="mainSearch"
                     size="small"
                     className="w-full mb-3"
                  />
                  <i className="pi pi-search absolute right-3 top-[12px]" />
               </div>
               <div className="text-center mb-2">{t('pages.machines.available')}</div>
               <TableComponent
                  data={vehicleGroups.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))}
                  columns={[
                     {
                        name: 'name',
                        body: (rowData) => {
                           return <span>{rowData.name}</span>;
                        },
                     },
                     {
                        name: 'count',
                        body: (rowData) => {
                           return <div className="text-center">{rowData.count}</div>;
                        },
                     },
                  ]}
                  size="small"
               />
            </div>
         </div>

         <div className="col-12 lg:col-8 grid !p-0 !m-0">
            <div className="col-6 sm:col-4 md:col-3 lg:col">
               <div className="bg-white rounded-md shadow-sm p-3 h-[120px]">
                  <div className="text-[14px] text-center border-b-2 pb-2">{t('pages.dashboard.availableMachine')}</div>
                  <div className="text-center text-[18px] mt-3">{totalAvailable}</div>
               </div>
            </div>
            <div className="col-6 sm:col-4 md:col-3 lg:col">
               <div className="bg-white rounded-md shadow-sm p-3 h-[120px]">
                  <div className="text-[14px] text-center border-b-2 pb-2">{t('pages.dashboard.totalSublease')}</div>
                  <div className="text-center text-[18px] mt-3">{totalSublease}</div>
               </div>
            </div>
            <div className="col-6 sm:col-4 md:col-3 lg:col">
               <div className="bg-white rounded-md shadow-sm p-3 h-[120px]">
                  <div className="text-[14px] text-center border-b-2 pb-2">{t('pages.dashboard.parcRent')}</div>
                  <div className="text-center text-[18px] mt-3">
                     {totalRentVehicles} / {totalVehicles}
                  </div>
               </div>
            </div>
            <div className="col-6 sm:col-4 md:col-3 lg:col">
               <div className="bg-white rounded-md shadow-sm p-3 h-[120px]">
                  <div className="text-[14px] text-center border-b-2 pb-2">{t('pages.dashboard.totalOffersOfTheMonth')}</div>
                  <div className="text-center text-[18px] mt-3">{totalOffersOfTheMonth}</div>
               </div>
            </div>
            <div className="col-6 sm:col-4 md:col-3 lg:col">
               <div className="bg-white rounded-md shadow-sm p-3 h-[120px]">
                  <div className="text-[14px] text-center border-b-2 pb-2">{t('pages.dashboard.mySalesOfTheMonth')}</div>
                  <div className="text-center text-[18px] mt-3">{totalSalesOfTheMonth}</div>
               </div>
            </div>

            <div className="col-12 grid !p-0 !m-0 h-full">
               <div className="col-12 lg:col-6">
                  <div className="bg-white rounded-md shadow-sm p-3 h-full">
                     <div className="flex justify-between">
                        <div className="flex items-center">
                           <div className="text-center mb-2">{t('pages.dashboard.pendingSubleaseOffers')}</div>
                           <div className="text-center mb-2 text-[18px] ms-2">({totalPendingSubleaseOffers.length})</div>
                        </div>
                        <div className="relative">
                           <InputText
                              value={searchPendingSubleaseOffers}
                              onChange={(e) => setSearchPendingSubleaseOffers(e.target.value)}
                              placeholder={t('global.search')}
                              id="mainSearch"
                              size="small"
                              className="w-full mb-3"
                           />
                           <i className="pi pi-search absolute right-3 top-[12px]" />
                        </div>
                     </div>

                     <TableComponent
                        data={totalPendingSubleaseOffers.filter((item) => item.customer.toLowerCase().includes(searchPendingSubleaseOffers.toLowerCase()))}
                        columns={[
                           {
                              name: 'custormer',
                              header: t('global.sidebar.customer'),
                              body: (rowData) => {
                                 return <span>{rowData.customer}</span>;
                              },
                           },
                           {
                              name: 'stardDate',
                              header: t('pages.offers.startDate'),
                              body: (rowData) => {
                                 return <div>{moment(rowData.startDate).format('DD/MM/YYYY')}</div>;
                              },
                           },
                           {
                              name: 'stardDate',
                              header: t('global.table.machineGroup'),
                              body: (rowData) => {
                                 return <div>{rowData.machineGroup}</div>;
                              },
                           },
                        ]}
                        size="small"
                     />
                  </div>
               </div>
               <div className="col-12  lg:col-6">
                  <div className="bg-white rounded-md shadow-sm p-3 h-full">
                     <div className="flex justify-between">
                        <div className="flex items-center">
                           <div className="text-center mb-2">{t('pages.dashboard.pendingOffers')}</div>
                           <div className="text-center mb-2 text-[18px] ms-2">({totalPendingOffers.length})</div>
                        </div>

                        <div className="relative">
                           <InputText
                              value={searchPendingOffers}
                              onChange={(e) => setSearchPendingOffers(e.target.value)}
                              placeholder={t('global.search')}
                              id="mainSearch"
                              size="small"
                              className="w-full mb-3"
                           />
                           <i className="pi pi-search absolute right-3 top-[12px]" />
                        </div>
                     </div>

                     <TableComponent
                        data={totalPendingOffers.filter((item) => item.customer.toLowerCase().includes(searchPendingOffers.toLowerCase()))}
                        columns={[
                           {
                              name: 'custormer',
                              header: t('global.sidebar.customer'),
                              body: (rowData) => {
                                 return <span>{rowData.customer}</span>;
                              },
                           },
                           {
                              name: 'stardDate',
                              header: t('pages.offers.startDate'),
                              body: (rowData) => {
                                 return <div>{moment(rowData.startDate).format('DD/MM/YYYY')}</div>;
                              },
                           },
                           {
                              name: 'stardDate',
                              header: t('global.table.machineGroup'),
                              body: (rowData) => {
                                 return <div>{rowData.machineGroup}</div>;
                              },
                           },
                        ]}
                        size="small"
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Exploitation;
