import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: {},
	token: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setToken: (state, action) => {
			state.token = action.payload;
		},
		logout: (state, action) => {
			console.log("logout");
			state.user = {};
			state.token = null;
		},
	},
});

export const { setUser, setToken, logout } = userSlice.actions;

export default userSlice.reducer;