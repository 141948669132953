import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState = {
   selectedLang: 'en-EN',
   languages: [
      {
         id: 1,
         code: 'EN',
         value: 'en-EN',
         name: 'English',
      },
      {
         id: 2,
         code: 'FR',
         value: 'fr-FR',
         name: 'Français',
      },
      //   {
      //      id: 3,
      //      code: 'TR',
      //      value: 'tr-TR',
      //      name: 'Türkçe',
      //   },
   ],
};

export const languageSlice = createSlice({
   name: 'language',
   initialState,
   reducers: {
      setSelectedLang: (state, action) => {
         state.selectedLang = action.payload;
         i18next.changeLanguage(action.payload);
      },
   },
});

export const { setSelectedLang } = languageSlice.actions;

export default languageSlice.reducer;
