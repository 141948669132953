import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   vehicleReservations: [],
};

export const logisticsSlice = createSlice({
   name: 'logistics',
   initialState,
   reducers: {
      setVehicleReservations: (state, action) => {
         state.vehicleReservations = action.payload;
      },
      updateVehicleReservationStatus: (state, action) => {
         const index = state.vehicleReservations.findIndex((item) => +item.id === +action.payload.id);
         state.vehicleReservations[index].status = action.payload.status;
         state.vehicleReservations[index].isDelivered = action.payload.isDelivered;
      },
      updateVehicleReservationDate: (state, action) => {
         const index = state.vehicleReservations.findIndex((item) => +item.id === +action.payload.id);
         state.vehicleReservations[index].beginDate = action.payload.beginDate;
         state.vehicleReservations[index].endDate = action.payload.endDate;
         state.vehicleReservations[index].status = action.payload.status;
      },
      handleCancelOfferWithReservations: (state, action) => {
         const orderItems = action.payload.orderItems;
         orderItems.forEach((orderItem) => {
            const index = state.vehicleReservations.findIndex((item) => +item.id === +orderItem.vehicleReservationId);
            if (index !== -1) {
               state.vehicleReservations[index].status = 'RESERVED';
            }
         });
      },
      handleCreateInvoice: (state, action) => {
         const vehicleReservationId = action.payload;
         const index = state.vehicleReservations.findIndex((item) => +item.id === +vehicleReservationId);
         state.vehicleReservations[index] = {
            ...state.vehicleReservations[index],
            isInvoiced: true,
         };
      },
      reRentVehicleReservation: (state, action) => {
         const index = state.vehicleReservations.findIndex((item) => +item.id === +action.payload.id);
         state.vehicleReservations[index].endDate = action.payload.data.endDate;
         state.vehicleReservations[index].status = action.payload.data.status;
      },
   },
});

export const {
   setVehicleReservations,
   updateVehicleReservationStatus,
   updateVehicleReservationDate,
   handleCancelOfferWithReservations,
   handleCreateInvoice,
   reRentVehicleReservation,
} = logisticsSlice.actions;

export default logisticsSlice.reducer;
