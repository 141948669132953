import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch } from 'react-redux';
import { createOfferDocument } from '../../../../store/apis/OffersApi';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export default function Documents(props) {
   const dispatch = useDispatch();
   const fileUploadRef = useRef(null);

   const resetInputs = () => {
      toast.success(t('pages.offers.createSaleMessage'));
      props.setInformationsInputs({
         orderKindId: 2,
         customerId: null,
         addressId: null,
         referenceNumber: '',
         description: '',
         privacyDescription: '',
         malfunctionCaseNumber: '',
         isFactor: false,
      });
      props.setItems([]);
      props.setDocuments([]);
      props.setCreatedOrderId(null);
      props.setActiveIndex(0);
      props.setCreateSaleVisible(false);
   };

   const onUpload = async () => {
      if (props.documents.length) {
         const files = props.documents.map((file) => file.object);
         const data = {
            orderId: props.createdOrderId,
            documents: files,
         };
         const res = await dispatch(createOfferDocument(data));
         if (res) {
            resetInputs();
         }
      } else {
         resetInputs();
      }
   };

   const onFileSelect = (e) => {
      const newFiles = e.files.map((file) => ({
         name: file.name.lastIndexOf('.') <= 0 ? file.name : file.name.substring(0, file.name.lastIndexOf('.')),
         size: file.size,
         extension: file.name.split('.').pop(),
         object: file,
      }));
      const uniqueNewFiles = newFiles.filter((newFile) => {
         return !props.documents.some((existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size);
      });
      props.setDocuments((prevFiles) => [...prevFiles, ...uniqueNewFiles]);
   };

   const removeFile = (fileToRemove) => {
      props.setDocuments((prevFiles) => prevFiles.filter((file) => file.object !== fileToRemove.object));
   };

   const fileTemplate = (rowData) => {
      return (
         <>
            <Button
               icon="pi pi-eye"
               className="mr-2 p-button-rounded p-button-success"
               onClick={() => window.open(URL.createObjectURL(rowData.object), '_blank')}
            />
            <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => removeFile(rowData)} />
         </>
      );
   };

   const sizeTemplate = (rowData) => {
      return `${(rowData.size / 1024).toFixed(2)} KB`;
   };

   return (
      <>
         <div>
            <FileUpload
               ref={fileUploadRef}
               multiple
               accept="*"
               maxFileSize={2000000}
               onSelect={onFileSelect}
               chooseLabel={t('pages.offers.addNewOfferDocument')}
               uploadOptions={{ style: { display: 'none' } }}
               cancelOptions={{ style: { display: 'none' } }}
               pt={{
                  content: { style: { display: 'none' } },
                  buttonbar: { style: { background: 'none', border: 'none', display: 'flex', justifyContent: 'end' } },
               }}
            />
         </div>
         <div>
            <DataTable value={props.documents} size="small">
               <Column field="name" header={t('global.table.fileName')} style={{ width: '30%' }}></Column>
               <Column field="size" header={t('global.table.size')} body={sizeTemplate} style={{ width: '25%' }}></Column>
               <Column field="extension" header={t('global.table.extension')} style={{ width: '20%' }}></Column>
               <Column body={fileTemplate} header={t('global.table.action')} style={{ width: '25%' }}></Column>
            </DataTable>
         </div>

         <div className="flex justify-between mt-4">
            <Button label={t('global.prev')} icon="pi pi-chevron-left" onClick={() => props.setActiveIndex(props.activeIndex - 1)} />
            <Button label={t('global.saveAndFinish')} icon="pi pi-chevron-right" iconPos="right" onClick={onUpload} />
         </div>
      </>
   );
}
