import { RouterProvider, createBrowserRouter } from "react-router-dom";

import routes from "./routes";
import { Toaster } from "react-hot-toast";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { addLocale } from "primereact/api";
import { setGenders, setRoles } from "./store/slices/sharedSlice";
import { t } from "i18next";

function App() {
	const router = createBrowserRouter(routes);
	const dispatch = useDispatch();
	const { i18n } = useTranslation();

	const { selectedLang } = useSelector((state) => state.language);
	const { genders, roles } = useSelector((state) => state.shared);

	useEffect(() => {
		i18n.changeLanguage(selectedLang);
		// changes role names every time language changes
		if (genders || roles) {
			let tempGenders = [];
			let tempRoles = [];
			genders.forEach((gender) => {
				tempGenders.push({
					id: gender.id,
					name: t(`global.genders.${gender.id}`),
				});
			});
			roles.forEach((role) => {
				tempRoles.push({
					id: role.id,
					name: t(`global.roles.${role.id}`),
				});
			});
			dispatch(setGenders(tempGenders));
			dispatch(setRoles(tempRoles));
		}
	}, [i18n, selectedLang]);

	addLocale("tr", {
		firstDayOfWeek: 0,
		dayNames: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
		dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
		dayNamesMin: ["Pa", "Pz", "Sa", "Ça", "Pe", "Cu", "Ct"],
		monthNames: [
			"Ocak",
			"Şubat",
			"Mart",
			"Nisan",
			"Mayıs",
			"Haziran",
			"Temmuz",
			"Ağustos",
			"Eylül",
			"Ekim",
			"Kasım",
			"Aralık",
		],
		monthNamesShort: ["Ock", "Şbt", "Mrt", "Nsn", "Mys", "Hzr", "Tmz", "Ağs", "Eyl", "Ekm", "Ksm", "Arl"],
		today: "Bugün",
		dateFormat: "dd/mm/yy",
		clear: "Temizle",
	});
	addLocale("fr", {
		firstDayOfWeek: 0,
		dayNames: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
		dayNamesShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
		dayNamesMin: ["Pa", "Pz", "Sa", "Ça", "Pe", "Cu", "Ct"],
		monthNames: [
			"Ocak",
			"Şubat",
			"Mart",
			"Nisan",
			"Mayıs",
			"Haziran",
			"Temmuz",
			"Ağustos",
			"Eylül",
			"Ekim",
			"Kasım",
			"Aralık",
		],
		monthNamesShort: ["Ock", "Şbt", "Mrt", "Nsn", "Mys", "Hzr", "Tmz", "Ağs", "Eyl", "Ekm", "Ksm", "Arl"],
		today: "Bugün",
		dateFormat: "dd/mm/yy",
		clear: "Temizle",
	});

	return (
		<>
			<Toaster position="top-center" reverseOrder={false} />
			<RouterProvider router={router} />
		</>
	);
}

export default App;
