import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   contracts: [],
   contractCount: 0,
   contract: null,
   contractDocuments: [],
};

export const contractsSlice = createSlice({
   name: 'contracts',
   initialState,
   reducers: {
      setContracts: (state, action) => {
         state.contracts = action.payload;
      },
      setContractCount: (state, action) => {
         state.contractCount = action.payload;
      },
      removeDeletedContract: (state, action) => {
         state.contracts = state.contracts.filter((contract) => contract.id !== action.payload);
      },
      setUpdatedContract: (state, action) => {
         const contract = action.payload;
         const ind = state.contracts.findIndex((c) => +c.id === +contract.id);
         if (ind !== -1) {
            if (!contract.isContracted) {
               state.contracts.splice(ind, 1);
               return;
            }
            state.contracts[ind] = contract;
         }
      },
      setContract: (state, action) => {
         state.contract = action.payload;
      },
      addContractItem: (state, action) => {
         const { orderId } = action.payload;
         state.contract !== null && +state.contract.id === +orderId && state.contract.orderItems.push(action.payload);
         const ind = state.contracts.findIndex((contract) => +contract.id === +orderId);
         if (ind !== -1) {
            state.contracts[ind].orderItems.push(action.payload);
         }
      },
      removeContractItem: (state, action) => {
         const { orderId, orderItemId } = action.payload;
         state.contract !== null &&
            +state.contract.id === +orderId &&
            state.contract.orderItems.splice(
               state.contract.orderItems.findIndex((item) => +item.id === +orderItemId),
               1,
            );
         const ind = state.contracts.findIndex((contract) => +contract.id === +orderId);
         if (ind !== -1) {
            state.contracts[ind].orderItems.splice(
               state.contracts[ind].orderItems.findIndex((item) => +item.id === +orderItemId),
               1,
            );
         }
      },
      handleUpdateContractItem: (state, action) => {
         const { orderId, orderItemId, orderItem } = action.payload;
         state.contract !== null &&
            +state.contract.id === +orderId &&
            (state.contract.orderItems[state.contract.orderItems.findIndex((item) => +item.id === +orderItemId)] = orderItem);
         const ind = state.contracts.findIndex((contract) => +contract.id === +orderId);
         if (ind !== -1) {
            state.contracts[ind].orderItems[state.contracts[ind].orderItems.findIndex((item) => +item.id === +orderItemId)] = orderItem;
         }
      },
      handleUpdateVehicleReservation: (state, action) => {
         const { orderId, orderItemId, vehicleReservation } = action.payload;
         state.contract !== null &&
            +state.contract.id === +orderId &&
            (state.contract.orderItems[state.contract.orderItems.findIndex((item) => +item.id === +orderItemId)].vehicleReservation = vehicleReservation);
         const ind = state.contracts.findIndex((contract) => +contract.id === +orderId);
         if (ind !== -1) {
            state.contracts[ind].orderItems[state.contracts[ind].orderItems.findIndex((item) => +item.id === +orderItemId)].vehicleReservation =
               vehicleReservation;
         }
      },
      setContractDocuments: (state, action) => {
         state.contractDocuments = action.payload;
      },
      removeContractDocument: (state, action) => {
         state.contractDocuments = state.contractDocuments.filter((doc) => +doc.id !== +action.payload);
      },
      removeDeletedSupplierTransporterOrder: (state, action) => {
         const { orderId, supplierTransporterOrderId } = action.payload;
         console.log('orderId', orderId, 'supplierTransporterOrderId', supplierTransporterOrderId);
         const ind = state.contracts.findIndex((contract) => +contract.id === +orderId);

			state.contract !== null &&
				+state.contract.id === +orderId &&
				(state.contract.supplierTransporterOrders = state.contract.supplierTransporterOrders.filter((sto) => +sto.id !== +supplierTransporterOrderId));
		},
		duplicateContract: (state, action) => {
			state.contracts.unshift(action.payload);
		},
	},
});

export const {
	setContracts,
	setContractCount,
	removeDeletedContract,
	setUpdatedContract,
	setContract,
	addContractItem,
    duplicateContract,
	removeContractItem,
	handleUpdateContractItem,
	handleUpdateVehicleReservation,
	setContractDocuments,
	addContractDocument,
	removeContractDocument,
	removeDeletedSupplierTransporterOrder,
} = contractsSlice.actions;

export default contractsSlice.reducer;
