import { t } from 'i18next';
import { Steps } from 'primereact/steps';
import React, { useState } from 'react';
import Informations from './Informations';
import Items from './Items';
import Documents from './Documents';
import { Dialog } from 'primereact/dialog';

const CreateSale = (props) => {
   const [activeIndex, setActiveIndex] = useState(0);
   const steps = [
      {
         label: t('pages.offers.informations'),
      },
      {
         label: t('pages.offers.items'),
      },
      {
         label: t('pages.offers.documents'),
      },
   ];
   const [informationsInputs, setInformationsInputs] = useState({
      orderKindId: 2,
      customerId: null,
      addressId: null,
      referenceNumber: '',
      description: '',
      privacyDescription: '',
      malfunctionCaseNumber: '',
      isFactor: false,
   });
   const [items, setItems] = useState([]);
   const [documents, setDocuments] = useState([]);
   const [createdOrderId, setCreatedOrderId] = useState(null);

   return (
      <div>
         <Dialog
            header={t('pages.offers.createANewSale')}
            visible={props.createSaleVisible}
            className="w-[70vw]"
            onHide={() => {
               setInformationsInputs({
                  orderKindId: 2,
                  customerId: null,
                  addressId: null,
                  referenceNumber: '',
                  description: '',
                  privacyDescription: '',
                  malfunctionCaseNumber: '',
                  isFactor: false,
               });
               setItems([]);
               setDocuments([]);
               setCreatedOrderId(null);
               setActiveIndex(0);
               props.setCreateSaleVisible(false);
            }}
         >
            <Steps model={steps} activeIndex={activeIndex} className="mb-5" />
            <div className="p-3">
               {activeIndex === 0 ? (
                  <Informations
                     informationsInputs={informationsInputs}
                     setInformationsInputs={setInformationsInputs}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     createdOrderId={createdOrderId}
                  />
               ) : activeIndex === 1 ? (
                  <Items
                     items={items}
                     setItems={setItems}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     informationsInputs={informationsInputs}
                     createdOrderId={createdOrderId}
                     setCreatedOrderId={setCreatedOrderId}
                  />
               ) : activeIndex === 2 ? (
                  <Documents
                     documents={documents}
                     setDocuments={setDocuments}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     createdOrderId={createdOrderId}
                     setCreatedOrderId={setCreatedOrderId}
                     setCreateSaleVisible={props.setCreateSaleVisible}
                     setInformationsInputs={setInformationsInputs}
                     setItems={setItems}
                  />
               ) : null}
            </div>
         </Dialog>
      </div>
   );
};

export default CreateSale;
