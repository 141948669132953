import { createSlice } from '@reduxjs/toolkit';

const initialState = {
   offers: [],
   offer: null,
   offerDocuments: [],
};

export const offersSlice = createSlice({
   name: 'offers',
   initialState,
   reducers: {
      setOffers: (state, action) => {
         state.offers = action.payload;
      },
      removeOffer: (state, action) => {
         const ids = action.payload;
         const ind = state.offers.findIndex((offer) => +offer.id === +ids[0]);
         if (ind !== -1) {
            state.offers.splice(ind, 1);
         }
      },
      addOffer: (state, action) => {
         state.offers.unshift(action.payload);
      },
      handleUpdateOffer: (state, action) => {
         const offer = action.payload;
         const ind = state.offers.findIndex((o) => +o.id === +offer.id);
         if (ind !== -1) {
            if (offer.isContracted) {
               state.offers.splice(ind, 1);
               return;
            }
            state.offers[ind] = offer;
         }
      },
      addOfferItem: (state, action) => {
         const { orderId } = action.payload;
         state.offer !== null && +state.offer.id === +orderId && state.offer.orderItems.push(action.payload);
         const ind = state.offers.findIndex((offer) => +offer.id === +orderId);
         if (ind !== -1) {
            state.offers[ind].orderItems.push(action.payload);
         }
      },
      setOffer: (state, action) => {
         state.offer = action.payload;
      },
      handleUpdateDetailOffer: (state, action) => {
         const offer = action.payload;
         state.offer !== null && state.offer.id === offer.id && (state.offer = offer);
         const ind = state.offers.findIndex((o) => +o.id === +offer.id);
         if (ind !== -1) {
            state.offers[ind] = offer;
         }
      },
      removeOfferItem: (state, action) => {
         const { orderId, orderItemId } = action.payload;
         state.offer !== null &&
            +state.offer.id === +orderId &&
            state.offer.orderItems.splice(
               state.offer.orderItems.findIndex((item) => +item.id === +orderItemId),
               1,
            );
         const ind = state.offers.findIndex((offer) => +offer.id === +orderId);
         if (ind !== -1) {
            state.offers[ind].orderItems.splice(
               state.offers[ind].orderItems.findIndex((item) => +item.id === +orderItemId),
               1,
            );
         }
      },
      handleUpdateVehicleReservation: (state, action) => {
         const { orderId, orderItemId, vehicleReservation } = action.payload;
         state.offer !== null &&
            +state.offer.id === +orderId &&
            (state.offer.orderItems[state.offer.orderItems.findIndex((item) => +item.id === +orderItemId)].vehicleReservation = vehicleReservation);
         const ind = state.offers.findIndex((offer) => +offer.id === +orderId);
         if (ind !== -1) {
            state.offers[ind].orderItems[state.offers[ind].orderItems.findIndex((item) => +item.id === +orderItemId)].vehicleReservation = vehicleReservation;
         }
      },
      handleUpdateOfferItem: (state, action) => {
         const { orderId, orderItemId, orderItem } = action.payload;
         state.offer !== null &&
            +state.offer.id === +orderId &&
            (state.offer.orderItems[state.offer.orderItems.findIndex((item) => +item.id === +orderItemId)] = orderItem);
         const ind = state.offers.findIndex((offer) => +offer.id === +orderId);
         if (ind !== -1) {
            state.offers[ind].orderItems[state.offers[ind].orderItems.findIndex((item) => +item.id === +orderItemId)] = orderItem;
         }
      },
      setOfferDocuments: (state, action) => {
         state.offerDocuments = action.payload;
      },
      addOfferDocument: (state, action) => {
         if (!state.offer) return;
         console.log(action.payload);
         state.offerDocuments = action.payload;
      },
      removeOfferDocument: (state, action) => {
         const _offerDocuments = state.offerDocuments.filter((document) => +document.id !== +action.payload);
         state.offerDocuments = _offerDocuments;
      },
      duplicateOffer: (state, action) => {
         state.offers.unshift(action.payload);
      },
   },
});

export const {
   setOffers,
   removeOffer,
   addOffer,
   handleUpdateOffer,
   addOfferItem,
   setOffer,
   handleUpdateDetailOffer,
   removeOfferItem,
   handleUpdateOfferItem,
   handleUpdateVehicleReservation,
   setOfferDocuments,
   addOfferDocument,
   removeOfferDocument,
   duplicateOffer,
} = offersSlice.actions;
export default offersSlice.reducer;
