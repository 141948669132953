import TableComponent from '../../../components/TableComponent';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';
import { changeLoadingStatus } from '../../../store/slices/sharedSlice';
import toast from 'react-hot-toast';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { createReminder, getReminders, getUsers, updateReminder } from '../../../store/apis/Customers';

const Reminders = (props) => {
   const dispatch = useDispatch();
   const { user } = useSelector((state) => state.user);
   const { selectedCustomerId, reminders } = useSelector((state) => state.customer);

   const [showAddNewReminder, setShowAddNewReminder] = useState(false);
   const [users, setUsers] = useState();

   const [errors, setErrors] = useState({});

   const [selectedReminder, setSelectedReminder] = useState();

   const [remindersInputs, setRemindersInputs] = useState({
      user: user.id,
      date: '',
      description: '',
   });

   const tableColumns = [
      {
         name: 'users',
         header: t('global.table.user'),
         body: (rowData) => {
            return <span>{rowData.user.name}</span>;
         },
      },
      {
         name: 'reminderDate',
         header: t('global.table.reminderDate'),
         field: 'reminderDate',
         body: (rowData) => {
            return <span>{moment(rowData.date).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'explanation',
         header: t('global.table.explanation'),
         field: 'explanation',
         body: (rowData) => {
            return <span>{rowData.description}</span>;
         },
      },
   ];

   useEffect(() => {
      dispatch(getUsers()).then((res) => {
         setUsers(res);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (!props.customerData) return;
      dispatch(getReminders(props.customerData.id));
   }, [props.customerData]);

   const handleRowClick = (e) => {
      setSelectedReminder(e);

      setShowAddNewReminder(true);
   };

   const handleReminderSave = () => {
      dispatch(changeLoadingStatus(true));
      if (selectedReminder) {
         if (validateForm()) {
            dispatch(updateReminder(selectedReminder, moment(selectedReminder.date).format('YYYY-MM-DD'))).then((res) => {
               toast.success(t('global.updateSuccess'));
               setShowAddNewReminder(false);
               setSelectedReminder(null);
               resetVariables();
            });
         }
      } else {
         if (validateForm()) {
            dispatch(
               createReminder(selectedCustomerId, moment(remindersInputs.date).format('YYYY-MM-DD'), remindersInputs.description, remindersInputs.user),
            ).then((res) => {
               toast.success('Reminder created successfully!');
               setShowAddNewReminder(false);
               setSelectedReminder(null);
               resetVariables();
            });
         }
      }
   };

   // FORM VALIDATION
   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (name, value) => {
      if (selectedReminder) {
         setSelectedReminder((selectedReminder) => ({ ...selectedReminder, [name]: value }));
      }
      setRemindersInputs((prevInfos) => ({
         ...prevInfos,
         [name]: value,
      }));
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['user', 'date', 'description'];
      let isValid = true;
      if (selectedReminder) {
         inputs.forEach((input) => {
            if (!selectedReminder[input]) {
               updateErrors(input, selectedReminder[input]);
               isValid = false;
            }
         });
      } else {
         inputs.forEach((input) => {
            if (!remindersInputs[input]) {
               updateErrors(input, remindersInputs[input]);
               isValid = false;
            }
         });
      }
      return isValid;
   };

   const resetVariables = () => {
      setRemindersInputs({
         user: user.id,
         date: null,
         description: '',
      });
   };

   const getCurrentDate = () => {
      const currentDate = new Date();
      return currentDate;
   };

   return (
      <div>
         <div>
            <div className="flex flex-wrap mt-2 justify-content-end">
               <Button
                  className=" col-12 md:col-3"
                  style={{ background: '#38b000', border: ' 1.5px solid #38b000' }}
                  label={t('global.table.addNewReminder')}
                  onClick={() => setShowAddNewReminder(true)}
               ></Button>
            </div>
            <div className="mt-10 col-6">
               <TableComponent data={reminders} columns={tableColumns} onRowClick={(e) => handleRowClick(e)} size="large" />
            </div>
            <Dialog
               visible={showAddNewReminder}
               onHide={() => {
                  setShowAddNewReminder(false);
                  resetVariables();
                  setSelectedReminder(null);
                  setErrors({});
               }}
               draggable={false}
               header={selectedReminder ? t('pages.customers.reminderDetails') : t('pages.customers.addNewReminder')}
               className="md-width-dialog"
            >
               <div className="grid">
                  <div className="md:col-6 col-12">
                     <label>{t('global.table.user')}*</label>
                     <Dropdown
                        options={users}
                        optionLabel="name"
                        optionValue="id"
                        onChange={(e) => {
                           if (selectedReminder) {
                              handleInputChange('user', { id: e.value });
                           } else {
                              handleInputChange('user', e.value);
                           }
                        }}
                        value={selectedReminder ? selectedReminder.user.id : remindersInputs.user}
                        className={`w-full input-detail ${errors.user && 'input-error'}`}
                     />
                     {errors.user && <small className="p-error">{errors.user}</small>}
                  </div>
                  <div className="md:col-6 col-12">
                     <label>{t('global.table.reminderDate')}*</label>
                     <Calendar
                        onChange={(e) => {
                           handleInputChange('date', e.value);
                        }}
                        minDate={getCurrentDate()}
                        dateFormat="dd/mm/yy"
                        value={selectedReminder ? new Date(selectedReminder.date) : remindersInputs.date}
                        className={`w-full input-detail ${errors.date && 'input-error'}`}
                     />
                     {errors.date && <small className="p-error">{errors.date}</small>}
                  </div>
                  <div className="col-12">
                     <label>{t('global.table.explanation')}*</label>
                     <InputTextarea
                        onChange={(e) => {
                           handleInputChange('description', e.target.value);
                        }}
                        value={selectedReminder ? selectedReminder.description : remindersInputs.description}
                        className={`w-full input-detail ${errors.description && 'input-error'}`}
                     />
                     {errors.description && <small className="p-error">{errors.description}</small>}
                  </div>
               </div>
               <div className="grid mt-3">
                  <div className="flex justify-end col-12">
                     <Button label={selectedReminder ? t('global.update') : t('global.create')} icon="pi pi-check" onClick={() => handleReminderSave()} />
                  </div>
               </div>
            </Dialog>
         </div>
      </div>
   );
};

export default Reminders;
