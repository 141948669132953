import React, { useEffect, useState } from 'react';
import OrdersInfoCards from './OrdersInfoCards';
import { Button } from 'primereact/button';
import { t } from 'i18next';
import TableComponent from '../../../components/TableComponent';
import moment from 'moment';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import {
   deleteOffer as removeOffer,
   getCustomers,
   getOfferKinds,
   getOffers,
   getUsers,
   getOffer,
   duplicateSelectedOffer,
   getOfferStatistics,
} from '../../../store/apis/OffersApi';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import toast from 'react-hot-toast';
import CreateSale from './CreateSale/CreateSale';
import AddNewOffer from './AddNewOffer/AddNewOffer';
import OfferDetail from './OfferDetail/OfferDetail';
import { useLocation } from 'react-router-dom';

const Offers = () => {
   const dispatch = useDispatch();
   const location = useLocation();
   const { offers } = useSelector((state) => state.offers);
   const [searchKeyword, setsearchKeyword] = useState('');
   const [filters, setFilters] = useState();
   const [showFilters, setShowFilters] = useState(false);
   const [offerCount, setOfferCount] = useState(0);
   const [rows, setRows] = useState(20);
   const [first, setFirst] = useState(0);
   const [offerKinds, setOfferKinds] = useState();
   const [users, setUsers] = useState();
   const [customers, setCustomers] = useState();
   const [deleteOffer, setDeleteOffer] = useState({
      dialogVisible: false,
      selectedOfferId: null,
   });
   const [duplicateOffer, setDuplicateOffer] = useState({
      dialogVisible: false,
      selectedOfferId: null,
   });
   const [createSaleVisible, setCreateSaleVisible] = useState(false);
   const [addNewOfferVisible, setAddNewOfferVisible] = useState(false);
   const [offerDetailVisible, setOfferDetailVisible] = useState(false);
   const [offerStatistics, setOfferStatistics] = useState(null);

   useEffect(() => {
      dispatch(getOfferStatistics()).then((res) => setOfferStatistics(res));
   }, []);

   async function fetchOffers() {
      const res = await dispatch(getOffers(rows, first, searchKeyword, filters));
      if (res) {
         setOfferCount(res.orderCount);
      }
   }

   useEffect(() => {
      fetchOffers();
   }, [first, rows]);

   useEffect(() => {
      if (!showFilters) return;
      dispatch(getOfferKinds()).then((res) => setOfferKinds(res));
      dispatch(getCustomers()).then((res) => setCustomers(res));
      dispatch(getUsers()).then((res) => setUsers(res));
   }, [showFilters]);

   useEffect(() => {
      if (location.state?.orderId) {
         dispatch(getOffer(location.state.orderId)).then(() => {
            setOfferDetailVisible(true);
         });
      }
      const onbeforeunload = () => {
         //clear location state
         window.history.replaceState(null, '');
      };
      window.addEventListener('beforeunload', onbeforeunload);
      return () => {
         window.removeEventListener('beforeunload', onbeforeunload);
      };
   }, [location.state]);

   const showOfferDetail = (rowData) => {
      dispatch(getOffer(+rowData.id)).then(() => {
         setOfferDetailVisible(true);
      });
   };

   const handleDuplicateOffer = async () => {
      const res = await dispatch(duplicateSelectedOffer(+duplicateOffer.selectedOfferId));
      if (res) {
         showOfferDetail(res);
         toast.success(t('pages.offers.duplicateOfferSuccessMessage'));
         setDuplicateOffer({
            dialogVisible: false,
            selectedOfferId: null,
         });
      }
   };

   const tableColumns = [
      {
         name: 'type',
         header: t('global.table.type'),
         body: (rowData) => {
            return <span>{rowData.orderKind.name}</span>;
         },
      },
      {
         name: 'orderNumber',
         header: t('global.table.offerNumber'),
         body: (rowData) => {
            return <span>{rowData.orderNumber}</span>;
         },
      },
      {
         name: 'status',
         header: t('global.table.status'),
         body: (rowData) => {
            return <span>{rowData.isRefused ? 'Refused' : rowData.isOffered ? 'Offered' : '-'}</span>;
         },
      },
      {
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         name: 'customerName',
         header: t('global.table.customerName'),
         body: (rowData) => {
            return <span>{rowData.customer.companyName}</span>;
         },
      },
      {
         name: 'placeName',
         header: t('global.table.placeName'),
         body: (rowData) => {
            return <span>{rowData.address.placeName}</span>;
         },
      },
      {
         name: 'machines',
         header: t('global.table.machines'),
         body: (rowData) => {
            return (
               <span>
                  {rowData.orderItems &&
                     rowData.orderItems.map((item) =>
                        item.category === 'SUP'
                           ? item.vehicleReservation?.vehicle?.machineFullCode.replace(/\s/g, '') + ', '
                           : item.category === 'INV'
                           ? item.vehicleReservation?.vehicleGroup?.name + ', '
                           : '',
                     )}
               </span>
            );
         },
      },
      {
         name: 'actions',
         body: (rowData) => {
            return (
               <div className="flex justify-center">
                  <div className="flex items-center">
                     <Button className="mx-1" icon="pi pi-pencil" severity="warning" onClick={() => showOfferDetail(rowData)} />
                     <Button
                        className="mx-1"
                        icon="pi pi-trash"
                        severity="danger"
                        onClick={() =>
                           setDeleteOffer({
                              dialogVisible: true,
                              selectedOfferId: rowData.id,
                           })
                        }
                     />
                     <Button
                        className="mx-1"
                        icon="pi pi-copy"
                        severity="info"
                        onClick={() =>
                           setDuplicateOffer({
                              dialogVisible: true,
                              selectedOfferId: rowData.id,
                           })
                        }
                     />
                  </div>
               </div>
            );
         },
      },
   ];

   return (
      <div className="flex flex-col w-full">
         <OrdersInfoCards offerStatistics={offerStatistics} />
         <Card className="mt-4">
            <div className="flex justify-between mb-3">
               <div>
                  <span className="p-input-icon-left">
                     <i className="pi pi-search" />
                     <InputText
                        value={searchKeyword}
                        onChange={(e) => setsearchKeyword(e.target.value)}
                        placeholder={t('global.search')}
                        size="small"
                        onKeyDown={(e) => {
                           if (e.key === 'Enter') {
                              dispatch(getOffers(rows, first, e.target.value));
                           }
                        }}
                     />
                  </span>
                  <Button
                     icon={filters ? 'pi pi-filter-slash' : 'pi pi-filter'}
                     className="ml-2"
                     onClick={() => {
                        if (filters) {
                           setFilters(null);
                           setsearchKeyword('');
                           dispatch(getOffers(rows, first));
                        } else {
                           setShowFilters(true);
                        }
                     }}
                     size="small"
                     severity={filters && 'danger'}
                  />
               </div>
               <div>
                  <Button label={t('pages.offers.createSale')} className="me-4" onClick={() => setCreateSaleVisible(true)} />
                  <Button label={t('pages.offers.addNewOffer')} severity="success" onClick={() => setAddNewOfferVisible(true)} />
               </div>
            </div>
            <TableComponent data={offers || []} columns={tableColumns} size="small" />
            <div className="flex items-center justify-center">
               <Paginator first={first} rows={rows} totalRecords={offerCount} onPageChange={(e) => setFirst(e.first)} />
            </div>
         </Card>
         {/* FILTERS DIALOG */}
         <Dialog header={t('global.filters')} visible={showFilters} onHide={() => setShowFilters(false)} className="md-width-dialog" draggable={false}>
            <div className="grid">
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.offers.quantity')}</label>
                  <InputNumber
                     value={rows}
                     min={1}
                     onValueChange={(e) => setRows(e.value)}
                     showButtons
                     buttonLayout="horizontal"
                     className="w-full input-detail"
                     decrementButtonClassName="p-button-secondary"
                     incrementButtonClassName="p-button-secondary"
                     incrementButtonIcon="pi pi-plus"
                     decrementButtonIcon="pi pi-minus"
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('global.search')}</label>
                  <InputText
                     className="w-full p-inputtext-sm input-detail"
                     name="search"
                     value={searchKeyword}
                     onChange={(e) => setsearchKeyword(e.target.value)}
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.offers.type')}</label>
                  <Dropdown
                     options={offerKinds}
                     value={filters?.orderKindId}
                     onChange={(e) => setFilters({ ...filters, orderKindId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('pages.offers.customer')}</label>
                  <Dropdown
                     filter
                     options={customers}
                     value={filters?.customerId}
                     onChange={(e) => setFilters({ ...filters, customerId: e.value })}
                     optionLabel="companyName"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label>{t('global.table.user')}</label>
                  <Dropdown
                     filter
                     options={users}
                     value={filters?.userId}
                     onChange={(e) => setFilters({ ...filters, userId: e.value })}
                     optionLabel="name"
                     optionValue="id"
                     className="w-full input-detail"
                     showClear
                  />
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center w-full justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>{t('pages.offers.offered')}</label>
                     <InputSwitch checked={filters?.isOffered} onChange={(e) => setFilters({ ...filters, isOffered: e.value })} />
                  </div>
               </div>
               <div className="flex flex-wrap md:col-6 col-12">
                  <label className="text-white">.</label>
                  <div className="flex align-items-center w-full justify-content-between px-3 py-1.5 border rounded border-box input-detail">
                     <label>{t('pages.offers.refused')}</label>
                     <InputSwitch checked={filters?.isRefused} onChange={(e) => setFilters({ ...filters, isRefused: e.value })} />
                  </div>
               </div>
            </div>
            <div className="flex justify-center col-12 md:justify-end">
               <Button
                  icon="pi pi-check"
                  label={t('global.apply')}
                  onClick={() => {
                     dispatch(getOffers(rows, first, searchKeyword, filters));
                     setShowFilters(false);
                  }}
                  size="small"
               />
            </div>
         </Dialog>
         {/* Delete Offer Dialog */}
         <Dialog
            header={t('pages.offers.deleteOffer')}
            visible={deleteOffer.dialogVisible}
            style={{ width: '400px' }}
            onHide={() =>
               setDeleteOffer({
                  dialogVisible: false,
                  selectedOfferId: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.offers.deleteOfferMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="warning"
                  className="mr-2 "
                  onClick={() =>
                     setDeleteOffer({
                        dialogVisible: false,
                        selectedOfferId: null,
                     })
                  }
               />
               <Button
                  label={t('pages.offers.delete')}
                  severity="danger"
                  onClick={() => {
                     dispatch(removeOffer([+deleteOffer.selectedOfferId])).then(
                        setDeleteOffer({
                           dialogVisible: false,
                           selectedOfferId: null,
                        }),
                        toast.success(t('pages.offers.deletedOfferMessage')),
                     );
                  }}
               />
            </div>
         </Dialog>
         {/* Duplicate Offer Dialog */}
         <Dialog
            header={t('pages.offers.duplicateOffer')}
            visible={duplicateOffer.dialogVisible}
            style={{ width: '400px' }}
            onHide={() =>
               setDuplicateOffer({
                  dialogVisible: false,
                  selectedOfferId: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.offers.duplicateOfferMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.offers.cancel')}
                  severity="danger"
                  className="mr-2 "
                  onClick={() =>
                     setDuplicateOffer({
                        dialogVisible: false,
                        selectedOfferId: null,
                     })
                  }
               />
               <Button label={t('global.save')} severity="success" onClick={handleDuplicateOffer} />
            </div>
         </Dialog>
         {/* Create Sale */}
         <CreateSale createSaleVisible={createSaleVisible} setCreateSaleVisible={setCreateSaleVisible} />

         {/* Add New Offer */}
         <AddNewOffer addNewOfferVisible={addNewOfferVisible} setAddNewOfferVisible={setAddNewOfferVisible} />

         {/* Offer Detail */}
         <OfferDetail setOfferDetailVisible={setOfferDetailVisible} offerDetailVisible={offerDetailVisible} />
      </div>
   );
};

export default Offers;
