import { t } from 'i18next';
import { Button } from 'primereact/button';
import { Steps } from 'primereact/steps';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import Informations from './Informations';
import Malfunctions from './Malfunctions';
import { useLocation } from 'react-router-dom';

const CreateVehicleMalfunction = (props) => {
   const [activeIndex, setActiveIndex] = useState(0);
   const [createdId, setCreatedId] = useState(null);
   const location = useLocation();
   const steps = [
      {
         label: t('pages.malfunctions.informations'),
      },
      {
         label: t('pages.malfunctions.malfunctions'),
      },
   ];

   const [informationsInputs, setInformationsInputs] = useState({
      vehicleMalfunctionKindId: null,
      vehicleId: null,
      faultDate: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      description: '',
      note: '',
      customerId: null,
      addressId: null,
   });

   const [malfunctionsInputs, setMalfunctionsInputs] = useState({
      isMalfunctionOperatorDefault: false,
      isMalfunctionBatteryFailure: false,
      isMalfunctionEngineFailure: false,
      isMalfunctionBodyDamage: false,
      isMalfunctionImproperHandling: false,
      isMalfunctionChargerFailure: false,
      isMalfunctionElectricalFailure: false,
      isMalfunctionSafetyAccessoriesFailure: false,
      isMalfunctionRemoteControlFailure: false,
      isMalfunctionBatteryCharger: false,
      isMalfunctionTornCable: false,
      isMalfunctionHydraulicFailure: false,
      isMalfunctionOther: false,
      malfunctionOther: '',
   });

   useEffect(() => {
      if (props.createDialogVisible == false) {
         setActiveIndex(0);
         setInformationsInputs({
            vehicleMalfunctionKindId: null,
            vehicleId: null,
            faultDate: '',
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            description: '',
            note: '',
            customerId: null,
            addressId: null,
         });
         setMalfunctionsInputs({
            isMalfunctionOperatorDefault: false,
            isMalfunctionBatteryFailure: false,
            isMalfunctionEngineFailure: false,
            isMalfunctionBodyDamage: false,
            isMalfunctionImproperHandling: false,
            isMalfunctionChargerFailure: false,
            isMalfunctionElectricalFailure: false,
            isMalfunctionSafetyAccessoriesFailure: false,
            isMalfunctionRemoteControlFailure: false,
            isMalfunctionBatteryCharger: false,
            isMalfunctionTornCable: false,
            isMalfunctionHydraulicFailure: false,
            isMalfunctionOther: false,
            malfunctionOther: '',
         });
      }
   }, [props.createDialogVisible]);

   useEffect(() => {
      if (location.state && location.state.vehicleId) {
         setInformationsInputs({
            ...informationsInputs,
            vehicleId: location.state.vehicleId,
            vehicleMalfunctionKindId: location.state.type,
            customerId: location.state.customerId,
         });
         props.setCreateDialogVisible(true);
      }

      const onbeforeunload = () => {
         window.history.replaceState(null, '');
      };
      window.addEventListener('beforeunload', onbeforeunload);
      return () => {
         window.removeEventListener('beforeunload', onbeforeunload);
      };
   }, [location.state]);

   useEffect(() => {
      if (props.reservation) {
         setInformationsInputs({
            vehicleId: props.reservation.vehicle.id,
            customerId: props.reservation.customer.id,
            vehicleMalfunctionKindId: props.selectedType ? props.selectedType : null,
         });
      }
   }, [props]);

   return (
      <div>
         <Dialog
            header={t('pages.malfunctions.addNewMalfunctionRecord')}
            visible={props.createDialogVisible}
            className="w-[90vw]"
            onHide={() => props.setCreateDialogVisible(false)}
            draggable={false}
         >
            <Steps model={steps} activeIndex={activeIndex} className="mb-5" />
            <div className="p-3">
               {activeIndex === 0 ? (
                  <Informations
                     informationsInputs={informationsInputs}
                     setInformationsInputs={setInformationsInputs}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     createdId={createdId}
                     setCreatedId={setCreatedId}
                  />
               ) : activeIndex === 1 ? (
                  <Malfunctions
                     malfunctionsInputs={malfunctionsInputs}
                     setMalfunctionsInputs={setMalfunctionsInputs}
                     activeIndex={activeIndex}
                     setActiveIndex={setActiveIndex}
                     setCreateDialogVisible={props.setCreateDialogVisible}
                     createdId={createdId}
                     setCreatedId={setCreatedId}
                     comfirmMalfunction={props.comfirmMalfunction}
                     setComfirmMalfunction={props.setComfirmMalfunction}
                  />
               ) : null}
            </div>
         </Dialog>
      </div>
   );
};

export default CreateVehicleMalfunction;
