import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUnpaidInvoices } from '../../../../store/apis/ManagementApi';
import TableComponent from '../../../../components/TableComponent';
import { t } from 'i18next';
import moment from 'moment';
import { Button } from 'primereact/button';

const PrevMonthsReports = ({ prevMonthsReportsDialog, setPrevMonthsReportsDialog }) => {
   const dispatch = useDispatch();
   const [prevMonthsReports, setPrevMonthsReports] = useState([]);

   /* useEffect(() => {
      dispatch(getInvoices()).then((res) => {
         setPrevMonthsReports(res);
      });
   }, []); */

   const tableColumns = [
      {
         field: 'id',
         name: 'id',
         header: t('pages.planning.id'),
         body: (rowData) => {
            return <span>{rowData?.id}</span>;
         },
      },
      {
         field: 'creationDate',
         name: 'creationDate',
         header: t('global.table.creationDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.createdAt).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'accountingReference',
         name: 'accountingReference',
         header: t('pages.invoices.accountingReference'),
         body: (rowData) => {
            return <span>{rowData?.accountingReference}</span>;
         },
      },
      {
         field: 'journal',
         name: 'journal',
         header: t('pages.invoices.sale'),
         body: (rowData) => {
            return <span>{rowData?.journal}</span>;
         },
      },
      {
         field: 'amountInvoicedBeforeTax',
         name: 'amountInvoicedBeforeTax',
         header: t('pages.invoices.amountInvoicedBeforeTax'),
         body: (rowData) => {
            return <span>€{rowData?.totalBeforeTax}</span>;
         },
      },
      {
         field: 'amountReceivedBeforeTax',
         name: 'amountReceivedBeforeTax',
         header: t('pages.invoices.amountReceivedBeforeTax'),
         body: (rowData) => {
            return <span>€{rowData?.totalBeforeTax}</span>;
         },
      },
      {
         field: 'libelle',
         name: 'libelle',
         header: t('pages.invoices.libelle'),
         body: (rowData) => {
            return <span>{rowData?.libelle}</span>;
         },
      },
      {
         field: 'invoiceNumber',
         name: 'invoiceNumber',
         header: t('pages.invoices.invoiceNumber'),
         body: (rowData) => {
            return <span>{rowData?.invoiceNumber}</span>;
         },
      },
      {
         field: 'dueDate',
         name: 'dueDate',
         header: t('pages.invoices.dueDate'),
         body: (rowData) => {
            return <span>{moment(rowData?.dueDate).format('DD/MM/YYYY')}</span>;
         },
      },
      {
         field: 'user',
         name: 'user',
         header: t('global.table.user'),
         body: (rowData) => {
            return <span>{rowData?.customer?.user.name || '-'}</span>;
         },
      },
      {
         field: 'comment',
         name: 'comment',
         header: t('pages.invoices.comment'),
      },
   ];

   return (
      <>
         <Dialog
            header={t('pages.invoices.previousMonthsReports')}
            visible={prevMonthsReportsDialog}
            style={{ width: '80vw' }}
            onHide={() => setPrevMonthsReportsDialog(false)}
         >
            <div>
               <Button icon="pi pi-file-pdf" severity="info" className="!text-[14px] mb-3" />
            </div>
            <TableComponent data={[]} columns={tableColumns} scrollable={true} />
         </Dialog>
      </>
   );
};

export default PrevMonthsReports;
