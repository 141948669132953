import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import client from "../../graphql/apollo.config";
import { LOGIN } from "../../graphql/authorization";
import { useDispatch, useSelector } from "react-redux";
import { changeLoadingStatus } from "../../store/slices/sharedSlice";
import toast from "react-hot-toast";
import { t } from "i18next";
import { setToken, setUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { selectedLang } = useSelector((state) => state.language);
	const { loading } = useSelector((state) => state.shared);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleLogin = async () => {
		dispatch(changeLoadingStatus(true));
		await client
			.mutate({
				mutation: LOGIN,
				variables: {
					language: selectedLang.slice(3, 5),
					email,
					password,
				},
			})
			.then((res) => {
				console.log(res);
				dispatch(setUser(res.data.signIn.user));
				dispatch(setToken(res.data.signIn.token));
				navigate("/dashboard");
				window.location.reload();
			})
			.catch((err) => {
				console.log(err);
				if (err.message.includes("does not exist")) {
					toast.error(t("authorization.login.doesNotExist"));
				}
			})
			.finally(() => {
				dispatch(changeLoadingStatus(false));
			});
	};
	return (
		<div className="flex h-full items-center justify-center">
			<div className="surface-card p-3 md:p-5 shadow-2 border-round full-width-dialog lg:w-4">
				<div className="grid items-center justify-center flex-col mb-5 mt-3">
					<img src={require("../../assets/logo.png")} alt="metaloc" className="mb-3 w-1/2" />
				</div>
				<div className="mb-3">
					<label htmlFor="email" className="block text-900 font-medium mb-2">
						Email
					</label>
					<InputText
						type="email"
						placeholder="Email address"
						className="w-full mb-3"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label htmlFor="password" className="block text-900 font-medium mb-2">
						Password
					</label>
					<InputText
						type="password"
						placeholder="Password"
						className="w-full mb-3"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					{/* <div className="flex align-items-center justify-content-between mb-6">
						<a href="/" className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
							Forgot your password?
						</a>
					</div> */}
				</div>
				<div className="grid items-center justify-center">
					<Button label="Sign In" className="w-auto border" onClick={() => handleLogin()} loading={loading} />
				</div>
			</div>
		</div>
	);
};

export default Login;
