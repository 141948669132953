import React from 'react';

import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';

import { ConfirmDialog } from 'primereact/confirmdialog'; // For <ConfirmDialog /> component
import { confirmDialog } from 'primereact/confirmdialog'; // For confirmDialog method

import { t } from 'i18next';
import i18n from '../../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import client from '../../../graphql/apollo.config';
import { DELETE_USER } from '../../../graphql/users';
import { changeLoadingStatus, setDialogFlag } from '../../../store/slices/sharedSlice';

const UserDetails = (props) => {
   const dispatch = useDispatch();
   const { loading } = useSelector((state) => state.shared);

   const handleUpdateUser = () => {
      //
   };

   const handleDeleteUser = () => {
      confirmDialog({
         message: t('pages.settings.users.deleteConfirmation'),
         header: t('global.warning'),
         icon: 'pi pi-exclamation-triangle',
         acceptClassName: 'p-button-danger',
         accept: () => {
            dispatch(changeLoadingStatus(true));
            client
               .mutate({
                  mutation: DELETE_USER,
                  variables: { ids: [+props.user.id] },
               })
               .then((res) => {
                  console.log(res);
                  toast.success(t('pages.settings.users.userDeleteSuccess'));
                  dispatch(setDialogFlag(true));
               })
               .catch((err) => {
                  console.log(err);
                  toast.error(err.message);
               })
               .finally(() => {
                  dispatch(changeLoadingStatus(false));
               });
         },
         reject: () => {},
      });
   };

   return (
      <div className="grid">
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.firstName')}*</label>
            <InputText
               value={props.user.firstName}
               // onChange={(e) => setFirstName(e.target.value)}
               placeholder={t('global.table.firstName')}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.lastName')}*</label>
            <InputText
               value={props.user.lastName}
               // onChange={(e) => setLastName(e.target.value)}
               placeholder={t('global.table.lastName')}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.email')}*</label>
            <InputText
               value={props.user.email}
               // onChange={(e) => setEmail(e.target.value)}
               placeholder={t('global.table.email')}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.phoneNumber')}*</label>
            <InputMask
               value={props.user.phone}
               // onChange={(e) => setPhone(e.target.value)}
               mask="99 99 99 99 99"
               placeholder={t('global.table.phoneNumber')}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.birthdate')}*</label>
            <Calendar
               value={new Date(props.user.birthdate)}
               // onChange={(e) => setBirthdate(e.value)}
               placeholder={t('pages.settings.users.selectBirthdate')}
               className="w-full"
               dateFormat="dd/mm/yy"
               locale={i18n.language.toLowerCase().slice(0, 2)}
               maxDate={new Date()}
               showIcon
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.gender')}*</label>
            <Dropdown
               placeholder={t('pages.settings.users.selectGender')}
               // options={genders}
               optionLabel="name"
               value={props.user.selectedGender}
               // onChange={(e) => setSelectedGender(e.value)}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('pages.settings.users.password')}*</label>
            <InputText
               value={props.user.password}
               // onChange={(e) => setPassword(e.target.value)}
               placeholder={t('pages.settings.users.password')}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.role')}*</label>
            <Dropdown
               placeholder={t('pages.settings.users.selectRole')}
               // options={roles}
               optionLabel="name"
               value={props.user.selectedRole}
               // onChange={(e) => setSelectedRole(e.value)}
               className="w-full"
            />
         </div>
         <div className="flex flex-wrap md:col-4 col-12">
            <label>{t('global.table.identityNumber')}</label>
            <InputText
               placeholder={t('global.table.identityNumber')}
               value={props.user.idNumber}
               // onChange={(e) => setIdNumber(e.value)}
               className="w-full"
               maxLength={16}
            />
         </div>
         <div className="flex flex-wrap col-12">
            <label>{t('global.table.notes')}</label>
            <InputTextarea
               placeholder={t('global.table.notes')}
               value={props.user.note}
               // onChange={(e) => setNote(e.value)}
               className="w-full"
            />
         </div>
         <div className="grid items-center justify-between mt-3">
            <Button
               label={t('pages.settings.users.deleteUser')}
               onClick={() => handleDeleteUser()}
               icon="pi pi-trash"
               loading={loading}
               className="ml-3"
               severity="danger"
            />
            <Button label={t('pages.settings.users.createUser')} onClick={() => handleUpdateUser()} icon="pi pi-check" loading={loading} />
         </div>
         <ConfirmDialog />
      </div>
   );
};

export default UserDetails;
