import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableComponent from '../../../components/TableComponent';
import { useDispatch } from 'react-redux';
import {
   createItemTransportation,
   deleteItemTransportation,
   getItemTransportations,
   getVehicleReservationsForTours,
   updateAddress,
   updateItemTransportation,
   updateVehicleReservationDriver,
} from '../../../store/apis/LogisticsApi';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const Tours = () => {
   const { t } = useTranslation();
   const dispatch = useDispatch();
   const [departures, setDepartures] = useState([]);
   const [returns, setReturns] = useState([]);
   const [_allDepartures, setAllDepartures] = useState([]);
   const [_allReturns, setAllReturns] = useState([]);
   const [selectedDriver, setSelectedDriver] = useState('WAITING');
   const [createVisible, setCreateVisible] = useState(false);
   const [createStatus, setCreateStatus] = useState(null);
   const [dialogStatus, setDialogStatus] = useState('CREATE');
   const [updatedItemId, setUpdatedItemId] = useState(null);
   const [updateReservationDiaolog, setUpdateReservationDiaolog] = useState(false);
   const [updateReservationData, setUpdateReservationData] = useState({});
   const [updateAddressData, setUpdateAddressData] = useState({});

   const driverOptions = [
      { label: 'Driver1', value: 'DRIVER1' },
      { label: 'Driver2', value: 'DRIVER2' },
      { label: 'DriverST', value: 'DRIVER3' },
      { label: 'DriverST2', value: 'DRIVER4' },
      { label: 'DriverST3', value: 'DRIVER5' },
      { label: 'DriverST4', value: 'DRIVER6' },
      { label: 'ITE', value: 'DRIVER7' },
      { label: 'Top Remorquage', value: 'DRIVER8' },
      { label: 'Waiting List', value: 'WAITING' },
      { label: 'ALL', value: 'ALL' },
   ];

   const [createData, setCreateData] = useState({
      status: null,
      customer: '',
      contractNo: '',
      deliveryAddress: '',
      contact: '',
      description: '',
      machineCode: '',
      comment: '',
      driver: null,
   });

   useEffect(() => {
      const fetchData = async () => {
         const vehicleReservations = await dispatch(
            getVehicleReservationsForTours({
               statuses: ['RENT', 'RETURN_PLANED'],
               isContracted: true,
               isSupplier: false,
               // isNext: true,
            }),
         );
         const itemTransportations = await dispatch(
            getItemTransportations({
               isNext: true,
            }),
         );

         const _depfilteredVehicleReservations = vehicleReservations
            ? vehicleReservations.filter((item) => item.status === 'RENT' && item.deliverDriver === null && item.isDelivered === false)
            : [];
         const _depfilteredItemTransportations = itemTransportations ? itemTransportations.filter((item) => item.status === 'RENT' && !item.driver) : [];
         setDepartures([..._depfilteredVehicleReservations, ..._depfilteredItemTransportations]);
         // setDepartures([
         //    ...vehicleReservations?.filter((item) => item.status === 'RENT' && item.deliverDriver === null),
         //    ...itemTransportations?.filter((item) => item.status === 'RENT' && !item.driver),
         // ]);

         const _alldepfilteredVehicleReservations = vehicleReservations
            ? vehicleReservations.filter((item) => item.status === 'RENT' && item.isDelivered === false)
            : [];
         const _alldepfilteredItemTransportations = itemTransportations ? itemTransportations.filter((item) => item.status === 'RENT') : [];
         setAllDepartures([..._alldepfilteredVehicleReservations, ..._alldepfilteredItemTransportations]);
         // setAllDepartures([...vehicleReservations.filter((item) => item.status === 'RENT'), ...itemTransportations.filter((item) => item.status === 'RENT')]);

         const _retfilteredVehicleReturns = vehicleReservations
            ? vehicleReservations.filter((item) => item.status === 'RETURN_PLANED' && item.returnDriver === null)
            : [];
         const _retfilteredItemReturns = itemTransportations ? itemTransportations.filter((item) => item.status === 'RETURN' && !item.driver) : [];
         setReturns([..._retfilteredVehicleReturns, ..._retfilteredItemReturns]);
         // setReturns([
         //    ...vehicleReservations.filter((item) => item.status === 'RETURN_PLANED' && item.returnDriver === null),
         //    ...itemTransportations.filter((item) => item.status === 'RETURN' && !item.driver),
         // ]);

         const _allretfilteredVehicleReturns = vehicleReservations ? vehicleReservations.filter((item) => item.status === 'RETURN_PLANED') : [];
         const _allretfilteredItemReturns = itemTransportations ? itemTransportations.filter((item) => item.status === 'RETURN') : [];

         setAllReturns([..._allretfilteredVehicleReturns, ..._allretfilteredItemReturns]);
         // setAllReturns([
         //    ...vehicleReservations.filter((item) => item.status === 'RETURN_PLANED'),
         //    ...itemTransportations.filter((item) => item.status === 'RETURN'),
         // ]);
      };
      fetchData();
   }, []);

   useEffect(() => {
      if (selectedDriver && selectedDriver !== 'ALL' && selectedDriver !== 'WAITING') {
         if (createStatus) setCreateData({ ...createData, driver: selectedDriver });
         setDepartures(_allDepartures.filter((item) => item.driver === selectedDriver || item.deliverDriver === selectedDriver));
         setReturns(_allReturns.filter((item) => item.driver === selectedDriver || item.returnDriver === selectedDriver));
      } else if (selectedDriver === 'WAITING') {
         if (createStatus) setCreateData({ ...createData, driver: null });
         setDepartures(_allDepartures.filter((item) => item.driver === null || item.deliverDriver === null));
         setReturns(_allReturns.filter((item) => item.driver === null || item.returnDriver === null));
      } else if (selectedDriver === 'ALL') {
         setDepartures(_allDepartures);
         setReturns(_allReturns);
      } else {
         setDepartures(_allDepartures);
         setReturns(_allReturns);
      }
   }, [selectedDriver]);

   const handleDeleteItemTransportation = (id, status) => {
      dispatch(deleteItemTransportation(+id)).then(() => {
         if (status === 'RENT') {
            setDepartures(departures.filter((item) => +item.id !== +id));
            setAllDepartures(_allDepartures.filter((item) => +item.id !== +id));
         } else {
            setReturns(returns.filter((item) => +item.id !== +id));
            setAllReturns(_allReturns.filter((item) => +item.id !== +id));
         }
      });
   };

   const confirm1 = (id, status) => {
      confirmDialog({
         message: t('pages.departuresAndReturns.confirm'),
         icon: 'pi pi-exclamation-triangle',
         accept: () => handleDeleteItemTransportation(id, status),
      });
   };

   const tableColumns = [
      {
         field: 'customer',
         name: 'customer',
         header: t('pages.logistics.customer'),
         body: (rowData) => {
            const companyName =
               rowData.machineCode || rowData.machineCode === ''
                  ? rowData.customer
                  : rowData.customer && rowData.customer.companyName
                  ? rowData.customer.companyName
                  : '-';
            return companyName ? <span>{companyName}</span> : <span>-</span>;
         },
      },
      {
         field: 'contractNo',
         name: 'contractNo',
         header: t('pages.logistics.contractNo'),
         body: (rowData) => {
            const contractNo =
               (rowData.orderItems && rowData.orderItems[0] && rowData.orderItems[0].order && rowData.orderItems[0].order.orderNumber) || rowData.contractNo;
            return contractNo ? <span>{contractNo}</span> : <span>-</span>;
         },
      },
      {
         field: 'deliveryAddress',
         name: 'deliveryAddress',
         header: t('pages.logistics.deliveryAddress'),
         body: (rowData) => {
            const deliveryAddress =
               (rowData.orderItems &&
                  rowData.orderItems[0] &&
                  rowData.orderItems[0].order &&
                  rowData.orderItems[0].order.address &&
                  rowData.orderItems[0].order.address.placeName +
                     ', ' +
                     rowData.orderItems[0].order.address.city +
                     ', ' +
                     rowData.orderItems[0].order.address.postCode +
                     ', ' +
                     rowData.orderItems[0].order.address.address) ||
               rowData.deliveryAddress;
            return deliveryAddress ? <span>{deliveryAddress}</span> : <span>-</span>;
         },
      },
      {
         field: 'contact',
         name: 'contact',
         header: t('pages.tours.contact'),
         body: (rowData) => {
            const receiverName =
               (rowData.orderItems &&
                  rowData.orderItems[0] &&
                  rowData.orderItems[0].order &&
                  rowData.orderItems[0].order.address &&
                  rowData.orderItems[0].order.address.receiverName) ||
               rowData.contact;
            return receiverName ? <span>{receiverName}</span> : <span>-</span>;
         },
      },
      {
         field: 'description',
         name: 'description',
         header: t('pages.contracts.description'),
         body: (rowData) => {
            const description = (rowData.vehicle && rowData.vehicle.note && rowData.vehicle.note) || rowData.description;
            return description ? <span>{description}</span> : <span>-</span>;
         },
      },
      {
         field: 'machineCode',
         name: 'machineCode',
         header: t('pages.malfunctions.machineCode'),
         body: (rowData) => {
            const machineCode =
               (rowData.vehicle && rowData.vehicle.machineFullCode && rowData.vehicle.machineFullCode.split(' ').join('')) || rowData.machineCode;
            return machineCode ? <span>{machineCode}</span> : <span>-</span>;
         },
      },
      {
         field: 'comment',
         name: 'comment',
         header: t('global.table.comment'),
         body: (rowData) => {
            const comment = rowData.comment && rowData.comment;
            return comment ? <span>{comment}</span> : <span>-</span>;
         },
      },
      {
         field: 'driver',
         name: 'driver',
         header: t('pages.tours.driver'),
         body: (rowData) => {
            const driver = (rowData.returnDriver && rowData.returnDriver) || (rowData.deliverDriver && rowData.deliverDriver) || rowData.driver;
            return driver ? <span>{driverOptions.find((item) => item.value === driver).label}</span> : <span>-</span>;
         },
      },
      {
         name: 'action',
         body: (rowData) => {
            return (
               <div className="flex justify-end">
                  <Button
                     onClick={() =>
                        rowData.machineCode || rowData.machineCode === '' || rowData.machineCode === null
                           ? handleUpdateItemTrasportation(rowData)
                           : handleUpdateReservation(rowData)
                     }
                     icon="pi pi-pencil"
                     severity="success"
                     className={`text-[14px] ps-[3px] !w-[32px] !py-[2px]`}
                  />

                  <Button
                     onClick={() => confirm1(rowData.id, rowData.status)}
                     icon="pi pi-trash"
                     severity="danger"
                     className={`text-[14px] ps-[3px] !w-[32px] !py-[2px] ms-2 ${
                        rowData.machineCode || rowData.machineCode === '' || rowData.machineCode === null ? 'block' : 'hidden'
                     }`}
                  />
               </div>
            );
         },
      },
   ];

   const openCreateDialog = (status) => {
      if (selectedDriver) {
         setCreateData({ driver: selectedDriver, status });
      } else {
         setCreateData({ status });
      }
      setDialogStatus('CREATE');
      setCreateStatus({ status });
      setCreateVisible(true);
   };

   const handleCreate = () => {
      if (createData.driver === 'WAITING') {
         createData.driver = null;
      }

      dispatch(createItemTransportation(createData)).then((res) => {
         setCreateVisible(false);
         setCreateData({
            status: null,
            customer: '',
            contractNo: '',
            deliveryAddress: '',
            contact: '',
            description: '',
            machineCode: '',
            comment: '',
            driver: null,
         });
         if (res.status === 'RENT') {
            setDepartures([...departures, res]);
            setAllDepartures([..._allDepartures, res]);
         } else {
            setReturns([...returns, res]);
            setAllReturns([..._allReturns, res]);
         }
      });
   };

   const handleUpdateItemTrasportation = (rowData) => {
      setDialogStatus('UPDATE');
      setCreateData({
         status: rowData.status,
         customer: rowData.customer,
         contractNo: rowData.contractNo,
         deliveryAddress: rowData.deliveryAddress,
         contact: rowData.contact,
         description: rowData.description,
         machineCode: rowData.machineCode,
         comment: rowData.comment,
         driver: rowData.driver,
      });
      setUpdatedItemId(rowData.id);
      setCreateVisible(true);
   };

   const handleUpdate = () => {
      const _data = { ...createData, id: updatedItemId };
      dispatch(updateItemTransportation(_data)).then((res) => {
         setCreateVisible(false);
         setCreateData({
            id: null,
            status: null,
            customer: '',
            contractNo: '',
            deliveryAddress: '',
            contact: '',
            description: '',
            machineCode: '',
            comment: '',
            driver: null,
         });
         if (res.status === 'RENT') {
            if (departures.filter((item) => item.machineCode || item.machineCode === '' || item.machineCode === null).length > 0) {
               setDepartures([...departures.filter((item) => item.id !== updatedItemId), res]);
               setAllDepartures([..._allDepartures.filter((item) => item.id !== updatedItemId), res]);
            }
         } else {
            if (returns.filter((item) => item.machineCode || item.machineCode === '' || item.machineCode === null).length > 0) {
               setReturns([...returns.filter((item) => item.id !== updatedItemId), res]);
               setAllReturns([..._allReturns.filter((item) => item.id !== updatedItemId), res]);
            }
         }
      });
   };

   const handleUpdateReservation = (rowData) => {
      setUpdateAddressData({
         id: rowData.orderItems[0].order.address.id,
         address: rowData.orderItems[0].order.address.address,
         receiverName: rowData.orderItems[0].order.address.receiverName,
         city: rowData.orderItems[0].order.address.city,
         country: rowData.orderItems[0].order.address.country,
         postCode: rowData.orderItems[0].order.address.postCode,
         placeName: rowData.orderItems[0].order.address.placeName,
      });
      setUpdateReservationData({
         id: +rowData.id,
         returnDriver: rowData.returnDriver,
         deliverDriver: rowData.deliverDriver,
         comment: rowData.comment,
         status: rowData.status,
         vehicleId: rowData.vehicle.id,
      });
      setUpdateReservationDiaolog(true);
   };

   const handleUpdateVehicleReservation = () => {
      dispatch(updateVehicleReservationDriver(updateReservationData)).then((res) => {
         setUpdateReservationDiaolog(false);
         if (res.status === 'RENT') {
            const _departures = departures.map((item) => {
               if (item.id === res.id) {
                  return res;
               }
               return item;
            });
            setDepartures(_departures);
            setAllDepartures(
               _allDepartures.map((item) => {
                  if (item.id === res.id) {
                     return res;
                  }
                  return item;
               }),
            );
         } else {
            const _returns = returns.map((item) => {
               if (item.id === res.id) {
                  return res;
               }
               return item;
            });
            setReturns(_returns);
            setAllReturns(
               _allReturns.map((item) => {
                  if (item.id === res.id) {
                     return res;
                  }
                  return item;
               }),
            );
         }
      });
      dispatch(updateAddress(updateAddressData)).then((res) => {
         if (updateReservationData.status === 'RENT') {
            const _departures = departures.map((item) => {
               if (item.id === updateReservationData.id) {
                  return { ...item, orderItems: [{ ...item.orderItems[0], order: { ...item.orderItems[0].order, address: res } }] };
               }

               return item;
            });
            setDepartures(_departures);
            setAllDepartures(
               _allDepartures.map((item) => {
                  if (item.id === updateReservationData.id) {
                     return { ...item, orderItems: [{ ...item.orderItems[0], order: { ...item.orderItems[0].order, address: res } }] };
                  }
                  return item;
               }),
            );
         } else {
            const _returns = returns.map((item) => {
               if (item.id === updateReservationData.id) {
                  return { ...item, orderItems: [{ ...item.orderItems[0], order: { ...item.orderItems[0].order, address: res } }] };
               }

               return item;
            });
            setReturns(_returns);
            setAllReturns(
               _allReturns.map((item) => {
                  if (item.id === updateReservationData.id) {
                     return { ...item, orderItems: [{ ...item.orderItems[0], order: { ...item.orderItems[0].order, address: res } }] };
                  }
                  return item;
               }),
            );
         }
      });
   };

   return (
      <div className="w-full">
         <ConfirmDialog />

         <div className="grid">
            <div className="col-12 md:col-6 md:!px-[10vw]">
               <label>{t('pages.tours.editionByDriver')}</label>
               <Dropdown
                  options={driverOptions}
                  value={selectedDriver}
                  onChange={(e) => setSelectedDriver(e.value)}
                  optionLabel="label"
                  optionValue="value"
                  className="w-full input-detail"
                  placeholder="All Drivers"
                  showClear
               />
            </div>

            {/* <div className="col-12 md:col-6 md:!px-[10vw]">
               <label>{t('pages.tours.printingTourSheets') + '(PDF)'}</label>
               <Dropdown
                  //   options={vehicles}
                  //   value={updateData.vehicleId}
                  //   onChange={(e) => setUpdateData({ ...updateData, vehicleId: e.value })}
                  optionLabel="machineFullCode"
                  optionValue="id"
                  className="w-full input-detail"
               />
            </div> */}
         </div>

         <div className="bg-white rounded shadow-md p-3 mt-4">
            <div className="flex items-center justify-between font-bold !text-[16px] mb-3">
               {t('pages.contracts.departure')}
               <Button onClick={() => openCreateDialog('RENT')} icon="pi pi-plus" rounded />
            </div>
            <TableComponent data={departures} columns={tableColumns} scrollable={true} cellSelection={true} />
         </div>

         <div className="bg-white rounded shadow-md p-3 mt-3">
            <div className="flex items-center justify-between font-bold !text-[16px] mb-3">
               {t('pages.contracts.return')}
               <Button onClick={() => openCreateDialog('RETURN')} icon="pi pi-plus" rounded />
            </div>
            <TableComponent data={returns} columns={tableColumns} scrollable={true} cellSelection={true} />
         </div>

         {/* Create Dialog */}
         <Dialog header={t('pages.planning.vehicleReservations')} visible={createVisible} style={{ width: '50vw' }} onHide={() => setCreateVisible(false)}>
            <div className="grid">
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.contracts.customer')}</label>
                  <InputText
                     value={createData.customer}
                     onChange={(e) => setCreateData({ ...createData, customer: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.departuresAndReturns.contractNo')}</label>
                  <InputText
                     value={createData.contractNo}
                     onChange={(e) => setCreateData({ ...createData, contractNo: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.customers.deliveryAddress')}</label>
                  <InputText
                     value={createData.deliveryAddress}
                     onChange={(e) => setCreateData({ ...createData, deliveryAddress: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.tours.contact')}</label>
                  <InputText
                     value={createData.contact}
                     onChange={(e) => setCreateData({ ...createData, contact: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.contracts.description')}</label>
                  <InputTextarea
                     value={createData.description}
                     onChange={(e) => setCreateData({ ...createData, description: e.target.value })}
                     className="w-full input-detail"
                     rows={1}
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.machineCode')}</label>
                  <InputText
                     value={createData.machineCode}
                     onChange={(e) => setCreateData({ ...createData, machineCode: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('global.table.comment')}</label>
                  <InputTextarea
                     value={createData.comment}
                     onChange={(e) => setCreateData({ ...createData, comment: e.target.value })}
                     className="w-full input-detail"
                     rows={1}
                  />
               </div>
               <div className="col-12 md:col-6 lg:col-4">
                  <label>{t('pages.tours.driver')}</label>
                  <Dropdown
                     options={driverOptions.filter((item) => item.value !== 'ALL' && item.value !== 'WAITING')}
                     value={createData.driver}
                     onChange={(e) => setCreateData({ ...createData, driver: e.value })}
                     optionLabel="label"
                     optionValue="value"
                     placeholder={t('global.select')}
                     className={`w-full input-detail`}
                     showClear
                  />
               </div>
            </div>
            <div className="mt-4 text-end">
               {dialogStatus === 'CREATE' ? (
                  <Button onClick={handleCreate} label={t('global.create')} severity="success" />
               ) : (
                  <Button onClick={handleUpdate} label={t('global.update')} severity="success" />
               )}
            </div>
         </Dialog>

         {/* Update Reservation Dialog */}
         <Dialog
            header={t('pages.planning.vehicleReservations')}
            visible={updateReservationDiaolog}
            style={{ width: '30vw' }}
            onHide={() => setUpdateReservationDiaolog(false)}
         >
            <div className="grid">
               <div className="col-12">
                  <label>{t('pages.tours.driver')}</label>
                  <Dropdown
                     value={updateReservationData.status === 'RENT' ? updateReservationData.deliverDriver : updateReservationData.returnDriver}
                     options={driverOptions}
                     optionLabel="label"
                     optionValue="value"
                     onChange={(e) =>
                        setUpdateReservationData(
                           updateReservationData.status === 'RENT'
                              ? { ...updateReservationData, deliverDriver: e.value }
                              : { ...updateReservationData, returnDriver: e.value },
                        )
                     }
                     className="w-full input-detail"
                     maxLength={255}
                  />
               </div>

               <div className="col-12">
                  <label>{t('pages.tours.contact')}</label>
                  <InputText
                     value={updateAddressData.receiverName}
                     onChange={(e) => setUpdateAddressData({ ...updateAddressData, receiverName: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>

               <div className="col-12">
                  <label>{t('global.table.comment')}</label>
                  <InputTextarea
                     value={updateReservationData.comment}
                     onChange={(e) => setUpdateReservationData({ ...updateReservationData, comment: e.target.value })}
                     className="w-full input-detail"
                     maxLength={255}
                  />
               </div>

               <div className="col-12">
                  <label>{t('global.table.deliveryAddress')}</label>
                  <InputTextarea
                     value={updateAddressData.address}
                     onChange={(e) => setUpdateAddressData({ ...updateAddressData, address: e.target.value })}
                     className="w-full input-detail"
                     maxLength={255}
                  />
               </div>

               <div className="col-12">
                  <label>{t('pages.planning.placeName')}</label>
                  <InputText
                     value={updateAddressData.placeName}
                     onChange={(e) => setUpdateAddressData({ ...updateAddressData, placeName: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>

               <div className="col-12">
                  <label>{t('global.table.city')}</label>
                  <InputText
                     value={updateAddressData.city}
                     onChange={(e) => setUpdateAddressData({ ...updateAddressData, city: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>

               <div className="col-12">
                  <label>{t('global.table.postCode')}</label>
                  <InputText
                     value={updateAddressData.postCode}
                     onChange={(e) => setUpdateAddressData({ ...updateAddressData, postCode: e.target.value })}
                     className="w-full input-detail"
                  />
               </div>

               <div className="col-12 mt-2 text-end">
                  <Button onClick={handleUpdateVehicleReservation} label={t('global.update')} severity="success" />
               </div>
            </div>
         </Dialog>
      </div>
   );
};

export default Tours;
