import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeSidebarStatus } from '../store/slices/sharedSlice';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';

import { setSelectedLang } from '../store/slices/languageSlice';
import { t } from 'i18next';
import { setReminderButtonClicked, setShowReminders } from '../store/slices/remindersSlice';
import { getGlobalSearch } from '../store/apis/SharedApi';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/min/locales';

const Header = () => {
   const dispatch = useDispatch();
   const navigation = useNavigate();
   const { showSidebar } = useSelector((state) => state.shared);
   const { selectedLang, languages } = useSelector((state) => state.language);
   const { user } = useSelector((state) => state.user);

   const { unreadNotifications } = useSelector((state) => state.reminder);

   const [searchInput, setSearchInput] = useState('');
   const [searchSuggestions, setSearchSuggestions] = useState([]);
   const [options, setOptions] = useState([]);

   const searchFunc = (event) => {
      // setSearchSuggestions([...Array(10).keys()].map((item) => event.query + '-' + item));
      dispatch(getGlobalSearch(event.query)).then((res) => {
         const _options = [];
         for (let i = 0; i < res.customers.length; i++) {
            _options.push({ label: res.customers[i].companyName, value: res.customers[i].id, type: t('global.sidebar.customer') });
         }
         for (let i = 0; i < res.offers.length; i++) {
            _options.push({ label: res.offers[i].orderNumber, value: res.offers[i].id, type: t('pages.pdf.offer') });
         }
         for (let i = 0; i < res.contracts.length; i++) {
            _options.push({ label: res.contracts[i].orderNumber, value: res.contracts[i].id, type: t('global.table.contract') });
         }
         for (let i = 0; i < res.vehicles.length; i++) {
            _options.push({ label: res.vehicles[i].machineFullCode, value: res.vehicles[i].id, type: t('pages.malfunctions.machine') });
         }
         setSearchSuggestions(_options.map((item) => item.label + ' - ' + (item.type === 'Order' ? t('pages.contracts.contract') : item.type)));
         setOptions(_options);
      });
   };

   const handleClick = (e) => {
      setSearchInput(e.target.value);
      if (options.find((item) => item.label == e.target.value.split(' - ')[0])) {
         if (options.find((item) => item.label == e.target.value.split(' - ')[0]).type == t('global.sidebar.customer')) {
            navigation('/customers', { state: { customerId: options.find((item) => item.label == e.target.value.split(' - ')[0]).value } });
         } else if (options.find((item) => item.label == e.target.value.split(' - ')[0]).type == t('pages.pdf.offer')) {
            navigation('/commerce/offers', { state: { orderId: options.find((item) => item.label == e.target.value.split(' - ')[0]).value } });
         } else if (options.find((item) => item.label == e.target.value.split(' - ')[0]).type == t('pages.malfunctions.machine')) {
            navigation(`/workshop/contracts`, { state: { machineId: options.find((item) => item.label == e.target.value.split(' - ')[0]).value } });
         } else if (options.find((item) => item.label == e.target.value.split(' - ')[0]).type == t('global.table.contract')) {
            navigation(`/workshop/t-card`, { state: { machineId: options.find((item) => item.label == e.target.value.split(' - ')[0]).value } });
         } else {
            console.log('error');
         }
      }
   };

   return (
      <div className="w-full flex justify-between items-center h-10">
         <div className="flex items-center">
            <Button onClick={() => dispatch(changeSidebarStatus(!showSidebar))} icon="pi pi-bars" text className="mr-3" size="small" />
            <AutoComplete
               value={searchInput}
               suggestions={searchSuggestions}
               completeMethod={searchFunc}
               onChange={(e) => handleClick(e)}
               className="p-inputtext-sm"
               placeholder={t('global.search')}
            />
         </div>
         <div className="flex items-center">
            <Dropdown
               value={selectedLang}
               onChange={(e) => {
                  dispatch(setSelectedLang(e.value));
                  moment.locale(e.value.split('-')[0]);
               }}
               options={languages}
               optionLabel="name"
               className="w-auto !p-0"
            />
            <div className="hidden md:block mx-3 py-2 px-4 rounded-lg">
               <i className="pi pi-user mr-3 text-sm" />
               <span>
                  {user.firstName} {user.lastName}
               </span>
            </div>
            <Button
               type="button"
               icon="pi pi-bell"
               outlined={unreadNotifications !== 0 ? false : true}
               onClick={() => {
                  dispatch(setShowReminders(true));
                  dispatch(setReminderButtonClicked(true));
               }}
               badge={unreadNotifications}
               // badgeClassName="p-badge-danger"
               className="w-auto px-3"
               size="small"
            />
         </div>
      </div>
   );
};

export default Header;
