import { t } from 'i18next';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAddresses, getPublicHolidays } from '../../../../store/apis/OffersApi';
import CreateInformationAddress from './CreateInformationAddress';
import { InputSwitch } from 'primereact/inputswitch';
import TableComponent from '../../../../components/TableComponent';
import { deleteOrderItem, getInvoice, updateInvoice } from '../../../../store/apis/ManagementApi';
import UpdateInvoiceDetail from './UpdateInvoiceDetail';
import toast from 'react-hot-toast';
import AddSaleItems from './AddSaleItems';
import UpdateSaleItem from './UpdateSaleItem';
import moment from 'moment';
import InvoiceOffer1 from '../../../../components/PDFS/InvoiceOffer/InvoiceOffer1';
import SaleInvoicePDF from '../../../../components/PDFS/SaleInvoicePDF';
import RentOfferForInvoice from '../../../../components/PDFS/RentOffer/RentOfferForInvoice';
import SaleOfferForInvoicePDF from '../../../../components/PDFS/SaleOfferForInvoicePDF';
import { InputTextarea } from 'primereact/inputtextarea';

const UpdateInvoice = ({ selectedInvoice, setSelectedInvoice }) => {
   const dispatch = useDispatch();
   const invoice = useSelector((state) => state.management.invoice);
   const [invoiceInputs, setInvoiceInputs] = useState({});
   const [errors, setErrors] = useState({});
   const [addresses, setAddresses] = useState();
   const [createAddressDialog, setCreateAddressDialog] = useState(false);
   const [createdAddress, setCreatedAddress] = useState(null);
   const [selectedAddressId, setSelectedAddressId] = useState(null);
   const [updateInvoiceDialog, setUpdateInvoiceDialog] = useState({
      visible: false,
      data: null,
   });
   const [createSaleDialog, setCreateSaleDialog] = useState(false);
   const [totalPrices, setTotalPrices] = useState({
      totalAfterTax: 0,
   });
   const [invoiceLockedDialog, setInvoiceLockedDialog] = useState(false);
   const [invoiceLocked, setInvoiceLocked] = useState(false);
   const [updateSaleDialog, setUpdateSaleDialog] = useState(false);
   const [removeOrderItem, setRemoveOrderItem] = useState({
      visible: false,
      data: null,
   });
   const [publicHolidays, setPublicHolidays] = useState([]);

   useEffect(() => {
      const convertToFormattedDates = (datesArray) => {
         const currentYear = moment().year();
         const formattedDates = datesArray.map((date) => {
            const { day, month } = date;
            const formattedDate = moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
            return formattedDate;
         });
         setPublicHolidays(formattedDates);
      };
      dispatch(getPublicHolidays()).then((res) => {
         if (res) {
            convertToFormattedDates(res);
         }
      });
   }, []);

   useEffect(() => {
      if (selectedInvoice) {
         dispatch(getInvoice(selectedInvoice.data.id));
      }
   }, [selectedInvoice]);

   useEffect(() => {
      if (invoice && invoice.customer) {
         dispatch(getAddresses({ customerId: invoice.customer.id })).then((res) => setAddresses(res));
      }
      setInvoiceInputs({
         addressId: invoice?.address.id,
         referenceNumber: invoice?.referenceNumber,
         description: invoice?.description,
         privacyDescription: invoice?.privacyDescription,
         isFactor: invoice?.isFactor,
      });

      let totalItemsAfterTax = 0;
      if (invoice?.invoiceItems) {
         totalItemsAfterTax = invoice?.invoiceItems.reduce(
            (acc, item) => acc + parseFloat(item.price * item.quantity + (item.price * item.quantity * item.tax) / 100),
            0,
         );
         setTotalPrices({
            totalAfterTax: (parseFloat(invoice?.totalAfterTax) + parseFloat(totalItemsAfterTax)).toFixed(2),
         });
      }
   }, [invoice]);

   useEffect(() => {
      if (createdAddress) {
         if (selectedAddressId) {
            setAddresses(addresses.map((address) => (+address.id === +createdAddress.id ? createdAddress : address)));
            setInvoiceInputs({ ...invoiceInputs, addressId: createdAddress.id });
         } else {
            setAddresses([...addresses, createdAddress]);
            setInvoiceInputs({ ...invoiceInputs, addressId: createdAddress.id });
         }
      }
   }, [createdAddress]);

   const updateErrors = (name, value) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: !value ? t('global.requiredField') : '',
      }));
   };

   const handleInputChange = (e, name) => {
      const { value } = e.target;
      setInvoiceInputs({ ...invoiceInputs, [name]: value });
      updateErrors(name, value);
   };

   const validateForm = () => {
      const inputs = ['addressId'];
      let isValid = true;
      inputs.forEach((input) => {
         if (!invoiceInputs[input]) {
            updateErrors(input, invoiceInputs[input]);
            isValid = false;
         }
      });
      return isValid;
   };

   const handleUpdateInvoice = () => {
      if (!validateForm()) return;
      const data = {
         id: invoice.id,
         addressId: invoiceInputs.addressId,
         referenceNumber: invoiceInputs.referenceNumber,
         description: invoiceInputs.description,
         privacyDescription: invoiceInputs.privacyDescription,
         isFactor: invoiceInputs.isFactor,
         status: invoiceLocked ? 'INVOICED' : undefined,
      };
      dispatch(updateInvoice(data)).then((res) => {
         if (res) {
            /* setSelectedInvoice({
               visible: true,
               data: null,
            }); */
         }
         invoiceLocked ? toast.success(t('pages.invoices.invoiceCreatedSuccessfully')) : toast.success(t('pages.invoices.updateInvoiceSuccess'));
      });
   };

   const handleDeleteOrderItem = () => {
      const data = {
         id: removeOrderItem.data.id,
         invoiceId: invoice.id,
      };
      dispatch(deleteOrderItem(data)).then(() => {
         setRemoveOrderItem({
            visible: false,
            data: null,
         });
         toast.success(t('pages.contracts.deleteOrderItemSuccess'));
      });
   };

   const calculateWorkDays = (item) => {
      let start = moment(item.beginDate);
      let end = moment(item.endDate);
      let workDays = 0;
      const totalDays = end.diff(start, 'days') + 1;
      for (let i = 0; i < totalDays; i++) {
         const currentDay = start.day();
         const currentDate = start.format('YYYY-MM-DD');
         if (
            (currentDay === 0 && !item.isIncludingSundays) ||
            (currentDay === 6 && !item.isIncludingSaturdays) ||
            (!item.isIncludingPublicHolidays && publicHolidays.includes(currentDate))
         ) {
            start.add(1, 'days');
            continue;
         }
         workDays++;
         start.add(1, 'days');
      }
      return workDays;
   };

   const tableColumns = [
      {
         field: 'edit',
         name: 'edit',
         bodyClassName: 'w-[70px]',
         body: (rowData) => {
            return (
               <Button
                  icon="pi pi-pencil"
                  severity="warning"
                  className="text-[14px] !py-[3px]"
                  onClick={() =>
                     setUpdateInvoiceDialog({
                        visible: true,
                        data: rowData,
                     })
                  }
               />
            );
         },
      },
      {
         name: 'machineGroup',
         header: t('pages.planning.machineGroup'),
         body: (rowData) => <span>{rowData?.vehicleGroup?.name}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'machineCode',
         header: t('pages.planning.machineCode'),
         body: (rowData) => <span>{rowData?.vehicle?.machineFullCode}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'description',
         header: t('pages.contracts.description'),
         body: (rowData) => <span>{rowData?.vehicle?.note}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'beginDate',
         header: t('pages.offers.startDate'),
         body: (rowData) => <span>{moment(rowData?.beginDate).format('DD/MM/YYYY')}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'endDate',
         header: t('pages.offers.endDate'),
         body: (rowData) => <span>{moment(rowData?.endDate).format('DD/MM/YYYY')}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'quantity',
         header: t('pages.offers.quantity'),
         body: (rowData) => <span>{rowData?.quantity}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'price',
         header: t('pages.offers.price'),
         body: (rowData) => <span>€{rowData?.price}</span>,
         headerClassName: 'bg-red-100',
      },
      {
         name: 'rent',
         header: t('pages.planning.rent'),
         body: (rowData) => <span>€{parseFloat(rowData?.subTotal).toFixed(2)}</span>,
      },
      {
         name: 'ecoFee',
         header: t('pages.offers.ecoFee'),
         body: (rowData) => <span>€{rowData?.isEco ? rowData?.ecoPrice : 0}</span>,
      },
      {
         name: 'insuranceRate',
         header: t('pages.offers.insurance'),
         body: (rowData) => <span>{rowData?.isInsurance ? rowData?.insurancePrice + ' €' : 0.0 + ' €'}</span>,
      },
      {
         name: 'deliveryFee',
         header: t('pages.offers.deliveryFee'),
         body: (rowData) => <span>€{rowData?.deliveryFee}</span>,
      },
      {
         name: 'pickupFee',
         header: t('pages.offers.pickupFee'),
         body: (rowData) => <span>€{rowData?.pickupFee}</span>,
      },
      {
         name: 'discount',
         header: t('pages.invoices.discount'),
         body: (rowData) => <span>€{parseFloat((rowData?.price * rowData?.quantity * rowData?.discount) / 100).toFixed(2)}</span>,
      },
      {
         name: 'fuel',
         header: t('pages.invoices.fuel'),
         body: (rowData) => <span>€{parseFloat(rowData?.fuel * rowData?.dieselPrice).toFixed(2)}</span>,
      },
      {
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) => <span>€{parseFloat(rowData?.totalBeforeTax).toFixed(2)}</span>,
      },
      {
         name: 'tax',
         header: t('pages.offers.tax'),
         body: (rowData) => <span>%{+rowData?.tax}</span>,
      },
      {
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => <span>€{parseFloat(rowData?.totalAfterTax).toFixed(2)}</span>,
      },
   ];

   const tableColumnsSale = [
      {
         field: 'edit',
         name: 'edit',
         bodyClassName: 'w-[70px]',
         body: () => {
            return <Button icon="pi pi-pencil" severity="warning" className="text-[14px] !py-[3px]" onClick={() => setUpdateSaleDialog(true)} />;
         },
      },
      {
         name: 'category',
         header: t('pages.offers.category'),
         body: (rowData) => {
            return <span>{rowData?.category}</span>;
         },
      },
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => {
            return <span>{rowData?.saleDescription}</span>;
         },
      },
      {
         name: 'quantity',
         header: t('pages.offers.quantity'),
         body: (rowData) => {
            return <span>{rowData?.quantity}</span>;
         },
      },
      {
         name: 'price',
         header: t('pages.offers.price'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price).toFixed(2)}</span>;
         },
      },
      {
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price * rowData?.quantity).toFixed(2)}</span>;
         },
      },
      {
         name: 'taxFee',
         header: t('pages.offers.taxFee'),
         body: (rowData) => {
            return <span>€{((rowData?.price * rowData?.quantity * rowData?.tax) / 100).toFixed(2)}</span>;
         },
      },
      {
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price * rowData?.quantity + (rowData?.price * rowData?.quantity * rowData?.tax) / 100).toFixed(2)}</span>;
         },
      },
   ];

   const tableColumnsSaleItems = [
      {
         name: 'category',
         header: t('pages.offers.category'),
         body: (rowData) => {
            return <span>{rowData?.category}</span>;
         },
         footer: t('pages.offers.total').toUpperCase(),
      },
      {
         name: 'description',
         header: t('global.table.description'),
         body: (rowData) => {
            return <span>{rowData?.description}</span>;
         },
      },
      {
         name: 'quantity',
         header: t('pages.offers.quantity'),
         body: (rowData) => {
            return <span>{rowData?.quantity}</span>;
         },
      },
      {
         name: 'price',
         header: t('pages.offers.price'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price).toFixed(2)}</span>;
         },
      },
      {
         name: 'totalBeforeTax',
         header: t('pages.offers.totalBeforeTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price * rowData?.quantity).toFixed(2)}</span>;
         },
      },
      {
         name: 'taxFee',
         header: t('pages.offers.taxFee'),
         body: (rowData) => {
            return <span>€{((rowData?.price * rowData?.quantity * rowData?.tax) / 100).toFixed(2)}</span>;
         },
      },
      {
         name: 'totalAfterTax',
         header: t('pages.offers.totalAfterTax'),
         body: (rowData) => {
            return <span>€{parseFloat(rowData?.price * rowData?.quantity + (rowData?.price * rowData?.quantity * rowData?.tax) / 100).toFixed(2)}</span>;
         },
         footer: <span>€{parseFloat(invoice?.totalAfterTax).toFixed(2)}</span>,
      },
      {
         name: 'actions',
         header: '',
         body: (rowData) => {
            return (
               <Button
                  icon="pi pi-trash"
                  severity="danger"
                  className="text-[14px] !py-[3px]"
                  onClick={() => setRemoveOrderItem({ visible: true, data: rowData })}
               />
            );
         },
      },
   ];

   return (
      <>
         {selectedInvoice && selectedInvoice.type === 'machine' ? (
            <Dialog
               header={
                  t('pages.invoices.updateInvoice') + ' ' + t('pages.invoices.for').toLocaleLowerCase() + ': ' + selectedInvoice?.data?.customer?.companyName
               }
               visible={selectedInvoice.visible}
               className="w-[80vw]"
               onHide={() =>
                  setSelectedInvoice({
                     visible: false,
                     data: null,
                  })
               }
            >
               <div className="grid">
                  <div className="col-12 md:col-6 lg:col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                     <div style={{ marginRight: '10px', flex: '1' }}>
                        <label>{t('global.table.placeName')}*</label>
                        <Dropdown
                           filter
                           options={addresses}
                           value={invoiceInputs.addressId}
                           onChange={(e) => handleInputChange(e, 'addressId')}
                           optionLabel="placeName"
                           optionValue="id"
                           className={`w-full input-detail ${errors.addressId && 'input-error'}`}
                           showClear
                        />
                        {errors.addressId && <small className="p-error">{errors.addressId}</small>}
                     </div>
                     {invoiceInputs.addressId && (
                        <Button
                           icon="pi pi-pencil"
                           severity="warning"
                           className="mt-4 me-2 h-[35px] w-[35px]"
                           onClick={() => {
                              setSelectedAddressId(invoiceInputs.addressId);
                              setCreateAddressDialog(true);
                           }}
                        />
                     )}
                     <Button
                        icon="pi pi-plus"
                        className="mt-4 h-[35px] w-[35px]"
                        onClick={() => {
                           setCreateAddressDialog(true);
                           setSelectedAddressId(null);
                        }}
                     />
                  </div>
                  <div className="col-12 md:col-6 lg:col-6">
                     <label>{t('pages.offers.poNumber')}</label>
                     <InputText
                        value={invoiceInputs.referenceNumber}
                        onChange={(e) => handleInputChange(e, 'referenceNumber')}
                        className="w-full input-detail"
                        maxLength={50}
                     />
                  </div>
                  <div className="col-12 md:col-12 lg:col-12">
                     <label>{t('global.table.explanation')}</label>
                     <InputTextarea
                        rows={2}
                        value={invoiceInputs.description}
                        onChange={(e) => handleInputChange(e, 'description')}
                        className="w-full input-detail"
                     />
                     {errors.description && <small className="p-error">{errors.description}</small>}
                  </div>
                  <div className="col-12 md:col-12 lg:col-12">
                     <label>{t('pages.offers.privacyExplanation')}</label>
                     <InputTextarea
                        rows={2}
                        value={invoiceInputs.privacyDescription}
                        onChange={(e) => handleInputChange(e, 'privacyDescription')}
                        className="w-full input-detail"
                     />
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <div className="flex items-center justify-between p-1 border-2 rounded-md">
                        <label>{t('pages.offers.offered')}</label>
                        <div className="flex items-center">
                           <InputSwitch checked={true} className="mt-1" disabled />
                           <RentOfferForInvoice data={invoice} type={'offer'} />
                        </div>
                     </div>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <div className="flex items-center justify-between p-1 border-2 rounded-md">
                        <label>{t('pages.contracts.contracted')}</label>
                        <div className="flex items-center">
                           <InputSwitch checked={true} className="mt-1" disabled />
                           <RentOfferForInvoice data={invoice} type={'contract'} />
                        </div>
                     </div>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <div className="flex items-center justify-between p-1 border-2 rounded-md">
                        <label>{t('pages.offers.factor')}</label>
                        <div className="flex items-center">
                           <InputSwitch checked={invoiceInputs.isFactor} onChange={(e) => handleInputChange(e, 'isFactor')} className="mt-1" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="mt-4">
                  <span className="font-bold">{invoice?.category === 'INV' ? t('pages.offers.inventoryMachine') : t('pages.offers.supplierMachine')}</span>
                  <TableComponent
                     data={invoice?.children && invoice?.children.length > 0 ? invoice?.children : [invoice]}
                     columns={tableColumns}
                     className="mt-1"
                  />
               </div>

               <div className="mt-4">
                  <div className="flex items-center">
                     <span className="font-bold">{t('pages.invoices.saleItems')}</span>
                     <Button icon="pi pi-plus" className="ml-3 !py-[3px]" onClick={() => setCreateSaleDialog(true)} />
                  </div>

                  <TableComponent data={invoice?.invoiceItems || []} columns={tableColumnsSaleItems} className="mt-1" />
               </div>

               <div className="flex items-center justify-between mt-3">
                  <div className="flex items-center">
                     <Button
                        icon={invoiceLocked ? 'pi pi-lock' : 'pi pi-lock-open'}
                        severity={invoiceLocked ? 'success' : 'warning'}
                        className="text-[14px] !py-[3px] mr-2"
                        onClick={() => setInvoiceLockedDialog(true)}
                     />
                     <InvoiceOffer1 data={invoice} />
                  </div>
                  <Button label={t('global.save')} severity="success" className="text-[14px] !py-[3px]" onClick={handleUpdateInvoice} />
               </div>
            </Dialog>
         ) : (
            <Dialog
               header={
                  t('pages.invoices.updateInvoice') + ' ' + t('pages.invoices.for').toLocaleLowerCase() + ': ' + selectedInvoice?.data?.customer?.companyName
               }
               visible={selectedInvoice.visible}
               className="w-[80vw]"
               onHide={() =>
                  setSelectedInvoice({
                     visible: false,
                     data: null,
                  })
               }
            >
               <div className="grid">
                  <div className="col-12 md:col-6 lg:col-6" style={{ display: 'flex', alignItems: 'flex-start' }}>
                     <div style={{ marginRight: '10px', flex: '1' }}>
                        <label>{t('global.table.placeName')}*</label>
                        <Dropdown
                           filter
                           options={addresses}
                           value={invoiceInputs.addressId}
                           onChange={(e) => handleInputChange(e, 'addressId')}
                           optionLabel="placeName"
                           optionValue="id"
                           className={`w-full input-detail ${errors.addressId && 'input-error'}`}
                           showClear
                        />
                        {errors.addressId && <small className="p-error">{errors.addressId}</small>}
                     </div>
                     {invoiceInputs.addressId && (
                        <Button
                           icon="pi pi-pencil"
                           severity="warning"
                           className="mt-4 me-2 h-[35px] w-[35px]"
                           onClick={() => {
                              setSelectedAddressId(invoiceInputs.addressId);
                              setCreateAddressDialog(true);
                           }}
                        />
                     )}
                     <Button
                        icon="pi pi-plus"
                        className="mt-4 h-[35px] w-[35px]"
                        onClick={() => {
                           setCreateAddressDialog(true);
                           setSelectedAddressId(null);
                        }}
                     />
                  </div>
                  <div className="col-12 md:col-6 lg:col-6">
                     <label>{t('pages.offers.poNumber')}</label>
                     <InputText
                        value={invoiceInputs.referenceNumber}
                        onChange={(e) => handleInputChange(e, 'referenceNumber')}
                        className="w-full input-detail"
                        maxLength={50}
                     />
                  </div>
                  <div className="col-12 md:col-12 lg:col-12">
                     <label>{t('global.table.explanation')}</label>
                     <InputTextarea
                        rows={2}
                        value={invoiceInputs.description}
                        onChange={(e) => handleInputChange(e, 'description')}
                        className="w-full input-detail"
                     />
                     {errors.description && <small className="p-error">{errors.description}</small>}
                  </div>
                  <div className="col-12 md:col-12 lg:col-12">
                     <label>{t('pages.offers.privacyExplanation')}</label>
                     <InputTextarea
                        rows={2}
                        value={invoiceInputs.privacyDescription}
                        onChange={(e) => handleInputChange(e, 'privacyDescription')}
                        className="w-full input-detail"
                     />
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <div className="flex items-center justify-between p-1 border-2 rounded-md">
                        <label>{t('pages.offers.offered')}</label>
                        <div className="flex items-center">
                           <InputSwitch checked={true} className="mt-1" disabled />
                           <SaleOfferForInvoicePDF data={invoice} />
                        </div>
                     </div>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <div className="flex items-center justify-between p-1 border-2 rounded-md">
                        <label>{t('pages.contracts.contracted')}</label>
                        <div className="flex items-center">
                           <InputSwitch checked={true} className="mt-1" disabled />
                           <SaleOfferForInvoicePDF data={invoice} />
                        </div>
                     </div>
                  </div>
                  <div className="col-12 md:col-6 lg:col-4">
                     <div className="flex items-center justify-between p-1 border-2 rounded-md">
                        <label>{t('pages.offers.factor')}</label>
                        <div className="flex items-center">
                           <InputSwitch checked={invoiceInputs.isFactor} onChange={(e) => handleInputChange(e, 'isFactor')} className="mt-1" />
                        </div>
                     </div>
                  </div>
               </div>

               <div className="mt-4">
                  <span className="font-bold">{t('pages.invoices.sale')}</span>
                  <TableComponent data={[invoice]} columns={tableColumnsSale} className="mt-1" />
               </div>

               <div className="mt-4">
                  <div className="flex items-center">
                     <span className="font-bold">{t('pages.invoices.saleItems')}</span>
                     <Button icon="pi pi-plus" className="ml-3 !py-[3px]" onClick={() => setCreateSaleDialog(true)} />
                  </div>

                  <TableComponent data={invoice?.invoiceItems || []} columns={tableColumnsSaleItems} className="mt-1" />
               </div>

               <div className="flex items-center justify-between mt-3">
                  <div className="flex items-center">
                     <Button
                        icon={invoiceLocked ? 'pi pi-lock' : 'pi pi-lock-open'}
                        severity={invoiceLocked ? 'success' : 'warning'}
                        className="text-[14px] !py-[3px] mr-2"
                        onClick={() => setInvoiceLockedDialog(true)}
                     />
                     <SaleInvoicePDF data={invoice} />
                  </div>
                  <Button label={t('global.save')} severity="success" className="text-[14px] !py-[3px]" onClick={handleUpdateInvoice} />
               </div>
            </Dialog>
         )}

         <Dialog
            header={selectedAddressId ? t('pages.offers.updateAddress') : t('pages.offers.createANewAddress')}
            className="w-[70vw]"
            visible={createAddressDialog}
            onHide={() => {
               setCreateAddressDialog(false);
               setSelectedAddressId(null);
            }}
         >
            <CreateInformationAddress
               setCreateAddressDialog={setCreateAddressDialog}
               setCreatedAddress={setCreatedAddress}
               customerId={invoice?.customer?.id}
               selectedAddressId={selectedAddressId}
            />
         </Dialog>

         <Dialog
            header={t('pages.invoices.invoiceLock')}
            style={{ width: '400px' }}
            visible={invoiceLockedDialog}
            onHide={() => {
               setInvoiceLockedDialog(false);
            }}
         >
            <span>{t('pages.invoices.updateInvoiceLockMessage')}</span>
            <div className="flex justify-end mt-3">
               <Button label={t('pages.offers.cancel')} severity="danger" className="mr-2 " onClick={() => setInvoiceLockedDialog(false)} />
               <Button
                  label={t('global.apply')}
                  severity="success"
                  onClick={() => {
                     setInvoiceLocked(true);
                     setInvoiceLockedDialog(false);
                  }}
               />
            </div>
         </Dialog>

         <Dialog
            header={t('pages.invoices.deleteInvoiceSaleItem')}
            visible={removeOrderItem.visible}
            style={{ width: '400px' }}
            onHide={() =>
               setRemoveOrderItem({
                  visible: false,
                  data: null,
               })
            }
         >
            <div className="confirmation-content">{<span>{t('pages.invoices.deleteInvoiceSaleItemMessage')}</span>}</div>
            <div className="flex justify-end mt-3">
               <Button
                  label={t('pages.contracts.cancel')}
                  severity="warning"
                  className="mr-2 "
                  onClick={() =>
                     setRemoveOrderItem({
                        visible: false,
                        data: null,
                     })
                  }
               />
               <Button label={t('pages.contracts.delete')} severity="danger" onClick={() => handleDeleteOrderItem()} />
            </div>
         </Dialog>

         {updateInvoiceDialog.visible && <UpdateInvoiceDetail updateInvoice={updateInvoiceDialog} setUpdateInvoice={setUpdateInvoiceDialog} />}
         {createSaleDialog && <AddSaleItems createSaleDialog={createSaleDialog} setCreateSaleDialog={setCreateSaleDialog} />}
         {updateSaleDialog && <UpdateSaleItem updateSaleDialog={updateSaleDialog} setUpdateSaleDialog={setUpdateSaleDialog} />}
      </>
   );
};

export default UpdateInvoice;
