import { combineReducers, configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, createTransform } from 'redux-persist';
import { thunk } from 'redux-thunk';
import CryptoJS from 'crypto-js';
// reducer imports
import userSlice from './slices/userSlice';
import sharedSlice from './slices/sharedSlice';
import languageSlice from './slices/languageSlice';
import customerSlice from './slices/customerSlice';
import machineSlice from './slices/machineSlice';
import planningSlice from './slices/planningSlice';
import offersSlice from './slices/offerSlice';
import malfunctionSlice from './slices/MalfunctionSlice';
import contractsSlice from './slices/contractsSlice';
import logisticsSlice from './slices/logisticsSlice';
import managementSlice from './slices/managementSlice';
import reminderSlice  from './slices/remindersSlice';

const encrypt = createTransform(
   (inboundState) => {
      return CryptoJS.AES.encrypt(JSON.stringify(inboundState), import.meta.env.ALBATRACK_SECRET_KEY).toString();
   },
   (outboundState) => {
      return JSON.parse(CryptoJS.AES.decrypt(outboundState, import.meta.env.ALBATRACK_SECRET_KEY).toString(CryptoJS.enc.Utf8));
   },
);
const persistConfig = {
   key: 'root',
   storage,
   whiteList: ['user', 'language'],
   blacklist: ['customer', 'machine'],
   transform: [encrypt],
};
const reducer = combineReducers({
   user: userSlice,
   shared: sharedSlice,
   language: languageSlice,
   customer: customerSlice,
   machine: machineSlice,
   planning: planningSlice,
   offers: offersSlice,
   malfunctions: malfunctionSlice,
   contracts: contractsSlice,
   logistics: logisticsSlice,
   management: managementSlice,
   reminder: reminderSlice
});
const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
