import React from 'react';

import './components.scss';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { t } from 'i18next';

const TableComponent = (props) => {
   return (
      <div>
         <DataTable
            className={props.className}
            value={props.data}
            size={props.size || 'small'}
            stripedRows
            scrollable={props.scrollable}
            scrollHeight={props.scrollHeight}
            onRowClick={(e) => (props.onRowClick ? props.onRowClick(e.data) : null)}
            emptyMessage={t('global.table.noData')}
            removableSort
            headerColumnGroup={props.headerColumnGroup}
            cellSelection={props.cellSelection}
            onCellSelect={props.onCellSelect}
            rowClassName={props.rowClassName}
            selectionMode={props.selectionMode}
            selection={props.selection}
            onSelectionChange={props.onSelectionChange}
            showGridlines={props.showGridlines}
            editMode={props.editMode}
            onRowEditComplete={props.onRowEditComplete}
         >
            {props.columns?.map((column) => {
               if (column.body) {
                  return (
                     <Column
                        key={column.name || column.field}
                        header={column.header}
                        footer={column.footer}
                        body={column.body}
                        field={column.field}
                        bodyClassName={column.bodyClassName}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        selectionMode={column.selectionMode}
                        editor={column.editor}
                        rowEditor={column.rowEditor}
                     />
                  );
               } else {
                  return (
                     <Column
                        key={column.name || column.field}
                        field={column.field}
                        header={column.header}
                        footer={column.footer}
                        sortable={props.sortable}
                        headerClassName={column.headerClassName}
                        headerStyle={column.headerStyle}
                        bodyClassName={column.bodyClassName}
                        selectionMode={column.selectionMode}
                        editor={column.editor}
                        rowEditor={column.rowEditor}
                     />
                  );
               }
            })}
         </DataTable>
      </div>
   );
};

export default TableComponent;
